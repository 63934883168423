import React, { useContext, useEffect } from "react";
import ContextState from "../contextApi/ContextState";
import { BiSolidErrorAlt } from "react-icons/bi";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IoReload } from "react-icons/io5";

const ContextError = () => {
  const { showError, showErrorPage, key } = useContext(ContextState);
  const showHome = () => {
    // navigate("/");
    window.location.href = "/";
    // window.location.href = "/";
  };

  useEffect(() => {
    document.title = "Error - NEO";

    return () => {
      document.title = "NEO";
    };
  }, []);

  const code = 500;
  return (
    <div className="error-div">
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          {/* <h1 className="display-1 fw-bold">hello</h1> */}

          <div>
            <h2 className="display-5 fw-bold">{code}</h2>
            {/* <p className="fs-3">
              <span className="text-danger">Opps!</span> Some Error Occured From
              Your Side.
            </p> */}
            <p className="fs-3 fw-bold">Technical Break</p>
            {/* <p className="lead">"The page you’re looking for doesn’t exist."</p> */}

            <p className="mb-0">
              we apologize for the interruptions on the site.
            </p>
            <p className="mb-0">
              Our experts are already working on troubleshooting.
            </p>
            {/* <a onClick={showHome} className="btn btn-primary mt-5">
            Go To Home
          </a> */}
            <a onClick={showHome} className="btn btn-primary mt-5">
              Go Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContextError;
