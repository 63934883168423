import React, { useContext, useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import "../../../../assets/styles/CardModal.css";
import ContextState from "../../../contextApi/ContextState";
import ModalError from "../../../error/ModalError";
import { BeatLoader } from "react-spinners";
import { useOutletContext, useParams } from "react-router-dom";
import { useMediaQuery, Typography } from "@mui/material";
import { RiAttachmentLine } from "react-icons/ri";
import { IoMdArrowDropleft } from "react-icons/io";
import { TimelineSkeleton } from "../../../Exporters/ExportFunctions";

export default function ProductTimeline({
  reloadTimelinePage,
  refreshBtn,
  // setRefreshBtn,
}) {
  const { data, color } = useOutletContext();

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const { productId } = data;
  // console.log("productID : ",productID)

  // const { productType, product_id } = useParams();
  // console.log("productType , product_id : ", productType, product_id, data);
  const [timelineData, setTimelineData] = useState(null);

  const {
    url,
    formatDate,
    fetchApi,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    modalControllerRef,
  } = useContext(ContextState);
  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  // resize observer

  const [tableWidthDiv, setTableWidthDiv] = useState(0);

  useEffect(() => {
    let resizeObserver;

    const waitForElement = () => {
      const tableWidthDivElement = document.querySelector(".tableWidthDiv");
      if (tableWidthDivElement) {
        // Create a ResizeObserver and observe the element
        resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setTableWidthDiv(entry.contentRect.width);
          }
        });
        resizeObserver.observe(tableWidthDivElement);
      } else {
        // Retry finding the element after 100ms
        setTimeout(waitForElement, 100);
      }
    };

    // Start the element check
    waitForElement();

    // Cleanup function
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect(); // Stop observing
      }
    };
  }, []);
  useEffect(() => {
    showLoadingModal(true);
    document.title = `Timeline of Product ID#${productId} - NEO`;

    // setTimelineData(demoTimeLineData.timeline);
    let isMounted = true;
    const fetchCardData = async () => {
      try {
        let payload = {
          info_type: "timeline",
          product_id: productId,
        };

        // if (refreshBtn) {
        //   setRefreshLoading(true);
        // }

        let jsonData;
        if (isMounted) {
          jsonData = await fetchApi(
            "get_product_info",
            "POST",
            payload,
            true,
            1
          );

          // console.log("timeline data recieved successfully:", jsonData)
          if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
            setTimelineData(jsonData?.data?.timeline);
            showModalErrorPage(null, null);
          } else if (
            jsonData.statusCode === 500 ||
            jsonData.statusCode === 401
          ) {
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
          } else {
            // showModalErrorPage(jsonData.statusCode, jsonData.msg);
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
          }
        }
        showLoadingModal(false);
      } catch (error) {
        if (modalControllerRef.current) {
          // openSnackbar("Request Timed Out!!!", "warning");
          console.log("fetch abort in timeline ... ");
        } else {
          openSnackbar("Some Error Occured!", "error");
        }
      } finally {
        // setRefreshLoading(false);
        // setRefreshBtn(false);
      }
    };

    fetchCardData();
    // showLoadingModal(false);
    return () => {
      isMounted = false;
    };
  }, []);

  const getDotColor = (eventType) => {
    switch (eventType) {
      case "creation":
        return "red";
      case "resolved":
        return "green";
      default:
        return color.secondary;
    }
  };

  return (
    <>
      {(lodingModalVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "absolute", height: "100%", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <TimelineSkeleton isSmallSmallScreen={isSmallSmallScreen} />
      )) ||
        (!showModalError.status && !lodingModalVisiblity && (
          <div className="card-Div tableWidthDiv" style={{ minHeight: "50vh" }}>
            {" "}
            <Timeline
              sx={{
                // [`& .${timelineOppositeContentClasses.root}`]: {
                //   flex: 1, // Adjust spacing for better layout
                // },
                "& .MuiTimelineItem-root": {
                  margin: "1px 0px", // Spacing between timeline items
                },
                "& .MuiTimelineDot-root": {
                  width: "16px", // Bigger dots
                  height: "16px",
                  borderWidth: "2px",
                  // borderColor: "#1976d2", // Border color for dots
                  // borderColor: color.taxtHeadingLight, // Border color for dots
                  boxShadow: "0px 0px  4px 0px " + color.taxtHeadingLight,
                },
                "& .MuiTimelineConnector-root": {
                  // backgroundColor: "#1976d2", // Line color
                  backgroundColor: color.secondary, // Line color
                  width: "4px", // Thicker lines
                },
              }}
              position="right"
              className="mt-4"
            >
              {timelineData?.map((item, index) => (
                <TimelineItem key={index} className="timeline-page">
                  <TimelineOppositeContent
                    color="textSecondary"
                    style={{
                      fontFamily: "Open-Sans-regular",
                      color: "rgba(146, 146, 146, 0.88)",
                      padding: "10px 16px",
                      fontSize: "14px",
                      // padding: "0px 10px",
                      // width:"200px",
                      flex: "0 0 250px",
                      display: (tableWidthDiv < 800 && "none") || "block",
                    }}
                  >
                    {item.creation_time && (
                      <Typography component="span" className="my-4">
                        {formatDate(item.creation_time)}
                      </Typography>
                    )}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      style={{
                        backgroundColor:
                          (index === 0 && "red") || color.secondary,
                        // padding: "3px",
                        marginTop: "12px",
                      }}
                    />
                    {/* {index < timelineData.length - 1 && <TimelineConnector />} */}
                    <TimelineConnector
                      style={{
                        backgroundColor:
                          (index === timelineData.length - 1 &&
                            color.textWhiteColor) ||
                          color.secondary,
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent
                    style={{
                      fontFamily: "Open-Sans-regular",
                      color: "rgba(76, 78, 100, 0.88)",
                      fontSize: "16px",
                      wordBreak: "break-word",
                      paddingTop: "4px",
                    }}
                  >
                    <Typography variant="h6" component="span">
                      {item.event}
                    </Typography>
                    {item.creation_time && (
                      <Typography
                        color="textSecondary"
                        style={{
                          fontFamily: "Open-Sans-regular",
                          color: color.taxtHeadingLight,
                          // padding: "6px 8px",
                          fontSize: "14px",
                          textAlign: "right",
                          display: (tableWidthDiv >= 800 && "none") || "block",
                        }}
                        component="div"
                      >
                        {formatDate(item.creation_time)}
                      </Typography>
                    )}
                    {/* new Time line  */}
                    {Object.values({
                      0: {
                        name: "checklist",
                        data: item.checklist_data,
                        show: item.checklist,
                      },
                      1: {
                        name: "assignee",
                        data: item.assignee_data,
                        show: item.assignee,
                      },
                      2: {
                        name: "attachment",
                        data: item.attachment_data,
                        show: item.attachments,
                      },
                      3: {
                        name: "comment",
                        data: item.comment_data,
                        show: item.comment,
                      },
                    }).map(
                      (dict_item, index) =>
                        dict_item.show && (
                          <Typography className="my-3">
                            <Typography className="card-Div">
                              <Typography variant="h6" component="span">
                                {dict_item.name}
                              </Typography>

                              <Timeline
                                sx={{
                                  // [`& .${timelineOppositeContentClasses.root}`]: {
                                  //   flex: 0.3, // Adjust spacing for better layout
                                  // },
                                  display: "flex", // Ensure it's using flexbox
                                  flexDirection: "column", // Stack the items vertically
                                  alignItems: "flex-start", // Left-align the items
                                  "& .MuiTimelineItem-root": {
                                    margin: "0px 0px", // Spacing between timeline items
                                  },
                                  "& .MuiTimelineDot-root": {
                                    width: "16px", // Bigger dots
                                    height: "16px",
                                    borderWidth: "2px",
                                    // borderColor: "#1976d2", // Border color for dots
                                    borderColor: color.secondary,
                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                  },
                                  "& .MuiTimelineConnector-root": {
                                    backgroundColor: color.secondary, // Line color
                                    width: "4px", // Thicker lines
                                  },
                                }}
                              >
                                {dict_item.data.map((checkpoint, idx) => (
                                  <TimelineItem style={{ width: "100%" }}>
                                    <TimelineOppositeContent
                                      style={{ display: "none" }}
                                    />
                                    <TimelineSeparator
                                      style={{ marginRight: "20px" }}
                                    >
                                      {/* <TimelineConnector /> */}
                                      <TimelineDot
                                        style={{
                                          backgroundColor: color.primary,
                                          borderColor:
                                            idx === 0
                                              ? color.red
                                              : color.secondary,
                                          // padding: "3px",
                                          // marginTop: "12px",
                                        }}
                                      />
                                      <TimelineConnector
                                        style={{
                                          backgroundColor:
                                            item?.checklistData?.length - 1 ===
                                            idx
                                              ? "white"
                                              : color.secondary,
                                        }}
                                      />
                                    </TimelineSeparator>
                                    <TimelineContent
                                      className=" position-relative "
                                      style={{
                                        // padding: "8px",
                                        margin: "8px 0px",
                                        marginTop: "10px",
                                        boxShadow:
                                          "-2px 2px 2px 0px " +
                                          color.taxtHeadingLight.slice(0, -2) +
                                          "22",
                                        borderRadius: "4px",
                                        // borderLeft:"1px solid gray",
                                        // borderBottom:"1px solid gray"
                                      }}
                                    >
                                      <Typography variant="h6" component="span">
                                        {checkpoint.event}
                                      </Typography>
                                      <Typography
                                        component="span"
                                        className="position-absolute"
                                        style={{
                                          top: "-15px",
                                          left: "-30px",
                                        }}
                                      >
                                        <IoMdArrowDropleft
                                          style={{
                                            fontSize: "50px",
                                            color: color.textWhiteColor,
                                            filter: `drop-shadow(-2px 2px 1px ${
                                              color.taxtHeadingLight.slice(
                                                0,
                                                -2
                                              ) + "44"
                                            })`,
                                          }}
                                        />
                                      </Typography>
                                      <Typography
                                        color="textSecondary"
                                        style={{
                                          fontFamily: "Open-Sans-regular",
                                          color: color.taxtHeadingLight,
                                          // padding: "6px 8px",
                                          fontSize: "14px",
                                          textAlign: "right",
                                        }}
                                        component="div"
                                      >
                                        {formatDate(checkpoint.creation_time)}
                                      </Typography>
                                      {/* {checkpoint.attachment_data &&
                                    checkpoint.attachment_data.length !== 0 && (
                                      <Typography
                                        className=""
                                        sx={{
                                          display: "flex", // Use flexbox to align items
                                          alignItems: "center", // Vertically align items
                                          justifyContent: "flex-start", // Horizontally align items
                                          gap: "2px", // Add some spacing between elements
                                          // textAlign: "center", // Center-align the text
                                          fontFamily: "Open-Sans-regular",
                                          color: color.tableRowHeading,
                                          fontWeight: 500,
                                          cursor: "pointer",
                                          fontSize: "0.85rem",
                                          "&:hover": {
                                            fontWeight: 600,
                                          },
                                        }}
                                      >
                                        {checkpoint.attachment_data.length}
                                        <RiAttachmentLine
                                          style={{ color: color.secondary }}
                                        />{" "}
                                        Attachment
                                      </Typography>
                                    )} */}
                                    </TimelineContent>
                                  </TimelineItem>
                                ))}
                              </Timeline>
                            </Typography>
                          </Typography>
                        )
                    )}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
}
