import React, { useContext, useEffect, useState } from "react";
import { getSellerDetails } from "./GeneralDetails";
import { formattingString } from "../../analytics/DataPull";
import { DisplaySellerDetails } from "./GeneralDetails";
import { getCalendarPickerSkeletonUtilityClass } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { color } from "../../modals/Calllog";
import ContextState from "../../contextApi/ContextState";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { FaPhoneAlt } from "react-icons/fa";
import { DisplayingProductDetailsTable } from "./DisplayingProductDetailsTable";
import { Tag } from "primereact/tag";
import { bytesToSize } from "../../RaiseTicketForm/FilesUpload";
import { Button } from "primereact/button";
import { FaFileCsv } from "react-icons/fa";
import ShowAttachments from "../../Attachment/ShowAttachments";
import ShowPreview from "../../Attachment/ShowPreview";
import { urlToFileArrayBuffer } from "../../Exporters/ExportFunctions";
import * as XLSX from "xlsx";
const Preview = (props) => {
  const {
    genFields,
    isSmallScreen,
    handleCheckBox,
    isCheckBox,
    isMediumScreen,
    islageScreen,
    setGenFields,
  } = props;
  const { formatInIndianRupees, openSnackbar } = useContext(ContextState);

  const [sheetData, setSheetData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [preview, setPreview] = useState({
    fileName: "",
    fileUrl: "",
    type: "",
    fileType: "",
    fileData: null,
  });

  // to load the button while download or preview from backend
  const [dataLoading, setDataLoading] = useState({
    load: false,
    file_name: "",
  });
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  // const handleButtonClick = (value) => {
  //   setTagButton(value);
  // };

  const handleClosePreview = () => {
    setShowModal(false);
    setSheetData(null);
    setPreview({
      fileName: "",
      fileUrl: "",
      type: "",
      fileType: "",
      fileData: null,
    });
  };

  const handleFilePreview = async (attachment) => {
    setDataLoading((prev) => ({
      ...prev,
      load: true,
      file_name: file_name,
    }));
    const { file_name, type, fileType, blobData } = attachment;
    // Generate the base64 string from blobData (object URL)
    const arrayBuffer = await urlToFileArrayBuffer(blobData);
    const fileData = new Uint8Array(arrayBuffer);
    const base64String = arrayToBase64(fileData);

    let fileUrl = "";
    if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
      fileUrl = `data:image/${fileType};base64,${base64String}`;
    } else if (fileType === "pdf") {
      fileUrl = `data:application/pdf;base64,${base64String}`;
    } else if (["xlsx", "xls"].includes(fileType)) {
      fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
    } else if (fileType === "csv") {
      fileUrl = `data:text/csv;base64,${base64String}`;
    } else if (fileType === "mp3") {
      fileUrl = `data:audio/mp3;base64,${base64String}`;
    } else {
      return null;
    }
    setPreview({
      fileName: file_name,
      fileUrl: fileUrl,
      type: type,
      fileType: fileType,
      fileData: base64String,
    });
    if (["xlsx", "xls", "csv"].includes(fileType)) {
      const binaryStr = window.atob(base64String);
      const binaryData = new Uint8Array(
        [...binaryStr].map((char) => char.charCodeAt(0))
      );
      const workbook = XLSX.read(binaryData, { type: "array" });

      const sheetName = workbook.SheetNames[0]; // Get the first sheet
      const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
      const sheetJson = XLSX.utils.sheet_to_json(worksheet, {
        header: 1, // Keep as an array of arrays
        defval: "-", // Fill empty cells with placeholder
      });

      // Store the first 100 rows of the Excel sheet in sheetData
      setSheetData(sheetJson.slice(0, 100));
    } else {
      setShowModal(true);
    }
    setDataLoading((prev) => ({
      ...prev,
      load: false,
      file_name: "",
    }));
    // console.log("preview data", preview, "sheet data:", sheetData);
  };

  const handleDeleteBtn = (attachment) => {
    // console.log("tag attchment clciked:", attachment, modalOpen);
    const { id, tag, blobData } = attachment; // Extract `id` and `tag` from attachment
    setGenFields((prev) => {
      const updatedAttachments = prev.attachments.filter(
        (item) => item.objectURL !== blobData
      ); // Filter out the attachment with the specified `blobData`

      // console.log("updatedAttachments : ", updatedAttachments);

      if (updatedAttachments.length === 0) {
        openSnackbar("Please ensure at least one attachment is included.");
        return prev;
      } else {
        return {
          ...prev,
          attachments: updatedAttachments,
        };
      }
    });
  };

  const handleDownloadBtn = (attachment) => {
    // Logic to handle file download
    const link = document.createElement("a");
    link.href = attachment.blobData; // Use objectURL for download
    link.download = attachment.file_name;
    link.click();
  };

  const arrayToBase64 = (array) => {
    const chunkSize = 10000; // Adjust chunk size based on your needs
    let base64String = "";

    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
  };

  const loadAttachments = async () => {
    if (genFields.attachments.length > 0) {
      try {
        // Resolve all promises in the mapped array to get the final list
        const temp_list = await Promise.all(
          genFields.attachments.map(async (element, index) => {
            let fileType = element.type.split("/")[1];
            // Handle the case for xlsx type and mp3 files
            if (
              fileType ===
              "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
              fileType = "xlsx";
            } else if (fileType === "mpeg") {
              fileType = "mp3";
            }

            return {
              id: index,
              file_name: element.name,
              type:
                (["png", "jpg", "jpeg", "webp"].includes(fileType) && "img") ||
                (["xlsx", "xls", "csv"].includes(fileType) && "excel") ||
                (fileType === "mp3" && "audio") ||
                fileType,
              fileType: fileType,
              blobData: element.objectURL,
            };
          })
        );
        setSelectedAttachments((prev) => temp_list);
      } catch (error) {
        console.error("Error adding attachment:", error);
      }
    }
  };
  useEffect(() => {
    loadAttachments();
  }, [genFields]);

  return (
    <div className="m-1">
      {true && (
        <div>
          {/* lot details */}
          {genFields.sellerDetails.sellerName !== "" ? (
            <div className="my-3 mt-1 pb-1">
              <div className="card-Div p-3">
                <div
                  className="bar-chart-heading mb-2"
                  style={{
                    color: color.taxtHeading,
                    lineHeight: "1.2",
                    fontSize: "1rem",
                  }}
                >
                  Lot Details
                </div>
                <DisplaySellerDetails
                  isSmallScreen={isSmallScreen}
                  isMediumScreen={isMediumScreen}
                  islageScreen={islageScreen}
                  isAcknowledgment={true}
                  genFields={genFields}
                />
              </div>
            </div>
          ) : null}

          {/* seller details */}
          {genFields.sellerDetails.sellerName !== "" ? (
            <div className="card-Div p-3 ">
              <div
                className="bar-chart-heading mb-2"
                style={{
                  color: color.taxtHeading,
                  lineHeight: "1.2",
                  fontSize: "1rem",
                }}
              >
                Seller Details
              </div>
              <DisplaySellerDetails
                isSmallScreen={isSmallScreen}
                genFields={genFields}
                isMediumScreen={isMediumScreen}
                islageScreen={islageScreen}
              />
            </div>
          ) : null}

          {/* product details */}

          {genFields.productDetails.length > 0 ? (
            <div className="my-0  py-1">
              <DisplayingProductDetailsTable
                genFields={genFields}
                isReview={true}
                tableHeader={"Product Details"}
                // isSerialNumber={true}
              />
            </div>
          ) : null}

          {/* serial numbers */}
          {genFields.productDetails.length > 0 ? (
            <DisplayingProductDetailsTable
              genFields={genFields}
              isReview={true}
              tableHeader={"Product Serial Numbers"}
              isSerialNumber={true}
            />
          ) : null}

          {genFields.attachments.length > 0 && (
            <div className="mt-2 pt-3">
              <div className="card-Div p-3">
                <div
                  className="bar-chart-heading mb-2"
                  style={{
                    color: color.taxtHeading,
                    lineHeight: "1.2",
                    fontSize: "1rem",
                  }}
                >
                  Attachments
                </div>
                <div
                  className=""
                  // style={{
                  //   padding: "18px 20px",
                  //   margin: isSmallScreen ? "20px 4px 4px" : "4px",
                  // }}
                >
                  {/* attachments */}
                  {selectedAttachments && (
                    <div className="px-1 py-2">
                      <ShowAttachments
                        attachments={selectedAttachments}
                        handleFilePreview={handleFilePreview}
                        handleDeleteBtn={handleDeleteBtn}
                        isSmallScreen={isSmallScreen}
                        showDownload={false}
                        dataLoading={dataLoading}
                        isDataWithTag={false}
                        color={color}
                      />
                    </div>
                  )}

                  {(showModal || sheetData) && (
                    <div>
                      <ShowPreview
                        showModal={showModal}
                        handleClosePreview={handleClosePreview}
                        sheetData={sheetData}
                        previewData={preview}
                        isSmallScreen={isSmallScreen}
                        color={color}
                      />
                    </div>
                  )}
                  {false &&
                    genFields.attachments.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          width: "125px",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "125px",
                            height: "125px",
                            overflow: "hidden",
                            borderRadius: "5px",
                          }}
                        >
                          {file.name.endsWith(".pdf") ? (
                            <embed
                              alt={file.name}
                              role="presentation"
                              src={file.objectURL}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover" }}
                            />
                          ) : file.name.endsWith(".csv") ||
                            file.name.endsWith(".xlsx") ? (
                            <FaFileCsv
                              style={{ height: "125px", width: "125px" }}
                            />
                          ) : (
                            <img
                              alt={file.name}
                              role="presentation"
                              src={file.objectURL}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </div>

                        <Tooltip title={file.name} arrow>
                          <div
                            style={{
                              width: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              paddingTop: "8px",
                            }}
                          >
                            {file.name}
                          </div>
                        </Tooltip>

                        <Tag
                          severity="warning"
                          className="px-3 py-2 mt-2"
                          style={{ maxWidth: "125px", textAlign: "center" }}
                          value={bytesToSize(file.size)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {false && (
        <div class="accordion" id="accordionExample">
          {/* General Details */}
          <div class="accordion-item accordion-item_lotdetails">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                General Details
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {genFields.sellerDetails.sellerName !== "" ? (
                  <div>
                    <DisplaySellerDetails
                      // sellerName={genFields.sellerDetails.sellerName}
                      isSmallScreen={isSmallScreen}
                      isAcknowledgment={true}
                      genFields={genFields}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Seller Details */}
          <div class="accordion-item accordion-item_lotdetails">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Seller Details
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {genFields.sellerDetails.sellerName !== "" ? (
                  <div>
                    <DisplaySellerDetails
                      isSmallScreen={isSmallScreen}
                      genFields={genFields}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Product Type */}
          <div class="accordion-item accordion-item_lotdetails">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Product Type
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {genFields.productDetails.length > 0 ? (
                  <div style={{ margin: "16px 5px" }}>
                    <DisplayingProductDetailsTable genFields={genFields} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Product Serial Numbers */}
          <div class="accordion-item accordion-item_lotdetails">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Product Serial Numbers
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {genFields.productDetails.length > 0 ? (
                  <div style={{ margin: "16px 5px" }}>
                    <DisplayingProductDetailsTable
                      genFields={genFields}
                      isSmallScreen={isSmallScreen}
                      isSerialNumber={true}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* attachments */}

          <div class="accordion-item accordion-item_lotdetails">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Attachments
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {genFields.attachments.length > 0 && (
                  <div>
                    <div
                      className="d-flex flex-wrap gap-3 p-3 mt-3"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                        padding: "18px 20px",
                        margin: isSmallScreen ? "20px 4px 4px" : "4px",
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                        margin: "4px",
                      }}
                    >
                      {genFields.attachments.map((file, index) => (
                        <div
                          style={{
                            // marginBottom: "25px",
                            // width: "100%",
                            // // minWidth: "300px",
                            // display: "flex",
                            // flexWrap: "wrap",
                            // alignItems: "center",
                            width: "125px",
                            borderRadius: "5px",
                            // height: "160px",
                          }}
                        >
                          <div>
                            {file.name.endsWith(".pdf") ? (
                              <embed
                                alt={file.name}
                                role="presentation"
                                src={file.objectURL}
                                width={125}
                                // height={172}
                                style={
                                  {
                                    // objectFit: "contain",
                                  }
                                }
                              />
                            ) : file.name.endsWith(".csv") ? (
                              <FaFileCsv
                                style={{ height: "172px", width: "125px" }}
                              />
                            ) : (
                              <img
                                alt={file.name}
                                role="presentation"
                                src={file.objectURL}
                                width={125}
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                  minHeight: "172px",
                                }}
                              />
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                // marginLeft: "8px",
                              }}
                            >
                              {file.name}
                              <Tag
                                severity="warning"
                                className="px-3 py-2 mt-2"
                                style={{ maxWidth: "150px" }}
                                value={bytesToSize(file.size)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="form-check mt-3 ms-1 ">
        <input
          class="form-check-input"
          type="checkbox"
          value={isCheckBox}
          onChange={(e) => {
            handleCheckBox(e.target.checked);
          }}
          id="flexCheckDefault"
        />
        <label class="form-check-label" for="flexCheckDefault">
          I confirm that the entered details are correct.
        </label>
      </div>
    </div>
  );
};

export default Preview;

//   {/* General Detaisl */}
//   <div
//   style={{
//     borderRadius: "10px",
//     backgroundColor: "#ffffff",
//     padding: "18px 20px",
//     margin: isSmallScreen ? "20px 4px 4px" : "4px",
//     boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
//   }}
// >
//   <p className="fs-6 mb-2">General Details</p>

//   {genFields.sellerDetails.sellerName !== "" ? (
//     <div>
//       <DisplaySellerDetails
//         // sellerName={genFields.sellerDetails.sellerName}
//         isSmallScreen={isSmallScreen}
//         isAcknowledgment={true}
//         genFields={genFields}
//       />
//     </div>
//   ) : null}
// </div>
// <div
//   style={{
//     borderRadius: "10px",
//     backgroundColor: "#ffffff",
//     padding: "18px 20px",
//     margin: isSmallScreen ? "20px 4px 4px" : "18px 4px 4px 4px",
//     boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
//   }}
// >
//   <p className="fs-6 mb-2">Seller Details</p>

//   {genFields.sellerDetails.sellerName !== "" ? (
//     <div>
//       <DisplaySellerDetails
//         // sellerName={genFields.sellerDetails.sellerName}
//         isSmallScreen={isSmallScreen}
//         // isAcknowledgment={true}
//         genFields={genFields}
//       />
//     </div>
//   ) : null}
// </div>

// {/* Product details Data grid table  */}
// {genFields.productDetails.length > 0 ? (
//   <div className="m-1">
//     <DisplayingProductDetailsTable genFields={genFields} />
//   </div>
// ) : null}

// {/* data grid table serial numbers */}

// {genFields.productDetails.length > 0 ? (
//   <div className="m-1 my-2">
//     <DisplayingProductDetailsTable
//       genFields={genFields}
//       isSmallScreen={isSmallScreen}
//       isSerialNumber={true}
//     />
//   </div>
// ) : null}

// {/* attachmenst */}
// {genFields.attachments.length > 0 && (
//   <div>
//     <div
//       className="d-flex flex-wrap gap-3 p-3 mt-3"
//       style={{
//         borderRadius: "10px",
//         backgroundColor: "#ffffff",
//         padding: "18px 20px",
//         margin: isSmallScreen ? "20px 4px 4px" : "4px",
//         boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
//         margin: "4px",
//       }}
//     >
//       {genFields.attachments.map((file, index) => (
//         <div
//           style={{
//             // marginBottom: "25px",
//             // width: "100%",
//             // // minWidth: "300px",
//             // display: "flex",
//             // flexWrap: "wrap",
//             // alignItems: "center",
//             width: "125px",
//             borderRadius: "5px",
//             // height: "160px",
//           }}
//         >
//           <div>
//             {file.name.endsWith(".pdf") ? (
//               <embed
//                 alt={file.name}
//                 role="presentation"
//                 src={file.objectURL}
//                 width={125}
//                 // height={172}
//                 style={
//                   {
//                     // objectFit: "contain",
//                   }
//                 }
//               />
//             ) : file.name.endsWith(".csv") ? (
//               <FaFileCsv style={{ height: "172px", width: "125px" }} />
//             ) : (
//               <img
//                 alt={file.name}
//                 role="presentation"
//                 src={file.objectURL}
//                 width={125}
//                 style={{
//                   objectFit: "cover",
//                   borderRadius: "5px",
//                 }}
//               />
//             )}
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 // marginLeft: "8px",
//               }}
//             >
//               {file.name}
//               <Tag
//                 severity="warning"
//                 className="px-3 py-2 mt-2"
//                 style={{ maxWidth: "150px" }}
//                 value={bytesToSize(file.size)}
//               />
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   </div>
// )}
