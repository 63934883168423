import React, { useContext, useState } from "react";
import "../../../assets/styles/AllTickets.css";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ContextState from "../../contextApi/ContextState";
import { LoadingButton } from "@mui/lab";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip as BootstrapTooltip } from "react-bootstrap";
import { CiExport } from "react-icons/ci";
import ToolTip from "react-bootstrap/Tooltip";
import { Box, Tooltip as MuiTooltip, useMediaQuery } from "@mui/material";
import { color } from "../../modals/Calllog";
import cardViewImg from "../../../assets/images/grid (1).png";
import tableViewImg from "../../../assets/images/list.png";

import cardViewWhiteImg from "../../../assets/images/grid_view_white.png";
import tableViewWhiteImg from "../../../assets/images/table_view_white.png";

import { FaPlus } from "react-icons/fa";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { camelCaseToTitleCase } from "../AllLots";
import ShowSeller from "./ShowSeller";
import EditSellerDetailsModal from "./EditSellerDetailsModal";
import SellerDetailsMainModal from "./SellerModals/SellerDetailsMainModal";

function MyVerticallyCenteredModal(props) {
  const { lotId, reloadData } = props;

  const [deleteRemark, setDeleteRemark] = useState("");
  const { openSnackbar, fetchApi } = useContext(ContextState);
  const [deleteBtnLoading, setdeleteBtnLoading] = useState(false);

  const handleDelete = async () => {
    // openSnackbar("Ticket deleted succesfully", "success");
    let isMounted = true;
    const payload = {
      seller_id: lotId,
      reason: deleteRemark,
    };
    // console.log("payload:", payload);
    setdeleteBtnLoading(true);
    if (deleteRemark.trim() !== "") {
      try {
        const response = await fetchApi(
          "delete_seller",
          "POST",
          payload,
          isMounted
        );

        // // Check if the response is successful
        if (response.statusCode === 200) {
          // Reload the React component
          console.log("visit info deleted succesfully");
          await reloadData();
          setDeleteRemark("");
          openSnackbar(response.data.message, "success");
          // openSnackbar(`Ticket ${deleteRemark} deleted succesfully`, "success");
        } else if (response.statusCode === 403) {
          openSnackbar(response.msg, "error");
        } else {
          openSnackbar(response.msg, "error");
        }
      } catch (error) {
        openSnackbar("some error occured while saving details", "error");
        // console.error("Some Error occured:", error);
      } finally {
        setdeleteBtnLoading(false);
      }
      props.onHide();
    } else {
      openSnackbar("Remark cannot be empty", "warning");

      setdeleteBtnLoading(false);
    }

    return () => {
      isMounted = false;
    };
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="delete-modal-header">
        <Modal.Title id="contained-modal-title-vcenter text-danger">
          {/* Delete Ticket {props.ticketnum} */}
          <div className="fs-5">
            Delete Seller Id <span style={{ color: "red" }}> #{lotId}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="delete-modal-body">
        <p className="mt-0">
          This action will remove all of your Seller information. This cannot be
          undone.
        </p>
        <span>Add Reason: </span>
        <InputGroup>
          {/* <InputGroup.Text>
            <p>Add Remark</p>
          </InputGroup.Text> */}

          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            // style={{ minHeight: "75px", maxHeight: "300px" }}
            value={deleteRemark} // Controlled component
            onChange={(e) => setDeleteRemark(e.target.value)} // Update deleteRemark state on input change
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleDelete}>Delete Ticket</Button> */}
        <LoadingButton
          loading={deleteBtnLoading}
          loadingPosition="end"
          size="large"
          onClick={handleDelete}
          disabled={deleteRemark.length === 0}
          variant="contained"
          style={{
            padding: "5px 10px",
            fontSize: "0.85rem",
            textTransform: "capitalize",
          }}
        >
          <span className={deleteBtnLoading ? "me-4" : ""}>Delete</span>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
}

function Seller({
  ticketData,
  currentPage,
  handelCurrentPage,
  cardsPerPage,
  totalCardsCount,
  reloadData,
  onExportLocal,
  RDMData,
  handleExportDataListChange,
  ExportDataList,
  SwitchView,
  handelSwitchView,
}) {
  const navigate = useNavigate();

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  const { formatDate } = useContext(ContextState);

  const [selectView, setSelectView] = useState(0);

  const isLargeScreen = useMediaQuery("(max-width:1400px)");

  const [deleteModal, setdeleteModal] = useState({
    show: false,
    lot_id: null,
  });

  const rowHeight = 64; // Default row height in DataGrid
  const headerHeight = 135; // Default header height in DataGrid
  const maxRows = 5; // Maximum number of rows per page
  const visibleRows = Math.max(
    ticketData?.table_data?.rows?.length || 0,
    maxRows
  );

  const [sellerModalState, setSellerModalState] = useState({
    isOpen: false,
    sellerId: "",
    // sellerName: "",
    // contacted_person: "",
    // phone: "",
    // email: "",
    // address: "",
  });

  const handelDeleteModal = (show, lot_id) => {
    setdeleteModal((prevSate) => {
      return { show: show, lot_id: lot_id };
    });
  };

  const handleOpenEditModal = (rowData) => {
    setSellerModalState((prev) => ({
      ...prev,
      isOpen: true,
      sellerId: rowData.sellerId,
      // sellerName: rowData.sellerName,
      // contacted_person: rowData.person,
      // phone: rowData.phone,
      // email: rowData.email,
      // address: rowData.address,
    }));
  };

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      headers.push({
        field: "expand",
        headerName: "",
        width: 50,
        editable: false,
        sortable: false, // Disable sorting for this column
        filterable: false, // Disable filtering for this column
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                textWrap: "nowrap",
              }}
            >
              {
                <MuiTooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        More Details
                      </div>
                    </>
                  }
                  arrow
                >
                  <span
                    style={{
                      // border: "2px solid blue",
                      padding: "3px 9px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      textTransform: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // console.log(params.row);
                      if (params.row) {
                        handleOpenEditModal(params.row);
                      }
                    }}
                  >
                    <FaPlus />
                  </span>
                </MuiTooltip>
              }
            </Box>
          );
        },
      });
      data.column.forEach((item, index) => {
        if (!["region", "district", "location"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex:
              !isLargeScreen &&
              !["sellerId", "phone", "creation_time"].includes(item.field),
            headerAlign: ![0, 1].includes(index) ? "center" : "left",
            renderHeader: (params) => (
              <MuiTooltip
                title={`${item.field
                  .replace(/_/g, " ")
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}`}
                arrow
              >
                <span
                  style={{
                    paddingLeft: ([0, 1].includes(index) && "10px") || "0px",
                  }}
                >
                  {params.colDef.headerName}
                </span>
              </MuiTooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              return (
                <Box
                  sx={{
                    // width: !index ? 400 : 150, // Adjust width as needed
                    //   marginLeft: "5px", // Add margin to separate text from progress bar
                    //   fontWeight: "bold", // Customize font weight if needed
                    textAlign: ![0, 1].includes(index) ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight:
                      (item.field === "status" && "600") ||
                      (index !== 0 && "500") ||
                      "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color:
                      (item.field === "status" &&
                        ((params.value === "pending" && color.orange) ||
                          (params.value === "ongoing" && color.option_4) ||
                          (params.value === "done" && color.greenToShow))) ||
                      color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <MuiTooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params.field.split("_").join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform:
                                    (item.field === "status" && "capitalize") ||
                                    "none",
                                }}
                              >
                                {(item.field === "arrivalDate" &&
                                  formatDate(params.value)) ||
                                  params.value}
                              </strong>
                            </div>
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding:
                            (params.value === "pending" && "4px 9px") ||
                            (params.value === "ongoing" && "4px 8px") ||
                            (params.value === "done" && "4px 20px") ||
                            "4px 9px",
                          borderRadius: "4px",
                          backgroundColor:
                            (item.field === "status" &&
                              ((params.value === "pending" &&
                                color.iconBackgroundorange) ||
                                (params.value === "ongoing" &&
                                  color.option_4_bg) ||
                                (params.value === "done" &&
                                  color.iconBackgroundgreen))) ||
                            "transparent",
                          textTransform:
                            (["status", "reason"].includes(item.field) &&
                              "capitalize") ||
                            "none",
                        }}
                      >
                        {((item.field === "arrivalDate" ||
                          item.field === "creationDate") &&
                          formatDate(params.value).slice(0, -8)) ||
                          params.value}
                      </span>
                    </MuiTooltip>
                  }
                </Box>
              );
            },
          });
        }
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }
  const { TableDataHeaders, TabelDataRows } = (ticketData?.table_data &&
    ticketData?.table_data?.rows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: ticketData?.table_data?.rows || [],
      column: ticketData?.table_data?.columns || [],
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Showing <strong>All Seller </strong> Details.
    </ToolTip>
  );

  const handleCloseSellerModal = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    } else {
      // resetting the state
      setSellerModalState((prev) => ({
        ...prev,
        isOpen: false,
        sellerId: "",
        // sellerName: "",
        // address: "",
        // contacted_person: "",
        // phone: "",
        // email: "",
      }));
    }
  };
  return (
    <>
      {ticketData ? (
        <>
          <div className="container row">
            {/* heading and list details */}
            <div
              className="upper-heading-container p-0 px-1 pt-2"
              style={{ marginBottom: "15px" }}
            >
              {/* Document info */}
              <div className="info-part">
                <span className="px-2 mb-1 info-heading">Seller Details</span>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <i
                    className="bi bi-info-circle icon-color px-0"
                    id="info-icon"
                    style={{ fontSize: "15px", lineHeight: "24px" }}
                  ></i>
                </OverlayTrigger>
              </div>
              {ExportDataList && (
                <div className="d-flex flex-row ms-auto align-items-center display-inline">
                  {/* Document details */}
                  <div
                    className="show-range ms-auto"
                    style={{ alignSelf: "end" }}
                  >
                    {(selectView === 0 && ticketData?.count_heading) ||
                      (selectView !== 0 && RDMData?.count_heading)}
                    {/* export details for basic data && more data */}
                  </div>
                  <div
                    className="d-flex flex-row  mx-2 mb-1"
                    style={{
                      borderRadius: "6px",
                      boxShadow: "0px 0px 1px 1px" + color.taxtHeadingLight,
                    }}
                  >
                    <div
                      className="d-flex  align-item-center justifty-content-center"
                      style={{
                        backgroundColor:
                          (SwitchView === 0 && "#2d97d4") || "inherit",
                        borderRadius: "4px",
                        boxShadow:
                          (SwitchView === 0 &&
                            "0px 0px 1px 1px" + color.taxtHeadingLight) ||
                          "none",
                        cursor: "pointer",
                        transition:
                          "background-color 0.3s ease, box-shadow 0.3s ease",
                        padding: "7px",
                      }}
                      onClick={() => {
                        handelSwitchView(0);
                      }}
                    >
                      {SwitchView === 1 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Card View"}
                          arrow={true}
                        >
                          <img
                            src={cardViewImg}
                            alt="card_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                      {SwitchView === 0 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Card View"}
                          arrow={true}
                        >
                          <img
                            src={cardViewWhiteImg}
                            alt="card_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                    </div>
                    <div
                      className="d-flex align-item-center justifty-content-center"
                      style={{
                        backgroundColor:
                          (SwitchView === 1 && "#2d97d4") || "inherit",
                        borderRadius: "4px",
                        boxShadow:
                          (SwitchView === 1 &&
                            "0px 0px 1px 1px" + color.taxtHeadingLight) ||
                          "none",
                        cursor: "pointer",
                        transition:
                          "background-color 0.3s ease, box-shadow 0.3s ease",
                        padding: "7px",
                      }}
                      onClick={() => {
                        handelSwitchView(1);
                      }}
                    >
                      {SwitchView === 1 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Table View"}
                          arrow={true}
                          style={{ height: "10px" }}
                        >
                          <img
                            src={tableViewWhiteImg}
                            alt="table_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                      {SwitchView === 0 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Table View"}
                          arrow={true}
                          style={{ height: "10px" }}
                        >
                          <img
                            src={tableViewImg}
                            alt="table_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                    </div>
                  </div>
                  {/* Document export data */}
                  <div
                    className="dropdow dropdown_export mx-2 ms-0"
                    style={{ cursor: "pointer" }}
                  >
                    <MuiTooltip
                      data-html="true"
                      title={"Export Data"}
                      arrow={true}
                      style={{ height: "15px", width: "15px" }}
                    >
                      <i
                        className="fa-solid fa-ellipsis-vertical  "
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          padding: "0px 3px",
                          color: color.taxtHeadingLight,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      ></i>
                    </MuiTooltip>
                    {/* Centered dropdown */}
                    {/* </button> */}
                    <ul
                      className="dropdown-menu dropdown-menu-end delete-ticket-btn-border"
                      style={{ padding: "4px 0px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          onExportLocal(ticketData?.count_heading || "");
                        }}
                      >
                        <a
                          className="dropdown-item text-center edit-ticket-btn"
                          href="#"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onExportLocal(ticketData?.count_heading || "");
                          }}
                        >
                          <span className="export_all_data_btn">
                            <CiExport className="me-2" />
                          </span>
                          Export Data
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

            {/* card view  */}
            {SwitchView === 0 && (
              <div className="cards-parent">
                {ticketData?.table_data &&
                  ticketData?.table_data?.rows?.map((ticket, index) => {
                    // const timeInfo = getTimeStr(ticket);
                    return (
                      <ShowSeller
                        key={index}
                        sellerData={ticket}
                        handleDeleteModal={handelDeleteModal}
                        handleOpenEditModal={handleOpenEditModal}
                      />
                    );
                  })}
              </div>
            )}

            {/* Data grid */}
            {SwitchView === 1 && (
              <div className={`m-0 p-0 ${selectView !== 0 && "mb-4"}`}>
                <div className="card-Div">
                  <Box
                    sx={{
                      height:
                        ticketData?.tickets_limit !== "All" ? "72vh" : "72vh",
                      // || gridHeight,
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      rows={TabelDataRows}
                      columns={TableDataHeaders}
                      density="standard"
                      paginationMode="client"
                      pageSize={101}
                      disableSelectionOnClick
                      disableRowSelectionOnClick
                      onRowClick={(param) => {
                        // console.log("row_param : ", param.row);
                        // if (param.row) {
                        //   handleOpenEditModal(param.row);
                        // }
                      }}
                      getRowClassName={(params) => {
                        // console.log(
                        //   "params : ",
                        //   params,
                        //   params.row.id === selectedRow?.id
                        // );
                        // return params.row.id === selectedRow?.id
                        //   ? "selected-row"
                        //   : "";
                      }}
                      sx={{
                        "&.MuiDataGrid-root": {
                          border: "none",
                          borderRadius: "5px", // Adjust border radius as needed
                          overflow: "hidden", // Ensure content respects the border radius,
                          padding: "0px",
                          margin: "0px",
                          marginTop: "-10px",
                        },
                        ".MuiDataGrid-columnHeader": {
                          backgroundColor: color.textWhiteColor,
                        },
                        ".MuiDataGrid-filler": {
                          backgroundColor: color.textWhiteColor,
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                          fontFamily: "Open-Sans-Light",
                          fontWeight: "bold",
                          padding: "5px 0px",
                          fontSize: "0.85rem",
                          textTransform: "capitalize",
                          color: color.taxtHeading,
                        },
                        ".MuiDataGrid-columnHeader--alignCenter": {
                          headerAlign: "left",
                        },
                        ".MuiDataGrid-row": {
                          border: "none", // Remove border from rows
                          backgroundColor: color.textWhiteColor, // Set rows background color to white
                          "&:hover": {
                            backgroundColor: color.primary, // Ensure background color remains white on hover
                            // cursor: "pointer",
                          },
                        },
                        ".MuiDataGrid-footerContainer": {
                          display:
                            (visibleRows <= 50 && "none") || "inline-block", // Hide the pagination footer
                          justifyContent: "space-between", // Space out selected row count and pagination controls
                          alignItems: "center", // Vertically align items in the footer
                          marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                          height: "30px",
                        },
                        ".MuiTablePagination-root": {
                          // ".MuiInputBase-root": {
                          //   display: "none",
                          // },

                          // ".MuiTablePagination-selectLabel": {
                          //   display: "none",
                          // },
                          ".MuiToolbar-root": {
                            marginTop: "0px",
                          },
                        },
                        ".MuiDataGrid-cell": {
                          outline: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        },
                        "&.selected-row": {
                          backgroundColor: "red",
                          "&:hover": {
                            backgroundColor: "red",
                          },
                        },
                        ".MuiDataGrid-selectedRowCount": {
                          display: "none",
                        },
                        ".MuiTablePagination-toolbar": {
                          padding: "2px 16px",
                          marginTop: "-15px",
                        },
                      }}
                    />
                  </Box>
                </div>
              </div>
            )}

            {/* react pagination */}
            {ticketData?.tickets_limit !== "All" && (
              <div className="p-1 py-2 m-0">
                <div className=" pagination-head-container p-0 m-0">
                  <ReactPaginate
                    pageCount={Math.ceil(totalCardsCount / cardsPerPage)}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={(e) => {
                      handelCurrentPage(e.selected);
                    }}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                    renderOnZeroPageCount={null}
                    forcePage={currentPage}
                  />
                </div>
              </div>
            )}

            {deleteModal?.show && (
              <MyVerticallyCenteredModal
                show={deleteModal.show}
                lotId={deleteModal.lot_id}
                onHide={() => handelDeleteModal(false)}
                reloadData={reloadData}
              />
            )}
          </div>
        </>
      ) : (
        <div className="container no-ticket-found-div text-center count_heading_div">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
            <p>{ticketData?.message || ""}</p>
          </div>
        </div>
      )}

      {/* edit seller details */}

      {sellerModalState.isOpen ? (
        <SellerDetailsMainModal
          data={sellerModalState}
          reloadData={reloadData}
          onClose={handleCloseSellerModal}
        />
      ) : null}
    </>
  );
}

export default Seller;
