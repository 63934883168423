import React, { useContext, useState } from "react";
import "../assets/styles/AllTickets.css";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ContextState from "./contextApi/ContextState";
import { LoadingButton } from "@mui/lab";
import ShowVisits from "./visits/ShowVisits";
import { CiExport } from "react-icons/ci";
import { Tooltip } from "@mui/material";
function MyVerticallyCenteredModal(props) {
  const [deleteRemark, setDeleteRemark] = useState("");
  const { openSnackbar, fetchApi } = useContext(ContextState);
  const [deleteBtnLoading, setdeleteBtnLoading] = useState(false);

  const handleDelete = async () => {
    // openSnackbar("Ticket deleted succesfully", "success");
    let isMounted = true;
    const payload = {
      ticket_id: props.ticketId,
      visit_id: props.ticketnum,
      reason: deleteRemark,
    };
    // console.log("payload:", payload);
    setdeleteBtnLoading(true);
    if (deleteRemark.trim() !== "") {
      try {
        const response = await fetchApi(
          "delete_visit_info",
          "POST",
          payload,
          isMounted
        );

        // // Check if the response is successful
        if (response.statusCode === 200) {
          // Reload the React component
          // console.log("visit info deleted succesfully");
          await props.reloadData();
          setDeleteRemark("");
          openSnackbar(response.data.message, "success");
          // openSnackbar(`Ticket ${deleteRemark} deleted succesfully`, "success");
        } else if (response.statusCode === 403) {
          openSnackbar(response.msg, "error");
        } else {
          openSnackbar(response.msg, "error");
        }
      } catch (error) {
        openSnackbar("some error occured while saving details", "error");
        // console.error("Some Error occured:", error);
      } finally {
        setdeleteBtnLoading(false);
      }
      props.onHide();
    } else {
      openSnackbar("Remark cannot be empty", "warning");

      setdeleteBtnLoading(false);
    }

    return () => {
      isMounted = false;
    };
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="delete-modal-header">
        <Modal.Title id="contained-modal-title-vcenter text-danger">
          {/* Delete Ticket {props.ticketnum} */}
          <div className="fs-5">
            Delete Visit Id{" "}
            <span style={{ color: "red" }}>#{props.ticketnum}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="delete-modal-body">
        <p className="mt-0">
          This action will remove all of your Visit information. This cannot be
          undone.
        </p>
        <span>Add Reason: </span>
        <InputGroup>
          {/* <InputGroup.Text>
            <p>Add Remark</p>
          </InputGroup.Text> */}

          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            // style={{ minHeight: "75px", maxHeight: "300px" }}
            value={deleteRemark} // Controlled component
            onChange={(e) => setDeleteRemark(e.target.value)} // Update deleteRemark state on input change
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleDelete}>Delete Ticket</Button> */}
        <LoadingButton
          loading={deleteBtnLoading}
          loadingPosition="end"
          size="large"
          onClick={handleDelete}
          disabled={deleteRemark.length === 0}
          variant="contained"
          style={{
            padding: "5px 10px",
            fontSize: "0.85rem",
            textTransform: "capitalize",
          }}
        >
          <span className={deleteBtnLoading ? "me-4" : ""}>Delete</span>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
}

function Visits({
  ticketData,
  currentPage,
  handelCurrentPage,
  cardsPerPage,
  totalCardsCount,
  reloadData,
  onExportLocal,
}) {
  // const [currentPage, handelCurrentPage] = useState(0); // Initialize currentPage to 0
  // Calculate the index range for the current page
  // const indexOfLastCard = (currentPage + 1) * cardsPerPage;
  // const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // const currentTickets = ticketData.slice(indexOfFirstCard, indexOfLastCard);
  const currentTickets = ticketData?.visit_ticket_list || [];

  // console.log("Current Ticket --->", ticketData);
  // Change page
  const handlePageClick = (data) => {
    // console.log("---->", data.selected);
    handelCurrentPage(data.selected);
  };

  const [deleteModal, setdeleteModal] = useState({
    show: false,
    visit_id: null,
    ticket_id: null,
  });

  const handelDeleteModal = (show, visit_id, ticket_id) => {
    setdeleteModal((prevSate) => {
      return { show: show, visit_id: visit_id, ticket_id: ticket_id };
    });
  };

  return (
    <>
      {ticketData?.visit_ticket_list ? (
        <>
          <div className="container">
            <div className="count_heading_div text-end  mb-2 text-nowrap">
              {ticketData?.count_heading || ""}
              <Tooltip title="Export">
                <span
                  className="export_all_data_btn"
                  onClick={() => onExportLocal(ticketData?.count_heading || "")}
                >
                  <CiExport />
                </span>
              </Tooltip>
            </div>
            <MyVerticallyCenteredModal
              show={deleteModal.show}
              ticketnum={deleteModal.visit_id}
              ticketId={deleteModal.ticket_id}
              onHide={() => handelDeleteModal(false)}
              reloadData={reloadData}
            />

            <div className="cards-parent">
              {/* Render ShowTicket component for each ticket */}
              {currentTickets &&
                currentTickets?.map((ticket) => {
                  // const timeInfo = getTimeStr(ticket);
                  return (
                    <ShowVisits
                      key={ticket.visit_id}
                      ticketId={ticket?.ticket_id}
                      // remarks={ticket.remarks}
                      issue_type={ticket.issue_type}
                      sub_issue_type={ticket.sub_issue_type}
                      start_date={ticket.start_date}
                      end_date={ticket.end_date}
                      visit_id={ticket.visit_id}
                      // total_expense={ticket.total_expense}
                      // parts_replaced={ticket.parts_replaced}
                      support_person={ticket.support_person}
                      machine_code={ticket.machine_code}
                      // distance={ticket.distance}
                      reloadData={reloadData}
                      handleDeleteModal={handelDeleteModal}
                      // attchementsCount={ticket?.no_of_attachments}
                      scheduled_date={ticket?.scheduled_date}
                      // scheduled_desc={ticket?.scheduled_desc}
                      status={ticket.status}
                      status_updated_time={ticket?.status_updated_time}
                      creation_time={ticket?.creation_time}
                    />
                  );
                })}
            </div>
            {/* <p>
                (Showing {startIndex}-{endIndex} of {totalCardsCount} tickets)
              </p> */}

            <div className=" pagination-head-container p-4 pe-0 me-0">
              <ReactPaginate
                pageCount={Math.ceil(totalCardsCount / cardsPerPage)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
                renderOnZeroPageCount={null}
                forcePage={currentPage} // Ensure active page is highlighted
              />
            </div>
          </div>
        </>
      ) : (
        <div className="container no-ticket-found-div text-center count_heading_div">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
            <p>{ticketData?.message || ""}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Visits;
