import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/CardModal.css";
import ContextState from "../contextApi/ContextState";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ModalError from "../error/ModalError";
import { Tooltip } from "@mui/material";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { BeatLoader } from "react-spinners";
import { CardPagesSkeleton } from "../Exporters/ExportFunctions";
import Placeholder from "../error/Placeholder";

const Comments = ({
  data,
  reloadCommentsPage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) => {
  const {
    formatDate,
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    controllerRef,
  } = useContext(ContextState);
  const { ticketId, status } = data;
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState([]);

  const [loadingModalVisiblity, setLoadingModalVisiblity] = useState(true);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  // previous comments data that is coming from backend
  const [commentsData, setCommentsData] = useState(null);

  const [reloadData, setReloadData] = useState(false);

  // this loading variable is set for loading of buttons on click
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setComment(value);
  };

  const handleAddComment = async () => {
    try {
      let comments = [];
      comments.push(comment);
      const payload = {
        ticket_id: ticketId,
        comments: comments,
      };

      setLoading(true);

      // Send the payload to the backend route
      const response = await fetchApi(
        "add_ticket_comment",
        "POST",
        payload,
        true
      );
      if (response.statusCode === 200) {
        // console.log("comments added succesfully");

        openSnackbar("comments added succesfully", "success");
        setReloadData((prevState) => !prevState);
        setEdit(false);
        setComment([]);
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error("Error editing ticket information:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchCardData = async () => {
      try {
        showLoadingModal(true);
        let payload = {
          info_type: "comments",
          ticket_id: ticketId,
        };

        if (refreshBtn) {
          setRefreshLoading(true);
        }

        let jsonData;
        if (isMounted) {
          jsonData = await fetchApi(
            "get_ticket_info",
            "POST",
            payload,
            true,
            1
          );

          // console.log("comments data recieved successfully:", jsonData.data);
          if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
            if (isMounted) {
              setCommentsData(jsonData.data.comments);
              showLoadingModal(false);
            }
          } else if (
            jsonData.statusCode === 500 ||
            jsonData.statusCode === 401
          ) {
            // openSnackbar(jsonData.data, "error")
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
            // console.log()
          } else {
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
          }
        }
        showLoadingModal(false);
      } catch (error) {
        if (controllerRef.current) {
          openSnackbar(
            "Your data is on its way. Thank you for your patience.",
            "warning"
          );
        } else {
          openSnackbar("Some Error Occured!", "error");
        }
      } finally {
        setRefreshLoading(false);
        setRefreshBtn(false);
      }
    };

    fetchCardData();

    return () => {
      isMounted = false;
    };
    // showLoadingModal(false);
  }, [reloadData, reloadCommentsPage]);

  return (
    <>
      {(loadingModalVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <>
          <div className="mx-3 my-3">
            <CardPagesSkeleton
              len={1}
              cardHeight={40}
              componentClass={"issue-type-row-div"}
            />
            <CardPagesSkeleton len={1} cardHeight={400} componentClass={""} />
          </div>
        </>
      )) ||
        (!showModalError.status && (
          <div className="comment-section mt-4">
            <div className="comment-box mx-4 row">
              <div className="p-0 ">
                <button
                  // id="saveTicketBtn"
                  type="button"
                  className="btn btn-outline-primary markResolved_button"
                  disabled={ticketStatus === "resolved"}
                  onClick={() => setEdit(!edit)}
                >
                  <Tooltip>Add new comment</Tooltip>
                </button>
              </div>

              {edit && (
                <>
                  <label className="col-12 my-2 p-0">
                    <p
                      className="my-2"
                      style={{
                        fontFamily: "Open-Sans-Light",
                        fontWeight: 600,
                        fontSize: "16px",
                        color: "rgba(76, 78, 100, 1)",
                      }}
                    >
                      Add a comment
                    </p>
                    <textarea
                      className="form-control"
                      name="postContent"
                      onChange={handleInputChange}
                      rows={6}
                    />
                  </label>
                  <div className="mt-2 p-0">
                    <LoadingButton
                      size="large"
                      onClick={handleAddComment}
                      endIcon={<SendIcon />}
                      loading={loading}
                      disabled={comment.length === 0}
                      loadingPosition="end"
                      variant="contained"
                    >
                      <span>Add</span>
                    </LoadingButton>
                  </div>
                </>
              )}
            </div>

            {(commentsData?.length && (
              <div className="show-comments mx-4 ">
                <div className="previous-comments-header ">
                  <h5 className="previous-comments-header-text">
                    Previous Comments
                  </h5>
                </div>
                {commentsData.map((comment, index) => (
                  <div className=" mb-2 py-2  previous-comments" key={index}>
                    <div className="row ">
                      <div className="col-12 commented-by">
                        {comment.username}{" "}
                        <span className="comment-time-span">
                          {formatDate(comment.creation_time)}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-12 comment-text">
                          {/* {comment.comment} */}

                          {comment.comment ? (
                            // if /n is there starting in new line
                            comment.comment
                              .split("\n")
                              .map((line, index) => (
                                <div key={index}>{line}</div>
                              ))
                          ) : (
                            // Render the full line if no newlines
                            <div>{comment.comment}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
              <div className="col-12 comment-date">{comment.creation_time}</div>
            </div> */}
                  </div>
                ))}
              </div>
            )) ||
              (!edit && (
                <div className="m-4">
                  <Placeholder
                    message="Oops! No Comment is Added yet."
                    icon={<CommentOutlinedIcon fontSize="inherit" />}
                  />
                </div>
              ))}
          </div>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
};

export default Comments;
