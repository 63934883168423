import test_profile_img from "../../../assets/images/default_product_hdd_without_background.png";
import v39_scanner_img from "../../../assets/images/product/V39.png";
import v600_scanner_img from "../../../assets/images/product/V600.png";
import ardiuno_nano_controller_img from "../../../assets/images/product/ardiuno_nano.png";
import ardiuno_uno_controller_img from "../../../assets/images/product/ardiuno_uno.png";
import nuc_img from "../../../assets/images/product/nuc.png";
import { Tooltip } from "@mui/material";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import ToolTip from "react-bootstrap/Tooltip";

import React, { useEffect, useState, useContext, useCallback } from "react";
import * as XLSX from "xlsx";
import { addFilters } from "../../features/filtersSlice/filterSlice";
import { BeatLoader } from "react-spinners";
import Error from "../../error/Error";
import Dropdown from "../../filters/Dropdown";
import AllFilters from "../../filters/AllFilters";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  Typography,
  Box,
  ButtonGroup,
  Button as MUIButton,
  Tooltip as MuiTooltip,
  linearProgressClasses,
  Pagination,
  Modal,
  Chip,
  IconButton,
} from "@mui/material";
import TotalMachinesImg from "../../../assets/images/machine-blue.png";
import TotalDistrictImg from "../../../assets/images/district-blue.png";
import TotalRegionImg from "../../../assets/images/region-blue.png";
import allTicketsImg from "../../../assets/images/total-assessment-blue-3.png";

// import showInventoryImg from "../../../assets/images/inv_total_count_2.png"
import showInventoryImg from "../../../assets/images/inv_total_count.png";
import showReadyToUseImg from "../../../assets/images/inv_ready_to_use.png";
import showInHouseImg from "../../../assets/images/inv_in_house.png";
import { useDispatch, useSelector } from "react-redux";
import ContextState from "../../contextApi/ContextState";
import { CardPagesSkeleton } from "../../Exporters/ExportFunctions";
import Placeholder from "../../error/Placeholder";
import {
  ProductionQuantityLimitsOutlined,
  SentimentDissatisfiedOutlined,
} from "@mui/icons-material";

const ProductType = () => {
  const {
    color,
    ProductTypeData,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
  } = useOutletContext();
  const navigate = useNavigate();
  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Showing All produts Type in inventory
    </ToolTip>
  );

  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    location_icon: (isSmallSmallScreen && "30px") || "35px",
    location_icon_background_width: "50px",
    location_icon_background_height: "50px",
    location_background_borderRadius: "15%",

    locationVerticalLineHeight: "30px",

    product_type_small_icon_desc:
      (isSmallSmallScreen && "0.815rem") || "0.915rem",
    product_type_small_icon_num: "1.25rem",
  };

  // filters started

  const {
    fetchApi,
    showError,
    showErrorPage,
    openSnackbar,
    generalApiCall,
    gencontrollerRef,
    controllerRef,
    formatInIndianRupees,
  } = useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters);

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");
  const [liveMachinesData, setliveMachinesData] = useState(null);

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState(null);
  useEffect(() => {
    setLoading(true);
    setData(null);
    document.title = `All Products - NEO`;
    const fetchDataAndUpdateFilters = async () => {
      // fetchFiltersDataApiCall
      let is_product_data_Mounted = true;
      try {
        const api_data = await fetchApi(
          "get_product_types",
          "POST",
          {},
          is_product_data_Mounted
        );
        // console.log("all_filter_data in All Ticket : ", api_data);
        if (api_data.statusCode === 200) {
          setData(api_data.data);
          showErrorPage(null, null);
        } else if (api_data.statusCode === 500 || api_data.statusCode === 401) {
          setData(null);
          showErrorPage(api_data.statusCode, api_data.msg);
        } else {
          setData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
        setLoading(false);
      } catch (error) {
        console.log("Error in page : ", error);
      }

      return () => {
        is_product_data_Mounted = false;
      };
    };
    fetchDataAndUpdateFilters();
    // console.log("data set to empty List - 2");
  }, []);

  // const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (e, path, targetPath) => {
    if (e.ctrlKey || e.metaKey) {
      // Open in a new tab if Ctrl or Cmd key is pressed
      window.open(targetPath, "_blank");
    } else {
      // Prevent full page reload and navigate within SPA
      e.preventDefault();
      navigate(`${path}`);
    }
  };

  return (
    <>
      {(!showError.status && (
        <>
          {/* <Dropdown
            blurTickets={handelPageBlur}
            filters={filters}
            filterCount={filterCount}
            handleFilterChange={handleFilterChange}
            handleSaveChanges={handleSaveChanges}
            handleFilterReset={handleFilterReset}
            getUserFilters={getUserFilters}
            getFilterResponse={getFilterResponse}
            filtersOptions={filtersOptions}
          />
          {allFiltersDisplay && (
            <AllFilters
              filters={showAllfilters}
              handelResetAllBtn={handelResetAllBtn}
              handelDeleteFilterBtn={handelDeleteFilterBtn}
              getUserFilters={getUserFilters}
            />
          )} */}

          {/* <div
            className={`${blurTickets} all-ticket-content ${
              allFiltersDisplay ? "m-top-0 p-top-0" : " "
            }`}
          > */}
          <div className={`${blurTickets} all-ticket-content pt-2 mt-1 }`}>
            {(loading && (
              // {lodingVisiblity && (
              // <div className="showSpinner all-ticket-spinner">
              //   <BeatLoader color="#2D97D4" />
              // </div>
              <div className="mt-4">
                <CardPagesSkeleton len={12} cardHeight={170} />
              </div>
            )) || (
              <div className="container">
                <div className="count_heading_div text-end  pe-0 text-nowrap d-flex ms-auto">
                  <div className="upper-heading-container p-0 py-2 pb-1">
                    {/* Document info */}
                    <div className="info-part">
                      <span className="px-2 mb-1 info-heading">
                        Products Types
                      </span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <i
                          className="bi bi-info-circle icon-color px-0"
                          id="info-icon"
                          style={{ fontSize: "15px", lineHeight: "24px" }}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                {/* we get Data.message from backend when no product found or when any message we want to give */}
                {(!Data.message && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        (isSmallScreen && "1fr") ||
                        (isMediumScreen && "repeat(2,1fr)") ||
                        "repeat(3,1fr)",
                      gap: "15px",
                    }}
                  >
                    {Object.entries(Data?.product_type_data || {}).map(
                      ([key, value], index) => (
                        <div
                          className="position-relative  d-flex flex-column gap-1"
                          style={{
                            borderRadius: "8px",
                            boxShadow: "0px 0px 4px 1px #00000026",
                            backgroundColor: "#fff",
                            margin: "4px",
                          }}
                          key={index}
                        >
                          {/* More */}
                          <div className="d-flex align-items-center position-absolute top-0 end-0 mt-3 me-3">
                            <div className="ms-auto">
                              <Tooltip
                                data-html="true"
                                title={"Click to view detailed data"}
                                arrow={true}
                              >
                                <a
                                  href={`${location.pathname}/${key}`}
                                  style={{
                                    color: color.taxtHeadingLight,
                                    textDecoration: "none",
                                  }}
                                  className="location_moredetails_btn ms-auto align-self-center"
                                  onClick={(e) => {
                                    const targetPath = `${location.pathname}/${key}`;
                                    handleClick(e, key, targetPath);
                                  }}
                                >
                                  More
                                  <i className="fa-solid fa-chevron-right ps-1"></i>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                          {/* Image and Description */}
                          {/* Product Desc */}
                          <div className="my-2 flex-grow-1">
                            <span
                              style={{
                                // border: "2px solid blue",
                                padding: "5px 0px",
                                borderRadius: "4px",
                                backgroundColor: "transparent",
                                textTransform: "none",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "5px",
                                height: "100%",
                              }}
                            >
                              <div className="m-0 p-0 ">
                                <img
                                  src={
                                    key.toLowerCase() === "scanner"
                                      ? v39_scanner_img
                                      : key.toLowerCase() === "nuc"
                                      ? nuc_img
                                      : key.toLowerCase() === "controller"
                                      ? ardiuno_nano_controller_img
                                      : test_profile_img
                                  }
                                  alt={`${key} image`}
                                  className="mx-2"
                                  style={{ height: "auto", width: "70px" }}
                                />
                              </div>
                              <div
                                className="product_full_desc d-flex flex-column justify-content-between h-100 py-3 pb-2"
                                style={{
                                  fontFamily: "Open-Sans-Light",
                                  fontSize: "1.25rem",
                                  // rowGap: "8px",
                                  fontWeight: 600,
                                  color: color.navHeadingAboveText,
                                  // padding: "10px 0px",
                                }}
                              >
                                <div
                                  className="product_name p-0 m-0"
                                  style={{
                                    fontFamily: "Open-Sans-regular",
                                    textTransform: "capitalize",
                                    lineHeight: "1.625rem",
                                    fontWeight: 500,
                                    color: color.taxtHeading,
                                  }}
                                >
                                  {key}
                                </div>
                                <div
                                  className="product_desc p-0 m-0"
                                  style={{
                                    fontSize: "1.45rem",
                                  }}
                                >
                                  {formatInIndianRupees(value.totalCount)}
                                  {/* {item} desc/use */}
                                </div>
                              </div>
                            </span>
                          </div>

                          {/* <div className="underline1 pt-2"></div> */}
                          {/* bottom div info */}
                          <div
                            className="d-flex flex-row justify-content-between align-items-center "
                            style={{
                              fontSize: "0.805rem",
                              fontFamily: "Open Sans, sans-serif",
                              fontWeight: 700,
                              // backgroundColor: color.iconBackgroundBlue,
                              backgroundColor: color.primary,
                              color: color.taxtHeading,
                              borderBottomRightRadius: "8px",
                              borderBottomLeftRadius: "8px",
                              padding: "15px",
                            }}
                          >
                            {[
                              {
                                label: "Ready To Use",
                                value: value.ready_to_use,
                              },
                              { label: "In House", value: value.in_house },
                            ].map((item, index) => (
                              <>
                                <div
                                  key={index}
                                  className="d-flex align-items-end gap-1"
                                  style={{
                                    fontSize: "0.995rem",
                                    fontWeight: 700,
                                  }}
                                >
                                  <p
                                    className="p-0 m-0"
                                    style={{
                                      fontSize: "0.895rem",
                                      fontWeight: 600,
                                      color: color.secondary,
                                      // color: color.iconBackgroundBlue,
                                    }}
                                  >
                                    {item.label}:
                                  </p>
                                  <span className="p-0 m-0">{item.value}</span>
                                </div>
                                {[0].includes(index) && (
                                  <div
                                    className={`barChartDivDescVerticalSeperator`}
                                    style={{
                                      height: "20px",
                                    }}
                                  ></div>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )) || (
                  <div className="" style={{ height: "60vh" }}>
                    <Placeholder
                      // message="Oops! No Product Added Yet."
                      message={Data.message}
                      icon={
                        <ProductionQuantityLimitsOutlined fontSize="inherit" />
                      }
                      color={color}
                      removeBoxShadow={true}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )) ||
        (showError.status && <Error />) || <Error code={500} />}
    </>
  );
};

export default ProductType;
