import React, { useContext, useMemo, useState } from "react";
import { color } from "../../../modals/Calllog";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Box, Tooltip as MuiTooltip, useMediaQuery } from "@mui/material";
import cardViewWhiteImg from "../../../../assets/images/grid_view_white.png";
import tableViewImg from "../../../../assets/images/list.png";
import cardViewImg from "../../../../assets/images/grid (1).png";
import tableViewWhiteImg from "../../../../assets/images/table_view_white.png";
import { DataGrid } from "@mui/x-data-grid";
import ShowSeller from "../ShowSeller";
import ContextState from "../../../contextApi/ContextState";
import { Tooltip as BootstrapTooltip } from "react-bootstrap";
import ToolTip from "react-bootstrap/Tooltip";
import { FaPlus } from "react-icons/fa";
import rupees_icon from "../../../../assets/images/currency-rupee.svg";
import productName from "../../../../assets/images/productName.png";
import Person from "../../../../assets/images/person.jpg";
import Calender from "../../../../assets/images/calender.jpg";
import parts_replaced_icons from "../../../../assets/images/gear-fill.svg";
import { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import ModalError from "../../../error/ModalError";
import { Navigate, useNavigate } from "react-router-dom";
import { CardPagesSkeleton } from "../../../Exporters/ExportFunctions";

const SellerLotHistory = ({
  sellerId,
  reloadAllSellersData,
  setRefreshLoading,
  refreshBtn,
  setRefreshBtn,
  reload,
  reloadLotHistoryPage,
}) => {
  const {
    formatDate,
    formatInIndianRupees,
    fetchApi,
    openSnackbar,
    showModalErrorPage,
    showModalError,
  } = useContext(ContextState);

  const navigate = useNavigate();

  const [SwitchView, setSwitchView] = useState(0);

  const isMediumScreen = useMediaQuery("(max-width:992px)");
  const isLargeScreen = useMediaQuery("(max-width:1200px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const [Data, setData] = useState(null);
  const [loadingModalVisiblity, setLoadingModalVisiblity] = useState(true);

  let isMounted;
  useEffect(() => {
    isMounted = true;
    showModalErrorPage(null, null);
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [reloadLotHistoryPage, reloadAllSellersData]);

  const fetchData = async () => {
    try {
      if (refreshBtn) {
        setRefreshLoading(true);
      }
      const payload = { info_type: "order_history", seller_id: sellerId };

      const response = await fetchApi(
        "get_seller_modal_details",
        "POST",
        payload,
        isMounted
      );

      if (response.statusCode === 200) {
        setData(response.data);
      } else {
        setData(null);
        showModalErrorPage(response.statusCode, response.err);
      }
    } catch (error) {
    } finally {
      setRefreshLoading(false);
      setLoadingModalVisiblity(false);
      setRefreshBtn(false);
    }
  };

  const rowHeight = 64; // Default row height in DataGrid
  const headerHeight = 135; // Default header height in DataGrid
  const maxRows = 5; // Maximum number of rows per page
  const visibleRows = Math.max(Data?.table_data?.rows?.length || 0, maxRows);

  const handelSwitchView = (view) => {
    setSwitchView(view);
  };

  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Showing <strong>All Lots </strong> Details.
    </ToolTip>
  );

  const imagesUrls = {
    Laptop:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/dell-inspiron-3000.png",
    Keybord:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
    Mobile:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/samsung-s22.png",
    Monitor:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/apple-iMac-4k.png",
    default:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
  };

  const handleNavigateLotDetails = (lotId) => {
    navigate(`/alllots/${lotId}`);
  };

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      headers.push({
        field: "expand",
        headerName: "",
        width: 55,
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                // width: !index ? 400 : 150, // Adjust width as needed
                //   marginLeft: "5px", // Add margin to separate text from progress bar
                //   fontWeight: "bold", // Customize font weight if needed
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                // color:
                //   (item.field === "status" &&
                //     ((params.value === "rejected" && color.red) ||
                //       (params.value === "accepted" && color.greenToShow))) ||
                //   (params.row["is_rejected"][item.field] && color.red) ||
                //   color.tableRowHeading,
                textWrap: "nowrap",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {
                <MuiTooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        More Details
                      </div>
                    </>
                  }
                  arrow
                >
                  <span
                    style={{
                      // border: "2px solid blue",

                      // padding: "3px 9px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      textTransform: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      console.log(params.row);
                      // navigate(`${params.row.product_id}`);
                      if (params.row) {
                        handleNavigateLotDetails(params.row.lotId);
                      }
                    }}
                  >
                    <FaPlus />
                  </span>
                </MuiTooltip>
              }
            </Box>
          );
        },
      });
      data.columns.forEach((item, index) => {
        // if (!["region", "district", "location"].includes(item.field)) {
        headers.push({
          field: item.field,
          headerName: item.headerName,
          width: item.width,
          // editable: item.editable,
          editable: false,
          sortable: false,
          filterable: false,
          flex:
            !isLargeScreen && !["lotId", "productCount"].includes(item.field)
              ? 1
              : 0,

          headerAlign: ![0].includes(index) ? "center" : "left",
          resizable: item.resizable,
          renderHeader: (params) => (
            <MuiTooltip
              title={`${item?.field
                ?.replace(/_/g, " ")
                ?.split(" ")
                .map(
                  (word) =>
                    word?.charAt(0)?.toUpperCase() +
                    word?.slice(1)?.toLowerCase()
                )
                .join(" ")}`}
              arrow
            >
              <span
                style={{
                  paddingLeft: ([0].includes(index) && "10px") || "0px",
                  extAlign: ![0].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  // fontWeight: "600",
                  color: color.tableRowHeading,
                }}
              >
                {params.colDef.headerName}
              </span>
            </MuiTooltip>
          ),
          renderCell: (params) => {
            // console.log("params-->: ", params);
            return (
              <Box
                sx={{
                  // width: !index ? 400 : 150, // Adjust width as needed
                  //   marginLeft: "5px", // Add margin to separate text from progress bar
                  //   fontWeight: "bold", // Customize font weight if needed
                  textAlign: ![0].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  fontWeight:
                    (item.field === "status" && "600") ||
                    (index !== 0 && "500") ||
                    "700",
                  fontSize: item.field === "status" ? "0.785rem" : "1.05rem",
                  color: color.tableRowHeading,
                  textWrap: "nowrap",
                }}
              >
                {
                  <MuiTooltip
                    title={
                      <>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            fontFamily: "Open-Sans-Medium",
                          }}
                        >
                          <div>
                            <strong style={{ textTransform: "capitalize" }}>
                              {params.field?.split("_").join(" ")}
                            </strong>
                            :{" "}
                            <strong
                              style={{
                                textTransform:
                                  (item.field === "status" && "capitalize") ||
                                  "none",
                              }}
                            >
                              {(item.field === "creationDate" &&
                                formatDate(params.value)) ||
                                (item.field === "arrivalDate" &&
                                  formatDate(params.value)) ||
                                params.value}
                            </strong>
                          </div>
                        </div>
                      </>
                    }
                    arrow
                  >
                    {(item.field === "product_name" && (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding: "0px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform: "none",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          height: "100%",
                        }}
                      >
                        <div className="product_img m-0 p-0 me-2 ">
                          <img
                            src={
                              imagesUrls[params.value] || imagesUrls["default"]
                            }
                            alt="product_img"
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        <div
                          className="product_full_desc d-flex flex-column  p-0 m-0 lh-sm"
                          style={{
                            fontFamily: "Open-Sans-Light",
                            color: color.taxtHeading,
                            fontWeight: 600,
                            width: "100%",
                          }}
                        >
                          <div
                            className="product_name p-0 m-0"
                            // style={{ height: "20px" }}
                          >
                            {params.value[0]}
                          </div>
                          <div
                            className="product_desc p-0 m-0"
                            style={{
                              width: "90%",
                              fontWeight: 400,
                              fontSize: "0.89rem",
                              overflow: "hidden", // Hide overflow
                              textOverflow: "ellipsis", // Show ellipsis
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                          >
                            {params.value[1]}
                          </div>
                        </div>
                      </span>
                    )) || (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding:
                            (item.field === "status" && "5px 9px") || "4px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform:
                            (["status", "reason"].includes(item.field) &&
                              "capitalize") ||
                            "none",
                          width: "100px",
                        }}
                      >
                        {(item.field === "creationDate" &&
                          formatDate(params.value, true)) ||
                          (item.field === "arrivalDate" &&
                            formatDate(params.value, true)) ||
                          (item.field === "totalAmount" &&
                            formatInIndianRupees(params.value)) ||
                          params.value}
                      </span>
                    )}
                  </MuiTooltip>
                }
              </Box>
            );
          },
        });
        // }
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = useMemo(() => {
    if (Data?.tableData?.rows?.length !== 0) {
      return getFirstPageDataTableDataHeader({
        rows: Data?.tableData?.rows || [],
        columns: Data?.tableData?.columns || [],
      });
    }
    return { TableDataHeaders: [], TabelDataRows: [] };
  }, [
    JSON.stringify(Data?.tableData?.rows),
    JSON.stringify(Data?.tableData?.columns),
    isLargeScreen,
  ]);

  return (
    <>
      {(loadingModalVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "64vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <div className="mt-4 mx-3">
          <CardPagesSkeleton len={12} cardHeight={200} />
        </div>
      )) ||
        (!showModalError?.status &&
          Data &&
          (!Data.msg ? (
            <div className="container row">
              <div
                className="upper-heading-container p-0 px-1 pt-2"
                style={{ marginBottom: "15px", marginTop: "10px" }}
              >
                {/* Document info */}
                <div className="info-part">
                  <span className="px-2 mb-1 info-heading">Lots Details</span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <i
                      className="bi bi-info-circle icon-color px-0"
                      id="info-icon"
                      style={{ fontSize: "15px", lineHeight: "24px" }}
                    ></i>
                  </OverlayTrigger>
                </div>
                <div className="d-flex flex-row ms-auto align-items-center display-inline">
                  {/* Document details */}
                  <div
                    className="show-range ms-auto"
                    style={{ alignSelf: "end" }}
                  >
                    {Data?.count_heading}
                  </div>
                  <div
                    className="d-flex flex-row  mx-2 mb-1"
                    style={{
                      borderRadius: "6px",
                      boxShadow: "0px 0px 1px 1px" + color.taxtHeadingLight,
                    }}
                  >
                    <div
                      className="d-flex  align-item-center justifty-content-center"
                      style={{
                        backgroundColor:
                          (SwitchView === 0 && "#2d97d4") || "inherit",
                        borderRadius: "4px",
                        boxShadow:
                          (SwitchView === 0 &&
                            "0px 0px 1px 1px" + color.taxtHeadingLight) ||
                          "none",
                        cursor: "pointer",
                        transition:
                          "background-color 0.3s ease, box-shadow 0.3s ease",
                        padding: "7px",
                      }}
                      onClick={() => {
                        handelSwitchView(0);
                      }}
                    >
                      {SwitchView === 1 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Card View"}
                          arrow={true}
                        >
                          <img
                            src={cardViewImg}
                            alt="card_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                      {SwitchView === 0 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Card View"}
                          arrow={true}
                        >
                          <img
                            src={cardViewWhiteImg}
                            alt="card_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                    </div>
                    <div
                      className="d-flex align-item-center justifty-content-center"
                      style={{
                        backgroundColor:
                          (SwitchView === 1 && "#2d97d4") || "inherit",
                        borderRadius: "4px",
                        boxShadow:
                          (SwitchView === 1 &&
                            "0px 0px 1px 1px" + color.taxtHeadingLight) ||
                          "none",
                        cursor: "pointer",
                        transition:
                          "background-color 0.3s ease, box-shadow 0.3s ease",
                        padding: "7px",
                      }}
                      onClick={() => {
                        handelSwitchView(1);
                      }}
                    >
                      {SwitchView === 1 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Table View"}
                          arrow={true}
                          style={{ height: "10px" }}
                        >
                          <img
                            src={tableViewWhiteImg}
                            alt="table_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                      {SwitchView === 0 && (
                        <MuiTooltip
                          data-html="true"
                          title={"Table View"}
                          arrow={true}
                          style={{ height: "10px" }}
                        >
                          <img
                            src={tableViewImg}
                            alt="table_view"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </MuiTooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {SwitchView === 0 && (
                <div className="cards-parent">
                  {Data?.tableData &&
                    Data?.tableData?.rows?.map((ticket, index) => {
                      return (
                        <LotCard
                          lotDetails={ticket}
                          formatInIndianRupees={formatInIndianRupees}
                          handelNavigate={handleNavigateLotDetails}
                        />
                      );
                    })}
                </div>
              )}

              {/* Data grid */}
              {SwitchView === 1 && (
                <div className={`m-0 p-0 ${"mb-4"}`}>
                  <div className="card-Div">
                    <Box
                      sx={{
                        height: Data?.tickets_limit !== "All" ? "44vh" : "72vh",
                        // || gridHeight,
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        rows={TabelDataRows}
                        columns={TableDataHeaders}
                        density="standard"
                        paginationMode="client"
                        pageSize={101}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        onRowClick={(param) => {
                          // console.log("row_param : ", param.row);
                          // if (param.row) {
                          //   handleOpenEditModal(param.row);
                          // }
                        }}
                        getRowClassName={(params) => {
                          // console.log(
                          //   "params : ",
                          //   params,
                          //   params.row.id === selectedRow?.id
                          // );
                          // return params.row.id === selectedRow?.id
                          //   ? "selected-row"
                          //   : "";
                        }}
                        sx={{
                          "&.MuiDataGrid-root": {
                            border: "none",
                            borderRadius: "5px", // Adjust border radius as needed
                            overflow: "hidden", // Ensure content respects the border radius,
                            padding: "0px",
                            margin: "0px",
                            marginTop: "-10px",
                          },
                          ".MuiDataGrid-columnHeader": {
                            backgroundColor: color.textWhiteColor,
                          },
                          ".MuiDataGrid-filler": {
                            backgroundColor: color.textWhiteColor,
                          },
                          ".MuiDataGrid-columnHeaderTitle": {
                            fontFamily: "Open-Sans-Light",
                            fontWeight: "bold",
                            padding: "5px 0px",
                            fontSize: "0.85rem",
                            textTransform: "capitalize",
                            color: color.taxtHeading,
                          },
                          ".MuiDataGrid-columnHeader--alignCenter": {
                            headerAlign: "left",
                          },
                          ".MuiDataGrid-row": {
                            border: "none", // Remove border from rows
                            backgroundColor: color.textWhiteColor, // Set rows background color to white
                            "&:hover": {
                              backgroundColor: color.primary, // Ensure background color remains white on hover
                              // cursor: "pointer",
                            },
                          },
                          ".MuiDataGrid-footerContainer": {
                            display:
                              (visibleRows <= 50 && "none") || "inline-block", // Hide the pagination footer
                            justifyContent: "space-between", // Space out selected row count and pagination controls
                            alignItems: "center", // Vertically align items in the footer
                            marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                            height: "30px",
                          },
                          ".MuiTablePagination-root": {
                            // ".MuiInputBase-root": {
                            //   display: "none",
                            // },

                            // ".MuiTablePagination-selectLabel": {
                            //   display: "none",
                            // },
                            ".MuiToolbar-root": {
                              marginTop: "0px",
                            },
                          },
                          ".MuiDataGrid-cell": {
                            outline: "none",
                            "&:focus": {
                              outline: "none",
                            },
                          },
                          "&.selected-row": {
                            backgroundColor: "red",
                            "&:hover": {
                              backgroundColor: "red",
                            },
                          },
                          ".MuiDataGrid-selectedRowCount": {
                            display: "none",
                          },
                          ".MuiTablePagination-toolbar": {
                            padding: "2px 16px",
                            marginTop: "-15px",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="container no-ticket-found-div text-center count_heading_div">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                <p>{Data.msg}</p>
              </div>
            </div>
          ))) ||
        (showModalError?.status && (
          <div style={{ height: "60vh", overflow: "hidden" }}>
            <ModalError />
          </div>
        )) || <ModalError code={500} style={{ height: "55vh" }} />}
    </>
  );
};

export default SellerLotHistory;

function LotCard({ lotDetails, formatInIndianRupees, handelNavigate }) {
  const {
    lotId,
    invoiceNumber,
    productCount,
    totalAmount,
    products,
    creationDate,
    arrivalDate,
    receivedBy,
  } = lotDetails;

  return (
    <div
      className="card"
      onClick={() => {
        handelNavigate(lotId);
      }}
    >
      <div className="card-body py-3 px-3">
        <div className="position-relative d-flex flex-row align-items-center mb-1">
          <h3 className="ticketid  fs-4 mb-0 ">
            <svg className="ticket-icon" viewBox="0 0 576 512">
              <path
                fill="#362b2b"
                d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"
              />
            </svg>
            {/* {ticketId} */}
            {lotId}
          </h3>
        </div>

        {/* Invoice Number*/}
        <div className="d-flex align-items-center error_type  pb-0 mb-1 ">
          <p className=" ps-0 mb-0">Invocie </p>
          {invoiceNumber && (
            <p className="mb-0">
              {/* <span>
                <i className="bi bi-dot"></i>
              </span> */}
              <span className="ms-1"> #{invoiceNumber}</span>
            </p>
          )}
        </div>

        <div className="lower-part">
          {/* Contacted Person name */}
          {receivedBy && (
            <div className="d-flex align-items-center mb-2">
              <img className="imported_img " src={Person} alt="" />
              {/* <i className="bi bi-person-fill imported_img"></i> */}
              <p className=" generated_by  ps-2">{receivedBy}</p>
            </div>
          )}

          <div className="d-flex flex-wrap align-items-center mb-2">
            {/* Products Count */}
            <div className="d-flex align-items-center me-3">
              <img
                className="imported_img "
                src={parts_replaced_icons}
                alt=""
              />
              <p className=" generated_by  ps-2">
                {productCount
                  ? `${formatInIndianRupees(productCount)}`
                  : "Not Mentioned"}
              </p>
            </div>

            {/* total amount */}
            {totalAmount && (
              <div className="d-flex align-items-center me-3">
                <img className="imported_img " src={rupees_icon} alt="" />
                <p className=" generated_by  ps-2">
                  {totalAmount
                    ? `${formatInIndianRupees(totalAmount)}`
                    : "Not Mentioned"}
                </p>
              </div>
            )}
          </div>

          {/* Products names */}
          {products && (
            <div className="d-flex align-items-start  mb-2">
              <img
                className="imported_img"
                // key={"parts_replaced_icons"}
                src={productName}
                alt="parts_icon"
              />
              {/* <i className="bi bi-card-text imported_img"></i> */}
              <div
                className="d-flex flex-row align-items-center ps-2 error_description generated_by"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {products.length > 0 ? (
                  products.map((parts, index) => (
                    <p key={index} className={`m-0 me-2 text-nowrap`}>
                      <React.Fragment key={index}>
                        {index > 0 && <i className="bi bi-dot"></i>}
                        {parts}
                      </React.Fragment>
                    </p>
                  ))
                ) : (
                  <p className="mb-0 ms-2">Not Mentioned</p>
                )}
              </div>
            </div>
          )}

          {/* arrival date && creation date */}
          <div className="d-flex flex-wrap align-items-center  ">
            <div className="d-flex me-auto flex-row ">
              <img className="imported_img " src={Calender} alt="" />
              {/* <i className="bi bi-calendar3 imported_img"></i> */}
              <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                <span className="">{arrivalDate.slice(0, -12)}</span>
                <label className="text-start" style={{ marginTop: "2px" }}>
                  Arrival Date
                </label>
              </p>
            </div>

            {/* creation date */}

            {creationDate && (
              <div className="d-flex  flex-row justify-content-end">
                <img className="imported_img " src={Calender} alt="" />
                {/* <i className="bi bi-calendar3 imported_img"></i> */}
                <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                  <span className="">{creationDate.slice(0, -12)}</span>
                  <label className="text-start" style={{ marginTop: "2px" }}>
                    creationDate
                  </label>
                </p>
              </div>
            )}
          </div>
        </div>
        {/* <div className="underline1 pt-2"></div> */}
      </div>
    </div>
  );
}
