import React, { useContext, useEffect, useState } from "react";
import ContextState from "../contextApi/ContextState";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import ModalError from "../error/ModalError";
import { BeatLoader } from "react-spinners";
import { TableSkeleton } from "../Exporters/ExportFunctions";
import Placeholder from "../error/Placeholder";

const ModelDetailsLog = ({
  data,
  reloadModelLogPage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) => {
  const {
    fetchApi,
    formatDate,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    modalControllerRef,
  } = useContext(ContextState);
  const [showLoader, setShowLoader] = useState(false);

  const [logdata, setLogData] = useState(null);

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(true);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  //   exacting the ticket id from the props
  const { ticketId } = data;

  useEffect(() => {
    let isMounted = true;
    showLoadingModal(true);
    const fetchModelDetailsLog = async () => {
      try {
        const payload = {
          ticket_id: ticketId,
        };

        if (refreshBtn) {
          setRefreshLoading(true);
        }

        const response = await fetchApi(
          "get_model_details_log",
          "POST",
          payload,
          true,
          1
        );
        if (response?.statusCode === 200) {
          setLogData(response.data);
          setShowLoader((prev) => !prev);
        } else {
          showModalErrorPage(response.statusCode, response.msg);
        }
        showLoadingModal(false);
      } catch (error) {
        if (modalControllerRef.current) {
          openSnackbar(
            "Your data is on its way. Thank you for your patience.",
            "warning"
          );
        } else {
          openSnackbar("Some Error Occured!", "error");
        }
      } finally {
        setRefreshLoading(false);
        setRefreshBtn(false);
      }
    };
    fetchModelDetailsLog();

    return () => {
      isMounted = false;
    };
  }, [reloadModelLogPage]);

  const customCells = (columns) => {
    if (!columns) return [];

    let customcells = columns.map((each_col) => {
      if (each_col.headerName === "Update Time") {
        return {
          ...each_col,
          valueGetter: (value) => {
            let timestamp = formatDate(value);
            return timestamp;
          },
        };
      } else if (each_col.headerName === "Remarks") {
        return {
          ...each_col,
          flex: 1,
        };
      } else {
        return { ...each_col };
      }
    });

    return customcells;
  };

  return (
    <>
      {" "}
      {(lodingModalVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <>
          <div className="m-3">
            <TableSkeleton rowsNum={10} colsNum={7} />
          </div>
        </>
      )) ||
        (!showModalError.status && (
          <div style={{ margin: "25px 30px 0px" }}>
            <Box
              style={{ height: 800 }}
              sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "rgba(234, 235, 237, 0.95)",
                  color: "black",
                },
                "& .MuiDataGrid-row": {
                  backgroundColor: "white",
                  color: "black",
                },
                "& .MuiDataGrid-selectedRowCount": {
                  display: "none",
                },
                "& .MuiDataGrid-footerContainer": {
                  display: "flex",
                  justifyContent: "end",
                },
              }}
            >
              {logdata?.rows?.length > 0 ? (
                <DataGrid
                  style={{ height: 800, boxShadow: "0 3px 12px 0 #00000026" }}
                  rows={logdata?.rows || []}
                  columns={customCells(logdata?.columns)}
                />
              ) : (
                <div className="my-4 mx-1">
                  <Placeholder message="Oops! No Model Log is there." />
                </div>
              )}
            </Box>
          </div>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
};

export default ModelDetailsLog;
