import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Tooltip,
  Autocomplete,
  Box,
  TextField,
  Skeleton,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import * as XLSX from "xlsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useOutletContext } from "react-router-dom";
import { RiAddBoxFill } from "react-icons/ri";
import { RiAttachmentLine } from "react-icons/ri";
import close from "../../assets/images/close.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../contextApi/ContextState";
import Error from "../error/Error";
import { BeatLoader } from "react-spinners";
import TagAttachmentModal from "../Attachment/TagAttachmentModal";
import {
  TravelPageSkeleton,
  urlToFileArrayBuffer,
} from "../Exporters/ExportFunctions";
import ShowAttachments from "../Attachment/ShowAttachments";
import ConfirmDeletionModal from "../modals/ConfirmDeletionModal";
import ShowPreview from "../Attachment/ShowPreview";
import Placeholder from "../error/Placeholder";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import { IoMdCar } from "react-icons/io";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const style2 = {
  position: "relative",
  zIndex: "90",
  right: "0",
  top: "15%",
  left: "0",
  margin: "  auto",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  overflowY: "auto",
  width: "85vw",
  maxWidth: "1250px",
};

const EachVisitTravel = () => {
  const {
    color,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
    visitId,
    pagesData,
    handlePagesData,
  } = useOutletContext();

  const {
    fetchApi,
    openSnackbar,
    showError,
    showErrorPage,
    formatDate,
    modalControllerRef,
  } = useContext(ContextState);

  // this is for button loading
  const [loading, setLoading] = useState(false);
  const [cordinateLoading, setCordinateLoading] = useState(false);

  // state to maintain allDOwnload
  const [downloading, setDownloading] = useState({
    load: false,
    tag: null,
  });

  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    ticketId: "",
    attachId: "",
    travel_id: "",
    tag: "",
  });

  // this is for loading the page
  const [loadingVisiblity, setLoadingVisiblity] = useState(true);

  // state varibales
  const [expanded, setExpanded] = useState(false);

  // to load the button while download or preview from backend
  const [dataLoading, setDataLoading] = useState({
    load: false,
    attach_id: null,
  });

  // to reload the page on submit
  const [reloadData, setReloadData] = useState(false);

  const options = [
    // { label: "Machine Report", value: "Machine Report" },
    { label: "Start Meter", value: "Start Meter" },
    { label: "End Meter", value: "End Meter" },
    { label: "Vehicle Number", value: "Vehicle Number" },
    { label: "Others", value: "Others" },
    // Add more options here
  ];

  const [startTravel, setStartTravel] = useState({
    mode: "",
    latt: "",
    long: "",
    address: "",
    attachments: {},
  });

  const handleChange = (panel) => (event, isExpanded) => {
    handleClose("addTravel");
    setExpanded(isExpanded ? panel : false);
  };

  // modals list
  const [modalOpen, setModalOpen] = useState({
    addTravel: false,
    addAttach: false,
  });

  // this state is for preview component
  const [sheetData, setSheetData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [preview, setPreview] = useState({
    fileName: "",
    fileUrl: "",
    type: "",
    fileType: "",
    fileData: null,
  });

  const mode_of_transport_list = ["Car", "Bike", "Auto"];

  const handleClosePreview = () => {
    setShowModal(false);
    setSheetData(null);
    setPreview({
      fileName: "",
      fileUrl: "",
      type: "",
      fileType: "",
      fileData: null,
    });
  };

  // closing of modal
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    handleClose("addTravel");
    handleClose("addAttach");
    // setExpanded(false);
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };

  // reload of attachments when delete
  const reloadTravelPage = () => {
    setReloadData((prev) => !prev);
  };

  const handleOpen = (name, editData = {}) => {
    setModalOpen((prev) => ({
      ...prev,
      [name]: true,
    }));
    if (Object.keys(editData).length > 0) {
      // console.log("inside it.....");
      if (name === "status") {
        console.log("status");
      }
    }
  };

  const handleClose = (name) => {
    setModalOpen((prev) => ({
      ...prev,
      [name]: false,
    }));

    setStartTravel((prev) => ({
      ...prev,
      mode: "",
      latt: "",
      long: "",
      address: "",
      attachments: {},
    }));
  };

  const handleFilePreview = async (attachment) => {
    try {
      const { id, file_name, type, fileType } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));
      let fileUrl = "";
      let payload = {
        visitId: visitId,
        attachId: attachment?.id,
      };

      const response = await fetchApi(
        "download_visit_attachment",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200) {
        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
          fileUrl = `data:image/${fileType};base64,${response?.data?.data}`;
        } else if (fileType === "pdf") {
          fileUrl = `data:application/pdf;base64,${response?.data?.data}`;
        } else if (["xlsx", "xls"].includes(fileType)) {
          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data?.data}`;
        } else if (fileType === "csv") {
          fileUrl = `data:text/csv;base64,${response?.data?.data}`;
        } else if (fileType === "mp3") {
          fileUrl = `data:audio/mp3;base64,${response?.data?.data}`;
        } else {
          return null;
        }
        setPreview({
          fileName: file_name,
          fileUrl: fileUrl,
          type: type,
          fileType: fileType,
          fileData: response?.data?.data,
        });
        if (["xlsx", "xls", "csv"].includes(fileType)) {
          const binaryStr = window.atob(response?.data?.data);
          const binaryData = new Uint8Array(
            [...binaryStr].map((char) => char.charCodeAt(0))
          );
          const workbook = XLSX.read(binaryData, { type: "array" });

          const sheetName = workbook.SheetNames[0]; // Get the first sheet
          const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
          const sheetJson = XLSX.utils.sheet_to_json(worksheet, {
            header: 1, // Keep as an array of arrays
            defval: "-", // Fill empty cells with placeholder
          });

          // Store the first 100 rows of the Excel sheet in sheetData
          setSheetData(sheetJson.slice(0, 100));
        } else {
          setShowModal(true);
        }
      } else if (response.statusCode !== 200) {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      openSnackbar("Some error occured while Downloading File!", "error");
    } finally {
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    }
  };

  const handleDeleteBtn = async (attachment) => {
    // console.log("travel attchment clciked:", attachment);

    setConfirmDeletionState((prev) => ({
      isOpen: true,
      modalType: "visit_attachment",
      ticketId: visitId,
      attachId: attachment.id,
    }));
  };

  const handleDownloadBtn = async (attachment) => {
    try {
      const { id, file_name, type, fileType } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));

      // Prepare payload for the API request
      const payload = {
        attachId: id,
      };

      // Fetch binary data from the server
      const response = await fetchApi(
        "download_visit_attachment",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        // Base64 binary data
        const binaryData = response?.data?.data;
        // Construct the file URL based on the file type
        let fileUrl;
        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
          fileUrl = `data:image/${fileType};base64,${binaryData}`;
        } else if (fileType === "pdf") {
          fileUrl = `data:application/pdf;base64,${binaryData}`;
        } else if (["xlsx", "xls"].includes(fileType)) {
          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${binaryData}`;
        } else if (fileType === "csv") {
          fileUrl = `data:text/csv;base64,${binaryData}`;
        } else if (fileType === "mp3") {
          fileUrl = `data:audio/mp3;base64,${binaryData}`;
        } else {
          openSnackbar("Unsupported file type for download.", "warning");
          return null;
        }

        // Trigger the download using a temporary anchor element
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        openSnackbar("Failed to download the file. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error during file download:", error);
      openSnackbar("Some error occurred while downloading the file!", "error");
    } finally {
      // Reset loading state
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    }
  };

  const handleDownloadAllBtn = async (attachments, tag) => {
    try {
      const listIds = attachments.map((attachment) => attachment.id);
      console.log("listIds:", listIds);
      // Set loading state
      setDownloading((prev) => ({
        ...prev,
        load: true,
        tag: tag,
      }));

      // Prepare payload for the API request
      const payload = {
        attachIds: listIds,
      };

      // Fetch binary data from the server
      const response = await fetchApi(
        "download_multi_visit_attachment",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        // The server response contains a list of Base64 binary data
        const binaryDataList = response?.data?.data;

        if (binaryDataList?.length !== attachments.length) {
          openSnackbar(
            "Some files failed to download. Please try again.",
            "warning"
          );
          return;
        }

        // Loop through each attachment and download them
        attachments.forEach((attachment, index) => {
          const binaryData = binaryDataList[index]; // Corresponding binary data
          if (!binaryData) {
            console.warn(
              `No binary data found for attachment ID: ${attachment.id}`
            );
            return;
          }

          // Determine the file URL based on the file type
          let fileUrl;
          if (["png", "jpg", "jpeg", "webp"].includes(attachment.fileType)) {
            fileUrl = `data:image/${attachment.fileType};base64,${binaryData}`;
          } else if (attachment.fileType === "pdf") {
            fileUrl = `data:application/pdf;base64,${binaryData}`;
          } else if (["xlsx", "xls"].includes(attachment.fileType)) {
            fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${binaryData}`;
          } else if (attachment.fileType === "csv") {
            fileUrl = `data:text/csv;base64,${binaryData}`;
          } else if (attachment.fileType === "mp3") {
            fileUrl = `data:audio/mp3;base64,${binaryData}`;
          } else {
            openSnackbar(
              `Unsupported file type for download: ${attachment.fileType}`,
              "warning"
            );
            return;
          }

          // Trigger the download for each file
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = attachment.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });

        openSnackbar("All files downloaded successfully!", "success");
      } else {
        openSnackbar(
          "Failed to download the files. Please try again.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error during file download:", error);
      openSnackbar("Some error occurred while downloading the files!", "error");
    } finally {
      // Reset loading state
      setDownloading((prev) => ({
        ...prev,
        load: false,
        tag: null,
      }));
    }
  };

  const savedTravel = async () => {
    try {
      setLoading(true);
      const payload = {
        visit_id: visitId,
        mode: startTravel?.mode,
        lattitude: startTravel?.latt,
        longitude: startTravel?.long,
        address: startTravel.address,
      };

      const response = await fetchApi("add_indi_travel", "POST", payload, true);

      if (response.statusCode === 200) {
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "travel");
        handlePagesData(null, "timeline");
        handleClose("addTravel");
        setExpanded(false);
        setReloadData((prevState) => !prevState);
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while fetching filters!", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAttachment = async (attachments) => {
    try {
      const attachmentsData = await Promise.all(
        Object.keys(attachments || {}).flatMap(async (tag) => {
          const files = attachments[tag];

          return Promise.all(
            files.map(async (data) => {
              const arrayBuffer = await urlToFileArrayBuffer(data.blobData); // Use blobData for object URL
              const uint8Array = new Uint8Array(arrayBuffer);

              return {
                tag, // Include the tag here
                file_name: data.file_name,
                file_data: Array.from(uint8Array),
              };
            })
          );
        })
      ).then((results) => results.flat());

      const payload = {
        visit_id: visitId,
        travel_id: expanded,
        attachment_data: attachmentsData,
      };
      setLoading(true);

      const response = await fetchApi(
        "add_travelTag_attachments",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "travel");
        handlePagesData(null, "attachments");
        handlePagesData(null, "timeline");
        handleClose("addAttach");
        setReloadData((prevState) => !prevState);
        setExpanded(false);
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while fetching filters!", "error");
    } finally {
      setLoading(false);
    }
  };

  const saveEndPoint = async (travel_id) => {
    try {
      setLoading(true);
      const payload = {
        visit_id: visitId,
        travel_id: travel_id,
        lattitude: startTravel?.latt,
        longitude: startTravel?.long,
        address: startTravel.address,
      };
      // console.log("payload:", payload);

      const response = await fetchApi("add_end_point", "POST", payload, true);

      if (response.statusCode === 200) {
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "travel");
        handlePagesData(null, "timeline");
        handleClose("addTravel");
        setReloadData((prevState) => !prevState);
        setExpanded(false);
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while fetching filters!", "error");
    } finally {
      setLoading(false);
    }
  };

  // Function to get coordinates and address
  const handleGetLocation = async () => {
    try {
      setCordinateLoading(true);
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;

          // Save coordinates to state
          setStartTravel((prevData) => ({
            ...prevData,
            latt: latitude,
            long: longitude,
          }));

          // Use a reverse geocoding service to get the address
          const address = await reverseGeocode(latitude, longitude);
          setStartTravel((prevData) => ({
            ...prevData,
            address: address,
          }));
        });
      } else {
        console.log("Geolocation is not available");
      }
    } catch {
      console.log("Some error occured while fetching co-ordinates.");
    }
  };

  // Dummy function for reverse geocoding (replace with actual API call)
  const reverseGeocode = async (lat, lon) => {
    try {
      // Example using Nominatim's public API:
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
      );
      const data = await response.json();
      return data.display_name || "Unknown location";
    } catch {
      console.log("Some error occured while fetching location.");
    } finally {
      setCordinateLoading(false);
    }
  };

  const AddressDisplay = ({ startAddress, endAddress }) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        {/* Start Address */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <LocationOnIcon color="primary" fontSize="small" />
          <Typography variant="body2" color="textPrimary">
            {startAddress}
          </Typography>
        </Box>

        {/* Separator and End Address */}
        {endAddress ? (
          <>
            <ArrowForwardIcon color="action" fontSize="small" />
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <LocationOnIcon color="secondary" fontSize="small" />
              <Typography variant="body2" color="textSecondary">
                {endAddress}
              </Typography>
            </Box>
          </>
        ) : (
          // Fallback Design When Only Start Address is Available
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ color: "#6c757d", ml: 1, fontSize: "14px" }}
          >
            (Travel in progress)
          </Typography>
        )}
      </Box>
    );
  };

  const fetchTravelData = async () => {
    try {
      if (pagesData?.travel) {
        return;
      }
      setLoadingVisiblity(true);

      const payload = {
        visit_id: visitId,
      };

      const response = await fetchApi(
        "each_visit_travels",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        showErrorPage(null, null);
        handlePagesData(response?.data, "travel");
      } else {
        handlePagesData(null, "travel");
        showErrorPage(response?.statusCode, response.msg);
      }
    } catch {
      handlePagesData(null, "travel");
      showErrorPage(500, "Some Error Occured!");
    } finally {
      setLoadingVisiblity(false);
    }
  };

  // const [basicData, setBasicData] = useState({
  //   ticketId: "",
  //   status: "",
  // });

  // const fetchAllData = async () => {
  //   try {
  //     if (pagesData?.travel) {
  //       return;
  //     }

  //     const payload = {
  //       visit_id: visitId,
  //     };

  //     const response = await fetchApi("fetch_basicData", "POST", payload, true);

  //     if (response.statusCode === 200) {
  //       showErrorPage(null, null);
  //       setBasicData((prev) => ({
  //         ...prev,
  //         ticketId: response?.data?.ticketId,
  //         status: response?.data?.status,
  //       }));
  //       await fetchTravelData();
  //     } else {
  //       showErrorPage(response?.statusCode, response?.msg);
  //     }
  //   } catch {
  //     showErrorPage(500, "Some Error Occured!");
  //   } finally {
  //     setLoadingVisiblity(false);
  //   }
  // };

  useEffect(() => {
    showErrorPage(null, null);
    document.title = "Visit Travel - NEO";
    setLoadingVisiblity(true);
    fetchTravelData();
  }, [reloadData]);

  return (
    <>
      {(loadingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <TravelPageSkeleton len={4} color={color} />
      )) ||
        (!showError.status && (
          <div className="d-flex flex-column gap-2">
            <div className="add-more px-1">
              <div
                className="m-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RiAddBoxFill
                  onClick={
                    ["Scheduled"].includes(pagesData?.travel?.status)
                      ? null // Disable the click
                      : (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleOpen("addTravel");
                        }
                  }
                  style={{
                    color: ["Scheduled"].includes(pagesData?.travel?.status)
                      ? "#cccccc" // Disabled color
                      : "#666bff", // Enabled color
                    fontSize: "30px",
                    cursor: ["Scheduled"].includes(pagesData?.travel?.status)
                      ? "not-allowed" // Disabled cursor
                      : "pointer", // Enabled cursor
                  }}
                  className="increase-style"
                />
                <span style={{ marginLeft: "5px" }}>Add Travel</span>
              </div>
            </div>
            {(pagesData?.travel?.data &&
              pagesData?.travel?.data?.length > 0 && (
                <div
                  className="d-flex flex-column gap-3"
                  style={{ margin: "10px" }}
                >
                  {pagesData?.travel?.data &&
                    pagesData?.travel?.data?.map((travel) => (
                      <Accordion
                        key={travel.id}
                        expanded={expanded === travel.id}
                        onChange={handleChange(travel.id)}
                        className="accordian-border-style"
                        sx={{
                          "&.MuiAccordion-root": {
                            position: "static",
                          },
                          "&.Mui-expanded": {
                            margin: "1px 0",
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${travel.id}-content`}
                          id={`panel${travel.id}-header`}
                          sx={{
                            borderBottom:
                              expanded === travel.id
                                ? "1px solid #ccc"
                                : "none",
                          }}
                          className="mx-3 px-0"
                        >
                          <div className="d-flex justify-content-between align-items-center gap-3 w-100">
                            <Typography
                              sx={{
                                // width: "33%",
                                // flexShrink: 0,
                                color:
                                  expanded === travel.id
                                    ? "#666bff"
                                    : "inherit",
                                fontSize: "20px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div className="d-flex align-items-center gap-1">
                                <IoMdCar
                                  style={{
                                    marginBottom: "2px",
                                    fontSize: "25px",
                                  }}
                                />
                                {travel.id}
                              </div>
                            </Typography>
                            {!isMediumScreen && (
                              <AddressDisplay
                                startAddress={travel["Start Address"]}
                                endAddress={travel["End Address"]}
                              />
                            )}
                            <Typography
                              className={`${
                                travel.Status === "Done"
                                  ? "bg-success-subtle text-success-emphasis"
                                  : "bg-primary-subtle text-primary-emphasis"
                              } badge rounded-pill px-3 py-2`}
                              sx={{
                                color:
                                  travel.Status === "Done"
                                    ? "green"
                                    : "#666bff",
                                fontSize: "18px",
                                // marginLeft: "auto",
                                paddingRight: "20px",
                              }}
                            >
                              {travel.Status}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ padding: 0, margin: "10px 0px" }}
                        >
                          <div className="d-flex flex-column gap-3 p-2 mb-2">
                            {
                              <div
                                className="card-Div-1"
                                style={{
                                  padding: isSmallSmallScreen
                                    ? "15px 25px"
                                    : "15px 25px",
                                  margin: "6px 6px",
                                  // boxShadow:
                                  //   "rgba(0, 0, 0, 0.15) 0px 0px 3px 0px",
                                }}
                              >
                                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                                  <p
                                    className="m-0"
                                    style={{
                                      fontFamily: "Open-Sans-Light",
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      color: "rgba(76, 78, 100, 1)",
                                    }}
                                  >
                                    Ride Info
                                  </p>
                                  {travel["Status"] === "Done" &&
                                    !travel?.attachments && (
                                      <p
                                        className="m-0 hoverText d-flex justify-content-between align-items-center"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          handleOpen("addAttach");
                                        }}
                                      >
                                        <RiAttachmentLine
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleOpen("addAttach");
                                          }}
                                          style={{
                                            // color: "#556edd",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <span style={{ marginLeft: "5px" }}>
                                          Add
                                        </span>{" "}
                                      </p>
                                    )}
                                </div>
                                <div className="basic-info mb-3">
                                  {Object.entries(travel).map(
                                    ([label, content], index) =>
                                      (label !== "id" &&
                                        label !== "attachments" &&
                                        content !== null && (
                                          <div key={index} className="p-0">
                                            <p className="label-style">
                                              {label}
                                            </p>
                                            <p className="below-content-style">
                                              {[
                                                "Start Time",
                                                "End Time",
                                              ].includes(label)
                                                ? formatDate(content)
                                                : content}
                                            </p>
                                          </div>
                                        )) ||
                                      ""
                                  )}
                                </div>
                                {travel["Status"] !== "Done" && (
                                  <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-4 mt-2">
                                    {/* <Button
                                      variant="outlined"
                                      onClick={() => handleGetLocation()}
                                      style={{
                                        margin: "5px 0px",
                                        padding: "8px 12px",
                                        textTransform: "capitalize",
                                        fontSize: "16px",
                                      }}
                                    >
                                      End Point
                                    </Button> */}
                                    <LoadingButton
                                      sx={{
                                        color: "white",
                                        backgroundColor: "#666bff",
                                        borderRadius: "8px",
                                        margin: "5px 0px",
                                        padding: "8px 12px",
                                        fontSize: "15px",
                                        textTransform: "capitalize",
                                        "&:hover": {
                                          color: "white",
                                          backgroundColor: "#666bff",
                                        },
                                      }}
                                      size="large"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleGetLocation();
                                      }}
                                      endIcon={<SendIcon />}
                                      loading={cordinateLoading}
                                      disabled={startTravel?.address}
                                      loadingPosition="end"
                                      variant="contained"
                                    >
                                      <span>End Point</span>
                                    </LoadingButton>
                                    {startTravel?.latt && (
                                      <Typography sx={{ mt: 1 }}>
                                        <strong
                                          style={{
                                            color: "rgba(76,78,100,0.9",
                                          }}
                                        >
                                          Coordinates:
                                        </strong>{" "}
                                        ({startTravel.latt}, {startTravel?.long}
                                        )
                                        <br />
                                        <strong
                                          style={{
                                            color: "rgba(76,78,100,0.9",
                                          }}
                                        >
                                          Location:
                                        </strong>{" "}
                                        {startTravel?.address || "Fetching..."}
                                      </Typography>
                                    )}
                                    <Typography
                                      className="float-end"
                                      // style={{ margin: "auto" }}
                                    >
                                      <LoadingButton
                                        // style={{
                                        // margin: "5px 0px",
                                        // padding: "8px 12px",
                                        //   textTransform: "capitalize",
                                        //   fontSize: "16px",
                                        // }}
                                        sx={{
                                          color: "white",
                                          backgroundColor: "#666bff",
                                          borderRadius: "8px",
                                          margin: "5px 0px",
                                          padding: "8px 12px",
                                          textTransform: "capitalize",
                                          "&:hover": {
                                            color: "white",
                                            backgroundColor: "#666bff",
                                          },
                                        }}
                                        size="large"
                                        onClick={() => saveEndPoint(travel.id)}
                                        disabled={
                                          !startTravel?.latt ||
                                          !startTravel?.address
                                        }
                                        endIcon={<SendIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                      >
                                        <span>Save</span>
                                      </LoadingButton>
                                    </Typography>
                                  </div>
                                )}
                              </div>
                            }
                            {travel?.attachments && (
                              <div
                                className="card-Div-1"
                                style={{
                                  padding: isSmallScreen
                                    ? "10px 15px"
                                    : "15px 25px",
                                  margin: "0px 6px",
                                }}
                              >
                                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                  <p
                                    className="m-0"
                                    style={{
                                      fontFamily: "Open-Sans-Light",
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      color: "rgba(76, 78, 100, 1)",
                                    }}
                                  >
                                    Attachments
                                  </p>
                                  {travel["Status"] === "Done" &&
                                    travel?.attachments && (
                                      <p
                                        className="m-0 hoverText d-flex justify-content-between align-items-center"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          handleOpen("addAttach");
                                        }}
                                      >
                                        <RiAttachmentLine
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleOpen("addAttach");
                                          }}
                                          style={{
                                            // color: "#556edd",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <span style={{ marginLeft: "5px" }}>
                                          Add
                                        </span>{" "}
                                      </p>
                                    )}
                                </div>
                                <div className="">
                                  <ShowAttachments
                                    attachments={travel?.attachments}
                                    handleFilePreview={handleFilePreview}
                                    handleDownloadBtn={handleDownloadBtn}
                                    handleDeleteBtn={handleDeleteBtn}
                                    isSmallScreen={isSmallScreen}
                                    showDownload={true}
                                    downloadAll={true}
                                    handleDownloadAllBtn={handleDownloadAllBtn}
                                    dataLoading={dataLoading}
                                    downloading={downloading}
                                    isDataWithTag={true}
                                    color={color}
                                  />
                                </div>
                                {(showModal || sheetData) && (
                                  <div>
                                    <ShowPreview
                                      showModal={showModal}
                                      handleClosePreview={handleClosePreview}
                                      sheetData={sheetData}
                                      previewData={preview}
                                      isSmallScreen={isSmallScreen}
                                      color={color}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  {modalOpen?.addAttach && (
                    <TagAttachmentModal
                      modalOpen={modalOpen}
                      genFields={startTravel}
                      setGenFields={setStartTravel}
                      isSmallScreen={isSmallScreen}
                      // travel_id={expanded}
                      handleClose={handleClose}
                      options={options}
                      color={color}
                      isMediumScreen={isMediumScreen}
                      isSmallSmallScreen={isSmallSmallScreen}
                      islageScreen={islageScreen}
                      islagelargeScreen={islagelargeScreen}
                      handleSaveAttachment={handleSaveAttachment}
                      loading={loading}
                    />
                  )}
                </div>
              )) || (
              <div
                className=""
                style={{
                  margin: "5px",
                  borderRadius: "10px",
                  minHeight: "100px",
                }}
              >
                <Placeholder
                  message={
                    pagesData?.travel?.status === "Scheduled"
                      ? "You can add travel details only after the visit has been started."
                      : "Oops! No Travel is Added for this visit."
                  }
                  icon={<DirectionsCarOutlinedIcon fontSize="inherit" />}
                />
              </div>
            )}
          </div>
        )) ||
        (showError.status && <Error pageHeight={"80vh"} />)}
      {confirmDeletionState.isOpen && (
        <ConfirmDeletionModal
          deleteModalState={confirmDeletionState}
          handleClose={closeConfirmDelectionModal}
          handleReload={reloadTravelPage}
          handlePagesData={handlePagesData}
          pages={["travel", "attachments", "timeline"]}
        />
      )}
      {/* validate visit successfull or not  */}
      <Modal
        style={{
          position: "fixed",
          height: "100%",
          overflowY: "scroll",
          margin: "0px 0px 0px 10px",
        }}
        open={modalOpen?.addTravel}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <Box sx={style2}>
          <Typography
            style={{
              padding: "16px",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
            className="d-flex justify-content-between"
          >
            <h5 className="mb-0">Add Travel</h5>
            <div onClick={() => handleClose("addTravel")}>
              <Tooltip title={"Close"} arrow>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </Typography>
          <Typography className="p-3 pb-0">
            <div className="row row-gap-3 mb-3">
              <div className="col-lg-5">
                <Autocomplete
                  name="status"
                  id="tags-outlined"
                  value={startTravel.mode}
                  options={mode_of_transport_list || []}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  onChange={(e, selectedOption) => {
                    setStartTravel((prev) => ({
                      ...prev,
                      mode: selectedOption,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Mode *"
                      placeholder="Select Mode"
                      fullWidth
                      sx={{
                        "& .MuiAutocomplete-input": {
                          textTransform: "capitalize",
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-option": {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </div>
              <div className="col-lg-7">
                <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-4">
                  {/* <Button
                    variant="outlined"
                    onClick={() => handleGetLocation()}
                    style={{
                      margin: "5px 0px",
                      padding: "8px 12px",
                      textTransform: "capitalize",
                      fontSize: "16px",
                    }}
                  >
                    Start Point
                  </Button> */}
                  <LoadingButton
                    sx={{
                      color: "white",
                      backgroundColor: "#666bff",
                      borderRadius: "8px",
                      margin: "5px 0px",
                      padding: "8px 12px",
                      fontSize: "15px",
                      textTransform: "capitalize",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#666bff",
                      },
                    }}
                    size="large"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleGetLocation();
                    }}
                    endIcon={<SendIcon />}
                    loading={cordinateLoading}
                    disabled={startTravel?.address}
                    loadingPosition="end"
                    variant="contained"
                  >
                    <span>Start Point</span>
                  </LoadingButton>
                  {startTravel?.latt && (
                    <Typography sx={{ mt: 1 }}>
                      <strong style={{ color: "rgba(76,78,100,0.9" }}>
                        Coordinates:
                      </strong>{" "}
                      ({startTravel.latt}, {startTravel?.long})
                      <br />
                      <strong style={{ color: "rgba(76,78,100,0.9" }}>
                        Location:
                      </strong>{" "}
                      {startTravel?.address || "Fetching..."}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </Typography>
          <Typography className="float-end p-3">
            <LoadingButton
              sx={{
                color: "white",
                backgroundColor: "#666bff",
                borderRadius: "8px",
                textTransform: "capitalize",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#666bff",
                },
              }}
              size="large"
              onClick={() => savedTravel()}
              disabled={
                !startTravel?.mode ||
                !startTravel?.latt ||
                !startTravel?.address
              }
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default EachVisitTravel;
