import React, { useState, useEffect, useContext } from "react";
import ContextState from "../../../contextApi/ContextState";
import "../../../../assets/styles/CardModal.css";
import SendIcon from "@mui/icons-material/Send";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import FilesUpload from "../../../RaiseTicketForm/FilesUpload";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalError from "../../../error/ModalError";
import "react-image-lightbox/style.css";
import { LoadingButton } from "@mui/lab";
import ConfirmDeletionModal from "../../../modals/ConfirmDeletionModal";
import { BeatLoader } from "react-spinners";
import ProductImageViewer from "./ProductImageViewer";
import { useOutletContext } from "react-router-dom";
import ShowAttachments from "../../../Attachment/ShowAttachments";
import * as XLSX from "xlsx";
import ShowPreview from "../../../Attachment/ShowPreview";
import { AttachmentSkeleton } from "../../../Exporters/ExportFunctions";
import Placeholder from "../../../error/Placeholder";

const ProductAttachment = ({ reloadAttachmentsPage, refreshBtn }) => {
  const { data, color, refresgProductDetails } = useOutletContext();

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  const theme = useTheme();
  const {
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    controllerRef,
    capitalizeWords,
  } = useContext(ContextState);
  const [edit, setEdit] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [attachmentStatus, setAttachmentStatus] = useState(false);

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
    refresgProductDetails();
  };

  const { productId } = data;
  const [attachments, setAttachments] = useState({});
  const [sheetData, setSheetData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [queryStartTime, setQueryStartTime] = useState(null);

  const [preview, setPreview] = useState({
    fileName: "",
    fileUrl: "",
    type: "",
    fileType: "",
    fileData: null,
  });

  // delection modal state
  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    productId: null,
    attachId: null,
    attachName: "",
    startedQueryTime: null,
  });

  let isMounted;
  const [dataLoading, setDataLoading] = useState({
    load: false,
    attach_id: null,
  });

  const handleFilePreview = async (attachment) => {
    // console.log("--> ", attachment);
    try {
      const { id, file_name, type, fileType } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));
      let fileUrl = "";
      let payload = {
        product_id: productId,
        attachment_id: attachment?.id,
        info_type: "attachments",
        action: "single_file_data",
      };
      const response = await fetchApi(
        "get_product_info",
        "POST",
        payload,
        true
      );
      // console.log("response Data :", response);
      if (response?.statusCode === 200) {
        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
          fileUrl = `data:image/${fileType};base64,${response?.data?.data}`;
        } else if (fileType === "pdf") {
          fileUrl = `data:application/pdf;base64,${response?.data?.data}`;
        } else if (["xlsx", "xls"].includes(fileType)) {
          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data?.data}`;
        } else if (fileType === "csv") {
          fileUrl = `data:text/csv;base64,${response?.data?.data}`;
        } else if (fileType === "mp3") {
          fileUrl = `data:audio/mp3;base64,${response?.data?.data}`;
        } else {
          return null;
        }
        setPreview({
          fileName: file_name,
          fileUrl: fileUrl,
          type: type,
          fileType: fileType,
          fileData: response?.data?.data,
        });
        if (["xlsx", "xls", "csv"].includes(fileType)) {
          const binaryStr = window.atob(response?.data?.data);
          const binaryData = new Uint8Array(
            [...binaryStr].map((char) => char.charCodeAt(0))
          );
          const workbook = XLSX.read(binaryData, { type: "array" });
          const sheetName = workbook.SheetNames[0]; // Get the first sheet
          const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
          const sheetJson = XLSX.utils.sheet_to_json(worksheet, {
            header: 1, // Keep as an array of arrays
            defval: "-", // Fill empty cells with placeholder
          });
          // Store the first 100 rows of the Excel sheet in sheetData
          setSheetData(sheetJson.slice(0, 100));
        } else {
          setShowModal(true);
        }
      } else if (response?.statusCode !== 200) {
        openSnackbar(response.msg, "error");
      } else if (!response) {
        console.log("aborted the privious query ...");
      }
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    } catch (error) {
      console.log("error while Fetching file : ", error);
      if (controllerRef.current) {
        // console.log("abort error ");
      } else {
        // console.log("not a abort error ");
        openSnackbar("Some error occured while Fetching File!", "error");
        setDataLoading((prev) => ({
          ...prev,
          load: false,
          attach_id: null,
        }));
      }
    }
  };

  const handleClosePreview = () => {
    setShowModal(false);
    setSheetData(null);
    setPreview({
      fileName: "",
      fileUrl: "",
      type: "",
      fileType: "",
      fileData: null,
    });
  };

  const handleDownloadBtn = async (attachment) => {
    //   console.log("--> ", attachment);

    try {
      const { id, file_name } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));
      // Prepare payload for the API request
      const payload = {
        attach_id: id,
        info_type: "attachments",
        action: "single_file_data",
      };
      // Fetch binary data from the server
      const response = await fetchApi(
        "get_product_info",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200) {
        // Base64 binary data is available directly
        const binaryData = response?.data?.data;
        // Decode the Base64 binary data (if needed)
        const byteCharacters = atob(binaryData);
        const byteNumbers = new Array(byteCharacters.length).map((_, i) =>
          byteCharacters.charCodeAt(i)
        );
        const byteArray = new Uint8Array(byteNumbers);
        // Create a downloadable file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(
          new File([byteArray], file_name, { type: attachment.fileType })
        );
        link.download = file_name; // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        openSnackbar("Failed to download the file. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error during file download:", error);
      openSnackbar("Some error occurred while downloading the file!", "error");
    } finally {
      // Reset loading state
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    }
  };

  const handleDeleteBtn = async (attachment) => {
    // console.log("open modal to confom and delete--> ", attachment);

    setConfirmDeletionState((prev) => ({
      ...prev,
      isOpen: true,
      modalType: "product_attachment",
      productID: productId,
      attachId: attachment.id,
      attachName: attachment.file_name,
      startedQueryTime: queryStartTime,
    }));
  };

  const [loading, setLoading] = useState(true);

  const displayAttachments = async (isMounted) => {
    try {
      let payload = {
        product_id: productId,
        info_type: "attachments",
        action: "all_files",
      };
      setLoading(true);

      // if (refreshBtn) {
      //   setRefreshLoading(true);
      // }

      const jsonData = await fetchApi(
        "get_product_info",
        "POST",
        payload,
        true,
        1
      );

      if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
        if (isMounted) {
          const attachmentsData = jsonData.data.attachments;
          setAttachments(attachmentsData);
          showModalErrorPage(null, null);
        }
      } else if (jsonData?.statusCode === 500 || jsonData?.statusCode === 401) {
        // openSnackbar(jsonData.data, "error")
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
        // showLoadingModal(false);
      } else {
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      }
      setLoading(false);
    } catch (error) {
      if (controllerRef.current) {
        // openSnackbar("Request Timed Out!!!", "warning");
        console.log("fetch abort in attchemnts ... ");
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      // setRefreshLoading(false);
      // setRefreshBtn(false);
    }
  };

  // function to upload the files and status
  const [uploadButtonLoading, setUploadButtonLoading] = useState(false);
  const handleUpload = async () => {
    setUploadButtonLoading(true);
    // console.log("attachment files:", genFields);

    let formData = {};
    const attachmentsData = await Promise.all(
      genFields.attachments.map(async (data) => {
        // console.log(data);
        // Fetch array buffer from URL
        const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
        // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
        const uint8Array = new Uint8Array(arrayBuffer);

        // console.log(arrayBuffer);
        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer]);
        // console.log("blob-", blob);
        // Return object with file name and FormData
        return {
          file_name: data.name,
          file_data: Array.from(uint8Array),
        };
      })
    );
    formData = {
      product_id: productId,
      info_type: "attachment",
      query_start_time: queryStartTime,
      attachments: attachmentsData,
    };

    // let isMounted = true;
    const response = await fetchApi(
      "edit_product_info",
      "POST",
      formData,
      true
    );
    if (response && response.statusCode === 200) {
      //   console.log("files uploaded syccessfully", response.data);
      openSnackbar(response.data.message, "success");
      setGenFields((prevState) => ({
        ...prevState,
        attachments: [],
      }));
      setEdit(false);
      handleReloadData();
      refresgProductDetails();
    } else if (response.statusCode === 500) {
      openSnackbar("Internal sever error!!! Try after some time.", "error");
    } else {
      openSnackbar(response.msg.message, "error");
    }
    setUploadButtonLoading(false);
  };

  // function to convert the url to filesarraybuffer type
  const urlToFileArrayBuffer = async (fileUrl) => {
    try {
      // Fetch the file content as a blob
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Read the blob as an array buffer
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(new Error("Failed to read file"));
        };
        reader.readAsArrayBuffer(blob);
      });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };

  useEffect(() => {
    isMounted = true;
    document.title = `Attachments of Product ID#${productId} - NEO`;

    if (isMounted) {
      let startTime = new Date();
      startTime = startTime.toString().split(" (")[0];
      //   console.log("startTime : ", startTime);
      setQueryStartTime(startTime);
      displayAttachments(isMounted);
    }

    return () => {
      isMounted = false;
      // On unmount, abort the previous request for fetchApi api
      // if (controllerRef.current) {
      //   controllerRef.current.abort();
      // }
    };
  }, [reloadData, reloadAttachmentsPage]);

  const [genFields, setGenFields] = useState({
    attachments: [],
  });
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // closing of modal
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      {(loading && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "absolute", height: "100%", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <AttachmentSkeleton
          isSmallSmallScreen={isSmallSmallScreen}
          isSmallScreen={isSmallScreen}
        />
      )) ||
        (!showModalError.status && (
          <>
            <div
              className="card-Div main-attachment-container "
              style={{ minHeight: "50vh" }}
            >
              <div className="m-3 ">
                <button
                  type="button"
                  className="btn btn-outline-primary markResolved_button"
                  disabled={ticketStatus === "resolved"}
                  onClick={() => setEdit(!edit)}
                >
                  <Tooltip
                    data-html="true"
                    title={"Click here to add attachments"}
                    arrow={true}
                  >
                    Add attachments
                  </Tooltip>
                </button>
              </div>

              {edit && (
                <>
                  <div className="attachments-section mx-3 mt-2 mb-4">
                    <FilesUpload
                      genFields={genFields}
                      setGenFields={setGenFields}
                      isSmallScreen={isSmallScreen}
                    />
                    {/* <div className="uploadButton pt-3"> */}
                    {false && (
                      <FormControlLabel
                        className="d-block pb-2"
                        control={
                          <Checkbox
                            checked={attachmentStatus}
                            onChange={(e) => {
                              setAttachmentStatus(e.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Status (Resolved)"
                      />
                    )}

                    <LoadingButton
                      size="large"
                      onClick={handleUpload}
                      endIcon={<SendIcon />}
                      loading={uploadButtonLoading}
                      className="mt-2"
                      disabled={genFields?.attachments?.length === 0}
                      loadingPosition="end"
                      variant="contained"
                      style={{
                        backgroundColor: uploadButtonLoading
                          ? "inherit"
                          : color.secondary,
                        color: uploadButtonLoading
                          ? "inherit"
                          : color.textWhiteColor,
                      }}
                    >
                      <span>Add</span>
                    </LoadingButton>
                  </div>
                  {/* </div> */}
                </>
              )}

              {(Object.keys(attachments).length !== 0 && (
                <div className="show-attachments-content p-3">
                  {/* attchment header */}
                  <header className="pb-2 previous-comments-header">
                    Attachments
                    {(attachments?.length > 1
                      ? `${" - (" + attachments.length + " Files)"}`
                      : attachments?.length === 1
                      ? " - (1 File)"
                      : "") || ""}
                  </header>

                  {/* showing attchment details */}
                  <div className="">
                    <ShowAttachments
                      attachments={attachments}
                      handleFilePreview={handleFilePreview}
                      handleDownloadBtn={handleDownloadBtn}
                      handleDeleteBtn={handleDeleteBtn}
                      isSmallScreen={isSmallScreen}
                      showDownload={true}
                      dataLoading={dataLoading}
                      isDataWithTag={true}
                      color={color}
                    />
                  </div>
                </div>
              )) || (
                <div className="mx-3 my-4" style={{ height: "30vh" }}>
                  <Placeholder
                    message="Oops! No Attachment is Added."
                    removeBoxShadow={true}
                  />
                </div>
              )}
              {/* showing the attachment details in model */}
              <div>
                {(showModal || sheetData) && (
                  <div>
                    <ShowPreview
                      showModal={showModal}
                      handleClosePreview={handleClosePreview}
                      sheetData={sheetData}
                      previewData={preview}
                      isSmallScreen={isSmallScreen}
                      color={color}
                    />
                  </div>
                )}
              </div>
            </div>
            {confirmDeletionState?.isOpen && (
              <ConfirmDeletionModal
                deleteModalState={confirmDeletionState}
                handleClose={closeConfirmDelectionModal}
                handleReload={handleReloadData}
              />
            )}
          </>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
};

export default ProductAttachment;
