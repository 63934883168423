import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import { BeatLoader } from "react-spinners";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";


import { lazy, Suspense } from "react";

import ContextState from "./Components/contextApi/ContextState";
import SideBar from "./Components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { addOnlineStatus } from "./Components/features/filtersSlice/filterSlice";

function App() {
  const {
    lodingVisiblity,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    autoHide,
    openSnackbar,
  } = useContext(ContextState);
  // console.log("Access token secret", process.env)

  const onlineStatus = useSelector((state) => state.onlineStatus.online);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = (setvalue) => {
    setOpen(setvalue);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // check website is online or not

  function handleIsOnline() {
    const data = {
      isOnline: true,
      timeStamp: new Date().toISOString(),
    };
    dispatch(addOnlineStatus(data));
    openSnackbar("Back to online", "success");
  }
  function handleIsOffline() {
    const data = {
      isOnline: false,
      timeStamp: new Date().toISOString(),
    };
    openSnackbar("You're Offline. Check your internet connection. ", "warning");
    dispatch(addOnlineStatus(data));
  }

  useEffect(() => {
    window.addEventListener("online", handleIsOnline);
    window.addEventListener("offline", handleIsOffline);

    return () => {
      // clearInterval(interval);
      window.removeEventListener("online", handleIsOnline);
      window.removeEventListener("offline", handleIsOffline);
    };
  }, [onlineStatus]);

  const [fontsLoaded, setFontsLoaded] = useState(false);

  // useEffect(() => {
  //   const fonts = [
  //     "Open-Sans-regular",
  //     "OpenSans-ExtraBold",
  //     "Open-Sans-semi-bold",
  //   ];

  //   // Wait for fonts to load
  //   Promise.all(fonts.map((font) => document.fonts.load(`1em ${font}`)))
  //     .then(() => setFontsLoaded(true))
  //     .catch(() => setFontsLoaded(true)); // Handle any errors gracefully
  // }, []);
  return (
    <>
      <Suspense
        fallback={
          <div className="showSpinner">
            <BeatLoader color="#2D97D4" />
          </div>
        }
      >
        <Router>
          {/* {(fontsLoaded && ( */}
            <SideBar
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
            />
          {/* )) || (
            <div
              style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={websiteLogo} alt="nebulaaLOGO" />
              <BeatLoader color="#2D97D4" style={{ opacity: 0.5 }} />
            </div>
          )} */}
          {/* <Navbar /> */}
          <Box sx={{ width: 500 }}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={closeSnackbar}
              message={snackbarMessage}
              severity={snackbarSeverity}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={closeSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              <Alert
                onClose={closeSnackbar}
                severity={snackbarSeverity}
                variant="filled"
                sx={{
                  width: "100%",
                  ".MuiAlert-action": {
                    display: onlineStatus ? "" : "none",
                  },
                }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
