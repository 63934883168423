import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import * as XLSX from "xlsx";
import ContextState from "../contextApi/ContextState";
import Error from "../error/Error";
import { RiAddBoxFill } from "react-icons/ri";
import ShowAttachments from "../Attachment/ShowAttachments";
import ShowPreview from "../Attachment/ShowPreview";
import ConfirmDeletionModal from "../modals/ConfirmDeletionModal";
import TagAttachmentModal from "../Attachment/TagAttachmentModal";
import {
  AttachmentSkeleton,
  urlToFileArrayBuffer,
} from "../Exporters/ExportFunctions";
import Placeholder from "../error/Placeholder";
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";

const EachVisitAttachment = () => {
  const {
    color,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
    visitId,
    pagesData,
    handlePagesData,
  } = useOutletContext();

  const {
    fetchApi,
    openSnackbar,
    showError,
    showErrorPage,
    modalControllerRef,
  } = useContext(ContextState);

  // this is for button loading
  const [loading, setLoading] = useState(false);

  // state to maintain allDOwnload
  const [downloading, setDownloading] = useState({
    load: false,
    tag: null,
  });

  // this is for loading the page
  const [loadingVisiblity, setLoadingVisiblity] = useState(true);

  // to reload the page on submit
  const [reloadData, setReloadData] = useState(false);

  // modals list
  const [modalOpen, setModalOpen] = useState({
    addAttach: false,
  });

  // to load the button while download or preview from backend
  const [dataLoading, setDataLoading] = useState({
    load: false,
    attach_id: null,
  });

  const options = [
    // { label: "Machine Report", value: "Machine Report" },
    { label: "Hotel Bill", value: "Hotel Bill" },
    { label: "Machine", value: "Machine" },
    { label: "Scanner", value: "Scanner" },
    { label: "Service Report", value: "Service Report" },
    { label: "Travel Bill", value: "Travel Bill" },
    { label: "Others", value: "Others" },
    // Add more options here
  ];

  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    ticketId: "",
    attachId: "",
    travel_id: "",
    tag: "",
  });

  // this state is for preview component
  const [sheetData, setSheetData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [preview, setPreview] = useState({
    fileName: "",
    fileUrl: "",
    type: "",
    fileType: "",
    fileData: null,
  });

  // attachment data
  // const [attachmentData, setAttachmentData] = useState();
  const [newAttachments, setNewAttachments] = useState({
    attachments: {},
  });

  const handleFilePreview = async (attachment) => {
    try {
      const { id, file_name, type, fileType } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));
      let fileUrl = "";
      let payload = {
        attachId: attachment?.id,
      };

      const response = await fetchApi(
        "download_visit_attachment",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200) {
        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
          fileUrl = `data:image/${fileType};base64,${response?.data?.data}`;
        } else if (fileType === "pdf") {
          fileUrl = `data:application/pdf;base64,${response?.data?.data}`;
        } else if (["xlsx", "xls"].includes(fileType)) {
          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data?.data}`;
        } else if (fileType === "csv") {
          fileUrl = `data:text/csv;base64,${response?.data?.data}`;
        } else if (fileType === "mp3") {
          fileUrl = `data:audio/mp3;base64,${response?.data?.data}`;
        } else {
          return null;
        }
        setPreview({
          fileName: file_name,
          fileUrl: fileUrl,
          type: type,
          fileType: fileType,
          fileData: response?.data?.data,
        });
        if (["xlsx", "xls", "csv"].includes(fileType)) {
          const binaryStr = window.atob(response?.data?.data);
          const binaryData = new Uint8Array(
            [...binaryStr].map((char) => char.charCodeAt(0))
          );
          const workbook = XLSX.read(binaryData, { type: "array" });

          const sheetName = workbook.SheetNames[0]; // Get the first sheet
          const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
          const sheetJson = XLSX.utils.sheet_to_json(worksheet, {
            header: 1, // Keep as an array of arrays
            defval: "-", // Fill empty cells with placeholder
          });

          // Store the first 100 rows of the Excel sheet in sheetData
          setSheetData(sheetJson.slice(0, 100));
        } else {
          setShowModal(true);
        }
      } else if (response.statusCode !== 200) {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      openSnackbar("Some error occured while Downloading File!", "error");
    } finally {
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    }
  };

  const handleDownloadBtn = async (attachment) => {
    try {
      const { id, file_name, type, fileType } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));

      // Prepare payload for the API request
      const payload = {
        attachId: id,
      };

      // Fetch binary data from the server
      const response = await fetchApi(
        "download_visit_attachment",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        // Base64 binary data
        const binaryData = response?.data?.data;
        // Construct the file URL based on the file type
        let fileUrl;
        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
          fileUrl = `data:image/${fileType};base64,${binaryData}`;
        } else if (fileType === "pdf") {
          fileUrl = `data:application/pdf;base64,${binaryData}`;
        } else if (["xlsx", "xls"].includes(fileType)) {
          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${binaryData}`;
        } else if (fileType === "csv") {
          fileUrl = `data:text/csv;base64,${binaryData}`;
        } else if (fileType === "mp3") {
          fileUrl = `data:audio/mp3;base64,${binaryData}`;
        } else {
          openSnackbar("Unsupported file type for download.", "warning");
          return null;
        }

        // Trigger the download using a temporary anchor element
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        openSnackbar("Failed to download the file. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error during file download:", error);
      openSnackbar("Some error occurred while downloading the file!", "error");
    } finally {
      // Reset loading state
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    }
  };

  const handleDownloadAllBtn = async (attachments, tag) => {
    try {
      const listIds = attachments.map((attachment) => attachment.id);
      console.log("listIds:", listIds);
      // Set loading state
      setDownloading((prev) => ({
        ...prev,
        load: true,
        tag: tag,
      }));

      // Prepare payload for the API request
      const payload = {
        attachIds: listIds,
      };

      // Fetch binary data from the server
      const response = await fetchApi(
        "download_multi_visit_attachment",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        // The server response contains a list of Base64 binary data
        const binaryDataList = response?.data?.data;

        if (binaryDataList?.length !== attachments.length) {
          openSnackbar(
            "Some files failed to download. Please try again.",
            "warning"
          );
          return;
        }

        // Loop through each attachment and download them
        attachments.forEach((attachment, index) => {
          const binaryData = binaryDataList[index]; // Corresponding binary data
          if (!binaryData) {
            console.warn(
              `No binary data found for attachment ID: ${attachment.id}`
            );
            return;
          }

          // Determine the file URL based on the file type
          let fileUrl;
          if (["png", "jpg", "jpeg", "webp"].includes(attachment.fileType)) {
            fileUrl = `data:image/${attachment.fileType};base64,${binaryData}`;
          } else if (attachment.fileType === "pdf") {
            fileUrl = `data:application/pdf;base64,${binaryData}`;
          } else if (["xlsx", "xls"].includes(attachment.fileType)) {
            fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${binaryData}`;
          } else if (attachment.fileType === "csv") {
            fileUrl = `data:text/csv;base64,${binaryData}`;
          } else if (attachment.fileType === "mp3") {
            fileUrl = `data:audio/mp3;base64,${binaryData}`;
          } else {
            openSnackbar(
              `Unsupported file type for download: ${attachment.fileType}`,
              "warning"
            );
            return;
          }

          // Trigger the download for each file
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = attachment.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });

        openSnackbar("All files downloaded successfully!", "success");
      } else {
        openSnackbar(
          "Failed to download the files. Please try again.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error during file download:", error);
      openSnackbar("Some error occurred while downloading the files!", "error");
    } finally {
      // Reset loading state
      setDownloading((prev) => ({
        ...prev,
        load: false,
        tag: null,
      }));
    }
  };

  const handleDeleteBtn = async (attachment) => {
    setConfirmDeletionState((prev) => ({
      ...prev,
      isOpen: true,
      modalType: "visit_attachment",
      ticketId: visitId,
      attachId: attachment.id,
    }));
  };

  // closing of modal
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    handleClose("addAttach");
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };

  // reload of attachments when delete
  const reloadAttachPage = () => {
    setReloadData((prev) => !prev);
  };

  const handleOpen = (name, editData = {}) => {
    setModalOpen((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleClose = (name) => {
    setModalOpen((prev) => ({
      ...prev,
      [name]: false,
    }));

    setNewAttachments((prev) => ({
      ...prev,
      attachments: {},
    }));
  };

  const handleClosePreview = () => {
    setShowModal(false);
    setSheetData(null);
    setPreview({
      fileName: "",
      fileUrl: "",
      type: "",
      fileType: "",
      fileData: null,
    });
  };

  const handleSaveAttachment = async (attachments) => {
    try {
      const attachmentsData = await Promise.all(
        Object.keys(attachments || {}).flatMap(async (tag) => {
          const files = attachments[tag];

          return Promise.all(
            files.map(async (data) => {
              const arrayBuffer = await urlToFileArrayBuffer(data.blobData); // Use blobData for object URL
              const uint8Array = new Uint8Array(arrayBuffer);

              return {
                tag, // Include the tag here
                file_name: data.file_name,
                file_data: Array.from(uint8Array),
              };
            })
          );
        })
      ).then((results) => results.flat());

      const payload = {
        visit_id: visitId,
        travel_id: null,
        attachment_data: attachmentsData,
      };
      setLoading(true);

      const response = await fetchApi(
        "add_travelTag_attachments",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "attachments");
        handlePagesData(null, "timeline");
        handleClose("addAttach");
        setReloadData((prevState) => !prevState);
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while fetching filters!", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchAttachmentData = async () => {
    try {
      if (pagesData?.attachments) {
        return;
      }

      const payload = {
        visit_id: visitId,
        info_type: "attachments",
      };
      const response = await fetchApi(
        "get_visit_ticket_info",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        showErrorPage(null, null);
        handlePagesData(response?.data, "attachments");
      } else {
        handlePagesData(null, "attachments");
        showErrorPage(response?.statusCode, response.msg);
      }
    } catch {
      handlePagesData(null, "attachments");
      showErrorPage(500, "Some Error Occured!");
    } finally {
      setLoadingVisiblity(false);
    }
  };

  useEffect(() => {
    showErrorPage(null, null);
    document.title = "Visit Attachments - NEO";
    setLoadingVisiblity(true);
    fetchAttachmentData();
  }, [reloadData]);

  return (
    <>
      {(loadingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <AttachmentSkeleton
          color={color}
          len={5}
          isSmallSmallScreen={isSmallSmallScreen}
          isSmallScreen={isSmallScreen}
        />
      )) ||
        (!showError.status && (
          <div className="d-flex flex-column gap-2">
            <div className="add-more px-1">
              <div
                className="m-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RiAddBoxFill
                  onClick={
                    (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleOpen("addAttach");
                    }
                    // ["Completed"].includes(pagesData?.attachments?.status)
                    //   ? (e) => {
                    //       e.preventDefault();
                    //       e.stopPropagation();
                    //       handleOpen("addAttach");
                    //     }
                    //   : null
                  }
                  style={{
                    color: "#666bff",
                    // color: !["Completed"].includes(
                    //   pagesData?.attachments?.status
                    // )
                    //   ? "#cccccc" // Disabled color
                    //   : "#556edd", // Enabled color
                    fontSize: "30px",
                    cursor: "pointer",

                    // !["Completed"].includes(
                    //   pagesData?.attachments?.status
                    // )
                    //   ? "not-allowed"
                    //   : "pointer",
                  }}
                  className="increase-style"
                />
                <span style={{ marginLeft: "5px" }}>Add Attachment</span>{" "}
              </div>
            </div>
            {(pagesData?.attachments?.attachments &&
              Object.keys(pagesData?.attachments?.attachments).length > 0 && (
                <div
                  className="d-flex flex-column gap-3 card-Div-1"
                  style={{
                    margin: "5px",
                    padding: isSmallScreen ? "10px 15px 0px 15px" : "15px 25px",
                    minHeight: "100px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div
                    className=""
                    style={{
                      fontFamily: "Open-Sans-Light",
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "rgba(76, 78, 100, 1)",
                    }}
                  >
                    Attachments
                  </div>
                  <div className="">
                    <ShowAttachments
                      attachments={pagesData?.attachments?.attachments}
                      handleFilePreview={handleFilePreview}
                      handleDownloadBtn={handleDownloadBtn}
                      handleDeleteBtn={handleDeleteBtn}
                      isSmallScreen={isSmallScreen}
                      showDownload={true}
                      downloadAll={true}
                      handleDownloadAllBtn={handleDownloadAllBtn}
                      dataLoading={dataLoading}
                      downloading={downloading}
                      isDataWithTag={true}
                      color={color}
                    />
                  </div>
                </div>
              )) || (
              <div
                className=""
                style={{
                  margin: "5px",
                  borderRadius: "10px",
                  minHeight: "100px",
                }}
              >
                <Placeholder
                  message={
                    pagesData?.attachments?.status === "Completed"
                      ? "Oops! No Attachment is Added for this visit."
                      : "Oops! No Attachment is Added for this visit."
                  }
                />{" "}
              </div>
            )}
          </div>
        )) ||
        (showError.status && <Error pageHeight={"80vh"} />)}
      {(showModal || sheetData) && (
        <div>
          <ShowPreview
            showModal={showModal}
            handleClosePreview={handleClosePreview}
            sheetData={sheetData}
            previewData={preview}
            isSmallScreen={isSmallScreen}
            color={color}
          />
        </div>
      )}
      {confirmDeletionState.isOpen && (
        <ConfirmDeletionModal
          deleteModalState={confirmDeletionState}
          handleClose={closeConfirmDelectionModal}
          handleReload={reloadAttachPage}
          handlePagesData={handlePagesData}
          pages={["travel", "attachments", "timeline"]}
        />
      )}
      {modalOpen?.addAttach && (
        <TagAttachmentModal
          modalOpen={modalOpen}
          genFields={newAttachments}
          setGenFields={setNewAttachments}
          isSmallScreen={isSmallScreen}
          handleClose={handleClose}
          options={options}
          color={color}
          isMediumScreen={isMediumScreen}
          isSmallSmallScreen={isSmallSmallScreen}
          islageScreen={islageScreen}
          islagelargeScreen={islagelargeScreen}
          handleSaveAttachment={handleSaveAttachment}
          loading={loading}
        />
      )}
    </>
  );
};

export default EachVisitAttachment;
