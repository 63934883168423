import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import close from "../../../assets/images/close.svg";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../../contextApi/ContextState";
import { color } from "../../modals/Calllog";
import { DisplayingProductDetailsTable } from "./DisplayingProductDetailsTable";
import { CiHeadphones } from "react-icons/ci";
import { ContentPasteSearchOutlined } from "@mui/icons-material";
import { IoIosAdd } from "react-icons/io";
import AddIcon from "@mui/icons-material/Add";
import addProductTypeImg from "../../../assets/images/addproductType.png";
import addProductTypeBlueImg from "../../../assets/images/addproductTypeBlue.png";
import { ClearIcon } from "@mui/x-date-pickers";

function ProductDetailsForm({
  genFields,
  setGenFields,
  fieldError,
  isSmallScreen,
  FiltersData,
}) {
  const theme = useTheme();
  const { openSnackbar, fetchApi } = useContext(ContextState);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");

  const [addNewProduct, setAddNewProduct] = useState({
    productCategory: "",
    productPrice: "",
    serialNumber: [],
    productType: "",
    quantity: "",
    product_id: "",
  });

  const [addProductError, setAddProductError] = useState({
    productNameError: false,
    productSerialNumberError: false,
    productPriceError: false,
    productQuantityError: false,
    productTypeError: false,
    serialMacthedError: false,
  });

  const [addNewProductType, setAddNewProductType] = useState({
    isOpen: false,
    productCategory: "",
    modelNumber: "",
    category: "",
    description: "",
    quantity: "",
    tracking_type: "",
    productName: "",
  });

  // console.log("genFields---->", genFields);
  useEffect(() => {
    console.log("Add new Product Type : ", addNewProductType);
  }, [addNewProductType, setAddNewProductType]);
  useEffect(() => {
    console.log("Add new Product  : ", addNewProduct);
  }, [addNewProduct, setAddNewProduct]);

  useEffect(() => {
    if (addNewProduct.productCategory !== "") {
      setAddProductError((prev) => ({
        ...prev,
        productNameError: false,
      }));
    }

    if (addNewProduct.serialNumber !== "") {
      setAddProductError((prev) => ({
        ...prev,
        productSerialNumberError: false,
      }));
    }

    if (addNewProduct.productType !== "") {
      setAddProductError((prev) => ({
        ...prev,
        productTypeError: false,
      }));
    }

    if (addNewProduct.productPrice !== "") {
      setAddProductError((prev) => ({
        ...prev,
        productPriceError: false,
      }));
    }

    if (addNewProduct.quantity !== "") {
      setAddProductError((prev) => ({
        ...prev,
        productQuantityError: false,
      }));
    }

    // Count occurrences of each serial number
    // for (const product of genFields.productDetails) {
    //   const serialNumber = product.serialNumber;

    //   if (serialNumber !== addNewProduct.serialNumber) {
    //     setAddProductError((prev) => ({
    //       ...prev,
    //       serialMacthedError: false,
    //     }));
    //   }
    // }
    if (addNewProduct.serialNumber?.length !== 0) {
      setAddProductError((prev) => ({
        ...prev,
        productSerialNumberError: false,
      }));
    }
  }, [addNewProduct]);

  const handleAddProduct = (isSerialNumber) => {
    // let nError = false; // Product Name Error
    // let snError = false; // Serial Number Error
    let pError = false; // Product Price Error
    let tError = false; // Product Type Error
    let qError = false; // Quantity Error (if no serial number)
    let generalError = false;

    // let serialNumberMatchError = false; // To track if serial number already exists
    // let serialNumberMatch = {}; // To track occurrences of serial numbers

    // Check for duplicate serial numbers if isSerialNumber is true
    // if (isSerialNumber) {
    //   for (const product of genFields.productDetails) {
    //     const serialNumber = product.serialNumber;

    //     // Count serial number occurrences
    //     if (serialNumberMatch[serialNumber]) {
    //       serialNumberMatch[serialNumber] += 1;
    //     } else {
    //       serialNumberMatch[serialNumber] = 1;
    //     }
    //   }

    //   // Check for duplicates in the existing serial numbers
    //   if (serialNumberMatch[addNewProduct.serialNumber]) {
    //     serialNumberMatchError = true; // Serial number already exists
    //   }

    //   // Validate required fields for serial-numbered products
    //   if (addNewProduct.productCategory === "") nError = true;
    //   if (addNewProduct.serialNumber === "") snError = true;
    //   if (addNewProduct.productPrice === "") pError = true;
    //   if (addNewProduct.productType === "") tError = true;
    // } else {
    // Validate required fields for products without serial number
    // if (addNewProduct.productCategory === "") nError = true;
    if (addNewProduct.quantity === "") qError = true; // Quantity is required
    if (addNewProduct.productPrice === "") pError = true;
    if (addNewProduct.productType === "") tError = true;

    //  if the product amount exceeds the total amount
    if (
      genFields?.productDetails?.length > 0 &&
      !qError &&
      !pError &&
      !tError
    ) {
      //  total product amount should not excceds the previous specified amount
      if (
        addNewProduct.productPrice +
          genFields?.productDetails?.reduce(
            (acc, item) => acc + parseInt(item.productPrice, 10),
            0
          ) >
        parseInt(genFields.sellerDetails.totalAmount, 10)
      ) {
        generalError = true;
        openSnackbar(
          `The specified Product Amount exceeds the total Lot amount of Rs. ${genFields.sellerDetails.totalAmount}.`,
          "warning"
        );
      }

      // multiple prouduts should not be added with same product Type Details
      if (
        genFields?.productDetails
          ?.map((item, index) => item.productType.id)
          .includes(addNewProduct.productType.id)
      ) {
        generalError = true;
        openSnackbar(
          "The specified product has already been added to the list.",
          "warning"
        );
      }
    } else if (
      genFields?.productDetails?.length === 0 &&
      !qError &&
      !pError &&
      !tError
    ) {
      if (
        addNewProduct.productPrice >
        parseInt(genFields.sellerDetails.totalAmount, 10)
      ) {
        generalError = true;
        openSnackbar(
          `The specified Product Amount exceeds the total Lot amount of Rs. ${genFields.sellerDetails.totalAmount}.`,
          "warning"
        );
      }
    }

    // }

    // Set errors in state
    setAddProductError({
      // productNameError: nError,
      // productSerialNumberError: snError,
      productPriceError: pError,
      productQuantityError: qError,
      productTypeError: tError,
      // serialMacthedError: serialNumberMatchError,
    });

    // If any validation errors exist, return early to prevent further action
    if (
      // nError ||
      // snError ||
      tError ||
      pError ||
      qError ||
      generalError
      // serialNumberMatchError
    ) {
      return;
    }

    // Add the new product to the productDetails array in genFields
    setGenFields((prev) => {
      const updatedProductDetails = [
        { ...addNewProduct },
        ...prev.productDetails,
      ];

      const updatedProductCount = updatedProductDetails.reduce(
        (acc, item) => acc + parseInt(item.quantity, 10),
        0
      );

      return {
        ...prev,
        productDetails: updatedProductDetails,
        sellerDetails: {
          ...prev.sellerDetails,
          productCount: updatedProductCount,
        },
      };
    });

    // Reset the form fields based on isSerialNumber
    setAddNewProduct({
      productCategory: "",
      productPrice: "",
      serialNumber: [], // Reset serial number if applicable
      productType: "",
      quantity: "", // Reset quantity only if no serial number
    });

    // showing msg in snackbar
    openSnackbar("Added Product Successfully", "success");
  };

  function handleCloseAddNewProductType(e, reason) {
    if (reason && reason === "backdropClick") {
      return;
    }

    setAddNewProductType((prev) => ({
      ...prev,
      isOpen: false,
      productName: "",
      modelNumber: "",
      category: "",
      description: "",
      quantity: "",
    }));
  }

  // resetting the accordion when change
  const resetStateAccordion = () => {
    setAddNewProduct({
      productCategory: "",
      serialNumber: [],
      productPrice: "",
      productType: "",
      quantity: "",
    });
    // resetting error state
    setAddProductError({
      productNameError: false,
      productSerialNumberError: false,
      productPriceError: false,
      productQuantityError: false,
      productTypeError: false,
      serialMacthedError: false,
    });
  };

  const handleSerialNumbers = () => {
    // Find the product in the genFields array
    let pqError = false;
    let sError = false;
    let serialMacthedError = false;
    console.log("add new product type :", addNewProduct);
    if (!addNewProduct.productType || addNewProduct.productType === "") {
      pqError = true;
      setAddProductError((prev) => ({
        ...prev,
        productSerialNumberError: sError,
        productTypeError: pqError,
        serialMacthedError: serialMacthedError,
      }));
      return;
    }
    const [productType, quantity] = addNewProduct.productType.split(",");
    console.log("add new product : ", addNewProduct);
    const selectedProductIndex = genFields.productDetails.findIndex((item) => {
      return (
        item.productType &&
        item.productType.name.toLowerCase() === productType.toLowerCase() &&
        item.quantity == quantity
      );
    });

    const totalquantity =
      genFields?.productDetails[selectedProductIndex]?.quantity || null;

    if (addNewProduct.serialNumber.length < 1) sError = true;
    const serialNumberAlreadyAdded =
      genFields?.productDetails[selectedProductIndex].serialNumber.length;
    if (
      addNewProduct.serialNumber.length + serialNumberAlreadyAdded >
      totalquantity
    ) {
      serialMacthedError = true;
      openSnackbar(
        serialNumberAlreadyAdded < totalquantity
          ? `Only ${
              totalquantity - serialNumberAlreadyAdded
            } additional serial numbers can be added.`
          : "The quantity specified does not match the number of serial numbers provided. Please review and correct.",
        "warning"
      );
    }

    setAddProductError((prev) => ({
      ...prev,
      productSerialNumberError: sError,
      productTypeError: pqError,
      serialMacthedError: serialMacthedError,
    }));

    if (pqError || sError || serialMacthedError) {
      return;
    }

    if (selectedProductIndex !== -1) {
      setGenFields((prev) => {
        const updatedProductDetails = [...prev.productDetails];

        // Combine new serial numbers with existing ones, avoiding duplicates
        const existingSerialNumbers =
          updatedProductDetails[selectedProductIndex].serialNumber || [];

        updatedProductDetails[selectedProductIndex] = {
          ...updatedProductDetails[selectedProductIndex],
          serialNumber: [
            ...existingSerialNumbers,
            ...addNewProduct.serialNumber.filter(
              (serial) => !existingSerialNumbers.includes(serial)
            ),
          ],
        };

        return {
          ...prev,
          productDetails: updatedProductDetails,
        };
      });
    }

    // Reset the addNewProduct fields, but keep productType intact
    setAddNewProduct((prev) => ({
      ...prev,
      productCategory: "",
      serialNumber: [],
      productPrice: "",
      quantity: "",
    }));
  };

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleOpen = async () => {
    setOpen(true);
    setLoading(true);
    try {
      const payload = {
        products: true,
      };

      // Send the payload to the backend route
      const response = await fetchApi("get_lot_helper", "POST", payload, true);
      if (response.statusCode === 200) {
        console.log("produt fatched succesfully", response);
        setOptions(response.data.product_types || []);
        setLoading(false);
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error("Error editing ticket information:", error);
      // openSnackbar("some error occured fetching Sellers", "error");
    } finally {
      // setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    // setOptions([]);
  };

  useEffect(() => {
    setOptions(FiltersData.product_types);
  }, []);

  const [selectedProductDropdownOpen, setselectedProductDropdownOpen] =
    useState(false);
  // const [selectedProductDropdownOptions, setselectedProductDropdownOptions] = React.useState([]);
  // const [selectedProductDropdownLoading, setLoading] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  //   (async () => {
  //     setLoading(true);
  //     await sleep(1e3); // For demo purposes.
  //     setLoading(false);

  //     setOptions([...topFilms]);
  //   })();
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setOptions([]);
  // };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          justifyContent: "space-between",
        }}
      >
        {/* accordions */}

        <div class="accordion w-100" id="accordionExample">
          {/* accordion 1 */}
          <div class="accordion-item accordion-item_lotdetails">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed" // add 'collapsed' class to keep it closed
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false" // set 'false' to indicate it is collapsed
                aria-controls="collapseOne"
                onClick={resetStateAccordion}
              >
                Add Product Details
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse" // remove 'show' class to keep it closed initially
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* product name && product type */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isSmallScreen ? "column" : "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "10px",
                      gap: 25,
                    }}
                  >
                    {false && (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Product Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={addNewProduct.productCategory}
                          label=" Product Category"
                          onChange={(e) => {
                            setAddNewProduct((prev) => ({
                              ...prev,
                              productCategory: e.target.value,
                            }));
                          }}
                        >
                          {["Serialize", "Batch Wise"].map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>

                        {addProductError.productNameError ? (
                          <div
                            style={{
                              marginTop: "8px",
                              color: "red",
                              fontWeight: 300,
                              fontSize: "14px",
                              marginLeft: "10px",
                            }}
                          >
                            Please enter product name.
                          </div>
                        ) : (
                          <></>
                        )}
                      </FormControl>
                    )}

                    <div className="w-100 d-flex align-items-center gap-2 ">
                      <FormControl className="flex-grow-1">
                        <Autocomplete
                          sx={{ width: "100%" }}
                          open={open}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          id="Choose Product"
                          // value={addNewProduct?.productType || null}
                          value={addNewProduct?.productType || null}
                          getOptionLabel={(option) => option.name || null}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          options={options}
                          loading={loading}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          onChange={(e, new_value) => {
                            console.log("e --> ", e.target, new_value);
                            if (new_value) {
                              setAddNewProduct((prev) => ({
                                ...prev,
                                productType: new_value,
                                product_id: new_value.id,
                              }));
                            } else {
                              setAddNewProduct((prev) => ({
                                ...prev,
                                productType: null,
                                product_id: "",
                              }));
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product"
                              error={addProductError.productTypeError}
                              helperText={
                                addProductError.productTypeError
                                  ? "Please select product type."
                                  : ""
                              }
                            />
                          )}
                        />
                      </FormControl>
                      <Tooltip title="Add New Product">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setAddNewProductType((prev) => ({
                              ...prev,
                              isOpen: true,
                            }));
                          }}
                          className="d-flex flex-row gap-2 align-items-center"
                          style={{
                            // backgroundColor: color.lotBtncolor,
                            borderColor: color.lotBtncolor,
                            height: isSmallScreen ? "38px" : "40px",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            src={addProductTypeBlueImg}
                            style={{
                              color: color.lotBtncolor,
                              height: "auto",
                              width: isSmallScreen ? "30px" : "25px",
                            }}
                            alt="img"
                          />
                          {!isSmallScreen && (
                            <span
                              className="text-nowrap"
                              style={{
                                fontFamily: "Open-Sans-regular",
                                fontWeight: 600,
                                color: color.lotBtncolor,
                              }}
                            >
                              Add New Product
                            </span>
                          )}
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  {/* Product price& & quantity */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isSmallScreen ? "column" : "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "25px",
                      gap: 25,
                    }}
                  >
                    <FormControl
                      fullWidth
                      error={addProductError.productPriceError}
                    >
                      <TextField
                        value={addNewProduct.quantity}
                        fullWidth
                        type="number"
                        id="outlined-basic"
                        label="Quantity"
                        variant="outlined"
                        error={addProductError.productQuantityError}
                        helperText={
                          addProductError.productQuantityError
                            ? "Please enter Productqunatity"
                            : ""
                        }
                        onChange={(e) => {
                          setAddNewProduct((prev) => ({
                            ...prev,
                            quantity: parseInt(e.target.value, 10) || 0,
                          }));
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        value={addNewProduct.productPrice}
                        fullWidth
                        type="number"
                        id="outlined-basic"
                        label="Product Price"
                        variant="outlined"
                        error={addProductError.productPriceError}
                        helperText={
                          addProductError.productPriceError
                            ? " Please enter Total Product Price."
                            : ""
                        }
                        onChange={(e) => {
                          setAddNewProduct((prev) => ({
                            ...prev,
                            productPrice: parseInt(e.target.value, 10) || 0,
                          }));
                        }}
                      />
                    </FormControl>
                  </div>

                  {/* add button */}
                  <div className="d-flex gap-3 mt-3 pt-2 ms-auto">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleAddProduct(true);
                      }}
                      className="d-flex flex-row gap-1 align-items-center pe-3"
                      style={{
                        backgroundColor: color.lotBtncolor,
                        height: "38px",
                        borderRadius: "8px",
                      }}
                    >
                      <AddIcon
                        fontSize="small"
                        style={{
                          color: color.textWhiteColor,
                          height: "auto",
                          width: "25px",
                        }}
                      />
                      <span
                        className="text-nowrap"
                        style={{
                          fontFamily: "Open-Sans-regular",
                          fontWeight: 600,
                          color: color.textWhiteColor,
                        }}
                      >
                        Add
                      </span>
                    </Button>
                  </div>
                  {/* data grid table */}
                  <div className="mt-3 my-2 w-100">
                    {genFields.productDetails.length > 0 ? (
                      <DisplayingProductDetailsTable
                        genFields={genFields}
                        setGenFields={setGenFields}
                        isProductsPage={true}
                        isSmallScreen={isSmallScreen}
                      />
                    ) : null}
                  </div>

                  {/* accordion body ends */}
                </div>
              </div>
            </div>
          </div>

          {/* accordion 2 */}
          <div
            class="accordion-item accordion-item_lotdetails"
            style={{
              opacity: genFields?.productDetails?.length < 1 ? "0.5" : "",
            }}
          >
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                disabled={genFields?.productDetails?.length < 1}
                onClick={resetStateAccordion}
              >
                Add Serial Numbers
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: isMediumScreen ? "1fr" : "1fr 1fr",
                    gap: "20px",
                    rowGap: "20px",
                  }}
                >
                  {/* product type */}
                  <FormControl fullWidth className="h-100">
                    <TextField
                      fullWidth
                      select
                      label="Product"
                      key={"product_dropdown"}
                      value={addNewProduct.productType || ""}
                      onChange={(e) => {
                        console.log("e.target.value -> ", e.target.value);
                        setAddNewProduct((prev) => ({
                          ...prev,
                          productType: e.target.value,
                          serialNumber: [],
                        }));
                      }}
                      onClick={() => {
                        setselectedProductDropdownOpen(true);
                        setTimeout(() => {
                          setselectedProductDropdownOpen(false);
                        }, 100);
                        console.log("on click working");
                      }}
                      helperText={
                        addProductError.productTypeError
                          ? "Please select a product type."
                          : ""
                      }
                      error={addProductError.productTypeError}
                    >
                      {selectedProductDropdownOpen ? (
                        <div
                          className="p-1 px-3 m-1"
                          style={{ color: color.taxtHeadingLight }}
                        >
                          Loading ...
                        </div>
                      ) : (
                        genFields?.productDetails?.map((item, index) => {
                          const productTypeDetailsOfItem = item.productType;
                          const isAllSerialNumberAdded =
                            genFields.productDetails.every(
                              (subitem) =>
                                subitem.quantity ===
                                  subitem.serialNumber.length ||
                                subitem.productType.tracking_type.toLowerCase() ===
                                  "batch wise"
                            );
                          if (isAllSerialNumberAdded) {
                            if (index === 0) {
                              return (
                                <div
                                  className="p-1 px-3 m-1"
                                  style={{ color: color.taxtHeadingLight }}
                                >
                                  All Serial Numbers added
                                </div>
                              );
                            } else {
                              return null;
                            }
                          }
                          if (
                            productTypeDetailsOfItem[
                              "tracking_type"
                            ].toLowerCase() === "batch wise" ||
                            (productTypeDetailsOfItem[
                              "tracking_type"
                            ].toLowerCase() === "serialize" &&
                              parseInt(item.quantity, 10) ===
                                item.serialNumber.length)
                          ) {
                            return null;
                          }
                          return (
                            <MenuItem
                              key={index}
                              value={`${productTypeDetailsOfItem["name"]},${item.quantity}`}
                              style={{ width: "100%" }}
                            >
                              <div className="row w-100">
                                <div className="col-7 text-wrap">
                                  {`${isSmallScreen ? "" : "Product type : "} ${
                                    productTypeDetailsOfItem["name"]
                                  }`}
                                </div>
                                <div className="col-5">
                                  Qty : {item.quantity}
                                </div>
                              </div>
                            </MenuItem>
                          );
                        })
                      )}
                    </TextField>

                    {/* {addProductError.productTypeError ? (
                      <div
                        style={{
                          marginTop: "8px",
                          color: "red",
                          fontWeight: 300,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        Please select product type.
                      </div>
                    ) : (
                      <></>
                    )}
                    {addProductError.productTypeError && (
                      <FormHelperText>
                        Please select a product type.
                      </FormHelperText>
                    )} */}
                  </FormControl>

                  {/* adding serial numbers */}

                  <FormControl fullWidth className="h-100">
                    <Autocomplete
                      multiple
                      id="multi-select-label"
                      options={[]} // Empty array to ensure no options are shown
                      value={addNewProduct.serialNumber || []}
                      freeSolo // Allows user to input custom text
                      disablePortal // Prevents dropdown from rendering
                      disabled={
                        !addNewProduct.productType ||
                        addNewProduct.productType === ""
                          ? true
                          : false
                      }
                      onChange={(event, values) => {
                        console.log("serial number : ", values, genFields);
                        // const [productType, quantity] =
                        //   addNewProduct.productType.split(",");
                        // const selectedProductIndex =
                        //   genFields.productDetails.findIndex((item) => {
                        //     return (
                        //       item.productType &&
                        //       item.productType.name.toLowerCase() ===
                        //         productType.toLowerCase() &&
                        //       item.quantity == quantity
                        //     );
                        //   });
                        //  all serial number array
                        const serialNumbers =
                          genFields?.productDetails?.flatMap(
                            (item) => item.serialNumber
                          );
                        console.log("serial number 2: ", values, serialNumbers);

                        // checking with all serial number array
                        if (
                          serialNumbers.length !== 0 &&
                          values.some((snitem) =>
                            serialNumbers.includes(snitem)
                          )
                        ) {
                          openSnackbar(
                            "This serial number alredy entered",
                            "warning"
                          );
                        } else {
                          setAddNewProduct((prev) => ({
                            ...prev,
                            serialNumber: [...values],
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Serial Numbers"
                          placeholder="Add serial numbers"
                          variant="outlined"
                          className="h-100"
                          error={
                            addProductError.productSerialNumberError ||
                            addProductError.serialMacthedError
                          }
                          helperText={
                            addProductError.productSerialNumberError
                              ? "Please enter serial numbers."
                              : addProductError.serialMacthedError
                              ? "Please ensure that the serial numbers match the quantity."
                              : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                </div>

                {/* add button */}
                <div className="my-3 text-end">
                  {/* <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setAddNewProductType((prev) => ({
                          ...prev,
                          isOpen: true,
                        }));
                      }}
                      style={{ backgroundColor: color.lotBtncolor }}
                    >
                      Add Product Type
                    </Button> */}

                  <div className="d-flex my-3 justify-content-end">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleSerialNumbers();
                      }}
                      className="d-flex flex-row gap-1 align-items-center pe-3"
                      style={{
                        backgroundColor: color.lotBtncolor,
                        height: "38px",
                        borderRadius: "8px",
                      }}
                    >
                      <AddIcon
                        fontSize="small"
                        style={{
                          color: color.textWhiteColor,
                          height: "auto",
                          width: "25px",
                        }}
                      />
                      <span
                        className="text-nowrap"
                        style={{
                          fontFamily: "Open-Sans-regular",
                          fontWeight: 600,
                          color: color.textWhiteColor,
                        }}
                      >
                        Add
                      </span>
                    </Button>
                  </div>
                </div>
                <div>
                  {genFields.productDetails.length > 0 ? (
                    <div className="mt-3 my-2 w-100">
                      <DisplayingProductDetailsTable
                        genFields={genFields}
                        setGenFields={setGenFields}
                        isProductsPage={true}
                        isSmallScreen={isSmallScreen}
                        isSerialNumber={true}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              {/* accordion body ends here */}
            </div>
          </div>
          {/* accordian 3 */}
          {false && (
            <div
              class="accordion-item accordion-item_lotdetails"
              // style={{
              //   opacity: genFields?.productDetails?.length < 1 ? "0.5" : "",
              // }}
            >
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  // disabled={genFields?.productDetails?.length < 1}
                  // onClick={resetStateAccordion}
                >
                  Add Spare Products
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample3"
              >
                <div class="accordion-body">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {/* Product price& & quantity */}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: isSmallScreen
                          ? "1fr"
                          : "repeat(2,1fr)",
                        width: "100%",
                        marginTop: "25px",
                        gap: 25,
                      }}
                    >
                      <FormControl className="flex-grow-1">
                        <Autocomplete
                          sx={{ width: "100%" }}
                          open={open}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          id="Choose Product"
                          // value={addNewProduct?.productType || null}
                          value={addNewProduct?.productType || null}
                          getOptionLabel={(option) => option.name || null}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          options={options}
                          loading={loading}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          onChange={(e, new_value) => {
                            console.log("e --> ", e.target, new_value);
                            if (new_value) {
                              setAddNewProduct((prev) => ({
                                ...prev,
                                productType: new_value,
                                product_id: new_value.id,
                              }));
                            } else {
                              setAddNewProduct((prev) => ({
                                ...prev,
                                productType: null,
                                product_id: "",
                              }));
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product"
                              error={addProductError.productTypeError}
                              helperText={
                                addProductError.productTypeError
                                  ? "Please select product type."
                                  : ""
                              }
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl className="flex-grow-1">
                        <Autocomplete
                          sx={{ width: "100%" }}
                          open={open}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          id="Choose Product"
                          // value={addNewProduct?.productType || null}
                          value={addNewProduct?.productType || null}
                          getOptionLabel={(option) => option.name || null}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          options={options}
                          loading={loading}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          onChange={(e, new_value) => {
                            console.log("e --> ", e.target, new_value);
                            if (new_value) {
                              setAddNewProduct((prev) => ({
                                ...prev,
                                productType: new_value,
                                product_id: new_value.id,
                              }));
                            } else {
                              setAddNewProduct((prev) => ({
                                ...prev,
                                productType: null,
                                product_id: "",
                              }));
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product"
                              error={addProductError.productTypeError}
                              helperText={
                                addProductError.productTypeError
                                  ? "Please select product type."
                                  : ""
                              }
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl
                        fullWidth
                        error={addProductError.productPriceError}
                      >
                        <TextField
                          value={addNewProduct.quantity}
                          fullWidth
                          type="number"
                          id="outlined-basic"
                          label="Quantity"
                          variant="outlined"
                          error={addProductError.productQuantityError}
                          helperText={
                            addProductError.productQuantityError
                              ? "Please enter Productqunatity"
                              : ""
                          }
                          onChange={(e) => {
                            setAddNewProduct((prev) => ({
                              ...prev,
                              quantity: parseInt(e.target.value, 10) || 0,
                            }));
                          }}
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          value={addNewProduct.productPrice}
                          fullWidth
                          type="number"
                          id="outlined-basic"
                          label="Product Price"
                          variant="outlined"
                          error={addProductError.productPriceError}
                          helperText={
                            addProductError.productPriceError
                              ? " Please enter Total Product Price."
                              : ""
                          }
                          onChange={(e) => {
                            setAddNewProduct((prev) => ({
                              ...prev,
                              productPrice: parseInt(e.target.value, 10) || 0,
                            }));
                          }}
                        />
                      </FormControl>
                    </div>
                    {/* add button */}
                    {/* <div className="d-flex gap-3 mt-3 pt-2 ms-auto">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleAddProduct(true);
                      }}
                      className="d-flex flex-row gap-1 align-items-center pe-3"
                      style={{
                        backgroundColor: color.lotBtncolor,
                        height: "38px",
                        borderRadius: "8px",
                      }}
                    >
                      <AddIcon
                        fontSize="small"
                        style={{
                          color: color.textWhiteColor,
                          height: "auto",
                          width: "25px",
                        }}
                      />
                      <span
                        className="text-nowrap"
                        style={{
                          fontFamily: "Open-Sans-regular",
                          fontWeight: 600,
                          color: color.textWhiteColor,
                        }}
                      >
                        Add
                      </span>
                    </Button>
                  </div> */}
                    {/* data grid table */}
                    {/* <div className="mt-3 my-2 w-100">
                    {genFields.productDetails.length > 0 ? (
                      <DisplayingProductDetailsTable
                        genFields={genFields}
                        setGenFields={setGenFields}
                        isProductsPage={true}
                        isSmallScreen={isSmallScreen}
                      />
                    ) : null}
                  </div> */}
                    {/* accordion body ends */}
                  </div>

                  {/* add button */}
                  <div className="my-3 text-end">
                    {/* <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setAddNewProductType((prev) => ({
                          ...prev,
                          isOpen: true,
                        }));
                      }}
                      style={{ backgroundColor: color.lotBtncolor }}
                    >
                      Add Product Type
                    </Button> */}

                    <div className="d-flex my-3 justify-content-end">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleSerialNumbers();
                        }}
                        className="d-flex flex-row gap-1 align-items-center pe-3"
                        style={{
                          backgroundColor: color.lotBtncolor,
                          height: "38px",
                          borderRadius: "8px",
                        }}
                      >
                        <AddIcon
                          fontSize="small"
                          style={{
                            color: color.textWhiteColor,
                            height: "auto",
                            width: "25px",
                          }}
                        />
                        <span
                          className="text-nowrap"
                          style={{
                            fontFamily: "Open-Sans-regular",
                            fontWeight: 600,
                            color: color.textWhiteColor,
                          }}
                        >
                          Add
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div>
                    {genFields.productDetails.length > 0 ? (
                      <div className="mt-3 my-2 w-100">
                        <DisplayingProductDetailsTable
                          genFields={genFields}
                          setGenFields={setGenFields}
                          isProductsPage={true}
                          isSmallScreen={isSmallScreen}
                          isSerialNumber={true}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* accordion body ends here */}
              </div>
            </div>
          )}
        </div>

        {/*  */}

        {/* displaying the product list */}

        {/* <DisplayingProductDetailsCards
          genFields={genFields}
          setGenFields={setGenFields}
          isSmallScreen={isSmallScreen}
          fieldError={fieldError}
        /> */}
      </div>

      {addNewProductType.isOpen ? (
        <AddNewProductType
          addNewProductType={addNewProductType}
          setAddNewProductType={setAddNewProductType}
          handleClose={handleCloseAddNewProductType}
          genFields={genFields}
          setGenFields={setGenFields}
          isSmallScreen={isSmallScreen}
          openSnackbar={openSnackbar}
          fetchApi={fetchApi}
        />
      ) : null}
    </>
  );
}

export default ProductDetailsForm;

// add new Product type modal
const AddNewProductType = (props) => {
  const {
    addNewProductType,
    handleClose,
    isSmallScreen,
    setAddNewProductType,
    fetchApi,
    openSnackbar,
  } = props;

  const [addNewProductTypeError, setAddNewProductTypeError] = useState({
    productNameError: false,
    productModelNumberError: false,
    productTrakingTypeError: false,
    productDesriptionError: false,
    productQuantityError: false,
    productCategoryError: false,
  });

  useEffect(() => {
    // Check for each field and update the corresponding error state
    if (addNewProductType.productName !== "") {
      setAddNewProductTypeError((prev) => ({
        ...prev,
        productNameError: false,
      }));
    }

    if (addNewProductType.modelNumber !== "") {
      setAddNewProductTypeError((prev) => ({
        ...prev,
        productModelNumberError: false,
      }));
    }

    if (addNewProductType.productTrakingTypeError !== "") {
      setAddNewProductTypeError((prev) => ({
        ...prev,
        productTrakingTypeError: false,
      }));
    }
    if (addNewProductType.description !== "") {
      setAddNewProductTypeError((prev) => ({
        ...prev,
        productDesriptionError: false,
      }));
    }

    if (addNewProductType.quantity !== "") {
      setAddNewProductTypeError((prev) => ({
        ...prev,
        productQuantityError: false,
      }));
    }

    if (addNewProductType.category !== "") {
      setAddNewProductTypeError((prev) => ({
        ...prev,
        productCategoryError: false,
      }));
    }
  }, [addNewProductType, setAddNewProductType]);

  const [addProductDetailsLoading, setAddProductDetailsLoading] =
    useState(false);
  const handleProductType = async () => {
    setAddProductDetailsLoading(true);
    let nError = false;
    let mError = false;
    let bcError = false;
    let cError = false;
    let dError = false;
    let qError = false;

    // Check for empty values
    if (addNewProductType.productName === "") nError = true;
    // if (addNewProductType.modelNumber === "") mError = true;
    if (addNewProductType.tracking_type === "") bcError = true;
    if (addNewProductType.category === "") cError = true;
    if (addNewProductType.description === "") dError = true;
    if (addNewProductType.quantity === "") qError = true;

    // Set errors in state
    setAddNewProductTypeError({
      productNameError: nError,
      productModelNumberError: mError,
      productTrakingTypeError: bcError,
      productCategoryError: cError,
      productDesriptionError: dError,
      productQuantityError: qError,
    });

    // If there are any errors, return early
    if (nError || mError || dError || cError || qError || bcError) {
      setAddProductDetailsLoading(false);
      return;
    } else {
      //  fething api for adding of product
      console.log("add product to db : ", addNewProductType, {
        name: addNewProductType.productName.toLowerCase(),
        desc: addNewProductType.description.toLowerCase(),
        machine_requirement: parseInt(addNewProductType.quantity, 10) || 0,
        model_no: addNewProductType.modelNumber.toLowerCase(),
        category: addNewProductType.category.toLowerCase(),
        tracking_type: addNewProductType.tracking_type.toLowerCase(),
      });

      const payload = {
        name: addNewProductType.productName,
        desc: addNewProductType.description,
        machine_requirement: parseInt(addNewProductType.quantity, 10) || 0,
        model_no: addNewProductType.modelNumber,
        category: addNewProductType.category,
        tracking_type: addNewProductType.tracking_type,
      };
      try {
        // Send the payload to the backend route
        const response = await fetchApi(
          "add_product_type",
          "POST",
          payload,
          true
        );
        if (response.statusCode === 200) {
          console.log("product Type Details added succesfully", response);
          // setLoading(false);
          setAddNewProductType({
            isOpen: false,
            productName: "",
            description: "",
            modelNumber: "",
            quantity: "",
            category: "",
            tracking_type: "",
          });
          openSnackbar(
            response.data.message || "Product Type Details Added Sussessfully",
            "success"
          );
        } else {
          // console.error("Error editing ticket information:", response.statusCode);
          openSnackbar(response.msg, "error");
        }
      } catch (error) {
        console.error("Error editing ticket information:", error);
        openSnackbar(
          "Some error occured while saving Product Type Details",
          "error"
        );
      } finally {
        // setLoading(false);
        setAddProductDetailsLoading(false);
      }
      // Reset the form after adding the product
    }
  };

  // The filter function for options (based on MUI's default filter function)
  const filter = (options, { inputValue }) => {
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const categories = ["category 1", "category 2", "category 3"];

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleProductCtegoryDropdownOpen = async () => {
    setOpen(true);
    setLoading(true);
    try {
      const payload = { category: true };

      // Send the payload to the backend route
      const response = await fetchApi("get_lot_helper", "POST", payload, true);
      if (response.statusCode === 200) {
        console.log("produt category fatched succesfully", response);
        setOptions(response?.data?.category_arr || []);
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error("Error editing ticket information:", error);
      // openSnackbar("some error occured fetching Sellers", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleProductCtegoryDropdownClose = () => {
    setOpen(false);
    // setOptions([]);
  };

  return (
    <Modal
      open={addNewProductType.isOpen}
      style={{
        position: "fixed",
        overflowY: "scroll",
        height: "100%",
      }}
    >
      <Box
        style={{
          position: "relative",
          zIndex: "1111",
          right: "0px",
          left: "0px",
          top: "16%",
          margin: (isSmallScreen && "auto 10px") || "auto",
          overflowY: "auto",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          width: "",
          maxWidth: "900px",
          height: "auto",
        }}
      >
        {/* Modal Header starts*/}
        <div
          style={{
            padding: "16px",
            borderBottom: "1px solid #f5f5f5",
          }}
        >
          <div className="d-flex justify-content-between">
            <h5 className="m-0">Add New Product</h5>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {/* Modal Header ends*/}

        {/* Modal body starts */}
        <Box className=" m-1 mt-0 p-3 pt-0">
          <div
            style={{
              display: "grid",
              gap: "20px",
            }}
          >
            {/* product name */}
            <FormControl
              fullWidth
              error={addNewProductTypeError.productNameError}
              style={{ gridColumn: isSmallScreen ? "span 2" : "span 1" }}
            >
              <TextField
                value={addNewProductType.productName}
                fullWidth
                required
                id="outlined-basic"
                label="Name"
                variant="outlined"
                error={addNewProductTypeError.productNameError}
                helperText={
                  addNewProductTypeError.productNameError
                    ? "Please enter product name."
                    : ""
                }
                onChange={(e) => {
                  setAddNewProductType((prev) => ({
                    ...prev,
                    productName: e.target.value,
                  }));
                }}
              />
              {/* {addNewProductTypeError.productNameError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter product name.
                </div>
              ) : (
                <></>
              )} */}
            </FormControl>
            {/* product category */}
            <FormControl
              fullWidth
              style={{ gridColumn: isSmallScreen ? "span 2" : "span 1" }}
            >
              <Autocomplete
                onChange={(event, newValue) => {
                  if (newValue) {
                    if (
                      typeof newValue === "string" &&
                      newValue.startsWith('Add "')
                    ) {
                      const finalValue = newValue.slice(5, -1);
                      // setGenFields((prev) => {
                      //   return {
                      //     ...prev,
                      //     sellerDetails: {
                      //       ...prev.sellerDetails,
                      //       sellerName: finalValue,
                      //     },
                      //   };
                      // });
                      setAddNewProductType((prev) => ({
                        ...prev,
                        category: finalValue,
                      }));
                    } else {
                      setAddNewProductType((prev) => ({
                        ...prev,
                        category: newValue,
                      }));
                    }
                  } else {
                    setAddNewProductType((prev) => ({
                      ...prev,
                      category: "",
                    }));
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;

                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(`Add "${inputValue}"`);
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Regular option
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.toString();
                }}
                open={open}
                value={addNewProductType?.category || null}
                onOpen={handleProductCtegoryDropdownOpen}
                onClose={handleProductCtegoryDropdownClose}
                isOptionEqualToValue={(option, value) => option === value}
                options={options}
                loading={loading}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="Category"
                renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    required
                    error={addNewProductTypeError.productCategoryError}
                    helperText={
                      addNewProductTypeError.productCategoryError
                        ? "Please select/enter category"
                        : ""
                    }
                  />
                )}
              />

              {/* {addNewProductTypeError.productCategoryError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please select category
                </div>
              ) : (
                <></>
              )} */}
            </FormControl>
            {/* model number */}
            {false && (
              <FormControl
                fullWidth
                error={addNewProductTypeError.productModelNumberError}
                style={{ gridColumn: isSmallScreen ? "span 2" : "span 1" }}
              >
                <TextField
                  value={addNewProductType.modelNumber}
                  fullWidth
                  id="outlined-basic"
                  label="Model Number"
                  variant="outlined"
                  onChange={(e) => {
                    setAddNewProductType((prev) => ({
                      ...prev,
                      modelNumber: e.target.value,
                    }));
                  }}
                />
                {addNewProductTypeError.productModelNumberError ? (
                  <div
                    style={{
                      marginTop: "8px",
                      color: "red",
                      fontWeight: 300,
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    Please enter model number.
                  </div>
                ) : (
                  <></>
                )}
              </FormControl>
            )}
            {/* traking type info */}

            <FormControl
              fullWidth
              error={addNewProductTypeError.productTrakingTypeError}
              style={{ gridColumn: isSmallScreen ? "span 2" : "span 1" }}
            >
              <TextField
                id="tracking-type-select"
                label="Tracking Type"
                select
                value={addNewProductType.tracking_type}
                onChange={(e) => {
                  setAddNewProductType((prev) => ({
                    ...prev,
                    tracking_type: e.target.value || "",
                  }));
                }}
                fullWidth
                required
                error={addNewProductTypeError.productTrakingTypeError}
                helperText={
                  addNewProductTypeError.productTrakingTypeError
                    ? "Please select tracking type"
                    : ""
                }
                InputProps={{
                  endAdornment: addNewProductType.tracking_type && (
                    <InputAdornment position="end" className="me-3 pe-2">
                      <IconButton
                        aria-label="clear selection"
                        onClick={() =>
                          setAddNewProductType((prev) => ({
                            ...prev,
                            tracking_type: "",
                          }))
                        }
                        edge="end"
                        sx={{ p: 0.3 }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                {["Serialize", "Batch Wise"].map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            {/* Product quantity*/}
            <FormControl
              fullWidth
              // error={fieldError.subIssueError}
              style={{ gridColumn: isSmallScreen ? "span 2" : "span 1" }}
            >
              <TextField
                value={addNewProductTypeError.quantity}
                fullWidth
                required
                type="number"
                id="outlined-basic"
                // label="Quantity"
                label={"Quantity required in matt"}
                variant="outlined"
                error={addNewProductTypeError.productQuantityError}
                helperText={
                  addNewProductTypeError.productQuantityError
                    ? "Please enter Quantity required in matt."
                    : ""
                }
                onChange={(e) => {
                  setAddNewProductType((prev) => ({
                    ...prev,
                    quantity: e.target.value,
                  }));
                }}
              />
              {/* {addNewProductTypeError.productQuantityError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter Quantity required in matt.
                </div>
              ) : (
                <></>
              )} */}
            </FormControl>

            {/* description */}
            <FormControl
              fullWidth
              error={addNewProductTypeError.productDesriptionError}
              style={{
                gridColumn: "span 2",
              }}
            >
              <TextField
                fullWidth
                required
                id="outlined-multiline-static"
                label="Description"
                multiline
                labelId="demo-simple-select-label"
                rows={4}
                value={addNewProductType.description}
                error={addNewProductTypeError.productDesriptionError}
                helperText={
                  addNewProductTypeError.productDesriptionError
                    ? "Please enter Product description."
                    : ""
                }
                onChange={(e) => {
                  setAddNewProductType((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />
              {/* {addNewProductTypeError.productDesriptionError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter Product description.
                </div>
              ) : (
                <></>
              )} */}
            </FormControl>
          </div>
          {/* modal footer starts */}
          <div className="d-flex justify-content-end mt-3">
            {" "}
            <LoadingButton
              size="medium"
              onClick={() => {
                handleProductType();
              }}
              style={{
                backgroundColor: addProductDetailsLoading
                  ? color.primary
                  : color.lotBtncolor,
              }}
              endIcon={<SendIcon />}
              loading={addProductDetailsLoading}
              loadingPosition="end"
              variant="contained"
            >
              Add
            </LoadingButton>
          </div>
          {/* modal footer ends */}{" "}
        </Box>
      </Box>
    </Modal>
  );
};
