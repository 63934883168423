import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

// Keyframes for animation
const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Styled components
const PlaceholderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  textAlign: "center",
  backgroundColor: "white",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  // boxShadow: "rgb(76 78 100 / 24%) 0px 0px 15px 0px",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
}));

const AnimatedIcon = styled(Box)(({ theme }) => ({
  fontSize: "4rem",
  color: theme.palette.text.secondary,
  animation: `${floatAnimation} 2s ease-in-out infinite`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const PlaceholderText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: "1.2rem",
  color: theme.palette.text.secondary,
}));

// Component
const Placeholder = ({
  message = "No data available yet",
  icon = null,
  color,
  removeBoxShadow = false,
}) => {
  return (
    <PlaceholderContainer
      style={{
        boxShadow: removeBoxShadow
          ? "none"
          : "rgb(76 78 100 / 24%) 0px 0px 15px 0px",
        backgroundColor: color ? color.primary : "white",
      }}
    >
      <AnimatedIcon>{icon}</AnimatedIcon>
      <PlaceholderText>{message}</PlaceholderText>
    </PlaceholderContainer>
  );
};

// Default Props
Placeholder.defaultProps = {
  icon: <InsertDriveFileOutlinedIcon fontSize="inherit" />,
};

export default Placeholder;
