import React, { useContext, useEffect, useState } from "react";
import ContextState from "../../contextApi/ContextState";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { color } from "../../modals/Calllog";
import { DisplayingProductDetailsTable } from "../AddLotDetailsForm/DisplayingProductDetailsTable";
import { DisplayingShipmentProductDetailsTable } from "./DisplayingShipmentProductDetails";

export const demo = [
  {
    scanner: {
      V39: {
        id: 1,
        description: "Lower Scanner",
        machine_requirement: 1,
        tracking_type: "serialize",
        serialNumbers: [
          "V39-001",
          "V39-002",
          "V39-003",
          "V39-004",
          "V39-005",
          "V39-006",
        ],
      },
      V600: {
        id: 2,
        description: "Upper Scanner",
        machine_requirement: 1,
        tracking_type: "serialize",
        serialNumbers: [
          "V600-001",
          "V600-002",
          "V600-003",
          "V600-004",
          "V600-005",
          "V600-006",
          "V600-007",
        ],
      },
    },
  },
  {
    nuc: {
      "NUC i5": {
        id: 1,
        description: "Power Unit",
        machine_requirement: 1,
        tracking_type: "serialize",
        serialNumbers: [
          "NUC-001",
          "NUC-002",
          "NUC-003",
          "NUC-004",
          "NUC-005",
          "NUC-006",
          "NUC-007",
          "NUC-008",
          "NUC-009",
        ],
      },
    },
  },
  {
    controller: {
      "arduino nano": {
        id: 1,
        description: "matt micro controller",
        machine_requirement: 1,
        tracking_type: "serialize",
        serialNumbers: [
          "ARD-NANO-001",
          "ARD-NANO-002",
          "ARD-NANO-003",
          "ARD-NANO-004",
          "ARD-NANO-005",
          "ARD-NANO-006",
          "ARD-NANO-007",
        ],
      },
      "ARDUINO EVERY": {
        id: 2,
        description: "matt micro controller",
        machine_requirement: 1,
        tracking_type: "serialize",
        serialNumbers: [
          "ARD-EVERY-001",
          "ARD-EVERY-002",
          "ARD-EVERY-003",
          "ARD-EVERY-004",
          "ARD-EVERY-005",
          "ARD-EVERY-006",
          "ARD-EVERY-007",
          "ARD-EVERY-008",
        ],
      },
    },
  },
];

const ShipmentProductDetails = ({
  genFields,
  setGenFields,
  fieldError,
  isSmallScreen,
}) => {
  const { formatInIndianRupees, fetchApi, openSnackbar } =
    useContext(ContextState);

  const [product, setProduct] = useState({
    category: "",
    product: "",
    serialNumbers: [],
  });

  const [productState, setProductState] = useState({
    options: [], // Ensuring options is initialized as an empty array
    loading: false,
    open: false,
  });

  const fetchProductDetails = async () => {
    setProductState((prev) => ({ ...prev, loading: true, open: true }));

    try {
      const payload = { sellers: true };
      const response = await fetchApi("get_lot_helper", "POST", payload, true);

      if (response.statusCode === 200) {
        setProductState((prev) => ({
          ...prev,
          options: demo || [], // Ensure options is set properly
          loading: false,
        }));
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error("Error fetching seller details:", error);
      setProductState((prev) => ({ ...prev, loading: false }));
    }
  };

  const hanldeClose = () => {
    setProductState((prev) => ({ ...prev, open: false }));
  };

  const findProductNames = (category) => {
    const selectedCategory = productState?.options?.find(
      (item) => Object.keys(item)[0] === category
    );
    return selectedCategory ? Object.keys(selectedCategory[category]) : [];
  };

  const findProductSerialNumbers = (category, product) => {
    // Check if category and product are defined before accessing them
    if (category && product) {
      const selectedCategory = productState?.options?.find(
        (item) => Object.keys(item)[0] === category
      );

      // Ensure the category is found and has the expected product
      if (
        selectedCategory &&
        selectedCategory[category] &&
        selectedCategory[category][product]
      ) {
        const serialNumbers =
          selectedCategory[category][product]?.serialNumbers;
        return serialNumbers || [];
      }
    }
    return [];
  };

  const handleAddingofProductDetails = () => {
    if (
      product.category &&
      product.product &&
      product.serialNumbers.length > 0
    ) {
      setGenFields((prev) => {
        const { productDetails } = prev;

        // Find the index of the existing product
        const findExistProductIndex = productDetails.findIndex(
          (item) =>
            item.category === product.category &&
            item.product === product.product
        );

        // Create a copy of the current product details
        const updatedProductDetails = [...productDetails];

        if (findExistProductIndex !== -1) {
          // Merge and remove duplicates in serial numbers
          const existingSerialNumbers =
            updatedProductDetails[findExistProductIndex].serialNumbers;
          const mergedSerialNumbers = Array.from(
            new Set([...existingSerialNumbers, ...product.serialNumbers])
          );

          updatedProductDetails[findExistProductIndex] = {
            ...updatedProductDetails[findExistProductIndex],
            serialNumbers: mergedSerialNumbers,
          };
        } else {
          // Add the new product to the product details with unique serial numbers
          updatedProductDetails.push({
            ...product,
            serialNumbers: Array.from(new Set(product.serialNumbers)),
          });
        }

        // Return the updated state
        return { ...prev, productDetails: updatedProductDetails };
      });

      openSnackbar("Product added sucessfully", "success");
      // Resetting the product state
      setProduct((prev) => ({
        ...prev,
        category: "",
        product: "",
        serialNumbers: [],
      }));
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: genFields?.productDetails?.length > 0 ? "0px" : "20px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isSmallScreen
              ? "repeat(1, 1fr)"
              : "repeat(2, 1fr)",
            justifyContent: "space-between",
            width: "100%",
            gap: 25,
          }}
        >
          {/* Product Type */}
          <FormControl fullWidth error={fieldError.nameError}>
            <Autocomplete
              className="w-100"
              value={product.category}
              onChange={(event, newValue) => {
                if (newValue) {
                  setProduct((prev) => ({
                    ...prev,
                    category: newValue, // Ensure proper value
                    product: "",
                    serialNumbers: [],
                  }));
                } else {
                  setProduct((prev) => ({
                    ...prev,
                    category: "",
                    product: "",
                    serialNumbers: [],
                  }));
                }
              }}
              options={
                productState.options.map((item) => Object.keys(item)[0]) || []
              } // Ensure options is always an array
              loading={productState.loading}
              open={productState.open}
              onOpen={fetchProductDetails}
              onClose={hanldeClose}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product Category"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {productState.loading && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormControl>

          {/* Product */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Product</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Product"
              value={product.product || ""}
              onChange={(e) => {
                setProduct((prev) => ({
                  ...prev,
                  product: e.target.value,
                }));
              }}
            >
              {product.category ? (
                findProductNames(product.category).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))
              ) : (
                <MenuItem style={{ opacity: "0.6" }}>No Options</MenuItem>
              )}
            </Select>
          </FormControl>

          {/* Serial Numbers (Full Width) */}
          <FormControl
            fullWidth
            error={fieldError.nameError}
            style={{ gridColumnStart: 1, gridColumnEnd: -1 }}
          >
            <Autocomplete
              multiple
              id="multi-select-label"
              options={findProductSerialNumbers(
                product?.category,
                product?.product
              )}
              getOptionLabel={(option) => option || ""}
              filterSelectedOptions
              onChange={(event, newValue) =>
                setProduct((prev) => ({ ...prev, serialNumbers: newValue }))
              }
              value={product.serialNumbers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Serial Numbers"
                  placeholder="Select Serial Numbers"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </div>
        {/* add button */}
        <div className="d-flex gap-3 mt-3 pt-2 ms-auto">
          <Button
            variant="outlined"
            size="small"
            disabled={
              !product.category ||
              !product.product ||
              product.serialNumbers.length < 1
            }
            onClick={() => {
              handleAddingofProductDetails();
            }}
            className="d-flex flex-row gap-1 align-items-center pe-3"
            style={{
              color: color.lotBtncolor,
              height: "38px",
              borderRadius: "8px",
              // opacity:
              //   (!product.category ||
              //     !product.product ||
              //     product.serialNumbers.length < 1) &&
              //   "0.65",
              // border: `1px solid ${color.lotBtncolor}`,
            }}
          >
            <AddIcon
              fontSize="small"
              style={{
                color: color.lotBtncolor,
                height: "auto",
                width: "25px",
              }}
            />
            <span
              className="text-nowrap"
              style={{
                fontFamily: "Open-Sans-regular",
                fontWeight: 600,
                // color: color.textWhiteColor,
              }}
            >
              Add
            </span>
          </Button>
        </div>
      </div>

      {genFields.productDetails.length > 0 ? (
        <div className="mt-3 my-2 w-100">
          <DisplayingShipmentProductDetailsTable
            genFields={genFields}
            setGenFields={setGenFields}
            isProductsPage={true}
            isSmallScreen={isSmallScreen}
            isSerialNumber={true}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ShipmentProductDetails;
