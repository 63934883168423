import React, { useContext, useEffect, useState } from "react";
import "../../../../assets/styles/CardModal.css";
import ContextState from "../../../contextApi/ContextState";
import { useNavigate, useOutletContext } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ModalError from "../../../error/ModalError";
import { Tooltip, useMediaQuery } from "@mui/material";
import { BeatLoader } from "react-spinners";
import { CardPagesSkeleton } from "../../../Exporters/ExportFunctions";
import Placeholder from "../../../error/Placeholder";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

const ProductComments = ({}) => {
  const { data, color, refresgProductDetails } = useOutletContext();

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const {
    formatDate,
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    controllerRef,
  } = useContext(ContextState);
  const { productId } = data;
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState([]);

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  // previous comments data that is coming from backend
  const [commentsData, setCommentsData] = useState(null);

  const [reloadData, setReloadData] = useState(false);

  // this loading variable is set for loading of buttons on click
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setComment(value);
  };

  const [queryStartTime, setQueryStartTime] = useState(null);

  const handleAddComment = async () => {
    try {
      let comments = [];
      comments.push(comment);

      const payload = {
        product_id: productId,
        info_type: "comments",
        comment: comment,
        query_start_time: queryStartTime,
      };

      setLoading(true);

      // Send the payload to the backend route
      const response = await fetchApi(
        "edit_product_info",
        "POST",
        payload,
        true
      );
      if (response.statusCode === 200) {

        openSnackbar(response?.data?.message, "success");
        setReloadData((prevState) => !prevState);
        refresgProductDetails();
        setEdit(false);
        setComment([]);
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error("Error editing ticket information:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    document.title = `Comments of Product ID#${productId} - NEO`;

    const fetchCardData = async () => {
      // const startTime = new Date().toUTCString();
      let startTime = new Date();
      startTime = startTime.toString().split(" (")[0];
      setQueryStartTime(startTime);
      try {
        showLoadingModal(true);
        let payload = {
          info_type: "comments",
          product_id: productId,
        };

        // if (refreshBtn) {
        //   setRefreshLoading(true);
        // }

        let jsonData;
        if (isMounted) {
          jsonData = await fetchApi(
            "get_product_info",
            "POST",
            payload,
            true,
            1
          );

          if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
            if (isMounted) {
              setCommentsData(jsonData.data.comments);
              showLoadingModal(false);
            }
          } else if (
            jsonData.statusCode === 500 ||
            jsonData.statusCode === 401
          ) {
            openSnackbar(jsonData.msg, "error");
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
            // console.log()
          } else {
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
          }
        }
        showLoadingModal(false);
      } catch (error) {
        console.log("error : ", error);
        if (controllerRef.current) {
          // openSnackbar("Request Timed Out!!!", "warning");
          console.log("fetch abort in comments ... ");
        } else {
          openSnackbar("Some error occured!", "error");
        }
      } finally {
        // setRefreshLoading(false);
        // setRefreshBtn(false);
      }
    };

    fetchCardData();

    return () => {
      isMounted = false;
    };
    // showLoadingModal(false);
  }, [reloadData]);

  return (
    <>
      {(lodingModalVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "absolute", height: "100%", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <div className="mx-3 my-3">
          <CardPagesSkeleton
            len={1}
            cardHeight={40}
            componentClass={"issue-type-row-div"}
          />
          <CardPagesSkeleton len={1} cardHeight={400} componentClass={""} />
        </div>
      )) ||
        (!showModalError.status && (
          <div
            className=" card-Div comment-section mt-3 py-4 "
            style={{ minHeight: "50vh" }}
          >
            <div className="comment-box mx-4 row">
              <div className="mb-3 mx-0 px-0">
                <button
                  // id="saveTicketBtn"
                  type="button"
                  className="btn btn-outline-primary primary_button"
                  onClick={() => setEdit(!edit)}
                >
                  <Tooltip
                    data-html="true"
                    title={"Click here to add new comment"}
                    arrow={true}
                  >
                    Add new comment
                  </Tooltip>
                </button>
              </div>

              {edit && (
                <>
                  <label className="form-label col-12">
                    Add comments
                    <textarea
                      className="form-control"
                      name="postContent"
                      onChange={handleInputChange}
                      rows={6}
                    />
                  </label>
                  <div className="">
                    <LoadingButton
                      size="large"
                      onClick={handleAddComment}
                      endIcon={<SendIcon />}
                      loading={loading}
                      disabled={comment.length === 0}
                      loadingPosition="end"
                      variant="contained"
                    >
                      <span>Add</span>
                    </LoadingButton>
                  </div>
                </>
              )}
            </div>

            {(commentsData && commentsData?.length !== 0 && (
              <div className="show-comments mx-4 px mb-2">
                <div className="previous-comments-header ">
                  <h5 className="previous-comments-header-text">
                    Previous Comments
                  </h5>
                </div>
                {commentsData.map((comment, index) => (
                  <div className=" mb-2 py-2  previous-comments" key={index}>
                    <div className="row ">
                      <div className="col-12 commented-by">
                        {comment.username}{" "}
                        <span className="comment-time-span">
                          {formatDate(comment.creation_time)}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-12 comment-text">
                          {comment.comment}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
              <div className="col-12 comment-date">{comment.creation_time}</div>
            </div> */}
                  </div>
                ))}
              </div>
            )) || (
              <div className="m-4" style={{height:"30vh"}}>
                <Placeholder
                  message="Oops! No Comment is Added yet."
                  removeBoxShadow={true}
                  icon={<CommentOutlinedIcon fontSize="inherit" />}
                />
              </div>
            )}
          </div>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
};

export default ProductComments;
