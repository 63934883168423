import React, { useContext, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import "../../../../assets/styles/CardModal.css";
import {
  Autocomplete,
  Avatar,
  Checkbox,
  FormControlLabel,
  Tooltip as MuiTooltip,
  Tooltip,
} from "@mui/material";

import { useSelector } from "react-redux";
import ModalError from "../../../error/ModalError";
import { Button, ButtonGroup } from "@mui/material";
import Modal from "@mui/material/Modal";
import close from "../../../../assets/images/close.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { BeatLoader } from "react-spinners";

import { DataGrid } from "@mui/x-data-grid";
import { FaPlus } from "react-icons/fa";
import ContextState from "../../../contextApi/ContextState";
import { camelCaseToTitleCase } from "../../AllLots";
import { formattingString } from "../../../analytics/DataPull";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import personBlue from "../../../../assets/images/person_blue.png";
import {
  findColorByStatus,
  statusColors,
} from "../../Product/SpecificProductType";
import { OverviewPageSkeleton } from "../../../Exporters/ExportFunctions";
import {
  LotGeneralPageInfo,
  LotGeneralPageInfoSkeletion,
} from "../../../Exporters/ExportFunctions";

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  const nameParts = name.split(" ");
  const firstInitial = nameParts[0][0]; // First initial
  const secondInitial = nameParts.length > 1 ? nameParts[1][0] : ""; // Second initial (empty if no last name)

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstInitial}${secondInitial}`, // Concatenate initials
  };
}

export default function LotGeneralContent() {
  const { color, specLotDetails, handleSpecLotDetails } = useOutletContext();
  const { lotId } = useParams();

  const theme = useTheme();
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const {
    fetchApi,
    formatDate,
    showModalError,
    showModalErrorPage,
    modalControllerRef,
    formatInIndianRupees,
  } = useContext(ContextState);

  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );

  // serialized details modal
  const [serializedModal, setSerializedModal] = useState({
    isOpen: false,
    selectedRow: {},
  });

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  useEffect(() => {
    showModalErrorPage(null, null);
    let isMounted = true;
    const fetchLotData = async () => {
      showLoadingModal(true);
      try {
        const payload = { info_type: "" };
        const response = await fetchApi(
          `lots/${lotId}`,
          "POST",
          payload,
          isMounted
        );

        if (response.statusCode === 200) {
          showModalErrorPage(null, null);
          showLoadingModal(false);
          handleSpecLotDetails(response.data);
        } else if (response.statusCode !== 200) {
          showModalErrorPage(response.statusCode, response.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setRefreshBtn(false);
        // setRefreshLoading(true);
        showModalErrorPage(null, null);
        showLoadingModal(false);
      }
    };

    // if (!specLotDetails) {
    fetchLotData();
    // } else {
    // showLoadingModal(false);
    // }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleCloseModal = (e, reason) => {
    // console.log("------->", e, reason);
    if (reason && reason === "backdropClick") {
      return;
    }
    setSerializedModal((prev) => ({
      ...prev,
      isOpen: false,
      selectedRow: {},
    }));
  };

  const handleTableRowClick = (row) => {
    setSerializedModal((prev) => ({
      ...prev,
      isOpen: true,
      selectedRow: { ...row },
    }));
  };

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      headers.push({
        field: "expand",
        headerName: "",
        width: 55,
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                // width: !index ? 400 : 150, // Adjust width as needed
                //   marginLeft: "5px", // Add margin to separate text from progress bar
                //   fontWeight: "bold", // Customize font weight if needed
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                // color:
                //   (item.field === "status" &&
                //     ((params.value === "rejected" && color.red) ||
                //       (params.value === "accepted" && color.greenToShow))) ||
                //   (params.row["is_rejected"][item.field] && color.red) ||
                //   color.tableRowHeading,
                textWrap: "nowrap",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {
                <MuiTooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        More Details
                      </div>
                    </>
                  }
                  arrow
                >
                  <span
                    style={{
                      // border: "2px solid blue",

                      // padding: "3px 9px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      textTransform: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      console.log(params.row);
                      // navigate(`${params.row.product_id}`);
                      if (params.row) {
                        handleTableRowClick(params.row);
                      }
                    }}
                  >
                    <FaPlus />
                  </span>
                </MuiTooltip>
              }
            </Box>
          );
        },
      });
      data.columns.forEach((item, index) => {
        // if (!["region", "district", "location"].includes(item.field)) {
        headers.push({
          field: item.field,
          headerName: item.headerName,
          width: item.width,
          // editable: item.editable,
          editable: false,
          sortable: false,
          filterable: false,
          flex: !isSmallScreen && ["product_name"].includes(item.field) ? 1 : 0,

          headerAlign: ![0].includes(index) ? "center" : "left",
          resizable: item.resizable,
          renderHeader: (params) => (
            <MuiTooltip
              title={`${item?.field
                ?.replace(/_/g, " ")
                ?.split(" ")
                .map(
                  (word) =>
                    word?.charAt(0)?.toUpperCase() +
                    word?.slice(1)?.toLowerCase()
                )
                .join(" ")}`}
              arrow
            >
              <span
                style={{
                  paddingLeft: ([0].includes(index) && "10px") || "0px",
                  extAlign: ![0].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  // fontWeight: "600",
                  color: color.tableRowHeading,
                }}
              >
                {params.colDef.headerName}
              </span>
            </MuiTooltip>
          ),
          renderCell: (params) => {
            // console.log("params-->: ", params);
            return (
              <Box
                sx={{
                  // width: !index ? 400 : 150, // Adjust width as needed
                  //   marginLeft: "5px", // Add margin to separate text from progress bar
                  //   fontWeight: "bold", // Customize font weight if needed
                  textAlign: ![0].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  fontWeight:
                    (item.field === "status" && "600") ||
                    (index !== 0 && "500") ||
                    "700",
                  fontSize: item.field === "status" ? "0.785rem" : "1.05rem",
                  color: color.tableRowHeading,
                  textWrap: "nowrap",
                }}
              >
                {
                  <MuiTooltip
                    title={
                      <>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            fontFamily: "Open-Sans-Medium",
                          }}
                        >
                          <div>
                            <strong style={{ textTransform: "capitalize" }}>
                              {params.field?.split("_").join(" ")}
                            </strong>
                            :{" "}
                            <strong
                              style={{
                                textTransform:
                                  (item.field === "status" && "capitalize") ||
                                  "none",
                              }}
                            >
                              {(item.field === "creation_time" &&
                                formatDate(params.value)) ||
                                (item.field === "last_updated" &&
                                  formatDate(params.value)) ||
                                params.value}
                            </strong>
                          </div>
                        </div>
                      </>
                    }
                    arrow
                  >
                    {(item.field === "product_name" && (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding: "0px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform: "none",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          height: "100%",
                        }}
                      >
                        <div className="product_img m-0 p-0 me-2 ">
                          <img
                            src={
                              imagesUrls[params.value] || imagesUrls["default"]
                            }
                            alt="product_img"
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        <div
                          className="product_full_desc d-flex flex-column  p-0 m-0 lh-sm"
                          style={{
                            fontFamily: "Open-Sans-Light",
                            color: color.taxtHeading,
                            fontWeight: 600,
                            width: "100%",
                          }}
                        >
                          <div
                            className="product_name p-0 m-0"
                            // style={{ height: "20px" }}
                          >
                            {params.value[0]}
                          </div>
                          <div
                            className="product_desc p-0 m-0"
                            style={{
                              width: "90%",
                              fontWeight: 400,
                              fontSize: "0.89rem",
                              overflow: "hidden", // Hide overflow
                              textOverflow: "ellipsis", // Show ellipsis
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                          >
                            {params.value[1]}
                          </div>
                        </div>
                      </span>
                    )) || (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding:
                            (item.field === "status" && "5px 9px") || "4px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform:
                            (["status", "reason"].includes(item.field) &&
                              "capitalize") ||
                            "none",
                          width: "100px",
                        }}
                      >
                        {(item.field === "created_date" &&
                          formatDate(params.value, true)) ||
                          (item.field === "updated_date" &&
                            formatDate(params.value, true)) ||
                          (item.field === "price" &&
                            formatInIndianRupees(params.value)) ||
                          params.value}
                      </span>
                    )}
                  </MuiTooltip>
                }
              </Box>
            );
          },
        });
        // }
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = useMemo(() => {
    if (
      specLotDetails?.table_data &&
      specLotDetails?.table_data?.rows?.length !== 0
    ) {
      return getFirstPageDataTableDataHeader({
        rows: specLotDetails?.table_data?.rows || [],
        columns: specLotDetails?.table_data?.columns || [],
      });
    }
    return { TableDataHeaders: [], TabelDataRows: [] };
  }, [
    JSON.stringify(specLotDetails?.table_data?.rows),
    JSON.stringify(specLotDetails?.table_data?.columns),
    isSmallScreen,
    isMediumScreen,
  ]);

  const rowHeight = 65; // Default row height in DataGrid
  const headerHeight = 105; // Default header height in DataGrid
  const maxRows = 5; // Maximum number of rows per page
  const visibleRows = Math.max(
    specLotDetails?.table_data?.rows?.length || 0,
    maxRows
  );
  const gridHeight =
    headerHeight +
    rowHeight *
      (visibleRows > 100
        ? 104.08
        : visibleRows > 50
        ? visibleRows + 4.75
        : visibleRows > 20
        ? visibleRows + 2.5
        : visibleRows) +
    (visibleRows > 100 ? 52 : 0);

  const imagesUrls = {
    Laptop:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/dell-inspiron-3000.png",
    Keybord:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
    Mobile:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/samsung-s22.png",
    Monitor:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/apple-iMac-4k.png",
    default:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
  };
  return (
    <>
      {(lodingModalVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "absolute", height: "100%", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>

        <>
          <div className="my-3">
            <LotGeneralPageInfoSkeletion
              height={380}
              len={3}
              isMediumScreen={isMediumScreen}
            />
          </div>
        </>

        // <LotGeneralPageInfoSkeletion
        //   len={3}
        //   height={430}
        //   isMediumScreen={isMediumScreen}
        // />
      )) ||
        (!showModalError.status && specLotDetails && (
          <>
            <div
              className="row"
              style={{ padding: !isMediumScreen && "0px 10px" }}
            >
              {/* left section */}
              <div
                className="col-xl-8 col-xxl-9 px-xl-1 d-flex flex-column "
                style={{ rowGap: "20px" }}
              >
                {/* lot details */}
                <div className="card-Div">
                  <div className="d-flex flex-column gap-1 p-2 mb-2">
                    <h5 className="mb-1 pb-1" style={{ fontSize: "1.15rem" }}>
                      Lot details
                    </h5>
                    <div className="general_page_main_details p-0 m-0">
                      {/* showing basic details from here */}
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: isSmallScreen
                            ? "repeat(auto-fit, minmax(250px , 1fr))"
                            : islageScreen
                            ? "repeat(3, 1fr)"
                            : "repeat(4,1fr)",
                          // gridTemplateColumns:
                          //   "repeat(auto-fit, minmax(250px , 1fr))",
                          rowGap: "15px",
                        }}
                      >
                        {specLotDetails.lotDetails &&
                          Object.entries(specLotDetails.lotDetails).map(
                            ([key, value]) => (
                              <div
                                key={key}
                                className="basic_details_elements"
                                style={{ maxWidth: "100%" }}
                              >
                                <label
                                  htmlFor={`outlined-read-only-input-${key}`}
                                  className="basic_details_elements_label"
                                  style={{ fontSize: "0.95rem" }}
                                >
                                  {formattingString(key)}
                                </label>
                                <div
                                  id={`outlined-read-only-input-${key}`}
                                  className="basic_details_elements_content"
                                  style={{
                                    fontSize:
                                      (isSmallScreen && "0.95rem") || "1.15rem",
                                  }}
                                >
                                  {key == "creation_date" ||
                                  key == "arrival_date"
                                    ? formatDate(value).slice(0, 16)
                                    : value}
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* displaying in small screens */}
                {/* seller details */}
                <div
                  className="card-Div d-block d-xl-none "
                  style={{
                    padding: "20px",
                  }}
                >
                  <h5 className="mb-2 pb-1" style={{ fontSize: "1.15rem" }}>
                    Seller Details
                  </h5>

                  <div className="d-flex flex-column lot_general_desp">
                    <div className="d-flex align-items-start justify-content-start gap-2 mb-3">
                      {true && (
                        <div
                          style={{ minWidth: "40px" }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <div
                            className="rounded-circle d-flex align-items-center justify-content-center"
                            style={{
                              width: "40px",
                              height: "40px",
                              backgroundColor: color.iconBackgroundBlue,
                            }}
                          >
                            {/* <img
                              className="img-fluid rounded-circle "
                              src={personBlue}
                              alt="seller img"
                              style={{
                                width: "30px",
                                height: "30px",
                                padding: "4px",
                              }}
                            /> */}
                            <Avatar
                              {...stringAvatar(
                                specLotDetails?.sellerDetails?.seller_name
                              )}
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex-grow-1">
                        {/* <div
                          className="basic_details_elements_label"
                          style={{ lineHeight: "1.3", fontSize: "0.93rem" }}
                        >
                          Seller Address
                        </div> */}

                        <div
                          className="basic_details_elements_content"
                          style={{ lineHeight: "1.6rem", fontSize: "1.005rem" }}
                        >
                          {specLotDetails.sellerDetails.seller_name}
                        </div>
                        <div
                          className="basic_details_elements_label text-wrap"
                          style={{ lineHeight: "1.3", fontSize: "0.92rem" }}
                        >
                          {specLotDetails.sellerDetails.seller_address}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <p
                        style={{
                          // fontSize: "0.95rem",
                          marginBottom: "5px",
                          // color: "#3b4056",
                        }}
                      >
                        Contanct Info
                      </p>
                      {Object.entries(specLotDetails.sellerDetails).map(
                        ([key, value]) =>
                          ["person", "phone", "email"].includes(key) && (
                            <div
                              key={key}
                              className="d-flex align-items-center gap-1 mb-1"
                            >
                              <p
                                htmlFor={`outlined-read-only-input-${key}`}
                                // className="basic_details_elements_label mb-0"
                                // style={{
                                //   fontSize: "0.92rem",
                                // }}
                                className="p-0 m-0"
                                style={{
                                  color: color.taxtHeadingLight,
                                  fontWeight: 800,
                                  fontSize:
                                    (isSmallSmallScreen && "0.85rem") ||
                                    "0.95rem",
                                }}
                              >
                                {formattingString(key)}
                              </p>
                              <p
                                // className="mb-0"
                                // style={{ fontSize: "0.92rem" }}
                                className=" m-0 p-0"
                                style={{
                                  color: color.tableRowHeading,
                                  fontWeight: 600,
                                  fontSize:
                                    (isSmallSmallScreen && "0.95rem") ||
                                    "1.05rem",
                                }}
                              >
                                : {value}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>

                {/* data grid table */}
                <div className="card-Div p-0">
                  <div className="d-flex flex-column gap-1 p- mb-2">
                    <h5
                      style={{
                        fontSize: "1.12rem",
                        padding: "20px 22px",
                        marginBottom: "4px",
                        // fontWeight: "600",
                      }}
                    >
                      Product Details
                    </h5>
                    {/* <div>Form/Checklist comming soon ...</div> */}

                    <div className="">
                      <Box
                        sx={{
                          height:
                            (specLotDetails?.tickets_limit === "All" &&
                              "55vh") ||
                            gridHeight,
                          // width: "100%",
                          // maxWidth: "82vw",
                        }}
                      >
                        <DataGrid
                          rows={TabelDataRows}
                          columns={TableDataHeaders}
                          rowHeight={62}
                          columnHeaderHeight={62}
                          // pagination={false} // Disable pagination
                          // pagination={100} // Disable pagination
                          // autoPageSize // Automatically adjust page size based on available height
                          density="standard"
                          paginationMode="client"
                          // components={{
                          //   Pagination:
                          //     visibleRows > 100 ? CustomPagination : null,
                          // }}
                          // hideFooterPagination
                          pageSize={101}
                          disableSelectionOnClick
                          disableRowSelectionOnClick
                          disableColumnFilter
                          onRowClick={(param) => {
                            // console.log("row_param : ", param.row);
                            // if (param.row) {
                            //   handleTableRowClick(param.row);
                            // }
                          }}
                          //   getRowClassName={(params) => {
                          //     // console.log(
                          //     //   "params : ",
                          //     //   params,
                          //     //   params.row.id === selectedRow?.id
                          //     // );
                          //     return params.row.id === selectedRow?.id ? "selected-row" : "";
                          //   }}
                          sx={{
                            "&.MuiDataGrid-root": {
                              border: "none",
                              borderRadius: "5px", // Adjust border radius as needed
                              overflow: "hidden", // Ensure content respects the border radius,
                              padding: "0px",
                              margin: "0px",
                              marginTop: "-10px",
                              borderColor: "white",
                            },

                            ".MuiDataGrid-columnHeader": {
                              backgroundColor: color.primary,
                              border: "none",
                            },
                            ".MuiDataGrid-filler": {
                              backgroundColor: color.textWhiteColor,
                            },
                            ".MuiDataGrid-columnHeaderTitle": {
                              fontFamily: "Open-Sans-Light",
                              fontWeight: "bold",
                              padding: "5px 0px",
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                              color: color.taxtHeading,
                            },
                            ".MuiDataGrid-columnHeader--alignCenter": {
                              headerAlign: "left",
                            },
                            ".MuiDataGrid-row": {
                              border: "none", // Remove border from rows
                              backgroundColor: color.textWhiteColor, // Set rows background color to white
                              "&:hover": {
                                backgroundColor: color.primary, // Ensure background color remains white on hover
                                // cursor: "pointer",
                              },
                            },
                            ".MuiDataGrid-footerContainer": {
                              display:
                                (visibleRows <= 50 && "none") || "inline-block", // Hide the pagination footer
                              justifyContent: "space-between", // Space out selected row count and pagination controls
                              alignItems: "center", // Vertically align items in the footer
                              marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                              height: "30px",
                            },
                            ".MuiTablePagination-root": {
                              // ".MuiInputBase-root": {
                              //   display: "none",
                              // },

                              // ".MuiTablePagination-selectLabel": {
                              //   display: "none",
                              // },
                              ".MuiToolbar-root": {
                                marginTop: "0px",
                              },
                            },
                            ".MuiDataGrid-cell": {
                              // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                              outline: "none",
                              // display: "-webkit-box",
                              // WebkitBoxOrient: "vertical",
                              // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                              // overflow: "hidden",
                              // textOverflow: "ellipsis",
                              "&:focus": {
                                outline: "none", // Remove the blue outline when a cell is focused
                              },
                            },
                            "&.selected-row": {
                              backgroundColor: "red", // Highlight color for the selected row
                              "&:hover": {
                                backgroundColor: "red ", // Ensure highlight color remains on hover
                              },
                            },
                            ".MuiDataGrid-selectedRowCount": {
                              display: "none", // Hide the selected row count div
                            },
                            ".MuiTablePagination-toolbar": {
                              // width: "80%",  // Adjust the width here
                              // margin: "0 auto", // Center align the pagination
                              // marginLeft:"auto",
                              padding: "2px 16px", // You can adjust padding as well if needed
                              marginTop: "-15px",
                            },
                            "& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader":
                              {
                                borderBottom: "none",
                              },
                            "& .MuiDataGrid-row--borderBottom .MuiDataGrid-filler":
                              {
                                borderBottom: "none",
                              },
                            "& .MuiDataGrid-row--borderBottom .MuiDataGrid-scrollbarFiller":
                              {
                                borderBottom: "none",
                              },
                            ".MuiDataGrid-columnSeparator--resizable": {
                              display: "none",
                            },
                            ".MuiDataGrid-menuOpen": {
                              visibility: "hidden",
                            },
                            ".MuiDataGrid-menuIcon": {
                              display: "none",
                            },
                          }}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

              {/* right section */}
              {/* Seller  Details */}
              <div className="col-xl-4 col-xxl-3 px-xl-3 d-none d-xl-block">
                <div
                  className="card-Div"
                  style={{
                    padding: "20px",
                  }}
                >
                  <h5 className="mb-2 pb-1" style={{ fontSize: "1.15rem" }}>
                    Seller Details
                  </h5>

                  <div className="d-flex flex-column lot_general_desp">
                    <div className="d-flex align-items-start justify-content-start gap-2 mb-3">
                      {true && (
                        <div
                          style={{ minWidth: "40px" }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <div
                            className="rounded-circle d-flex align-items-center justify-content-center"
                            style={{
                              width: "40px",
                              height: "40px",
                              backgroundColor: color.iconBackgroundBlue,
                            }}
                          >
                            {/* <img
                              className="img-fluid rounded-circle "
                              src={personBlue}
                              alt="seller img"
                              style={{
                                width: "30px",
                                height: "30px",
                                padding: "4px",
                              }}
                            /> */}
                            <Avatar
                              {...stringAvatar(
                                specLotDetails?.sellerDetails?.seller_name
                              )}
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex-grow-1">
                        {/* <div
                          className="basic_details_elements_label"
                          style={{ lineHeight: "1.3", fontSize: "0.93rem" }}
                        >
                          Seller Address
                        </div> */}

                        <div
                          className="basic_details_elements_content"
                          style={{ lineHeight: "1.6rem", fontSize: "1.005rem" }}
                        >
                          {specLotDetails.sellerDetails.seller_name}
                        </div>
                        <div
                          className="basic_details_elements_label text-wrap"
                          style={{ lineHeight: "1.3", fontSize: "0.92rem" }}
                        >
                          {specLotDetails.sellerDetails.seller_address}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <p
                        style={{
                          // fontSize: "0.95rem",
                          marginBottom: "5px",
                          // color: "#3b4056",
                        }}
                      >
                        Contanct Info
                      </p>
                      {Object.entries(specLotDetails.sellerDetails).map(
                        ([key, value]) =>
                          ["person", "phone", "email"].includes(key) && (
                            <div
                              key={key}
                              className="d-flex align-items-center gap-1 mb-1"
                            >
                              <p
                                htmlFor={`outlined-read-only-input-${key}`}
                                // className="basic_details_elements_label mb-0"
                                // style={{
                                //   fontSize: "0.92rem",
                                // }}
                                className="p-0 m-0"
                                style={{
                                  color: color.taxtHeadingLight,
                                  fontWeight: 600,
                                  fontSize:
                                    (isSmallSmallScreen && "0.85rem") ||
                                    "0.92rem",
                                }}
                              >
                                {formattingString(key)}
                              </p>
                              <p
                                // className="mb-0"
                                // style={{ fontSize: "0.92rem" }}
                                className=" m-0 p-0"
                                style={{
                                  color: color.tableRowHeading,
                                  fontWeight: 600,
                                  fontSize:
                                    (isSmallSmallScreen && "0.95rem") ||
                                    "0.93rem",
                                }}
                              >
                                : {value}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* serialized modal */}
            {serializedModal.isOpen && (
              <SerializedModal
                isOpen={serializedModal.isOpen}
                selectedRow={serializedModal.selectedRow}
                handleClose={handleCloseModal}
                color={color}
                isMediumScreen={isMediumScreen}
                isSmallScreen={isSmallScreen}
              />
            )}

            {/* not needed this time  */}
          </>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
}

// detailed view of Serial numbers modal

function SerializedModal(props) {
  const {
    isOpen,
    selectedRow,
    handleClose,
    color,
    isMediumScreen,
    isSmallScreen,
  } = props;

  const { formatInIndianRupees } = useContext(ContextState);
  const navigate = useNavigate();
  const location = useLocation();

  const tableColumns = Object.keys(selectedRow)
    .map((item) => {
      if (item !== "id" && item !== "quantity") {
        return {
          field: item,
          headerName: formattingString(item),
          width: 150,
        };
      }
      return null;
    })
    .filter(Boolean);

  const tableRows = selectedRow?.serial_ids?.map((item, index) => ({
    ...selectedRow,
    id: index + 1,
    serial_ids: item,
    price: parseInt(selectedRow?.price) / parseInt(selectedRow?.quantity),
  }));

  console.log("tableRows", tableRows);

  const handleNavigateToProductPage = (e, category) => {
    if (e.ctrlKey || e.metaKey) {
      const url = `/allproducts/${category}`;
      window.open(url, "_blank");
    } else {
      navigate(`/allproducts/${category}`);
    }
  };

  function getFirstPageDataTableDataHeader(data) {
    if (data?.rows) {
      const hasSerialIds = data.rows.some((item) => item.serial_ids !== null);

      if (!hasSerialIds) {
        data.column = data.column.filter((col) => col.field !== "serial_ids");
      }
    }

    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      // Add all your other columns (Product Name, Serial Number, etc.)
      headers.push({
        field: "expand",
        headerName: "",
        width: 55,
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                // width: !index ? 400 : 150, // Adjust width as needed
                //   marginLeft: "5px", // Add margin to separate text from progress bar
                //   fontWeight: "bold", // Customize font weight if needed
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                // color:
                //   (item.field === "status" &&
                //     ((params.value === "rejected" && color.red) ||
                //       (params.value === "accepted" && color.greenToShow))) ||
                //   (params.row["is_rejected"][item.field] && color.red) ||
                //   color.tableRowHeading,
                textWrap: "nowrap",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {
                <MuiTooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        More Details
                      </div>
                    </>
                  }
                  arrow
                >
                  <a
                    style={{
                      // border: "2px solid blue",

                      // padding: "3px 9px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      textTransform: "none",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      handleNavigateToProductPage(e, params?.row?.category);
                    }}
                    // href={`/allproducts/${params?.row?.category}`}
                  >
                    <FaPlus />
                  </a>
                </MuiTooltip>
              }
            </Box>
          );
        },
      });

      data.column.forEach((item, index) => {
        if (!["uuid", "notes"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex: isSmallScreen ? 0 : 1,
            headerAlign: index === 0 ? "left" : "center",
            renderHeader: (params) => (
              <Tooltip title={item.headerName} arrow>
                <span style={{ paddingLeft: index === 0 ? "10px" : "0px" }}>
                  {params.colDef.headerName}
                </span>
              </Tooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              const finalParamValue = ["quantity", "price"].some(
                (items) => item.field === items && params.value !== ""
              )
                ? formatInIndianRupees(params.value)
                : ["quantity"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "1"
                : ["serial_number"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "-"
                : params.value;
              return (
                <Box
                  sx={{
                    textAlign: index !== 0 ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight: (index !== 0 && "500") || "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color:
                      (item.field == "status" &&
                        findColorByStatus(params.value)) ||
                      color.tableRowHeading,
                    textWrap: "nowrap",
                    // backgroundColor:
                    //   (item.field == "status" &&
                    //     findColorByStatus(params.value)) ||
                    //   "",
                  }}
                >
                  {
                    <Tooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params?.field?.split("_")?.join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {finalParamValue}
                              </strong>
                            </div>
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding: "4px 9px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor:
                            (item.field === "status" &&
                              findColorByStatus(params.value) + "33") ||
                            "transparent",
                        }}
                      >
                        {item.field === "product_name"
                          ? finalParamValue[0]
                          : finalParamValue}
                      </span>
                    </Tooltip>
                  }
                </Box>
              );
            },
          });
        }
      });
    }

    data.rows.forEach((item, index) => {
      rows.push({ ...item, id: index + 1 });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = (tableColumns &&
    tableRows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: tableRows || [],
      column: tableColumns || [],
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  return (
    <Modal
      open={isOpen}
      onCLose={handleClose}
      style={{
        position: "fixed",
        height: "100%",
        margin: "10px",
      }}
    >
      <Box
        style={{
          position: "relative",
          zIndex: "50",
          top: "15%",
          margin: "  auto",
          overflowY: "auto",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          maxWidth: "992px",
        }}
      >
        <div
          style={{
            padding: "16px 24px",
            borderBottom: "1px solid #f5f5f5",
          }}
        >
          <div className="d-flex justify-content-between">
            <h5 className="m-0">Detailed Product Details</h5>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {/* body starts */}
        <div style={{ padding: "28px 20px 20px" }}>
          <DataGrid
            columns={TableDataHeaders}
            rows={TabelDataRows}
            // rowHeight={25}
            sx={{
              "&.MuiDataGrid-root": {
                border: "1px solid",
                borderRadius: "5px", // Adjust border radius as needed
                overflow: "hidden", // Ensure content respects the border radius,
                padding: "0px",
                margin: "0px",
                marginTop: "-10px",
                borderColor: "#f5f5f7",
              },

              ".MuiDataGrid-columnHeader": {
                backgroundColor: "#f5f5f7",
                // border: "none",
              },
              ".MuiDataGrid-filler": {
                backgroundColor: color.textWhiteColor,
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Open-Sans-Light",
                fontWeight: "bold",
                padding: "5px 0px",
                fontSize: "0.85rem",
                textTransform: "capitalize",
                color: color.taxtHeading,
              },
              ".MuiDataGrid-columnHeader--alignCenter": {
                headerAlign: "left",
              },
              ".MuiDataGrid-row": {
                // border: "none", // Remove border from rows
                backgroundColor: color.textWhiteColor, // Set rows background color to white
                "&:hover": {
                  backgroundColor: color.primary, // Ensure background color remains white on hover
                  // cursor: "pointer",
                },
              },
              ".MuiDataGrid-footerContainer": {
                // display: (visibleRows <= 50 && "none") || "inline-block", // Hide the pagination footer
                justifyContent: "space-between", // Space out selected row count and pagination controls
                alignItems: "center", // Vertically align items in the footer
                marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                height: "30px",
              },
              ".MuiTablePagination-root": {
                // ".MuiInputBase-root": {
                //   display: "none",
                // },

                // ".MuiTablePagination-selectLabel": {
                //   display: "none",
                // },
                ".MuiToolbar-root": {
                  marginTop: "0px",
                },
              },
              ".MuiDataGrid-cell": {
                // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                outline: "none",
                // display: "-webkit-box",
                // WebkitBoxOrient: "vertical",
                // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                // overflow: "hidden",
                // textOverflow: "ellipsis",
                "&:focus": {
                  outline: "none", // Remove the blue outline when a cell is focused
                },
              },
              "&.selected-row": {
                backgroundColor: "red", // Highlight color for the selected row
                "&:hover": {
                  backgroundColor: "red ", // Ensure highlight color remains on hover
                },
              },
              ".MuiDataGrid-selectedRowCount": {
                display: "none", // Hide the selected row count div
              },
              ".MuiTablePagination-toolbar": {
                // width: "80%",  // Adjust the width here
                // margin: "0 auto", // Center align the pagination
                // marginLeft:"auto",
                padding: "2px 16px", // You can adjust padding as well if needed
                marginTop: "-15px",
              },
              "& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row--borderBottom .MuiDataGrid-filler": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row--borderBottom .MuiDataGrid-scrollbarFiller": {
                borderBottom: "none",
              },
              ".MuiDataGrid-columnSeparator--resizable": {
                display: "none",
              },
              ".MuiDataGrid-menuOpen": {
                visibility: "hidden",
              },
              ".MuiDataGrid-menuIcon": {
                display: "none",
              },
              ".MuiDataGrid-footerContainer": {
                display: "none",
              },
            }}
          />
        </div>
      </Box>
    </Modal>
  );
}
