import React, { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../contextApi/ContextState";
import dayjs from "dayjs";
import close from "../../assets/images/close.svg";
import { DataGrid } from "@mui/x-data-grid";
import { BeatLoader } from "react-spinners";
import { CardPagesSkeleton, TableSkeleton } from "../Exporters/ExportFunctions";

const color = {
  primary: "#f7f7f9", // website background
  // secondary: "#8884d8",
  // secondary: "#826af9", // blue color in dashboard
  // secondary: "#0098e9", // blue color in dashboard
  secondary: "#666bff", // blue color in dashboard
  green: "#82ca9d", // green color in dashboard
  greenToShow: "#72e128", // green color in dashboard
  red: "#ff4d49d9", // red color in dashboard
  orange: "#fdb528", // orange color in dashboard
  navBorderBottom: "#4c4e6422",
  taxtHeadingLight: "#4c4e6499", // normal text heading light color
  taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
  tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
  iconBackgroundBlue: "#666bff39",
  iconBackgroundWhite: "#ffffffe6",
  iconBackgroundgreen: "#82ca9d39",
  iconBackgroundred: "#ff4d4939",
  iconBackgroundorange: "#fff3dd",
  textWhiteColor: "#ffffff",
  textDescBackgroundRegionDiv: "#5b61e5",
  locationTextHeading: "#4c4e64fc", //location heeading color
  option_1: "#8884d8",
  option_1_bg: "#8884d830",
  option_2: "#82ca9d",
  option_2_bg: "#82ca9d30",
  option_3: "#fdb528d9",
  option_3_bg: "#fdb52830",
  option_4: "#26c6f9d9",
  option_4_bg: "#26c6f930",
  upper_limit: "#ff4d49d9",
  lower_limit: "#ff4d4966",
};

const AddScanIds = ({ scandetails, handleClose, gettingValuesfromscanids }) => {
  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );

  const { fetchApi, openSnackbar, formatDate } = useContext(ContextState);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [modalState, setModalState] = useState({
    page: 1,
    scan_ids_limit: 30,
  });
  const [data, setData] = useState([]);

  const isMediumScreen = useMediaQuery("(max-width:1200px)");

  useEffect(() => {
    if (scandetails?.fetch_api) {
      callApi();
    }
  }, [scandetails?.fetch_api]);

  const getCropId = (cropName) => {
    for (const [key, value] of Object.entries(filterData.param_data)) {
      if (value.matt_crop === cropName) {
        return value.crop_id;
      }
    }
    return null;
  };

  const callApi = async () => {
    try {
      setModalLoading(true);
      let payload;
      if (scandetails?.pull_data_state) {
        // console.log("add scan", scandetails);
        payload = {
          page_num: modalState.page,
          scan_ids_limit: modalState?.scan_ids_limit,
          scan_date: dayjs(scandetails?.date).format("YYYY-MM-DD"),
          crop_id: getCropId(scandetails?.crop_type),
          machine_code: parseInt(scandetails?.machineNumber),
        };
      } else {
        // console.log("add scan", scandetails);
        payload = {
          page_num: modalState.page,
          scan_ids_limit: modalState?.scan_ids_limit,
          scan_date: dayjs(scandetails?.date).format("YYYY-MM-DD"),
          crop_id: getCropId(scandetails?.crop_id),
          machine_code: parseInt(scandetails?.machine),
        };
      }

      const response = await fetchApi(
        "get_scan_ids_list",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        setData(response?.data);
      } else if (response?.statusCode === 500 || response?.statusCode === 401) {
        setData(response);
        openSnackbar(response.msg, "error");
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      openSnackbar("some error occured while saving details", error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleCheckboxChange = (ids) => {
    setSelectedItems([]); // Reset selectedItems before updating

    // Map ids to corresponding "Scan Id" values from data.rows
    const values = ids.map((id) => {
      const each_item = data?.rows?.find((item) => item.id === id);
      if (each_item) {
        if (scandetails?.pull_data_state) {
          // Remove last digits if pull_data_state is true
          const scanId = each_item["Scan Id"];
          const lastIndex = scanId.lastIndexOf(".");
          if (lastIndex !== -1) {
            return scanId.substring(0, lastIndex);
          }
          return scanId; // Return original Scan Id if no suitable split found
        } else {
          return each_item["Scan Id"]; // Return Scan Id as is
        }
      }
      return null; // Handle the case where id doesn't match any item
    });

    // console.log("values", values);

    // Filter out undefined or null values (if necessary)
    const filteredValues = values.filter(
      (value) => value !== undefined && value !== null
    );

    // Update selectedItems state with the filtered values
    setSelectedItems(filteredValues);
  };

  const modalstyle = {
    position: "relative",
    zIndex: "1111",
    top: "15%",
    margin: "  auto",
    overflowY: "auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    maxWidth: "992px",
    height: "60vh",
  };

  function custom_columns(col) {
    if (!col) {
      return;
    }

    return col.map((each_col, index) => {
      let updatedCol = { ...each_col };
      // Dynamically assign valueGetter based on headerName
      updatedCol.flex = index === 0 || isMediumScreen || index === 3 ? 0 : 1;

      if (each_col.headerName === "Scan Id") {
        updatedCol.valueGetter = (params) =>
          params.split(".").slice(0, -1).join(".");
      } else if (each_col.headerName === "Scan Time") {
        updatedCol.valueGetter = (params) => formatDate(params);
      } else if (each_col.headerName === "Status") {
        updatedCol.valueGetter = (params) => (params ? "Accepted" : "Rejected");

        // Add renderCell for Status column
        updatedCol.renderCell = (params) => {
          const statusText =
            params.value === "Accepted" ? "Accepted" : "Rejected";
          const color = params.value === "Accepted" ? "#68c00e" : "#f00707";
          const backgroundColor =
            params.value === "Accepted" ? "#e8fadd" : "#fae4e8";

          return (
            <span
              style={{
                color: color,
                backgroundColor: backgroundColor,
                borderRadius: "20px",
                height: "20px",
                marginTop: "10px",
                fontSize: "0.85rem",
                padding: "4px 12px",
              }}
            >
              {statusText}
            </span>
          );
        };
      }

      return updatedCol;
    });
  }

  return (
    // <div
    //   className="showSpinnerTicketModal"
    //   style={{ width: "100%", height: "100%" }}
    // >
    //   <BeatLoader color="#2D97D4" />
    // </div>
    <Modal
      open={scandetails?.isOpen}
      onClose={handleClose}
      style={{
        position: "fixed",
        height: "100%",
        // margin: "10px 0px 10px 10px",
      }}
    >
      <Box style={modalstyle} className=" col-10 col-sm-9 col-md-7 col-lg-7">
        {(modalLoading && (
          <div className="my-3">
            <div className="mx-2">
              <CardPagesSkeleton
                len={1}
                cardHeight={30}
                componentClass={"issue-type-row-div"}
              />
            </div>
            <TableSkeleton rowsNum={10} colsNum={5} />
          </div>
        )) || (
          <>
            <Typography
              style={{
                padding: "16px 24px",
                borderBottom: "1px solid #f5f5f5",
              }}
            >
              <div className="d-flex justify-content-between">
                <h5 className="m-0">Select Scan Id</h5>
                <div
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <img
                    src={close}
                    alt="close"
                    style={{ width: "20px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </Typography>
            {data?.rows?.length > 0 && !modalLoading ? (
              <Typography className="p-4">
                <div className="card-Div">
                  <div style={{ width: "100%", height: 400 }}>
                    {" "}
                    <DataGrid
                      checkboxSelection
                      // disableRowSelectionOnClick
                      rows={data?.rows}
                      // columns={data?.columns || []}
                      columns={custom_columns(data?.columns)}
                      onRowSelectionModelChange={(ids) => {
                        handleCheckboxChange(ids);
                      }}
                      sx={{
                        "&.MuiDataGrid-root": {
                          border: "none",
                          borderRadius: "5px", // Adjust border radius as needed
                          overflow: "hidden", // Ensure content respects the border radius,
                          padding: "0px",
                          margin: "0px",
                          marginTop: "-10px",
                        },
                        ".MuiDataGrid-columnHeader": {
                          backgroundColor: color.textWhiteColor,
                          // justifyContent: "center",
                        },

                        ".MuiDataGrid-columnHeaderTitleContainer": {
                          // justifyContent: "center",
                        },
                        ".MuiDataGrid-filler": {
                          backgroundColor: color.textWhiteColor,
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                          fontFamily: "Open-Sans-Light",
                          fontWeight: "bold",
                          padding: "5px 0px",
                          fontSize: "0.85rem",
                          textTransform: "capitalize",
                          color: color.taxtHeading,
                        },
                        ".MuiDataGrid-columnHeader--alignCenter": {
                          headerAlign: "left",
                        },
                        ".MuiDataGrid-row": {
                          border: "none", // Remove border from rows
                          backgroundColor: color.textWhiteColor, // Set rows background color to white
                          "&:hover": {
                            backgroundColor: color.primary, // Ensure background color remains white on hover
                            // cursor: "pointer",
                          },
                        },

                        ".MuiDataGrid-cell": {
                          outline: "none",
                          "&:focus": {
                            outline: "none", // Remove the blue outline when a cell is focused
                          },
                        },
                        // Custom CSS to hide the "Rows per page" component

                        ".MuiDataGrid-footerContainer": {
                          display: "none", // Hide the pagination footer
                        },
                        ".MuiTablePagination-root": {
                          ".MuiInputBase-root": {
                            display: "none",
                          },

                          ".MuiTablePagination-selectLabel": {
                            display: "none",
                          },
                          ".MuiToolbar-root": {
                            marginTop: "12px",
                          },
                        },
                        "&.selected-row": {
                          backgroundColor: "red", // Highlight color for the selected row
                          "&:hover": {
                            backgroundColor: "red ", // Ensure highlight color remains on hover
                          },
                        },
                        ".MuiDataGrid-selectedRowCount": {
                          display: "none", // Hide the selected row count div
                        },
                      }}
                    />
                  </div>
                </div>
              </Typography>
            ) : (
              <div
                className="container no-ticket-found-div text-center count_heading_div"
                style={{ height: "84%" }}
              >
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                  <p>No Scan Ids Data Found</p>
                </div>
              </div>
            )}
            {data?.rows?.length > 0 ? (
              <Typography
                style={{
                  padding: " 0px 24px",
                  float: "right",
                  display: "block",
                }}
              >
                <LoadingButton
                  size="medium"
                  endIcon={<SendIcon />}
                  // loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  onClick={() => {
                    setSelectedItems([]);
                    setModalState({ page: 1, scan_ids_limit: 30 });
                    gettingValuesfromscanids(selectedItems);
                  }}
                  disabled={selectedItems.length < 1 ? true : false}
                >
                  <span>Submit</span>
                </LoadingButton>
              </Typography>
            ) : null}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AddScanIds;
