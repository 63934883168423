import Carousel from "react-bootstrap/Carousel";
import React, { useContext, useEffect, useState } from "react";

// India Sate maps
import "react-svg-map/lib/index.css";

import { Tooltip as MuiTooltip, FormControl } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Andhra_Pradesh_IMG from "../../../assets/images/maps/Andhra_Pradesh_light_blue.png";
import Assam_IMG from "../../../assets/images/maps/assam_light_blue.png";
import chhattisgarh_IMG from "../../../assets/images/maps/chhattisgarh_light_blue.png";
import Haryana_IMG from "../../../assets/images/maps/Haryana_light_blue.png";
import MP_IMG from "../../../assets/images/maps/MP_light_blue.png";
import Odisha_IMG from "../../../assets/images/maps/Odisha_light_blue.png";
import Punjab_IMG from "../../../assets/images/maps/Punjab_light_blue.png";
import Rajasthan_IMG from "../../../assets/images/maps/Rajasthan_light_blue.png";
import telangana_IMG from "../../../assets/images/maps/telangana_light_blue.png";
import UP_IMG from "../../../assets/images/maps/UP_light_blue.png";
import Uttrakand_IMG from "../../../assets/images/maps/Uttrakand_light_blue.png";

import TotalMachinesImg from "../../../assets/images/machine-blue.png";

import TotalDistrictImg from "../../../assets/images/district-blue.png";

import ContextState from "../../contextApi/ContextState";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

function RegionCrousel({ test, color, data, pageStyling, showAccepted }) {
  // data Table
  // console.log("Region Crousel Data : ", data);

  // const buttons = [
  //   { label: "Amaravathi", clicked: false },
  //   { label: "Assam", clicked: false },
  //   { label: "Raipur", clicked: false },
  //   { label: "Panchkula", clicked: false },
  //   { label: "Bhopal", clicked: false },
  //   { label: "Odisha", clicked: false },
  //   { label: "Chandigarh", clicked: false },
  //   { label: "Telengana", clicked: false },
  //   { label: "Lucknow", clicked: false },
  //   { label: "Dehradun", clicked: false },
  // ];

  const { formatInIndianRupees } = useContext(ContextState);

  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);
  // const [currentRegion, setCurrentRegion] = useState("");

  const handleSelect = (selectedIndex) => {
    setClickedButtonIndex(selectedIndex);
  };
  const handleChange = (event) => {
    // setAge(event.target.value as string);
    const index = event.target.value;
    const selected_index = parseInt(index);
    const selectedButton = data[selected_index];

    // setIndex(index);
    setClickedButtonIndex(selected_index);
    // setCurrentRegion(selectedButton.name);

    // console.log(
    //   "index---->",
    //   typeof selected_index,
    //   selected_index,
    //   selectedButton
    // );
  };

  // console.log("showAccepted : -->", showAccepted)

  const getColorClass = (state) => {
    if (state) {
      return "shade1";
    } else {
      return "shade8";
    }
  };

  function getImageSrc(name) {
    const searchName = name.toLowerCase();
    switch (searchName || "") {
      case "amravati":
        return Andhra_Pradesh_IMG;
      case "assam":
        return Assam_IMG;
      case "raipur":
        return chhattisgarh_IMG;
      case "panchkula":
        return Haryana_IMG;
      case "bhopal":
        return MP_IMG;
      case "odisha":
        return Odisha_IMG;
      case "chandigarh":
        return Punjab_IMG;
      case "hyderabad":
        return telangana_IMG;
      case "lucknow":
        return UP_IMG;
      case "dehradun":
        return Uttrakand_IMG;
      default:
        // return Andhra_Pradesh_IMG;
        return "";
    }
  }
  const [divWidth, setDivWidth] = useState(null);
  useEffect(() => {
    // finding the current width of the div
    const handleResize = () => {
      const width = document.getElementById("myDiv").offsetWidth;
      setDivWidth(width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // unmouting the interval from the dom
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // function findValue_

  return (
    <div className="position-relative" id="myDiv">
      <div className="w-100 d-flex justify-content-end position-absolute z-1">
        <FormControl
          sx={{
            m: 1,
            minWidth: 100,
            backgroundColor: color.iconBackgroundWhite,
            borderRadius: "5px",
          }}
          size="small"
        >
          {/* <InputLabel
            id="demo-simple-select-label"
            sx={{ fontSize: "0.77rem", color: color.textWhiteColor }}
          >
            Region
          </InputLabel> */}
          <Select
            // labelId="demo-simple-select-label"
            // id="demo-simple-select"
            // label="Region"
            value={clickedButtonIndex}
            onChange={handleChange}
            sx={{ padding: "0px", margin: "0px", fontSize: "0.87rem" }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: color.iconBackgroundWhite,
                  borderRadius: "5px", // Apply borderRadius if needed
                },
              },
            }}
          >
            {data?.map((each_btn, index) => (
              <MenuItem
                className="option"
                value={index}
                key={`items-${each_btn}-${index}`}
              >
                {each_btn.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="">
        {/* Region Crousel */}
        <div
          className="card-Div p-0 m-0"
          style={{
            boxShadow: "none",
            backgroundColor: "inherit",
          }}
        >
          <Carousel
            data-bs-theme="dark"
            interval={2500}
            slide={true}
            pause={"hover"}
            controls={false}
            indicators={true}
            className="custom-carousel"
            activeIndex={clickedButtonIndex}
            onSelect={handleSelect}
          >
            {data.map((CarouselItem, index) => (
              <Carousel.Item key={index}>
                <div>
                  {/* Top heading */}
                  <div
                    className="bar-chart-heading pt-2 ps-2"
                    style={{ color: color.textWhiteColor }}
                  >
                    {CarouselItem.name}
                    <p style={{ fontSize: "1.05rem", marginTop: "5px" }}>
                      <span
                        key={test}
                        className="region-chart-value rotatingText-adjective"
                        style={{
                          marginRight: "5px",
                          color: color.textWhiteColor,
                        }}
                      >
                        {formatInIndianRupees(
                          CarouselItem.data.find(
                            (item) => item.label === "Total Scans"
                          ).value
                        )}
                      </span>
                      Total Scans
                    </p>
                  </div>
                  {/* Desc & img */}
                  <div className="carousel-region-div my-4 mb-5 w-100">
                    <div className="d-flex align-items-center flex-column flex-grow-1">
                      <div className="carousel-region-desc-div w-100 ">
                        {CarouselItem.data.map((regionDesc, index) => {
                          if (
                            showAccepted
                              ? regionDesc.label.toLowerCase() !==
                                  "total scans" &&
                                regionDesc.label.toLowerCase() !==
                                  "top district" &&
                                regionDesc.label.toLowerCase() !== "top machine"
                              : regionDesc.label.toLowerCase() !==
                                  "total scans" &&
                                regionDesc.label.toLowerCase() !==
                                  "top district" &&
                                regionDesc.label.toLowerCase() !==
                                  "top machine" &&
                                regionDesc.label.toLowerCase() !==
                                  "accepted scans" &&
                                regionDesc.label.toLowerCase() !==
                                  "accepted percent"
                          ) {
                            return (
                              <>
                                {
                                  <p
                                    key={index}
                                    style={{
                                      color: color.textWhiteColor,
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundColor:
                                          color.textDescBackgroundRegionDiv,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        // color: color.tableRowHeading,
                                      }}
                                    >
                                      {regionDesc.label === "Accepted Percent"
                                        ? `${regionDesc.value.toFixed(1)}%`
                                        : (typeof regionDesc.value).toLocaleLowerCase() ===
                                          "number"
                                        ? formatInIndianRupees(regionDesc.value)
                                        : regionDesc.value}
                                    </span>
                                    <div>{regionDesc.label}</div>
                                  </p>
                                }
                              </>
                            );
                          } else if (
                            regionDesc.label.toLowerCase() === "top district" ||
                            regionDesc.label.toLowerCase() === "top machine"
                          ) {
                            return (
                              <div className="top-div d-flex flex-row align-item-center  w-100 ms-1">
                                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                  <div
                                    style={{
                                      backgroundColor:
                                        color.iconBackgroundWhite,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height: pageStyling.iconBackgroundHeight,
                                      width: pageStyling.iconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={
                                        regionDesc.label.toLowerCase() ===
                                        "top district"
                                          ? TotalDistrictImg
                                          : TotalMachinesImg
                                      }
                                      alt="All Assesment img"
                                      className=""
                                      style={{
                                        width: pageStyling.IconWidth,
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* circular progress bar div desc */}
                                <div
                                  className="top-div-circular-progress-desc flex-grow-1"
                                  style={{
                                    color: color.textWhiteColor,
                                  }}
                                >
                                  <p
                                    className="p-0 m-0"
                                    style={{
                                      color: color.textWhiteColor,
                                    }}
                                  >
                                    {regionDesc.value}
                                  </p>
                                  {regionDesc.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        alignContent: "center",
                        margin: "auto",
                      }}
                      className={`img_hiding ${divWidth < 920 ? "d-none" : ""}`}
                    >
                      {CarouselItem.data.find(
                        (item) => item.label === "Accepted Percent"
                      ) && false ? (
                        <div
                          style={{
                            width: "auto",
                            height: "200px",
                            margin: "10px",
                          }}
                        >
                          <CircularProgressbar
                            value={
                              CarouselItem.data.find(
                                (item) => item.label === "Accepted Percent"
                              ).value
                            }
                            text={`${
                              CarouselItem.data.find(
                                (item) => item.label === "Accepted Percent"
                              ).value
                            }%`}
                            // background
                            backgroundPadding={5}
                            // styles={buildStyles({
                            //   backgroundColor: color.secondary,
                            //   textColor: color.green,
                            //   pathColor: color.green,
                            //   trailColor: "transparent",
                            //   trailColor: "transparent",
                            // })}

                            styles={{
                              root: {
                                // Customize background here
                                // backgroundColor: color.secondary,
                                // backgroundSize: "cover",
                                // backgroundPosition: "center",
                                // Other styles
                              },
                              trail: {
                                strokeLinecap: "butt",
                                transform: "rotate(-126deg)",
                                transformOrigin: "center center",
                              },
                              path: {
                                // strokeLinecap: "butt",
                                // transform: "rotate(-126deg)",
                                transformOrigin: "center center",
                                stroke: color.green,
                              },
                              text: {
                                fill: color.green,
                                fontWeight: 800,
                                fontSize: "15px",
                                dominantBaseline: "middle", // Align text vertically center
                                textAnchor: "middle", // Align text horizontally center
                              },
                            }}
                          />
                          {/* <CircularProgressbar value={45} text={`${45}%`} /> */}
                        </div>
                      ) : (
                        <img
                          //   className="ms-auto"
                          src={getImageSrc(CarouselItem.name)}
                          alt="AP Image"
                          style={{
                            width: "auto",
                            height: "200px",
                            margin: "10px",
                          }}
                        />
                      )}
                    </div>

                    {/* <div
                      style={{
                        // margin: "15px",
                        // marginTop: "-5px",
                        textAlign: "center",
                        alignItems: "center",
                        width: "240px",
                        margin: "auto",
                        // height: "450px",
                      }}
                    >
                      <SVGMap
                        style={{
                          width: "auto",
                          height: "450px",
                        }}
                        map={India}
                        // onLocationClick={onLocationClick}
                        // onLocationMouseOver={onLocationMouseOver}
                        // onLocationMouseOut={onLocationMouseOut}
                        className="svg-map"
                        locationClassName={(location) => {
                          if (
                            location.name === "Andaman and Nicobar Islands"
                            // location.name === "Jammu and Kashmir"
                          ) {
                            return "hidden";
                          }
                          // const selected_region = data.find(
                          //   (item) => location.name === CarouselItem.state
                          // );
                          const highlight_region =
                            CarouselItem?.state.toLowerCase() ===
                            location.name.toLowerCase()
                              ? true
                              : "";
                          // console.log(location);
                          return getColorClass(highlight_region);
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default RegionCrousel;
