import React from 'react'

const EachVisitBill = () => {
  return (
    <div>
      Not added yet
    </div>
  )
}

export default EachVisitBill
