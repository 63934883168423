import React, { useContext } from "react";
import { DisplayAddress } from "./ShipmentGeneralDetails";
import { DisplayingShipmentProductDetailsTable } from "./DisplayingShipmentProductDetails";
import ContextState from "../../contextApi/ContextState";
import { color } from "../../modals/Calllog";
import { Tooltip } from "@mui/material";
import { Tag } from "primereact/tag";
import { bytesToSize } from "../../RaiseTicketForm/FilesUpload";
import { FaFileCsv } from "react-icons/fa";

const ShipmentPreview = (props) => {
  const {
    genFields,
    isSmallScreen,
    handleCheckBox,
    isCheckBox,
    isMediumScreen,
    islageScreen,
  } = props;
  const { formatInIndianRupees } = useContext(ContextState);
  return (
    <div>
      {true && (
        <div>
          {/* lot details */}
          {genFields?.generalDetails?.trackId !== "" ? (
            <div className="mt-2 mb-4">
              <div className="card-Div p-3">
                <div
                  className="bar-chart-heading mb-2"
                  style={{
                    color: color.taxtHeading,
                    lineHeight: "1.2",
                    fontSize: "1rem",
                  }}
                >
                  General Details
                </div>
                <DisplayAddress
                  isSmallScreen={isSmallScreen}
                  isMediumScreen={isMediumScreen}
                  islageScreen={islageScreen}
                  preview={true}
                  genFields={genFields}
                />
              </div>
            </div>
          ) : null}

          {/* displaying of sender Address */}
          {genFields.generalDetails.sender.name !== "" ? (
            <div className="mt-4 mb-0 w-100">
              <div className="card-Div p-3 ">
                <div
                  className="bar-chart-heading  "
                  style={{
                    color: color.taxtHeading,
                    lineHeight: "1.2",
                    fontSize: "1rem",
                  }}
                >
                  Sender Details
                </div>
                <div className="px-1 my-1">
                  <DisplayAddress
                    isSmallScreen={isSmallScreen}
                    genFields={genFields}
                    isMediumScreen={isMediumScreen}
                    islageScreen={islageScreen}
                    addressType={"sender"}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* displaying of receviverAddress */}
          {genFields.generalDetails.receiver.name !== "" ? (
            <div className="my-4 mb-0 w-100">
              <div className="card-Div p-3 ">
                <div
                  className="bar-chart-heading  "
                  style={{
                    color: color.taxtHeading,
                    lineHeight: "1.2",
                    fontSize: "1rem",
                  }}
                >
                  Receiver Details
                </div>
                <div className="px-1 my-1">
                  <DisplayAddress
                    isSmallScreen={isSmallScreen}
                    genFields={genFields}
                    isMediumScreen={isMediumScreen}
                    islageScreen={islageScreen}
                    addressType={"receiver"}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* product details */}
          {genFields?.productDetails?.length > 0 ? (
            <DisplayingShipmentProductDetailsTable
              genFields={genFields}
              isReview={true}
              isSerialNumber={true}
              tableHeader={"Product Details"}
            />
          ) : null}

          {genFields.attachments.length > 0 && (
            <div className="mt-2 pt-3">
              <div className="card-Div p-3">
                <div
                  className="bar-chart-heading mb-2"
                  style={{
                    color: color.taxtHeading,
                    lineHeight: "1.2",
                    fontSize: "1rem",
                  }}
                >
                  Attachments
                </div>
                <div
                  className="d-flex flex-wrap gap-3 p-3 mt-3"
                  style={{
                    padding: "18px 20px",
                    margin: isSmallScreen ? "20px 4px 4px" : "4px",
                  }}
                >
                  {/* attachments */}
                  {genFields.attachments.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        width: "125px",
                        borderRadius: "5px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "125px",
                          height: "125px",
                          overflow: "hidden",
                          borderRadius: "5px",
                        }}
                      >
                        {file.name.endsWith(".pdf") ? (
                          <embed
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : file.name.endsWith(".csv") ||
                          file.name.endsWith(".xlsx") ? (
                          <FaFileCsv
                            style={{ height: "125px", width: "125px" }}
                          />
                        ) : (
                          <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>

                      <Tooltip title={file.name} arrow>
                        <div
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            paddingTop: "8px",
                          }}
                        >
                          {file.name}
                        </div>
                      </Tooltip>

                      <Tag
                        severity="warning"
                        className="px-3 py-2 mt-2"
                        style={{ maxWidth: "125px", textAlign: "center" }}
                        value={bytesToSize(file.size)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div class="form-check mt-3 ms-1 ">
        <input
          class="form-check-input"
          type="checkbox"
          value={isCheckBox}
          onChange={(e) => {
            handleCheckBox(e.target.checked);
          }}
          id="flexCheckDefault"
        />
        <label class="form-check-label" for="flexCheckDefault">
          I confirm that the entered details are correct.
        </label>
      </div>
    </div>
  );
};

export default ShipmentPreview;
