import React, { useContext, useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Modal,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import "../../assets/styles/VisitDetails.css";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import ModalError from "../error/ModalError";
import Autocomplete from "@mui/material/Autocomplete";
import FilesUpload from "../RaiseTicketForm/FilesUpload";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContextState from "../contextApi/ContextState";
import close from "../../assets/images/close.svg";
import ConfirmDeletionModal from "./ConfirmDeletionModal";
import { BeatLoader } from "react-spinners";
import { RiAddBoxFill } from "react-icons/ri";
import {
  CardPagesSkeleton,
  get_parts_replaced_list,
  get_support_persons_list,
  handleClick,
  isValidEmail,
} from "../Exporters/ExportFunctions";
import { useNavigate } from "react-router-dom";
import Placeholder from "../error/Placeholder";

// converting backend date format to YYYY-MM-DD
export function formatDateString(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  // getMonth() returns month from 0-11. Add 1 to get the correct month.
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function VisitDetails({
  reloadVisitsPage,
  data,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) {
  const { ticketId, status } = data;
  const {
    fetchApi,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    formatDate,
    modalControllerRef,
  } = useContext(ContextState);

  const navigate = useNavigate();

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(true);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  const [reloadData, setReloadData] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  // visit details intial state
  const [visitDetailsformData, setVisitDetailsFormData] = useState({
    location: "",
    support_person: [],
    total_expenses: "",
    parts_replaced: [],
    distance: null,
    remarks: "",
    product_serial_data: [],
    prev_id: "",
    new_id: [],
    start_date: null,
    end_date: null,
    attachments: [],
    isAddSerial: false,
  });

  // Temp state to hold the current entry
  const [tempSerialData, setTempSerialData] = useState({
    hardware: "",
    old_serial: "",
    new_serial: "",
  });

  // Handle input change for temp state
  const handleTempInputChange = (e) => {
    const { name, value } = e.target;
    setTempSerialData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Function to add the temp data to main product_serial_data array
  const addSerialData = () => {
    setVisitDetailsFormData((prev) => ({
      ...prev,
      product_serial_data: [
        ...(prev?.product_serial_data || []),
        tempSerialData,
      ],
    }));

    // Clear tempSerialData after adding
    setTempSerialData({
      hardware: "",
      old_serial: "",
      new_serial: "",
    });
    setVisitDetailsFormData((prev) => ({
      ...prev,
      isAddSerial: false,
    }));
  };

  // To delete the entry
  const handleDeleteElement = (index) => {
    setVisitDetailsFormData((prev) => ({
      ...prev, // Keep other state properties
      product_serial_data: prev.product_serial_data.filter(
        (_, i) => i !== index
      ),
    }));
  };

  // maintaining state variable  for previous visit attachments
  const [prevVisitAttachments, setPrevVisitAttachments] = useState([]);

  // maintaining a state variable for visit_id for individual cards
  const [visitId, setVisitId] = useState(null);

  // console.log("attachments data inside modal we got:", prevVisitAttachments);

  // getting filters data for modal from backend
  const [backendFilterData, setBackendFilterData] = useState({
    support_list: [],
    hardware_list: {},
    machines_list: [],
    support_name_list: [],
    machine_location_list: [],
    hardware_relaced_list: [],
  });

  const [isEditVisitDetails, setIsEditVisitDetails] = useState(true);

  const isEditVisits = () => {
    setIsEditVisitDetails(!isEditVisitDetails);
  };

  // maintaining a state variable to store the support visit data
  const [getBackendVisitDetails, setGetBackendVisitDetails] = useState([]);

  // modal state
  const [showVisitDetailsModal, setShowVisitDetailsModal] = useState(false);

  // button loader
  const [loadingButton, setLoadingButton] = useState(false);

  // buttons state
  const [addVisitdetailsButton, setAddVisitdetailsButton] = useState(false);

  // function required to get iuds of some elements in modal
  const get_machine_code = (check_location) => {
    for (
      let index = 0;
      index < backendFilterData.machines_list?.length;
      index++
    ) {
      const element = backendFilterData.machines_list[index];
      const location = element["location"];
      if (location === check_location) {
        return element["code"];
      }
    }
  };

  // to get ids for hardware while adding serial keys
  const transformProductSerialData = () => {
    const hardwareMapping = backendFilterData?.hardware_list || {}; // Mapping of hardware name to ID

    const transformedData = visitDetailsformData?.product_serial_data?.map(
      (item) => {
        const hardwareId = Object.keys(hardwareMapping).find(
          (key) => hardwareMapping[key] === item.hardware
        );

        return {
          id: hardwareId || null, // Use null if not found
          old: item.old_serial,
          new: item.new_serial,
        };
      }
    );

    return transformedData;
  };

  ///////////////// Add new visit //////////////
  const [addNewVisit, setAddNewVisit] = useState(false);

  // this is a state variable to take input from form
  const [scheduleDetail, setScheduleDetail] = useState({
    machine: "",
    assignee: [],
    date: "",
    description: "",
    person: "",
    contact: "",
    address: "",
    emailSwitch: false,
    email: "",
  });

  //// to show the error if not filled /////
  const [fieldError, setFieldError] = useState({
    machineError: false,
    dateError: false,
    assigneeError: false,
    descriptionError: false,
    personError: false,
    contactError: false,
    addressError: false,
    emailError: false,
  });

  const handleVisitDetailsAddButtonClick = async (selectedData) => {
    try {
      const payload = {
        ticket_id: ticketId,
      };
      const jsonData = await fetchApi(
        "get_visit_filters",
        "POST",
        payload,
        true
      );
      if (jsonData?.statusCode === 200 && jsonData && jsonData?.data) {
        // console.log("data fetch successfully");
        setBackendFilterData((prevState) => ({
          ...prevState,
          support_list: jsonData?.data?.support_list,
          hardware_list: jsonData?.data?.hardware_list,
          machines_list: jsonData?.data?.machines_list,
          support_name_list: jsonData?.data?.support_list.map(
            (element, index) => {
              return element.name;
            }
          ),
          machine_location_list: jsonData?.data?.machines_list.map(
            (element, index) => {
              return element.location;
            }
          ),
          hardware_relaced_list: Object.values(jsonData?.data?.hardware_list),
        }));
      } else {
        openSnackbar(
          "Some error occured, please try again after some time!",
          "error"
        );

        // console.log("data not fetched from backend, some errror occured!");
      }
      // console.log("data went inside the modal:", selectedData);

      if (selectedData) {
        setIsEditVisitDetails(true);
        setVisitDetailsFormData({
          location: selectedData.machine_code,
          support_person: selectedData?.support_person?.map(
            (eachObject) => eachObject.name
          ),
          total_expenses: selectedData?.total_expense,
          parts_replaced: selectedData?.parts_replaced?.map(
            (eachPart) => eachPart.hardware
          ),
          remarks: selectedData.remarks,
          start_date: dayjs(formatDateString(selectedData?.start_date)),
          end_date: dayjs(formatDateString(selectedData?.end_date)),
          attachments: [],
          distance: selectedData?.distance,
          product_serial_data: selectedData?.product_details,
        });
        setPrevVisitAttachments(selectedData?.attachments);
        setVisitId(selectedData?.id);

        setAddVisitdetailsButton(false);
      } else {
        setIsEditVisitDetails(false);
        // For a new visit detail, keep start and end date null initially
        setVisitDetailsFormData({
          location: "",
          support_person: [],
          total_expenses: "",
          parts_replaced: [],
          remarks: "",
          start_date: null,
          end_date: null,
          attachments: [],
          distance: null,
        });
        setAddVisitdetailsButton(true);
      }
      setShowVisitDetailsModal(true);
    } catch (error) {
      openSnackbar(
        "Some error occured, please try again after some time!",
        "error"
      );
      // console.log("some error occured!");
    }
  };

  // handle modal
  const handleCloseVisitDetailsModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setShowVisitDetailsModal(false);
  };

  // send to visit page
  const openEachVisit = (visitId) => {
    navigate(`/FCI/allvisits/${visitId}`);
  };

  const handleSaveChanges = async () => {
    if (!visitDetailsformData.start_date || !visitDetailsformData.end_date) {
      openSnackbar("Please select both start date and end date.");
      return;
    }

    // Check if end date is not before start date
    if (
      dayjs(visitDetailsformData.end_date).isBefore(
        visitDetailsformData.start_date
      )
    ) {
      openSnackbar("End date cannot be before start date.");
      return;
    }

    try {
      // ************from here we are going to hit api's************

      const urlToFileArrayBuffer = async (fileUrl) => {
        try {
          // Fetch the file content as a blob
          const response = await fetch(fileUrl);
          const blob = await response.blob();

          // Read the blob as an array buffer
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.onerror = () => {
              reject(new Error("Failed to read file"));
            };
            reader.readAsArrayBuffer(blob);
          });
        } catch (error) {
          console.error("Error fetching file:", error);
          return null;
        }
      };

      const attachmentsData = await Promise.all(
        visitDetailsformData?.attachments.map(async (data) => {
          // console.log(data);
          // Fetch array buffer from URL
          const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
          // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
          const uint8Array = new Uint8Array(arrayBuffer);

          // console.log(arrayBuffer);
          // Convert array buffer to Blob
          const blob = new Blob([arrayBuffer]);
          // console.log("blob-", blob);
          // Return object with file name and FormData
          return {
            file_name: data.name,
            file_data: Array.from(uint8Array),
          };
        })
      );

      const payload = {
        ticket_id: ticketId,
        start_date: visitDetailsformData.start_date,
        end_date: visitDetailsformData.end_date,
        total_expense: visitDetailsformData.total_expenses
          ? visitDetailsformData.total_expenses
          : null,
        distance: visitDetailsformData.distance
          ? visitDetailsformData.distance
          : null,
        remarks: visitDetailsformData.remarks,
        machine_code: get_machine_code(visitDetailsformData.location),
        support_person: get_support_persons_list(
          visitDetailsformData.support_person,
          backendFilterData?.support_list
        ),
        parts_replaced: get_parts_replaced_list(
          visitDetailsformData.parts_replaced,
          backendFilterData?.hardware_list
        ),
        attachment_data: attachmentsData,
        product_serial_data:
          visitDetailsformData?.product_serial_data?.length > 0
            ? transformProductSerialData()
            : [],
      };
      // console.log("final payload for addding new visit details:", payload);

      if (addVisitdetailsButton) {
        // console.log("we will hit the add visit api");
        setLoadingButton(true);
        const response = await fetchApi(
          "add_support_visit",
          "POST",
          payload,
          true,
          1
        );
        // let response = 200;
        if (response.statusCode === 200) {
          // Reload the React component
          // console.log("ticket edited succesfully");
          openSnackbar("visit details added succesfully", "success");
          handleCloseVisitDetailsModal();
          handleReloadData();
        } else {
          // console.error(
          //   "Error editing ticket information:",
          //   response.statusCode
          // );
          openSnackbar(
            "some error occured while saving visit details",
            "error"
          );
        }
      } else {
        // console.log("we will hit the edit visit api");
        const payloadEdit = {
          ticket_id: ticketId,
          visit_id: visitId,
          start_date: visitDetailsformData.start_date,
          end_date: visitDetailsformData.end_date,
          total_expense: visitDetailsformData.total_expenses
            ? visitDetailsformData.total_expenses
            : "",
          distance: visitDetailsformData.distance
            ? visitDetailsformData.distance
            : null,
          remarks: visitDetailsformData.remarks,
          machine_code: get_machine_code(visitDetailsformData.location),
          support_person: get_support_persons_list(
            visitDetailsformData.support_person,
            backendFilterData?.support_list
          ),
          parts_replaced: get_parts_replaced_list(
            visitDetailsformData.parts_replaced,
            backendFilterData?.hardware_list
          ),
          attachment_data: attachmentsData,
        };
        // console.log("edit payload", payloadEdit);
        setLoadingButton(true);
        const response = await fetchApi(
          "edit_support_visit",
          "POST",
          payloadEdit,
          true,
          1
        );
        if (response.statusCode === 200) {
          // console.log("ticket edited succesfully");
          openSnackbar("visit details edited succesfully", "success");
          handleCloseVisitDetailsModal();
          handleReloadData();
        } else {
          console.error(
            "Some error occured while editing visit details",
            response.statusCode
          );
          openSnackbar("some error occured while saving details", "error");
        }
      }
      setLoadingButton(false);
    } catch (error) {
      console.log("some error occured!");
      openSnackbar("some error occured while saving details", "error");
    }
    // handleCloseVisitDetailsModal();
  };

  // handling onChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVisitDetailsFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle Date Change
  const handleDateChange = (name, value) => {
    // Convert dayjs object to  format "MM/DD/YYYY"
    const formattedDate = dayjs(value).format("YYYY-MM-DD").toString();
    // console.log(formattedDate, "formattedDate");

    setVisitDetailsFormData((prevState) => ({
      ...prevState,
      [name]: formattedDate,
    }));
  };
  const handleNewDateChange = (name, value) => {
    // Convert dayjs object to  format "MM/DD/YYYY"
    const formattedDate = dayjs(value).format("YYYY-MM-DD").toString();
    // console.log(formattedDate, "formattedDate");

    setScheduleDetail((prevState) => ({
      ...prevState,
      [name]: formattedDate,
    }));

    if (value !== "" || value !== null) {
      setFieldError((prev) => ({
        ...prev,
        dateError: false,
      }));
    }
  };

  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
  };

  let is_ticket_data_Mounted;
  const visitTicket = async (is_ticket_data_Mounted) => {
    try {
      const payload = {
        ticket_id: ticketId,
      };

      if (refreshBtn) {
        setRefreshLoading(true);
      }

      const jsonData = await fetchApi(
        "get_support_visits",
        "POST",
        payload,
        true,
        1
      );
      if (jsonData?.statusCode === 200 && jsonData && jsonData?.data) {
        if (is_ticket_data_Mounted) {
          setGetBackendVisitDetails(jsonData?.data?.visit_data);
        }
      } else {
        // openSnackbar(jsonData.data, "error");
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
        // showLoadingModal(false);
      }
      showLoadingModal(false);
    } catch (error) {
      if (modalControllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setRefreshLoading(false);
      setRefreshBtn(false);
    }
  };

  useEffect(() => {
    is_ticket_data_Mounted = true;
    showLoadingModal(true);
    visitTicket(is_ticket_data_Mounted);
    return () => {
      is_ticket_data_Mounted = false;
      // On unmount, abort the previous request for fetchApi api
      // if (controllerRef.current) {
      //   controllerRef.current.abort();
      // }
    };
  }, [reloadData, reloadVisitsPage]);

  const addVisitFiltersData = async () => {
    setAddNewVisit(!addNewVisit);
    setScheduleDetail((prev) => ({
      ...prev,
      machine: "",
      date: "",
      assignee: [],
      description: "",
      person: "",
      contact: "",
      address: "",
      emailSwitch: false,
      email: "",
    }));
    setFieldError((prev) => ({
      ...prev,
      machineError: false,
      dateError: false,
      assigneeError: false,
      descriptionError: false,
      personError: false,
      contactError: false,
      addressError: false,
      emailError: false,
    }));

    if (backendFilterData?.machines_list?.length === 0) {
      try {
        const payload = {
          ticket_id: ticketId,
        };

        const jsonData = await fetchApi(
          "get_visit_filters",
          "POST",
          payload,
          true
        );

        if (jsonData?.statusCode === 200 && jsonData && jsonData?.data) {
          setBackendFilterData((prevState) => ({
            ...prevState,
            support_list: jsonData?.data?.support_list,
            hardware_list: jsonData?.data?.hardware_list,
            machines_list: jsonData?.data?.machines_list,
            support_name_list: jsonData?.data?.support_list.map(
              (element, index) => {
                return element.name;
              }
            ),
            machine_location_list: jsonData?.data?.machines_list.map(
              (element, index) => {
                return element.location;
              }
            ),
            hardware_relaced_list: Object.values(jsonData?.data?.hardware_list),
          }));
          setScheduleDetail((prev) => ({
            ...prev,
            email: jsonData?.data?.person_email,
          }));
        } else {
          openSnackbar("Some error occured while fetching filters!", "error");
        }
      } catch {
        openSnackbar("Some error occured while fetching filters!", "error");
      }
    }
  };

  const addFormData = async () => {
    try {
      setLoadingButton(true);
      if (
        scheduleDetail.machine === "" ||
        scheduleDetail.assignee?.length === 0 ||
        scheduleDetail?.date === "" ||
        scheduleDetail?.person === "" ||
        scheduleDetail?.contact === "" ||
        scheduleDetail?.address === "" ||
        scheduleDetail.description.trim().split(/\s+/).length < 5 ||
        (scheduleDetail?.emailSwitch &&
          (scheduleDetail?.email === "" ||
            !isValidEmail(scheduleDetail?.email)))
      ) {
        setFieldError((prev) => ({
          ...prev,
          machineError: scheduleDetail?.machine === "",
          assigneeError: scheduleDetail?.assignee?.length === 0,
          dateError: scheduleDetail?.date === "",
          personError: scheduleDetail?.person === "",
          contactError: scheduleDetail?.contact === "",
          addressError: scheduleDetail?.address === "",
          descriptionError:
            scheduleDetail.description.trim().split(/\s+/).length < 5,
          emailError: !isValidEmail(scheduleDetail?.email),
        }));
        return;
      }
      let payload = {
        ticket_id: ticketId,
        machine_code: scheduleDetail?.machine,
        support_person: get_support_persons_list(
          scheduleDetail?.assignee,
          backendFilterData?.support_list
        ),
        scheduled_date: scheduleDetail?.date,
        description: scheduleDetail?.description,
        status: "Scheduled",
        contact_person: scheduleDetail?.person,
        contact_number: scheduleDetail?.contact,
        location_address: scheduleDetail?.address,
      };
      if (scheduleDetail?.emailSwitch && isValidEmail(scheduleDetail?.email)) {
        payload["email_person"] = scheduleDetail?.email;
      }

      const response = await fetchApi("schedule_visit", "POST", payload, true);
      if (response?.statusCode === 200 && response?.data) {
        openSnackbar(response?.data?.message, "success");
        setAddNewVisit(!addNewVisit);
        handleReloadData();
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while saving details!", "error");
    } finally {
      setLoadingButton(false);
    }
  };

  // delection modal state
  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    ticketId: "",
    attachId: "",
  });

  // closing of modal
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      {(lodingModalVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <>
          <div className="m-3">
            <CardPagesSkeleton
              len={1}
              cardHeight={40}
              componentClass={"issue-type-row-div"}
            />
            <CardPagesSkeleton len={12} cardHeight={150} />
          </div>
        </>
      )) ||
        (!showModalError.status && (
          <div className="main_visit_container my-4 mx-4">
            <div className="mb-4">
              <button
                type="button"
                // disabled={ticketStatus === "resolved"}
                className="btn btn-outline-primary markResolved_button"
                // onClick={() => {
                //   handleVisitDetailsAddButtonClick();
                // }}
                onClick={() => addVisitFiltersData()}
              >
                Add Visit
              </button>
            </div>

            {addNewVisit && (
              <>
                <div className="data-pull-box mt-4">
                  <header
                    style={{
                      marginBottom: "16px",
                      marginTop: "5px",
                      fontWeight: 600,
                      color: "rgba(76, 78, 100, 0.9)",
                    }}
                  >
                    Schedule a Visit
                  </header>
                  <div className="row mb-4" style={{ rowGap: "25px" }}>
                    {/* Machine selection */}
                    <div className="col-lg-4">
                      <FormControl
                        helperText="Please select machine"
                        error={fieldError.machineError}
                        sx={{ width: "100%" }}
                        color="primary"
                      >
                        <Autocomplete
                          id="machine-select"
                          options={backendFilterData?.machines_list || []}
                          getOptionLabel={(option) =>
                            `${option.code} (${option.location})`
                          }
                          filterSelectedOptions
                          value={
                            backendFilterData?.machines_list.find(
                              (machine) =>
                                machine.code === scheduleDetail.machine
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            if (newValue !== "") {
                              setFieldError((prev) => ({
                                ...prev,
                                machineError: false,
                              }));
                            }
                            if (newValue) {
                              setScheduleDetail((prev) => ({
                                ...prev,
                                machine: newValue.code,
                              }));
                            } else {
                              setScheduleDetail((prev) => ({
                                ...prev,
                                machine: "",
                              }));
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Machine"
                              placeholder="Select Machine"
                              variant="outlined"
                              error={fieldError?.machineError}
                              helperText={
                                fieldError.machineError &&
                                "Please select a machine"
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    {/* Support person selection */}
                    <div className="col-lg-5">
                      <Autocomplete
                        multiple
                        id="assignee-select"
                        options={backendFilterData?.support_name_list || []}
                        filterSelectedOptions
                        value={scheduleDetail?.assignee}
                        onChange={(event, values) => {
                          const finalValues = values.map((item) => item);
                          setScheduleDetail((prev) => ({
                            ...prev,
                            assignee: finalValues,
                          }));
                          if (finalValues.length > 0) {
                            setFieldError((prev) => ({
                              ...prev,
                              assigneeError: false,
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Support Persons"
                            // placeholder="Select Support Persons"
                            error={fieldError.assigneeError}
                            helperText={
                              fieldError.assigneeError &&
                              "Please select at least one person"
                            }
                            variant="outlined"
                          />
                        )}
                      />
                    </div>

                    {/* Date selection */}
                    <div className="col-lg-3 mb-2 mb-lg-0 position-relative">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{
                            overflow: "unset",
                            padding: "0px",
                            width: "100%",
                          }}
                        >
                          <DatePicker
                            label="Select Date"
                            value={scheduleDetail?.date || null}
                            onChange={(date) =>
                              handleNewDateChange("date", date)
                            }
                            slotProps={{
                              textField: {
                                error: fieldError.dateError,
                                helperText: fieldError.dateError
                                  ? "Please select a date"
                                  : "",
                              },
                            }}
                            sx={{
                              display: "flex",
                              flexGrow: 1,
                              padding: "0px",
                              margin: "0px",
                              "& .MuiInputBase-input": {
                                color: "#4c4e64de",
                              },
                              "& .MuiInputLabel-root": {
                                color: fieldError.dateError
                                  ? "#df0538"
                                  : "#272323b3",
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>

                  {/* description  */}
                  <div className="row mb-4">
                    <div className="">
                      <TextField
                        name="Description"
                        fullWidth
                        label="Description"
                        id="outlined-textarea"
                        placeholder="For Ex: Replacement of a faulty part detected in the machine."
                        // onChange={(event) =>
                        //   setScheduleDetail((prev) => ({
                        //     ...prev,
                        //     description: event.target.value,
                        //   }))
                        // }
                        onChange={(event) => {
                          const value = event.target.value;
                          setScheduleDetail((prev) => ({
                            ...prev,
                            description: value,
                          }));

                          // Update error state based on word count
                          setFieldError((prev) => ({
                            ...prev,
                            descriptionError:
                              value.trim().split(/\s+/).length < 5,
                          }));
                        }}
                        rows={4}
                        multiline
                        value={scheduleDetail?.description}
                        error={fieldError?.descriptionError}
                        helperText={
                          fieldError?.descriptionError &&
                          "Please enter at leat 5 words"
                        }
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                          },
                        }}
                      />
                    </div>
                  </div>

                  <header
                    style={{
                      fontFamily: "Open-Sans-Light",
                      fontSize: "16px",
                      color: "rgba(76, 78, 100, 0.7)",
                      margin: "10px 5px",
                      fontWeight: 600,
                    }}
                  >
                    On-Site Contact and Location Details
                  </header>
                  <div className="row mb-4" style={{ rowGap: "25px" }}>
                    <div className="col-lg-3">
                      <TextField
                        fullWidth
                        value={scheduleDetail?.person}
                        id="outlined-basic"
                        label="Contact Person"
                        placeholder="For Ex: John Doe"
                        variant="outlined"
                        onChange={(event) => {
                          setScheduleDetail((prev) => {
                            return {
                              ...prev,
                              person: event.target.value,
                            };
                          });
                          setFieldError((prev) => ({
                            ...prev,
                            personError: event.target.value === "",
                          }));
                        }}
                        error={fieldError?.personError}
                        helperText={
                          fieldError?.personError &&
                          "Please enter a person name"
                        }
                      />
                    </div>
                    <div className="col-lg-3">
                      <TextField
                        fullWidth
                        type="number"
                        value={scheduleDetail?.contact}
                        id="outlined-basic"
                        label="Contact Number"
                        placeholder="For Ex: 9234 567 890"
                        variant="outlined"
                        onChange={(event) => {
                          setScheduleDetail((prev) => {
                            return {
                              ...prev,
                              contact: event.target.value,
                            };
                          });
                          // Update error state based on word count
                          setFieldError((prev) => ({
                            ...prev,
                            contactError: event.target.value === "",
                          }));
                        }}
                        error={fieldError?.contactError}
                        helperText={
                          fieldError?.contactError &&
                          "Please enter person contact number"
                        }
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextField
                        fullWidth
                        value={scheduleDetail?.address}
                        id="outlined-basic"
                        label="Location Address"
                        placeholder="For Ex: Fci Regional office Punjab, Bay no. 34-38, Sector 31A, Chandigarh Pin 160030"
                        variant="outlined"
                        onChange={(event) => {
                          setScheduleDetail((prev) => {
                            return {
                              ...prev,
                              address: event.target.value,
                            };
                          });
                          // Update error state based on word count
                          setFieldError((prev) => ({
                            ...prev,
                            addressError: event.target.value === "",
                          }));
                        }}
                        error={fieldError?.addressError}
                        helperText={
                          fieldError?.addressError &&
                          "Please enter the location address"
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex gap-0" style={{ marginTop: "10px" }}>
                    <Switch
                      sx={{
                        "&.MuiSwitch-root": {
                          marginLeft: "-10px ",
                        },
                      }}
                      checked={scheduleDetail.emailSwitch}
                      onChange={(event) => {
                        // console.log("event:", event);
                        if (scheduleDetail?.emailSwitch) {
                          setScheduleDetail((prev) => ({
                            ...prev,
                            email: "",
                          }));
                          setFieldError((prev) => ({
                            ...prev,
                            emailError: false,
                          }));
                        }
                        setScheduleDetail((prev) => {
                          return {
                            ...prev,
                            emailSwitch: !prev.emailSwitch,
                          };
                        });
                      }}
                    />
                    <p
                      className="my-auto"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontSize: isSmallScreen ? "13px" : "15px",
                          fontWeight: 600,
                          color: "rgba(76, 78, 100, 0.7)",
                          margin: "auto",
                          paddingRight: "10px",
                        }}
                      >
                        Send Notification (Optional)
                      </p>
                      <Tooltip
                        title="Notify the Issue Reporter about the Scheduled visit."
                        arrow
                      >
                        <i
                          className="bi bi-info-circle icon-color px-0"
                          style={{
                            fontSize: isSmallScreen ? "13px" : "15px",
                            // cursor: "pointer",
                            fontFamily: "Open-Sans-Light",
                            fontWeight: 600,
                            color: "rgba(76, 78, 100, 0.7)",
                          }}
                        ></i>
                      </Tooltip>
                    </p>
                  </div>

                  <div className="row">
                    {scheduleDetail?.emailSwitch && (
                      <div className="col-lg-4 my-2">
                        <TextField
                          fullWidth
                          value={scheduleDetail?.email}
                          id="outlined-basic"
                          label="Email"
                          placeholder="Enter recipient's email"
                          variant="outlined"
                          onChange={(event) => {
                            setScheduleDetail((prev) => {
                              return {
                                ...prev,
                                email: event.target.value,
                              };
                            });
                            setFieldError((prev) => ({
                              ...prev,
                              emailError: !isValidEmail(event.target.value),
                            }));
                          }}
                          error={fieldError?.emailError}
                          helperText={
                            fieldError?.emailError &&
                            "Please enter a valid email"
                          }
                        />
                      </div>
                    )}
                  </div>

                  {/* Submit button */}
                  <div className="mt-3 d-flex justify-content-end">
                    <LoadingButton
                      size="large"
                      onClick={() => addFormData()}
                      endIcon={<SendIcon />}
                      loading={loadingButton}
                      loadingPosition="end"
                      variant="contained"
                    >
                      <span>Submit</span>
                    </LoadingButton>
                  </div>
                </div>
              </>
            )}

            {!addNewVisit && (
              <div className="mt-3">
                {(getBackendVisitDetails?.length > 0 && (
                  <>
                    <h6 className="mx-2 mt-4">Visits for this Ticket</h6>
                    <div className="d-flex flex-row flex-wrap w-100 ">
                      {getBackendVisitDetails?.map((data) => (
                        <a
                          href={`${window.location.origin}/FCI/allvisits/${data?.id}`}
                          className="prev_visits_container"
                          onClick={(e) => {
                            const key = `/FCI/allvisits/${data?.id}`;
                            const targetPath = `${window.location.origin}/FCI/allvisits/${data?.id}`;
                            handleClick(e, key, targetPath, navigate);
                          }}
                          style={{ color: "black" }}
                          // onClick={() => openEachVisit(data?.id)}
                        >
                          <div className="prev_visits_body">
                            {/* visit_id  */}
                            <div className="mb-2 d-flex justify-content-between">
                              <p className="mb-0">
                                <span className="me-2">
                                  <i className="bi bi-ticket-fill"></i>
                                </span>
                                {data.id}
                              </p>
                              <p className="d-flex gap-1 align-items-center me-1 m-0 mb-0">
                                {/* {data?.status === "Scheduled" && (
                                    <>
                                      <i
                                        className="bi bi-calendar-date"
                                        style={{ color: "orange" }}
                                      ></i>
                                      <span
                                        className="ms-2"
                                        style={{ color: "orange" }}
                                      >
                                        {data?.status}
                                      </span>
                                    </>
                                  )}
                                  {data?.status === "Ongoing" && (
                                    <>
                                      <i
                                        className="fas fa-play-circle"
                                        style={{ color: "blue" }}
                                      ></i>
                                      <span
                                        className="ms-2"
                                        style={{ color: "blue" }}
                                      >
                                        {data?.status}
                                      </span>
                                    </>
                                  )}
                                  {data?.status === "Completed" && (
                                    <>
                                      <i
                                        className="fas fa-check-circle"
                                        style={{ color: "green" }}
                                      ></i>
                                      <span
                                        className="ms-2"
                                        style={{ color: "green" }}
                                      >
                                        {data?.status}
                                      </span>
                                    </>
                                  )} */}
                                <div
                                  className={` m-0 p-0 `}
                                  style={{
                                    width: "12px",
                                    height: "12px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    marginTop: "5px",
                                    marginRight: "10px",
                                    backgroundColor:
                                      data?.status.toLowerCase() === "scheduled"
                                        ? "red"
                                        : data?.status.toLowerCase() ===
                                          "completed"
                                        ? "green"
                                        : data?.status.toLowerCase() ===
                                          "validation"
                                        ? "#9400D3"
                                        : "blue",
                                  }}
                                ></div>
                                <p
                                  className={`m-0 p-0 ps-1`}
                                  style={{
                                    fontFamily: "Open-Sans-Medium",
                                    color:
                                      data?.status.toLowerCase() === "scheduled"
                                        ? "red"
                                        : data?.status.toLowerCase() ===
                                          "completed"
                                        ? "green"
                                        : data?.status.toLowerCase() ===
                                          "validation"
                                        ? "#9400D3"
                                        : "blue",
                                  }}
                                >
                                  {data?.status}
                                </p>
                              </p>
                            </div>

                            {/* support_persons */}

                            <div className="">
                              <p className="mb-2 d-flex flex-row flex-nowrap">
                                <span
                                  className="me-2"
                                  style={{ fontSize: "15px" }}
                                >
                                  <i className="bi bi-person"></i>
                                </span>

                                <div className="d-flex flex-row flex-wrap">
                                  {data?.support_person?.length > 0
                                    ? data.support_person.map(
                                        (each_person, index) => (
                                          <React.Fragment key={index}>
                                            <p className="mb-0">
                                              {index > 0 && (
                                                <span>
                                                  <i className="bi bi-dot"></i>
                                                </span>
                                              )}
                                              <span className="me-2">
                                                {each_person["name"]
                                                  ? each_person["name"]
                                                  : ""}
                                              </span>
                                            </p>
                                          </React.Fragment>
                                        )
                                      )
                                    : "Not mentioned name"}
                                </div>
                              </p>
                            </div>

                            {/* visit_location */}
                            <div>
                              <p className="mb-2">
                                <span className="me-1">
                                  <i className="bi bi-geo-alt"></i>
                                </span>{" "}
                                {data.machine_code}
                              </p>
                            </div>

                            {/* visit start_date - End date  */}
                            <div className="d-flex flex-row justify-content-between mb-2 visit_details_small_screen">
                              {data?.creation_time && (
                                <div>
                                  <p className="m-0 text-wrap">
                                    <span className="me-2">
                                      <i class="bi bi-calendar-date"></i>
                                    </span>
                                    {data?.creation_time.slice(0, -12)}
                                  </p>
                                  <p className="date_details">Creation Date</p>
                                </div>
                              )}
                              {data?.status.toLowerCase() === "completed" && (
                                <div>
                                  <p className="mb-0">
                                    {" "}
                                    <span className="me-2">
                                      <i class="bi bi-calendar-date"></i>
                                    </span>
                                    {data?.updated_time.slice(0, -12)}
                                  </p>

                                  <p className="date_details"> Resolved Date</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </>
                )) || (
                  <Placeholder
                    message="Oops! There is no any Visit for this Ticket."
                    icon={<DriveEtaOutlinedIcon fontSize="inherit" />}
                  />
                )}
              </div>
            )}

            {false && (
              <div className="show_previous_vist_details mt-3">
                {(getBackendVisitDetails?.length > 0 && (
                  <>
                    <h6>Previous Visits Details</h6>
                    <div className="d-flex flex-row flex-wrap w-100 ">
                      {getBackendVisitDetails?.map((data) => (
                        <div
                          className="prev_visits_container"
                          onClick={() => handleVisitDetailsAddButtonClick(data)}
                        >
                          <div className="prev_visits_body">
                            {/* visit_id  */}
                            <div className="mb-2">
                              <p className="mb-0">
                                <span className="me-2 ">
                                  <i class="bi bi-ticket-fill"></i>
                                </span>
                                {data.id}
                              </p>
                            </div>

                            {/* edit button' */}

                            {/* support_persons */}

                            <div className="">
                              <p className="mb-2 d-flex flex-row flex-nowrap">
                                <span className="me-2">
                                  <i className="bi bi-person-fill"></i>
                                </span>

                                <div className="d-flex flex-row flex-wrap">
                                  {data?.support_person?.length > 0
                                    ? data.support_person.map(
                                        (each_person, index) => (
                                          <React.Fragment key={index}>
                                            <p className="mb-0">
                                              {index > 0 && (
                                                <span>
                                                  <i className="bi bi-dot"></i>
                                                </span>
                                              )}
                                              <span className="me-2">
                                                {each_person["name"]
                                                  ? each_person["name"]
                                                  : ""}
                                              </span>
                                            </p>
                                          </React.Fragment>
                                        )
                                      )
                                    : "Not mentioned name"}
                                </div>
                              </p>
                            </div>

                            {/* visit_location */}
                            <div>
                              <p className="mb-2">
                                <span className="me-1">
                                  <i className="bi bi-geo-alt-fill"></i>
                                </span>{" "}
                                {data.machine_code} - (
                                {get_machine_code(data.machine_code)})
                              </p>
                            </div>

                            <div className="d-flex flex-row mb-2 custom_small">
                              <div className="me-4 ">
                                {" "}
                                {/* visit distance */}
                                <p className="mb-0">
                                  <span className="me-1">
                                    <i class="fas fa-running"></i>
                                  </span>{" "}
                                  {data.distance
                                    ? `${data.distance} km`
                                    : "Not Mentioned"}{" "}
                                </p>
                              </div>

                              <div className="me-4">
                                {/* Total_expense */}
                                <p className="mb-0">
                                  <span className="expense">
                                    <i class="bi bi-currency-rupee"></i>
                                  </span>
                                  {data.total_expense
                                    ? data.total_expense
                                    : "Not Mentioned"}
                                </p>
                              </div>

                              {/* count of attachments */}
                              <div className="me-4">
                                <p className="mb-0">
                                  {" "}
                                  <span className="me-2">
                                    <i class="bi bi-paperclip"></i>
                                  </span>
                                  {data?.attachments?.length}
                                </p>
                              </div>
                            </div>

                            {/* parts replaced */}
                            <div className="d-flex flex-row flex-nowrap mb-2">
                              <span className="me-2">
                                <i className="bi bi-gear"></i>
                              </span>

                              <div className="d-flex flex-row flex-wrap">
                                {" "}
                                {data.parts_replaced.length > 0 ? (
                                  data.parts_replaced.map((parts, index) => (
                                    <p className="mb-0 me-2">
                                      <React.Fragment key={index}>
                                        {index > 0 && (
                                          <i className="bi bi-dot"></i>
                                        )}
                                        {parts.hardware}
                                      </React.Fragment>
                                    </p>
                                  ))
                                ) : (
                                  <p className="mb-0 ms-2">Not Mentioned</p>
                                )}
                              </div>
                            </div>

                            {/* remarks */}

                            <div className="mb-2">
                              <p className="mb-0">
                                <span className="me-2">
                                  <i class="bi bi-chat-dots"></i>
                                </span>
                                {data.remarks ? data.remarks : "No remarks"}
                              </p>
                            </div>

                            {/* visit start_date - End date  */}
                            <div className="d-flex flex-row justify-content-between mb-2 visit_details_small_screen">
                              {data.start_date && (
                                <div>
                                  <p className="m-0 text-wrap">
                                    <span className="me-2">
                                      <i class="bi bi-calendar-date"></i>
                                    </span>
                                    {dayjs(data.start_date).format(
                                      "ddd MMM DD YYYY"
                                    )}
                                  </p>
                                  <p className="date_details">Visited Date</p>
                                </div>
                              )}
                              {data.end_date && (
                                <div>
                                  <p className="mb-0">
                                    {" "}
                                    <span className="me-2">
                                      <i class="bi bi-calendar-date"></i>
                                    </span>
                                    {dayjs(data.start_date).format(
                                      "ddd MMM DD YYYY"
                                    )}
                                  </p>

                                  <p className="date_details"> Resolved Date</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )) || (
                  <Placeholder
                  // message="Oops! No Timeline is there for this visit."
                  // icon={<DriveEtaOutlinedIcon fontSize="inherit" />}
                  />
                )}
              </div>
            )}

            {false && showVisitDetailsModal && (
              <Modal
                // size="xl"
                // className="modal-container"
                open={showVisitDetailsModal}
                onClose={handleCloseVisitDetailsModal}
                // backdrop="static"
                style={{
                  position: "fixed",
                  overflowY: "scroll",
                  margin: "0px 0px 25px 10px",
                }}
              >
                <Box
                  style={{
                    position: "relative",
                    zIndex: "1111",
                    right: "0",
                    top: "4%",
                    margin: "  auto",
                    overflowY: "auto",
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                    maxWidth: "992px",
                    width: "80vw",
                    height: "auto",
                  }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center p-3"
                    style={{ borderBottom: "1px solid #f5f5f5" }}
                  >
                    <p className="m-0">Visit Details</p>
                    {/* <span
                  onClick={isEditVisits}
                  className={
                    addVisitdetailsButton
                      ? "d-none edit_visit_icon"
                      : "edit_visit_icon"
                  }
                >
                  {isEditVisitDetails ? (
                    <img src={pensil} alt="" />
                  ) : (
                    <img src={edit_icon} alt="" />
                  )}
                </span> */}
                    <div
                      onClick={() => {
                        handleCloseVisitDetailsModal();
                      }}
                    >
                      <img
                        src={close}
                        alt="close"
                        style={{ width: "20px", cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box className="px-0 py-0 px-sm-3 py-sm-2 ">
                    <div className="main_visit_modal_container">
                      <div className="visit_details_form ">
                        <div className="d-flex flex-column flex-lg-row align-items-center mb-lg-3">
                          <div className="p-2 w-100 modal_elements ">
                            <Autocomplete
                              multiple
                              name="support_person"
                              id="tags-outlined"
                              value={visitDetailsformData.support_person}
                              options={backendFilterData?.support_name_list}
                              getOptionLabel={(option) => option}
                              filterSelectedOptions
                              onChange={(e, selectedOptions) => {
                                setVisitDetailsFormData((prev) => ({
                                  ...prev,
                                  support_person: selectedOptions.map(
                                    (option) => option
                                  ),
                                }));
                              }}
                              disabled={isEditVisitDetails}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Support Persons*"
                                  placeholder="Support Persons"
                                  sx={{
                                    "& .MuiButtonBase-input.Mui-disabled": {
                                      opacity: "0.6",
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="p-2 w-100">
                            <FormControl
                              // sx={{ m: 1, width: "fullwidth" }}
                              size="medium"
                              fullWidth
                            >
                              <InputLabel id="location-label">
                                Location*
                              </InputLabel>
                              <Select
                                placeholder="Enter Location"
                                disabled={isEditVisitDetails}
                                labelId="location-label"
                                value={visitDetailsformData.location}
                                onChange={handleInputChange}
                                label="Location*"
                                name="location"
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                  },
                                }}
                              >
                                {backendFilterData?.machine_location_list?.map(
                                  (location, index) => (
                                    // console.log(
                                    //   "location one by one:",
                                    //   location
                                    // );
                                    <MenuItem key={index} value={location}>
                                      {location}-({get_machine_code(location)})
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row mb-lg-3">
                          <div className="d-flex flex-row align-items-center w-100 w-lg-50 p-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                                sx={{
                                  overflow: "unset",
                                  padding: "0px",
                                  width: "100%",
                                }}
                              >
                                <DatePicker
                                  label="Start Date*"
                                  maxDate={
                                    visitDetailsformData?.end_date
                                      ? dayjs(visitDetailsformData.end_date)
                                      : dayjs()
                                  }
                                  // maxDate={dayjs()}
                                  value={visitDetailsformData.start_date}
                                  onChange={(date) =>
                                    handleDateChange("start_date", date)
                                  }
                                  className="min_width_unset"
                                  disabled={isEditVisitDetails}
                                  sx={{
                                    "& .MuiStack-root>.MuiTextField-root": {
                                      minWidth: "unset",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor:
                                        "rgba(0, 0, 0, 0.65)",
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>

                            <i class="bi bi-arrow-left-right mx-2"></i>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                                sx={{
                                  overflow: "unset",
                                  padding: "0px",
                                  width: "100%",
                                }}
                              >
                                <DatePicker
                                  className="min_width_unset"
                                  label="End Date*"
                                  disabled={isEditVisitDetails}
                                  minDate={dayjs(
                                    visitDetailsformData?.start_date
                                  )}
                                  maxDate={dayjs()}
                                  value={visitDetailsformData.end_date}
                                  onChange={(date) =>
                                    handleDateChange("end_date", date)
                                  }
                                  sx={{
                                    "& .MuiStack-root>.MuiTextField-root": {
                                      minWidth: "unset",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor:
                                        "rgba(0, 0, 0, 0.65)",
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>

                          <div className="p-2 w-100 w-lg-50 modal_elements">
                            <Autocomplete
                              multiple
                              disabled={isEditVisitDetails}
                              name="parts_replaced"
                              id="tags-outlined"
                              value={visitDetailsformData.parts_replaced}
                              options={backendFilterData?.hardware_relaced_list}
                              getOptionLabel={(option) => option}
                              filterSelectedOptions
                              onChange={(e, selectedOptions) => {
                                setVisitDetailsFormData((prev) => ({
                                  ...prev,
                                  parts_replaced: selectedOptions.map(
                                    (option) => option
                                  ),
                                }));
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Parts Replaced*"
                                  placeholder="Enter Parts Replaced"
                                />
                              )}
                            />
                          </div>
                        </div>

                        {/* distance && expenses section */}
                        <div className="d-flex flex-column flex-lg-row mb-lg-3">
                          {/* distance */}
                          <div className="p-2 w-100">
                            {" "}
                            <TextField
                              value={visitDetailsformData.distance}
                              name="distance"
                              placeholder="Enter Distance"
                              fullWidth
                              id="outlined-basic"
                              label="Distance (km)"
                              variant="outlined"
                              type="number"
                              onChange={handleInputChange}
                              disabled={isEditVisitDetails}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                },
                              }}
                            />
                          </div>

                          <div className="p-2 w-100">
                            {" "}
                            <TextField
                              value={visitDetailsformData.total_expenses}
                              name="total_expenses"
                              placeholder="Total Expense"
                              fullWidth
                              id="outlined-basic"
                              label="Total Expenses*"
                              variant="outlined"
                              type="number"
                              disabled={isEditVisitDetails}
                              onChange={handleInputChange}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                },
                              }}
                            />
                          </div>
                        </div>

                        {/*remarks desp */}
                        <div className="d-flex flex-column flex-lg-row mb-lg-3">
                          <div className="p-2 w-100">
                            <FormControl className="w-100">
                              <TextField
                                name="remarks"
                                label="Remarks*"
                                id="outlined-textarea"
                                disabled={isEditVisitDetails}
                                onChange={handleInputChange}
                                rows={4}
                                multiline
                                value={visitDetailsformData.remarks}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                  },
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>

                        {/* add serial no of products */}
                        {/* <button
                          type="button"
                          className="btn btn-outline-primary markResolved_button m-1"
                          disabled={isEditVisitDetails}
                          // variant="outlined"
                          onClick={() => {
                            setVisitDetailsFormData((prev) => ({
                              ...prev,
                              isAddSerial: !prev.isAddSerial,
                            }));
                          }}
                        >
                          <Tooltip
                            data-html="true"
                            title={
                              "Click here to add serial no of hardware parts"
                            }
                            arrow={true}
                          >
                            Add Serial No
                          </Tooltip>
                        </button> */}
                        {(!isEditVisitDetails && (
                          <div
                            className="m-1" // You can keep the same class for styling
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              data-html="true"
                              title={
                                "Click here to add serial no of hardware parts"
                              }
                              arrow={true}
                            >
                              <RiAddBoxFill
                                onClick={() => {
                                  if (!isEditVisitDetails) {
                                    // Check if editing is allowed
                                    setVisitDetailsFormData((prev) => ({
                                      ...prev,
                                      isAddSerial: !prev.isAddSerial,
                                    }));
                                  }
                                }}
                                className="add-icon-hover"
                                style={{
                                  color: isEditVisitDetails
                                    ? "gray"
                                    : "#6c757d", // Change color based on edit state
                                  fontSize: "30px", // Adjust size as needed
                                  cursor: isEditVisitDetails
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              />
                            </Tooltip>
                            <span style={{ marginLeft: "5px" }}>
                              Add Serial No
                            </span>{" "}
                          </div>
                        )) || <></>}

                        {(visitDetailsformData?.isAddSerial && (
                          <div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: `${
                                  isLargeScreen ? "1fr" : "1fr 1fr 1fr"
                                }`,
                                marginTop: "10px",
                                gap: "2px", // Add some space between columns
                              }}
                            >
                              {/* Hardware Autocomplete */}
                              <div className="p-2 w-100">
                                <Autocomplete
                                  name="hardware"
                                  id="tags-outlined"
                                  value={tempSerialData.hardware}
                                  options={
                                    backendFilterData?.hardware_relaced_list ||
                                    []
                                  }
                                  getOptionLabel={(option) => option}
                                  filterSelectedOptions
                                  onChange={(e, selectedOption) => {
                                    setTempSerialData((prev) => ({
                                      ...prev,
                                      hardware: selectedOption,
                                    }));
                                  }}
                                  disabled={isEditVisitDetails}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Hardware*"
                                      placeholder="Select Hardware"
                                      fullWidth
                                      sx={{
                                        "& .MuiButtonBase-input.Mui-disabled": {
                                          opacity: "0.6",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </div>

                              {/* Old V39 Serial No */}
                              <div className="p-2 w-100">
                                <TextField
                                  value={tempSerialData.old_serial}
                                  name="old_serial"
                                  placeholder="Enter Old Serial No"
                                  fullWidth
                                  id="outlined-basic"
                                  label="Old Serial No*"
                                  variant="outlined"
                                  onChange={handleTempInputChange}
                                  disabled={isEditVisitDetails}
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor:
                                        "rgba(0, 0, 0, 0.65)",
                                    },
                                  }}
                                />
                              </div>

                              {/* New V39 Serial No */}
                              <div className="p-2 w-100">
                                <TextField
                                  value={tempSerialData.new_serial}
                                  name="new_serial"
                                  placeholder="Enter New Serial No"
                                  fullWidth
                                  id="outlined-basic"
                                  label="New Serial No*"
                                  variant="outlined"
                                  onChange={handleTempInputChange}
                                  disabled={isEditVisitDetails}
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor:
                                        "rgba(0, 0, 0, 0.65)",
                                    },
                                  }}
                                />
                              </div>
                            </div>

                            {/* Button to add tempSerialData to product_serial_data */}
                            <div className="px-2 py-2">
                              <button
                                variant="contained"
                                className="btn btn-outline-primary markResolved_button m-1"
                                onClick={addSerialData}
                                disabled={
                                  !tempSerialData.hardware ||
                                  !tempSerialData.old_serial ||
                                  !tempSerialData.new_serial
                                }
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        )) || <></>}

                        {/* Render the list of elements */}
                        {(visitDetailsformData?.product_serial_data?.length >
                          0 && (
                          <div className="serial-keys-container">
                            <header className="pb-2 previous-comments-header">
                              Product Serial Keys
                            </header>
                            <div className="mt-2 data-variation-elements-container">
                              {visitDetailsformData?.product_serial_data?.map(
                                (element, index) => (
                                  <div
                                    key={index}
                                    className="data-variation-elements"
                                    style={{ color: "rgba(76, 78, 100, 0.7)" }}
                                  >
                                    <strong>Hardware:</strong>{" "}
                                    {element.hardware},{" "}
                                    <strong>Old Serial:</strong>{" "}
                                    {element.old_serial},{" "}
                                    <strong>New Serial:</strong>{" "}
                                    {element.new_serial}
                                    <span
                                      className="px-2 cross-btn-data-variation"
                                      variant="outlined"
                                      style={{
                                        cursor: isEditVisitDetails
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                      onClick={() => handleDeleteElement(index)}
                                    >
                                      {(addVisitdetailsButton && "x") || <></>}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )) || <></>}
                        {/* here serial part ended */}

                        {/* Attachements */}
                        {!addVisitdetailsButton && (
                          <div className="show-attachments-content px-2 mb-lg-3">
                            <header className="pb-2 previous-comments-header">
                              Attachments
                            </header>
                            {(prevVisitAttachments?.length && (
                              <div className="d-sm-flex flex-wrap gap-3">
                                {prevVisitAttachments &&
                                  prevVisitAttachments?.map(
                                    (attachment, index) => {
                                      if (attachment?.filetype) {
                                        let fileUrl;
                                        const fileType =
                                          attachment?.filetype?.toLowerCase();

                                        if (
                                          [
                                            "png",
                                            "jpg",
                                            "jpeg",
                                            "webp",
                                          ].includes(fileType)
                                        ) {
                                          fileUrl = `data:image/${fileType};base64,${attachment?.data}`;
                                        } else if (fileType === "pdf") {
                                          fileUrl = `data:application/pdf;base64,${attachment.data}`;
                                        } else if (
                                          ["xlsx", "xls"].includes(fileType)
                                        ) {
                                          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${attachment.data}`;
                                        } else if (fileType === "csv") {
                                          fileUrl = `data:text/csv;base64,${attachment.data}`;
                                        } else if (fileType === "mp3") {
                                          fileUrl = `data:audio/mp3;base64,${attachment.data}`;
                                        } else {
                                          return null;
                                        }

                                        return (
                                          <div
                                            key={index}
                                            className="d-flex align-items-center data-variation-elements my-3 mx-2 my-sm-0 mx-sm-0"
                                          >
                                            <a
                                              href={fileUrl}
                                              download={`${attachment?.filename}`}
                                            >
                                              {attachment?.filename}
                                            </a>

                                            <div className="ms-auto">
                                              <div
                                                className="dropdown ms-2  "
                                                style={{ cursor: "pointer" }}
                                              >
                                                <Tooltip
                                                  data-html="true"
                                                  title={
                                                    "Click to Delete this attachment"
                                                  }
                                                  arrow={true}
                                                >
                                                  <i
                                                    className="fa-solid fa-ellipsis-vertical  "
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    style={{
                                                      padding: "0px 3px",
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                    }}
                                                  ></i>
                                                </Tooltip>
                                                {/* Centered dropdown */}
                                                {/* </button> */}
                                                <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                                                  <li>
                                                    <a
                                                      className="dropdown-item text-center delete-ticket-btn"
                                                      href="#"
                                                      onClick={() => {
                                                        setConfirmDeletionState(
                                                          (prev) => ({
                                                            ...prev,
                                                            isOpen: true,
                                                            modalType:
                                                              "visit_attachment",
                                                            ticketId: ticketId,
                                                            attachId:
                                                              attachment.id,
                                                          })
                                                        );
                                                      }}
                                                    >
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                              </div>
                            )) ||
                              "No attachments added"}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* attachments */}
                    {!isEditVisitDetails && (
                      <div style={{ padding: "13px 21px 0px 21px" }}>
                        <p>* Service report and Machine images are mandatory</p>
                        <FilesUpload
                          genFields={visitDetailsformData}
                          setGenFields={setVisitDetailsFormData}
                          isSmallScreen={isSmallScreen}
                        />
                      </div>
                    )}

                    <Typography className="float-end p-3 px-4">
                      <LoadingButton
                        size="small"
                        onClick={handleSaveChanges}
                        disabled={
                          // ticketStatus === "resolved" ||
                          visitDetailsformData.location === "" ||
                          visitDetailsformData?.total_expenses === "" ||
                          (addVisitdetailsButton &&
                            visitDetailsformData?.attachments.length === 0) ||
                          visitDetailsformData?.start_date === null ||
                          visitDetailsformData?.end_date === null ||
                          visitDetailsformData?.support_person.length === 0 ||
                          visitDetailsformData?.parts_replaced.length === 0 ||
                          visitDetailsformData?.remarks === "" ||
                          isEditVisitDetails
                        }
                        endIcon={<SendIcon />}
                        loading={loadingButton}
                        loadingPosition="end"
                        variant="contained"
                        style={{ height: "36px" }}
                      >
                        <span>{`${
                          addVisitdetailsButton ? "Add Visit " : "Save Changes"
                        }`}</span>
                      </LoadingButton>
                    </Typography>
                  </Box>
                </Box>
              </Modal>
            )}

            {false && confirmDeletionState?.isOpen && (
              <ConfirmDeletionModal
                deleteModalState={confirmDeletionState}
                handleClose={closeConfirmDelectionModal}
                handleReload={handleReloadData}
                closingVisitModal={handleCloseVisitDetailsModal}
              />
            )}
          </div>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
}

export default VisitDetails;
