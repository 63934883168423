import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import close from "../../assets/images/close.svg";
import * as XLSX from "xlsx";
import { Box, Tooltip, Typography } from "@mui/material";
import FilesUpload from "../RaiseTicketForm/FilesUpload";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import { urlToFileArrayBuffer } from "../Exporters/ExportFunctions";
import ShowAttachments from "./ShowAttachments";
import ShowPreview from "./ShowPreview";
import { color } from "../modals/Calllog";

const TagAttachmentModal = ({
  modalOpen,
  genFields,
  setGenFields,
  isSmallScreen,
  handleClose,
  options,
  color,
  isMediumScreen,
  isSmallSmallScreen,
  islageScreen,
  islagelargeScreen,
  handleSaveAttachment,
  loading,
}) => {
  const modalstyle = {
    position: "relative",
    zIndex: "90",
    right: "0",
    top: "12%",
    left: "0",
    margin: "  auto",
    minHeight: "20vh",
    height: "auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    overflowY: "auto",
    width: "100%",
    maxWidth: "1250px",
  };
  const [tagAttach, setTagAttach] = useState({
    attachments: [],
  });

  // this state is for preview component
  const [sheetData, setSheetData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [preview, setPreview] = useState({
    fileName: "",
    fileUrl: "",
    type: "",
    fileType: "",
    fileData: null,
  });

  // to load the button while download or preview from backend
  const [dataLoading, setDataLoading] = useState({
    load: false,
    attach_id: "",
  });

  const [tagButton, setTagButton] = useState(null);
  const handleButtonClick = (value) => {
    setTagButton(value);
  };

  const handleClosePreview = () => {
    setShowModal(false);
    setSheetData(null);
    setPreview({
      fileName: "",
      fileUrl: "",
      type: "",
      fileType: "",
      fileData: null,
    });
  };

  const handleFilePreview = async (attachment) => {
    setDataLoading((prev) => ({
      ...prev,
      load: true,
      attach_id: attachment.id,
    }));
    const { file_name, type, fileType, blobData } = attachment;
    // Generate the base64 string from blobData (object URL)
    const arrayBuffer = await urlToFileArrayBuffer(blobData);
    const fileData = new Uint8Array(arrayBuffer);
    const base64String = arrayToBase64(fileData);

    let fileUrl = "";
    if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
      fileUrl = `data:image/${fileType};base64,${base64String}`;
    } else if (fileType === "pdf") {
      fileUrl = `data:application/pdf;base64,${base64String}`;
    } else if (["xlsx", "xls"].includes(fileType)) {
      fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
    } else if (fileType === "csv") {
      fileUrl = `data:text/csv;base64,${base64String}`;
    } else if (fileType === "mp3") {
      fileUrl = `data:audio/mp3;base64,${base64String}`;
    } else {
      return null;
    }
    setPreview({
      fileName: file_name,
      fileUrl: fileUrl,
      type: type,
      fileType: fileType,
      fileData: base64String,
    });
    if (["xlsx", "xls", "csv"].includes(fileType)) {
      const binaryStr = window.atob(base64String);
      const binaryData = new Uint8Array(
        [...binaryStr].map((char) => char.charCodeAt(0))
      );
      const workbook = XLSX.read(binaryData, { type: "array" });

      const sheetName = workbook.SheetNames[0]; // Get the first sheet
      const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
      const sheetJson = XLSX.utils.sheet_to_json(worksheet, {
        header: 1, // Keep as an array of arrays
        defval: "-", // Fill empty cells with placeholder
      });

      // Store the first 100 rows of the Excel sheet in sheetData
      setSheetData(sheetJson.slice(0, 100));
    } else {
      setShowModal(true);
    }
    setDataLoading((prev) => ({
      ...prev,
      load: false,
      attach_id: null,
    }));
    // console.log("preview data", preview, "sheet data:", sheetData);
  };

  const handleDeleteBtn = (attachment) => {
    // console.log("tag attchment clciked:", attachment, modalOpen);
    const { id, tag } = attachment; // Extract `id` and `tag` from attachment

    setGenFields((prev) => {
      const updatedAttachments = { ...prev.attachments }; // Clone existing attachments object

      // Filter out the attachment with the specified `id`
      updatedAttachments[tag] = updatedAttachments[tag].filter(
        (item) => item.id !== id
      );

      // If the tag has no more files, delete the tag
      if (updatedAttachments[tag].length === 0) {
        delete updatedAttachments[tag];
      }

      return {
        ...prev,
        attachments: updatedAttachments,
      };
    });
  };

  const arrayToBase64 = (array) => {
    const chunkSize = 10000; // Adjust chunk size based on your needs
    let base64String = "";

    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      base64String += String.fromCharCode(...chunk);
    }

    return btoa(base64String);
  };

  const handleAddAttachment = async () => {
    if (tagAttach.attachments.length > 0) {
      try {
        // Resolve all promises in the mapped array to get the final list
        const temp_list = await Promise.all(
          tagAttach.attachments.map(async (element, index) => {
            let fileType = element.type.split("/")[1];
            // Handle the case for xlsx type and mp3 files
            if (
              fileType ===
              "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
              fileType = "xlsx";
            } else if (fileType === "mpeg") {
              fileType = "mp3";
            }

            return {
              id: index,
              file_name: element.name,
              tag: tagButton,
              type:
                (["png", "jpg", "jpeg", "webp"].includes(fileType) && "img") ||
                (["xlsx", "xls", "csv"].includes(fileType) && "excel") ||
                (fileType === "mp3" && "audio") ||
                fileType,
              fileType: fileType,
              blobData: element.objectURL,
            };
          })
        );

        // Update the state with the resolved list of attachments
        setGenFields((prev) => {
          const updatedAttachments = {
            ...prev.attachments, // Keep existing attachments
            [tagButton]: [
              ...(prev.attachments[tagButton] || []), // Preserve existing files under this tag if any
              ...temp_list, // Add new attachments
            ],
          };

          // Make sure that tagButton exists and no infinite loop occurs
          return {
            ...prev,
            attachments: updatedAttachments,
          };
        });

        // Clear tagAttach and tagButton states after the update
        setTagAttach({ attachments: [] });
        setTagButton("");
      } catch (error) {
        console.error("Error adding attachment:", error);
      }
    }
  };

  return (
    <Modal
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      style={{
        position: "fixed",
        height: "100%",
        overflowY: "scroll",
        margin: "0px 0px 0px 10px",
      }}
      open={modalOpen?.addAttach}
      BackdropProps={{
        onClick: (e) => e.stopPropagation(),
      }}
    >
      <Box sx={modalstyle}>
        <Typography
          className="d-flex justify-content-between p-3"
          style={{ borderBottom: "1px solid rgb(199, 199, 199)" }}
        >
          <h5 className="mb-0"> Add Attachment</h5>{" "}
          <div
            onClick={() => {
              handleClose("addAttach");
            }}
          >
            <Tooltip title={"Close"} arrow>
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        </Typography>
        <div
          className="d-flex px-3 pt-3 pb-2"
          style={{ fontSize: "16px", color: "rgba(76, 78, 100, 0.99)" }}
        >
          Choose Attachment Tag:
        </div>
        <div
          className="tag-options px-3"
          style={{ marginBottom: "5px", flexWrap: "wrap", gap: "15px" }}
        >
          {options.map((option, index) => (
            <div className="tag-option-button" key={index}>
              <button
                onClick={() => handleButtonClick(option.value)}
                className={tagButton === option.value ? "active" : ""}
              >
                {option.label}
              </button>
            </div>
          ))}
        </div>

        {tagButton && (
          <>
            <Typography className="px-3 py-2">
              <FilesUpload
                genFields={tagAttach}
                setGenFields={setTagAttach}
                isSmallScreen={isSmallScreen}
              />
            </Typography>
            <div className="px-3 py-2">
              {/* <button
                variant="contained"
                className="btn btn-outline-primary markResolved_button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleAddAttachment();
                }}
                disabled={tagAttach?.attachments?.length === 0}
              >
                Add
              </button> */}
              <LoadingButton
                sx={{
                  color: "#666bff",
                  border: "1px solid #666bff",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "rgb(171, 174, 255)",
                    border: "1px solid rgb(171, 174, 255)",
                  },
                }}
                size="medium"
                disabled={tagAttach?.attachments?.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleAddAttachment();
                }}
                startIcon={<AddIcon />}
                variant="outlined"
              >
                <span>Add</span>
              </LoadingButton>
            </div>{" "}
          </>
        )}
        {modalOpen?.addAttach && (
          <div className="px-3 py-2">
            <ShowAttachments
              attachments={genFields.attachments}
              handleFilePreview={handleFilePreview}
              handleDeleteBtn={handleDeleteBtn}
              isSmallScreen={isSmallScreen}
              showDownload={false}
              dataLoading={dataLoading}
              isDataWithTag={true}
              color={color}
            />
          </div>
        )}

        {(showModal || sheetData) && (
          <div>
            <ShowPreview
              showModal={showModal}
              handleClosePreview={handleClosePreview}
              sheetData={sheetData}
              previewData={preview}
              isSmallScreen={isSmallScreen}
              color={color}
            />
          </div>
        )}

        <Typography className="float-end p-3">
          <LoadingButton
            sx={{
              color: "white",
              backgroundColor: "#666bff",
              borderRadius: "8px",
              textTransform: "capitalize",
              "&:hover": {
                color: "white",
                backgroundColor: "#666bff",
              },
            }}
            size="large"
            onClick={() => handleSaveAttachment(genFields?.attachments)}
            disabled={
              Object.keys(genFields?.attachments)?.length === 0 || tagButton
            }
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Save</span>
          </LoadingButton>
        </Typography>
      </Box>
    </Modal>
  );
};

export default TagAttachmentModal;
