import React, { useContext, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import Select from "@mui/material/Select";
import "../../../../assets/styles/CardModal.css";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Tooltip as MuiTooltip,
  Button,
  Modal,
  ButtonGroup,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
} from "@mui/material";
import ContextState from "../../../contextApi/ContextState";
import { useSelector } from "react-redux";
import ModalError from "../../../error/ModalError";
import close from "../../../../assets/images/close.svg";
import { useTheme } from "@mui/material/styles";
import { BeatLoader } from "react-spinners";

import showInventoryImg from "../../../../assets/images/inv_total_count.png";
import showReadyToUseImg from "../../../../assets/images/inv_ready_to_use.png";
import showInHouseImg from "../../../../assets/images/inv_in_house.png";
import cheklistFileUploadImg from "../../../../assets/images/cheklist_file_upload.png";
import { DataGrid } from "@mui/x-data-grid";
import { FaPlus, FaUpload, FaEye } from "react-icons/fa";
import { json, useOutletContext } from "react-router-dom";
import { IoMdCar, IoMdDownload } from "react-icons/io";
import { GoChecklist } from "react-icons/go";
import { HiUserAdd } from "react-icons/hi";
import dayjs from "dayjs";
import { formattingString } from "../../../analytics/DataPull";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ShowAttachments from "../../../Attachment/ShowAttachments";
import ConfirmDeletionModal from "../../../modals/ConfirmDeletionModal";
import * as XLSX from "xlsx";
import FilesUpload from "../../../RaiseTicketForm/FilesUpload";
import ShowPreview from "../../../Attachment/ShowPreview";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CardPagesSkeleton,
  OverviewPageSkeleton,
} from "../../../Exporters/ExportFunctions";
import Placeholder from "../../../error/Placeholder";

const statusColors = [
  { status: "Arrival", color: "#2196F3" }, // Blue shade
  { status: "Testing", color: "#4FC3F7" }, // Light Blue
  { status: "Repair", color: "#29B6F6" }, // Medium Blue
  { status: "Retesting", color: "#039BE5" }, // Dark Blue
  { status: "Ready to Go", color: "#66BB6A" }, // Light Green
  { status: "On Field", color: "#43A047" }, // Medium Green
  { status: "In House", color: "#388E3C" }, // Dark Green
  { status: "Restore For use", color: "#2E7D32" }, // Darker Green
  { status: "Dispatch", color: "#FF7043" }, // Light Red
  { status: "Return", color: "#EF5350" }, // Medium Red
  { status: "Re Dispatched", color: "#E53935" }, // Darker Red
  { status: "Damaged", color: "#D32F2F" }, // Dark Red
  { status: "Prepare", color: "#81D4FA" }, // Very Light Blue
];
const findColorByStatus = (status) => {
  const normalizedStatus = status ? String(status).toLowerCase() : "";
  const result = statusColors.find(
    (item) => item.status.toLowerCase() === normalizedStatus
  );
  return result ? result.color : null; // Return the color if found, otherwise return null
};

const FilePreviewOverlay = ({
  data,
  setShowModal,
  showModal,
  selectedRow,
  color,
  isSmallScreen,
  reloadAttachmentsPage,
  fetchSatgeEventDataAndUpdateStageData,
  fetchDataAndUpdateProductGenralData,
  refresgProductDetails,
}) => {
  // alter files
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  // const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  const theme = useTheme();
  const {
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    controllerRef,
  } = useContext(ContextState);
  const [edit, setEdit] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachmentStatus, setAttachmentStatus] = useState(false);

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
    fetchSatgeEventDataAndUpdateStageData(selectedRow.c_identity, true);
    fetchDataAndUpdateProductGenralData(false);
    refresgProductDetails();
  };

  const { ticketId, productId } = data;
  const track_id = selectedRow["c_track_id"];
  const checkpoint_desc = selectedRow["checkpoint"];

  const [attachments, setAttachments] = useState([]);
  const [sheetData, setSheetData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const [preview, setPreview] = useState({
    fileName: "",
    fileUrl: "",
    type: "",
    fileType: "",
    fileData: null,
  });

  // delection modal state
  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    ticketId: "",
    attachId: null,
    trackId: null,
    productID: null,
    attachName: "",
    startedQueryTime: null,
  });

  let isMounted;
  const [dataLoading, setDataLoading] = useState({
    load: false,
    attach_id: null,
  });

  const handleFilePreview = async (attachment) => {
    try {
      const { id, file_name, type, fileType } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));
      let fileUrl = "";
      let payload = {
        product_id: productId,
        attachment_id: attachment?.id,
        info_type: "attachments",
        action: "single_file_data",
      };
      const response = await fetchApi(
        "get_product_info",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200) {
        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
          fileUrl = `data:image/${fileType};base64,${response?.data?.data}`;
        } else if (fileType === "pdf") {
          fileUrl = `data:application/pdf;base64,${response?.data?.data}`;
        } else if (["xlsx", "xls"].includes(fileType)) {
          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data?.data}`;
        } else if (fileType === "csv") {
          fileUrl = `data:text/csv;base64,${response?.data?.data}`;
        } else if (fileType === "mp3") {
          fileUrl = `data:audio/mp3;base64,${response?.data?.data}`;
        } else {
          return null;
        }
        setPreview({
          fileName: file_name,
          fileUrl: fileUrl,
          type: type,
          fileType: fileType,
          fileData: response?.data?.data,
        });
        if (["xlsx", "xls", "csv"].includes(fileType)) {
          const binaryStr = window.atob(response?.data?.data);
          const binaryData = new Uint8Array(
            [...binaryStr].map((char) => char.charCodeAt(0))
          );
          const workbook = XLSX.read(binaryData, { type: "array" });
          const sheetName = workbook.SheetNames[0]; // Get the first sheet
          const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
          const sheetJson = XLSX.utils.sheet_to_json(worksheet, {
            header: 1, // Keep as an array of arrays
            defval: "-", // Fill empty cells with placeholder
          });
          // Store the first 100 rows of the Excel sheet in sheetData
          setSheetData(sheetJson.slice(0, 100));
        } else {
          setShowPreview(true);
        }
      } else if (response?.statusCode !== 200) {
        openSnackbar(response.msg, "error");
      } else if (!response) {
        console.log("aborted the privious query ...");
      }
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    } catch (error) {
      console.log("error while Fetching file : ", error);
      if (controllerRef.current) {
        // console.log("abort error ");
      } else {
        // console.log("not a abort error ");
        openSnackbar("Some error occured while Fetching File!", "error");
        setDataLoading((prev) => ({
          ...prev,
          load: false,
          attach_id: null,
        }));
      }
    }
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setSheetData(null);
    setPreview({
      fileName: "",
      fileUrl: "",
      type: "",
      fileType: "",
      fileData: null,
    });
  };

  const handleDownloadBtn = async (attachment) => {
    try {
      const { id, file_name } = attachment;
      setDataLoading((prev) => ({
        ...prev,
        load: true,
        attach_id: id,
      }));
      // Prepare payload for the API request
      const payload = {
        product_id: productId,
        attachment_id: attachment?.id,
        info_type: "attachments",
        action: "single_file_data",
      };
      // Fetch binary data from the server
      const response = await fetchApi(
        "get_product_info",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200) {
        // Base64 binary data is available directly
        const binaryData = response?.data?.data;
        // Decode the Base64 binary data (if needed)
        const byteCharacters = atob(binaryData);
        const byteNumbers = new Array(byteCharacters.length).map((_, i) =>
          byteCharacters.charCodeAt(i)
        );
        const byteArray = new Uint8Array(byteNumbers);
        // Create a downloadable file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(
          new File([byteArray], file_name, { type: attachment.fileType })
        );
        link.download = file_name; // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        openSnackbar("Failed to download the file. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error during file download:", error);
      openSnackbar("Some error occurred while downloading the file!", "error");
    } finally {
      // Reset loading state
      setDataLoading((prev) => ({
        ...prev,
        load: false,
        attach_id: null,
      }));
    }
  };

  const [queryStartTime, setQueryStartTime] = useState(null);

  const handleDeleteBtn = async (attachment) => {
    console.log("The attachment Data : ", attachment);
    setConfirmDeletionState((prev) => ({
      ...prev,
      isOpen: true,
      modalType: "product_attachment",
      trackId: track_id,
      productID: productId,
      attachId: attachment.id,
      attachName: attachment.file_name,
      startedQueryTime: queryStartTime,
    }));
  };
  useEffect(() => {
    console.log("confirmDeletionState : ", confirmDeletionState)
  
  }, [confirmDeletionState])
  

  const displayAttachments = async (ticketId, isMounted) => {
    try {
      let payload = {
        info_type: "attachments",
        action: "all_files",
        track_id: track_id,
        product_id: productId,
      };
      showLoadingModal(true);

      // if (refreshBtn) {
      //   setRefreshLoading(true);
      // }

      const jsonData = await fetchApi(
        "get_product_info",
        "POST",
        payload,
        true,
        2
      );
      if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
        if (isMounted) {
          // console.log("attachments data received successfully:", jsonData.data.attachments)
          const attachmentsData = jsonData.data.attachments;
          setAttachments(attachmentsData);
          showModalErrorPage(null, null);
        }
      } else if (jsonData?.statusCode === 500 || jsonData?.statusCode === 401) {
        // openSnackbar(jsonData.data, "error")
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
        // showLoadingModal(false);
      } else {
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      }
      showLoadingModal(false);
    } catch (error) {
      if (controllerRef.current) {
        // openSnackbar("Request Timed Out!!!", "warning");
        console.log("fetch abort in attchemnts ... ");
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      // setRefreshLoading(false);
      // setRefreshBtn(false);
    }
  };

  // function to upload the files and status
  const handleUpload = async () => {
    setLoading(true);
    // console.log("attachment files:", genFields);

    let formData = {};
    const attachmentsData = await Promise.all(
      genFields.attachments.map(async (data) => {
        // console.log(data);
        // Fetch array buffer from URL
        const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
        // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
        const uint8Array = new Uint8Array(arrayBuffer);

        // console.log(arrayBuffer);
        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer]);
        // console.log("blob-", blob);
        // Return object with file name and FormData
        return {
          file_name: data.name,
          file_data: Array.from(uint8Array),
        };
      })
    );
    formData = {
      product_id: productId,
      attachments: attachmentsData,
      checklist_track_id: track_id,
      checkpoint_desc: checkpoint_desc,
      query_start_time: new Date().toUTCString(),
      info_type: "checklist",
      action: "add_attachments",
    };

    // let isMounted = true;
    const response = await fetchApi(
      "edit_product_info",
      "POST",
      formData,
      true,
      2
    );
    if (response && response.statusCode === 200) {
      openSnackbar(response.data.message, "success");
      setGenFields((prevState) => ({
        ...prevState,
        attachments: [],
      }));
      setEdit(false);
      handleReloadData();
    } else if (response.statusCode === 500) {
      openSnackbar("Internal sever error!!! Try after some time.", "error");
    } else {
      openSnackbar(response.msg.message, "error");
    }
    setLoading(false);
  };

  // function to convert the url to filesarraybuffer type
  const urlToFileArrayBuffer = async (fileUrl) => {
    try {
      // Fetch the file content as a blob
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Read the blob as an array buffer
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(new Error("Failed to read file"));
        };
        reader.readAsArrayBuffer(blob);
      });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };

  useEffect(() => {
    isMounted = true;
    if (isMounted) {
      let startTime = new Date();
      startTime = startTime.toString().split(" (")[0];
      //   console.log("startTime : ", startTime);
      setQueryStartTime(startTime);
      displayAttachments(ticketId, isMounted);
    }

    return () => {
      isMounted = false;
      // On unmount, abort the previous request for fetchApi api
      // if (controllerRef.current) {
      //   controllerRef.current.abort();
      // }
    };
  }, [reloadData, reloadAttachmentsPage]);

  const [genFields, setGenFields] = useState({
    attachments: [],
  });
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // useEffect(() => {
  //   console.log("genFeild : ", genFields);
  // }, [genFields]);

  // closing of modal
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "12%",
            left: "0",
            margin: "  auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "1200px",
          }}
        >
          {/* Modal Header */}
          <div
            className="d-flex flex-row justify-content-between p-2  px-3"
            style={{
              // padding: "12px 18px",
              borderBottom:
                "1px solid" + color.taxtHeadingLight.slice(0, -2) + "33",
              position: "relative",
              zIndex: 1000,
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ paddingLeft: "2px" }}
            >
              <p
                className="m-0 p-0 "
                style={{
                  fontFamily: "Open-Sans-regular",
                  fontWeight: 500,
                  fontSize:
                    (isSmallSmallScreen && "1rem") ||
                    (isMediumScreen && "1.25rem") ||
                    "1.5rem",
                  color: color.taxtHeading,
                }}
              >
                Checklist Step #{`${selectedRow.checklist_serial_number}`}
              </p>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
          {/* Modal Header ends... */}

          {/* Modal Body Starts .... */}
          <div>
            <div
              className="main-attachment-container "
              style={{ minHeight: "50vh" }}
            >
              {(lodingModalVisiblity && (
                // {lodingVisiblity && (
                // <div
                //   className="showSpinner "
                //   style={{
                //     position: "absolute",
                //     height: "100%",
                //     width: "100%",
                //   }}
                // >
                //   <BeatLoader color="#2D97D4" />
                // </div>
                <div className="mx-3 my-3">
                  <CardPagesSkeleton
                    len={1}
                    cardHeight={40}
                    componentClass={"issue-type-row-div"}
                  />
                  <CardPagesSkeleton
                    len={1}
                    cardHeight={400}
                    componentClass={""}
                  />
                </div>
              )) ||
                (!showModalError.status && (
                  <>
                    <div
                      className=" main-attachment-container "
                      style={{ minHeight: "50vh", padding: "8px 16px " }}
                    >
                      <div className="my-3 mx-1">
                        <div
                          className="top-div-circular-progress-desc "
                          style={{
                            color: color.taxtHeadingLight,
                            fontSize: (isSmallScreen && "0.85rem") || "0.95",
                          }}
                        >
                          <p
                            style={{
                              color: color.tableRowHeading,
                              lineHeight: "1.45rem",
                              fontWeight: 600,
                            }}
                          >
                            {selectedRow.checkpoint}
                            {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                    }}
                                    >
                                    (
                                      {formatInIndianRupees(
                                        data?.last_10_days_data?.top_data?.region_scan
                                        )}
                                        scans)
                                </span> */}
                          </p>
                          CheckPoint
                        </div>
                      </div>
                      <div className="my-3 mx-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary markResolved_button"
                          disabled={ticketStatus === "resolved"}
                          onClick={() => setEdit(!edit)}
                        >
                          <MuiTooltip
                            data-html="true"
                            title={"Click here to add attachments"}
                            arrow={true}
                          >
                            Add attachments
                          </MuiTooltip>
                        </button>
                      </div>

                      {edit && (
                        <>
                          <div className="attachments-section my-3 mx-1">
                            <FilesUpload
                              genFields={genFields}
                              setGenFields={setGenFields}
                              isSmallScreen={isSmallScreen}
                            />
                            {/* <div className="uploadButton pt-3"> */}
                            {false && (
                              <FormControlLabel
                                className="d-block pb-2"
                                control={
                                  <Checkbox
                                    checked={attachmentStatus}
                                    onChange={(e) => {
                                      setAttachmentStatus(e.target.checked);
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label="Status (Resolved)"
                              />
                            )}

                            <LoadingButton
                              size="large"
                              onClick={handleUpload}
                              endIcon={<SendIcon />}
                              loading={loading}
                              className="mt-2"
                              disabled={genFields?.attachments?.length === 0}
                              loadingPosition="end"
                              variant="contained"
                              sx={{
                                backgroundColor: color.secondary,
                              }}
                            >
                              <span>Add</span>
                            </LoadingButton>
                          </div>
                          {/* </div> */}
                        </>
                      )}

                      {/* show attachment */}
                      {(attachments?.length !== 0 && (
                        <div className="show-attachments-content my-3 mx-1">
                          {/* attchment header */}
                          <header className="pb-2 previous-comments-header">
                            Attachments
                            {(attachments.length > 1
                              ? `${" - (" + attachments.length + " Files)"}`
                              : attachments.length === 1
                              ? " - (1 File)"
                              : "") || ""}
                          </header>

                          {/* showing attchment details */}
                          <div className="">
                            <ShowAttachments
                              attachments={attachments}
                              handleFilePreview={handleFilePreview}
                              handleDownloadBtn={handleDownloadBtn}
                              handleDeleteBtn={handleDeleteBtn}
                              isSmallScreen={isSmallScreen}
                              showDownload={true}
                              dataLoading={dataLoading}
                              isDataWithTag={false}
                              color={color}
                            />
                          </div>
                        </div>
                      )) || (
                        <div className="mx-3 my-4" style={{ height: "30vh" }}>
                          <Placeholder
                            message="No Attachment Added Yet."
                            removeBoxShadow={true}
                          />
                        </div>
                      )}

                      {/* showing the attachment details in model */}
                      {
                        <div>
                          {(showPreview || sheetData) && (
                            <div>
                              <ShowPreview
                                showModal={showPreview}
                                handleClosePreview={handleClosePreview}
                                sheetData={sheetData}
                                previewData={preview}
                                isSmallScreen={isSmallScreen}
                                color={color}
                              />
                            </div>
                          )}
                        </div>
                      }
                    </div>
                    {confirmDeletionState?.isOpen && (
                      <ConfirmDeletionModal
                        deleteModalState={confirmDeletionState}
                        handleClose={closeConfirmDelectionModal}
                        handleReload={handleReloadData}
                      />
                    )}
                  </>
                )) ||
                (showModalError.status && (
                  <ModalError modelHeight={"50vh"} />
                )) || <ModalError code={500} />}
            </div>
          </div>
          {/* Modal Body ends .... */}
        </Box>
      </Modal>
    </>
  );
};
const EditStageModal = ({
  stageModalData,
  setShowModal,
  showModal,
  color,
  getTimeSring,
  hoursDifference,
  handelsetStageModalOpen,
  queryStartTime,
  productID,
  fetchDataAndUpdateFilters,
  refresgProductDetails,
}) => {
  // alter files
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  // const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  const theme = useTheme();
  const {
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    controllerRef,
  } = useContext(ContextState);

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  const [selectStage, setSelectStage] = useState({
    value: "",
    stage: "",
    id: "",
    error: false,
  });
  const [selectStageOption, setSelectStageOption] = useState("");
  const [prevSelectedStage, setPrevSelectedStage] = useState("");

  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    if (stageModalData) {
      const prevStageData = stageModalData["current_selected_stage"];
      handelSelectStage(
        JSON.stringify(prevStageData),
        prevStageData.stage,
        prevStageData.id
      );
      setPrevSelectedStage(prevStageData);
      setSelectStageOption(stageModalData["all_product_stage"]);
      setShowLoading(false);
    }
  }, [stageModalData]);

  const handelSelectStage = (value, stage, id) => {
    setSelectStage((prev) => ({ ...prev, value: value, stage: stage, id: id }));
  };
  const handelSelectStageError = (error) => {
    setSelectStage((prev) => ({ ...prev, error: error }));
  };
  const handleChange = (event) => {
    const jsonRawString = event.target.value;
    const selectedValue = JSON.parse(jsonRawString);
    if (selectedValue) {
      handelSelectStage(jsonRawString, selectedValue.stage, selectedValue.id);
    } else {
      handelSelectStage("", null, null);
    }
  };

  const [showStageChangeLoading, setShowStageChangeLoading] = useState(false);
  const handelSubmit = async () => {
    let isMounted = true;
    // handleButtonClick("general_deatils")
    setShowStageChangeLoading(true);
    // gets its id then send to db
    if (!selectStage.stage || selectStage.stage === null) {
      handelSelectStageError(true);
      openSnackbar("Kindly select a stage to proceed.", "warning");
    } else {
      try {
        let payload = {
          product_id: productID,
          new_stage: selectStage.stage,
          stage_id: selectStage.id,
          query_start_time: queryStartTime,
        };

        // if (refreshBtn) {
        //   setRefreshLoading(true);
        // }

        const jsonData = await fetchApi(
          "edit_product_info",
          "POST",
          payload,
          true,
          2
        );
        if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
          if (isMounted) {
            openSnackbar(jsonData?.data?.message, "success");
            fetchDataAndUpdateFilters();
            refresgProductDetails();
          }
        } else if (
          jsonData?.statusCode === 500 ||
          jsonData?.statusCode === 401
        ) {
          openSnackbar(jsonData?.msg, "error");
          // showModalErrorPage(jsonData.statusCode, jsonData.msg);
          // showLoadingModal(false);
        } else {
          openSnackbar("Opps!! Somthing went wrong ... ", "warning");
          // showModalErrorPage(jsonData.statusCode, jsonData.msg);
        }
      } catch (error) {
        if (controllerRef.current) {
          // openSnackbar("Request Timed Out!!!", "warning");
          console.log("fetch abort in attchemnts ... ");
        } else {
          openSnackbar("Some Error Occured!", "error");
        }
      } finally {
        handelsetStageModalOpen(false);
      }
    }
    setShowStageChangeLoading(false);

    return () => {
      isMounted = false;
    };
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "30%",
            left: "0",
            margin: "  auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "500px",
            padding: "10px",
            minHeight: "350px",
          }}
        >
          {/* Modal Header */}
          <div
            className="d-flex flex-column gap-3 position-relative py-2 pt-4"
            style={{
              // padding: "12px 18px",
              // borderBottom:
              // "1px solid" + color.taxtHeadingLight.slice(0, -2) + "33",
              position: "relative",
              zIndex: 1000,
            }}
          >
            <div className="d-flex justify-content-center">
              <p
                className="m-0 p-0 text-center"
                style={{
                  fontFamily: "Open-Sans-regular",
                  fontWeight: 600,
                  fontSize:
                    (isSmallSmallScreen && "1rem") ||
                    (isMediumScreen && "1.25rem") ||
                    "1.5rem",
                  color: color.taxtHeading,
                }}
              >
                Edit Stage
              </p>
            </div>
            <div
              className="d-flex justify-content-center align-items-center position-absolute top-0 end-0 p-4"
              onClick={() => {
                handelsetStageModalOpen(false);
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
              {/* <button
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  color:color.taxtHeadingLight
                }}
              >
                X
              </button> */}
            </div>
          </div>
          {/* Modal Header ends... */}

          {/* Modal Body Starts .... */}
          <div>
            <div className="main-attachment-container px-3">
              {((lodingModalVisiblity || showLoading) && (
                // {lodingVisiblity && (
                // <div
                //   className="showSpinner "
                //   style={{
                //     position: "absolute",
                //     height: "100%",
                //     width: "100%",
                //   }}
                // >
                //   <BeatLoader color="#2D97D4" />
                // </div>
                <div className="mx-3 my-3">
                  <CardPagesSkeleton
                    len={1}
                    cardHeight={40}
                    componentClass={"issue-type-row-div"}
                  />
                  <CardPagesSkeleton
                    len={1}
                    cardHeight={400}
                    componentClass={""}
                  />
                </div>
              )) ||
                (!showModalError.status && stageModalData && (
                  <>
                    <div className="d-flex flex-column gap-4 justify-content-between">
                      <div className="text-center">
                        Choose the appropriate stage for Product.
                      </div>
                      {/* select stage to change */}
                      <div className="position-relative mb-2">
                        <div className="d-flex flex-row gap-3 justify-content-center align-itemd-center">
                          <Box
                            sx={{
                              minWidth: 120,
                              flexGrow: 1,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                error={selectStage.error}
                                sx={{
                                  transform: "translate(14px, 8px) scale(1)", // Adjust when label is floating
                                  "&.MuiInputLabel-shrink": {
                                    transform:
                                      "translate(14px, -10px) scale(0.75)", // Adjust when label shrinks
                                  },
                                }}
                              >
                                Stage
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectStage.value}
                                label="stage"
                                onChange={handleChange}
                                sx={{
                                  height: 40, // Set desired height
                                  "& .MuiSelect-select": {
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                  },
                                }}
                              >
                                <MenuItem value={null}>None</MenuItem>
                                {selectStageOption.map((value, idx) => (
                                  <MenuItem
                                    key={idx}
                                    value={JSON.stringify(value)}
                                  >
                                    {value.stage}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                          <div className="d-flex align-item-center justify-content-center">
                            <LoadingButton
                              size="medium"
                              onClick={handelSubmit}
                              endIcon={<SendIcon />}
                              loading={showStageChangeLoading}
                              className="my-1"
                              disabled={
                                selectStage?.stage &&
                                selectStage.stage.toLowerCase() ===
                                  prevSelectedStage?.stage?.toLowerCase()
                              }
                              loadingPosition="end"
                              variant="contained"
                              sx={{
                                backgroundColor: color.secondary,
                                fontSize: "0.85rem",
                                padding: "4px 10px",
                                "&:hover": {
                                  color: color.secondary,
                                  borderColor: color.secondary,
                                  backgroundColor: color.iconBackgroundBlue,
                                },
                              }}
                            >
                              <span
                                style={{
                                  lineHeight: "0.95rem",
                                  textTransform: "capitalize",
                                }}
                              >
                                Submit
                              </span>
                            </LoadingButton>
                          </div>
                        </div>
                        <div
                          className="position-absolute  text-center"
                          style={{
                            bottom: "-20px",
                            left: "0px",
                            color: color.taxtHeadingLight,
                            fontSize: "0.75rem",
                          }}
                        >
                          {/* *Any changes to the current stage will be final and cannot be reversed. */}
                          *Changes to the current stage are final and
                          irreversible.
                        </div>
                      </div>
                      {/* divider */}
                      <div class="barChartDivDescHorizontalSeperator" />
                      {/*  current status */}
                      <div
                        className="d-flex flex-column gap-3 my-2 mb-4"
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 800,
                          fontSize: "0.89rem",
                          color: color.taxtHeadingLight,
                        }}
                      >
                        {/* <div>Current Stage Details</div> */}
                        <div
                          className="d-flex flex-row gap-2  flex-grow-1 justify-content-evenly"
                          style={{ height: "80px" }}
                        >
                          {Object.entries({
                            current_stage: prevSelectedStage.stage,
                            last_updated: getTimeSring.slice(12),
                          }).map(([key, value], idx) => {
                            return (
                              <>
                                <div
                                  key={value}
                                  className="p-0  d-inline d-flex flex-column gap-1 h-100 justify-content-between"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {(key === "current_stage" && (
                                    <div className="flex-grow-1  d-flex align-items-center justify-content-center">
                                      <span
                                        style={{
                                          fontFamily: "Open-Sans-regular",
                                          fontWeight: 700,
                                          color: findColorByStatus(value),
                                          fontSize: "1.25rem",
                                          backgroundColor:
                                            findColorByStatus(value) + "33",
                                          borderRadius: "30px",
                                          // borderRadius: "25%",
                                          padding: "5px 15px",
                                        }}
                                      >
                                        {value}
                                      </span>
                                    </div>
                                  )) ||
                                    (key === "last_updated" && (
                                      <div
                                        className="d-inline flex-grow-1 d-flex align-items-center justify-content-center "
                                        style={{ fontSize: "1.25rem" }}
                                      >
                                        {(hoursDifference > 24 && (
                                          <span className="badge bg-warning-subtle text-warning-emphasis rounded-pill px-2 ">
                                            {value}
                                          </span>
                                        )) || (
                                          <span className="badge bg-success-subtle text-success-emphasis rounded-pill  px-2">
                                            {value}
                                          </span>
                                        )}
                                      </div>
                                    ))}
                                  <div
                                    style={{
                                      color: color.taxtHeading,
                                      textAlign: "center",
                                    }}
                                  >
                                    {key.replace("_", " ")}
                                  </div>
                                </div>

                                {idx === 0 && (
                                  <div class="barChartDivDescVerticalSeperator"></div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )) ||
                (showModalError.status && <ModalError />) || (
                  <ModalError code={500} />
                )}
            </div>
          </div>
          {/* Modal Body ends .... */}
        </Box>
      </Modal>
    </>
  );
};

export default function ProductGeneralContent({
  reloadGeneralPage,
  reloadAllTicketsData,
  refreshBtn,
  // setRefreshBtn,
  // setRefreshLoading,
  productType,
  pageStyling,
}) {
  const { data, color, refresgProductDetails } = useOutletContext();
  const { productId } = data;

  // const [stateO, setStateO] = useState(false)
  const [reloadData, setReloadData] = useState(false);
  const [queryStartTime, setQueryStartTime] = useState(null);
  const {
    fetchApi,
    url,
    formatDate,
    ticketStatus,
    handleSetTicketStatus,
    openSnackbar,
    showError,
    showErrorPage,
    showModalErrorPage,
    modalControllerRef,
  } = useContext(ContextState);

  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );

  // console.log("efjoeinfw===", filterData);
  // showing modal
  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  const [edit, setEdit] = useState(false);
  const [markResolved, setMarkResolved] = useState(false);

  const [generalData, setGeneralData] = useState(null);
  const [status, setStatus] = useState("");
  const [issueType, setIssueType] = useState("");
  const [subIssueType, setSubIssueType] = useState("");
  const [resolutionDetails, setResolutionDetails] = useState("");
  const [assignTask, setAssignTask] = useState(null);
  const [internetStatus, setInternetStatus] = useState(false);
  const [description, setDescription] = useState("");
  const [visitRequired, setVisitRequired] = useState(false);

  // state variable to store the status to enable/disable the resolved button
  const [enabled, setEnabled] = useState(false);

  // Employee Details Edit state

  const [edited_Emp_Details, setEdited_Emp_Details] = useState({
    employee_name: "",
    employee_phone: "",
    employee_email: "",
    employee_id: "",
    employee_designation: "",
    level: "",
    location_name: "",
  });

  const [loadingSpinner, setLoadingSpinner] = useState(false);
  // Employee Edit details modal statet

  const [employeeEditModal, setEmployeeEditModal] = useState({
    modalState: false,
  });
  const [area_data, setArea_data] = useState([]);
  const theme = useTheme();
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const handleChange = (event, setStateFunction) => {
    setStateFunction(event.target.value);
  };

  // Function to capitalize first letter
  // const capitalizeFirstLetter = (str) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };

  let statusClass;
  switch (generalData && generalData["Status"]) {
    case "pending":
      statusClass = "pending-color";
      break;
    case "ongoing":
      statusClass = "ongoing-color";
      break;
    case "resolved":
      statusClass = "resolved-color";
      break;
    case "validation":
      statusClass = "validation-color";
      break;
    default:
      statusClass = "ongoing-color";
  }

  // Edit details function starts here............

  const [uploads, setUploads] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleFileUpload = (e, rowData) => {
    setSelectedRow(rowData);
    setShowModal(true);
  };

  const handlePreview = (fileData) => {
    setSelectedFile(fileData);
  };

  const [tableWidthDiv, setTableWidthDiv] = useState(0);

  useEffect(() => {
    const waitForElement = () => {
      const tableWidthDiv = document.querySelector(".tableWidthDiv");
      if (tableWidthDiv) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setTableWidthDiv(entry.contentRect.width);
          }
        });
        resizeObserver.observe(tableWidthDiv);

        // Clean up observer on unmount
        return () => resizeObserver.disconnect();
      } else {
        // Try again after a short delay if the element isn't found
        setTimeout(waitForElement, 100);
      }
    };

    // Start the initial check
    waitForElement();
  }, []);

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      // headers.push({
      //   field: "expand",
      //   headerName: "",
      //   width: 50,
      //   editable: false,
      //   sortable: false, // Disable sorting for this column
      //   filterable: false, // Disable filtering for this column
      //   disableColumnMenu: true,
      //   renderCell: (params) => {
      //     return (
      //       <Box
      //         sx={{
      //           // width: !index ? 400 : 150, // Adjust width as needed
      //           //   marginLeft: "5px", // Add margin to separate text from progress bar
      //           //   fontWeight: "bold", // Customize font weight if needed
      //           textAlign: "center",
      //           fontFamily: "Open-Sans-Medium",
      //           fontWeight: "700",
      //           fontSize: "1.05rem",
      //           // color:
      //           //   (item.field === "status" &&
      //           //     ((params.value === "rejected" && color.red) ||
      //           //       (params.value === "accepted" && color.greenToShow))) ||
      //           //   (params.row["is_rejected"][item.field] && color.red) ||
      //           //   color.tableRowHeading,
      //           textWrap: "nowrap",
      //         }}
      //       >
      //         {
      //           <MuiTooltip
      //             title={
      //               <>
      //                 <div
      //                   style={{
      //                     fontSize: "0.85rem",
      //                     fontFamily: "Open-Sans-Medium",
      //                   }}
      //                 >
      //                   More Details
      //                 </div>
      //               </>
      //             }
      //             arrow
      //           >
      //             <span
      //               style={{
      //                 // border: "2px solid blue",
      //                 padding: "3px 9px",
      //                 borderRadius: "4px",
      //                 backgroundColor: "transparent",
      //                 cursor: "pointer",
      //                 textTransform: "none",
      //                 cursor: "pointer",
      //               }}
      //               onClick={() => {
      //                 console.log(params.row);
      //                 // navigate(`${params.row.product_id}`);
      //                 // if (params.row) {
      //                 //   handleTableRowClick(params.row);
      //                 // }
      //               }}
      //             >
      //               <FaPlus />
      //             </span>
      //           </MuiTooltip>
      //         }
      //       </Box>
      //     );
      //   },
      // });
      data.column.forEach((item, index) => {
        // if (!["region", "district", "location"].includes(item.field)) {
        headers.push({
          field: item.field,
          headerName: item.headerName,
          width: item.width,
          editable: item.editable,
          // flex: !isSmallScreen,
          flex: tableWidthDiv > 500 ? item.flex : 0,
          // !isSmallScreen && !["product_id", "status"].includes(item.field),
          headerAlign: ![1].includes(index) ? "center" : "left",
          resizable: item.resizable,
          disableColumnMenu: true,
          editable: false,
          sortable: false,
          filterable: false,
          renderHeader: (params) => (
            <MuiTooltip
              title={`${item.field
                .replace(/_/g, " ")
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}`}
              arrow
            >
              <span
                style={{
                  paddingLeft: ([1].includes(index) && "10px") || "0px",
                }}
              >
                {params.colDef.headerName}
              </span>
            </MuiTooltip>
          ),
          renderCell: (params) => {
            // console.log("params-->: ", params);
            return (
              <Box
                sx={{
                  // width: !index ? 400 : 150, // Adjust width as needed
                  //   marginLeft: "5px", // Add margin to separate text from progress bar
                  //   fontWeight: "bold", // Customize font weight if needed
                  textAlign: ![1].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  fontWeight:
                    (item.field === "status" && "600") ||
                    (index !== 0 && "500") ||
                    "700",
                  fontSize: item.field === "status" ? "0.785rem" : "1.05rem",
                  color: color.tableRowHeading,
                  textWrap: "nowrap",
                }}
              >
                {
                  <MuiTooltip
                    title={
                      <>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            fontFamily: "Open-Sans-Medium",
                          }}
                        >
                          <div>
                            <strong style={{ textTransform: "capitalize" }}>
                              {params.field.split("_").join(" ")}
                            </strong>
                            :{" "}
                            <strong
                              style={{
                                textTransform:
                                  (item.field === "status" && "capitalize") ||
                                  "none",
                              }}
                            >
                              {(item.field === "creation_time" &&
                                formatDate(params.value)) ||
                                (item.field === "last_updated" &&
                                  formatDate(params.value)) ||
                                params.value}
                            </strong>
                          </div>
                          {item.upper !== undefined && (
                            <p
                              className="m-0 p-0"
                              style={{
                                fontSize: "0.65rem",
                              }}
                            >
                              Upper Limit : {item.upper}
                            </p>
                          )}
                          {item.lower !== undefined && (
                            <p
                              className="m-0 p-0"
                              style={{
                                fontSize: "0.65rem",
                              }}
                            >
                              Lower Limit : {item.lower}{" "}
                            </p>
                          )}
                        </div>
                      </>
                    }
                    arrow
                  >
                    {(item.field === "checkpoint" && (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding: "0px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform: "none",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          height: "50px",
                        }}
                      >
                        {/* <div className="product_img m-0 p-0 ">
                          <img
                            src={test_profile_img}
                            alt="product_img"
                            style={{ height: "40px", width: "40px" }}
                          />
                        </div> */}
                        <div
                          className="product_full_desc d-flex flex-column  p-0 m-0 lh-sm"
                          style={{
                            fontFamily: "Open-Sans-Light",
                            color: color.taxtHeading,
                            fontWeight: 600,
                            width: "100%",
                          }}
                        >
                          <div
                            className="product_name p-0 m-0 "
                            // style={{ height: "20px" }}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {params.value}
                          </div>
                          {false && (
                            <div
                              className="product_desc p-0 m-0"
                              style={{
                                width: "90%",
                                fontWeight: 400,
                                fontSize: "0.89rem",
                                overflow: "hidden", // Hide overflow
                                textOverflow: "ellipsis", // Show ellipsis
                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                            >
                              Product_desc/use facdcdcccvevevdvdhvbdjvdvvbjv
                            </div>
                          )}
                        </div>
                      </span>
                    )) || (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding:
                            (item.field === "status" && "5px 9px") || "4px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform:
                            (["status", "reason"].includes(item.field) &&
                              "capitalize") ||
                            "none",
                          width: "100px",
                        }}
                      >
                        {(item.field === "created_date" &&
                          formatDate(params.value, true)) ||
                          (item.field === "updated_date" &&
                            formatDate(params.value, true)) ||
                          params.value}
                      </span>
                    )}
                  </MuiTooltip>
                }
              </Box>
            );
          },
        });
        // }
      });
      headers.push({
        field: "fileUpload",
        headerName: "Upload File",
        headerAlign: "center",
        width: 150,
        // flex: tableWidthDiv > 1000 ? 1 : 0,
        flex: 0,
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderHeader: (params) => (
          <MuiTooltip title={`Upload File`} arrow>
            <span
              style={{
                paddingLeft: "0px",
              }}
            >
              Upload File
            </span>
          </MuiTooltip>
        ),
        renderCell: (params) => (
          <Box
            sx={{
              textAlign: "center",
              fontFamily: "Open-Sans-Medium",
              fontWeight: "700",
              fontSize: "1.05rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              color: color.taxtHeadingLight,

              "&:hover": {
                color: color.secondary,
              },
            }}
          >
            <div
              className="d-flex flex-row gap-1  align-items-center justify-content-start h-100"
              onClick={(e) => handleFileUpload(e, params.row)}
              style={{ cursor: "pointer", width: "150px", paddingLeft: "10px" }}
            >
              <div>
                <MuiTooltip
                  title={
                    <div
                      style={{
                        fontSize: "0.85rem",
                        fontFamily: "Open-Sans-Medium",
                      }}
                    >
                      Choose File
                    </div>
                  }
                  arrow
                >
                  {/* <label
                  htmlFor={`file-upload-${params.id}`}
                  style={{
                    cursor: "pointer",
                    color: "#007bff",
                    display: "flex",
                    alignItems: "center",
                    }}
                    >
                    <FaUpload style={{ margin: "15px" }} />
                    </label> */}
                  {/* <Button
                variant="outlined"
                color="primary"
                
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  }}
              > */}
                  <FaUpload
                    src={cheklistFileUploadImg}
                    alt="file_upload"
                    style={{
                      marginRight: "5px",
                      marginBottom: "2px",
                      color: color.secondary,
                    }}
                  />
                  {/* <img
                    onClick={(e) => handleFileUpload(e, params.row)}
                    src={cheklistFileUploadImg}
                    alt="file_upload"
                    style={{ height: "auto", width: "30px", cursor: "pointer" }}
                  /> */}
                  {/* </Button> */}
                </MuiTooltip>
              </div>
              {/* <input
                id={`file-upload-${params.id}`}
                type="file"
                style={{ display: "none" }}
              />
              {uploads[params.row.id] && (
                <span style={{ fontSize: "0.9rem", marginTop: "8px" }}>
                {uploads[params.row.id].name}
                </span>
                )} */}
              <p className="p-0 m-0" style={{ fontSize: "0.85rem" }}>
                {` ${
                  (params.row.attchments_count > 1 &&
                    params.row.attchments_count + " Files Uploaded") ||
                  (params.row.attchments_count === 1 && "1 File Uploaded") ||
                  "No Files Added"
                }`}{" "}
              </p>
            </div>
          </Box>
        ),
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const [stageDataLoading, setStageDataLoading] = useState(true);
  const [stageData, setStageData] = useState(null);

  const { TableDataHeaders, TabelDataRows } = useMemo(() => {
    if (
      stageData?.checklist_table_data &&
      stageData?.checklist_table_data?.rows?.length !== 0
    ) {
      return getFirstPageDataTableDataHeader({
        rows: stageData?.checklist_table_data?.rows || [],
        column: stageData?.checklist_table_data?.column || [],
      });
    }
    return { TableDataHeaders: [], TabelDataRows: [] };
  }, [
    JSON.stringify(stageData?.checklist_table_data?.rows),
    JSON.stringify(stageData?.checklist_table_data?.column),
    isSmallScreen,
    uploads,
    selectedRowIds,
    tableWidthDiv,
    // stageDataLoading
  ]);

  const rowHeight = 50; // Default row height in DataGrid
  const headerHeight = 105; // Default header height in DataGrid
  const maxRows = 5; // Maximum number of rows per page
  const visibleRows = Math.max(
    stageData?.checklist_table_data?.rows?.length || 0,
    maxRows
  );
  const gridHeight =
    headerHeight +
    rowHeight *
      (visibleRows > 100
        ? 104.08
        : visibleRows > 50
        ? visibleRows + 4.75
        : visibleRows > 20
        ? visibleRows + 2.5
        : visibleRows) +
    (visibleRows > 100 ? 52 : 0); // Added 52 for pagination height

  const [disableSubmitChecklistButton, setDisableSubmitChecklistButton] =
    useState(true);

  const getTimeStr = (lastUpdateTime) => {
    // last updated time for stage
    const currentTime = new Date();
    const inputDate = lastUpdateTime.replace("GMT", "GMT+0530");
    const TicketCeratedTime = new Date(inputDate);
    // Calculate the difference in milliseconds
    const timeDifferenceInMilliseconds = currentTime - TicketCeratedTime;
    // console.log(
    //   currentTime,
    //   ticket.creation_time,
    //   TicketCeratedTime,
    //   timeDifferenceInMilliseconds,
    //   ticket.ticket_id
    // );
    let CreatedTime = "Last Updated ";

    // if (support_state !== 'done') {
    const minutesDifference = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );

    if (timeDifferenceInMilliseconds < 0) {
      CreatedTime += 0 + " minutes ago";
    } else {
      if (minutesDifference < 60) {
        CreatedTime += minutesDifference + " minutes ago";
      } else {
        const hoursDifference = Math.floor(minutesDifference / 60);

        if (hoursDifference < 24) {
          CreatedTime += hoursDifference + " hours ago";
        } else {
          const daysDifference = Math.floor(hoursDifference / 24);

          if (daysDifference < 7) {
            CreatedTime += daysDifference + " days ago";
          } else {
            const weeksDifference = Math.floor(daysDifference / 7);

            if (weeksDifference < 4) {
              CreatedTime += weeksDifference + " weeks ago";
            } else {
              const monthsDifference = Math.floor(daysDifference / 30);

              if (monthsDifference < 12) {
                CreatedTime += monthsDifference + " months ago";
              } else {
                const yearsDifference = Math.floor(daysDifference / 365);
                CreatedTime += yearsDifference + " years ago";
              }
            }
          }
        }
      }
    }

    const hoursDifference = Math.floor(minutesDifference / 60);
    return {
      hoursDifference,
      CreatedTime,
    };
  };

  const [stageModal, setStageModal] = useState({
    open: false,
    conformation: false,
    data: null,
  });

  const handelsetStageModalOpen = (open) => {
    setStageModal((prevData) => ({
      ...prevData,
      open: open,
    }));
  };
  const handelsetStageModalConformation = (conformation) => {
    setStageModal((prevData) => ({
      ...prevData,
      conformation: conformation,
    }));
  };
  const handelsetStageModalData = (data) => {
    setStageModal((prevData) => ({
      ...prevData,
      data: data,
    }));
  };

  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState(null);

  const timeInfo =
    Data &&
    Data?.product_stage_details?.last_updated_date &&
    getTimeStr(Data?.product_stage_details?.last_updated_date);
  // const timeInfo = getTimeStr(data.);
  const { getTimeSring, hoursDifference } = timeInfo
    ? {
        getTimeSring: timeInfo.CreatedTime || null, // Fallback to null if not available
        hoursDifference: timeInfo.hoursDifference || null, // Fallback to null if not available
      }
    : { getTimeSring: null, hoursDifference: null }; // Fallback when timeInfo is null/undefined

  // console.log("getTimeSring, hoursDifference :", getTimeSring, hoursDifference);
  const fetchSatgeEventDataAndUpdateStageData = async (
    checkListName,
    showErrorMsg = false
  ) => {
    // fetchFiltersDataApiCall
    setStageDataLoading(true);
    setStageData(null);
    let is_product_stage_event_data_Mounted = true;
    const payload = {
      product_id: productId,
      info_type: "checklist",
      stage_event_name: checkListName,
    };
    const api_data = await fetchApi(
      "get_product_info",
      "POST",
      payload,
      is_product_stage_event_data_Mounted,
      2
    );
    if (api_data.statusCode === 200) {
      setStageData(api_data?.data);
      setSelectedRowIds(api_data?.data?.checked_array);
      // showErrorPage(null, null);
    } else if (api_data.statusCode === 500 || api_data.statusCode === 401) {
      setStageData(null);
      if (showErrorMsg) {
        openSnackbar(api_data.msg || "", "warning");
      }
      // showErrorPage(api_data.statusCode, api_data.msg);
    } else {
      setStageData(null);
      // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
      // console.log("Some Error Occured", all_filter_data);
      localStorage.clear();
      window.location.href = "/login";
    }
    setStageDataLoading(false);

    return () => {
      is_product_stage_event_data_Mounted = false;
    };
  };
  const fetchDataAndUpdateProductGenralData = async (isReload = true) => {
    // fetchFiltersDataApiCall
    if (isReload) {
      setLoading(true);
      setData(null);
    }

    let startTime = new Date();
    startTime = startTime.toString().split(" (")[0];
    // console.log("startTime : ", startTime);
    setQueryStartTime(startTime);

    let is_product_data_Mounted = true;
    const payload = {
      product_id: productId,
      info_type: "general",
    };
    const api_data = await fetchApi(
      "get_product_info",
      "POST",
      payload,
      is_product_data_Mounted,
      1
    );
    // console.log("all_filter_data in product_data_Mounted : ", api_data);
    if (api_data.statusCode === 200) {
      setData(api_data?.data?.gen_details || null);
      handelsetStageModalData(
        api_data?.data?.gen_details?.stage_model_data || null
      );
      showErrorPage(null, null);
    } else if (api_data.statusCode === 500 || api_data.statusCode === 401) {
      setData(null);
      showErrorPage(api_data.statusCode, api_data.msg);
    } else {
      setData(null);
      // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
      // console.log("Some Error Occured", all_filter_data);
      localStorage.clear();
      window.location.href = "/login";
    }
    setLoading(false);

    return () => {
      is_product_data_Mounted = false;
    };
  };
  useEffect(() => {
    document.title = `General Details of Product ID#${productId} - NEO`;
    fetchDataAndUpdateProductGenralData();
    // console.log("data set to empty List - 2");
  }, []);

  const [checklistSubmitButtonLoading, setChecklistSubmitButtonLoading] =
    useState(false);

  const [expandedAccordian, setExpandedAccordian] = React.useState("");
  const handelChecklistSubmit = async (checkListName) => {
    setChecklistSubmitButtonLoading(true);
    const prevSelectedTableRowIds = stageData.checked_array;
    const currentSelectedTableRowIds = selectedRowIds;

    //  ids which are marked unchecked by the new user
    const trackUnselect = prevSelectedTableRowIds.filter(
      (item) => !currentSelectedTableRowIds.includes(item)
    );
    const modifiedTrackIdsList = stageData?.checklist_table_data?.rows
      ?.filter(
        (item) =>
          currentSelectedTableRowIds.includes(item.id) &&
          !prevSelectedTableRowIds.includes(item.id)
      )
      .map((item, idx) => item.c_track_id);
    const markUncheckedTrackIdsList = stageData?.checklist_table_data?.rows
      ?.filter((item) => trackUnselect.includes(item.id))
      .map((item, idx) => item.c_track_id);

    let is_product_data_Mounted = true;
    const payload = {
      product_id: productId,
      info_type: "checklist",
      action: "submit",
      query_start_time: queryStartTime,
      checklist_track_ids: {
        marked_checked: modifiedTrackIdsList,
        marked_unchecked: markUncheckedTrackIdsList,
      },
    };
    const api_data = await fetchApi(
      "edit_product_info",
      "POST",
      payload,
      is_product_data_Mounted
    );
    if (api_data.statusCode === 200) {
      openSnackbar(api_data?.data?.message || "Successful", "success");
      fetchDataAndUpdateProductGenralData(false);
      refresgProductDetails();
      fetchSatgeEventDataAndUpdateStageData(checkListName, true);
    } else if (api_data.statusCode === 500 || api_data.statusCode === 401) {
      openSnackbar(api_data.msg || "Error", "error");
      // showErrorPage(api_data.statusCode, api_data.msg);
    } else {
      openSnackbar("Opps! Api error , Have a Good Day ...", "warning");
      // setData(null);
      // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
      // console.log("Some Error Occured", all_filter_data);
      // localStorage.clear();
      // window.location.href = "/login";
    }
    // fetchSatgeEventDataAndUpdateStageData(checkListName = checkListName);
    setChecklistSubmitButtonLoading(false);

    return () => {
      is_product_data_Mounted = false;
    };
  };

  const areArraysEqual = (a, b) =>
    a && b && a.length === b.length && a.sort().join() === b.sort().join();

  return (
    <>
      {(loading && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "absolute", height: "100%", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <div className="">
          <CardPagesSkeleton len={1} cardHeight={300} componentClass="" />
          <CardPagesSkeleton len={1} cardHeight={300} componentClass="" />
          <CardPagesSkeleton len={1} cardHeight={300} componentClass="" />
        </div>
      )) ||
        (!showError.status && Data && (
          <>
            <div className="">
              <div className="my-1 position-relative d-flex flex-column gap-1 row-gap-2">
                {/* product type Details & product stage details  */}
                <div className="mb-2">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                      rowGap: "15px",
                      height: "100%",
                    }}
                  >
                    {/* product type Details */}
                    <div
                      style={{
                        flexBasis: "0",
                        flexShrink: 1,
                        flexGrow: 1,
                        flexBasis: "600px",
                      }}
                    >
                      <div className="card-Div h-100">
                        <div className="h-100 d-flex flex-column gap-3 p-2 pb-0">
                          <div
                            className=""
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "0.95rem",
                              color: color.tableRowHeading,
                            }}
                          >
                            Product Type
                          </div>
                          <div className="general_page_main_details p-0 m-0">
                            {/* showing basic details from here */}
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns:
                                  "repeat(auto-fit, minmax(150px , 1fr))",
                                rowGap: "10px",
                              }}
                            >
                              {Object.entries(
                                Data.product_type_details || {}
                              ).map(([key, value]) => (
                                <div
                                  key={key}
                                  className="basic_details_elements "
                                  style={{ maxWidth: "100%" }}
                                >
                                  <label
                                    htmlFor={`outlined-read-only-input-${key}`}
                                    className="basic_details_elements_label"
                                    style={{
                                      fontSize: "0.95rem",
                                    }}
                                  >
                                    {key.replace("_", " ")}
                                  </label>
                                  <div
                                    id={`outlined-read-only-input-${key}`}
                                    className="basic_details_elements_content "
                                    style={{
                                      fontSize: "1.15rem",
                                    }}
                                  >
                                    {value
                                      ? Array.isArray(value)
                                        ? value.map(
                                            ([machineNo, location], index) => (
                                              <div
                                                key={`${machineNo}-${location}`}
                                              >
                                                {machineNo} ({location})
                                              </div>
                                            )
                                          )
                                        : value
                                      : "-"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* product stage Details */}
                    {Data?.product_stage_details && (
                      <div
                        style={{
                          minWidth: "300px",
                          flexGrow: 1,
                          flexBasis: "300px",
                          maxWidth: "100%",
                        }}
                      >
                        <div className="card-Div h-100">
                          <div
                            className="h-100 d-flex flex-column p-2 position-relative justify-content-between"
                            style={{ minHeight: "80px" }}
                          >
                            {/* <div
                              className=""
                              style={{
                                fontFamily: "Open-Sans-Light",
                                fontWeight: 600,
                                fontSize: "0.95rem",
                                color: color.tableRowHeading,
                              }}
                            >
                              Current Stage
                            </div> */}
                            {false && (
                              <div
                                className="d-flex flex-column-reverse gap-1"
                                style={{
                                  fontFamily: "Open-Sans-Light",
                                  fontWeight: 800,
                                  fontSize: "0.759rem",
                                  color: color.taxtHeadingLight,
                                }}
                              >
                                {/* <div>Current Stage</div> */}
                                <p
                                  className="p-0 m-0 d-inline"
                                  style={{
                                    // fontSize: "0.785rem",
                                    fontWeight: 700,
                                    color: findColorByStatus(
                                      Data.product_stage_details.current_stage
                                    ),
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Open-Sans-regular",
                                      fontWeight: 600,
                                      fontSize: "1.395rem",
                                      backgroundColor:
                                        findColorByStatus(
                                          Data.product_stage_details
                                            .current_stage
                                        ) + "33",
                                      borderRadius: "4px",
                                      padding: "4px 9px",
                                    }}
                                  >
                                    {Data.product_stage_details.current_stage}
                                  </span>
                                  {getTimeSring && (
                                    <div className="ps-1 d-inline">
                                      {(hoursDifference > 24 && (
                                        <span className="badge bg-warning-subtle text-warning-emphasis rounded-pill me-auto px-2 ">
                                          {getTimeSring}
                                        </span>
                                      )) || (
                                        <span className="badge bg-success-subtle text-success-emphasis rounded-pill me-auto px-2">
                                          {getTimeSring}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </p>
                              </div>
                            )}

                            <div
                              className="d-flex flex-column gap-3  my-4 mb-0"
                              style={{
                                fontFamily: "Open-Sans-Light",
                                fontWeight: 800,
                                fontSize: "0.89rem",
                                color: color.taxtHeadingLight,
                              }}
                            >
                              <div
                                className="d-flex flex-row gap-2  flex-grow-1 justify-content-evenly"
                                style={{ height: "80px" }}
                              >
                                {Object.entries({
                                  current_stage:
                                    Data.product_stage_details.current_stage,
                                  last_updated: getTimeSring.slice(12),
                                }).map(([key, value], idx) => {
                                  return (
                                    <>
                                      <div
                                        key={value}
                                        className="p-0  d-inline d-flex flex-column gap-1 h-100 justify-content-between"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {(key === "current_stage" && (
                                          <div className="flex-grow-1  d-flex align-items-center justify-content-center">
                                            <span
                                              style={{
                                                fontFamily: "Open-Sans-regular",
                                                fontWeight: 700,
                                                color:
                                                  findColorByStatus("Arrival"),
                                                fontSize: "1.25rem",
                                                backgroundColor:
                                                  findColorByStatus("Arrival") +
                                                  "33",
                                                borderRadius: "30px",
                                                // borderRadius: "25%",
                                                padding: "5px 15px",
                                              }}
                                            >
                                              {value}
                                            </span>
                                          </div>
                                        )) ||
                                          (key === "last_updated" && (
                                            <div
                                              className="d-inline flex-grow-1 d-flex align-items-center justify-content-center "
                                              style={{ fontSize: "1.25rem" }}
                                            >
                                              {(hoursDifference > 24 && (
                                                <span className="badge bg-warning-subtle text-warning-emphasis rounded-pill px-2 ">
                                                  {value}
                                                </span>
                                              )) || (
                                                <span className="badge bg-success-subtle text-success-emphasis rounded-pill  px-2">
                                                  {value}
                                                </span>
                                              )}
                                            </div>
                                          ))}
                                        <div
                                          style={{
                                            color: color.taxtHeading,
                                            textAlign: "center",
                                          }}
                                        >
                                          {key.replace("_", " ")}
                                        </div>
                                      </div>

                                      {idx === 0 && (
                                        <div class="barChartDivDescVerticalSeperator"></div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="edit-product-stage  position-absolute top-0 end-0">
                              <ButtonGroup
                                variant="text"
                                aria-label="Basic button group"
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "8px", // Add the gap between buttons
                                  "& .MuiButton-outlined": {
                                    //   color: color.tableRowHeading,
                                    // border: "none",
                                    fontFamily: "Open-Sans-Medium",
                                    // borderColor: color.secondary,
                                    borderRadius: "5px",
                                    "&:hover": {
                                      color: color.secondary,
                                      borderColor: color.secondary,
                                      backgroundColor: color.iconBackgroundBlue,
                                    },
                                  },
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    // handleButtonClick("general_deatils")
                                    // console.log("open modal", Data);
                                    handelsetStageModalOpen(true);
                                    handelsetStageModalData(
                                      Data.stage_model_data
                                    );
                                  }}
                                  sx={{
                                    // backgroundColor: color.secondary,
                                    color: color.secondary,
                                    fontSize: "0.85rem",
                                    padding: "4px 10px",
                                    //   color: showTableData.general_deatils ? "white" : "red",
                                    textTransform: "capitalize",
                                    "&:hover": {
                                      // border: "none",
                                      // backgroundColor: color.iconBackgroundBlue,
                                    },
                                  }}
                                >
                                  Edit Stage
                                </Button>
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* seller Details */}
                <div className="mb-2">
                  <div className="card-Div ">
                    <div className="d-flex flex-column gap-3 p-2 pb-0 mb-1">
                      <div
                        className=""
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: "0.95rem",
                          color: color.tableRowHeading,
                        }}
                      >
                        Seller Details
                      </div>
                      <div className="general_page_main_details p-0 m-0">
                        {/* showing basic details from here */}
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(250px , 1fr))",
                            rowGap: "20px",
                          }}
                        >
                          {Object.entries(Data.seller_deatils || {}).map(
                            ([key, value]) => (
                              <div
                                key={key}
                                className="basic_details_elements "
                                style={{ maxWidth: "100%" }}
                              >
                                <label
                                  htmlFor={`outlined-read-only-input-${key}`}
                                  className="basic_details_elements_label"
                                  style={{
                                    fontSize: "0.95rem",
                                  }}
                                >
                                  {key.replace("_", " ")}
                                </label>
                                <div
                                  id={`outlined-read-only-input-${key}`}
                                  className="basic_details_elements_content "
                                  style={{
                                    fontSize: "1.15rem",
                                  }}
                                >
                                  {value
                                    ? Array.isArray(value)
                                      ? value.map(([machineNo, location]) => (
                                          <div key={`${machineNo}-${location}`}>
                                            {machineNo} ({location})
                                          </div>
                                        ))
                                      : ("address" === key.toLowerCase() &&
                                          value.slice(0, 30)) ||
                                        value
                                    : "-"}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-1">
                  {/* Lot Details */}
                  <div className="card-Div ">
                    <div className="d-flex flex-column gap-3 p-2 pb-0 mb-1">
                      <div
                        className=""
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: "0.95rem",
                          color: color.tableRowHeading,
                        }}
                      >
                        Lot Details
                      </div>
                      <div className="general_page_main_details p-0 m-0">
                        {/* showing basic details from here */}
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(250px , 1fr))",
                            rowGap: "20px",
                          }}
                        >
                          {Object.entries(Data.lot_details || {}).map(
                            ([key, value]) => (
                              <div
                                key={key}
                                className="basic_details_elements "
                                style={{ maxWidth: "100%" }}
                              >
                                <label
                                  htmlFor={`outlined-read-only-input-${key}`}
                                  className="basic_details_elements_label"
                                  style={{
                                    fontSize: "0.95rem",
                                  }}
                                >
                                  {key.replace("_", " ")}
                                </label>
                                <div
                                  id={`outlined-read-only-input-${key}`}
                                  className="basic_details_elements_content "
                                  style={{
                                    fontSize:
                                      (isSmallScreen && "0.95rem") || "1.15rem",
                                  }}
                                >
                                  {value
                                    ? Array.isArray(value) && value.length > 0
                                      ? value.map(
                                          ([machineNo, location], index) => (
                                            <span
                                              key={`${machineNo}-${location}`}
                                              className="d-block"
                                            >
                                              {machineNo} ({location}){" "}
                                              {value.length > 1 &&
                                                value.length - 1 !== index &&
                                                ", "}
                                            </span>
                                          )
                                        )
                                      : ([
                                          "created_time",
                                          "arrival_date",
                                        ].includes(key) &&
                                          ("arrival_date" === key
                                            ? formatDate(value).slice(0, 15)
                                            : formatDate(value))) ||
                                        value
                                    : "-"}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* stage analysis */}
                {/* make it accordian wise */}
                {Object.entries(Data?.stage_event_data || {}).some(
                  ([key, value]) => value.length !== 0
                ) && (
                  <div className="d-flex flex-column gap-1 ">
                    {/* stage event div title */}
                    {false && (
                      <div
                        className=""
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: "0.95rem",
                          color: color.tableRowHeading,
                        }}
                      >
                        Stage Checklist Form
                      </div>
                    )}

                    {/* <div>Form/Checklist comming soon ...</div> */}
                    {/* checklist Table  */}
                    <div className="mt-1">
                      {Data?.stage_event_data?.checklist?.map(
                        (event_data, idx2) => (
                          <Accordion
                            expanded={
                              expandedAccordian ===
                              `${Data?.stage_event_data?.checklist}_${event_data.stage_event_name}`
                            }
                            onChange={(e, isOpen) => {
                              if (isOpen) {
                                fetchSatgeEventDataAndUpdateStageData(
                                  event_data.stage_event_name
                                );
                                setExpandedAccordian(
                                  `${Data?.stage_event_data?.checklist}_${event_data.stage_event_name}`
                                );
                              } else {
                                setStageData(null);
                                setExpandedAccordian("");
                              }
                            }}
                            style={{
                              marginBottom: "14px", // Adds vertical spacing between accordions
                              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px", // Adds box shadow
                              borderRadius: "8px", // Rounds the corners
                              border: "none",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${idx2}-header-${event_data.stage_event_data}`}
                              id={`panel${idx2}-header-${event_data.stage_event_data}`}
                              style={{
                                padding: "10px 18px",
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-center gap-3 w-100">
                                <Typography
                                  sx={{
                                    // width: "33%",
                                    // flexShrink: 0,
                                    color:
                                      expandedAccordian ===
                                      `${Data?.stage_event_data?.checklist}_${event_data.stage_event_name}`
                                        ? "#666bff"
                                        : "inherit",

                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "Open-Sans-Light",
                                    fontWeight: 500,
                                    fontSize: "1.05rem",
                                    // color: color.tableRowHeading,
                                  }}
                                >
                                  <div className="d-flex align-items-center gap-1">
                                    <GoChecklist
                                      style={{
                                        marginBottom: "2px",
                                        fontSize: "25px",
                                      }}
                                    />
                                    {/* {travel.id} */}
                                    {event_data.display_name}
                                  </div>
                                </Typography>
                                <Typography
                                  className={`${
                                    event_data.all_checked
                                      ? "bg-success-subtle text-success-emphasis"
                                      : "bg-warning-subtle text-warning-emphasis"
                                  } badge rounded-pill px-3 py-2 mx-1`}
                                  sx={{
                                    color: event_data.all_checked
                                      ? "green"
                                      : "#666bff",
                                    fontSize: "0.895rem",
                                    // marginLeft: "auto",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {(expandedAccordian ===
                                    `${Data?.stage_event_data?.checklist}_${event_data.stage_event_name}` &&
                                    event_data.display_status) ||
                                    (event_data.all_checked
                                      ? "Done"
                                      : "Pending")}
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "10px 0px" }}>
                              <div className="tableWidthDiv">
                                {(stageDataLoading && (
                                  <TableSkeleton rowsNum={10} colsNum={4} />
                                )) || (
                                  <Box
                                    sx={{
                                      height: gridHeight,
                                      width: "100%",
                                      // maxWidth: "70vw",
                                    }}
                                  >
                                    <DataGrid
                                      rows={TabelDataRows}
                                      columns={TableDataHeaders}
                                      // pagination={false} // Disable pagination
                                      // pagination={100} // Disable pagination
                                      // autoPageSize // Automatically adjust page size based on available height
                                      density="standard"
                                      paginationMode="client"
                                      // components={{
                                      //   Pagination:
                                      //     visibleRows > 100 ? CustomPagination : null,
                                      // }}
                                      // hideFooterPagination
                                      pageSize={101}
                                      // disableSelectionOnClick
                                      disableRowSelectionOnClick
                                      disableColumnFilter
                                      // onRowClick={(param) => {
                                      //   console.log("row_param : ", param.row);
                                      //   // if (param.row) {
                                      //   //   handleTableRowClick(param.row);
                                      //   // }
                                      // }}
                                      onRowSelectionModelChange={(
                                        selectedRow
                                      ) => {
                                        setSelectedRowIds(selectedRow);
                                        // openSnackbar(
                                        //   "Checkboxes update automatically when you add or delete images.",
                                        //   "warning"
                                        // );
                                        if (
                                          selectedRow.length ===
                                          stageData?.checklist_table_data?.rows
                                            ?.length
                                        ) {
                                          setDisableSubmitChecklistButton(
                                            false
                                          );
                                        } else {
                                          setDisableSubmitChecklistButton(true);
                                        }
                                      }}
                                      checkboxSelection
                                      rowSelectionModel={selectedRowIds} // Pass the selected row IDs here
                                      isRowSelectable={(params) => {
                                        // console.log("diable : ", params.row);
                                        return (
                                          stageData &&
                                          !stageData?.checked_array?.includes(
                                            params.row.id
                                          )
                                        );
                                      }}
                                      //   getRowClassName={(params) => {
                                      //     // console.log(
                                      //     //   "params : ",
                                      //     //   params,
                                      //     //   params.row.id === selectedRow?.id
                                      //     // );
                                      //     return params.row.id === selectedRow?.id ? "selected-row" : "";
                                      //   }}
                                      sx={{
                                        "&.MuiDataGrid-root": {
                                          border: "none",
                                          borderRadius: "5px", // Adjust border radius as needed
                                          overflow: "hidden", // Ensure content respects the border radius,
                                          padding: "0px",
                                          margin: "0px",
                                          marginTop: "-10px",
                                        },
                                        ".MuiDataGrid-columnHeader": {
                                          backgroundColor: color.primary,
                                        },
                                        ".MuiDataGrid-filler": {
                                          backgroundColor: color.textWhiteColor,
                                        },
                                        ".MuiDataGrid-columnHeaderTitle": {
                                          fontFamily: "Open-Sans-Light",
                                          fontWeight: "bold",
                                          padding: "5px 0px",
                                          fontSize: "0.85rem",
                                          textTransform: "capitalize",
                                          color: color.taxtHeading,
                                        },
                                        ".MuiDataGrid-columnHeader--alignCenter":
                                          {
                                            headerAlign: "left",
                                          },
                                        ".MuiDataGrid-row": {
                                          border: "none", // Remove border from rows
                                          backgroundColor: color.textWhiteColor, // Set rows background color to white
                                          "&:hover": {
                                            backgroundColor: color.primary, // Ensure background color remains white on hover
                                            // cursor: "pointer",
                                          },
                                        },
                                        ".MuiDataGrid-footerContainer": {
                                          display:
                                            (visibleRows <= 50 && "none") ||
                                            "inline-block", // Hide the pagination footer
                                          justifyContent: "space-between", // Space out selected row count and pagination controls
                                          alignItems: "center", // Vertically align items in the footer
                                          marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                                          height: "30px",
                                        },
                                        ".MuiTablePagination-root": {
                                          // ".MuiInputBase-root": {
                                          //   display: "none",
                                          // },

                                          // ".MuiTablePagination-selectLabel": {
                                          //   display: "none",
                                          // },
                                          ".MuiToolbar-root": {
                                            marginTop: "0px",
                                          },
                                        },
                                        ".MuiDataGrid-cell": {
                                          // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                                          outline: "none",
                                          // display: "-webkit-box",
                                          // WebkitBoxOrient: "vertical",
                                          // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                          "&:focus": {
                                            outline: "none", // Remove the blue outline when a cell is focused
                                          },
                                        },
                                        "&.selected-row": {
                                          backgroundColor: "red", // Highlight color for the selected row
                                          "&:hover": {
                                            backgroundColor: "red ", // Ensure highlight color remains on hover
                                          },
                                        },
                                        "& .Mui-checked": {
                                          color: color.secondary,
                                        },
                                        // ".MuiDataGrid-selectedRowCount": {
                                        //   display: "none", // Hide the selected row count div
                                        // },
                                        // "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                                        //   {
                                        //     display: "none", // Hide "Select All" checkbox
                                        //   },
                                        ".MuiTablePagination-toolbar": {
                                          // width: "80%",  // Adjust the width here
                                          // margin: "0 auto", // Center align the pagination
                                          // marginLeft:"auto",
                                          padding: "2px 16px", // You can adjust padding as well if needed
                                          marginTop: "-15px",
                                        },
                                      }}
                                    />
                                  </Box>
                                )}
                              </div>
                              {/* submit button */}
                              <div className=" mb-3 me-3 text-end">
                                {(stageDataLoading && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                      height: "100px",
                                    }}
                                  >
                                    <Skeleton
                                      animation="wave"
                                      variant="text"
                                      width={100}
                                    />
                                  </div>
                                )) || (
                                  <LoadingButton
                                    size="large"
                                    onClick={() => {
                                      handelChecklistSubmit(
                                        event_data.stage_event_name
                                      );
                                    }}
                                    endIcon={<SendIcon />}
                                    loading={checklistSubmitButtonLoading}
                                    className="mt-2"
                                    disabled={areArraysEqual(
                                      stageData?.checked_array,
                                      selectedRowIds
                                    )}
                                    loadingPosition="end"
                                    variant="contained"
                                    sx={{
                                      backgroundColor: color.secondary,
                                      fontSize: "0.85rem",
                                      padding: "4px 10px",

                                      "&:hover": {
                                        color: color.secondary,
                                        borderColor: color.secondary,
                                        backgroundColor:
                                          color.iconBackgroundBlue,
                                      },
                                    }}
                                  >
                                    <span
                                      style={{
                                        lineHeight: "0.95rem",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Submit
                                    </span>
                                  </LoadingButton>
                                )}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}
                    </div>

                    {/* for privew of selected file */}
                    {showModal && (
                      <FilePreviewOverlay
                        // fileName={selectedFile.fileName}
                        // fileType={selectedFile.fileType}
                        // imageData={selectedFile.imageData}
                        selectedRow={selectedRow}
                        setShowModal={setShowModal}
                        isSmallScreen={isSmallScreen}
                        color={color}
                        showModal={showModal}
                        data={data}
                        fetchSatgeEventDataAndUpdateStageData={
                          fetchSatgeEventDataAndUpdateStageData
                        }
                        fetchDataAndUpdateProductGenralData={
                          fetchDataAndUpdateProductGenralData
                        }
                        refresgProductDetails={refresgProductDetails}
                      />
                    )}
                  </div>
                )}
              </div>
              {/* stage modal  */}
              {stageModal?.open && (
                <EditStageModal
                  stageModalData={stageModal.data}
                  setShowModal={setStageModal}
                  handelsetStageModalOpen={handelsetStageModalOpen}
                  showModal={stageModal.open}
                  color={color}
                  getTimeSring={getTimeSring}
                  hoursDifference={hoursDifference}
                  queryStartTime={queryStartTime}
                  productID={productId}
                  fetchDataAndUpdateFilters={
                    fetchDataAndUpdateProductGenralData
                  }
                  refresgProductDetails={refresgProductDetails}
                />
              )}
            </div>
          </>
        )) ||
        (showError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
}

export const getSellerDetails = [
  {
    sellerName: "Amazon",
    contactEmail: "xyz@gmail.com",
    contactNumber: "12234567890",
    contactPerson: "John",
    address: "Panjagutta, Hyderabad",
  },
  {
    sellerName: "eBay",
    contactEmail: "contact@ebay.com",
    contactNumber: "12345678901",
    contactPerson: "Jane",
    address: "Banjara Hills, Hyderabad",
  },
  {
    sellerName: "Walmart",
    contactEmail: "info@walmart.com",
    contactNumber: "9876543210",
    contactPerson: "Bob",
    address: "Secunderabad, Hyderabad",
  },
  {
    sellerName: "Best Buy",
    contactEmail: "support@bestbuy.com",
    contactNumber: "5432167890",
    contactPerson: "Alice",
    address: "Kukatpally, Hyderabad",
  },
];

// displaying the seller details
export const DisplaySellerDetails = (props) => {
  const { isSmallScreen, isAcknowledgment, genFields } = props;
  const { formatInIndianRupees } = useContext(ContextState);

  const sellerName = genFields?.sellerDetails?.sellerName;

  const sellerDetails = getSellerDetails.find(
    (item) => sellerName === item.sellerName
  );

  return (
    <>
      {!isAcknowledgment && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr", // Three columns
            gridRowGap: "15px",
          }}
        >
          {[
            "sellerName",
            "contactEmail",
            "contactNumber",
            "contactPerson",
            "address",
          ].map((showValues, index) => (
            <p
              key={`${showValues}-${index}`}
              className="m-0"
              style={{
                gridColumn:
                  showValues === "address" && !isSmallScreen ? "3" : "",
                gridRowStart:
                  showValues === "address" && !isSmallScreen ? "1" : "",
                gridRowEnd:
                  showValues === "address" && !isSmallScreen ? "span 2" : "",
              }}
            >
              <label
                htmlFor={`outlined-read-only-input-${showValues}`}
                className="basic_details_elements_label_data_table"
                style={{
                  textTransform: "capitalize",
                  fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                }}
              >
                {formattingString(showValues)}
              </label>
              <div
                id={`outlined-read-only-input-${showValues}`}
                className="basic_details_elements_data_content"
                style={{
                  fontSize: isSmallScreen ? "0.95rem" : "1rem",
                }}
              >
                {sellerDetails[showValues]}
              </div>
            </p>
          ))}
        </div>
      )}

      {isAcknowledgment && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr 1fr", // four columns
            gridRowGap: "20px",
          }}
        >
          {["invoiceNumber", "arrivalDate", "productCount", "totalAmount"].map(
            (showValues, index) => (
              <p key={`${showValues}-${index}`} className="m-0">
                <label
                  htmlFor={`outlined-read-only-input-${showValues}`}
                  className="basic_details_elements_label_data_table"
                  style={{
                    textTransform: "capitalize",
                    fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                  }}
                >
                  {formattingString(showValues)}
                </label>
                <div
                  id={`outlined-read-only-input-${showValues}`}
                  className="basic_details_elements_data_content"
                  style={{
                    fontSize: isSmallScreen ? "0.95rem" : "1rem",
                  }}
                >
                  {showValues === "arrivalDate"
                    ? dayjs(genFields.sellerDetails[showValues]).format(
                        "DD-MM-YYYY"
                      )
                    : ["productCount", "totalAmount"].includes(showValues)
                    ? formatInIndianRupees(genFields.sellerDetails[showValues])
                    : genFields.sellerDetails[showValues]}
                </div>
              </p>
            )
          )}
        </div>
      )}
    </>
  );
};

// table scaleton
const TableSkeleton = ({ rowsNum, colsNum }) => {
  return (
    <TableContainer>
      <Table>
        {/* Table Header */}
        <TableHead>
          <TableRow>
            {[...Array(colsNum)].map((_, index) => (
              <TableCell key={`header-${index}`}>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* Table Body */}
        <TableBody>
          {[...Array(rowsNum)].map((_, rowIndex) => (
            <TableRow key={`row-${rowIndex}`}>
              {[...Array(colsNum)].map((_, colIndex) => (
                <TableCell key={`row-${rowIndex}-col-${colIndex}`}>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
