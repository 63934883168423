import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
  Tooltip as MuiTooltip,
} from "@mui/material";
import ContextState from "../../contextApi/ContextState";
import { useContext, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { color } from "../../modals/Calllog";
import { AiOutlineDelete } from "react-icons/ai";
import close from "../../../assets/images/close.svg";
import { GrEdit } from "react-icons/gr";
import "../../../assets/styles/AddLotDetailsForm.css";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { Laptop, Monitor } from "@mui/icons-material";
import product_default_img from "../../../../src/assets/images/default_product_hdd_without_background.png";
import { productTypeImg } from "../AddLotDetailsForm/DisplayingProductDetailsTable";

export const DisplayingShipmentProductDetailsTable = (props) => {
  const {
    genFields,
    setGenFields,
    isProductsPage,
    isSmallScreen,
    isSerialNumber,
    isReview,
    tableHeader,
  } = props;
  const { formatInIndianRupees, openSnackbar } = useContext(ContextState);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");

  //   state edit product details

  const [editProductDetailsModal, setEditProductDetailsModal] = useState({
    isOpen: false,
    rowId: "",
  });

  // data for product details data grid table
  const columns = [
    { field: "category", headerName: "Category", width: 180 },
    { field: "product", headerName: "Product", width: 200 },

    isSerialNumber
      ? { field: "serial_number", headerName: "Serial Number", width: 180 }
      : { field: "quantity", headerName: "Quantity", width: 140 },
  ];

  const rows = genFields.productDetails.map((each_row, index) => ({
    id: index + 1,
    category: each_row.category,
    product: each_row.product,
    serial_number: each_row.serialNumbers,
  }));

  const handleDelete = (row, isSerialNumber = false) => {
    setGenFields((prev) => {
      let updatedProductDetails;

      if (isSerialNumber) {
        updatedProductDetails = prev.productDetails.map((prod) => {
          if (prod.product.toLowerCase() === row.product.toLowerCase()) {
            return {
              ...prod,
              serialNumbers: prod.serialNumbers.filter(
                (serial) => serial !== row.serial_number
              ),
            };
          }
          return prod;
        });

        // Remove products with no serial numbers
        updatedProductDetails = updatedProductDetails.filter(
          (prod) => prod.serialNumbers.length > 0
        );
      } else {
        updatedProductDetails = prev.productDetails.filter(
          (item, index) => index !== row.id - 1
        );
      }

      return {
        ...prev,
        productDetails: updatedProductDetails,
      };
    });

    openSnackbar("Successfully deleted from the product list", "warning");
  };

  //   closing of edit details modal
  function handleCloseEditDetailsModal(e, reason) {
    if (reason && reason === "backdropClick") {
      return;
    }
    setEditProductDetailsModal((prev) => ({ ...prev, isOpen: false }));
  }

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];
    // console.log("data :", data);

    if (data.rows.length > 0) {
      // First column (Image)
      headers.push({
        field: "expand",
        headerName: "",
        width: 70,
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const productType = params.row.category;

          return (
            <Box sx={{ textAlign: "center", padding: "8px" }}>
              <Tooltip title={productType} arrow>
                <div
                  style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    className="img-fluid"
                    src={
                      productTypeImg[productType] || productTypeImg["default"]
                    }
                    alt={product_default_img}
                  />
                </div>
              </Tooltip>
            </Box>
          );
        },
      });

      // Add all your other columns (Product Name, Serial Number, etc.)
      data.column.forEach((item, index) => {
        if (!["uuid", "notes"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex: isMediumScreen ? 0 : 1,
            headerAlign: index === 0 ? "left" : "center",
            renderHeader: (params) => (
              <Tooltip title={item.headerName} arrow>
                <span style={{ paddingLeft: index === 0 ? "10px" : "0px" }}>
                  {params.colDef.headerName}
                </span>
              </Tooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              const finalParamValue = ["quantity", "price"].some(
                (items) => item.field === items && params.value !== ""
              )
                ? formatInIndianRupees(params.value)
                : ["quantity"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "1"
                : ["serial_number"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "-"
                : params.value;
              return (
                <Box
                  sx={{
                    textAlign: index !== 0 ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight: (index !== 0 && "500") || "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color: color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <Tooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params.field.split("_").join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {finalParamValue}
                              </strong>
                            </div>
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding: "4px 9px",
                          borderRadius: "4px",
                          textTransform: "none",
                        }}
                      >
                        {finalParamValue}
                      </span>
                    </Tooltip>
                  }
                </Box>
              );
            },
          });
        }
      });

      // Add the "Delete" column at the end
      if (data.isProductsPage) {
        headers.push({
          field: "delete",
          headerName: "Action",
          width: 100,
          editable: false,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          headerAlign: "center",
          renderCell: (params) => {
            return (
              <Box sx={{ textAlign: "center", padding: "8px" }}>
                {/* <Tooltip title="Remove product from list." arrow> */}
                <div
                  style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    margin: "0px auto",
                  }}
                  //   onClick={() => {
                  //     // handleDelete(params.row);
                  //   }}
                >
                  {/* <AiFillDelete style={{ width: "20px", height: "20px" }} /> */}
                  {/* <AiOutlineDelete
                      style={{ width: "20px", height: "20px" }}
                    /> */}

                  {/* action for delete and edit */}

                  {/* <div
                    className="dropdow dropdown_export mx-2"
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip
                      data-html="true"
                      title="Click for Edit && Delete Options"
                      arrow={true}
                    >
                      <i
                        className="fa-solid fa-ellipsis-vertical  "
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ padding: "0px 3px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      ></i>
                    </Tooltip>
                    <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                      <li>
                        <div
                          className="dropDown_btns lot_btn_edit"
                          style={{ borderBottom: "1px solid #eaeaf0" }}
                          onClick={() => {
                            setEditProductDetailsModal((prev) => ({
                              ...prev,
                              isOpen: true,
                              rowId: params.row.id - 1,
                            }));

                            // console.log(params.row);
                          }}
                        >
                          <GrEdit
                            style={{
                              width: "20x",
                              height: "20px",
                              marginRight: "12px",
                              marginLeft: "5px",
                            }}
                          />
                          Edit
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropDown_btns lot_btn_delete"
                          onClick={() => handleDelete(params.row)}
                        >
                          <AiOutlineDelete
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "12px",
                            }}
                          />
                          Delete
                        </div>
                      </li>
                    </ul>
                  </div> */}

                  <Tooltip title="Delete" x>
                    <div
                      className="dropDown_btns lot_btn_delete"
                      onClick={() =>
                        isSerialNumber
                          ? handleDelete(params.row, isSerialNumber)
                          : handleDelete(params.row)
                      }
                    >
                      <AiOutlineDelete
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "0px auto",
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              </Box>
            );
          },
        });
      }
    }

    data.rows.forEach((item, index) => {
      if (isSerialNumber) {
        if (item?.serial_number?.length > 0) {
          item.serial_number.forEach((serial, serialIndex) => {
            rows.push({
              ...item,
              serial_number: serial,
              price: parseInt(item.price) / parseInt(item.quantity),
              // id: `${index + 1}-${serialIndex + 1}`, // Unique ID for each serial number
              id: index + serialIndex, // Unique ID for each serial number
            });
          });
        }
      } else {
        rows.push({ ...item, id: index + 1 });
      }
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = (columns &&
    rows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: rows || [],
      column: columns || [],
      isProductsPage: isProductsPage ? isProductsPage : false,
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  const getProductDetailsHeaders = (TableDataHeaders) => {
    const tableHeaders = TableDataHeaders.filter(
      (item) => item.field !== "expand"
    ).map((item, index) => ({
      id: item.field,
      label: item.headerName,
      align: index ? "center" : "left",
      minWidth: index ? "250" : "90",
    }));

    return tableHeaders;
  };

  const getProductDetailsRows = (TableDataRows) => {
    return TableDataRows.map((item) => {
      const imageUrl =
        productTypeImg[item.category.toLowerCase()] ||
        productTypeImg["default"];

      if (isSerialNumber) {
        return createData(
          // imageUrl,
          item.category,
          item.product,
          item.quantity,
          item.price,
          item.serial_number,
          isSerialNumber
        );
      } else {
        return createData(
          // imageUrl,
          item.category,
          item.product,
          item.quantity,
          item.price,
          null,
          isSerialNumber
        );
      }
    });
  };

  function createData(
    category,
    product,
    quantity,
    price,
    serial_number,
    isSerialNumber
  ) {
    if (isSerialNumber) {
      return {
        category,
        product,
        serial_number,
        quantity,
        price,
      };
    } else {
      return { category, product, quantity, price };
    }
  }

  return (
    <>
      {TabelDataRows.length > 0 && !isReview ? (
        <div className="card-Div">
          <DataGrid
            rows={TabelDataRows}
            columns={TableDataHeaders}
            sx={{
              "&.MuiDataGrid-root": {
                border: "none",
                borderRadius: "5px", // Adjust border radius as needed
                overflow: "hidden", // Ensure content respects the border radius,
                padding: "0px",
                margin: "0px",
                marginTop: "-10px",
              },
              ".MuiDataGrid-columnHeader": {
                backgroundColor: color.textWhiteColor,
                // justifyContent: "center",
              },

              ".MuiDataGrid-columnHeaderTitleContainer": {
                // justifyContent: "center",
              },
              ".MuiDataGrid-filler": {
                backgroundColor: color.textWhiteColor,
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Open-Sans-Light",
                fontWeight: "bold",
                padding: "5px 0px",
                fontSize: "0.85rem",
                textTransform: "capitalize",
                color: color.taxtHeading,
              },
              ".MuiDataGrid-columnHeader--alignCenter": {
                headerAlign: "left",
              },
              ".MuiDataGrid-row": {
                border: "none", // Remove border from rows
                backgroundColor: color.textWhiteColor, // Set rows background color to white
                "&:hover": {
                  backgroundColor: color.primary, // Ensure background color remains white on hover
                  // cursor: "pointer",
                },
              },

              ".MuiDataGrid-cell": {
                outline: "none",
                "&:focus": {
                  outline: "none", // Remove the blue outline when a cell is focused
                },
              },
              // Custom CSS to hide the "Rows per page" component

              ".MuiDataGrid-footerContainer": {
                // display: "none", // Hide the pagination footer
              },
              ".MuiTablePagination-root": {
                ".MuiInputBase-root": {
                  display: "none",
                },
                ".MuiTablePagination-selectLabel": {
                  display: "none",
                },
                // ".MuiToolbar-root": {
                //   marginTop: "12px",
                // },
              },
              "&.selected-row": {
                backgroundColor: "red", // Highlight color for the selected row
                "&:hover": {
                  backgroundColor: "red ", // Ensure highlight color remains on hover
                },
              },
              ".MuiDataGrid-selectedRowCount": {
                display: "none",
              },
            }}
          />

          {/* {editProductDetailsModal && (
          <EditProdutDetails
            editProductDetailsModal={editProductDetailsModal}
            handleClose={handleCloseEditDetailsModal}
            genFields={genFields}
            setGenFields={setGenFields}
            isSmallScreen={isSmallScreen}
          />
        )} */}
        </div>
      ) : null}

      {TabelDataRows.length > 0 && !isProductsPage && isReview && (
        <>
          <div
            className={`static-header-chart-div flex-grow-1 ${
              isSmallScreen ? "w-100" : "w-auto"
            }`}
            style={{ marginTop: "18px" }}
          >
            <div className="card-Div h-100 p-0 ">
              <div className="top-table-div ">
                {tableHeader && (
                  <div
                    className="bar-chart-heading pt-3 ps-3"
                    style={{
                      color: color.taxtHeading,
                      lineHeight: "1.2",
                      fontSize: "1rem",
                    }}
                  >
                    {tableHeader}
                  </div>
                )}
                {/* <div
                  className="ps-3"
                  style={{
                    color: "#838485",
                    fontSize: "14px",
                    textTransform: "none",
                    marginBottom: "4px",
                  }}
                >
                  Top districts with highest procurement
                </div> */}
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "auto",
                    border: "none",
                    boxShadow: "none",
                    borderRadius: "10px",
                    padding: "8px",
                  }}
                >
                  <TableContainer
                    sx={{
                      borderRadius: "15px",
                      overflow: "auto",
                      // border: "2px solid" + color.primary,
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      style={{ overflow: "auto" }}
                    >
                      <TableHead>
                        <TableRow>
                          {getProductDetailsHeaders(TableDataHeaders)?.map(
                            (column, headerIndex) =>
                              column.id !== "percent" && (
                                <TableCell
                                  key={`table-header-${headerIndex}-${headerIndex}`}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    color: color.tableRowHeading,
                                    padding: "7px",
                                    paddingLeft:
                                      headerIndex === 0 ? "72px" : "10px",
                                    paddingRight:
                                      headerIndex ===
                                      TableDataHeaders.length - 1
                                        ? "20px"
                                        : "10px",
                                    fontWeight: 400,
                                    fontSize: "0.9rem",
                                    lineHeight: "1.959rem",
                                    letterSpacing: "0.17px",
                                  }}
                                  className="bar-chart-heading"
                                >
                                  {column.label}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getProductDetailsRows(TabelDataRows)?.map(
                          (row, rowIndex) => (
                            <MuiTooltip
                              key={`table-${rowIndex}`}
                              placement="top"
                              arrow
                            >
                              <TableRow hover role="checkbox" tabIndex={-1}>
                                {getProductDetailsHeaders(TableDataHeaders).map(
                                  (column, rowDivIndex) => {
                                    // Access the specific value for each column by its ID from the row
                                    const value =
                                      column.id === "category" ? (
                                        <div className="d-flex align-items-center gap-3">
                                          <img
                                            src={
                                              productTypeImg[row[column.id]] ||
                                              productTypeImg["default"]
                                            }
                                            alt={row.category}
                                            style={{
                                              width: "35px",
                                              height: "35px",
                                              borderRadius: "4px",
                                            }}
                                          />
                                          <div>{row[column.id]}</div>
                                        </div>
                                      ) : (
                                        row[column.id]
                                      );

                                    return (
                                      <TableCell
                                        key={`table-row-${rowIndex}-${rowDivIndex}`}
                                        align={column.align}
                                        sx={{
                                          minWidth: column.minWidth,
                                          borderBottom: "none",
                                          padding: "10px",
                                          color:
                                            column.label !== "Product Name"
                                              ? color.tableRowHeading
                                              : color.taxtHeading,
                                          paddingLeft:
                                            rowDivIndex === 0 ? "20px" : "10px",
                                          paddingRight:
                                            rowDivIndex ===
                                            TableDataHeaders.length - 1
                                              ? "20px"
                                              : "10px",
                                          fontWeight:
                                            rowDivIndex === 0 ? 600 : 500,
                                          fontSize: "1rem",
                                          lineHeight: 1.429,
                                          letterSpacing: "0.15px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {value}
                                      </TableCell>
                                    );
                                  }
                                )}
                              </TableRow>
                            </MuiTooltip>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
