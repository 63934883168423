import React from "react";
import Person from "../../../assets/images/person.jpg";
import seller from "../../../assets/images/seller_3.svg";
import mail from "../../../assets/images/mail.png";
import location from "../../../assets/images/location_black.png";
import { Tooltip } from "@mui/material";
import { FaPhoneAlt } from "react-icons/fa";

const ShowSeller = ({ sellerData, handleDeleteModal, handleOpenEditModal }) => {
  const { sellerId, sellerName, address, person, phone, email } = sellerData;

  return (
    <div
      className="card"
      onClick={() => {
        // console.log("card clicked");
        handleOpenEditModal(sellerData);
      }}
    >
      <div className="card-body py-3 px-3">
        <div className="position-relative d-flex flex-row align-items-center mb-1">
          <h3 className="ticketid  fs-4 mb-0 ">
            <svg className="ticket-icon" viewBox="0 0 576 512">
              <path
                fill="#362b2b"
                d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"
              />
            </svg>
            {/* {ticketId} */}
            {sellerId}
          </h3>

          {/* Issue Type */}
          {false && (
            <div className="dropdown ">
              {/* <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              > */}
              <Tooltip
                data-html="true"
                title={"Click here to delete this lot detail"}
                arrow={true}
                placement="right"
              >
                <i
                  className="fa-solid fa-ellipsis-vertical "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ padding: "0px 3px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                ></i>
              </Tooltip>
              {/* Centered dropdown */}
              {/* </button> */}
              <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                <li>
                  <a
                    className="dropdown-item text-center delete-ticket-btn"
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteModal(true, sellerId);
                    }}
                  >
                    Delete Seller
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="lower-part">
          {/* seller name */}
          <div className="d-flex align-items-center mb-2">
            <img className="imported_img " src={seller} alt="" />
            {/* <i className="bi bi-person-fill imported_img"></i> */}
            <p className=" generated_by  ps-2">{sellerName}</p>
          </div>

          {/* Contacted Person name */}
          <div className="d-flex align-items-center mb-2">
            <img className="imported_img " src={Person} alt="" />
            {/* <i className="bi bi-person-fill imported_img"></i> */}
            <p className=" generated_by  ps-2">{person}</p>
          </div>

          {/* Phone */}
          {phone && (
            <div className="d-flex align-items-center me-3 mb-2">
              <FaPhoneAlt className="imported_img" />
              <p className=" generated_by  ps-2">{phone}</p>
            </div>
          )}

          {/* Email */}
          {email && (
            <div className="d-flex align-items-center me-3 mb-2">
              <img className="imported_img " src={mail} alt="e-mail" />
              <p className=" generated_by  ps-2">{email}</p>
            </div>
          )}

          {/* Address */}
          <div className="d-flex align-items-center mb-2">
            <img className="imported_img " src={location} alt="" />
            {/* <i className="bi bi-person-fill imported_img"></i> */}
            <p className=" generated_by error_description ps-2">{address}</p>
          </div>
        </div>
        {/* <div className="underline1 pt-2"></div> */}
      </div>
    </div>
  );
};

export default ShowSeller;
