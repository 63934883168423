import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import ToolTip from "react-bootstrap/Tooltip";
// import test_profile_img from "../../../assets/images/default_profile.png";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  Typography,
  Box,
  ButtonGroup,
  Button as MUIButton,
  Tooltip as MuiTooltip,
  linearProgressClasses,
  Pagination,
  Modal,
  Chip,
  IconButton,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Rectangle,
  ComposedChart,
  Line,
  Sector,
} from "recharts";
import {
  FaTicketAlt,
  FaChartLine,
  FaCheckCircle,
  FaChartBar,
  FaMapMarkedAlt,
  FaBuilding,
  FaCogs,
  FaPlus,
} from "react-icons/fa";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useMediaQuery } from "@material-ui/core";
import ContextState from "../../contextApi/ContextState";
import test_profile_img from "../../../assets/images/default_product_hdd_without_background.png";
import v39_scanner_img from "../../../assets/images/product/V39.png";
import v600_scanner_img from "../../../assets/images/product/V600.png";
import ardiuno_nano_controller_img from "../../../assets/images/product/ardiuno_nano.png";
import ardiuno_uno_controller_img from "../../../assets/images/product/ardiuno_uno.png";
import nuc_img from "../../../assets/images/product/nuc.png";

import showInventoryImg from "../../../assets/images/inv_total_count.png";
import showReadyToUseImg from "../../../assets/images/inv_ready_to_use.png";
import showInHouseImg from "../../../assets/images/inv_in_house.png";
import ReactPaginate from "react-paginate";

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import * as XLSX from "xlsx";
import { addFilters } from "../../features/filtersSlice/filterSlice";
import { BeatLoader } from "react-spinners";
import Error from "../../error/Error";
import Dropdown from "../../filters/Dropdown";
import AllFilters from "../../filters/AllFilters";
import { useDispatch, useSelector } from "react-redux";
import {
  TableSkeleton,
  TravelPageSkeleton,
} from "../../Exporters/ExportFunctions";

const statusColors = [
  { status: "Arrival", color: "#2196F3" }, // Blue shade
  { status: "Testing", color: "#4FC3F7" }, // Light Blue
  { status: "Repair", color: "#29B6F6" }, // Medium Blue
  { status: "Retesting", color: "#039BE5" }, // Dark Blue
  { status: "Ready to Go", color: "#66BB6A" }, // Light Green
  { status: "On Field", color: "#43A047" }, // Medium Green
  { status: "In House", color: "#388E3C" }, // Dark Green
  { status: "Restore For use", color: "#2E7D32" }, // Darker Green
  { status: "Dispatch", color: "#FF7043" }, // Light Red
  { status: "Return", color: "#EF5350" }, // Medium Red
  { status: "Re Dispatched", color: "#E53935" }, // Darker Red
  { status: "Damaged", color: "#D32F2F" }, // Dark Red
  { status: "Prepare", color: "#81D4FA" }, // Very Light Blue
];
export const findColorByStatus = (status) => {
  const result = statusColors?.find(
    (item) => item?.status?.toLowerCase() === status?.toLowerCase()
  );
  return result ? result.color : null; // Return the color if found, otherwise return null
};

const SpecificProductType = () => {
  const { productCategory } = useParams();
  const { color, pageStyling } = useOutletContext();

  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState(true);

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Showing All produts of{" "}
      <span style={{ textTransform: "capitalize" }}>
        {productCategory || ""}
      </span>{" "}
      category from inventory
    </ToolTip>
  );


  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (e, path, targetPath) => {
    if (e.ctrlKey || e.metaKey) {
      // Open in a new tab if Ctrl or Cmd key is pressed
      window.open(targetPath, "_blank");
    } else {
      // Prevent full page reload and navigate within SPA
      e.preventDefault();
      navigate(`${path}`);
    }
  };

  // const [Data, setData] = useState({
  //   table_data: {
  //     column: [
  //       {
  //         field: "product_id",
  //         headerName: "Product Id",
  //         width: 100,
  //         flex: 1,
  //         resizable: false,
  //       },
  //       {
  //         field: "lot_id",
  //         headerName: "Lot ID",
  //         width: 150,
  //         editable: false,
  //         flex: 1,
  //         resizable: false,
  //       },
  //       {
  //         field: "serial_id",
  //         headerName: "Serial No.",
  //         width: 150,
  //         flex: 1,
  //         resizable: false,
  //       },
  //       {
  //         field: "created_date",
  //         headerName: "Arrival Date",
  //         type: "number",
  //         width: 150,
  //         editable: false,
  //         flex: 1,
  //         resizable: false,
  //       },
  //       {
  //         field: "updated_date",
  //         headerName: "Last Updated ",
  //         type: "number",
  //         width: 150,
  //         editable: false,
  //         flex: 1,
  //         resizable: false,
  //       },
  //       {
  //         field: "status",
  //         headerName: "Status",
  //         type: "number",
  //         width: 200,
  //         flex: 1,
  //         editable: false,
  //         resizable: false,
  //       },
  //     ],
  //     rows: [
  //       {
  //         id: 1,
  //         lot_id: "XXX1",
  //         serial_id: "abcXXX123",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Arrival",
  //         quantity: 1,
  //         product_id: 0,
  //       },
  //       {
  //         id: 2,
  //         lot_id: "XXX2",
  //         serial_id: "abcXXX124",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Testing",
  //         quantity: 1,
  //         product_id: 1,
  //       },
  //       {
  //         id: 3,
  //         lot_id: "XXX3",
  //         serial_id: "abcXXX125",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Ready to Go",
  //         quantity: 1,
  //         product_id: 2,
  //       },
  //       {
  //         id: 4,
  //         lot_id: "XXX4",
  //         serial_id: "abcXXX126",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "On Field",
  //         quantity: 1,
  //         product_id: 3,
  //       },
  //       {
  //         id: 5,
  //         lot_id: "XXX5",
  //         serial_id: "abcXXX127",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         quantity: 1,
  //         status: "Dispatch",
  //         product_id: 4,
  //       },
  //       {
  //         id: 6,
  //         lot_id: "XXX6",
  //         serial_id: "abcXXX128",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "In House",
  //         quantity: 1,
  //         product_id: 5,
  //       },
  //       {
  //         id: 7,
  //         lot_id: "XXX7",
  //         serial_id: "abcXXX129",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Return",
  //         quantity: 1,
  //         product_id: 6,
  //       },
  //       {
  //         id: 8,
  //         lot_id: "XXX8",
  //         serial_id: "abcXXX130",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Repair",
  //         quantity: 1,
  //         product_id: 7,
  //       },
  //       {
  //         id: 9,
  //         lot_id: "XXX9",
  //         serial_id: "abcXXX131",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Retesting",
  //         quantity: 1,
  //         product_id: 8,
  //       },
  //       {
  //         id: 10,
  //         lot_id: "XX10",
  //         serial_id: "abcXXX131",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Restore For use",
  //         quantity: 1,
  //         product_id: 9,
  //       },
  //       {
  //         id: 11,
  //         lot_id: "XX11",
  //         serial_id: "abcXXX131",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Re Dispatched",
  //         quantity: 1,
  //         product_id: 10,
  //       },
  //       {
  //         id: 12,
  //         lot_id: "XX12",
  //         serial_id: "abcXXX131",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Damaged",
  //         quantity: 1,
  //         product_id: 11,
  //       },
  //       {
  //         id: 13,
  //         lot_id: "XX13",
  //         serial_id: "abcXXX131",
  //         product_name: "product_name",
  //         created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //         status: "Prepare",
  //         quantity: 1,
  //         product_id: 12,
  //       },
  //     ],
  //   },
  //   top_stage_desc: [
  //     {
  //       stage: "Arrival",
  //       count: 10,
  //     },
  //     {
  //       stage: "Testing",
  //       count: 5,
  //     },
  //     {
  //       stage: "Ready to Go",
  //       count: 20,
  //     },
  //     {
  //       stage: "On Field",
  //       count: 30,
  //     },
  //     {
  //       stage: "Dispatch",
  //       count: 4,
  //     },
  //     {
  //       stage: "In House",
  //       count: 1,
  //     },
  //     {
  //       stage: "Return",
  //       count: 7,
  //     },
  //     {
  //       stage: "Repair",
  //       count: 17,
  //     },
  //     {
  //       stage: "Retesting",
  //       count: 2,
  //     },
  //     {
  //       stage: "Restore For use",
  //       count: 7,
  //     },
  //     {
  //       stage: "Re Dispatched",
  //       count: 5,
  //     },
  //     {
  //       stage: "Damaged",
  //       count: 5,
  //     },
  //     {
  //       stage: "Prepare",
  //       count: 2,
  //     },
  //   ],
  //   last_10_days_data: {
  //     cropwise_chart: [
  //       {
  //         crop: "Arrival",
  //         total: 10,
  //         accepted: 2301,
  //       },
  //       {
  //         crop: "Testing",
  //         total: 5,
  //         accepted: 2229,
  //       },
  //       {
  //         crop: "Ready to Go",
  //         total: 20,
  //         accepted: 1512,
  //       },
  //       {
  //         crop: "On Field",
  //         total: 30,
  //         accepted: 1265,
  //       },
  //       {
  //         crop: "Dispatch",
  //         total: 4,
  //         accepted: 1,
  //       },
  //       {
  //         crop: "In House",
  //         total: 1,
  //         accepted: 1,
  //       },
  //       {
  //         crop: "Return",
  //         total: 7,
  //         accepted: 1,
  //       },
  //       {
  //         crop: "Repair",
  //         total: 17,
  //         accepted: 1,
  //       },
  //       {
  //         crop: "Retesting",
  //         total: 2,
  //         accepted: 1,
  //       },
  //       {
  //         crop: "Restore For use",
  //         total: 7,
  //         accepted: 1,
  //       },
  //       {
  //         crop: "Re Dispatched",
  //         total: 5,
  //         accepted: 1,
  //       },
  //       {
  //         crop: "Damaged",
  //         total: 5,
  //         accepted: 1,
  //       },
  //     ],
  //   },
  // });

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    // console.log(": ", data);

    if (data.rows.length > 0) {
      headers.push({
        field: "expand",
        headerName: "",
        width: 50,
        editable: false,
        sortable: false, // Disable sorting for this column
        filterable: false, // Disable filtering for this column
        disableColumnMenu: true,
        renderCell: (params) => {
          const targetPath = location.pathname + "/" + params.row.product_id;
          return (
            <Box
              sx={{
                // width: !index ? 400 : 150, // Adjust width as needed
                //   marginLeft: "5px", // Add margin to separate text from progress bar
                //   fontWeight: "bold", // Customize font weight if needed
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                // color:
                //   (item.field === "status" &&
                //     ((params.value === "rejected" && color.red) ||
                //       (params.value === "accepted" && color.greenToShow))) ||
                //   (params.row["is_rejected"][item.field] && color.red) ||
                //   color.tableRowHeading,
                textWrap: "nowrap",
              }}
            >
              {
                <MuiTooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        More Details
                      </div>
                    </>
                  }
                  arrow
                >
                  {false && (
                    <span
                      style={{
                        // border: "2px solid blue",
                        padding: "3px 9px",
                        borderRadius: "4px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        // console.log(params.row);

                        handleClick(e, params.row.product_id, targetPath);
                        // navigate(`${params.row.product_id}`);
                        // if (params.row) {
                        //   handleTableRowClick(params.row);
                        // }
                      }}
                    >
                      <FaPlus />
                    </span>
                  )}
                  <a
                    href={targetPath}
                    style={{
                      padding: "3px 9px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default behavior of the <a> tag
                      e.stopPropagation(); // Stops propagation of the click event
                      // console.log(params.row, targetPath);
                      handleClick(e, params.row.product_id, targetPath);
                    }}
                  >
                    <FaPlus style={{ color: color.tableRowHeading }} />
                  </a>
                </MuiTooltip>
              }
            </Box>
          );
        },
      });
      data.column.forEach((item, index) => {
        // if (!["region", "district", "location"].includes(item.field)) {
        headers.push({
          field: item.field,
          headerName: item.headerName,
          width: item.width,
          editable: item.editable,
          flex:
            !isSmallScreen && !["product_id", "status"].includes(item.field),
          headerAlign: ![0].includes(index) ? "center" : "left",
          resizable: item.resizable,
          renderHeader: (params) => (
            <MuiTooltip
              title={`${item.field
                .replace(/_/g, " ")
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}`}
              arrow
            >
              <span
                style={{
                  paddingLeft: ([0].includes(index) && "10px") || "0px",
                }}
              >
                {params.colDef.headerName}
              </span>
            </MuiTooltip>
          ),
          renderCell: (params) => {
            // console.log("params-->: ", params);
            return (
              <Box
                sx={{
                  // width: !index ? 400 : 150, // Adjust width as needed
                  //   marginLeft: "5px", // Add margin to separate text from progress bar
                  //   fontWeight: "bold", // Customize font weight if needed
                  textAlign: ![0].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  fontWeight:
                    (item.field === "stage_description" && "600") ||
                    (index !== 0 && "500") ||
                    "700",
                  fontSize:
                    item.field === "stage_description" ? "0.985rem" : "1.05rem",
                  color:
                    (item.field === "stage_description" &&
                      findColorByStatus(params.value)) ||
                    color.tableRowHeading,
                  textWrap: "nowrap",
                }}
              >
                {
                  <MuiTooltip
                    title={
                      <>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            fontFamily: "Open-Sans-Medium",
                          }}
                        >
                          <div>
                            <strong style={{ textTransform: "capitalize" }}>
                              {params.field.split("_").join(" ")}
                            </strong>
                            :{" "}
                            <strong
                              style={{
                                textTransform:
                                  (item.field === "status" && "capitalize") ||
                                  "none",
                              }}
                            >
                              {(item.field === "creation_time" &&
                                formatDate(params.value)) ||
                                (item.field === "last_updated" &&
                                  formatDate(params.value)) ||
                                params.value}
                            </strong>
                          </div>
                          {item.upper !== undefined && (
                            <p
                              className="m-0 p-0"
                              style={{
                                fontSize: "0.65rem",
                              }}
                            >
                              Upper Limit : {item.upper}
                            </p>
                          )}
                          {item.lower !== undefined && (
                            <p
                              className="m-0 p-0"
                              style={{
                                fontSize: "0.65rem",
                              }}
                            >
                              Lower Limit : {item.lower}{" "}
                            </p>
                          )}
                        </div>
                      </>
                    }
                    arrow
                  >
                    {(item.field === "product_name" && (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding: "0px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform: "none",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          height: "50px",
                        }}
                      >
                        {/* <div className="product_img m-0 p-0 ">
                          <img
                            src={test_profile_img}
                            alt="product_img"
                            style={{ height: "40px", width: "40px" }}
                          />
                        </div> */}
                        <div
                          className="product_full_desc d-flex flex-column  p-0 m-0 lh-sm"
                          style={{
                            fontFamily: "Open-Sans-Light",
                            color: color.taxtHeading,
                            fontWeight: 600,
                            width: "100%",
                          }}
                        >
                          <div
                            className="product_name p-0 m-0"
                            // style={{ height: "20px" }}
                          >
                            {params.value}
                          </div>
                          <div
                            className="product_desc p-0 m-0"
                            style={{
                              width: "90%",
                              fontWeight: 400,
                              fontSize: "0.89rem",
                              overflow: "hidden", // Hide overflow
                              textOverflow: "ellipsis", // Show ellipsis
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                          >
                            Product_desc/use facdcdcccvevevdvdhvbdjvdvvbjv
                          </div>
                        </div>
                      </span>
                    )) || (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding:
                            (item.field === "stage_description" &&
                              "5px 12px") ||
                            "4px 9px",
                          borderRadius: "20px",
                          backgroundColor:
                            (item.field === "stage_description" &&
                              findColorByStatus(params.value) + "33") ||
                            "transparent",
                          textTransform:
                            (["status", "reason"].includes(item.field) &&
                              "capitalize") ||
                            "none",
                          width: "100px",
                        }}
                      >
                        {(item.field === "created_date" &&
                          formatDate(params.value, true)) ||
                          (item.field === "updated_date" &&
                            formatDate(params.value, true)) ||
                          params.value}
                      </span>
                    )}
                  </MuiTooltip>
                }
              </Box>
            );
          },
        });
        // }
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  // const { TableDataHeaders, TabelDataRows } = (Data?.table_data &&
  //   Data?.table_data?.rows?.length !== 0 &&
  //   getFirstPageDataTableDataHeader({
  //     rows: Data?.table_data?.rows || [],
  //     column: Data?.table_data?.column || [],
  //   })) || { TableDataHeaders: [], TabelDataRows: [] };

  const { TableDataHeaders, TabelDataRows } = useMemo(() => {
    if (
      Data?.product_details_table_data &&
      Data?.product_details_table_data?.rows?.length !== 0
    ) {
      return getFirstPageDataTableDataHeader({
        rows: Data?.product_details_table_data?.rows || [],
        column: Data?.product_details_table_data?.column || [],
      });
    }
    return { TableDataHeaders: [], TabelDataRows: [] };
  }, [
    JSON.stringify(Data?.product_details_table_data?.rows),
    JSON.stringify(Data?.product_details_table_data?.column),
    isSmallScreen,
  ]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy - 10}
          dy={8}
          textAnchor="middle"
          fill={color.taxtHeading}
          style={{ fontSize: "1.05rem", fontWeight: "bold" }}
        >
          {payload.total}
        </text>
        <text
          x={cx}
          y={cy + 5}
          dy={8}
          textAnchor="middle"
          fill={color.taxtHeading}
          style={{ fontSize: "0.75rem" }}
        >
          {payload.crop}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        {/* <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        /> */}
        {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text> */}
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  };

  const [activePieIndex, setactivePieIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const onPieEnter = useCallback(
    (_, index) => {
      setactivePieIndex(index);
      setIsHovered(true);
    },
    [setactivePieIndex]
  );

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setactivePieIndex(
          (prevIndex) =>
            (prevIndex + 1) % Data?.last_10_days_data?.cropwise_chart.length
        );
      }, 3000); // Change interval as needed

      return () => clearInterval(interval);
    }
  }, [isHovered, Data]);

  const onPieLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const shadesOfBlue = [
    "#666bff",
    "#7c79ff",
    "#8e87ff",
    "#9f95ff",
    "#afa3ff",
    "#bdb2ff",
    "#cbc1fe",
    "#d8d0fe",
    "#e5dffd",
  ];

  const rowHeight = 50; // Default row height in DataGrid
  const headerHeight = 105; // Default header height in DataGrid
  const maxRows = 5; // Maximum number of rows per page
  const visibleRows = Math.max(
    Data?.product_details_table_data?.rows?.length || 0,
    maxRows
  );
  const gridHeight =
    headerHeight +
    rowHeight *
      (visibleRows > 100
        ? 104.08
        : visibleRows > 50
        ? visibleRows + 4.75
        : visibleRows > 20
        ? visibleRows + 2.5
        : visibleRows) +
    (visibleRows > 100 ? 52 : 0); // Added 52 for pagination height

  const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
  const [totalCardsCount, settotalCardsCount] = useState(20); // Initialize total vards from backend
  // Number of cards to display per page
  const [cardsPerPage, setCardsPerPage] = useState(20);

  const handelCurrentPage = (clickedPageNumber) => {
    // // console.log(
    //   "current Page ----------->",
    //   clickedPageNumber,
    //   parseInt(clickedPageNumber) + 1,
    //   currentPage
    // );
    // setCurrentPage(parseInt(clickedPageNumber) + 1);
    setCurrentPage(0);
    // updateFilters(filters, parseInt(clickedPageNumber), true, true);
  };

  // filters started

  const {
    fetchApi,
    showError,
    showErrorPage,
    openSnackbar,
    generalApiCall,
    gencontrollerRef,
    controllerRef,
    formatInIndianRupees,
    formatDate,
    capitalizeWords,
  } = useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters);

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");
  const [liveMachinesData, setliveMachinesData] = useState(null);

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  // const [loading, setLoadi ng] = useState(true);

  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters
  const [filters, setFilters] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
    searchBox: [],
    ticketPerPage: [],
    commitId: [],
    modelNo: [],
    branchName: [],
  });
  const [showAllfilters, setshowAllFilters] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
    searchBox: [],
    ticketPerPage: [],
    commitId: [],
    modelNo: [],
    branchName: [],
  });
  const [filterCount, setFilterCount] = useState({
    status: 0,
    issueType: 0,
    subIssueType: 0,
    RO: 0,
    DO: 0,
    machineNumber: 0,
    createdStartDate: 0,
    createdEndDate: 0,
    resolvedStartDate: 0,
    resolvedEndDate: 0,
    sort: 0,
    crop_variation: 0,
    params_variation: 0,
    assignee: 0,
    moreFilters: 0,
    searchBox: 0,
    ticketPerPage: 0,
    commitId: 0,
    modelNo: 0,
    branchName: 0,
  });

  const updateFilters = (Filters, clickedPageNumber, updateURL = true) => {
    // console.log("update filter 0 -->", Filters, clickedPageNumber);
    Filters = applyCoustomChecksFilter(Filters);
    setFilters(Filters);
    setCurrentPage(clickedPageNumber);
    if (updateURL) {
      updateURLParams(Filters, clickedPageNumber);
      // console.log("Url Is Updating ");
    } else {
      // console.log("Url Is Not Updating ");
    }
    updateFilterCount(Filters);
    if (getUserFilters?.issues) {
      applyIssueTypeFilters(Filters);
    }
    showAllFilters(Filters);
    fetchData(Filters, clickedPageNumber, cardsPerPage);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params, page) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    urlSearchParams.set("currentPage", parseInt(page) + 1);
    // urlSearchParams.set("cardsPerPage", cardsPerPage);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/allticket" && !Data) {
      // Check if the state already exists
      // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
            Page: parseInt(page) + 1,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      window.history.pushState(
        {
          filters: params,
          Page: parseInt(page) + 1,
        },
        "",
        newUrl
      );
    }
  };

  //  State for the Extra details for Export Data...

  const fetchData = async (F, Page, PerPage, reloadData = true) => {
    // if (reloadData) {
    //   setData(null);
    //   // console.log("data set to empty List - 3");

    //   setLoading(true);
    //   showLoading(true);
    // }
    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};
    payload["page_num"] = parseInt(Page) + 1;
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["creation_from"] = F["createdStartDate"][0];
    }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["creation_to"] = F["createdEndDate"][0];
    }
    if (
      F["resolvedStartDate"] &&
      F["resolvedStartDate"].length &&
      F["status"].some((value) => value.toLowerCase() === "resolved")
    ) {
      payload["resolved_from"] = F["resolvedStartDate"][0];
    }
    if (
      F["resolvedEndDate"] &&
      F["resolvedEndDate"].length &&
      F["status"].some((value) => value.toLowerCase() === "resolved")
    ) {
      payload["resolved_to"] = F["resolvedEndDate"][0];
    } else {
    }
    if (F["issueType"] && F["issueType"].length) {
      payload["issue"] = F["issueType"];
    }
    if (F["subIssueType"] && F["subIssueType"].length) {
      payload["sub_issue"] = F["subIssueType"];
    }
    if (F["status"] && F["status"].length) {
      payload["status"] = F["status"].map((e) => e.toLowerCase());
    }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    if (F["sort"] && F["sort"].length) {
      const sortDictManual = {
        "Creation time- Ascending": "creation_time:ASC",
        "Creation time- Descending": "creation_time:DESC",
        "Updated time- Descending": "last_updated:DESC",
      };
      const sortArr =
        (sortDictManual[filtersOptions["sortDict"][0]] &&
          F["sort"].map((item) => filtersOptions["sortDict"][item])) ||
        sortDictManual[F["sort"][0]];
      payload["Sort"] = sortArr;
    }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop_variation"] = F["crop_variation"];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      payload["params_variation"] = F["params_variation"];
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["assignee"] = F["assignee"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    if (F["branchName"] && F["branchName"].length) {
      payload["branchName"] = F["branchName"];
    }
    if (F["moreFilters"] && F["moreFilters"].length) {
      F["moreFilters"].forEach((item) => {
        payload[item] = true;
      });
    }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }

    let is_all_ticket_data_Mounted = true;
    // const all_tickets_data = await fetchApi(
    //   "get_all_tickets_data",
    //   "POST",
    //   payload,
    //   is_all_ticket_data_Mounted
    // );
    // console.log("all_ticket_data : ", all_tickets_data, payload);

    // if (all_tickets_data.statusCode === 200) {
    //   showErrorPage(null, null);
    //   setData(all_tickets_data?.data || null);
    //   settotalCardsCount(all_tickets_data?.data?.total_count || 1);

    //   if (all_tickets_data?.data?.tickets_limit === "All") {
    //     setCardsPerPage(all_tickets_data?.data?.total_count || 1);
    //   } else {
    //     setCardsPerPage(all_tickets_data?.data?.tickets_limit || 1);
    //   }
    // } else if (
    //   all_tickets_data.statusCode === 500 ||
    //   all_tickets_data.statusCode === 401
    // ) {
    //   setData(null);
    //   showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
    // } else {
    //   setData(null);
    //   // showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
    //   localStorage.clear();
    //   window.location.href = "/login";
    // }

    if (reloadData) {
      setLoading(false);
      // showLoading(false);
    }

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };
  // to reload the data
  async function reloadData() {
    await fetchData(filters, currentPage, cardsPerPage, false);
  }

  const handleFilterChange = (filter, filterType) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filterType];

      if (filterList && filterType === "searchBox") {
        if (filter !== "") currentFilters[filterType] = [filter];
        else currentFilters[filterType] = [];
      } else if (filterList && filterList.includes(filter)) {
        currentFilters[filterType] = filterList.filter(
          (prevFilter) => prevFilter !== filter
        );
      } else if (
        filterList &&
        (filterType === "createdStartDate" ||
          filterType === "createdEndDate" ||
          filterType === "resolvedStartDate" ||
          filterType === "resolvedEndDate")
      ) {
        currentFilters[filterType] = [filter];
      } else if (
        (filterList && filterType === "sort") ||
        filterType === "ticketPerPage"
      ) {
        currentFilters[filterType] = [filter];
      } else {
        currentFilters[filterType] = [...(filterList || []), filter];
        if (filterList && filterType === "issueType") {
          currentFilters["subIssueType"] = [];
          currentFilters["status"] = [];
          currentFilters["crop_variation"] = [];
          currentFilters["params_variation"] = [];
        }
      }
      if (filterType === "RO") {
        applyROFilters(currentFilters);
      } else if (filterType === "machineNumber") {
        applyMachineNumberFilters(currentFilters);
      } else if (filterType === "DO") {
        applyDOFilters(currentFilters);
      }
      applyIssueTypeFilters(currentFilters);
      updateFilterCount(currentFilters);
      applyCropVariationFilters(currentFilters);
      // console.log(
      //   "update filter 1 -->",
      //   prevFilters,
      //   currentFilters,
      //   filter,
      //   filterType
      // );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    // const filters = { ...filters };
    if (Object.keys(filters || {}).includes(filtername)) {
      filters[filtername] = [];
    }
    if (filtername === "moreFilters") {
      filters["searchBox"] = [];
      updateFilters["ticketPerPage"] = [];
    }
    if (filtername === "issueType") {
      filters["subIssueType"] = [];
    }
    if (filtername === "dateSelector") {
      filters["resolvedStartDate"] = [];
      filters["resolvedEndDate"] = [];
      filters["createdStartDate"] = [];
      filters["createdEndDate"] = [];
    }
    // console.log("working", filtername, filtername === "dateSelector", filters);
    setFilters(filters);
    // console.log(filtername);
    if (filtername === "RO") {
      applyROFilters(filters);
    } else if (filtername === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filtername === "DO") {
      applyDOFilters(filters);
    }
    // console.log(filters, "updateFilterCount");
    applyIssueTypeFilters(filters);
    updateFilterCount(filters);
  };

  const handleSaveChanges = (filtername) => {
    // console.log("sendFilters->  ", filtername);
    const filterAfterCoustomChange = applyCoustomChecksFilter(filters);
    updateFilters(filterAfterCoustomChange, 0);
    setshowAllFilters(filterAfterCoustomChange);
  };

  const handelResetAllBtn = () => {
    // console.log("working");
    for (const key in filters) {
      filters[key] = [];
    }
    // console.log("updated filter ", filters);
    updateFilters(filters, 0);

    setfiltersOptions((prevState) => ({
      ...prevState,
      DO: filtersOptionsCopy["DO"],
      machineNumber: filtersOptionsCopy["machineNumber"],
      RO: filtersOptionsCopy["RO"],
    }));
  };

  const handelDeleteFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    if (filters.hasOwnProperty(filterType)) {
      // Remove the specified filterValue from the filterKey array
      filters[filterType] = filters[filterType].filter(
        (filter) => filter !== subfilter
      );
    }
    if (filterType === "status" && subfilter === "Resolved") {
      filters["resolvedEndDate"] = [];
      filters["resolvedStartDate"] = [];
    }
    if (filterType === "issueType") {
      filters["subIssueType"] = [];
      filters["status"] = [];
      if (subfilter === "Data Variation") {
        filters["crop_variation"] = [];
        filters["params_variation"] = [];
        filters["modelNo"] = [];
        filters["commitId"] = [];
        filters["branchName"] = [];
      }
    }
    if (filterType === "crop_variation") {
      filters["params_variation"] = [];
    }

    if (filterType === "RO") {
      applyROFilters(filters);
    } else if (filterType === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filterType === "DO") {
      applyDOFilters(filters);
    }
    updateFilters(filters, 0);
    // console.log("Deleted single filter --->", filters);
  };

  function showAllFilters(FiltersDict) {
    for (const i in FiltersDict) {
      if (FiltersDict[i].length !== 0) {
        // console.log("show filter");
        setallFilterDisplay(true);
        return;
      }
    }
    setallFilterDisplay(false);
  }

  const [getUserFilters, setgetUserFilters] = useState(null);

  // filters to show

  const [filtersOptions, setfiltersOptions] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    sortDict: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    branchName: [],
    moreFilters: ["visit_required", "internet_issue"],
    ticketPerPage: ["20", "50", "100", "All"],
  });
  const [filtersOptionsCopy, setfiltersOptionsCopy] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    sortDict: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    branchName: [],
    moreFilters: ["visit_required", "internet_issue"],
    ticketPerPage: ["20", "50", "100", "All"],
  });
  // filters sorting according to selected filter
  const getFilterResponse = async (response) => {
    // setLoading(true);
    // showLoading(true);
    try {
      // calling refresh token whenever loading the page
      // console.log("get_user_filters--->", response);
      if (response.statusCode === 200) {
        // console.log("get_user_filters Data Fetched Successfully");
        const data = response.data;
        // console.log("data", new Date().toLocaleString(), data);
        setgetUserFilters(data);

        // Extract RO# keys from  filters_data
        const roKeys =
          data && data.access_level === "institution"
            ? Object.keys(data?.filters_data || {})
            : [];

        // Extract DO# keys from RO objects in filters_data
        const doKeys =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {}).flatMap((Do) =>
                Object.keys(Do)
              )
            : data && data.access_level === "region"
            ? Object.keys(data.filters_data)
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniquedoKeys = Array.from(new Set(doKeys));

        // Extract machine numbers from DO keys
        const machineNumbervalue =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {})
                .flatMap((ro) => Object.values(ro))
                .flatMap((doData) => doData)
                .flatMap((machine) => machine)
                .concat(Object.values(data?.ro_machines || {}))
            : data && data.access_level === "region"
            ? Object.values(data?.filters_data || {})
                .flatMap((doList) => {
                  return Object.values(doList);
                })
                .flat()
            : data && data.access_level === "district"
            ? data.filters_data
            : [];
        // Populate machineNumber array with unique machine numbers
        const uniqueMachineNumbers = Array.from(new Set(machineNumbervalue));

        // populate subissuetype in filter option
        const subIssueTypes = Object.values(data.issues[0]).flatMap((issue) =>
          issue.sub_issue.map((subIssue) => subIssue[1])
        );
        // console.log(
        //   "Sub issues : ",
        //   subIssueTypes,
        //   typeof subIssueTypes,
        //   subIssueTypes.length
        // );
        // populating sort in filters Option
        const sortArr =
          (data && data.sort_list.map((item) => item.value)) || [];
        const sortDictArr =
          (data &&
            data.sort_list.reduce((acc, item) => {
              acc[item.value] = item.id;
              return acc;
            }, {})) ||
          [];

        const assigneeType =
          (data && data.assignee_list?.map((item) => item.name)) || [];
        // console.log("assigneeType", assigneeType);
        const cropvariationTypes = data ? Object.keys(data.param_data) : [];

        let commitIdArr = data && Object.values(data.commit_id_list || {});

        commitIdArr = commitIdArr.map((commitIds, index) => {
          return commitIds.slice(0, 5);
        });

        const modelNoArr = data && Object.values(data.model_no_list || {});
        const branchNameArr = data && Object.values(data.git_branch_list || []);
        const dataParamsTypes =
          Array.from(
            new Set(
              data &&
                Object.values(data.param_data).flatMap((item) =>
                  Object.keys(item.client_params)
                )
            )
          ) || [];

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        const statusTypes = Array.from(
          new Set(Object.values(data?.states_list || {}).flat())
        );
        const issueTypeArr = Object.keys(data?.states_list || {});
        // console.log("statusTypes:", statusTypes, issueTypeArr);

        setfiltersOptions((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          subIssueType: subIssueTypes,
          sortDict: sortDictArr,
          sort: sortArr,
          crop_variation: cropvariationTypes,
          params_variation: dataParamsTypes,
          assignee: assigneeType,
          modelNo: modelNoArr,
          commitId: commitIdArr,
          branchName: branchNameArr,
          issueType: issueTypeArr,
          status: statusTypes,
        }));
        setfiltersOptionsCopy((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          subIssueType: subIssueTypes,
          status: statusTypes,
          sort: sortArr,
          sortDict: sortDictArr,
          crop_variation: cropvariationTypes,
          params_variation: dataParamsTypes,
          assignee: assigneeType,
          modelNo: modelNoArr,
          commitId: commitIdArr,
          branchName: branchNameArr,
          issueType: issueTypeArr,
        }));
      } else {
        // Handle non-successful responses
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const applyROFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;

    // Extract all DO keys from selected RO filters
    const filteredDO = roFilters.flatMap((ro) =>
      getUserFilters?.filters_data[ro]
        ? Object.keys(getUserFilters?.filters_data[ro] || {})
        : []
    );

    // console.log("filteredDO", filteredDO, roFilters);

    // Extract all machine numbers from selected RO filters and DO keys
    const filteredMachineNumbers = roFilters.flatMap((ro) =>
      getUserFilters?.filters_data[ro]
        ? Object.values(getUserFilters?.filters_data[ro] || {})
            .flatMap((doValues) => doValues)
            .concat(
              getUserFilters?.ro_machines[ro]
                ? getUserFilters?.ro_machines[ro]
                : []
            )
        : []
    );

    // Update filtersOptions with unique filtered DO values and machine numbers
    const uniqueDO = Array.from(new Set(filteredDO));
    const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));

    if (roFilters.length === 0) {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["RO"] = [];
      newFilter["machineNumber"] = [];
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);
      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: filtersOptionsCopy["DO"],
        machineNumber: filtersOptionsCopy["machineNumber"],
      }));
    } else {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["machineNumber"] = [];
      // console.log("uniqueMachineNumber --> ", uniqueMachineNumbers);
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);

      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: uniqueDO,
        machineNumber: uniqueMachineNumbers,
      }));
    }
  };

  const applyDOFilters = (filtersDict) => {
    const doFilters = filtersDict.DO;
    const roFilters = filtersDict.RO;
    if (getUserFilters.access_level === "institution") {
      // Find corresponding RO keys for selected DO keys
      const roKeys = [];
      Object.entries(getUserFilters?.filters_data || {}).forEach(
        ([roKey, doValues]) => {
          Object.keys(doValues).forEach((doKey) => {
            if (doFilters.includes(doKey)) {
              roKeys.push(roKey);
            }
          });
        }
      );

      // Extract all machine numbers from selected DO keys
      const filteredMachineNumbers = doFilters.flatMap((doKey) =>
        Object.values(getUserFilters.filters_data)
          .filter((ro) => ro[doKey])
          .flatMap((ro) => ro[doKey])
      );
      // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
      const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
      const uniqueRO = Array.from(new Set(roKeys));
      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
          RO: filtersOptionsCopy["RO"],
        }));
      } else {
        // //filters["machineNumber"] = uniqueMachineNumbers;
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: uniqueMachineNumbers,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract machine numbers for selected DO keys
      const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
        if (getUserFilters.filters_data[doKey]) {
          acc.push(...getUserFilters.filters_data[doKey]);
        }
        return acc;
      }, []);

      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
        }));
      } else {
        // Update filtersOptions machineNumber with unique filtered machine numbers
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: Array.from(new Set(filteredMachineNumbers)),
        }));
      }
    }
  };

  const applyMachineNumberFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;
    const doFilters = filtersDict.DO;
    const machineNumberFilters = filtersDict.machineNumber;
    if (getUserFilters.access_level === "institution") {
      // Extract corresponding RO and DO keys for selected machine numbers
      const filteredRODO = Object.entries(
        getUserFilters?.filters_data || {}
      ).reduce((acc, [ro, roData]) => {
        Object.entries(roData || {}).forEach(([doKey, machines]) => {
          const matchingMachines = machines.filter((machine) =>
            machineNumberFilters.includes(machine)
          );
          if (matchingMachines.length > 0) {
            acc.push({ RO: ro, DO: doKey });
          }
        });
        return acc;
      }, []);

      // Extract unique RO and DO keys
      const uniqueRO = Array.from(
        new Set(filteredRODO.map((entry) => entry.RO))
      );
      const uniqueDO = Array.from(
        new Set(filteredRODO.map((entry) => entry.DO))
      );

      // Update filtersOptions with unique RO and DO keys
      if (machineNumberFilters.length === 0) {
        // console.log(
        //   "machineNumberFilters.length",
        //   machineNumberFilters.length,
        //   filtersOptions
        // );
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: filtersOptionsCopy["RO"],
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
          DO: doFilters.length === 0 ? uniqueDO : prevState.DO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract DO keys for selected machine numbers
      const filteredDOKeys = Object.keys(getUserFilters.filters_data).filter(
        (doKey) => {
          const machineNumbers = getUserFilters.filters_data[doKey];
          return machineNumbers.some((number) =>
            machineNumberFilters.includes(number)
          );
        }
      );

      if (machineNumberFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        // Update filtersOptions DO with unique filtered DO keys
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO:
            doFilters.length === 0
              ? Array.from(new Set(filteredDOKeys))
              : prevState.RO,
        }));
      }
    }
  };

  // const applyIssueTypeFilters = (filterDict) => {
  // Function to update filtersOptions based on selected issueType

  // Function to update filtersOptions based on selected issueType
  const applyIssueTypeFilters = (filterDict) => {
    if (filterDict?.issueType) {
      if (filterDict.issueType.length !== 0) {
        // Filter subIssueType values based on the selected issueType
        const filteredSubIssueType =
          getUserFilters?.issues?.reduce((acc, issue) => {
            const selectedIssueTypes = filterDict.issueType;
            for (const issueType of selectedIssueTypes) {
              if (issue[issueType]) {
                acc.push(
                  ...issue[issueType].sub_issue?.map((subIssue) => subIssue[1])
                );
              }
            }
            return acc;
          }, []) || [];
        // Update filtersOptions state with the filtered subIssueType values
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: Array.from(
            new Set([...(filters.subIssueType || []), ...filteredSubIssueType])
          ),
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: filtersOptionsCopy.subIssueType,
        }));
      }
    }
  };

  const applyCropVariationFilters = (filterDict) => {
    // Get the list of param_data keys from filters.crop_variation
    const selectedCrops = filterDict.crop_variation;

    // Filter client_params keys based on selectedCrops
    const clientParamsKeysArray = Object.entries(
      getUserFilters?.param_data || {}
    )
      .filter(([key]) => selectedCrops.includes(key)) // Filter based on selectedCrops
      .flatMap(([key, value]) => Object.keys(value.client_params));

    // Update filtersOptions.params_variation
    const updatedParamsVariation = Array.from(
      new Set([...(filters.params_variation || []), ...clientParamsKeysArray])
    );

    // console.log("updatedParamsVariation", updatedParamsVariation);
    if (selectedCrops.length) {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: updatedParamsVariation,
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: filtersOptionsCopy["params_variation"],
      }));
    }
  };

  const applyCoustomChecksFilter = (filterDict) => {
    if (
      filterDict["status"] &&
      !filterDict["status"].some(
        (status) => status.toLowerCase() === "resolved"
      )
    ) {
      filterDict["resolvedEndDate"] = [];
      filterDict["resolvedStartDate"] = [];
    }

    if (
      filterDict?.issueType &&
      !filterDict?.issueType?.includes("Data Variation")
    ) {
      filterDict["params_variation"] = [];
      filterDict["crop_variation"] = [];
      filterDict["modelNo"] = [];
      filterDict["commitId"] = [];
      filterDict["branchName"] = [];
    }

    if (filterDict?.issueType && filterDict?.issueType?.length === 0) {
      filterDict.subIssueType = [];
      filterDict.status = [];
    }

    if (
      filterDict?.crop_variation &&
      filterDict?.crop_variation?.length === 0
    ) {
      filterDict.params_variation = [];
    }

    return filterDict;
  };

  // filters ended

  useEffect(() => {
    setLoading(true);
    setData(null);
    document.title = `${capitalizeWords(productCategory)} Details - NEO`;
    const fetchDataAndUpdateProductDetail = async () => {
      // fetchFiltersDataApiCall
      let is_product_data_Mounted = true;
      const payload = {
        category: productCategory,
      };
      const api_data = await fetchApi(
        "get_product_types_details",
        "POST",
        payload,
        is_product_data_Mounted
      );
      // console.log("all_filter_data in All Ticket : ", api_data);
      if (api_data.statusCode === 200) {
        setData(api_data?.data?.product_type_data[0]);
        showErrorPage(null, null);
      } else if (api_data.statusCode === 500 || api_data.statusCode === 401) {
        setData(null);
        showErrorPage(api_data.statusCode, api_data.msg);
      } else {
        setData(null);
        // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        // console.log("Some Error Occured", all_filter_data);
        localStorage.clear();
        window.location.href = "/login";
      }
      setLoading(false);

      return () => {
        is_product_data_Mounted = false;
      };
    };
    fetchDataAndUpdateProductDetail();
    // console.log("data set to empty List - 2");
  }, []);

  return (
    <>
      {(!showError.status && (
        <>
          {/* <Dropdown
            blurTickets={handelPageBlur}
            filters={filters}
            filterCount={filterCount}
            handleFilterChange={handleFilterChange}
            handleSaveChanges={handleSaveChanges}
            handleFilterReset={handleFilterReset}
            getUserFilters={getUserFilters}
            getFilterResponse={getFilterResponse}
            filtersOptions={filtersOptions}
          />
          {allFiltersDisplay && (
            <AllFilters
              filters={showAllfilters}
              handelResetAllBtn={handelResetAllBtn}
              handelDeleteFilterBtn={handelDeleteFilterBtn}
              getUserFilters={getUserFilters}
            />
          )} */}

          {/* <div
            className={`${blurTickets} all-ticket-content ${
              allFiltersDisplay ? "m-top-0 p-top-0" : " "
            }`}
          > */}
          <div className={`${blurTickets} all-ticket-content pt-2 mt-1 }`}>
            {" "}
            {(loading && (
              // {lodingVisiblity && (
              // <div className="showSpinner all-ticket-spinner">
              //   <BeatLoader color="#2D97D4" />
              // </div>
              <>
                <div className="mx-3 my-3">
                  <TravelPageSkeleton
                    len={1}
                    accordionHeight={isSmallScreen ? 500 : 200}
                  />
                </div>
                <TableSkeleton rowsNum={12} colsNum={5} />
              </>
            )) || (
              <div className="container d-flex row-gap-2 flex-column">
                {/* Page top details */}
                <div className="count_heading_div text-end  pe-0 text-nowrap d-flex ">
                  <div className="upper-heading-container p-0 px-1 pt-2">
                    {/* Document info */}
                    <div className="info-part">
                      <span className="px-2 mb-1 info-heading">
                        <span style={{ textTransform: "capitalize" }}>
                          {productCategory}
                        </span>
                      </span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({
                          ProductType: productCategory,
                        })}
                      >
                        <i
                          className="bi bi-info-circle icon-color px-0"
                          id="info-icon"
                          style={{ fontSize: "15px", lineHeight: "24px" }}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex   ${
                    (isMediumScreen && "gap-3 flex-column") || "gap-1 flex-row"
                  }`}
                >
                  {/* Product info */}
                  <div
                    className=""
                    style={{ width: (isMediumScreen && "100%") || "250px" }}
                  >
                    <div className="h-100">
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding: "8px 0px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform: "none",
                          display: "flex",
                          flexDirection: (isMediumScreen && "row") || "column",
                          alignItems: isMediumScreen ? "flex-end" : "center",
                          justifyContent: isMediumScreen
                            ? "flex-end"
                            : "center",
                          gap: (isMediumScreen && "5px") || "10px",
                          height: "100%",
                        }}
                      >
                        {/* <span
                        style={{
                          //   border: "2px solid blue",
                          padding: "8px 0px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform: "none",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                          gap: "5px",
                          height: "100%",
                        }}
                      > */}
                        {/* "product Img" */}
                        {/* <div
                          className={`d-flex justify-content-center align-items-center `}
                          style={{
                            // backgroundColor: "#dddfff99",
                            backgroundColor: color.primary,
                            width: "120px",
                            height: "120px",
                            borderRadius: "15%",
                          }}
                        > */}
                        {/* <img
                          src={test_profile_img}
                          alt="product_img"
                          style={{
                            height: "auto",
                            width: (isMediumScreen && "100px") || "160px",
                          }}
                        /> */}

                        <img
                          src={
                            Data?.category_name === "scanner"
                              ? v39_scanner_img
                              : Data?.category_name === "nuc"
                              ? nuc_img
                              : Data?.category_name === "controller"
                              ? ardiuno_nano_controller_img
                              : test_profile_img
                          }
                          alt={`${Data?.category_name} image`}
                          className="mx-2"
                          style={{
                            height: "auto",
                            width: (isMediumScreen && "100px") || "160px",
                          }}
                        />
                        {/* </div> */}
                        {/* "product Description" */}
                        <div
                          className={`product_full_desc d-flex flex-column justify-content-center ${
                            (isMediumScreen &&
                              "align-items-start flex-grow-1") ||
                            "align-items-center"
                          } p-0 m-0 py-1 `}
                          style={{
                            fontFamily: "Open-Sans-Light",
                            color: color.taxtHeading,
                            fontWeight: 600,
                            fontSize: "1.25rem",
                            rowGap: "7px",
                            textTransform: "capitalize",
                          }}
                        >
                          <div
                            className="product_name p-0 m-0"
                            style={{ lineHeight: "1.25rem" }}
                          >
                            {Data?.category_name || productCategory}
                          </div>
                          {/* <div
                            className={`product_desc p-0 m-0  text-wrap lh-sm ${
                              (!isMediumScreen && "text-center") || ""
                            }`}
                            style={{
                              fontWeight: 400,
                              fontSize: "1.05rem",
                              lineHeight: "0.95rem",
                              maxWidth: (isMediumScreen && "95%") || "200px",
                            }}
                          >
                            {productType} desc/use Lorem ipsum dolor sit amet
                            consectetur adipisicing elit.
                          </div> */}
                        </div>
                      </span>
                    </div>
                    {/* Status overview */}
                    {false && (
                      <div className="">
                        <div className="average-div d-flex flex-row flex-wrap align-items-center justify-content-start w-100 gap-0 mt-1">
                          {/* top Div */}
                          {
                            <div
                              className="top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1"
                              style={{ minWidth: "120px" }}
                            >
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={showInventoryImg}
                                    alt="All Assesment img"
                                    className=""
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {10}
                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_10_days_data?.top_data?.region_scan
                                  )}
                                  scans)
                                </span> */}
                                </p>
                                Total Count
                              </div>
                            </div>
                          }

                          {/* vertical seperator line */}
                          {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                          {/* middel Div */}
                          {
                            <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={showReadyToUseImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}

                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {5}
                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_month_data["Top district"].scan
                                  )}{" "}
                                  scans)
                                </span> */}
                                </p>
                                Ready To Use
                              </div>
                            </div>
                          }
                          {/* Divider line */}

                          {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                          {/* bottom Div */}
                          {!isSmallScreen && (
                            <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={showInHouseImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {3}
                                </p>
                                In House
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {!isMediumScreen && (
                    <div
                      className={`barChartDivDescVerticalSeperator`}
                      style={{
                        height: "160px",
                      }}
                    />
                  )}
                  {isMediumScreen && (
                    <div class="barChartDivDescHorizontalSeperator"></div>
                  )}
                  {/* status count */}
                  {false && (
                    <div
                      className=" position-relative "
                      style={{
                        minWidth: "400px",
                        overflow: "hidden",
                        padding: (isMediumScreen && "4px") || "0px",
                      }}
                    >
                      <div
                        className="ps-2"
                        style={{
                          color: color.taxtHeading,
                          fontSize: "1.15rem",
                          height: "100%",
                        }}
                      >
                        {/* heading */}
                        <div
                          className="bar-chart-heading"
                          style={{ fontSize: "1.25rem" }}
                        >
                          Status Overwiew
                        </div>
                        {/* sub heading for desc */}
                        <p
                          style={{
                            fontSize: "0.68rem",
                            marginTop: "0px",
                            textTransform: "capitalize",
                          }}
                        >
                          {/* <span
                              className="bar-chart-num"
                              style={{
                                marginRight: "5px",
                                color: color.taxtHeading,
                              }}
                            >
                              {formatInIndianRupees(
                                data?.last_10_days_data?.active_data?.total
                              )}
                            </span> */}
                          {productCategory} count in each status
                        </p>
                        {/* desc for the status */}
                        <div
                          className="status-desc"
                          style={{ marginTop: "110px", fontSize: "0.95rem" }}
                        >
                          <span style={{ fontSize: "1rem", fontWeight: 700 }}>
                            {
                              Data?.last_10_days_data?.cropwise_chart[
                                activePieIndex
                              ]?.total
                            }
                          </span>
                          <span
                            style={{
                              fontSize: "0.85rem",
                              fontWeight: 400,
                              padding: "0px 5px",
                              textTransform: "capitalize",
                            }}
                          >
                            {productCategory}
                          </span>
                          are
                          <span
                            style={{
                              fontSize: "0.85rem",
                              fontWeight: 700,
                              padding: "0px 5px",
                              textTransform: "capitalize ",
                            }}
                          >
                            {
                              Data.last_10_days_data.cropwise_chart[
                                activePieIndex
                              ].crop
                            }
                          </span>
                        </div>
                      </div>
                      {/* pie-graph for status  */}
                      <div
                        className="pie-chart-div d-flex align-items-center justify-content-center position-absolute top-0 end-0"
                        style={{
                          margin:
                            // (isSmallSmallScreen && "-90px -90px") ||
                            // (isSmallScreen && "-90px -100px") ||
                            // (isMediumScreen && "-80px -100px") ||
                            "-70px -100px",
                          height: "350px",
                          width: "auto",
                        }}
                      >
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={activePieIndex}
                            activeShape={renderActiveShape}
                            data={Data?.last_10_days_data?.cropwise_chart || []}
                            cx={200}
                            cy={175}
                            innerRadius={50}
                            outerRadius={65}
                            fill={color.secondary}
                            dataKey="total"
                            startAngle={90}
                            endAngle={-270}
                            onMouseEnter={onPieEnter}
                            onMouseLeave={onPieLeave}
                          >
                            {Data?.last_10_days_data?.cropwise_chart.map(
                              (entry, index) => (
                                <Cell
                                  key={`pie-cell-${index}`}
                                  fill={findColorByStatus(entry.crop)}
                                />
                              )
                            )}
                          </Pie>
                        </PieChart>
                      </div>
                    </div>
                  )}

                  {/* stage analysis */}
                  <div
                    className={`flex-grow-1 ${
                      (isMediumScreen && "ms-4 me-1") || "mx-1"
                    }`}
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fit,minmax(200px , 1fr))",
                      gap: "20px",
                    }}
                  >
                    {Data?.stage_product_count?.map(
                      ({ stage, count }, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            maxHeight: "65px",
                          }}
                        >
                          <span
                            style={{
                              //   border: "2px solid blue",
                              // padding: "10px 0px",
                              borderRadius: "4px",
                              backgroundColor: "transparent",
                              textTransform: "none",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              gap: "8px",
                              width: "150px",
                              minHeight: "55px",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: findColorByStatus(stage),
                                width: "4px",
                                height: "100%",
                                borderRadius: "40px",
                              }}
                            />
                            <div
                              className="product_full_desc d-flex flex-column justify-content-between p-0 m-0 h-100 py-1 pb-0 lh-sm "
                              style={{
                                fontFamily: "Open-Sans-Light",
                                color: color.tableRowHeading,
                                fontWeight: 200,
                                fontSize: "0.895rem",
                                rowGap: "2px",
                                textTransform: "capitalize",
                              }}
                            >
                              <div className=" p-0 m-0">{stage}</div>
                              <div
                                className=" p-0 m-0"
                                style={{
                                  color: color.taxtHeading,
                                  fontWeight: 600,
                                  fontSize: "1.125rem",
                                }}
                              >
                                {count}
                              </div>
                            </div>
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="my-3">
                  <div className="card-Div p-0 ">
                    <div className="d-flex flex-column gap-1">
                      <h5
                        style={{
                          fontSize: "1.12rem",
                          padding: "20px 22px",
                          marginBottom: "4px",
                          // fontWeight: "600",
                        }}
                      >
                        Product Details
                      </h5>
                      <div className="">
                        <Box
                          sx={{
                            height: "60vh",
                            // (Data?.tickets_limit === "All" && "55vh") ||
                            // gridHeight,
                            width: "100%",
                          }}
                        >
                          <DataGrid
                            rows={TabelDataRows}
                            columns={TableDataHeaders}
                            // pagination={false} // Disable pagination
                            // pagination={20} // Disable pagination
                            // autoPageSize // Automatically adjust page size based on available height
                            density="standard"
                            paginationMode="client"
                            // components={{
                            //   Pagination:
                            //     visibleRows > 100 ? CustomPagination : null,
                            // }}
                            // hideFooterPagination
                            // pageSize={20}
                            disableSelectionOnClick
                            disableRowSelectionOnClick
                            // disableColumnFilter
                            onRowClick={(param) => {
                              // console.log("row_param : ", param.row);
                              // if (param.row) {
                              //   handleTableRowClick(param.row);
                              // }
                            }}
                            //   getRowClassName={(params) => {
                            //     // console.log(
                            //     //   "params : ",
                            //     //   params,
                            //     //   params.row.id === selectedRow?.id
                            //     // );
                            //     return params.row.id === selectedRow?.id ? "selected-row" : "";
                            //   }}
                            sx={{
                              "&.MuiDataGrid-root": {
                                border: "none",
                                borderRadius: "5px", // Adjust border radius as needed
                                overflow: "hidden", // Ensure content respects the border radius,
                                padding: "0px",
                                margin: "0px",
                                marginTop: "-10px",
                                borderColor: "white",
                              },
                              ".MuiDataGrid-columnHeader": {
                                backgroundColor: color.primary,
                                border: "none",
                              },
                              ".MuiDataGrid-filler": {
                                backgroundColor: color.textWhiteColor,
                              },
                              ".MuiDataGrid-columnHeaderTitle": {
                                fontFamily: "Open-Sans-Light",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                fontSize: "0.85rem",
                                textTransform: "capitalize",
                                color: color.taxtHeading,
                              },
                              ".MuiDataGrid-columnHeader--alignCenter": {
                                headerAlign: "left",
                              },
                              ".MuiDataGrid-row": {
                                border: "none", // Remove border from rows
                                backgroundColor: color.textWhiteColor, // Set rows background color to white
                                "&:hover": {
                                  backgroundColor: color.primary, // Ensure background color remains white on hover
                                  // cursor: "pointer",
                                },
                              },
                              ".MuiDataGrid-footerContainer": {
                                display:
                                  // (visibleRows <= 50 && "none") ||
                                  "inline-block", // Hide the pagination footer
                                justifyContent: "space-between", // Space out selected row count and pagination controls
                                alignItems: "center", // Vertically align items in the footer
                                // marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                                // height: "30px",
                              },
                              ".MuiTablePagination-root": {
                                // ".MuiInputBase-root": {
                                //   display: "none",
                                // },

                                // ".MuiTablePagination-selectLabel": {
                                //   display: "none",
                                // },
                                ".MuiToolbar-root": {
                                  marginTop: "0px",
                                },
                              },
                              ".MuiDataGrid-cell": {
                                // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                                outline: "none",
                                // display: "-webkit-box",
                                // WebkitBoxOrient: "vertical",
                                // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                "&:focus": {
                                  outline: "none", // Remove the blue outline when a cell is focused
                                },
                              },
                              "&.selected-row": {
                                backgroundColor: "red", // Highlight color for the selected row
                                "&:hover": {
                                  backgroundColor: "red ", // Ensure highlight color remains on hover
                                },
                              },
                              ".MuiDataGrid-selectedRowCount": {
                                display: "none", // Hide the selected row count div
                              },
                              ".MuiTablePagination-toolbar": {
                                // width: "80%",  // Adjust the width here
                                // margin: "0 auto", // Center align the pagination
                                // marginLeft:"auto",
                                padding: "2px 16px", // You can adjust padding as well if needed
                                marginTop: "-15px",
                              },
                              "& .MuiDataGrid-scrollbarFiller": {
                                backgroundColor: color.primary,
                              },
                              "& .MuiDataGrid-scrollbarFiller--header": {
                                backgroundColor: color.primary,
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
                {/* work on it */}
                {/* <div className="p-1 py-2 m-0">
                  {visibleRows > 100 && (
                    <div className=" pagination-head-container p-0 m-0">
                      <ReactPaginate
                        // pageCount={Math.ceil(totalCardsCount / cardsPerPage)}
                        pageCount={Math.ceil(totalCardsCount / cardsPerPage)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={(e) => {
                          handelCurrentPage(e.selected);
                        }}
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                        renderOnZeroPageCount={null}
                        forcePage={currentPage} // Ensure active page is highlighted
                      />
                    </div>
                  )}
                </div> */}
              </div>
            )}
          </div>
        </>
      )) ||
        (showError.status && <Error />) || <Error code={500} />}
    </>
  );
};

export default SpecificProductType;
