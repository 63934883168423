import { Outlet, Navigate } from "react-router-dom";
import ContextState from "../contextApi/ContextState";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import Error from "../error/Error";
import { CardPagesSkeleton } from "../Exporters/ExportFunctions";

const PrivateRoutes = ({ showSideBarError, handelshowFirstPageError }) => {
  const userDetails = useSelector((state) => state.userDetail);
  const onlineStatus = useSelector((state) => state.onlineStatus.online);
  const { key, url, SetNewAccessToken } = useContext(ContextState);
  let auth = { token: localStorage.getItem(key) };

  const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000;

  const call_refresh_after_half_hour = async () => {
    try {
      // console.log("func called for refresh token")
      const currentTime = new Date().getTime();
      const refreshTokenTimestamp = localStorage.getItem(
        "refreshTokenTimestamp"
      );

      if (
        auth?.token &&
        refreshTokenTimestamp &&
        !isNaN(refreshTokenTimestamp) &&
        currentTime - refreshTokenTimestamp >= THIRTY_MINUTES_IN_MS
      ) {
        // console.log("diff in times:", currentTime - refreshTokenTimestamp)
        const response = await fetch(url + "refresh_user_token", {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({}),
        });

        const responseData = await response.json();
        const statusCode = response.status;

        if (statusCode === 200) {
          handelshowFirstPageError(null, null);
          // console.log("response", responseData?.access_token);
          SetNewAccessToken(responseData?.access_token);
          localStorage.setItem("refreshTokenTimestamp", currentTime.toString());
        } else {
          // handelshowFirstPageError(statusCode, responseData?.msg);
          console.log(
            "refresh_user_token with statusCode {" +
              statusCode +
              "} error 1 : ",
            responseData
          );
        }
      }
    } catch (error) {
      // handelshowFirstPageError(500, error);
      console.log(
        "refresh_user_token with statusCode {" + 500 + "} error 2 : ",
        error
      );
    }
  };

  // useEffect(() => {
  //   let isMounted = true;

  //   const intervalId = setInterval(() => {
  //     if (isMounted) {
  //       call_refresh_after_half_hour();
  //     }
  //   }, 60000);

  //   return () => {
  //     isMounted = false;
  //     clearInterval(intervalId);
  //   };
  // }, []);

  if (showSideBarError?.status) {
    return <Error code={showSideBarError.status} msg={showSideBarError.msg} />;
  } else {
    return onlineStatus ? (
      auth?.token ? (
        userDetails?.data ? (
          <Outlet />
        ) : (
          // <div
          //   className="showSpinner all-ticket-spinner"
          //   style={{ height: "100vh", zIndex: 5 }}
          // >
          //   <BeatLoader color="#2D97D4" />
          // </div>
          <div className="mx-2">
            <CardPagesSkeleton len={6} cardHeight={300} componentClass={"cards-parent1"} />
          </div>
        )
      ) : (
        <Navigate to="/login" />
      )
    ) : (
      <Error code={505} msg={"No Internet"} />
    );
  }
};

export default PrivateRoutes;
