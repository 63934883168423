import React, { useContext, useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import "../../assets/styles/CardModal.css";
import ContextState from "../contextApi/ContextState";
import ModalError from "../error/ModalError";
import { BeatLoader } from "react-spinners";
import { TimelineSkeleton } from "../Exporters/ExportFunctions";
import { useMediaQuery } from "@mui/material";

export default function LeftAlignedTimeline({
  data,
  reloadTimelinePage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) {
  const { ticketId } = data;
  const [timelineData, setTimelineData] = useState(null);

  const {
    url,
    formatDate,
    fetchApi,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    modalControllerRef,
  } = useContext(ContextState);
  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(true);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  const isSmallSmallScreen = useMediaQuery("(max-width:415px)");

  useEffect(() => {
    let isMounted = true;
    showLoadingModal(true);
    const fetchCardData = async () => {
      try {
        let payload = {
          info_type: "timeline",
          ticket_id: ticketId,
        };

        if (refreshBtn) {
          setRefreshLoading(true);
        }

        let jsonData;
        if (isMounted) {
          jsonData = await fetchApi(
            "get_ticket_info",
            "POST",
            payload,
            true,
            1
          );

          // console.log("timeline data recieved successfully:", jsonData)
          if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
            setTimelineData(jsonData.data.timeline);
            showModalErrorPage(null, null);
          } else if (
            jsonData.statusCode === 500 ||
            jsonData.statusCode === 401
          ) {
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
          } else {
            // showModalErrorPage(jsonData.statusCode, jsonData.msg);
            showModalErrorPage(jsonData.statusCode, jsonData.msg);
          }
        }
        showLoadingModal(false);
      } catch (error) {
        if (modalControllerRef.current) {
          openSnackbar(
            "Your data is on its way. Thank you for your patience.",
            "warning"
          );
        } else {
          openSnackbar("Some Error Occured!", "error");
        }
      } finally {
        setRefreshLoading(false);
        setRefreshBtn(false);
      }
    };

    fetchCardData();
    // showLoadingModal(false);
    return () => {
      isMounted = false;
    };
  }, [reloadTimelinePage]);

  const getDotColor = (eventType) => {
    switch (eventType) {
      case "creation":
        return "red";
      case "resolved":
        return "green";
      default:
        return "blue";
    }
  };

  return (
    <>
      {(lodingModalVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <>
          <div className="m-3">
            <TimelineSkeleton isSmallSmallScreen={isSmallSmallScreen} />
          </div>
        </>
      )) ||
        (!showModalError.status && !lodingModalVisiblity && (
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.6,
              },
            }}
            className="mt-4"
          >
            {timelineData?.map((item, index) => (
              <TimelineItem key={index} className="timeline-page">
                <TimelineOppositeContent
                  color="textSecondary"
                  style={{
                    fontFamily: "Open-Sans-regular",
                    color: "rgba(146, 146, 146, 0.88)",
                    padding: "6px 8px",
                    fontSize: "14px",
                  }}
                >
                  {formatDate(item.creation_time)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    style={{
                      backgroundColor: getDotColor(item.event_type),
                      padding: "3px",
                      marginTop: "12px",
                    }}
                  />
                  {index < timelineData.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent
                  style={{
                    fontFamily: "Open-Sans-regular",
                    color: "rgba(76, 78, 100, 0.88)",
                    padding: "6px 10px",
                    fontSize: "16px",
                  }}
                >
                  {item.event}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        )) ||
        (showModalError.status && <ModalError />) || <ModalError code={500} />}
    </>
  );
}
