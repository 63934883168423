import React, { useEffect, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

const ProductTypeDetails = () => {
  const {
    color,
    ProductTypeData,
    formatInIndianRupees,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
  } = useOutletContext();
  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "23px",
    iconBackgroundWidth: "45px",
    iconBackgroundHeight: "45px",

    monthlyScanDivDescIconWidth: "20px",
    monthlyScanDivDescIconBackgroundWidth: "40px",
    monthlyScanDivDescIconBackgroundHeight: "40px",
  };
  return (
    <div className="">
      <Outlet
        context={{
          color: color,
          ProductTypeData: ProductTypeData,
          formatInIndianRupees: formatInIndianRupees,
          pageStyling: pageStyling,
          isMediumScreen: isMediumScreen,
          isSmallScreen: isSmallScreen,
          isSmallSmallScreen: isSmallSmallScreen,
          islageScreen: islageScreen,
          islagelargeScreen: islagelargeScreen,
        }}
      />
    </div>
  );
};

export default ProductTypeDetails;
