// import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../contextApi/ContextState";
import { useSelector } from "react-redux";
import AddScanIds from "./AddScanIds";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import close from "../../assets/images/close.svg";

function ScanDetailsModal({
  show,
  handleClose,
  machineData,
  ticketId,
  handleReloadData,
}) {
  const { fetchApi, openSnackbar } = useContext(ContextState);

  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );

  const [loading, setLoading] = React.useState(false);

  // these state variables are for maintaining data-variation details
  const [variationcrop, setVariationcrop] = useState(null);
  const [ParamKey, setParamKey] = useState([]);
  // //console.log("Parameters Selected Here:", ParamKey);

  // these state variables are for scan details component
  // const [machine, setMachine] = useState(null);
  const [scanId, setScanId] = useState("");
  const [paramValues, setParamValues] = useState({});

  const [addNewScanDetails, setAddNewScanDetails] = useState({
    date: null,
    isOpen: false,
    fetch_api: false,
    machine: null,
    crop_id: null,
    // scan_ids: [],
    pull_data: false,
    custom_scan_id: [],
  });

  // console.log("news", addNewScanDetails);
  const [addedScanDetails, setAddedScanDetails] = useState([]);
  const [final_scanIds, setFinal_ScanIds] = useState([]);

  // State Variables for modal Details
  const [modalMachines, setModalMachines] = useState(null);
  const [commitId, setCommitId] = useState("");
  const [modalNumber, setModalNumber] = useState("");
  const [modalCrop, setModalCrop] = useState(null);
  const [gitBranch, setGitBranch] = useState(null);
  const [modalDetailsList, setModalDetailsList] = useState([]);

  const [scanIdData, setScanIdData] = useState(null);
  // //console.log(machineData, "machine", machine);
  //this is to take care of individual scan details section
  const handleParamChange = (paramKey, type, value) => {
    setParamValues((prevValues) => ({
      ...prevValues,
      [paramKey]: {
        ...prevValues[paramKey],
        [type]: value,
      },
    }));
  };

  //this is to take care of data scan detail section globally
  const [scanDetailsList, setScanDetailsList] = useState([]);

  const handleAddScanDetails = () => {
    if (addNewScanDetails?.machine && scanId && addNewScanDetails?.crop_id) {
      setScanDetailsList((prevList) => [
        ...prevList,
        {
          machine: addNewScanDetails?.machine,
          scanId: scanId,
          crop: addNewScanDetails?.crop_id,
          pullData: addNewScanDetails?.pull_data,
          params: { ...paramValues },
        },
      ]);

      setAddNewScanDetails({
        date: null,
        isOpen: false,
        fetch_api: false,
        machine: null,
        crop_id: null,
        // scan_ids: [],
        pull_data: false,
        custom_scan_id: [],
      });

      setScanId("");

      // Clear input fields and reset pullData
      // setMachine(null);
      // setScanId("");
      // setCrop(null);
      setParamValues({});
      // setPullData(false);
    }
  };

  const add_scan_details_global = async (data) => {
    data?.forEach((element, index) => {
      setScanDetailsList((prevList) => [
        ...prevList,
        {
          machine: addNewScanDetails?.machine,
          scanId: element["scan_id"],
          crop: addNewScanDetails?.crop_id,
          pullData: addNewScanDetails?.pull_data,
          params: element["param_value"],
        },
      ]);
    });
    // console.log("**********", scanDetailsData);
    // console.log("**********", scanDetailsData);
  };

  // this is to handle data variation section
  // const handleSaveChanges = () => {
  //     if (variationcrop && ParamKey) {
  //         setElementList(prevList => [...prevList, { crop: variationcrop, param: ParamKey }]);
  //         setVariationcrop(null);
  //         setParamKey(null);
  //     }
  // };

  // this is to handle data-variation details globally
  const [elementList, setElementList] = useState([]);
  const handleSaveChanges = () => {
    if (variationcrop && ParamKey.length > 0) {
      ParamKey.forEach((param) => {
        setElementList((prevList) => [
          ...prevList,
          { crop: variationcrop, param },
        ]);
      });
      setVariationcrop(null);
      setParamKey([]);
    }
  };

  // this is to remove data-variation details
  const removeElement = (index) => {
    setElementList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const removeScanDetail = (indexToRemove) => {
    setScanDetailsList((prevScanDetails) =>
      prevScanDetails.filter((_, index) => index !== indexToRemove)
    );
  };

  const getCropName = (cropId) => {
    for (const [key, value] of Object.entries(filterData.param_data)) {
      if (value.crop_id === cropId) {
        return value.matt_crop;
      }
    }
    return null; // Return null if the crop ID is not found
  };

  const getGitBranchId = (gitBranch) => {
    for (const [key, value] of Object.entries(filterData?.git_branch_list)) {
      if (value === gitBranch) {
        return key;
      }
    }
    return null;
  };
  const getGitBranchName = (gitBranch) => {
    for (const [key, value] of Object.entries(filterData?.git_branch_list)) {
      if (key === gitBranch) {
        return value;
      }
    }
    return null;
  };

  // add modal details globally here
  const handleModalDetails = () => {
    const newDetails = {
      machine_code: modalMachines,
      commit_id: commitId,
      model_no: modalNumber,
      crop_id: getCropId(modalCrop),
      git_branch: getGitBranchId(gitBranch),
    };
    // //console.log("adding new model details", commitId);
    setModalDetailsList((prevList) => [...prevList, newDetails]);
    // Clear input fields
    setModalMachines(null);
    setCommitId("");
    setModalNumber("");
    setModalCrop(null);
    setGitBranch(null);
  };

  const handleDeleteElement = (index) => {
    setModalDetailsList((prevList) => prevList.filter((_, i) => i !== index));
  };

  // //console.log("manual value from input entries-----", paramValues)

  const handleChange = (event, setStateFunction) => {
    setStateFunction(event.target.value);
  };

  // function made to fetch crop ids based on name
  const getCropId = (cropName) => {
    for (const [key, value] of Object.entries(filterData.param_data)) {
      if (value.matt_crop === cropName) {
        return value.crop_id;
      }
    }
    return null;
  };

  // Function to get param_id based on crop_name and param_name
  const getParamId = (cropName, paramName) => {
    const cropData = filterData.param_data[cropName];
    if (cropData && cropData.client_params[paramName]) {
      return cropData.client_params[paramName];
    }
    return null;
  };

  // Prepare scan details data
  const scanDetailsData = scanDetailsList.map((scanDetail) => {
    // Prepare params data for each scan detail
    const paramsData = Object.entries(scanDetail.params).map(
      ([paramKey, paramValue]) => ({
        param_id: getParamId(scanDetail.crop, paramKey),
        matt_value: paramValue.matt || null,
        manual_value: paramValue.manual || null,
      })
    );

    return {
      scan_id: scanDetail.scanId,
      machine_code: scanDetail.machine,
      crop_id: getCropId(scanDetail.crop),
      pull_scan: scanDetail.pullData,
      params: paramsData,
    };
  });

  // console.log("scan details ", scanDetailsData);
  // Prepare variation data
  const variationData = elementList.map((element) => ({
    crop_id: getCropId(element.crop),
    param_id: getParamId(element.crop, element.param),
  }));

  const handleChangesinDB = async () => {
    try {
      // Construct the payload
      const payload = {
        ticket_id: ticketId,
        scan_details: scanDetailsData,
        variation_data: variationData,
        model_details: modalDetailsList,
      };
      setLoading(true);

      // console.log("payload final:---", payload);

      const response = await fetchApi(
        "add_ticket_scan_details",
        "POST",
        payload,
        true
      );

      // Check if the response is successful
      if (response.statusCode === 200) {
        // Reload the React component
        // //console.log("ticket edited succesfully")
        openSnackbar("Scan details added succesfully", "success");
        handleClose();
        handleReloadData();
        setScanDetailsList([]);
        setElementList([]);
        setModalDetailsList([]);
        // setting the state to intial
        setAddNewScanDetails({
          date: null,
          isOpen: false,
          fetch_api: false,
          machine: null,
          crop_id: null,
          // scan_ids: [],
          pull_data: false,
          custom_scan_id: [],
        });
      } else {
        //console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      //console.error("Internal server error:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      setLoading(false);
    }
  };

  // handling date mui date picker
  const handleInputChange = (name, value) => {
    //console.log(name, "name------->");
    if (name === "isOpen") {
      setAddNewScanDetails((prevState) => ({
        ...prevState,
        [name]: value,
        fetch_api: true,
      }));
    } else {
      setAddNewScanDetails((prev) => ({ ...prev, [name]: value }));
    }
    // //console.log("addNewScanDetails---------->", addNewScanDetails);
  };

  function handleCloseAddScanModal(event, reason) {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setAddNewScanDetails((prev) => ({
      ...prev,
      isOpen: false,
      fetch_api: false,
    }));
  }

  const addinngScandetails = async (selectedIds) => {
    try {
      const payload = {
        scan_ids: [...selectedIds],
      };

      // setLoading(true);

      //console.log("final payload for matt ", payload);
      const response = await fetchApi(
        "get_scans_matt_values",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        setAddedScanDetails(response?.data?.data);
        // //console.log("Scan IDs fetched successfully:", response?.data?.data);
        //console.log("int", addedScanDetails);
        await add_scan_details_global(response?.data?.data);

        //console.log("final scans", final_scanIds);
        handleCloseAddScanModal();
        setAddNewScanDetails({
          date: null,
          isOpen: false,
          fetch_api: false,
          machine: null,
          crop_id: null,
          // scan_ids: [],
          pull_data: false,
          custom_scan_id: [],
        });
      } else {
        console.error("Error fetching scan IDs:", response.statusCode);
      }
    } catch (error) {
      console.error("Internal server error:", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    return () => {};
  });
  const modal_sub_style = {
    position: "relative",
    top: "10%",
    margin: "  auto",
    borderRadius: "10px",
    border: "none",
    backgroundColor: "white",
    maxWidth: "1250px",
    width: "88vw",
    overflowY: "scroll",
  };

  return (
    <Modal
      // size="xl"
      style={{
        position: "fixed",
        height: "100%",
        overflowY: "scroll",
        margin: "0px 0px 0px 10px",
      }}
      className="modal-container"
      open={show}
      onClose={handleClose}
      // backdrop="static"
    >
      <Box
        style={modal_sub_style}
        // className="col-10 col-sm-10 col-md-8 col-lg-8"
      >
        <Box style={{ padding: "16px", borderBottom: "1px solid #f5f5f5" }}>
          <div className="d-flex justify-content-between">
            <h5 className="m-0">Edit Scan Details</h5>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </Box>
        <Box style={{ padding: "16px" }}>
          <div
            className="accordion edit-machine-detail-page"
            id="accordionPanelsStayOpenExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Edit Data Variation Details
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body">
                  <div className="edit-data-variation">
                    <div className="edit-data-variation-header">
                      <header>Add Data Variation</header>
                      <button
                        variant="primary"
                        className="btn btn-primary"
                        disabled={
                          variationcrop === null || ParamKey.length === 0
                        }
                        onClick={handleSaveChanges}
                      >
                        Add
                      </button>
                    </div>

                    <Box
                      className="basic_details"
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "45ch" },
                        "@media (max-width: 735px)": {
                          "& .MuiTextField-root": { width: "100%" },
                        },
                        "@media (max-width: 735px)": {
                          "& .MuiFormControl-root": { m: 1, width: "100%" },
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl sx={{ m: 1, width: "45ch" }} size="medium">
                        <InputLabel id="crop-label">Crop</InputLabel>
                        <Select
                          labelId="crop-label"
                          value={variationcrop}
                          // onChange={handleChangevariationcrop}
                          onChange={(e) => handleChange(e, setVariationcrop)}
                          label="Crop"
                        >
                          {filterData?.param_data &&
                            Object.keys(filterData.param_data).map(
                              (crop, index) => (
                                <MenuItem key={index} value={crop}>
                                  {crop}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>

                      {/* <div style={{ width: '200px' }}> */}
                      <FormControl sx={{ m: 1, width: "45ch" }} size="medium">
                        <InputLabel id="param-label">Parameter</InputLabel>
                        <Select
                          labelId="param-label"
                          value={ParamKey}
                          onChange={(e) => handleChange(e, setParamKey)}
                          label="Parameter"
                          multiple
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxWidth: "45ch",
                              },
                            },
                          }}
                          inputProps={{
                            style: {
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            },
                          }}
                        >
                          {filterData &&
                            variationcrop &&
                            filterData.param_data[variationcrop] &&
                            Object.entries(
                              filterData.param_data[variationcrop][
                                "client_params"
                              ]
                            ).map(([paramKey, paramValue]) => (
                              <MenuItem key={paramKey} value={paramKey}>
                                {paramKey}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {/* </div> */}
                    </Box>
                    {/* Render the list of elements */}
                    <div className="data-variation-elements-container">
                      {elementList.map((element, index) => (
                        <div key={index} className="data-variation-elements">
                          {element.crop}, {element.param}
                          <span
                            className="px-2 cross-btn-data-variation"
                            variant="outlined"
                            onClick={() => removeElement(index)}
                          >
                            x
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Edit ScanID Detail
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body">
                  <div className="edit-scan-details">
                    <div className="edit-data-variation-header">
                      <header>Add Scan Details</header>
                      <button
                        variant="primary"
                        className="btn btn-primary"
                        onClick={handleAddScanDetails}
                        disabled={
                          scanId === "" ||
                          addNewScanDetails?.machine === null ||
                          addNewScanDetails?.crop_id === null
                        }
                      >
                        Add
                      </button>
                    </div>

                    <Box
                      className="basic_details"
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "32ch" },
                        "@media (max-width: 995px)": {
                          "& .MuiTextField-root": { width: "100%" },
                        },
                        "@media (max-width: 995px)": {
                          "& .MuiFormControl-root": { m: 1, width: "100%" },
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl sx={{ m: 1, width: "32ch" }} size="medium">
                        <InputLabel id="machine-label">Machine</InputLabel>
                        <Select
                          labelId="machine-label"
                          value={addNewScanDetails?.machine}
                          onChange={(e) =>
                            handleInputChange("machine", e.target.value)
                          }
                          label="Machine"
                        >
                          {machineData?.length &&
                            machineData.map((item, index) => (
                              <MenuItem key={index} value={item["code"]}>
                                {item["code"]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      <FormControl sx={{ m: 1, width: "32ch" }} size="medium">
                        <InputLabel id="crop-label">Crop</InputLabel>
                        <Select
                          labelId="crop-label"
                          value={addNewScanDetails?.crop_id}
                          onChange={(e) =>
                            handleInputChange("crop_id", e.target.value)
                          }
                          label="Crop"
                        >
                          {filterData?.param_data &&
                            Object.keys(filterData.param_data).map(
                              (crop, index) => (
                                <MenuItem key={index} value={crop}>
                                  {crop}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>

                      {/* date picker */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          // name="date"
                          value={addNewScanDetails?.date}
                          onChange={(date) => handleInputChange("date", date)}
                        />
                      </LocalizationProvider>

                      <TextField
                        id="outlined-multiline-flexible"
                        label="Scan Id"
                        value={scanId}
                        onChange={(e) => setScanId(e.target.value)}
                        multiline
                        maxRows={4}
                        required
                      />

                      <div className="pull-data align-self-center m-2">
                        {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={addNewScanDetails?.pull_data}
                            onChange={() => {
                              handleInputChange(
                                "pull_data",
                                !addNewScanDetails?.pull_data
                              );
                            }}
                          />
                        }
                        label="Pull Data"
                      /> */}
                        <FormControlLabel
                          control={
                            <Switch
                              checked={addNewScanDetails?.pull_data}
                              onChange={() => {
                                handleInputChange(
                                  "pull_data",
                                  !addNewScanDetails?.pull_data
                                );
                              }}
                            />
                          }
                          label="Pull Data"
                        />
                      </div>

                      <Tooltip
                        data-html="true"
                        title={"Click on more to select scan ids"}
                        arrow={true}
                      >
                        <Button
                          variant="outlined"
                          // style={{
                          //   alignSelf: "center",
                          //   // cursor: "pointer",
                          //   color: "#3b71ca",
                          //   border: "1px solid #3b71ca",
                          //   backgroundColor: "#fff",
                          //   padding: "4px 8px",
                          //   boxShadow: "none",
                          //   cursor: "pointer",
                          // }}
                          // className="more_details_hover"
                          className="m-3"
                          disabled={
                            (addNewScanDetails?.date &&
                              addNewScanDetails.crop_id &&
                              addNewScanDetails?.machine) == null || scanId
                              ? true
                              : false
                          }
                          onClick={() => {
                            // e.stopPropagation();
                            handleInputChange("isOpen", true);
                            // hanldeFetchScanIds();
                          }}
                        >
                          Select Scan Id
                        </Button>
                      </Tooltip>
                    </Box>

                    {filterData &&
                      addNewScanDetails?.crop_id &&
                      scanId &&
                      filterData.param_data[addNewScanDetails?.crop_id] &&
                      filterData.param_data[addNewScanDetails?.crop_id][
                        "client_params"
                      ] && (
                        <div className="crop-details-container">
                          <div className="row mb-3 heading-parameters">
                            <div className="col-6 ">Parameters</div>
                            <div className="col-3 ">Manual</div>
                            <div className="col-3 ">Matt</div>
                          </div>
                          {Object.entries(
                            filterData.param_data[addNewScanDetails?.crop_id][
                              "client_params"
                            ]
                          ).map(([paramKey, paramValue]) => (
                            <div
                              key={paramValue}
                              className="param-column row mb-2"
                            >
                              <div className="param-name col-6">{paramKey}</div>
                              <div className="col-3">
                                <input
                                  className="form-control"
                                  type="number"
                                  value={paramValues[paramKey]?.manual || ""}
                                  placeholder=""
                                  onChange={(e) =>
                                    handleParamChange(
                                      paramKey,
                                      "manual",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-control"
                                  type="number"
                                  value={paramValues[paramKey]?.matt || ""}
                                  placeholder=""
                                  onChange={(e) =>
                                    handleParamChange(
                                      paramKey,
                                      "matt",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                    {/* Render the list of scan details */}
                    {scanDetailsList.length > 0 && (
                      <div className="mt-3 scan-detail">
                        {scanDetailsList.map((scanDetail, index) => (
                          <>
                            <div key={index} className="modal-col-style">
                              <div className="d-flex justify-content-between px-1 mb-3">
                                <div
                                  className="scanid-title-modal"
                                  style={{
                                    maxWidth: "70%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {scanDetail.scanId}
                                </div>

                                <Tooltip
                                  data-html="true"
                                  title={
                                    "Click here to delete this selected scan id"
                                  }
                                  arrow={true}
                                  placement="right"
                                >
                                  <button
                                    className="btn btn-outline-danger btn-delete-scan-detail"
                                    onClick={() => removeScanDetail(index)}
                                  >
                                    Delete
                                  </button>
                                </Tooltip>
                              </div>
                              <div
                                className={`${
                                  scanDetail.pullData
                                    ? "status-pull-data"
                                    : "status-no-pull-data"
                                }`}
                              >
                                {scanDetail.pullData ? "Pull Data" : "No Pull"}
                              </div>

                              {/* <div className="card-body"> */}
                              <div className="row mb-2">
                                <div className=" col-5 text-center text-nowrap sub-heading">
                                  Param
                                </div>
                                <div className=" col-4 text-center text-nowrap sub-heading">
                                  Manual
                                </div>
                                <div className=" col-3 text-center text-nowrap sub-heading">
                                  Matt
                                </div>
                              </div>
                              {/* <div className="params"> */}
                              {/* {console.log("vinay-------", scanDetail)} */}
                              {Object.entries(scanDetail.params).map(
                                ([paramKey, paramValue]) => (
                                  <div
                                    key={paramKey}
                                    className="param row mb-2"
                                  >
                                    <div className="col-5 text-center Parameters">
                                      {paramKey || "-"}
                                    </div>
                                    <div className="col-4 text-center manual_res">
                                      {paramValue.manual || "-"}
                                    </div>
                                    <div className="col-3 text-center manual_res">
                                      {paramValue.matt || "-"}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* add Scan ids modal */}
              {addNewScanDetails.isOpen ? (
                <AddScanIds
                  scandetails={addNewScanDetails}
                  handleClose={handleCloseAddScanModal}
                  gettingValuesfromscanids={addinngScandetails}
                />
              ) : null}
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Edit Machine Model Details
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse "
              >
                <div className="accordion-body">
                  <div className="edit-data-variation">
                    <div className="edit-data-variation-header">
                      <header>Add Model Details</header>
                      <button
                        variant="primary"
                        className="btn btn-primary"
                        onClick={handleModalDetails}
                        disabled={
                          modalMachines === null ||
                          modalCrop === null ||
                          commitId === "" ||
                          modalNumber === "" ||
                          gitBranch === null
                        }
                      >
                        Add
                      </button>
                    </div>

                    <Box
                      className="basic_details"
                      component="form"
                      sx={{
                        // "& .MuiTextField-root": { m: 1, width: "33ch" },
                        // "@media (max-width: 900px)": {
                        //   "& .MuiTextField-root": { width: "100%" },
                        // },
                        "@media (max-width: 900px)": {
                          "& .MuiFormControl-root": { width: "100%" },
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl sx={{ m: 1, width: "33ch" }} size="medium">
                        <InputLabel id="modal-machine-label">
                          Machine
                        </InputLabel>
                        <Select
                          labelId="modal-machine-label"
                          value={modalMachines}
                          onChange={(e) => handleChange(e, setModalMachines)}
                          label="Machine"
                        >
                          {machineData?.length &&
                            machineData.map((item, index) => (
                              <MenuItem key={index} value={item["code"]}>
                                {item["code"]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      {/* commit_id  */}
                      <FormControl sx={{ m: 1, width: "33ch" }}>
                        <Autocomplete
                          disablePortal
                          size="medium"
                          id="combo-box-demo"
                          options={
                            filterData?.commit_id_list &&
                            Object.values(filterData.commit_id_list).map(
                              (item) => item.slice(0, 5)
                            )
                          }
                          value={commitId}
                          renderInput={(params) => (
                            <TextField {...params} label="Commit Id" />
                          )}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setCommitId(newValue);
                            }
                          }}
                        />
                      </FormControl>

                      {/* model_no option */}
                      <FormControl sx={{ m: 1, width: "33ch" }}>
                        <Autocomplete
                          disablePortal
                          size="medium"
                          id="combo-box-demo"
                          options={
                            filterData?.model_no_list &&
                            Object.values(filterData.model_no_list)
                          }
                          value={modalNumber}
                          renderInput={(params) => (
                            <TextField {...params} label="Model Number" />
                          )}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setModalNumber(newValue);
                            }
                          }}
                        />
                      </FormControl>

                      {/* crop options */}
                      <FormControl sx={{ m: 1, width: "33ch" }} size="medium">
                        <InputLabel id="modal-crop-label">Crop</InputLabel>
                        <Select
                          labelId="modal-crop-label"
                          value={modalCrop}
                          onChange={(e) => handleChange(e, setModalCrop)}
                          label="Crop"
                        >
                          {filterData?.param_data &&
                            Object.keys(filterData.param_data).map(
                              (crop, index) => (
                                <MenuItem key={index} value={crop}>
                                  {crop}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>

                      {/* git branches */}
                      <FormControl sx={{ m: 1, width: "33ch" }}>
                        <Autocomplete
                          disablePortal
                          size="medium"
                          id="combo-box-demo"
                          options={
                            filterData &&
                            Object.values(filterData?.git_branch_list)
                          }
                          value={gitBranch}
                          renderInput={(params) => (
                            <TextField {...params} label="Git Branch" />
                          )}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setGitBranch(newValue);
                            }
                          }}
                        />
                      </FormControl>
                    </Box>

                    {/* Render the list of elements */}
                    <div className="data-variation-elements-container mt-1">
                      {modalDetailsList.map((element, index) => (
                        <div key={index} className="data-variation-elements">
                          {element.machine_code}, {element.commit_id},{" "}
                          {element.model_no}, {getCropName(element.crop_id)},
                          {getGitBranchName(element.git_branch)}
                          <span
                            className="px-2 cross-btn-data-variation"
                            variant="outlined"
                            onClick={() => handleDeleteElement(index)}
                          >
                            x
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <Box
          style={{ padding: "16px", display: "flex", justifyContent: "end" }}
        >
          {/* <Button variant="secondary" onClick={handleClose}>Close</Button> */}
          {/* <Button variant="primary" onClick={handleChangesinDB}>Save Changes</Button> */}
          <LoadingButton
            size="large"
            onClick={handleChangesinDB}
            endIcon={<SendIcon />}
            loading={loading}
            disabled={
              scanDetailsList.length === 0 &&
              elementList.length === 0 &&
              modalDetailsList.length === 0
            }
            loadingPosition="end"
            variant="contained"
          >
            <span>Submit</span>
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default ScanDetailsModal;
