import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Box from "@mui/material/Box";
import "../../../../assets/styles/CardModal.css";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { BeatLoader } from "react-spinners";
import ContextState from "../../../contextApi/ContextState";
import RefreshIcon from "@mui/icons-material/Refresh";
import Modal from "@mui/material/Modal";
import SellerGeneralInfo from "./SellerGeneralInfo";
import SellerLotHistory from "./SellerLotHistory";

const SellerDetailsMainModal = ({ data, reloadData, onClose }) => {
  const { isOpen, sellerId } = data;
  const { showModalErrorPage, modalControllerRef } = useContext(ContextState);

  const [reloadButtonClicked, setReloadButtonClicked] = useState({
    generalTab: false,
    // orderOverviewTab: false,
    lotHistoryTab: false,
  });

  const [activeTab, setActiveTab] = useState("#");

  const [refreshLoading, setRefreshLoading] = useState(false);
  const [refreshBtn, setRefreshBtn] = useState(false);

  useEffect(() => {
    return () => {
      // On unmount, abort the previous request for fetchApi api
      if (modalControllerRef.current) {
        modalControllerRef.current.abort();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.title = `${
        activeTab === "#"
          ? "General Info"
          : activeTab === "link-1"
          ? "Lots Overview"
          : ""
      } - NEO`;
    } else {
      document.title = "Sellers - NEO";
    }
    return () => {};
  }, [activeTab, isOpen]);

  const reloadCorresponsingTab = () => {
    setRefreshBtn(true);
    showModalErrorPage(null, null);
    if (activeTab === "#") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        generalTab: !prevState.generalTab,
      }));
    }
    //  else if (activeTab === "link-1") {
    //   setReloadButtonClicked((prevState) => ({
    //     ...prevState,
    //     orderOverviewTab: !prevState.orderOverviewTab,
    //   }));
    // }
    else if (activeTab === "link-1") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        lotHistoryTab: !prevState.lotHistoryTab,
      }));
    }
  };

  const handleTabSelect = (eventKey) => {
    if (activeTab === eventKey) {
      showModalErrorPage(null, null);
    } else {
      setActiveTab(eventKey);
      showModalErrorPage(null, null);
    }
  };

  const modalstyle = {
    position: "relative",
    zIndex: "80",
    top: "10%",
    width: "95vw",
    margin: "  auto",
    height: "70vh",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    maxWidth: "1600px",
    overflowY: "auto",
  };

  return (
    <Modal
      style={{
        position: "fixed",
        height: "100%",
      }}
      open={isOpen}
      onClose={onClose}
    >
      {/* Render specific data within the modal */}
      <Box sx={modalstyle}>
        {sellerId && (
          <>
            <div className="ModalHeader">
              <Nav
                variant="tabs"
                defaultActiveKey="#"
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                <Tooltip
                  data-html="true"
                  title={"General Seller Information"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className="general" eventKey="#">
                      General Info
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>

                <Tooltip
                  data-html="true"
                  title={"Order History Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-1">
                      Order History
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
              </Nav>

              {/* reload and close section */}
              <div className="end-sections-header">
                <Tooltip
                  className="tooltip-inner"
                  data-html="true"
                  title={"Refresh this section"}
                  arrow={true}
                >
                  <RefreshIcon
                    className={`reload-section ${
                      refreshLoading ? "rotate" : ""
                    }`}
                    onClick={reloadCorresponsingTab}
                    fontSize=""
                  />
                </Tooltip>

                <Tooltip
                  className="tooltip-inner"
                  data-html="true"
                  title={"Close"}
                  arrow={true}
                >
                  <CloseIcon
                    className="reload-section "
                    onClick={onClose}
                    fontSize=""
                  />
                </Tooltip>
              </div>
            </div>
            <div className="cardContent">
              {activeTab === "#" && (
                <SellerGeneralInfo
                  sellerId={sellerId}
                  reloadAllSellersData={reloadData}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  //   reloadSameTab={reloadSameTab}
                  reloadGeneralPage={reloadButtonClicked.generalTab}
                />
              )}
              {/* {activeTab === "link-1" && (
                <SellerDetailsOrderOverview
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  //   reloadSameTab={reloadSameTab}
                  reloadAttachmentsPage={reloadButtonClicked.attachmentsTab}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                />
              )} */}
              {activeTab === "link-1" && (
                <SellerLotHistory
                  sellerId={sellerId}
                  setRefreshLoading={setRefreshLoading}
                  //   reloadSameTab={reloadSameTab}
                  reloadLotHistoryPage={reloadButtonClicked.lotHistoryTab}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                />
              )}
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default SellerDetailsMainModal;
