import React, { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import ContextState from "../../contextApi/ContextState";

import Nav from "react-bootstrap/Nav";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

import GeneralContent from "../../../Components/modals/GeneralContent";
import DataPool from "../../../Components/modals/DataPool";
import Visit from "../../../Components/modals/VisitDetails";
import AttachmentsContent from "../../../Components/modals/AttachmentsContent";
import ResultContent from "../../../Components/modals/ResultContent";
import close from "../../../assets/images/close.svg";
import Comments from "../../../Components/modals/Comments";
import Timeline from "../../../Components/modals/Timeline";
import Modal from "@mui/material/Modal";
import ModelDetailsLog from "../../../Components/modals/ModelDetailsLog";
import { OverlayTrigger } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import ToolTip from "react-bootstrap/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IoPersonCircleOutline } from "react-icons/io5";
import Stack from "@mui/material/Stack";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  Typography,
  Box,
  ButtonGroup,
  Button,
  Tooltip as MuiTooltip,
  linearProgressClasses,
  useMediaQuery,
  TextField,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import ProductGeneralContent from "./ProductDeatilsComponents/ProductGeneralContent";
import ProductTimeline from "./ProductDeatilsComponents/ProductTimeline";
import ProductComments from "./ProductDeatilsComponents/ProductComments";
import ProductAttachment from "./ProductDeatilsComponents/ProductAttchment";
import test_profile_img from "../../../assets/images/default_product_hdd_without_background.png";
import v39_scanner_img from "../../../assets/images/product/V39.png";
import v600_scanner_img from "../../../assets/images/product/V600.png";
import ardiuno_nano_controller_img from "../../../assets/images/product/ardiuno_nano.png";
import ardiuno_uno_controller_img from "../../../assets/images/product/ardiuno_uno.png";
import nuc_img from "../../../assets/images/product/nuc.png";

import showInventoryImg from "../../../assets/images/inv_total_count.png";
import showReadyToUseImg from "../../../assets/images/inv_ready_to_use.png";
import showInHouseImg from "../../../assets/images/inv_in_house.png";
import showProductTypeIMG from "../../../assets/images/productType.png";
import showStageIMG from "../../../assets/images/stageImg.png";
import Error from "../../error/Error";
import { BeatLoader } from "react-spinners";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import ModalError from "../../error/ModalError";
import { CardPagesSkeleton } from "../../Exporters/ExportFunctions";

const EditAssigneeModal = ({
  stageModalData,
  setShowModal,
  showModal,
  color,
  handelsetStageModalOpen,
  queryStartTime,
  productID,
  fetchDataAndUpdateFilters,
}) => {
  // const selectStageOption =
  //   stageModalData && stageModalData["all_product_stage"];
  // const prevSelectedStage =
  //   stageModalData && stageModalData["current_selected_stage"]; // dictionary of id, stage

  const getTimeStr = (lastUpdateTime) => {
    // last updated time for stage
    const currentTime = new Date();
    const inputDate = lastUpdateTime.replace("GMT", "GMT+0530");
    const TicketCeratedTime = new Date(inputDate);
    // Calculate the difference in milliseconds
    const timeDifferenceInMilliseconds = currentTime - TicketCeratedTime;
    // console.log(
    //   currentTime,
    //   ticket.creation_time,
    //   TicketCeratedTime,
    //   timeDifferenceInMilliseconds,
    //   ticket.ticket_id
    // );
    let CreatedTime = "Last Updated ";

    // if (support_state !== 'done') {
    const minutesDifference = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );

    if (timeDifferenceInMilliseconds < 0) {
      CreatedTime += 0 + " minutes ago";
    } else {
      if (minutesDifference < 60) {
        CreatedTime += minutesDifference + " minutes ago";
      } else {
        const hoursDifference = Math.floor(minutesDifference / 60);

        if (hoursDifference < 24) {
          CreatedTime += hoursDifference + " hours ago";
        } else {
          const daysDifference = Math.floor(hoursDifference / 24);

          if (daysDifference < 7) {
            CreatedTime += daysDifference + " days ago";
          } else {
            const weeksDifference = Math.floor(daysDifference / 7);

            if (weeksDifference < 4) {
              CreatedTime += weeksDifference + " weeks ago";
            } else {
              const monthsDifference = Math.floor(daysDifference / 30);

              if (monthsDifference < 12) {
                CreatedTime += monthsDifference + " months ago";
              } else {
                const yearsDifference = Math.floor(daysDifference / 365);
                CreatedTime += yearsDifference + " years ago";
              }
            }
          }
        }
      }
    }

    const hoursDifference = Math.floor(minutesDifference / 60);
    return {
      hoursDifference,
      CreatedTime,
    };
  };
  const timeInfo =
    stageModalData?.updated_date && getTimeStr(stageModalData.updated_date);
  const { getTimeSring, hoursDifference } = timeInfo
    ? {
        getTimeSring: timeInfo.CreatedTime || null, // Fallback to null if not available
        hoursDifference: timeInfo.hoursDifference || null, // Fallback to null if not available
      }
    : { getTimeSring: null, hoursDifference: null }; // Fallback when timeInfo is null/undefined
  // alter files
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  // const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  const theme = useTheme();
  const {
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    controllerRef,
  } = useContext(ContextState);

  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  const [selectStage, setSelectStage] = useState({
    value: "",
    name: "",
    id: "",
    error: false,
  });

  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    if (stageModalData) {
      const getPrevSelectedAssignee =
        stageModalData.assignee_name &&
        stageModalData.all_assignee_list.filter(
          (item) =>
            item["name"].toLowerCase() ===
            stageModalData.assignee_name.toLowerCase()
        )[0];
      handelSelectStage(
        JSON.stringify(getPrevSelectedAssignee || ""),
        getPrevSelectedAssignee?.name || null,
        getPrevSelectedAssignee?.id || null
      );

      setShowLoading(false);
    }
  }, [stageModalData]);

  const handelSelectStage = (value, name, id) => {
    setSelectStage((prev) => ({ ...prev, value: value, name: name, id: id }));
  };
  const handelSelectStageError = (error) => {
    setSelectStage((prev) => ({ ...prev, error: error }));
  };
  const handleChange = (event) => {
    const jsonRawString = event.target.value;
    const selectedValue = JSON.parse(jsonRawString);
    if (selectedValue) {
      handelSelectStage(jsonRawString, selectedValue.name, selectedValue.id);
    } else {
      handelSelectStage("", null, null);
    }
  };


  const [showStageChangeLoading, setShowStageChangeLoading] = useState(false);

  // const handleChange = (e) => {
  //   setAssigneeName(e.target.value);
  //   if (e.target.value) {
  //     setError(false); // Clear error if input is valid
  //   }
  // };
  const handelSubmit = async () => {
    let isMounted = true;
    // handleButtonClick("general_deatils")
    // console.log("send data to db ...", selectStage.stage, selectStage.id);
    setShowStageChangeLoading(true);

    if (
      !selectStage.name ||
      selectStage.name === null ||
      stageModalData?.assignee_name === selectStage.name
    ) {
      handelSelectStageError(true);
      openSnackbar("Kindly select a Assignee to proceed.", "warning");
    } else {
      // console.log(selectStage.name);
      try {
        let payload = {
          product_id: productID,
          info_type: "assignee",
          assignee_name: selectStage.name,
          query_start_time: queryStartTime,
        };

        // if (refreshBtn) {
        //   setRefreshLoading(true);
        // }

        const jsonData = await fetchApi(
          "edit_product_info",
          "POST",
          payload,
          true,
          2
        );
        if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
          if (isMounted) {
            openSnackbar(jsonData?.data?.message, "success");
            fetchDataAndUpdateFilters();
          }
        } else if (
          jsonData?.statusCode === 500 ||
          jsonData?.statusCode === 401
        ) {
          openSnackbar(jsonData?.msg, "error");
          // showModalErrorPage(jsonData.statusCode, jsonData.msg);
          // showLoadingModal(false);
        } else {
          openSnackbar("Opps!! Somthing went wrong ... ", "warning");
          // showModalErrorPage(jsonData.statusCode, jsonData.msg);
        }
      } catch (error) {
        if (controllerRef.current) {
          // openSnackbar("Request Timed Out!!!", "warning");
          console.log("fetch abort in attchemnts ... ");
        } else {
          openSnackbar("Some Error Occured!", "error");
        }
      } finally {
        handelsetStageModalOpen(false);
      }
    }
    setShowStageChangeLoading(false);

    return () => {
      isMounted = false;
    };
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "30%",
            left: "0",
            margin: "  auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "500px",
            padding: "10px",
            minHeight: "250px",
          }}
        >
          {/* Modal Header */}
          <div
            className="d-flex flex-column gap-3 position-relative py-2 pt-4"
            style={{
              // padding: "12px 18px",
              // borderBottom:
              // "1px solid" + color.taxtHeadingLight.slice(0, -2) + "33",
              position: "relative",
              zIndex: 1000,
            }}
          >
            <div className="d-flex justify-content-center">
              <p
                className="m-0 p-0 text-center"
                style={{
                  fontFamily: "Open-Sans-regular",
                  fontWeight: 600,
                  fontSize:
                    (isSmallSmallScreen && "1rem") ||
                    (isMediumScreen && "1.25rem") ||
                    "1.5rem",
                  color: color.taxtHeading,
                }}
              >
                Edit Assignee
              </p>
            </div>
            <div
              className="d-flex justify-content-center align-items-center position-absolute top-0 end-0 p-4"
              onClick={() => {
                handelsetStageModalOpen(false);
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
              {/* <button
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                  color:color.taxtHeadingLight
                }}
              >
                X
              </button> */}
            </div>
          </div>
          {/* Modal Header ends... */}

          {/* Modal Body Starts .... */}
          <div>
            {stageModalData && (
              <div className="d-flex flex-column gap-4 justify-content-between">
                <div className="text-center">
                  Choose the appropriate Assignee for the{" "}
                  <span style={{ fontWeight: "600" }}>
                    {stageModalData.stage}
                  </span>{" "}
                  Stage.
                </div>
                {/* select stage to change */}
                <div className="position-relative m-2">
                  <div className="d-flex flex-row gap-3 justify-content-center align-itemd-center">
                    <Box
                      sx={{
                        minWidth: 120,
                        flexGrow: 1,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          error={selectStage.error}
                          sx={{
                            transform: "translate(14px, 8px) scale(1)", // Adjust when label is floating
                            "&.MuiInputLabel-shrink": {
                              transform: "translate(14px, -10px) scale(0.75)", // Adjust when label shrinks
                            },
                          }}
                        >
                          Assignee
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectStage.value}
                          label="assignee"
                          onChange={handleChange}
                          sx={{
                            height: 40, // Set desired height
                            "& .MuiSelect-select": {
                              paddingTop: 1,
                              paddingBottom: 1,
                            },
                          }}
                        >
                          <MenuItem value={null}>None</MenuItem>
                          {stageModalData["all_assignee_list"].map(
                            (value, idx) => (
                              <MenuItem key={idx} value={JSON.stringify(value)}>
                                {value.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                    <div className="d-flex align-item-center justify-content-center">
                      <LoadingButton
                        size="medium"
                        onClick={handelSubmit}
                        endIcon={<SendIcon />}
                        loading={showStageChangeLoading}
                        className="my-1"
                        // disabled={
                        //   assigneeName === stageModalData.assignee_name ||
                        //   assigneeName === ""
                        // }
                        loadingPosition="end"
                        variant="contained"
                        sx={{
                          backgroundColor: color.secondary,
                          fontSize: "0.85rem",
                          padding: "4px 10px",
                          "&:hover": {
                            color: color.secondary,
                            borderColor: color.secondary,
                            backgroundColor: color.iconBackgroundBlue,
                          },
                        }}
                      >
                        <span
                          style={{
                            lineHeight: "0.95rem",
                            textTransform: "capitalize",
                          }}
                        >
                          Submit
                        </span>
                      </LoadingButton>
                    </div>
                  </div>
                </div>
                {/* divider */}
                <div class="barChartDivDescHorizontalSeperator " />
                {/*  current status */}
                <div
                  className="d-flex flex-column gap-3 my-2 mb-4"
                  style={{
                    fontFamily: "Open-Sans-Light",
                    fontWeight: 800,
                    fontSize: "0.89rem",
                    color: color.taxtHeadingLight,
                  }}
                >
                  <div
                    className="d-flex flex-row gap-2  flex-grow-1 justify-content-evenly"
                    style={{ height: "80px" }}
                  >
                    {Object.entries({
                      current_assignee: stageModalData.assignee_name
                        ? stageModalData.assignee_name
                        : "Not Assignee Yet",
                      last_updated: getTimeSring.slice(12),
                    }).map(([key, value], idx) => {
                      return (
                        <>
                          <div
                            key={value}
                            className="p-0  d-inline d-flex flex-column gap-1 h-100 justify-content-between"
                            style={{ textTransform: "capitalize" }}
                          >
                            {(key === "current_assignee" && (
                              <div className="flex-grow-1  d-flex align-items-center justify-content-center">
                                <span
                                  style={{
                                    fontFamily: "Open-Sans-regular",
                                    fontWeight: 700,
                                    // color: findColorByStatus("Arrival"),
                                    color: stageModalData.assignee_name
                                      ? color.taxtHeading
                                      : color.red,
                                    fontSize: "1.25rem",
                                    // backgroundColor:
                                    //   findColorByStatus("Arrival") + "33",
                                    borderRadius: "30px",
                                    // borderRadius: "25%",
                                    padding: "5px 15px",
                                  }}
                                >
                                  {value}
                                </span>
                              </div>
                            )) ||
                              (key === "last_updated" && (
                                <div
                                  className="d-inline flex-grow-1 d-flex align-items-center justify-content-center "
                                  style={{ fontSize: "1.25rem" }}
                                >
                                  {(hoursDifference > 24 && (
                                    <span className="badge bg-warning-subtle text-warning-emphasis rounded-pill px-2 ">
                                      {value}
                                    </span>
                                  )) || (
                                    <span className="badge bg-success-subtle text-success-emphasis rounded-pill  px-2">
                                      {value}
                                    </span>
                                  )}
                                </div>
                              ))}
                            <div
                              style={{
                                color: color.taxtHeading,
                                textAlign: "center",
                              }}
                            >
                              {key.replace("_", " ")}
                            </div>
                          </div>

                          {idx === 0 && (
                            <div class="barChartDivDescVerticalSeperator"></div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Modal Body ends .... */}
        </Box>
      </Modal>
    </>
  );
};

const ProductDetails = () => {
  const { productCategory, productID } = useParams();
  const {
    color,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
  } = useOutletContext();

  // const Data = {
  //   product_id: "XXX",
  //   serial_id: "abcXXX123",
  //   product_name: "product_name",
  //   product_desc: "product_desc",
  //   quantity: 1,
  //   product_desc: "product_desc",
  //   price: 500,
  //   stage: "Arrival",
  //   created_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  //   updated_date: "Wed, 16 Oct 2024 16:47:10 GMT",
  // };
  // console.log("selectedProductDetails : ", dataFromApi);
  const isOpen = true;
  const onClose = false;
  const {
    fetchApi,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    lodingModalVisiblity,
    showError,
    showErrorPage,
    showModalErrorPage,
    modalControllerRef,
    formatDate,
  } = useContext(ContextState);

  const [reloadButtonClicked, setReloadButtonClicked] = useState({
    generalTab: false,
    attachmentsTab: false,
    scanDetailsTab: false,
    timelineTab: false,
    commentsTab: false,
    dataPullTab: false,
    visitsTab: false,
    modelLog: false,
  });
  const [activeTab, setActiveTab] = useState("#");

  // to get the sate of reloaded tab
  // const [reloadSameTab, setReloadSameTab] = useState(false);

  // this varibale is to animate the loading icon
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [refreshBtn, setRefreshBtn] = useState(false);

  useEffect(() => {
    setActiveTab("#");
    return () => {
      // On unmount, abort the previous request for fetchApi api
      if (modalControllerRef.current) {
        modalControllerRef.current.abort();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.title = `${
        activeTab === "#"
          ? "General Info"
          : activeTab === "link-1"
          ? "Attachments"
          : activeTab === "link-2"
          ? "Scan Details"
          : activeTab === "link-3"
          ? "Timeline"
          : activeTab === "link-4"
          ? "Comments"
          : activeTab === "link-5"
          ? "Visits"
          : activeTab === "link-6"
          ? "Pull Data"
          : activeTab === "link-7"
          ? "Model Log"
          : ""
      } - NEO`;
    } else {
      document.title = "All Tickets - NEO";
    }
    return () => {};
  }, [activeTab, isOpen]);

  const reloadCorresponsingTab = () => {
    // console.log(reloadButtonClicked)
    setRefreshBtn(true);
    showModalErrorPage(null, null);
    if (activeTab === "#") {
      // console.log("button clciked");
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        generalTab: !prevState.generalTab,
      }));
    } else if (activeTab === "link-1") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        attachmentsTab: !prevState.attachmentsTab,
      }));
    } else if (activeTab === "link-2") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        scanDetailsTab: !prevState.scanDetailsTab,
      }));
    } else if (activeTab === "link-3") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        timelineTab: !prevState.timelineTab,
      }));
    } else if (activeTab === "link-4") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        commentsTab: !prevState.commentsTab,
      }));
    } else if (activeTab === "link-5") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        visitsTab: !prevState.visitsTab,
      }));
    } else if (activeTab === "link-6") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        dataPullTab: !prevState.dataPullTab,
      }));
    } else if (activeTab === "link-7") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        modelLog: !prevState.modelLog,
      }));
    }
  };

  const handleTabSelect = (eventKey) => {
    if (activeTab === eventKey) {
      showModalErrorPage(null, null);
      // setReloadSameTab(!reloadSameTab);
    } else {
      setActiveTab(eventKey);
      showModalErrorPage(null, null);
    }
  };

  const modalstyle = {
    position: "relative",
    zIndex: "80",
    top: "2%",
    width: "90vw",
    margin: "  auto",
    height: "95vh",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    maxWidth: "1800px",
    overflowY: "auto",
  };


  const [showTableData, setShowTableData] = useState({
    general_deatils: true,
    attachment: false,
    timeline: false,
    comments: false,
  });


  const data = {
    // ticketId: "d462b5628",
    // ticketId: "1e063283b",
    ticketId: "28092349f",
    // status: "resolved",
    productId: productID,
  };
  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Showing product Deatils of ProductID{" "}
      <span style={{ textTransform: "capitalize" }}>
        #{props.product_id || ""}
      </span>{" "}
      from inventory
    </ToolTip>
  );

  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "30px",
    iconBackgroundWidth: "55px",
    iconBackgroundHeight: "55px",

    // monthlyScanDivDescIconWidth: "20px",
    // monthlyScanDivDescIconBackgroundWidth: "50px",
    // monthlyScanDivDescIconBackgroundHeight: "50px",

    location_background_borderRadius: "15%",
  };

  const navItems = [
    { label: "General Info", path: "" },
    { label: "Attachments", path: "attachments" },
    { label: "TimeLine", path: "timeline" },
    { label: "Comments", path: "comments" },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (e, path, targetPath) => {
    if (e.ctrlKey || e.metaKey) {
      // Open in a new tab if Ctrl or Cmd key is pressed
      window.open(targetPath, "_blank");
    } else {
      // Prevent full page reload and navigate within SPA
      e.preventDefault();
      navigate(path);
    }
  };

  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState(true);
  const [queryStartTime, setQueryStartTime] = useState(null);
  const [showRefreshAnimation, setShowRefreshAnimation] = useState(true);

  const fetchDataAndUpdateFilters = async (showLoading = true) => {
    const startTime = new Date().toUTCString();
    // console.log("startTime : ", startTime);
    if (showLoading) {
      setLoading(true);
      setData(null);
    }
    setShowRefreshAnimation(true);
    setQueryStartTime(startTime);
    let is_product_data_Mounted = true;
    // fetchFiltersDataApiCall
    const payload = {
      product_id: productID,
      product_category: productCategory,
    };
    const api_data = await fetchApi(
      "get_product_info",
      "POST",
      payload,
      is_product_data_Mounted
    );
    // console.log("all_filter_data in All Ticket : ", api_data);
    if (api_data.statusCode === 200) {
      setData(api_data?.data?.gen_details);
      // handelsetStageModalData(api_data?.data?.gen_details);
      showErrorPage(null, null);
    } else if (api_data.statusCode === 500 || api_data.statusCode === 401) {
      setData(null);
      showErrorPage(api_data.statusCode, api_data.msg);
    } else {
      setData(null);
      // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
      // console.log("Some Error Occured", all_filter_data);
      localStorage.clear();
      window.location.href = "/login";
    }
    setShowRefreshAnimation(false);
    setLoading(false);

    return () => {
      is_product_data_Mounted = false;
    };
  };

  const refresgProductDetails = () => {
    fetchDataAndUpdateFilters(false);
  };
  useEffect(() => {
    document.title = `Product ID#${productID} - NEO`;
    fetchDataAndUpdateFilters();
    // console.log("data set to empty List - 2");
  }, []);

  const [stageModal, setStageModal] = useState({
    open: false,
    conformation: false,
    data: null,
  });

  const handelsetStageModalOpen = (open) => {
    setStageModal((prevData) => ({
      ...prevData,
      open: open,
    }));
  };
  const handelsetStageModalConformation = (conformation) => {
    setStageModal((prevData) => ({
      ...prevData,
      conformation: conformation,
    }));
  };
  const handelsetStageModalData = (data) => {
    setStageModal((prevData) => ({
      ...prevData,
      data: data,
    }));
  };

  const getProductImage = (productName) => {
    const lowerCaseName = productName.toLowerCase();

    if (lowerCaseName.includes("v39")) {
      return v39_scanner_img;
    }
    if (lowerCaseName.includes("v600")) {
      return v39_scanner_img; // Consider if this is intentional or a typo
    }
    if (lowerCaseName.includes("nuc")) {
      return nuc_img;
    }
    if (lowerCaseName.includes("ardiuno nano")) {
      return ardiuno_nano_controller_img;
    }
    if (lowerCaseName.includes("ardiuno")) {
      return ardiuno_uno_controller_img;
    }
    return test_profile_img;
  };

  return (
    <>
      {(!showError.status && (
        <>
          {/* <Dropdown
          blurTickets={handelPageBlur}
          filters={filters}
          filterCount={filterCount}
          handleFilterChange={handleFilterChange}
          handleSaveChanges={handleSaveChanges}
          handleFilterReset={handleFilterReset}
          getUserFilters={getUserFilters}
          getFilterResponse={getFilterResponse}
          filtersOptions={filtersOptions}
        />
        {allFiltersDisplay && (
          <AllFilters
            filters={showAllfilters}
            handelResetAllBtn={handelResetAllBtn}
            handelDeleteFilterBtn={handelDeleteFilterBtn}
            getUserFilters={getUserFilters}
          />
        )} */}

          {/* <div
          className={`${blurTickets} all-ticket-content ${
            allFiltersDisplay ? "m-top-0 p-top-0" : " "
          }`}
        > */}
          <div className="container">
            {(loading && (
              // {lodingVisiblity && (
              // <div className="showSpinner all-ticket-spinner">
              //   <BeatLoader color="#2D97D4" />
              // </div>
              <div className="row">
                <div className="col-lg-4">
                  <CardPagesSkeleton
                    len={1}
                    cardHeight={600}
                    componentClass=""
                  />
                  <CardPagesSkeleton
                    len={1}
                    cardHeight={80}
                    componentClass=""
                  />
                </div>
                <div className="col-lg-8">
                  <div style={{ maxWidth: "600px", width: "100%" }}>
                    <CardPagesSkeleton
                      len={4}
                      cardHeight={40}
                      componentClass="header-skeletons"
                    />
                  </div>
                  <CardPagesSkeleton
                    len={1}
                    cardHeight={600}
                    componentClass=""
                  />
                </div>
              </div>
            )) || (
              <>
                {/* Page top details */}
                <div className="count_heading_div text-end  pe-0 text-nowrap d-flex ">
                  <div className="upper-heading-container p-0 pt-2">
                    {/* Document info */}
                    {false && (
                      <div className="info-part mt-2">
                        <span className="px-1  pe-2 mb-1 info-heading">
                          <span style={{ textTransform: "capitalize" }}>
                            Product Deatils
                          </span>
                        </span>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip({
                            ProductType: productCategory,
                            product_id: productID,
                          })}
                        >
                          <i
                            className="bi bi-info-circle icon-color px-0"
                            id="info-icon"
                            style={{ fontSize: "15px", lineHeight: "24px" }}
                          ></i>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                </div>
                {false && (
                  <div
                    className="product_full_desc d-flex flex-column  py-2 px-2 m-0 my-1 mb-2 lh-sm flex-grow-1"
                    style={{
                      fontFamily: "Open-Sans-Light",
                      color: color.taxtHeading,
                      fontWeight: 600,
                      fontSize: "1.25rem",
                      rowGap: "7px",
                      textTransform: "capitalize",
                    }}
                  >
                    <div
                      className="product_name p-0 m-0"
                      style={{ height: "20px" }}
                    >
                      Product ID #{Data.product_id}
                    </div>
                    <div
                      className="product_desc p-0 m-0"
                      style={{
                        height: "20px",
                        fontWeight: 400,
                        fontSize: "1.05rem",
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Show ellipsis
                        whiteSpace: "nowrap", // Prevent text wrapping
                      }}
                    >
                      {formatDate(Data.created_date)}
                    </div>
                  </div>
                )}
                <div className={`row `}>
                  {/* left product detail */}
                  <div
                    className={`${
                      (islagelargeScreen && "col-xl-4") || "col-xl-3"
                    } mb-3 d-flex flex-column row-gap-3`}
                  >
                    {(showRefreshAnimation && (
                      <Box
                        className="d-flex flex-column gap-4 p-2"
                        sx={{ backgroundColor: "#f4f6f8", borderRadius: "8px" }}
                      >
                        {/* Main Content Skeleton */}
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          width="100%"
                          height="50vh"
                          sx={{ bgcolor: "#e0e0e0" }}
                        />

                        {/* Sub Content Skeleton */}
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={100}
                            height={100}
                            sx={{ bgcolor: "#e0e0e0" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width="100%"
                            height={100}
                            sx={{ bgcolor: "#e0e0e0" }}
                          />
                        </Stack>
                      </Box>
                    )) || (
                      <>
                        <div className="card-Div position-relative">
                          <div
                            className="d-flex flex-column align-items-center py-1 pt-4"
                            style={{ rowGap: "2px" }}
                          >
                            {/* Product header */}
                            <div
                              className="product_full_desc d-flex flex-column  p-2 py-3 m-0 mb-0 lh-sm flex-grow-1"
                              style={{
                                fontFamily: "Open-Sans-Light",
                                color: color.taxtHeading,
                                fontWeight: 600,
                                fontSize: "1.25rem",
                                rowGap: "7px",
                                textTransform: "capitalize",
                              }}
                            >
                              <div
                                className={`d-flex justify-content-center align-items-center `}
                                style={{
                                  // backgroundColor: "#dddfff99",
                                  backgroundColor: color.textWhiteColor,
                                  width: "120px",
                                  height: "120px",
                                  borderRadius:
                                    pageStyling.location_background_borderRadius,
                                }}
                                // className="ms-auto"
                              >
                                <img
                                  src={getProductImage(Data.product_name)}
                                  alt={`${Data.product_category} image`}
                                  className="mx-2"
                                  style={{ height: "auto", width: "140px" }}
                                />
                              </div>
                            </div>

                            <div
                              className="product_name p-0 m-0"
                              style={{
                                // height: "20px",
                                fontFamily: "Open-Sans-Light",
                                color: color.tableRowHeading,
                                fontWeight: 900,
                                fontSize: "1.25rem",
                                textTransform: "capitalize",
                              }}
                            >
                              Product ID #{Data.product_id || productID}
                            </div>
                            {/* <div
                className="product_name p-0 m-0"
                style={{
                  // height: "20px",
                  fontFamily: "Open-Sans-Light",
                  color: color.taxtHeadingLight,
                  fontWeight: 900,
                  fontSize: "0.95rem",
                  textTransform: "capitalize",
                }}
              >
                product desc 
              </div> */}
                            <div
                              className="product_desc p-0 m-0"
                              style={{
                                fontWeight: 400,
                                fontSize: "0.95rem",
                                textAlign: "center",
                                width: "95%",
                                // overflow: "hidden", // Hide overflow
                                // textOverflow: "ellipsis", // Show ellipsis
                                // whiteSpace: "nowrap", // Prevent text wrapping
                                color: color.taxtHeadingLight,
                              }}
                            >
                              {Data.product_desc}
                            </div>
                          </div>
                          {/* Product Id  */}
                          {/* <div
              className="machine_btns position-absolute top-0 end-0 mt-3 me-3"
              // style={{ width: "fit-content" }}
            >
              #{123}
            </div> */}
                          {/* stage, price , machine overview */}
                          <div className="">
                            <div className="average-div d-flex flex-row flex-wrap align-items-center justify-content-around  w-100 gap-0 mt-1">
                              {/* stage */}

                              <div
                                className="top-div top-monthly-div d-flex flex-row align-item-center gap-1 "
                                style={{ minWidth: "100px" }}
                              >
                                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-1">
                                  <div
                                    style={{
                                      backgroundColor: color.iconBackgroundBlue,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height: pageStyling.iconBackgroundHeight,
                                      width: pageStyling.iconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={showStageIMG}
                                      alt="All Assesment img"
                                      className=""
                                      style={{
                                        width: pageStyling.IconWidth,
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* circular progress bar div desc */}
                                <div
                                  className="top-div-circular-progress-desc flex-grow-1"
                                  style={{
                                    color: color.taxtHeadingLight,
                                    fontSize: "1.05rem",
                                    lineHeight: "1.25rem",
                                    width: "auto",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: color.tableRowHeading,
                                      fontSize: "0.65rem",
                                    }}
                                  >
                                    {Data.stage}
                                    {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_10_days_data?.top_data?.region_scan
                                  )}
                                  scans)
                                </span> */}
                                  </p>
                                  Stage
                                </div>
                              </div>

                              {/* Product Type */}

                              <div
                                className=" top-div top-monthly-div d-flex flex-row align-item-center gap-1"
                                style={{ minWidth: "100px" }}
                              >
                                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-1">
                                  <div
                                    style={{
                                      backgroundColor: color.iconBackgroundBlue,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height: pageStyling.iconBackgroundHeight,
                                      width: pageStyling.iconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="ms-auto barChartGraphDesc"
                                  >
                                    <img
                                      src={showProductTypeIMG}
                                      alt="All Assesment img"
                                      style={{
                                        width: pageStyling.IconWidth,
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* circular progress bar div desc */}

                                <div
                                  className="top-div-circular-progress-desc flex-grow-1"
                                  style={{
                                    color: color.taxtHeadingLight,
                                    fontSize: "1.05rem",
                                    lineHeight: "1.25rem",
                                    width: "auto",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: color.tableRowHeading,
                                      fontSize: "0.65rem",
                                    }}
                                  >
                                    {Data.product_name}
                                    {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_month_data["Top district"].scan
                                  )}{" "}
                                  scans)
                                </span> */}
                                  </p>
                                  Product Type
                                </div>
                              </div>

                              {/* Divider line */}

                              {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                              {/* bottom Div */}
                              {!isSmallScreen && false && (
                                <div className=" top-div top-monthly-div d-flex flex-row align-item-center ">
                                  <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                    <div
                                      style={{
                                        backgroundColor:
                                          color.iconBackgroundBlue,
                                        borderRadius:
                                          pageStyling.imageBackgroundDivRadius,
                                        height:
                                          pageStyling.iconBackgroundHeight,
                                        width: pageStyling.iconBackgroundWidth,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      className="ms-auto barChartGraphDesc"
                                    >
                                      <img
                                        src={showInHouseImg}
                                        alt="All Assesment img"
                                        style={{
                                          width: pageStyling.IconWidth,
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {/* circular progress bar div desc */}
                                  <div
                                    className="top-div-circular-progress-desc flex-grow-1"
                                    style={{
                                      color: color.taxtHeading,
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: color.tableRowHeading,
                                      }}
                                    >
                                      {1310}
                                    </p>
                                    Machine
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* Product Details */}
                          <div
                            className="m-2"
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "0.95rem",
                              color: color.taxtHeading,
                            }}
                          >
                            Details
                          </div>
                          <div className="barChartDivDescHorizontalSeperator"></div>
                          {/* product detail div */}
                          <div className="">
                            <div className=" w-100">
                              <div className="user-details-div m-0 p-0 my-3">
                                <dl className="row gap-2">
                                  {Data &&
                                    [
                                      "serial_id",
                                      "product_desc",
                                      "price",
                                      "stage",
                                      "created_date",
                                      "updated_date",
                                    ].map((key, idx) => (
                                      <div className="d-flex gap-2" key={key}>
                                        <dt
                                          className="p-0 m-0"
                                          style={{
                                            color: color.taxtHeadingLight,
                                            fontWeight: 800,
                                            fontSize:
                                              (isSmallSmallScreen &&
                                                "0.85rem") ||
                                              "0.95rem",
                                          }}
                                        >
                                          {(key === "updated_date" &&
                                            "Last Upadted") ||
                                            key.replace("_", " ")}
                                          :
                                        </dt>
                                        <dd
                                          className=" m-0 p-0"
                                          style={{
                                            color: color.tableRowHeading,
                                            fontWeight: 600,
                                            fontSize:
                                              (isSmallSmallScreen &&
                                                "0.95rem") ||
                                              "1.05rem",
                                          }}
                                        >
                                          {([
                                            "created_date",
                                            "updated_date",
                                          ].includes(key) &&
                                            formatDate(Data[key])) ||
                                            Data[key]}
                                        </dd>
                                      </div>
                                    ))}
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        {Data.is_assignee && (
                          <div className="card-Div">
                            <div
                              className="h-100 d-flex flex-column p-2 position-relative justify-content-between"
                              style={{ minHeight: "80px" }}
                            >
                              {/* <div
                            className=""
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "0.95rem",
                              color: color.tableRowHeading,
                            }}
                          >
                            Current Assignee
                          </div> */}

                              <div
                                className="d-flex flex-column gap-3"
                                style={{
                                  fontFamily: "Open-Sans-Light",
                                  fontWeight: 800,
                                  fontSize: "0.89rem",
                                  color: color.taxtHeadingLight,
                                }}
                              >
                                <div
                                  className="top-div top-monthly-div d-flex flex-row align-item-center gap-1 justify-content-start mt-2"
                                  style={{ minWidth: "100px", height: "70px" }}
                                >
                                  <div className="d-flex align-items-center justify-content-center mx-1">
                                    <div
                                      style={{
                                        backgroundColor:
                                          color.iconBackgroundBlue,
                                        borderRadius:
                                          pageStyling.imageBackgroundDivRadius,
                                        height:
                                          pageStyling.iconBackgroundHeight,
                                        width: pageStyling.iconBackgroundWidth,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {/* <img
                                      src={showStageIMG}
                                      alt="All Assesment img"
                                      className=""
                                      style={{
                                        width: pageStyling.IconWidth,
                                        height: "auto",
                                        }}
                                        /> */}
                                      <IoPersonCircleOutline
                                        style={{
                                          color: color.secondary,
                                          width: pageStyling.IconWidth,
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="top-div-circular-progress-desc flex-grow-1"
                                    style={{
                                      color: color.taxtHeadingLight,
                                      fontSize: "1.05rem",
                                      lineHeight: "1.25rem",
                                      width: "auto",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: color.tableRowHeading,
                                        fontSize: "0.65rem",
                                      }}
                                    >
                                      {Data.assignee_name
                                        ? Data.assignee_name
                                        : "Not Assignee Yet"}
                                    </p>
                                    Assignee To
                                  </div>
                                </div>
                                {/* {Object.entries({
                              current_stage:
                                Data.product_stage_details.current_stage,
                              last_updated: getTimeSring.slice(12),
                            }).map(([key, value], idx) => {
                              return (
                                <>
                                  <div
                                    key={value}
                                    className="p-0  d-inline d-flex flex-column gap-1 h-100 justify-content-between"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {(key === "current_stage" && (
                                      <div className="flex-grow-1  d-flex align-items-center justify-content-center">
                                        <span
                                          style={{
                                            fontFamily: "Open-Sans-regular",
                                            fontWeight: 700,
                                            color: findColorByStatus("Arrival"),
                                            fontSize: "1.25rem",
                                            backgroundColor:
                                              findColorByStatus("Arrival") +
                                              "33",
                                            borderRadius: "30px",
                                            // borderRadius: "25%",
                                            padding: "5px 15px",
                                          }}
                                        >
                                          {value}
                                        </span>
                                      </div>
                                    )) ||
                                      (key === "last_updated" && (
                                        <div
                                          className="d-inline flex-grow-1 d-flex align-items-center justify-content-center "
                                          style={{ fontSize: "1.25rem" }}
                                        >
                                          {(hoursDifference > 24 && (
                                            <span className="badge bg-warning-subtle text-warning-emphasis rounded-pill px-2 ">
                                              {value}
                                            </span>
                                          )) || (
                                            <span className="badge bg-success-subtle text-success-emphasis rounded-pill  px-2">
                                              {value}
                                            </span>
                                          )}
                                        </div>
                                      ))}
                                    <div
                                      style={{
                                        color: color.taxtHeading,
                                        textAlign: "center",
                                      }}
                                    >
                                      {key.replace("_", " ")}
                                    </div>
                                  </div>

                                  {idx === 0 && (
                                    <div class="barChartDivDescVerticalSeperator"></div>
                                  )}
                                </>
                              );
                            })} */}
                              </div>

                              <div className="edit-product-stage  position-absolute top-0 end-0">
                                <ButtonGroup
                                  variant="text"
                                  aria-label="Basic button group"
                                  sx={{
                                    display: "flex",

                                    justifyContent: "center",
                                    gap: "8px", // Add the gap between buttons
                                    "& .MuiButton-outlined": {
                                      //   color: color.tableRowHeading,
                                      // border: "none",
                                      fontFamily: "Open-Sans-Medium",
                                      // borderColor: color.secondary,
                                      borderRadius: "5px",
                                      "&:hover": {
                                        color: color.secondary,
                                        borderColor: color.secondary,
                                        backgroundColor:
                                          color.iconBackgroundBlue,
                                      },
                                    },
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      // handleButtonClick("general_deatils")
                                      // console.log("open modal", Data);
                                      handelsetStageModalOpen(true);
                                      handelsetStageModalData(Data);
                                    }}
                                    sx={{
                                      // backgroundColor: color.secondary,
                                      color: color.secondary,
                                      fontSize: "0.85rem",
                                      padding: "4px 10px",
                                      //   color: showTableData.general_deatils ? "white" : "red",
                                      textTransform: "capitalize",
                                      "&:hover": {
                                        // border: "none",
                                        // backgroundColor: color.iconBackgroundBlue,
                                      },
                                    }}
                                  >
                                    Edit Assignee
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </div>
                            {stageModal?.open && (
                              <EditAssigneeModal
                                stageModalData={stageModal.data}
                                setShowModal={setStageModal}
                                handelsetStageModalOpen={
                                  handelsetStageModalOpen
                                }
                                showModal={stageModal.open}
                                color={color}
                                queryStartTime={queryStartTime}
                                productID={productID}
                                fetchDataAndUpdateFilters={
                                  fetchDataAndUpdateFilters
                                }
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}{" "}
                  </div>

                  {/* product info */}
                  <div
                    className={`${
                      (islagelargeScreen && "col-xl-8") || "col-xl-9"
                    } mb-3`}
                  >
                    {/* top divs general info / Attachments / TimeLine / Comments main components */}
                    <div
                      className="bar-chart-heading pt-2 px-1 mb-3"
                      style={{ color: color.taxtHeading }}
                    >
                      <Box
                        className="style-scrollbar"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          overflow: "auto",
                        }}
                      >
                        <ButtonGroup
                          variant="outlined"
                          aria-label="Basic button group"
                          sx={{
                            display: "flex",
                            gap: "8px",
                            "& .MuiButton-outlined": {
                              border: "none",
                              fontFamily: "Open-Sans-Medium",
                              borderRadius: "5px",
                              overflow: "visible",
                              "&:hover": {
                                color: color.secondary,
                                borderColor: color.secondary,
                                backgroundColor: color.iconBackgroundBlue,
                              },
                            },
                          }}
                        >
                          {navItems.map((item) => {
                            const isActive =
                              item.path === ""
                                ? ![
                                    "attachments",
                                    "timeline",
                                    "comments",
                                  ].includes(
                                    location.pathname.split("/").slice(-1)[0]
                                  )
                                : location.pathname.split("/").slice(-1)[0] ===
                                  item.path;
                            const targetPath = `${location.pathname
                              .split("/")
                              .slice(0, 4)
                              .join("/")}/${item.path}`; // Define full path for link preview

                            return (
                              <Button
                                key={item.path}
                                href={targetPath} // Set href for link preview on hover
                                onClick={(e) => {
                                  handleClick(e, item.path, targetPath);
                                }}
                                sx={{
                                  backgroundColor: isActive
                                    ? color.secondary
                                    : color.primary,
                                  color: isActive ? color.primary : "inherit",
                                  fontSize: "0.85rem",
                                  padding: "5px 10px",
                                  textTransform: "capitalize",
                                  "&:hover": {
                                    border: "none",
                                    backgroundColor: isActive
                                      ? "darkred"
                                      : color.iconBackgroundBlue,
                                  },
                                }}
                              >
                                {item.label}
                              </Button>
                            );
                          })}
                        </ButtonGroup>
                      </Box>
                    </div>
                    {/* general info / Attachments / TimeLine / Comments main components */}

                    {
                      <div
                        className=""
                        style={{
                          minHeight: "70vh",
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <Outlet
                          context={{
                            data: data,
                            color: color,
                            refresgProductDetails: refresgProductDetails,
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )) ||
        (showError.status && <Error />) || <Error code={500} />}
    </>
  );
};

export default ProductDetails;
