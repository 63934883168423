import React from "react";
import { CircularProgress, Stack, styled, Tooltip } from "@mui/material";
import excel_file_img from "../../assets/images/attchments/excel_file_img.png";
import IMG_file_img from "../../assets/images/attchments/img.png";
import PDF_file_img from "../../assets/images/attchments/pdf_file_img.png";
import MP3_file_img from "../../assets/images/attchments/mp3.png";
import file_img from "../../assets/images/attchments/file.png";
import { BeatLoader } from "react-spinners";
import download from "../../assets/images/download.svg";
import { IoMdCloudDownload } from "react-icons/io";

const ShowAttachments = ({
  handleFilePreview,
  handleDeleteBtn,
  handleDownloadBtn,
  attachments,
  isSmallScreen,
  showDownload,
  downloadAll,
  handleDownloadAllBtn,
  dataLoading,
  downloading,
  isDataWithTag,
  color,
}) => {
  const styles = {
    thumbnail: {
      maxHeight: "150px",
      cursor: "pointer",
      // border: "1px solid #ccc",
      borderRadius: "4px",
      transition: "transform 0.2s ease",
    },
  };

  // console.log("attachments : ", attachments)

  return (
    <div className="">
      {isDataWithTag && attachments && Object.keys(attachments).length > 0 ? (
        Object.keys(attachments).map((tag) => (
          <div key={tag} style={{ marginBottom: "20px" }}>
            {/* Tag Name */}
            <div
              className="d-flex align-items-center gap-3"
              style={{
                fontSize: "16px",
                color: "rgba(76, 78, 100, 0.99)",
                marginBottom: "12px",
              }}
            >
              <p className="p-0 m-0">
                {tag} - ({attachments[tag].length}{" "}
                {(attachments[tag].length > 1 && "Files") || "File"})
              </p>

              {downloadAll &&
                (downloading?.tag === tag && downloading?.load ? (
                  <p className="p-0 m-0">Downloading...</p>
                ) : (
                  <p
                    className="p-0 m-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDownloadAllBtn(attachments[tag], tag)}
                  >
                    <Tooltip title={`Download All ${tag} Tag Files`} arrow>
                      <img
                        src={download}
                        alt="download"
                        style={{ width: "20px" }}
                      />
                    </Tooltip>
                  </p>
                ))}
            </div>
            {/* Attachments List */}
            <div className="d-flex flex-wrap gap-3">
              {attachments[tag].map((attachment) => (
                <div
                  key={attachment.id}
                  className="attachment-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "10px",
                    border: "1px solid rgb(229, 230, 232)",
                    // boxShadow:
                    //   "0px 0px 2px 0px " +
                    //   color.taxtHeadingLight.slice(0, -2) +
                    //   "99",
                    borderRadius: "8px",
                    // backgroundColor: color.primary + "99",
                    width: "auto",
                    minWidth: "200px",
                  }}
                >
                  {/* Thumbnail */}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleFilePreview(attachment)}
                    className="d-flex flex-row gap-2 align-items-end h-100"
                  >
                    {(!(
                      dataLoading.load &&
                      dataLoading.attach_id === attachment.id
                    ) && (
                      <div>
                        <img
                          src={
                            attachment?.type === "excel"
                              ? excel_file_img
                              : attachment?.type === "img"
                              ? IMG_file_img
                              : attachment?.type === "pdf"
                              ? PDF_file_img
                              : attachment?.type === "audio"
                              ? MP3_file_img
                              : file_img
                          }
                          style={{ width: "50px", height: "auto" }}
                          alt={attachment?.file_name}
                        />
                      </div>
                    )) || (
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent={"center"}
                        height={"100%"}
                      >
                        <CircularProgress
                          style={{
                            color: color.secondary,
                            // padding: "10px",
                          }}
                          size="16px"
                        />
                      </Stack>
                    )}
                    <div
                      style={{
                        overflowWrap: "anywhere",
                        textTransform: "none",
                      }}
                    >
                      {attachment?.file_name}
                    </div>
                  </div>

                  {/* Dropdown Actions */}
                  <div className="ms-auto">
                    <div className="dropdown" style={{ cursor: "pointer" }}>
                      <Tooltip arrow title={"Actions"} placement="right">
                        <div
                          className="circular-bg"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                        </div>
                      </Tooltip>

                      <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                        {showDownload && (
                          <li className="">
                            <button
                              className="dropdown-item text-center edit-ticket-btn"
                              onClick={() => handleDownloadBtn(attachment)}
                            >
                              Download
                            </button>
                          </li>
                        )}
                        <li className="">
                          <button
                            className="dropdown-item text-center delete-ticket-btn"
                            onClick={() => handleDeleteBtn(attachment)}
                          >
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : attachments && attachments.length > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          {/* Attachments List */}
          <div className="d-flex flex-wrap gap-3">
            {attachments.map((attachment) => (
              <div
                key={attachment.id}
                className="attachment-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  border: "1px solid rgb(229, 230, 232)",
                  borderRadius: "8px",
                  // backgroundColor: color.primary + "99",
                  width: "auto",
                  minWidth: "200px",
                }}
              >
                {/* Thumbnail */}
                {
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleFilePreview(attachment)}
                    className="d-flex flex-row gap-2 align-items-end h-100 "
                  >
                    {(!(
                      dataLoading.load &&
                      dataLoading.attach_id === attachment.id
                    ) && (
                      <div>
                        <img
                          src={
                            attachment?.type === "excel"
                              ? excel_file_img
                              : attachment?.type === "img"
                              ? IMG_file_img
                              : attachment?.type === "pdf"
                              ? PDF_file_img
                              : attachment?.type === "audio"
                              ? MP3_file_img
                              : file_img
                          }
                          style={{ width: "50px", height: "auto" }}
                          alt={attachment?.file_name}
                        />
                      </div>
                    )) || (
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent={"center"}
                        height={"100%"}
                      >
                        <CircularProgress
                          style={{
                            color: color.secondary,
                            // padding: "10px",
                          }}
                          size="16px"
                        />
                      </Stack>
                    )}
                    <div
                      style={{
                        overflowWrap: "anywhere",
                        textTransform: "none",
                      }}
                    >
                      {attachment?.file_name}
                    </div>
                  </div>
                }

                {/* Dropdown Actions */}
                <div className="ms-auto">
                  <div className="dropdown" style={{ cursor: "pointer" }}>
                    <Tooltip arrow title={"Actions"} placement="right">
                      <div
                        className="circular-bg"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </div>
                    </Tooltip>
                    <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                      {showDownload && (
                        <li className="">
                          <button
                            className="dropdown-item text-center edit-ticket-btn"
                            onClick={() => handleDownloadBtn(attachment)}
                          >
                            Download
                          </button>
                        </li>
                      )}
                      <li className="">
                        <button
                          className="dropdown-item text-center delete-ticket-btn"
                          onClick={() => handleDeleteBtn(attachment)}
                        >
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // <div
        //   className="d-flex justify-content-center align-items-center"
        //   style={{ height: "20vh" }}
        // >
        //   No attachments found.
        // </div>
        ""
      )}
    </div>
  );
};

export default ShowAttachments;
