import React, { useRef, useState, useEffect, useContext } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import "primeicons/primeicons.css";
// import 'primeflex/primeflex.css';
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./FileUpload.css";
import ContextState from "../contextApi/ContextState";
import excel_file_img from "../../assets/images/attchments/excel_file_img.png";
import mp3_file_img from "../../assets/images/attchments/mp3.png";

import IMG_file_img from "../../assets/images/attchments/img.png";
import PDF_file_img from "../../assets/images/attchments/pdf_file_img.png";
import file_img from "../../assets/images/attchments/file.png";

import { useMediaQuery } from "@mui/material";

export const bytesToSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024; // 1 KB = 1024 Bytes
  const sizes = ["Bytes", "KB", "MB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = (bytes / Math.pow(k, i)).toFixed(2);

  return `${size} ${sizes[i]}`;
};

export default function FilesUpload({ genFields, setGenFields }) {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  const { openSnackbar } = useContext(ContextState);
  function findValueByKey(obj, targetKey) {
    let result = null;

    // Recursive function to traverse nested object
    function traverse(obj) {
      // Check if the current object is null or undefined
      if (obj === null || typeof obj !== "object") {
        return;
      }

      // Loop through the object's properties
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // Check if the current key matches the target key
          if (key === targetKey) {
            result = obj[key]; // Found the value, assign it to result
            return;
          }

          // If the current property is an object, recursively call traverse
          if (typeof obj[key] === "object") {
            traverse(obj[key]);
            if (result !== null) {
              // Check if result is found
              return; // Terminate recursion if result is found
            }
          }
        }
      }
    }

    // Call the recursive function with the initial object
    traverse(obj);

    return result;
  }

  const initialFiles = [
    // Example file objects
    { name: "file1.txt", size: 1000 },
    { name: "file2.jpg", size: 2000 },
    // Add more files as needed
  ];

  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const allowedFileTypes = [
    "png",
    "jpg",
    "jpeg",
    "webp",
    "pdf",
    "xlsx",
    "xls",
    "csv",
    "mp3",
  ];

  // const onTemplateSelect = (e) => {
  //   let _totalSize = totalSize;
  //   let files = e.files;
  //   const MAX_SIZE_MB = 25;
  //   const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

  //   // Get the last file from the files object
  //   const lastFile = files[files.length - 1];

  //   if (lastFile) {
  //     const fileSize = lastFile.size || 0;
  //     const fileExtension = lastFile.name.split(".").pop().toLowerCase();

  //     if (!allowedFileTypes.includes(fileExtension)) {
  //       openSnackbar(
  //         `File type '${fileExtension}' is not allowed. Please select a valid file type.`,
  //         "warning"
  //       );
  //     } else if (fileSize > MAX_SIZE_BYTES) {
  //       openSnackbar(
  //         `Cannot add '${lastFile.name}'. File size exceeds the maximum allowed size of ${MAX_SIZE_MB}MB.`,
  //         "warning"
  //       );
  //     } else {
  //       _totalSize += fileSize;

  //       // Update state with the new data
  //       setGenFields((prev) => ({
  //         ...prev,
  //         attachments: [...prev.attachments, lastFile],
  //       }));
  //       setTotalSize(_totalSize);
  //     }
  //   }
  // };

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;
    const MAX_SIZE_MB = 25;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    // Get the existing file names to avoid duplication
    const existingFileNames = genFields.attachments.map((file) => file.name);

    // Filter out files that are already in the state (based on name)
    const newFiles = files.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    // Iterate through all the new files (avoiding duplicates)
    newFiles.forEach((file) => {
      const fileSize = file.size || 0;
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedFileTypes.includes(fileExtension)) {
        openSnackbar(
          `File type '${fileExtension}' is not allowed. Please select a valid file type.`,
          "warning"
        );
      } else if (fileSize > MAX_SIZE_BYTES) {
        openSnackbar(
          `Cannot add '${file.name}'. File size exceeds the maximum allowed size of ${MAX_SIZE_MB}MB.`,
          "warning"
        );
      } else {
        _totalSize += fileSize;

        // Add the new valid files to attachments
        setGenFields((prev) => ({
          ...prev,
          attachments: [...prev.attachments, file],
        }));
      }
    });

    // Update the total size after processing all new files
    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const onTemplateRemove = (file, callback) => {
    setGenFields((prev) => {
      let newData = [];
      prev.attachments.forEach((data) => {
        if (JSON.stringify(data) !== JSON.stringify(file)) {
          newData.push(data);
        }
      });
      return newData;
    });
    // console.log("onTemplateRemove")
    setTotalSize(totalSize - file.size);
    // callback();
  };

  const onTemplateClear = () => {
    setGenFields((prev) => {
      return {
        ...prev,
        attachments: [],
      };
    });
    // console.log("onTemplateClear")
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    // console.log("headerTemplate")
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "0.5px solid rgba(76,78,100,0.1)",
          // height: "75px",
        }}
      >
        <div>
          {chooseButton}
          {/* {uploadButton} */}
          {cancelButton}
        </div>
        <div className="flex align-items-center gap-3 ml-auto">
          {genFields.attachments.length}
          {" Files Selected"}
        </div>
      </div>
    );
  };
  const removeFile = (file) => {
    setGenFields((prev) => {
      let newData = [];
      prev.attachments.forEach((data) => {
        if (JSON.stringify(data) !== JSON.stringify(file)) {
          newData.push(data);
        }
      });
      return {
        ...prev,
        attachments: newData,
      };
    });
    // console.log("onTemplateRemove")
  };

  const emptyTemplate = () => {
    return genFields.attachments.length ? (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {genFields.attachments.map((file, index) => (
          <div
            style={{
              marginBottom: "25px",
              width: "100%",
              overflowX: "hidden",
              minWidth: "300px",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              {isSmallScreen ? (
                <></>
              ) : file.name.endsWith(".pdf") ? (
                <embed
                  alt={file.name}
                  role="presentation"
                  src={file.objectURL}
                  width={125}
                />
              ) : (
                <img
                  alt={file.name}
                  role="presentation"
                  src={file.objectURL}
                  width={125}
                />
              )}

              <div>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "8px",
                  }}
                >
                  {file.name}
                  <Tag
                    severity="warning"
                    className="px-3 py-2 mt-2"
                    style={{ maxWidth: "150px" }}
                    value={bytesToSize(file.size)}
                  />
                </span>
              </div>
            </div>
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger m-auto rounded-btn"
              onClick={() => removeFile(file)}
            />
          </div>
        ))}
      </div>
    ) : (
      <div
        style={{
          height: "100px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
        }}
      >
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-3"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined rounded-btn",
  };



  return (
    <div style={{ width: "100%" }}>
      <FileUpload
        ref={fileUploadRef}
        name="demo[]"
        url="/api/upload"
        multiple
        accept="*"
        capture="environment"
        maxFileSize={100000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={(file, props) => {
          // console.log('index-', props.index);
          if (props.index === 0) {
            return genFields.attachments.length ? (
              <div
                key={props.index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                  gap: "30px",
                  flexWrap: "wrap",
                  // justifyContent: "space-between",
                  width: "100%",
                  // alignItems: "center",
                }}
              >
                {genFields.attachments.map((file, index) => (
                  <div
                    style={{
                      // marginBottom: "25px",
                      // width: "45%",
                      overflowX: "hidden",
                      // minWidth: "300px",
                      display: "flex",
                      flexDirection: "row",
                      // flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "0px",
                      boxShadow: "0px 0px 2px 0px gray",
                      borderRadius: "5px",
                      padding: "20px",
                      width:"100%"
                    }}
                  >
                    <div className="d-flex align-items-start justify-content-center">
                      {(file.name.endsWith(".mp3") && (
                        <img
                          src={mp3_file_img || file_img}
                          alt="File Review img"
                          style={{ width: "80px", height: "auto" }}
                        />
                      )) ||
                        (isSmallScreen && (
                          <img
                            src={
                              ["png", "jpg", "jpeg", "webp"].includes(
                                file.name.split(".").pop().toLowerCase()
                              )
                                ? IMG_file_img
                                : ["xlsx", "xls", "csv"].includes(
                                    file.name.split(".").pop().toLowerCase()
                                  )
                                ? excel_file_img
                                : file.name.endsWith(".pdf")
                                ? PDF_file_img
                                : file_img
                            }
                            alt="File Review img"
                            style={{ width: "80px", height: "auto" }}
                          />
                        ))}
                    </div>

                    <div
                      style={{
                        // flexGrow: 1,
                        // height: "auto",
                        display: "flex",
                        alignItems: file.name.endsWith(".mp3")
                          ? "start"
                          : "center",
                        flexDirection: file.name.endsWith(".mp3")
                          ? "column"
                          : "row",
                        justifyContent: "flex-start",
                        minWidth: "100px",
                      }}
                    >
                      {/* {isSmallScreen ? (
                        <></>
                      ) : file.name.endsWith(".pdf") ? (
                        <embed
                          alt={file.name}
                          role="presentation"
                          src={file.objectURL}
                          width={125}
                        />
                      ) : (
                        <img
                          alt={file.name}
                          role="presentation"
                          src={file.objectURL}
                          width={125}
                        />
                      )} */}
                      {isSmallScreen ? (
                        <></>
                      ) : file.name.endsWith(".pdf") ? (
                        <embed
                          alt={file.name}
                          role="presentation"
                          src={file.objectURL}
                          width={125}
                        />
                      ) : ["png", "jpg", "jpeg", "webp"].includes(
                          file.name.split(".").pop().toLowerCase()
                        ) ? (
                        <img
                          alt={file.name}
                          role="presentation"
                          src={file.objectURL}
                          width={125}
                        />
                      ) : ["xlsx", "xls", "csv"].includes(
                          file.name.split(".").pop().toLowerCase()
                        ) ? (
                        <div>
                          {/* <i
                            className="pi pi-file-excel"
                            style={{ fontSize: "3rem" }}
                          ></i> */}
                          <img
                            src={excel_file_img}
                            alt={file.name.slice(0, 10) + ".xlsx"}
                            style={{ height: "auto", width: "80px" }}
                          />
                          {/* <span>XLSX</span> */}
                        </div>
                      ) : file.name.endsWith(".mp3") ? (
                        <div className="mx-2">
                          <audio controls>
                            <source src={file.objectURL} type="audio/mp3" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ) : (
                        <div>
                          <i
                            className="pi pi-file"
                            style={{ fontSize: "3rem" }}
                          ></i>
                          <span>{file.name}</span>
                        </div>
                      )}

                      <div className="mx-2">
                        <a
                          href={file.objectURL} // Replace with the desired URL
                          target="_blank" // Opens the link in a new tab
                          rel="noopener noreferrer" // Improves security
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginLeft: "8px",
                            justifyContent: "flex-end",
                            textDecoration: "none", // Removes underline
                            color: "inherit", // Inherits the text color
                          }}
                        >
                          <span
                            style={{
                              textAlign: "left",
                              wordBreak: "break-all",
                            }}
                          >
                            {file.name}
                          </span>
                          <Tag
                            severity="warning"
                            className="px-3 py-2 mt-2"
                            style={{ maxWidth: "150px" }}
                            value={bytesToSize(file.size)}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center h-100 ms-auto">
                      <Button
                        type="button"
                        icon="pi pi-times"
                        // style={{
                        //   alignSelf:"center"
                        // }}
                        className="p-button-outlined p-button-rounded p-button-danger m-auto rounded-btn"
                        onClick={() => removeFile(file)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  height: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    fontSize: "1.2em",
                    color: "var(--text-color-secondary)",
                  }}
                  className="my-3"
                >
                  Drag and Drop Image Here
                </span>
              </div>
            );
          } else {
            return null;
          }
        }}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        style={{
          border: "0.5px  solid rgba(76,78,100,0.1)",
          borderRadius: "8px",
        }}
        files={initialFiles}
      />
    </div>
  );
}
