import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import "../../assets/styles/LineChartAnalysis.css";
// import "../../assets/styles/UserDashboard.css";
import Dropdown from "../filters/Dropdown";
import AllFilters from "../filters/AllFilters";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ToolTip from "react-bootstrap/Tooltip";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../features/filtersSlice/filterSlice";
import ContextState from "../contextApi/ContextState";
import Error from "../error/Error";
import ModalError from "../error/ModalError";
import { styled } from "@mui/material/styles";
import {
  FaTicketAlt,
  FaChartLine,
  FaCheckCircle,
  FaChartBar,
  FaMapMarkedAlt,
  FaBuilding,
  FaCogs,
} from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  Typography,
  Box,
  ButtonGroup,
  Button as MUIButton,
  Tooltip as MuiTooltip,
  linearProgressClasses,
  Pagination,
  Modal,
  Chip,
  IconButton,
} from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { Nav, Dropdown as NavDropdown } from "react-bootstrap";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import FirstPageDataTable from "../User/Dashboard/FirstPageDataTable";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import { CiExport } from "react-icons/ci";

import accepted_scans from "../../assets/images/accepted_machine_white.png";
import rejected_scans from "../../assets/images/rejected_machine_white.png";

import cardViewImg from "../../assets/images/grid (1).png";
import tableViewImg from "../../assets/images/list.png";

import cardViewWhiteImg from "../../assets/images/grid_view_white.png";
import tableViewWhiteImg from "../../assets/images/table_view_white.png";

import close from "../../assets/images/close.svg";
import ShowDataPullCard from "./ShowDataPullCard";
import download from "../../assets/images/download.svg";
import {
  CardPagesSkeleton,
  LabelContentSkeleton,
} from "../Exporters/ExportFunctions";

const icons = [, FaTicketAlt, FaCheckCircle];
const color = {
  primary: "#f7f7f9", // website background
  // secondary: "#8884d8",
  // secondary: "#826af9", // blue color in dashboard
  // secondary: "#0098e9", // blue color in dashboard
  secondary: "#666bff", // blue color in dashboard
  green: "#82ca9d", // green color in dashboard
  greenToShow: "#72e128", // green color in dashboard
  red: "#ff4d49d9", // red color in dashboard
  orange: "#fdb528", // orange color in dashboard
  navBorderBottom: "#4c4e6422",
  taxtHeadingLight: "#4c4e6499", // normal text heading light color
  taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
  tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
  iconBackgroundBlue: "#666bff39",
  iconBackgroundWhite: "#ffffffe6",
  iconBackgroundgreen: "#82ca9d39",
  iconBackgroundred: "#ff4d4939",
  iconBackgroundorange: "#fff3dd",
  textWhiteColor: "#ffffff",
  textDescBackgroundRegionDiv: "#5b61e5",
  locationTextHeading: "#4c4e64fc", //location heeading color
  option_1: "#8884d8",
  option_1_bg: "#8884d830",
  option_2: "#82ca9d",
  option_2_bg: "#82ca9d30",
  option_3: "#fdb528d9",
  option_3_bg: "#fdb52830",
  option_4: "#26c6f9d9",
  option_4_bg: "#26c6f930",
  upper_limit: "#ff4d49d9",
  lower_limit: "#ff4d4966",
};
export function formattingString(str) {
  const finalStr = str
    .split("_")
    .map((item) => item.slice(0, 1).toUpperCase() + item.slice(1))
    .join(" ");

  return finalStr;
}
const RowDetailsModal = ({ open, rowData, onClose, liveMachineData }) => {
  const { fetchApi, showLoading, formatDate, formatInIndianRupees } =
    useContext(ContextState);

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  // const machineStatus = liveMachineData?.[rowData?.machine];
  const machineStatus = null;
  // console.log("rowData : ", rowData);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
        }}
      >
        <Box
          style={{
            position: "relative",
            right: "0",
            top: "20%",
            left: "0",
            margin: isSmallScreen ? "auto" : "auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "900px",
            maxHeight: "60%",
            width: "90vw",
          }}
        >
          <Typography
            className="d-flex justify-content-between px-3 py-2 position-relative"
            style={{ borderBottom: "1px solid #f5f5f5" }}
          >
            <p
              className="mb-0"
              style={{
                fontFamily: "Open-Sans-regular",
                fontWeight: 500,
                fontSize: isSmallSmallScreen
                  ? "0.85rem"
                  : isSmallScreen
                  ? "1.25rem"
                  : "1.5rem",
                color: color.taxtHeading,
                padding: isSmallSmallScreen
                  ? "6px 1px"
                  : isSmallScreen
                  ? "2px 1px"
                  : "0px 1px",
                display: "flex",
                flexDirection: (isSmallScreen && "column") || "row",
              }}
            >
              <div>{`Scan ID Details ${isSmallScreen ? ":" : " "}`}</div>
              {!isSmallScreen ? (
                <div style={{ margin: "0 10px" }}>-</div>
              ) : (
                <></>
              )}
              <div>{` Lot Number: '${rowData["scan_id"]
                .split("-")
                .splice(2)
                .join("-")}'`}</div>
            </p>
            <div
              onClick={onClose}
              className="position-absolute"
              style={{
                top: "12px",
                right: "20px",
                zIndex: 5,
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </Typography>

          <Box className="p-3">
            <div className="upper-card-basic-info position-relative mb-3 position-relative p-3 mx-1">
              <p className="p-0 m-0 d-inline position-absolute end-0 top-0 mt-2 me-2">
                {/* <Chip
                  color={rowData["status"] === "rejected" ? "error" : "success"}
                  label={
                    rowData["status"] === "rejected"
                      ? "Rejected Scan"
                      : "Accepted Scan"
                  }
                  size="small"
                /> */}

                <Box
                  sx={{
                    // textAlign: !index ? "left" : "center",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight: "700",
                    fontSize: "1.05rem",
                    color:
                      (rowData["status"] === "pending" && color.orange) ||
                      (rowData["status"] === "ongoing" && color.option_4) ||
                      color.greenToShow,
                  }}
                >
                  <span
                    style={{
                      // border: "2px solid blue",
                      padding: "4px 12px",
                      borderRadius: "4px",
                      backgroundColor:
                        (rowData["status"] === "pending" &&
                          color.iconBackgroundorange) ||
                        (rowData["status"] === "ongoing" &&
                          color.option_4_bg) ||
                        color.iconBackgroundgreen,
                    }}
                  >
                    {rowData["status"]}
                  </span>
                </Box>
              </p>
              <div
                className={`d-flex ${
                  isSmallScreen ? "flex-column" : "flex-row"
                } mb-3`}
                // style={{ marginRight: "70px" }}
              >
                <p
                  className="basic_details_elements_label_data_table m-0"
                  style={{
                    textTransform: "capitalize",
                    fontSize: isSmallScreen ? "0.945rem" : "1.25rem",
                  }}
                >
                  Scan Id :
                </p>
                <p
                  className="basic_details_elements_data_content"
                  style={{
                    fontSize: (isSmallScreen && "0.945rem") || "1.25rem",
                    padding: (isSmallScreen && "0px 5px") || "0px 10px",
                    margin: (isSmallScreen && "2px") || "0px 10px",
                    boxShadow: "0px 0px 3px 0px " + color.taxtHeadingLight,
                    borderRadius: "4px",
                    width: "fit-content",
                  }}
                >
                  {rowData["scan_id"]}
                </p>
              </div>
              <div
                className="moredetail-gridstyle "
                style={{
                  gridTemplateColumns: isSmallSmallScreen
                    ? "1fr"
                    : isSmallScreen
                    ? "repeat(2, 1fr)"
                    : isMediumScreen
                    ? "repeat(3, 1fr)"
                    : "repeat(3, 1fr)",

                  gridRowGap: "25px",
                  // paddingTop: "25px",
                }}
              >
                {[
                  // "scan_id",
                  "region",
                  "district",
                  "machine",
                  "location",
                  "ticket_id",
                  "username",
                  "creation_time",
                  "last_updated",
                ].map((showValues, index) => (
                  <p key={`${showValues}-${index}`} className="m-0">
                    <label
                      htmlFor={`outlined-read-only-input-${showValues}`}
                      className="basic_details_elements_label_data_table"
                      style={{
                        textTransform: "capitalize",
                        fontSize: isSmallScreen ? "0.785rem" : "0.85rem",
                      }}
                    >
                      {(showValues === "pulled_by" && "Pulled By") ||
                        formattingString(showValues)}
                    </label>{" "}
                    <div
                      id={`outlined-read-only-input-${showValues}`}
                      className="basic_details_elements_data_content"
                      style={{
                        fontSize: (isSmallScreen && "0.945rem") || "1.25rem",
                      }}
                    >
                      {(["creation_time", "last_updated"].includes(
                        showValues
                      ) &&
                        formatDate(rowData[showValues])) ||
                        (showValues === "machine" && machineStatus && (
                          <MuiTooltip
                            title={
                              machineStatus?.last_online &&
                              machineStatus?.status ? (
                                <>
                                  <p className="mb-0">
                                    Status: {machineStatus?.status}
                                  </p>
                                  <p className="mb-0">
                                    Last Online :
                                    {formatDate(machineStatus?.last_online)}
                                  </p>
                                </>
                              ) : (
                                ""
                              )
                            }
                          >
                            <span>{rowData[showValues]}</span>
                          </MuiTooltip>
                        )) ||
                        rowData[showValues]}
                    </div>
                  </p>
                ))}
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
const RowDetailsModal1 = ({ open, rowData, onClose, liveMachineData }) => {
  const { fetchApi, showLoading, formatDate, formatInIndianRupees } =
    useContext(ContextState);

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  // const machineStatus = liveMachineData?.[rowData?.machine];
  const machineStatus = null;
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Submodelopen, setSubmodelOpen] = useState(false);
  const handleTableRowClick = (rowData) => {
    // console.log("handleRowClick : ", rowData);
    setSubmodelOpen(true);
    setSelectedRow(rowData);
  };
  const [showError, setShowError] = useState({
    status: null,
    msg: null,
  });
  const showErrorPage = (status, msg) => {
    showError.status = status;
    showError.msg = msg;
    setShowError(showError);
  };
  // console.log("rowData : ", rowData);

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      headers.push({
        field: "expand",
        headerName: "",
        width: 50,
        editable: false,
        sortable: false, // Disable sorting for this column
        filterable: false, // Disable filtering for this column
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                // width: !index ? 400 : 150, // Adjust width as needed
                //   marginLeft: "5px", // Add margin to separate text from progress bar
                //   fontWeight: "bold", // Customize font weight if needed
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                // color:
                //   (item.field === "status" &&
                //     ((params.value === "rejected" && color.red) ||
                //       (params.value === "accepted" && color.greenToShow))) ||
                //   (params.row["is_rejected"][item.field] && color.red) ||
                //   color.tableRowHeading,
                textWrap: "nowrap",
              }}
            >
              {
                <MuiTooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        More Details
                      </div>
                    </>
                  }
                  arrow
                >
                  <span
                    style={{
                      // border: "2px solid blue",
                      padding: "3px 9px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      textTransform: "none",

                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // console.log(params.row);
                      if (params.row) {
                        handleTableRowClick(params.row);
                      }
                    }}
                  >
                    <FaPlus />
                  </span>
                </MuiTooltip>
              }
            </Box>
          );
        },
      });
      data.column.forEach((item, index) => {
        if (!["region", "district", "location"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex:
              !isMediumScreen && !["scan_id", "status"].includes(item.field),
            headerAlign: index ? "center" : "left",
            renderHeader: (params) => (
              <MuiTooltip
                title={`${item.field
                  .replace(/_/g, " ")
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}`}
                arrow
              >
                <span
                  style={{
                    paddingLeft: ([0].includes(index) && "10px") || "0px",
                  }}
                >
                  {params.colDef.headerName}
                </span>
              </MuiTooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              return (
                <Box
                  sx={{
                    // width: !index ? 400 : 150, // Adjust width as needed
                    //   marginLeft: "5px", // Add margin to separate text from progress bar
                    //   fontWeight: "bold", // Customize font weight if needed
                    textAlign: index ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight:
                      (item.field === "status" && "600") ||
                      (index !== 0 && "500") ||
                      "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color:
                      (item.field === "status" &&
                        ((params.value === "pending" && color.orange) ||
                          (params.value === "ongoing" && color.option_4) ||
                          (params.value === "done" && color.greenToShow))) ||
                      color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <MuiTooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params.field.split("_").join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform:
                                    (item.field === "status" && "capitalize") ||
                                    "none",
                                }}
                              >
                                {(item.field === "creation_time" &&
                                  formatDate(params.value)) ||
                                  (item.field === "last_updated" &&
                                    formatDate(params.value)) ||
                                  params.value}
                              </strong>
                            </div>
                            {item.upper !== undefined && (
                              <p
                                className="m-0 p-0"
                                style={{
                                  fontSize: "0.65rem",
                                }}
                              >
                                Upper Limit : {item.upper}
                              </p>
                            )}
                            {item.lower !== undefined && (
                              <p
                                className="m-0 p-0"
                                style={{
                                  fontSize: "0.65rem",
                                }}
                              >
                                Lower Limit : {item.lower}{" "}
                              </p>
                            )}
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding:
                            (params.value === "pending" && "4px 9px") ||
                            (params.value === "ongoing" && "4px 8px") ||
                            (params.value === "done" && "4px 20px") ||
                            "4px 9px",
                          borderRadius: "4px",
                          backgroundColor:
                            (item.field === "status" &&
                              ((params.value === "pending" &&
                                color.iconBackgroundorange) ||
                                (params.value === "ongoing" &&
                                  color.option_4_bg) ||
                                (params.value === "done" &&
                                  color.iconBackgroundgreen))) ||
                            "transparent",
                          textTransform:
                            (item.field === "status" && "capitalize") || "none",
                        }}
                      >
                        {(item.field === "creation_time" &&
                          formatDate(params.value, true)) ||
                          (item.field === "last_updated" &&
                            formatDate(params.value, true)) ||
                          params.value}
                      </span>
                    </MuiTooltip>
                  }
                </Box>
              );
            },
          });
        }
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = (Data?.table_data &&
    Data?.table_data?.rows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: Data?.table_data?.rows || [],
      column: Data?.table_data?.column || [],
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  const fetchData = async (group_id = null, reloadData = true) => {
    if (reloadData) {
      setData(null);
    }
    setLoading(true);
    const payload = {};

    if (group_id) {
      payload["group_id"] = group_id;
    }

    let is_all_ticket_data_Mounted = true;
    // console.log("data send : ", payload);

    const all_tickets_data = await fetchApi(
      "get_pull_id_scans_details",
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    // console.log("data send : ", payload, all_tickets_data?.data);

    if (is_all_ticket_data_Mounted) {
      if (all_tickets_data.statusCode === 200) {
        showErrorPage(null, null);
        setData(all_tickets_data?.data || null);
      } else if (
        all_tickets_data.statusCode === 500 ||
        all_tickets_data.statusCode === 401
      ) {
        setData(all_tickets_data?.data || null);
        showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
        // console.log(
        //   "all_tickets_data.statusCode, all_tickets_data.msg : ",
        //   all_tickets_data.statusCode,
        //   all_tickets_data.msg
        // );
      } else {
        setData(all_tickets_data?.data || null);
        showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    setLoading(false);

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };

  useEffect(() => {
    fetchData(rowData["data_pull_id"]);
  }, []);

  const handleSubTableRowClose = () => {
    setSubmodelOpen(false);
  };

  // export data for data pull modal ....
  const onExportLocal = () => {
    let headingsData = [];

    for (const [key, value] of Object.entries(rowData)) {
      if (!["id", "scanIds_list"].includes(key)) {
        if (key === "creation_time") {
          headingsData.push([formattingString(key), formatDate(value)]);
        } else if (key === "machines_list") {
          headingsData.push([formattingString(key), value.join(",")]);
        } else {
          headingsData.push([formattingString(key), value]);
        }
      }
    }

    // Process the scan table data, filtering out "id" and "is_rejected"
    const scantable = Data?.table_data?.rows?.map((col) => {
      let scanDataObj = {};

      for (const [key, value] of Object.entries(col)) {
        if (key !== "id" && key !== "is_rejected") {
          if (key === "creation_time") {
            scanDataObj[key] = formatDate(value);
          } else {
            scanDataObj[key] = value;
          }
        }
      }

      return scanDataObj;
    });

    // Prepare the headings for the scan table
    const scanTableHeadings = Object.keys(scantable[0]).map((item) =>
      item === "folder_type"
        ? formattingString("pull_folder")
        : formattingString(item)
    );

    // Convert the scan table rows to arrays of values
    const scanTableData = scantable.map((item) => Object.values(item));

    // Create worksheet data for Excel export
    const wsData = [
      ...headingsData.map((item) => [...item]),
      [],
      [],
      [], // Empty rows for spacing
      [...scanTableHeadings], // Scan table headings
      ...scanTableData.map((item) => [...item]), // Scan table data
    ];

    // Convert the data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DataPullSummary");

    // Generate and download the Excel file with a timestamp
    XLSX.writeFile(
      wb,
      `DataPullSummary_${rowData?.data_pull_id}_${new Date()
        .toString()
        .slice(0, 24)
        .replaceAll(" ", "_")}.xlsx`
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
        }}
      >
        <Box
          style={{
            position: "relative",
            right: "0",
            top: "10%",
            left: "0",
            margin: islageScreen ? "auto 10px" : "auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "1500px",
            maxHeight: "80%",
            minHeight: "60vh",
          }}
        >
          <Box
            className="d-flex justify-content-between align-items-center px-3 py-2 position-relative"
            style={{
              borderBottom: "1px solid #f5f5f5",
              backgroundColor: (showError.status && color.primary) || "inherit",
            }}
          >
            <p
              className="mb-0"
              style={{
                fontFamily: "Open-Sans-regular",
                fontWeight: 500,
                fontSize: isSmallSmallScreen
                  ? "1.0rem"
                  : isSmallScreen
                  ? "1.25rem"
                  : "1.5rem",
                color: color.taxtHeading,
              }}
            >
              {!loading &&
                `Data Pull Summary - Pull ID #${rowData["data_pull_id"]}`}
            </p>
            <div className="d-flex gap-2 gap-sm-3 ms-1">
              <div onClick={onExportLocal}>
                <img
                  src={download}
                  alt="download"
                  style={{ width: "19px", cursor: "pointer" }}
                />
              </div>
              <div onClick={onClose}>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </Box>
          {(!showError.status && (
            <>
              <Box className="p-3">
                {(loading && (
                  // <div
                  //   className="showSpinner all-ticket-spinner"
                  //   style={{ height: "100%" }}
                  // >
                  //   <BeatLoader color="#2D97D4" />
                  // </div>
                  <>
                    <div className="m-0 p-0">
                      {/* <CardPagesSkeleton
                        len={1}
                        cardHeight={30}
                        componentClass={"issue-type-row-div"}
                      /> */}
                      <LabelContentSkeleton
                        len={isSmallScreen ? 8 : 16}
                        labelWidth="30%"
                        headerWidth="90%"
                        cardHeight={30}
                        componentClass="issue-type-row-div"
                      />
                      <CardPagesSkeleton
                        len={1}
                        cardHeight={500}
                        componentClass={""}
                      />
                    </div>
                  </>
                )) ||
                  (Data && !Data.msg && (
                    <>
                      <div
                        className="upper-card-basic-info moredetail-gridstyle mb-3 position-relative p-3 mx-1"
                        style={{
                          gridTemplateColumns: isSmallSmallScreen
                            ? "1fr"
                            : isSmallScreen
                            ? "repeat(2, 1fr)"
                            : isMediumScreen
                            ? "repeat(3, 1fr)"
                            : "repeat(4, 1fr)",

                          gridRowGap: "25px",
                          paddingTop: "25px",
                        }}
                      >
                        <p className="p-0 m-0 d-inline position-absolute end-0 top-0 mt-2 me-2">
                          {/* <Chip
                  color={rowData["status"] === "rejected" ? "error" : "success"}
                  label={
                    rowData["status"] === "rejected"
                      ? "Rejected Scan"
                      : "Accepted Scan"
                  }
                  size="small"
                /> */}

                          <Box
                            sx={{
                              // textAlign: !index ? "left" : "center",
                              fontFamily: "Open-Sans-Medium",
                              fontWeight: "700",
                              fontSize: "1.05rem",
                              color:
                                (rowData["done_percent"] === 0 && color.red) ||
                                (rowData["done_percent"] <= 50 &&
                                  color.orange) ||
                                color.greenToShow,
                            }}
                          >
                            <span
                              style={{
                                // border: "2px solid blue",
                                padding: "4px 12px",
                                borderRadius: "4px",
                                backgroundColor:
                                  (rowData["done_percent"] === 0 &&
                                    color.iconBackgroundred) ||
                                  (rowData["done_percent"] <= 50 &&
                                    color.iconBackgroundorange) ||
                                  color.iconBackgroundgreen,
                              }}
                            >
                              {rowData["done_percent"]} % Completed
                            </span>
                          </Box>
                        </p>
                        {[
                          "data_pull_id",
                          "pulled_by",
                          "file_pulled",
                          "reason",
                          "ticket_id",
                          "creation_time",
                          "pending_count",
                          "ongoing_count",
                          "done_count",
                        ].map((showValues, index) => (
                          <p key={`${showValues}-${index}`} className="m-0">
                            <label
                              htmlFor={`outlined-read-only-input-${showValues}`}
                              className="basic_details_elements_label_data_table"
                              style={{
                                textTransform: "capitalize",
                                fontSize: isSmallScreen
                                  ? "0.785rem"
                                  : "0.85rem",
                              }}
                            >
                              {(showValues === "pulled_by" && "Pulled By") ||
                                formattingString(showValues)}
                            </label>{" "}
                            <div
                              id={`outlined-read-only-input-${showValues}`}
                              className="basic_details_elements_data_content"
                              style={{ fontSize: "1.25rem" }}
                            >
                              {(["creation_time"].includes(showValues) &&
                                formatDate(rowData[showValues])) ||
                                (showValues === "machine" && machineStatus && (
                                  <MuiTooltip
                                    title={
                                      machineStatus?.last_online &&
                                      machineStatus?.status ? (
                                        <>
                                          <p className="mb-0">
                                            Status: {machineStatus?.status}
                                          </p>
                                          <p className="mb-0">
                                            Last Online :
                                            {formatDate(
                                              machineStatus?.last_online
                                            )}
                                          </p>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    }
                                  >
                                    <span>{rowData[showValues]}</span>
                                  </MuiTooltip>
                                )) ||
                                rowData[showValues]}
                            </div>
                          </p>
                        ))}
                      </div>
                      <div className={`m-0 p-0 mb-4`}>
                        <div className="card-Div">
                          <Box
                            sx={{
                              height: "45vh",
                              width: "100%",
                            }}
                          >
                            {Submodelopen && (
                              <RowDetailsModal
                                open={Submodelopen}
                                rowData={selectedRow}
                                onClose={handleSubTableRowClose}
                                liveMachineData={liveMachineData}
                              />
                            )}
                            <DataGrid
                              rows={TabelDataRows}
                              columns={TableDataHeaders}
                              // pagination={false} // Disable pagination
                              // pagination={100} // Disable pagination
                              // autoPageSize // Automatically adjust page size based on available height
                              density="standard"
                              paginationMode="client"
                              // components={{
                              //   Pagination:
                              //     visibleRows > 100 ? CustomPagination : null,
                              // }}
                              // hideFooterPagination
                              pageSize={101}
                              disableSelectionOnClick
                              disableRowSelectionOnClick
                              onRowClick={(param) => {
                                // console.log("row_param : ", param.row);
                                // if (param.row) {
                                //   handleTableRowClick(param.row);
                                // }
                              }}
                              getRowClassName={(params) => {
                                // console.log(
                                //   "params : ",
                                //   params,
                                //   params.row.id === selectedRow?.id
                                // );
                                return params.row.id === selectedRow?.id
                                  ? "selected-row"
                                  : "";
                              }}
                              sx={{
                                "&.MuiDataGrid-root": {
                                  border: "none",
                                  borderRadius: "5px", // Adjust border radius as needed
                                  overflow: "hidden", // Ensure content respects the border radius,
                                  padding: "0px",
                                  margin: "0px",
                                  marginTop: "-10px",
                                },
                                ".MuiDataGrid-columnHeader": {
                                  backgroundColor: color.textWhiteColor,
                                },
                                ".MuiDataGrid-filler": {
                                  backgroundColor: color.textWhiteColor,
                                },
                                ".MuiDataGrid-columnHeaderTitle": {
                                  fontFamily: "Open-Sans-Light",
                                  fontWeight: "bold",
                                  padding: "5px 0px",
                                  fontSize: "0.85rem",
                                  textTransform: "capitalize",
                                  color: color.taxtHeading,
                                },
                                ".MuiDataGrid-columnHeader--alignCenter": {
                                  headerAlign: "left",
                                },
                                ".MuiDataGrid-row": {
                                  border: "none", // Remove border from rows
                                  backgroundColor: color.textWhiteColor, // Set rows background color to white
                                  "&:hover": {
                                    backgroundColor: color.primary, // Ensure background color remains white on hover
                                    // cursor: "pointer",
                                  },
                                },
                                ".MuiDataGrid-footerContainer": {
                                  display: "inline-block", // Hide the pagination footer
                                  justifyContent: "space-between", // Space out selected row count and pagination controls
                                  alignItems: "center", // Vertically align items in the footer
                                  marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                                  height: "30px",
                                },
                                ".MuiTablePagination-root": {
                                  // ".MuiInputBase-root": {
                                  //   display: "none",
                                  // },

                                  // ".MuiTablePagination-selectLabel": {
                                  //   display: "none",
                                  // },
                                  ".MuiToolbar-root": {
                                    marginTop: "0px",
                                  },
                                },
                                ".MuiDataGrid-cell": {
                                  // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                                  outline: "none",
                                  // display: "-webkit-box",
                                  // WebkitBoxOrient: "vertical",
                                  // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                                  // overflow: "hidden",
                                  // textOverflow: "ellipsis",
                                  "&:focus": {
                                    outline: "none", // Remove the blue outline when a cell is focused
                                  },
                                },
                                "&.selected-row": {
                                  backgroundColor: "red", // Highlight color for the selected row
                                  "&:hover": {
                                    backgroundColor: "red ", // Ensure highlight color remains on hover
                                  },
                                },
                                ".MuiDataGrid-selectedRowCount": {
                                  display: "none", // Hide the selected row count div
                                },
                                ".MuiTablePagination-toolbar": {
                                  // width: "80%",  // Adjust the width here
                                  // margin: "0 auto", // Center align the pagination
                                  // marginLeft:"auto",
                                  padding: "2px 16px", // You can adjust padding as well if needed
                                  marginTop: "-15px",
                                },
                              }}
                            />
                          </Box>
                        </div>
                      </div>
                    </>
                  )) ||
                  (Data && Data.msg && (
                    <div className="container no-ticket-found-div text-center count_heading_div">
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                        <p>{(Data && Data.msg) || "No Data Found"}</p>
                      </div>
                    </div>
                  ))}
              </Box>
            </>
          )) ||
            (showError.status && (
              <ModalError
                code={showError.status}
                msg={showError.msg}
                modelHeight={"70vh"}
              />
            )) || <ModalError code={404} modelHeight={"70vh"} />}
        </Box>
      </Modal>
    </div>
  );
};

const ScanidDetailedAnalytics = () => {
  const BorderLinearProgress = styled(LinearProgress)(
    ({ theme, colorAccept, colorReject, value }) => ({
      height: 8,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colorReject,
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colorAccept,
        borderRadius: 0,
        opacity: value > 50 ? 1 : 0.75,
        // borderRight: "2px solid white",
      },
    })
  );

  // const issueTyepLogoBGcolor = [, "#f79009", "#10b981"];
  function generateMonthsList(fromDate, toDate) {
    const startDate = new Date(fromDate + "-01"); // Convert fromDate to Date object
    const endDate = new Date(toDate + "-01"); // Convert toDate to Date object

    const monthsList = [];
    let currentDate = startDate;

    // Loop until currentDate is less than or equal to endDate
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // getMonth() returns zero-based month index
      const formattedMonth = month < 10 ? "0" + month : month.toString(); // Format month with leading zero if needed
      const formattedDate = year + "-" + formattedMonth;
      monthsList.push(formattedDate);

      // Move currentDate to the next month
      currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    }

    return monthsList;
  }
  // Set initial width
  const chartContainerRef = useRef(null);

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  const [chartWidth, setChartWidth] = useState(1700);
  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "25px",
    iconBackgroundWidth: "45px",
    iconBackgroundHeight: "45px",
  };

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.clientWidth;
        setChartWidth(containerWidth <= 1100 ? 1100 : containerWidth * 0.95);
      }
    };

    updateChartWidth();

    window.addEventListener("resize", updateChartWidth);

    return () => {
      window.removeEventListener("resize", updateChartWidth);
    };
  }, []);

  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Showing Data Pull Details{" "}
      {(Data?.created_start_date && Data?.created_end_date && (
        <>
          between Scan Date starting from{" "}
          <strong>{Data?.created_start_date}</strong> to
          <strong> {Data?.created_end_date} </strong>
        </>
      )) ||
        "according to there PullingGroupIds"}
    </ToolTip>
  );

  const [showError, setShowError] = useState({
    status: null,
    msg: null,
  });
  const showErrorPage = (status, msg) => {
    showError.status = status;
    showError.msg = msg;
    setShowError(showError);
  };

  // ************* filters required data ***********
  const {
    fetchApi,
    showLoading,
    formatDate,
    formatInIndianRupees,
    generalApiCall,
    gencontrollerRef,
    controllerRef,
  } = useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters); // user filter from redux

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");

  const [liveMachinesData, setliveMachinesData] = useState(null);

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);
  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters
  const [filters, setFilters] = useState({
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    time: [],
    machineNumber: [],
    hourlyStartDate: [],
    hourlyEndDate: [],
    DailyStartDate: [],
    DailyEndDate: [],
    MonthlyStartDate: [],
    MonthlyEndDate: [],
    weeklyStartDate: [],
    weeklyEndDate: [],
    crop_variation: [],
    params_variation: [],
    moreFilters: [],
    searchBox: [],
    visitSearchBox: [],
    scanIDSearchBox: [],
    ticketPerPage: [],
    assignee: [],
    sort: [],
    commitId: [],
    modelNo: [],
    compare: [],
    option: [],
    reasone: [],
  });
  const [showAllfilters, setshowAllFilters] = useState({
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    time: [],
    machineNumber: [],
    hourlyStartDate: [],
    hourlyEndDate: [],
    DailyStartDate: [],
    DailyEndDate: [],
    MonthlyStartDate: [],
    MonthlyEndDate: [],
    crop_variation: [],
    params_variation: [],
    moreFilters: [],
    searchBox: [],
    visitSearchBox: [],
    scanIDSearchBox: [],
    sort: [],
    ticketPerPage: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    reasone: [],
  });
  const [filterCount, setFilterCount] = useState({
    issueType: 0,
    subIssueType: 0,
    RO: 0,
    DO: 0,
    time: 0,
    machineNumber: 0,
    hourlyStartDate: 0,
    hourlyEndDate: 0,
    DailyStartDate: 0,
    DailyEndDate: 0,
    MonthlyStartDate: 0,
    MonthlyEndDate: 0,
    crop_variation: 0,
    params_variation: 0,
    moreFilters: 0,
    searchBox: 0,
    visitSearchBox: 0,
    scanIDSearchBox: 0,
    sort: 0,
    ticketPerPage: 0,
    assignee: 0,
    commitId: 0,
    modelNo: 0,
    reasone: 0,
  });
  // Function to update filters
  const updateFilters = (
    Filters,
    clickedPageNumber = 0,
    updateURL = true,
    reloadData = false
  ) => {
    // console.log("update filter 0 -->", Filters, clickedPageNumber);

    Filters = applyCoustomChecksFilter(Filters);
    setFilters(Filters);
    setCurrentPage(clickedPageNumber);
    if (updateURL) {
      updateURLParams(Filters, clickedPageNumber);
      // console.log("Url Is Updating ");
    }
    // else {
    // // console.log("Url Is Not Updating ");
    // }
    updateFilterCount(Filters);
    if (getUserFilters?.issues) {
      applyIssueTypeFilters(Filters);
    }
    if (getUserFilters?.param_data) applyCropVariationFilters(Filters);
    // makeSortFilterVisible(Filters);
    showAllFilters(Filters);
    // if (selectView === 0) {
    fetchData(Filters, clickedPageNumber, selectView, reloadData);
    // }
  };

  const getData = (updatedView) => {
    fetchData(filters, 0, updatedView, false);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params, page) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    // console.log("url send to update : ", params);
    urlSearchParams.set("currentPage", parseInt(page) + 1);
    urlSearchParams.set("cardsPerPage", cardsPerPage[0]);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/FCI/scaniddetailedanalysis" && !Data) {
      // Check if the state already exists
      // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
            Page: parseInt(page) + 1,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      // console.log("update state Wroking");
      window.history.pushState(
        {
          filters: params,
          Page: parseInt(page) + 1,
        },
        "",
        newUrl
      );
    }
  };

  // set page parameter to render
  const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
  const [totalCardsCount, settotalCardsCount] = useState({
    0: 20,
    1: 20,
    2: 20,
    3: 20,
  }); // Initialize total vards from backend
  // Number of cards to display per page
  const [cardsPerPage, setCardsPerPage] = useState({
    0: 20,
    1: 20,
    2: 20,
    3: 20,
  });

  const handelCurrentPage = (clickedPageNumber) => {
    // // console.log(
    //   "current Page ----------->",
    //   clickedPageNumber,
    //   parseInt(clickedPageNumber) + 1,
    //   currentPage
    // );
    // setCurrentPage(parseInt(clickedPageNumber) + 1);
    setCurrentPage(0);
    updateFilters(filters, parseInt(clickedPageNumber), true, true);
  };

  // this will populate our dashboard page
  const [Data, setData] = useState(null); // page data

  const [RDMData, setRDMData] = useState(null); // page data
  // filter change according to end point mapping
  const [filterchange, setFilterChange] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });
  const endpointMapping = {
    0: "get_pull_scans_group_details",
    1: "get_scan_region_destrict_machine_info",
    2: "get_scan_region_destrict_machine_info",
    3: "get_scan_region_destrict_machine_info",
  };

  const fetchData = async (F, Page = 0, endPointKey = 0, reloadData = true) => {
    if (reloadData) {
      setData(null);
      setRDMData(null);
    }
    setLoading(true);
    showLoading(true);
    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};
    payload["page_num"] = parseInt(Page) + 1;
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["start_date"] = F["createdStartDate"][0];
    }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["end_date"] = F["createdEndDate"][0];
    }
    if (
      (F["resolvedStartDate"] && F["resolvedStartDate"].length) ||
      F["status"].includes("Resolved")
    ) {
      payload["updated_last_start_date"] = F["resolvedStartDate"][0];
    }
    if (
      (F["resolvedEndDate"] && F["resolvedEndDate"].length) ||
      F["status"].includes("Resolved")
    ) {
      payload["updated_last_end_date"] = F["resolvedEndDate"][0];
    }
    //  filters for dashboard
    if (F["time"]?.length !== 0) {
      payload["frequency"] = F["time"][0];
    }
    if (F["weeklyStartDate"]?.length && F["time"].includes("weekly")) {
      payload["start_date"] = F["weeklyStartDate"][0];
    }
    if (F["weeklyEndDate"]?.length && F["time"].includes("weekly")) {
      payload["end_date"] = F["weeklyEndDate"][0];
    }
    if (F["DailyStartDate"]?.length && F["time"].includes("daily")) {
      payload["start_date"] = F["DailyStartDate"][0];
    }
    if (F["DailyEndDate"]?.length && F["time"].includes("daily")) {
      payload["end_date"] = F["DailyEndDate"][0];
    }
    if (F["MonthlyStartDate"]?.length && F["time"].includes("monthly")) {
      payload["start_date"] = F["MonthlyStartDate"][0];
    }
    if (F["MonthlyEndDate"]?.length && F["time"].includes("monthly")) {
      payload["end_date"] = F["MonthlyEndDate"][0];
    }
    if (
      F["MonthlyStartDate"]?.length &&
      F["time"].includes("monthly") &&
      F["MonthlyEndDate"]?.length &&
      F["time"].includes("monthly")
    ) {
      payload["months_list"] = generateMonthsList(
        F["MonthlyStartDate"],
        F["MonthlyEndDate"]
      );
    }

    if (F["issueType"] && F["issueType"].length) {
      payload["issue"] = F["issueType"];
    }
    if (F["subIssueType"] && F["subIssueType"].length) {
      payload["sub_issue"] = F["subIssueType"];
    }
    if (F["status"] && F["status"].length) {
      payload["status"] = F["status"].map((e) => e.toLowerCase());
    }
    if (F["reasone"] && F["reasone"].length) {
      payload["reasone"] = F["reasone"].map((e) => e.toLowerCase());
    }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    // if (F["sort"] && F["sort"].length) {
    //   const sortDictManual = {
    //     "Creation time- Ascending": "creation_time:ASC",
    //     "Creation time- Descending": "creation_time:DESC",
    //     "Updated time- Descending": "last_updated:DESC",
    //   };
    //   const sortArr =
    //     (sortDictManual[filtersOptions["sortDict"][0]] &&
    //       F["sort"].map((item) => filtersOptions["sortDict"][item])) ||
    //     sortDictManual[F["sort"][0]];
    //   payload["Sort"] = sortArr;
    // }
    if (F["sort"] && F["sort"].length) {
      payload["sort"] = F["sort"][0];
    }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop"] = F["crop_variation"][0];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      const updateDesc = {};
      for (const data of F["params_variation"]) {
        const selectedValue = data.split("_");
        const paramValue =
          selectedValue && selectedValue[0] ? selectedValue[0] : null;
        const DropdownValue =
          selectedValue && selectedValue[1] ? selectedValue[1] : null;
        const startRange =
          selectedValue && selectedValue[2] ? selectedValue[2] : null;
        const endRange =
          selectedValue && selectedValue[3] ? selectedValue[3] : null;
        const filterValue =
          selectedValue && selectedValue[4] ? selectedValue[4] : null;

        updateDesc[paramValue] = {
          option: DropdownValue,
          startRange: startRange,
          endRange: endRange,
          filter: filterValue,
        };
        // console.log(
        //   "selected value : ",
        //   selectedValue,
        //   paramValue,
        //   DropdownValue,
        //   startRange,
        //   endRange,
        //   filterValue
        // );
      }
      payload["description"] = updateDesc;
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["assignee"] = F["assignee"];
    }
    if (F["moreFilters"] && F["moreFilters"].length) {
      F["moreFilters"].forEach((item) => {
        if (item === "Tickets Page Pull Data")
          payload["raise_from_ticket"] = true;
        else if (item === "ScanID Page Pull Data")
          payload["raise_outside_ticket"] = true;
      });
    }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["scanIDSearchBox"] &&
      F["scanIDSearchBox"].length &&
      F["scanIDSearchBox"][0] !== ""
    ) {
      payload["scan_id"] = F["scanIDSearchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }
    if (
      F["visitSearchBox"] &&
      F["visitSearchBox"].length &&
      F["visitSearchBox"][0] !== ""
    ) {
      payload["group_id"] = F["visitSearchBox"][0];
    }
    if (F["option"] && F["option"].length && F["option"][0] !== "") {
      const dict = {};
      for (let index = 0; index < F["option"].length; index++) {
        const select = F["option"][index].split(/-(.+)/);
        const selectCompare = select[0];
        const selectValue = select[1];
        dict[`option_${index + 1}`] = {
          compare: selectCompare,
          value:
            selectCompare === "machine" ? selectValue.slice(0, 4) : selectValue,
        };
      }
      payload["option"] = dict;
    }

    let is_all_ticket_data_Mounted = true;
    // console.log("data send : ", payload);

    const all_tickets_data = await fetchApi(
      endpointMapping[endPointKey],
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    // console.log("data send : ", payload, all_tickets_data?.data);

    if (is_all_ticket_data_Mounted) {
      if (all_tickets_data.statusCode === 200) {
        showErrorPage(null, null);
        if (endPointKey === 0) {
          setData(all_tickets_data?.data || null);
          settotalCardsCount((prevfilter) => {
            const updateFilter = { ...prevfilter };
            updateFilter[selectView] = all_tickets_data?.data?.total_count || 1;
            return updateFilter;
          });

          if (all_tickets_data?.data?.tickets_limit === "All") {
            // setCardsPerPage(all_tickets_data?.data?.tickets_limit || 1);
            setCardsPerPage((prevfilter) => {
              const updateFilter = { ...prevfilter };
              updateFilter[selectView] =
                all_tickets_data?.data?.total_count || 1;
              return updateFilter;
            });
          } else {
            setCardsPerPage((prevfilter) => {
              const updateFilter = { ...prevfilter };
              updateFilter[selectView] =
                all_tickets_data?.data?.tickets_limit || 1;
              return updateFilter;
            });
            // setCardsPerPage(all_tickets_data?.data?.tickets_limit || 1);
          }
        } else {
          setRDMData(all_tickets_data?.data || null);
          settotalCardsCount((prevfilter) => {
            const updateFilter = { ...prevfilter };
            updateFilter[1] = all_tickets_data?.data?.region_total_count || 1;
            updateFilter[2] = all_tickets_data?.data?.district_total_count || 1;
            updateFilter[3] = all_tickets_data?.data?.machine_total_count || 1;
            return updateFilter;
          });

          setCardsPerPage((prevfilter) => {
            const updateFilter = { ...prevfilter };
            updateFilter[1] =
              Math.min(100, all_tickets_data?.data?.region_total_count) || 1;
            updateFilter[2] =
              Math.min(100, all_tickets_data?.data?.district_total_count) || 1;
            updateFilter[3] =
              Math.min(all_tickets_data?.data?.machine_total_count) || 1;
            return updateFilter;
          });
        }
      } else if (
        all_tickets_data.statusCode === 500 ||
        all_tickets_data.statusCode === 401
      ) {
        setData(all_tickets_data?.data || null);
        showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
        // console.log(
        //   "all_tickets_data.statusCode, all_tickets_data.msg : ",
        //   all_tickets_data.statusCode,
        //   all_tickets_data.msg
        // );
      } else {
        setData(all_tickets_data?.data || null);
        // showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    setLoading(false);
    showLoading(false);

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };

  // useEffect(() => {
  //   console.log("all Data : ", Data, RDMData);
  // }, [Data, RDMData]);

  const handleFilterChange = (filter, filterType, removeOld = false) => {
    // console.log(filter, filterType);
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filterType];

      if (
        filterList &&
        (filterType === "searchBox" ||
          filterType === "scanIDSearchBox" ||
          filterType === "visitSearchBox" ||
          filterType === "createdStartDate" ||
          filterType === "createdEndDate" ||
          filterType === "resolvedStartDate" ||
          filterType === "resolvedEndDate" ||
          filterType === "hourlyStartDate" ||
          filterType === "hourlyEndDate" ||
          filterType === "DailyStartDate" ||
          filterType === "DailyEndDate" ||
          filterType === "MonthlyStartDate" ||
          filterType === "MonthlyEndDate" ||
          filterType === "weeklyStartDate" ||
          filterType === "weeklyEndDate" ||
          filterType === "time")
      ) {
        if (filterType === "time") {
          currentFilters["DailyStartDate"] = [];
          currentFilters["DailyEndDate"] = [];
          currentFilters["MonthlyStartDate"] = [];
          currentFilters["MonthlyEndDate"] = [];
          currentFilters["hourlyStartDate"] = [];
          currentFilters["hourlyEndDate"] = [];
          currentFilters["weeklyStartDate"] = [];
          currentFilters["weeklyEndDate"] = [];
        }
        if (filter !== "") currentFilters[filterType] = [filter];
        else currentFilters[filterType] = [];
      } else if (filterType === "params_variation") {
        // console.log("params_variation : ", filter, filterType);

        const baseFilter = filter.split("_")[0];

        const filtered = currentFilters["params_variation"].filter(
          (field) => field.split("_")[0] !== baseFilter
        );

        if (
          filtered.length === currentFilters["params_variation"].length ||
          removeOld
        ) {
          // If no entries were removed, add the new filter
          filtered.push(filter);
        }

        currentFilters["params_variation"] = filtered;
        // console.log("--> after:  : ", currentFilters["params_variation"]);
      } else if (filterList && filterType === "compare") {
        if (filter !== "") currentFilters[filterType] = [filter];
        else {
          currentFilters[filterType] = [];
        }
      } else if (
        (filterList && filterType === "sort") ||
        filterType === "ticketPerPage" ||
        filterType === "crop_variation"
      ) {
        currentFilters[filterType] = [filter];

        if (
          currentFilters["crop_variation"] &&
          currentFilters["crop_variation"].length
        ) {
          setfiltersOptions((prevFilters) => {
            const updatedSort = Object.entries(
              getUserFilters["crop_param_limit"]
            )
              .filter(
                ([key, value]) => key === currentFilters["crop_variation"][0]
              )
              .flatMap(([key, value]) =>
                Object.entries(value["params"]).flatMap(([p_key, p_value]) => [
                  `${p_key}-Ascending`,
                  `${p_key}-Descending`,
                ])
              ); // Flatten the result

            const preservedSort = ["Date-Ascending", "Date-Descending"];
            // console.log("sort filter : ", [...preservedSort, ...updatedSort]);
            return {
              ...prevFilters,
              sort: [...preservedSort, ...updatedSort],
            };
          });
        }
        if (filterType === "crop_variation") {
          currentFilters["sort"] = [];
        }
      } else if (filterList && filterList.includes(filter)) {
        currentFilters[filterType] = filterList.filter(
          (prevFilter) => prevFilter !== filter
        );
      } else {
        currentFilters[filterType] = [...(filterList || []), filter];
      }
      if (filterType === "RO") {
        applyROFilters(currentFilters);
      } else if (filterType === "machineNumber") {
        applyMachineNumberFilters(currentFilters);
      } else if (filterType === "DO") {
        applyDOFilters(currentFilters);
      }
      if (filterType !== "params_variation") {
        if (filterType === "crop_variation")
          currentFilters["params_variation"] = [];
        applyCropVariationFilters(currentFilters);
      }
      applyIssueTypeFilters(currentFilters);
      updateFilterCount(currentFilters);

      //   console.log(
      //     "update filter 1 -->",
      //     prevFilters,
      //     currentFilters,
      //     filter,
      //     filterType
      //   );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    const updatefilters = { ...filters };
    updatefilters[filtername] = [];
    if (filtername === "moreFilters") {
      // updatefilters["searchBox"] = [];
      updatefilters["ticketPerPage"] = [];
    }
    if (filtername === "issueType") {
      updatefilters["subIssueType"] = [];
    }
    if (filtername === "time") {
      updatefilters["DailyStartDate"] = [];
      updatefilters["DailyEndDate"] = [];
      updatefilters["MonthlyStartDate"] = [];
      updatefilters["MonthlyEndDate"] = [];
      updatefilters["hourlyStartDate"] = [];
      updatefilters["hourlyEndDate"] = [];
    }
    if (filtername === "dateSelector") {
      updatefilters["createdStartDate"] = [];
      updatefilters["createdEndDate"] = [];
      updatefilters["resolvedStartDate"] = [];
      updatefilters["resolvedEndDate"] = [];
    }
    if (filtername === "crop_variation") {
      setfiltersOptions((prevFilters) => {
        return {
          ...prevFilters,
          sort: [...filtersOptionsCopy["sort"]],
        };
      });

      if (
        updatefilters["sort"] &&
        updatefilters["sort"].length !== 0 &&
        !filtersOptionsCopy["sort"].includes(updatefilters["sort"][0])
      ) {
        updatefilters["sort"] = [];
      }
    }
    updateFilterCount(updatefilters);
    setFilters(updatefilters);
    // console.log(filtername);
    if (filtername === "RO") {
      applyROFilters(updatefilters);
    } else if (filtername === "machineNumber") {
      applyMachineNumberFilters(updatefilters);
    } else if (filtername === "DO") {
      applyDOFilters(updatefilters);
    }
    // console.log(updatefilters, "updateFilterCount");
    // applyIssueTypeFilters(updatefilters);
    updateFilterCount(updatefilters);
  };

  const handleSaveChanges = (filtername) => {
    // console.log("sendFilters->  ", filtername, filters[filtername]);
    const filterAfterCoustomChange = applyCoustomChecksFilter(filters);
    updateFilters(filterAfterCoustomChange, 0, true, true);
    setshowAllFilters(filterAfterCoustomChange);
  };

  const handelResetAllBtn = () => {
    // console.log("working");
    for (const key in filters) {
      filters[key] = [];
    }
    // console.log("updated filter ", filters);
    updateFilters(filters, 0, true, true);

    setfiltersOptions((prevState) => ({
      ...prevState,
      DO: filtersOptionsCopy["DO"],
      machineNumber: filtersOptionsCopy["machineNumber"],
      RO: filtersOptionsCopy["RO"],
    }));
  };

  const handelDeleteFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    if (filters.hasOwnProperty(filterType)) {
      // Remove the specified filterValue from the filterKey array
      filters[filterType] = filters[filterType].filter(
        (filter) => filter !== subfilter
      );
    }
    if (filterType === "status" && subfilter === "Resolved") {
      filters["resolvedEndDate"] = [];
      filters["resolvedStartDate"] = [];
    }
    if (filterType === "issueType") {
      filters["subIssueType"] = [];
      filters["status"] = [];
      if (subfilter === "Data Variation") {
        filters["crop_variation"] = [];
        filters["params_variation"] = [];
        filters["modelNo"] = [];
        filters["commitId"] = [];
      }
    }
    if (filterType === "crop_variation") {
      filters["params_variation"] = [];
      setfiltersOptions((prevFilters) => {
        return {
          ...prevFilters,
          sort: [...filtersOptionsCopy["sort"]],
        };
      });

      if (
        filters["sort"] &&
        filters["sort"].length !== 0 &&
        !filtersOptionsCopy["sort"].includes(filters["sort"][0])
      ) {
        filters["sort"] = [];
      }
    }

    if (
      filterType === "hourlyStartDate" ||
      filterType === "hourlyEndDate" ||
      filterType === "DailyStartDate" ||
      filterType === "DailyEndDate" ||
      filterType === "MonthlyStartDate" ||
      filterType === "MonthlyEndDate" ||
      filterType === "time"
    ) {
      if (filterType !== "time") {
        filters["time"] = [];
      }
      filters["DailyStartDate"] = [];
      filters["DailyEndDate"] = [];
      filters["MonthlyStartDate"] = [];
      filters["MonthlyEndDate"] = [];
      filters["hourlyStartDate"] = [];
      filters["hourlyEndDate"] = [];
    }
    if (filterType === "RO") {
      applyROFilters(filters);
    } else if (filterType === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filterType === "DO") {
      applyDOFilters(filters);
    }
    updateFilters(filters, 0, true, true);

    // console.log("Deleted single filter --->", filters);
  };
  const handelTempRemoveFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };

      if (currentFilters.hasOwnProperty(filterType)) {
        // Remove the specified filterValue from the filterKey array
        currentFilters[filterType] = currentFilters[filterType].filter(
          (filter) => filter !== subfilter
        );
      }

      return currentFilters;
    });
  };
  const handelAutoSelectFilterBtn = (FilterName, FilterValue) => {
    // given machine number find its district and ro
    // and only ro if it is ro machine
    // console.log("Auto Select working : ", FilterName, FilterValue);
    const optionsSelected = FilterValue.split(/-(.+)/);
    const comparator = optionsSelected[0];
    const value = optionsSelected[1];
    // console.log("Auto Select working : ", comparator, value);
    const MachineValueArr = Array.from([value.slice(0, 4)]);
    // console.log(MachineValueArr, typeof MachineValueArr);
    let filteredRODO = Object.entries(getUserFilters?.ro_machines || {}).reduce(
      (acc, [ro, roMachine]) => {
        if (MachineValueArr.includes(roMachine)) {
          acc.push({ RO: ro });
        }
        return acc;
      },
      []
    );
    // console.log("Ro Machine Data ", filteredRODO);

    if (filteredRODO.length === 0) {
      filteredRODO = Object.entries(getUserFilters?.filters_data || {}).reduce(
        (acc, [ro, roData]) => {
          Object.entries(roData || {}).forEach(([doKey, machines]) => {
            const matchingMachines = machines.filter((machine) =>
              MachineValueArr.includes(machine)
            );
            if (matchingMachines.length > 0) {
              acc.push({ RO: ro, DO: doKey });
            }
          });
          return acc;
        },
        []
      );
    }

    // Extract unique RO and DO keys
    const uniqueRO = Array.from(new Set(filteredRODO.map((entry) => entry.RO)));
    const uniqueDO = Array.from(new Set(filteredRODO.map((entry) => entry.DO)));

    // console.log(uniqueRO, uniqueDO);

    const addOptions = [];
    for (const RO of uniqueRO) {
      if (RO) {
        addOptions.push(`region-${RO}`);
      }
    }
    for (const DO of uniqueDO) {
      if (DO) {
        addOptions.push(`district-${DO}`);
      }
    }

    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };

      currentFilters[FilterName] = [
        ...currentFilters[FilterName].filter((value) => value === FilterValue),
        ...addOptions,
      ];

      return currentFilters;
    });
  };

  function showAllFilters(FiltersDict) {
    for (const i in FiltersDict) {
      if (FiltersDict[i].length !== 0) {
        // console.log("show filter");
        setallFilterDisplay(true);
        return;
      }
    }
    setallFilterDisplay(false);
  }

  const [getUserFilters, setgetUserFilters] = useState(null);

  // filters to show

  const [filtersOptions, setfiltersOptions] = useState({
    status: ["Pending", "Ongoing", "Done"],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    searchBox: [],
    visitSearchBox: [],
    moreFilters: ["Tickets Page Pull Data", "ScanID Page Pull Data"],
    reasone: ["Model Training", "Image Verification"],
    sort: ["Date-Ascending", "Date-Descending"],
    // time: ["weekly", "daily", "monthly"],
    // compare: ["Region", "District", "Machine"],
    ticketPerPage: ["20", "50", "100", "All"],
  });
  const [filtersOptionsCopy, setfiltersOptionsCopy] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    searchBox: [],
    visitSearchBox: [],
    sort: ["Date-Ascending", "Date-Descending"],
    moreFilters: [],
  });
  // filters sorting according to selected filter
  const getFilterResponse = async (response, initalFilters = null) => {
    // setLoading(true);
    // showLoading(true);
    try {
      // calling refresh token whenever loading the page
      // console.log("get_user_filters--->", response);
      if (response.statusCode === 200) {
        // console.log("get_user_filters Data Fetched Successfully");
        const data = response.data;
        // console.log("data", new Date().toLocaleString(), data);
        setgetUserFilters(data);

        // Extract RO# keys from  filters_data
        const roKeys =
          data && data.access_level === "institution"
            ? Object.keys(data?.filters_data || {})
            : [];
        const compareUniqueRoKeys = roKeys.map((keys) => ({
          label: keys,
        }));
        let uniqueRoKeys = roKeys;
        const roKeysCopy = roKeys;

        // Extract DO# keys from RO objects in filters_data
        const doKeys =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {}).flatMap((Do) =>
                Object.keys(Do)
              )
            : data && data.access_level === "region"
            ? Object.keys(data.filters_data)
            : [];

        // Populate machineNumber array with unique machine numbers
        let uniquedoKeys = Array.from(new Set(doKeys));
        const uniquedoKeysCopy = uniquedoKeys;
        const compareUniqueDoKeys = uniquedoKeys.map((keys) => ({
          label: keys,
        }));

        // Extract machine numbers from DO keys
        const machineNumbervalue =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {})
                .flatMap((ro) => Object.values(ro))
                .flatMap((doData) => doData)
                .flatMap((machine) => machine)
                .concat(Object.values(data?.ro_machines || {}))
            : data && data.access_level === "region"
            ? Object.values(data?.filters_data || {})
                .flatMap((doList) => {
                  return Object.values(doList);
                })
                .flat()
            : data && data.access_level === "district"
            ? data.filters_data
            : [];

        // Populate machineNumber array with unique machine numbers
        let uniqueMachineNumbers = Array.from(new Set(machineNumbervalue));
        const uniqueMachineNumbersCopy = uniqueMachineNumbers;
        const comapreUniqueMachineNumbers = uniqueMachineNumbers.map(
          (keys) => ({
            label: `${keys} (${data.machines_data[keys]})`,
          })
        );

        // ********** not required ************
        // populate subissuetype in filter option
        // const subIssueTypes = Object.values(data.issues[0]).flatMap((issue) =>
        //   issue.sub_issue.map((subIssue) => subIssue[1])
        // );
        let commitIdArr = data && Object.values(data.commit_id_list || {});

        commitIdArr = commitIdArr.map((commitIds, index) => {
          return commitIds.slice(0, 5);
        });
        const modelNoArr = data && Object.values(data.model_no_list || {});
        // console.log(
        //   "Sub issues : ",
        //   subIssueTypes,
        //   typeof subIssueTypes,
        //   subIssueTypes.length
        // );
        // populating sort in filters Option
        // const sortArr =
        //   (data && data.sort_list.map((item) => item.value)) || [];
        // const sortDictArr =
        //   (data &&
        //     data.sort_list.reduce((acc, item) => {
        //       acc[item.value] = item.id;
        //       return acc;
        //     }, {})) ||
        //   [];

        const assigneeType =
          (data && data.assignee_list?.map((item) => item.name)) || [];
        // console.log("assigneeType", assigneeType);
        const cropvariationTypes = data ? Object.keys(data.param_data) : [];

        // let dataParamsTypes =
        //   Array.from(
        //     new Set(
        //       data &&
        //         Object.values(data.param_data).flatMap((item) =>
        //           Object.keys(item.client_params)
        //         )
        //     )
        //   ) || [];
        // const dataParamsTypesCopy = dataParamsTypes;
        let sortingData = ["Date-Ascending", "Date-Descending"];
        // console.log("--> all Parametrs : ", dataParamsTypes);
        if (initalFilters) {
          if (initalFilters?.RO?.length !== 0) {
            const roFilters = initalFilters?.RO;

            // Extract all DO keys from selected RO filters78
            const filteredDO = roFilters.flatMap((ro) =>
              data.filters_data[ro]
                ? Object.keys(data?.filters_data[ro] || {})
                : []
            );

            // console.log("filteredDO", filteredDO, roFilters);

            // Extract all machine numbers from selected RO filters and DO keys
            const filteredMachineNumbers = roFilters.flatMap((ro) =>
              data.filters_data[ro]
                ? Object.values(data?.filters_data[ro] || {})
                    .flatMap((doValues) => doValues)
                    .concat(data?.ro_machines[ro] ? data?.ro_machines[ro] : [])
                : []
            );

            // Update filtersOptions with unique filtered DO values and machine numbers
            uniquedoKeys = Array.from(new Set(filteredDO));
            uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
          } else if (initalFilters?.DO?.length != 0) {
            const doFilters = initalFilters.DO;
            if (data.access_level === "institution") {
              // Find corresponding RO keys for selected DO keys
              const roKeys = [];
              Object.entries(data?.filters_data || {}).forEach(
                ([roKey, doValues]) => {
                  Object.keys(doValues).forEach((doKey) => {
                    if (doFilters.includes(doKey)) {
                      roKeys.push(roKey);
                    }
                  });
                }
              );

              // Extract all machine numbers from selected DO keys
              const filteredMachineNumbers = doFilters.flatMap((doKey) =>
                Object.values(data.filters_data)
                  .filter((ro) => ro[doKey])
                  .flatMap((ro) => ro[doKey])
              );
              // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
              uniqueMachineNumbers = Array.from(
                new Set(filteredMachineNumbers)
              );
              uniqueRoKeys = Array.from(new Set(roKeys));

              // console.log(
              //   "Do start filter ",
              //   "ROKeys : ",
              //   roKeys,
              //   "Machine Filetrs : ",
              //   uniqueMachineNumbers
              // );
            } else if (data.access_level === "region") {
              // Extract machine numbers for selected DO keys
              const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
                if (data.filters_data[doKey]) {
                  acc.push(...data.filters_data[doKey]);
                }
                return acc;
              }, []);
              uniqueMachineNumbers = Array.from(
                new Set(filteredMachineNumbers)
              );
            }
          } else if (initalFilters?.machineNumber?.length != 0) {
            const machineNumberFilters = initalFilters.machineNumber;
            if (data.access_level === "institution") {
              // Extract corresponding RO and DO keys for selected machine numbers
              const filteredRODO = Object.entries(
                data?.filters_data || {}
              ).reduce((acc, [ro, roData]) => {
                Object.entries(roData || {}).forEach(([doKey, machines]) => {
                  const matchingMachines = machines.filter((machine) =>
                    machineNumberFilters.includes(machine)
                  );
                  if (matchingMachines.length > 0) {
                    acc.push({ RO: ro, DO: doKey });
                  }
                });
                return acc;
              }, []);

              // Extract unique RO and DO keys
              uniqueRoKeys = Array.from(
                new Set(filteredRODO.map((entry) => entry.RO))
              );
              uniquedoKeys = Array.from(
                new Set(filteredRODO.map((entry) => entry.DO))
              );
            } else if (data.access_level === "region") {
              // Extract DO keys for selected machine numbers
              const filteredDOKeys = Object.keys(data.filters_data).filter(
                (doKey) => {
                  const machineNumbers = data.filters_data[doKey];
                  return machineNumbers.some((number) =>
                    machineNumberFilters.includes(number)
                  );
                }
              );
              uniquedoKeys = Array.from(new Set(filteredDOKeys));
            }
          }
        }
        // console.log("--> Filterd Parametrs : ", dataParamsTypes);

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        // const statusTypes = Array.from(
        //   new Set(Object.values(data?.states_list || {}).flat())
        // );
        // const issueTypeArr = Object.keys(data?.states_list || {});
        // console.log("issueTypeArr : ", issueTypeArr)

        // const params_limit = data.crop_param_limit;
        // console.log("params_limit : ", params_limit);

        setfiltersOptions((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: uniqueRoKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          //   subIssueType: subIssueTypes,
          //   crop_variation: cropvariationTypes,
          assignee: assigneeType,
          //   params_variation: dataParamsTypes,
          //   params_limit: params_limit,
          //   sort: sortingData,
          //   issueType: issueTypeArr,
          //   modelNo: modelNoArr,
          //   commitId: commitIdArr,
          // status: statusTypes,
          // Compare_RO: compareUniqueRoKeys,
          // Compare_DO: compareUniqueDoKeys,
          // Compare_machineNumber: comapreUniqueMachineNumbers,
        }));
        setfiltersOptionsCopy((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeysCopy,
          DO: uniquedoKeysCopy,
          machineNumber: uniqueMachineNumbersCopy,
          //   subIssueType: subIssueTypes,
          //   assignee: assigneeType,
          //   crop_variation: cropvariationTypes,
          //   params_variation: dataParamsTypesCopy,
          //   issueType: issueTypeArr,
          //   modelNo: modelNoArr,
          //   commitId: commitIdArr,
          // status: statusTypes,
        }));
      } else {
        // Handle non-successful responses
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const applyROFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;

    // Extract all DO keys from selected RO filters78
    const filteredDO = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.keys(getUserFilters?.filters_data[ro] || {})
        : []
    );

    // console.log("filteredDO", filteredDO, roFilters);

    // Extract all machine numbers from selected RO filters and DO keys
    const filteredMachineNumbers = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.values(getUserFilters?.filters_data[ro] || {})
            .flatMap((doValues) => doValues)
            .concat(
              getUserFilters?.ro_machines[ro]
                ? getUserFilters?.ro_machines[ro]
                : []
            )
        : []
    );

    // Update filtersOptions with unique filtered DO values and machine numbers
    const uniqueDO = Array.from(new Set(filteredDO));
    const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));

    if (roFilters.length === 0) {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["RO"] = [];
      newFilter["machineNumber"] = [];
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);
      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: filtersOptionsCopy["DO"],
        machineNumber: filtersOptionsCopy["machineNumber"],
      }));
    } else {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["machineNumber"] = [];
      // console.log("uniqueMachineNumber --> ", uniqueMachineNumbers);
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);

      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: uniqueDO,
        machineNumber: uniqueMachineNumbers,
      }));
    }
  };

  const applyDOFilters = (filtersDict) => {
    const doFilters = filtersDict.DO;
    const roFilters = filtersDict.RO;
    if (getUserFilters.access_level === "institution") {
      // Find corresponding RO keys for selected DO keys
      const roKeys = [];
      Object.entries(getUserFilters?.filters_data || {}).forEach(
        ([roKey, doValues]) => {
          Object.keys(doValues).forEach((doKey) => {
            if (doFilters.includes(doKey)) {
              roKeys.push(roKey);
            }
          });
        }
      );

      // Extract all machine numbers from selected DO keys
      const filteredMachineNumbers = doFilters.flatMap((doKey) =>
        Object.values(getUserFilters.filters_data)
          .filter((ro) => ro[doKey])
          .flatMap((ro) => ro[doKey])
      );
      // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
      const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
      const uniqueRO = Array.from(new Set(roKeys));
      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
          RO: filtersOptionsCopy["RO"],
        }));
      } else {
        //filters["machineNumber"] = uniqueMachineNumbers;
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: uniqueMachineNumbers,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract machine numbers for selected DO keys
      const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
        if (getUserFilters.filters_data[doKey]) {
          acc.push(...getUserFilters.filters_data[doKey]);
        }
        return acc;
      }, []);

      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
        }));
      } else {
        // Update filtersOptions machineNumber with unique filtered machine numbers
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: Array.from(new Set(filteredMachineNumbers)),
        }));
      }
    }
  };

  const applyMachineNumberFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;
    const doFilters = filtersDict.DO;
    const machineNumberFilters = filtersDict.machineNumber;
    if (getUserFilters.access_level === "institution") {
      // Extract corresponding RO and DO keys for selected machine numbers
      const filteredRODO = Object.entries(
        getUserFilters?.filters_data || {}
      ).reduce((acc, [ro, roData]) => {
        Object.entries(roData || {}).forEach(([doKey, machines]) => {
          const matchingMachines = machines.filter((machine) =>
            machineNumberFilters.includes(machine)
          );
          if (matchingMachines.length > 0) {
            acc.push({ RO: ro, DO: doKey });
          }
        });
        return acc;
      }, []);

      // Extract unique RO and DO keys
      const uniqueRO = Array.from(
        new Set(filteredRODO.map((entry) => entry.RO))
      );
      const uniqueDO = Array.from(
        new Set(filteredRODO.map((entry) => entry.DO))
      );

      // Update filtersOptions with unique RO and DO keys
      if (machineNumberFilters.length === 0) {
        // console.log(
        //   "machineNumberFilters.length",
        //   machineNumberFilters.length,
        //   filtersOptions
        // );
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: filtersOptionsCopy["RO"],
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
          DO: doFilters.length === 0 ? uniqueDO : prevState.DO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract DO keys for selected machine numbers
      const filteredDOKeys = Object.keys(getUserFilters.filters_data).filter(
        (doKey) => {
          const machineNumbers = getUserFilters.filters_data[doKey];
          return machineNumbers.some((number) =>
            machineNumberFilters.includes(number)
          );
        }
      );

      if (machineNumberFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        // Update filtersOptions DO with unique filtered DO keys
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO:
            doFilters.length === 0
              ? Array.from(new Set(filteredDOKeys))
              : prevState.RO,
        }));
      }
    }
  };
  // const applyIssueTypeFilters = (filterDict) => {
  // Function to update filtersOptions based on selected issueType
  const applyIssueTypeFilters = (filterDict) => {
    // Filter subIssueType values based on the selected issueType
    const filteredSubIssueType =
      getUserFilters?.issues?.reduce((acc, issue) => {
        const selectedIssueTypes = filterDict.issueType;
        for (const issueType of selectedIssueTypes) {
          if (issue[issueType]) {
            acc.push(
              ...issue[issueType].sub_issue?.map((subIssue) => subIssue[1])
            );
          }
        }
        return acc;
      }, []) || [];

    // Variable to store status options
    const statusOptions = [];

    // Iterate over statesList and update statusOptions
    // Object.keys(getUserFilters?.states_list || {}).forEach((key) => {
    //   if (filterDict.issueType.includes(key)) {
    //     statusOptions.push(...(getUserFilters?.states_list[key] || []));
    //   }
    // });

    // console.log(
    //   "statusOptions.push(...statesList[key]) --> ",
    //   new Set([...filters.status, ...statusOptions])
    // );

    if (filterDict.issueType.length) {
      // Update filtersOptions state with the filtered subIssueType values
      setfiltersOptions((prevState) => ({
        ...prevState,
        subIssueType: Array.from(
          new Set([...(filters.subIssueType || []), ...filteredSubIssueType])
        ),
        // status: Array.from(new Set([...filters.status, ...statusOptions])),
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        subIssueType: filtersOptionsCopy.subIssueType,
        // status: filtersOptionsCopy.status,
      }));
    }
  };

  const applyCropVariationFilters = (filterDict) => {
    // Get the list of param_data keys from filters.crop_variation
    const selectedCrops = filterDict.crop_variation;

    // Filter client_params keys based on selectedCrops
    const clientParamsKeysArray = Object.entries(
      getUserFilters?.param_data || {}
    )
      .filter(([key]) => selectedCrops.includes(key)) // Filter based on selectedCrops
      .flatMap(([key, value]) => Object.keys(value.client_params));

    // Update filtersOptions.params_variation
    const updatedParamsVariation = Array.from(
      new Set([...(filters.params_variation || []), ...clientParamsKeysArray])
    );

    // console.log(
    //   "updatedParamsVariation",
    //   filterDict,
    //   getUserFilters?.param_data,
    //   updatedParamsVariation
    // );
    if (selectedCrops.length) {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: updatedParamsVariation,
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: filtersOptionsCopy["params_variation"],
      }));
    }
  };

  const applyCoustomChecksFilter = (filterDict) => {
    // if (filterDict["status"] && !filterDict["status"].includes("Resolved")) {
    //   filterDict["resolvedEndDate"] = [];
    //   filterDict["resolvedStartDate"] = [];
    // }

    // if (
    //   filterDict?.issueType &&
    //   !filterDict?.issueType?.includes("Data Variation")
    // ) {
    // filterDict["params_variation"] = [];
    // filterDict["crop_variation"] = [];
    // filterDict["modelNo"] = [];
    // filterDict["commitId"] = [];
    // }

    // if (filterDict?.issueType && filterDict?.issueType?.length === 0) {
    //   filterDict.subIssueType = [];
    //   filterDict.status = [];
    // }

    if (
      filterDict?.crop_variation &&
      filterDict?.crop_variation?.length === 0
    ) {
      filterDict.params_variation = [];
    }

    filterDict["compare"] = [];

    return filterDict;
  };

  const makeSortFilterVisible = (filterDict) => {
    if (filterDict?.crop_variation?.length !== 0) {
      const selectedCrops = filterDict.crop_variation;

      setfiltersOptions((prev) => {
        // updating the sorting filter
        const updatedSort = Object.entries(
          getFilterResponse.crop_param_limit || {}
        )
          .filter(([key, value]) => key === selectedCrops[0])
          .flatMap(([key, value]) =>
            Object.entries(value["params"]).flatMap(([p_key, p_value]) => [
              `${p_key}-Ascending`,
              `${p_key}-Descending`,
            ])
          ); // Flatten the result
        return {
          ...prev,
          sort: [...filtersOptionsCopy["sort"], ...updatedSort],
        };
        // console.log("sorting Data : ", sortingData, updatedSort);
      });
    }
  };

  // Memoized function to fetch live machine data
  const get_live_machine_data = useCallback(async () => {
    try {
      const response = await generalApiCall(
        "get_live_machines_status",
        "POST",
        {},
        true
      );
      if (response.statusCode === 200) {
        setliveMachinesData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    document.title = "Scan Details - NEO";
    setLoading(true);
    showLoading(true);
    setData(null);
    setRDMData(null);
    // console.log("data set to empty List - 1");
    const params = new URLSearchParams(window.location.search);
    // console.log("prams -->", params);
    const statusFilterData =
      params.get("status") !== "" && params.get("status") !== null
        ? params.get("status").split(",")
        : [];
    const issueTypeFilterData =
      params.get("issueType") !== "" && params.get("issueType") !== null
        ? params.get("issueType").split(",")
        : [];
    const ROFilterData =
      params.get("RO") !== "" && params.get("RO") !== null
        ? params.get("RO").split(",")
        : [];
    const DOFilterData =
      params.get("DO") !== "" && params.get("DO") !== null
        ? params.get("DO").split(",")
        : [];
    const machineNumberFilterData =
      params.get("machineNumber") !== "" && params.get("machineNumber") !== null
        ? params.get("machineNumber").split(",")
        : [];
    const timeFilterData =
      params.get("time") !== "" && params.get("time") !== null
        ? params.get("time").split(",")
        : [];
    const weeklyStartDateFilterData =
      params.get("weeklyStartDate") !== "" &&
      params.get("weeklyStartDate") !== null
        ? params.get("weeklyStartDate").split(",")
        : [];
    const weeklyEndDateFilterData =
      params.get("weeklyEndDate") !== "" && params.get("weeklyEndDate") !== null
        ? params.get("weeklyEndDate").split(",")
        : [];
    const DailyStartDateFilterData =
      params.get("DailyStartDate") !== "" &&
      params.get("DailyStartDate") !== null
        ? params.get("DailyStartDate").split(",")
        : [];
    const DailyEndDateFilterData =
      params.get("DailyEndDate") !== "" && params.get("DailyEndDate") !== null
        ? params.get("DailyEndDate").split(",")
        : [];
    const MonthlyStartDateFilterData =
      params.get("MonthlyStartDate") !== "" &&
      params.get("MonthlyStartDate") !== null
        ? params.get("MonthlyStartDate").split(",")
        : [];
    const MonthlyEndDateFilterData =
      params.get("MonthlyEndDate") !== "" &&
      params.get("MonthlyEndDate") !== null
        ? params.get("MonthlyEndDate").split(",")
        : [];

    const createdStartDateFilterData =
      params.get("createdStartDate") !== "" &&
      params.get("createdStartDate") !== null
        ? params.get("createdStartDate").split(",")
        : [];
    const createdEndDateFilterData =
      params.get("createdEndDate") !== "" &&
      params.get("createdEndDate") !== null
        ? params.get("createdEndDate").split(",")
        : [];
    // const commitIdFilterData =
    //   params.get("commitId") !== "" && params.get("commitId") !== null
    //     ? params.get("commitId").split(",")
    //     : [];
    // const modelNoFilterData =
    //   params.get("modelNo") !== "" && params.get("modelNo") !== null
    //     ? params.get("modelNo").split(",")
    //     : [];
    // console.log("machineNumberFilterData", machineNumberFilterData);
    const resolvedStartDateDateFilterData =
      params.get("resolvedStartDate") !== "" &&
      params.get("resolvedStartDate") !== null
        ? params.get("resolvedStartDate").split(",")
        : [];
    const resolvedEndDateFilterData =
      params.get("resolvedEndDate") !== "" &&
      params.get("resolvedEndDate") !== null
        ? params.get("resolvedEndDate").split(",")
        : [];
    const assigneeFilterData =
      params.get("assignee") !== "" && params.get("assignee") !== null
        ? params.get("assignee").split(",")
        : [];
    // const subIssueTypeFilterData =
    //   params.get("subIssueType") !== "" && params.get("subIssueType") !== null
    //     ? params.get("subIssueType").split(",")
    //     : [];
    // const subIssueTypeFilterData =
    //   params.get("subIssueType") !== "" && params.get("subIssueType") !== null
    //     ? params.get("subIssueType").split(",")
    //     : [];
    const sortFilterData =
      params.get("sort") !== "" && params.get("sort") !== null
        ? params.get("sort").split(",")
        : [];
    const reasoneFilterData =
      params.get("reasone") !== "" && params.get("reasone") !== null
        ? params.get("reasone").split(",")
        : [];
    const searchBoxFilterData =
      params.get("searchBox") !== "" && params.get("searchBox") !== null
        ? params.get("searchBox").split(",")
        : [];
    const visitSearchBoxFilterData =
      params.get("visitSearchBox") !== "" &&
      params.get("visitSearchBox") !== null
        ? params.get("visitSearchBox").split(",")
        : [];
    const scanIDSearchBoxFilterData =
      params.get("scanIDSearchBox") !== "" &&
      params.get("scanIDSearchBox") !== null
        ? params.get("scanIDSearchBox").split(",")
        : [];
    const Page =
      params.get("currentPage") !== "" && params.get("currentPage") !== null
        ? params.get("currentPage")
        : 1;
    const ticketPerPageFilterData =
      params.get("ticketPerPage") !== "" && params.get("ticketPerPage") !== null
        ? [params.get("ticketPerPage")]
        : [];
    // console.log("ticketPerPageFilterData", ticketPerPageFilterData);
    if (
      ticketPerPageFilterData.length ||
      ticketPerPageFilterData[0] === "20" ||
      ticketPerPageFilterData[0] === "50" ||
      ticketPerPageFilterData[0] === "100"
    ) {
      // setCardsPerPage(parseInt(ticketPerPageFilterData[0]));
      if (selectView === 0) {
        setCardsPerPage((prevfilter) => {
          const updateFilter = { ...prevfilter };
          updateFilter[selectView] = parseInt(ticketPerPageFilterData[0]);
          return updateFilter;
        });
      }
    }
    // console.log("searchBoxFilterData: ", searchBoxFilterData);
    const cropVariationFilterData =
      params.get("crop_variation") !== "" &&
      params.get("crop_variation") !== null
        ? params.get("crop_variation").split(",")
        : [];
    const paramVariationFilterData =
      params.get("params_variation") !== "" &&
      params.get("params_variation") !== null
        ? params.get("params_variation").split(",")
        : [];
    // const compareOptionFilterData =
    //   params.get("option") !== "" && params.get("option") !== null
    //     ? params.get("option").split(",")
    //     : [];
    // console.log("compareOptionFilterData : ", compareOptionFilterData);
    const moreFiltersFilterData =
      params.get("moreFilters") !== "" && params.get("moreFilters") !== null
        ? params.get("moreFilters").split(",")
        : [];

    // console.log("moreFiltersFilterData", paramVariationFilterData);
    // machineNumber: [],
    // createdStartDate: [],
    // createdEndDate: [],
    // resolvedStartDate: [],
    // resolvedEndDate: [],
    // sort: [],
    // crop_variation: [],
    // params_variation: [],

    // console.log("------------->", Page);
    const initalFilters = {
      status: statusFilterData,
      issueType: issueTypeFilterData,
      RO: ROFilterData,
      DO: DOFilterData,
      machineNumber: machineNumberFilterData,
      time: timeFilterData,
      sort: sortFilterData,
      //   hourlyStartDate: hourlyStartDateFilterData,
      //   hourlyEndDate: hourlyEndDateFilterData,
      // weeklyStartDate: weeklyStartDateFilterData,
      // weeklyEndDate: weeklyEndDateFilterData,
      // DailyStartDate: DailyStartDateFilterData,
      // DailyEndDate: DailyEndDateFilterData,
      // MonthlyStartDate: MonthlyStartDateFilterData,
      // MonthlyEndDate: MonthlyEndDateFilterData,
      createdStartDate: createdStartDateFilterData,
      createdEndDate: createdEndDateFilterData,
      resolvedStartDate: resolvedStartDateDateFilterData,
      resolvedEndDate: resolvedEndDateFilterData,
      crop_variation: cropVariationFilterData,
      params_variation: paramVariationFilterData,
      // option: compareOptionFilterData,
      visitSearchBox: visitSearchBoxFilterData,
      moreFilters: moreFiltersFilterData,
      searchBox: searchBoxFilterData,
      ticketPerPage: ticketPerPageFilterData,
      scanIDSearchBox: scanIDSearchBoxFilterData,
      assignee: assigneeFilterData,
      //   commitId: commitIdFilterData,
      //   modelNo: modelNoFilterData,
      //   subIssueType: subIssueTypeFilterData,
      reasone: reasoneFilterData,
    };
    // console.log("insitial filter --->", initalFilters);

    let is_all_filter_data_Mounted = true;

    if (is_all_filter_data_Mounted) {
      get_live_machine_data();
    }
    const intervalId = setInterval(async () => {
      await get_live_machine_data();
    }, 45000);

    const fetchDataAndUpdateFilters = async (
      initalFilters,
      Page,
      updateURL = true
    ) => {
      const currentTime = Date.now();
      const lastDataFetchTimestamp = userFilters?.lastDataFetchTimestamp;

      if (
        !lastDataFetchTimestamp ||
        currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
      ) {
        const all_filter_data = await fetchApi(
          "get_user_filters",
          "POST",
          {},
          is_all_filter_data_Mounted
        );
        // console.log("all_filter_data in All Ticket : ", all_filter_data);

        if (all_filter_data.statusCode === 200) {
          dispatch(addFilters(all_filter_data));
          getFilterResponse(all_filter_data, initalFilters);
          showErrorPage(null, null);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          setData(null);
          setRDMData(null);
          showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        } else {
          setData(null);

          setRDMData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        // console.log("Data fetch from redux ************", userFilters?.data);
        getFilterResponse(userFilters?.data);
      }
      updateFilters(initalFilters, parseInt(Page) - 1, updateURL, true);
      setshowAllFilters(initalFilters);
      // showLoading(false);
    };
    // console.log("initial filter from url : ", initalFilters);
    fetchDataAndUpdateFilters(initalFilters, Page);
    // console.log("data set to empty List - 2");

    // setTimeout(() => {
    //   setLoading(false);
    //   showLoading(false);
    // }, 2000);

    const handlePopState = () => {
      // Your logic to handle popstate event
      // console.log("mount", "User navigated back or forward");
      const previousPageURL = window.history.state || null;
      if (previousPageURL && previousPageURL.filters) {
        showLoading(true);
        fetchDataAndUpdateFilters(
          previousPageURL.filters,
          previousPageURL.Page,
          false
        );
        // console.log("Previous page URL:  filters send ", previousPageURL);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      is_all_filter_data_Mounted = false;
      // On unmount, abort the previous request for genFetchApi
      if (gencontrollerRef.current) {
        gencontrollerRef.current.abort();
      }
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      window.removeEventListener("popstate", handlePopState);

      // clearing interval for live machine data
      clearInterval(intervalId);
      // console.log("unmount");
    };
  }, [get_live_machine_data]);

  // const itemValues = [Data['total_created'], Data['total_resolved']];

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("test_custom_tooltip------------->", payload, active, label);
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "#ffffff",
            // margin: "8px",
            borderRadius: "5px",
            border: "1px solid",
            borderColor: "#e5e6e8",
          }}
        >
          {/* label for tooltip */}
          {label?.length > 1 ? (
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "0.75rem",
                borderBottom: "1px solid",
                borderColor: "#e5e6e8",
                padding: "4px 8px",
              }}
            >
              {label.length > 1 ? label.replace("w-", "Week-") : null}
            </p>
          ) : null}

          <div style={{ padding: "4px 0px" }}>
            <div style={{ padding: "4px 0" }}>
              {payload &&
                payload?.map((entry, index) => {
                  const entryData = entry.payload;
                  const name = entry.name.replace(" ", "_");
                  // console.log(
                  //   "entry--> ",
                  //   entry,
                  //   entryData,
                  //   entryData[name + "_sd"],
                  //   entryData[name + "_OOLScans"]
                  // );
                  return (
                    <div
                      key={`CustomTooltip-item-${index}`}
                      className="d-flex align-items-start gap-2 px-2 py-1"
                    >
                      <div
                        style={{
                          backgroundColor: entry?.color || entry?.payload?.fill,
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          marginTop: "6px",
                        }}
                      ></div>
                      <div className="d-flex flex-column">
                        {/* entry heading */}
                        <div
                          style={{ fontSize: "0.83rem", marginRight: "4px" }}
                        >
                          <span
                            style={{ fontSize: "0.93rem", fontWeight: 700 }}
                          >
                            {Data?.option &&
                              !["lower limit", "upper limit"].includes(
                                entry.name.toLowerCase()
                              ) &&
                              Data?.option[name]?.compare +
                                "-" +
                                Data?.option[name]?.value +
                                " "}
                          </span>
                          {entry.name}
                          {["lower limit", "upper limit"].includes(
                            entry.name.toLowerCase()
                          ) && (
                            <>
                              :{" "}
                              <p
                                style={{
                                  fontWeight: 600,
                                  display: "inline-block",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                {entry.value}
                              </p>
                            </>
                          )}
                        </div>
                        {/* entry data */}
                        {!["lower limit", "upper limit"].includes(
                          entry.name.toLowerCase()
                        ) && (
                          <div style={{ fontSize: "0.73rem", fontWeight: 600 }}>
                            <p
                              style={{
                                fontWeight: 600,
                                display: "inline-block",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              <span
                                style={{ fontWeight: 500, fontSize: "0.83rem" }}
                              >
                                value:{" "}
                              </span>
                              {entry.value}
                            </p>
                            {(entryData[name + "_sd"] ||
                              entryData[name + "_sd"] === 0) && (
                              <p
                                style={{
                                  fontWeight: 600,
                                  display: "inline-block",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "0.83rem",
                                  }}
                                >
                                  , sd:{" "}
                                </span>
                                {entryData[name + "_sd"]}
                              </p>
                            )}
                            {(entryData[name + "_OOLScans"] ||
                              entryData[name + "_OOLScans"] === 0) && (
                              <p
                                style={{
                                  fontWeight: 600,
                                  display: "inline-block",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "0.83rem",
                                  }}
                                >
                                  , OOLS:{" "}
                                </span>
                                {entryData[name + "_OOLScans"]}
                              </p>
                            )}
                            {(entryData[name + "_totalScans"] ||
                              entryData[name + "_totalScans"] === 0) && (
                              <p
                                style={{
                                  fontWeight: 600,
                                  display: "inline-block",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "0.83rem",
                                  }}
                                >
                                  , TS:{" "}
                                </span>
                                {entryData[name + "_totalScans"]}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="px-2">
              <div className="card-Div" style={{ minHeight: "50px" }}>
                <div className="row">
                  <div
                    className="col-3 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Value:
                  </div>
                  <div
                    className="col-9 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem", fontWeight: 600 }}
                  >
                    Average Parameter Value
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-3 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem" }}
                  >
                    sd:
                  </div>
                  <div
                    className="col-9 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem", fontWeight: 600 }}
                  >
                    Standard Daviation
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-3 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem" }}
                  >
                    OOLS:
                  </div>
                  <div
                    className="col-9 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem", fontWeight: 600 }}
                  >
                    Out Of Limit Scans
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-3 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem" }}
                  >
                    TS:
                  </div>
                  <div
                    className="col-9 text-nowrap bar-chart-heading"
                    style={{ fontSize: "0.75rem", fontWeight: 600 }}
                  >
                    Total Scans
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const [opacity, setOpacity] = useState({
    option_1: 1,
    option_2: 1,
    option_3: 1,
    option_4: 1,
    upper_limit: 1,
    lower_limit: 1,
  });

  const handleMouseEnterLegend = (o) => {
    // console.log("Mouse Enter ----->", o);
    const { dataKey } = o;

    setOpacity((prevOpacity) => {
      const updatedOpacity = {};

      for (const key in prevOpacity) {
        if (prevOpacity.hasOwnProperty(key)) {
          updatedOpacity[key] = key !== dataKey ? 0.1 : 1;
        }
      }

      return updatedOpacity;
    });
  };

  const handleMouseLeaveLegend = (o) => {
    // console.log("Mouse Leave ----->", o);
    const { dataKey } = o;
    // setOpacity((prevOpacity) => ({
    //   ...prevOpacity,
    //   [dataKey]: 1,
    // }));

    setOpacity((prevOpacity) => {
      const updatedOpacity = {};

      // Set all opacities to 1
      Object.keys(prevOpacity).forEach((key) => {
        updatedOpacity[key] = 1;
      });

      // Update the specific dataKey to 1
      updatedOpacity[dataKey] = 1;

      return updatedOpacity;
    });
  };
  const customLegend = (props) => {
    const { payload } = props;
    return (
      <div
        className="d-flex justify-content-center gap-3 "
        style={{ cursor: "pointer" }}
      >
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              listStyleType: "none",
              // marginTop: "4px",
              border: "1px solid",
              padding: "3px 10px",
              borderRadius: "10px",
              borderColor: "#e5e6e8",
            }}
            onMouseEnter={() => handleMouseEnterLegend(entry)}
            onMouseLeave={() => handleMouseLeaveLegend(entry)}
          >
            <span
              style={{
                display: "inline-block",
                backgroundColor: entry.color,
                width: "10px",
                height: "10px",
                marginRight: "8px",
                borderRadius: "50%",
              }}
            ></span>
            {(["option_1", "option_2", "option_3", "option_4"].includes(
              entry.dataKey
            ) &&
              Data.option[entry.dataKey].compare +
                "-" +
                Data.option[entry.dataKey].value) ||
              (entry.dataKey === "option_2" && entry.value + " Scans") ||
              entry.value}
          </div>
        ))}
      </div>
    );
  };

  const [selectView, setSelectView] = useState(0);
  const handelSetSelectView = (pageView) => {
    setSelectView(pageView);
  };
  const rowHeight = 64; // Default row height in DataGrid
  const headerHeight = 135; // Default header height in DataGrid
  const maxRows = 5; // Maximum number of rows per page
  const visibleRows = Math.max(Data?.table_data?.rows?.length || 0, maxRows);
  const gridHeight =
    headerHeight +
    rowHeight *
      (visibleRows > 100
        ? 104.08
        : visibleRows > 50
        ? visibleRows + 4.75
        : visibleRows > 20
        ? visibleRows + 2.5
        : visibleRows + 0.5) +
    (visibleRows > 100 ? 52 : 0); // Added 52 for pagination height

  function trimAfterLastDot(input) {
    // Find the position of the last period
    const lastDotIndex = input.lastIndexOf(".");

    // If there is no period in the string, return the original string
    if (lastDotIndex === -1) {
      return input;
    }

    // Return the string up to the last period
    return input.substring(0, lastDotIndex);
  }
  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      headers.push({
        field: "expand",
        headerName: "",
        width: 50,
        editable: false,
        sortable: false, // Disable sorting for this column
        filterable: false, // Disable filtering for this column
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                // width: !index ? 400 : 150, // Adjust width as needed
                //   marginLeft: "5px", // Add margin to separate text from progress bar
                //   fontWeight: "bold", // Customize font weight if needed
                textAlign: "center",
                fontFamily: "Open-Sans-Medium",
                fontWeight: "700",
                fontSize: "1.05rem",
                // color:
                //   (item.field === "status" &&
                //     ((params.value === "rejected" && color.red) ||
                //       (params.value === "accepted" && color.greenToShow))) ||
                //   (params.row["is_rejected"][item.field] && color.red) ||
                //   color.tableRowHeading,
                textWrap: "nowrap",
              }}
            >
              {
                <MuiTooltip
                  title={
                    <>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          fontFamily: "Open-Sans-Medium",
                        }}
                      >
                        More Details
                      </div>
                    </>
                  }
                  arrow
                >
                  <span
                    style={{
                      // border: "2px solid blue",
                      padding: "3px 9px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      textTransform: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // console.log(params.row);
                      if (params.row) {
                        handleTableRowClick(params.row);
                      }
                    }}
                  >
                    <FaPlus />
                  </span>
                </MuiTooltip>
              }
            </Box>
          );
        },
      });
      data.column.forEach((item, index) => {
        if (!["region", "district", "location"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex:
              !isMediumScreen &&
              ![
                "data_pull_id",
                "pulled_by",
                "reason",
                "creation_time",
              ].includes(item.field),
            headerAlign: ![0, 1].includes(index) ? "center" : "left",
            renderHeader: (params) => (
              <MuiTooltip
                title={`${item.field
                  .replace(/_/g, " ")
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}`}
                arrow
              >
                <span
                  style={{
                    paddingLeft: ([0, 1].includes(index) && "10px") || "0px",
                  }}
                >
                  {params.colDef.headerName}
                </span>
              </MuiTooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              return (
                <Box
                  sx={{
                    // width: !index ? 400 : 150, // Adjust width as needed
                    //   marginLeft: "5px", // Add margin to separate text from progress bar
                    //   fontWeight: "bold", // Customize font weight if needed
                    textAlign: ![0, 1].includes(index) ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight:
                      (item.field === "status" && "600") ||
                      (index !== 0 && "500") ||
                      "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color:
                      (item.field === "status" &&
                        ((params.value === "pending" && color.orange) ||
                          (params.value === "ongoing" && color.option_4) ||
                          (params.value === "done" && color.greenToShow))) ||
                      color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <MuiTooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params.field.split("_").join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform:
                                    (item.field === "status" && "capitalize") ||
                                    "none",
                                }}
                              >
                                {(item.field === "creation_time" &&
                                  formatDate(params.value)) ||
                                  (item.field === "last_updated" &&
                                    formatDate(params.value)) ||
                                  params.value}
                              </strong>
                            </div>
                            {item.upper !== undefined && (
                              <p
                                className="m-0 p-0"
                                style={{
                                  fontSize: "0.65rem",
                                }}
                              >
                                Upper Limit : {item.upper}
                              </p>
                            )}
                            {item.lower !== undefined && (
                              <p
                                className="m-0 p-0"
                                style={{
                                  fontSize: "0.65rem",
                                }}
                              >
                                Lower Limit : {item.lower}{" "}
                              </p>
                            )}
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding:
                            (params.value === "pending" && "4px 9px") ||
                            (params.value === "ongoing" && "4px 8px") ||
                            (params.value === "done" && "4px 20px") ||
                            "4px 9px",
                          borderRadius: "4px",
                          backgroundColor:
                            (item.field === "status" &&
                              ((params.value === "pending" &&
                                color.iconBackgroundorange) ||
                                (params.value === "ongoing" &&
                                  color.option_4_bg) ||
                                (params.value === "done" &&
                                  color.iconBackgroundgreen))) ||
                            "transparent",
                          textTransform:
                            (["status", "reason"].includes(item.field) &&
                              "capitalize") ||
                            "none",
                        }}
                      >
                        {(item.field === "creation_time" &&
                          formatDate(params.value, true)) ||
                          (item.field === "last_updated" &&
                            formatDate(params.value, true)) ||
                          params.value}
                      </span>
                    </MuiTooltip>
                  }
                </Box>
              );
            },
          });
        }
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
      //   rows.push({ ...item, scan_id: trimAfterLastDot(item["scan_id"]) });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = (Data?.table_data &&
    Data?.table_data?.rows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: Data?.table_data?.rows || [],
      column: Data?.table_data?.column || [],
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  const [ExportDataList, setExportDataList] = useState(null);

  const onExportLocal = (title) => {
    const wb = XLSX.utils.book_new();
    const headingTop = [
      ["Scan Count ", Data?.count_heading || RDMData?.count_heading || ""],
      Data?.crop || RDMData?.crop
        ? [["Crop", Data?.crop || RDMData?.crop]]
        : [],
      ["Date Range ", Data?.data_range || RDMData?.data_range || ""],
      [],
      [],
    ];

    const heading1 =
      [
        ...Data?.table_data?.column
          ?.map(
            (each_item) =>
              (ExportDataList[each_item.field] && each_item.field) || null
          )
          .filter((item) => item !== null),
      ] || [];

    const headingwb1 = heading1.slice();
    if (ExportDataList["done_percent"]) {
      headingwb1.push("done_percent");
    }
    if (ExportDataList["scanIds_list"]) {
      headingwb1.push("scanIds_list");
    }

    const heading2 =
      [
        ...Data?.table_data?.column
          ?.map((each_item) => each_item.field || null)
          .filter(
            (item) =>
              item !== null &&
              ![
                "scans_count",
                "file_pulled",
                "pending_count",
                "ongoing_count",
                "done_count",
              ].includes(item)
          ),
      ] || [];
    const headingwb2 = heading2.slice();
    headingwb2.splice(0, 0, "scanIds_list");

    const scanIdsDetails = [];

    const headingsData = Data?.table_data?.rows.map((each_row_Data) => {
      const update_arr = [];
      const update_arr_without_ScanId = [];

      for (const i of headingwb1) {
        if (i === "scanIds_list") {
          const scanIds_list_array_to_str = each_row_Data[i].join(", ");
          update_arr.push(scanIds_list_array_to_str);
        } else {
          update_arr.push(each_row_Data[i]);
        }
      }
      // console.log("update_arr -> ", update_arr, update_arr_without_ScanId);
      for (const i of headingwb2) {
        if (i !== "scanIds_list") {
          update_arr_without_ScanId.push(each_row_Data[i]);
        }
      }

      for (const scanId of each_row_Data["scanIds_list"]) {
        scanIdsDetails.push([scanId, ...update_arr_without_ScanId]);
      }
      return update_arr;
    });

    const dataLength = headingwb1.length;
    for (const headingTopDataIndex in headingTop) {
      let hlen = headingTop[headingTopDataIndex].length;
      while (hlen < dataLength) {
        headingTop[headingTopDataIndex].push("");
        hlen++;
      }
    }

    const final_headings = headingwb1.map((item) =>
      item === "username"
        ? formattingString("pull_by")
        : item === "folder_type"
        ? formattingString("pull_folder")
        : formattingString(item)
    );

    const wsData = [...headingTop, final_headings, ...headingsData];
    // console.log("wsData ... ", wsData);
    // Convert the data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Create a new workbook and append the worksheet
    XLSX.utils.book_append_sheet(wb, ws, "Data Pull Ids Details");

    const final_headings_2 = headingwb2.map((item) =>
      item === "username"
        ? formattingString("pull_by")
        : item === "folder_type"
        ? formattingString("pull_folder")
        : formattingString(item)
    );
    const wsData2 = [...headingTop, final_headings_2, ...scanIdsDetails];
    // console.log("wsData2 -> ", wsData2);
    const ws2 = XLSX.utils.aoa_to_sheet(wsData2);
    XLSX.utils.book_append_sheet(wb, ws2, "Scan Ids Details");

    // Generate and download the Excel file
    XLSX.writeFile(
      wb,
      `MattPullScanDetailsExportData(${title})_${new Date()
        .toString()
        .slice(0, 24)
        .replaceAll(" ", "_")}.xlsx`
    );
  };

  const handleExportDataListChange = (value) => {
    // console.log("value : ", value);
    setExportDataList((prevFilter) => {
      const updateFilter = { ...prevFilter };
      updateFilter[value] = !updateFilter[value];
      // console.log("--> ", updateFilter, value);
      return updateFilter;
    });
  };

  useEffect(() => {
    // if (Data) {
    //   console.log("Export Data prefetch : ", Data);
    // }
    const headings =
      Data?.table_data?.column?.map((each_item) => each_item.field) || [];

    if (headings.length !== 0) {
      headings.splice(1, 0, "scanIds_list");
    }
    headings.push("done_percent");
    setExportDataList(() => {
      const updateExportDataList = {};
      for (const i of headings) {
        updateExportDataList[i] = true;
      }
      return updateExportDataList;
    });
  }, [Data]);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleTableRowClick = (rowData) => {
    // console.log("handleRowClick : ", rowData);
    setOpen(true);
    setSelectedRow(rowData);
  };

  const handleTableRowClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  // const issueTyepLogoBGcolor = [ "#6366f1","#f79009", "#10b981", "#f04438"];

  const issueTyepLogoBGcolor = ["#3498db", "#10b981", "#FF5733", "#FF7F50"];
  const icons = [FaCogs, FaBuilding, FaMapMarkedAlt, FaTicketAlt, FaTicketAlt];

  const [SwitchView, setSwitchView] = useState(0);

  const handelSwitchView = (view) => {
    setSwitchView(view);
  };
  return (
    <>
      {(!showError.status && (
        <>
          <Dropdown
            blurTickets={handelPageBlur}
            filters={filters}
            filterCount={filterCount}
            handleFilterChange={handleFilterChange}
            handleSaveChanges={handleSaveChanges}
            handleFilterReset={handleFilterReset}
            getUserFilters={getUserFilters}
            getFilterResponse={getFilterResponse}
            filtersOptions={filtersOptions}
            updateTimestr={"Frequency"}
            startDateStr={"Creation Date"}
            updateDateFilterName={"Creation Date"}
            showResolvedDate={"Updated Date"}
            resolveDateStr={"Updated Date"}
            handelDeleteFilterBtn={handelDeleteFilterBtn}
            handelTempRemoveFilterBtn={handelTempRemoveFilterBtn}
            handelAutoSelectFilterBtn={handelAutoSelectFilterBtn}
            showSortbyDropdown={true}
            showStatus={true}
            searchBoxStr={"Search By TicketID"}
            visitsearchBoxStr={"Search By PullGroupId"}
          />
          {allFiltersDisplay && (
            <AllFilters
              filters={showAllfilters}
              handelResetAllBtn={handelResetAllBtn}
              handelDeleteFilterBtn={handelDeleteFilterBtn}
              getUserFilters={getUserFilters}
              searchBoxStr={"Ticket ID"}
              visitSearchBoxStr={"Group ID"}
            />
          )}

          <div
            className={`${blurTickets} all-ticket-content ${
              allFiltersDisplay ? "m-top-0 p-top-0" : " "
            }`}
          >
            {(loading && (
              // {lodingVisiblity && (
              // <div className="showSpinner all-ticket-spinner">
              //   <BeatLoader color="#2D97D4" />
              // </div>
              <CardPagesSkeleton len={12} cardHeight={230} />
            )) ||
              (Data && !Data.msg && (
                <div className="container row">
                  {/* heading and list details */}
                  <div className="upper-heading-container p-0 px-1 pt-2">
                    {/* Document info */}
                    <div className="info-part">
                      <span className="px-2 mb-1 info-heading">
                        Data Pull Details
                      </span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <i
                          className="bi bi-info-circle icon-color px-0"
                          id="info-icon"
                          style={{ fontSize: "15px", lineHeight: "24px" }}
                        ></i>
                      </OverlayTrigger>
                    </div>
                    {ExportDataList && (
                      <div className="d-flex flex-row ms-auto align-items-center display-inline">
                        {/* Document details */}
                        <div
                          className="show-range ms-auto"
                          style={{ alignSelf: "end" }}
                        >
                          {(selectView === 0 && Data?.count_heading) ||
                            (selectView !== 0 && RDMData?.count_heading)}
                          {/* export details for basic data && more data */}
                        </div>
                        <div
                          className="d-flex flex-row  mx-2 mb-1"
                          style={{
                            borderRadius: "6px",
                            boxShadow:
                              "0px 0px 1px 1px" + color.taxtHeadingLight,
                          }}
                        >
                          <div
                            className="d-flex  align-item-center justifty-content-center"
                            style={{
                              backgroundColor:
                                (SwitchView === 0 && "#2d97d4") || "inherit",
                              borderRadius: "4px",
                              boxShadow:
                                (SwitchView === 0 &&
                                  "0px 0px 1px 1px" + color.taxtHeadingLight) ||
                                "none",
                              cursor: "pointer",
                              transition:
                                "background-color 0.3s ease, box-shadow 0.3s ease",
                              padding: "7px",
                            }}
                            onClick={() => {
                              handelSwitchView(0);
                            }}
                          >
                            {SwitchView === 1 && (
                              <MuiTooltip
                                data-html="true"
                                title={"Card View"}
                                arrow={true}
                              >
                                <img
                                  src={cardViewImg}
                                  alt="card_view"
                                  style={{ height: "15px", width: "15px" }}
                                />
                              </MuiTooltip>
                            )}
                            {SwitchView === 0 && (
                              <MuiTooltip
                                data-html="true"
                                title={"Card View"}
                                arrow={true}
                              >
                                <img
                                  src={cardViewWhiteImg}
                                  alt="card_view"
                                  style={{ height: "15px", width: "15px" }}
                                />
                              </MuiTooltip>
                            )}
                          </div>
                          <div
                            className="d-flex align-item-center justifty-content-center"
                            style={{
                              backgroundColor:
                                (SwitchView === 1 && "#2d97d4") || "inherit",
                              borderRadius: "4px",
                              boxShadow:
                                (SwitchView === 1 &&
                                  "0px 0px 1px 1px" + color.taxtHeadingLight) ||
                                "none",
                              cursor: "pointer",
                              transition:
                                "background-color 0.3s ease, box-shadow 0.3s ease",
                              padding: "7px",
                            }}
                            onClick={() => {
                              handelSwitchView(1);
                            }}
                          >
                            {SwitchView === 1 && (
                              <MuiTooltip
                                data-html="true"
                                title={"Table View"}
                                arrow={true}
                                style={{ height: "10px" }}
                              >
                                <img
                                  src={tableViewWhiteImg}
                                  alt="table_view"
                                  style={{ height: "15px", width: "15px" }}
                                />
                              </MuiTooltip>
                            )}
                            {SwitchView === 0 && (
                              <MuiTooltip
                                data-html="true"
                                title={"Table View"}
                                arrow={true}
                                style={{ height: "10px" }}
                              >
                                <img
                                  src={tableViewImg}
                                  alt="table_view"
                                  style={{ height: "15px", width: "15px" }}
                                />
                              </MuiTooltip>
                            )}
                          </div>
                        </div>
                        {/* Document export data */}
                        <div
                          className="dropdow dropdown_export mx-2 ms-0"
                          style={{ cursor: "pointer" }}
                        >
                          <MuiTooltip
                            data-html="true"
                            title={"Export Data"}
                            arrow={true}
                            style={{ height: "15px", width: "15px" }}
                          >
                            <i
                              className="fa-solid fa-ellipsis-vertical  "
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                padding: "0px 3px",
                                color: color.taxtHeadingLight,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            ></i>
                          </MuiTooltip>
                          {/* Centered dropdown */}
                          {/* </button> */}
                          <ul
                            className="dropdown-menu dropdown-menu-end delete-ticket-btn-border"
                            style={{ padding: "4px 0px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {selectView === 0 && (
                              <li>
                                <div className="row  m-2 mx-3 created-date-heading ">
                                  Select Coloum To Export
                                </div>
                              </li>
                            )}
                            {selectView === 0 &&
                              ExportDataList &&
                              Object.keys(ExportDataList || {}).map(
                                (exportListData, index) => (
                                  <li key={index}>
                                    <div
                                      className="dropdown-item py-0"
                                      href="#"
                                    >
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id={`ExportDataList${index}`}
                                          checked={
                                            ExportDataList[exportListData]
                                          }
                                          onChange={() => {
                                            handleExportDataListChange(
                                              exportListData
                                            );
                                            //applyFilterss();
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`ExportDataList${index}`}
                                        >
                                          {exportListData == "username"
                                            ? formattingString("pull_by")
                                            : exportListData == "folder_type"
                                            ? formattingString("Pull_folder")
                                            : formattingString(exportListData)}
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            <li
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                // onExportLocal(Data?.count_heading || "");
                              }}
                            >
                              <a
                                className="dropdown-item text-center edit-ticket-btn"
                                href="#"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  onExportLocal(Data?.count_heading || "");
                                }}
                              >
                                <span className="export_all_data_btn">
                                  <CiExport className="me-2" />
                                </span>
                                Export Data
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>

                  {open && (
                    <RowDetailsModal1
                      open={open}
                      rowData={selectedRow}
                      onClose={handleTableRowClose}
                      liveMachineData={liveMachinesData}
                    />
                  )}

                  {/* card view  */}
                  {SwitchView === 0 && (
                    <div className="cards-parent">
                      {/* Render ShowTicket component for each ticket */}
                      {Data?.table_data &&
                        Data?.table_data?.rows?.map((groups, index) => {
                          // const timeInfo = getTimeStr(ticket);
                          return (
                            <ShowDataPullCard
                              key={index}
                              groupId={groups?.data_pull_id}
                              ticketId={
                                groups?.ticket_id === "-"
                                  ? null
                                  : groups?.ticket_id
                              }
                              remarks={null}
                              resone={groups?.reason}
                              sub_resone={null}
                              start_date={groups?.creation_time}
                              end_date={null}
                              support_person={[groups?.pulled_by]}
                              distance={null}
                              total_expense={null}
                              attchementsCount={null}
                              lotID_list={groups?.scanIds_list?.map((item) => {
                                return item.split("-").splice(2).join("-");
                              })} // send lotids
                              machines={groups?.machines_list}
                              reloadData={null}
                              handleDeleteModal={null}
                              liveMachinesData={liveMachinesData}
                              dataPullCount={groups?.file_pulled}
                              donePercent={groups?.done_percent}
                              handleTableRowClick={() => {
                                handleTableRowClick(groups);
                              }}
                            />
                          );
                        })}
                    </div>
                  )}

                  {/* Data grid */}
                  {SwitchView === 1 && (
                    <div className={`m-0 p-0 ${selectView !== 0 && "mb-4"}`}>
                      <div className="card-Div">
                        <Box
                          sx={{
                            height:
                              Data?.tickets_limit !== "All"
                                ? "70vh"
                                : "75vh" || gridHeight,
                            width: "100%",
                          }}
                        >
                          <DataGrid
                            rows={TabelDataRows}
                            columns={TableDataHeaders}
                            // pagination={false} // Disable pagination
                            // pagination={100} // Disable pagination
                            // autoPageSize // Automatically adjust page size based on available height
                            density="standard"
                            paginationMode="client"
                            // components={{
                            //   Pagination:
                            //     visibleRows > 100 ? CustomPagination : null,
                            // }}
                            // hideFooterPagination
                            pageSize={101}
                            disableSelectionOnClick
                            disableRowSelectionOnClick
                            onRowClick={(param) => {
                              // console.log("row_param : ", param.row);
                              // if (param.row) {
                              //   handleTableRowClick(param.row);
                              // }
                            }}
                            getRowClassName={(params) => {
                              // console.log(
                              //   "params : ",
                              //   params,
                              //   params.row.id === selectedRow?.id
                              // );
                              return params.row.id === selectedRow?.id
                                ? "selected-row"
                                : "";
                            }}
                            sx={{
                              "&.MuiDataGrid-root": {
                                border: "none",
                                borderRadius: "5px", // Adjust border radius as needed
                                overflow: "hidden", // Ensure content respects the border radius,
                                padding: "0px",
                                margin: "0px",
                                marginTop: "-10px",
                              },
                              ".MuiDataGrid-columnHeader": {
                                backgroundColor: color.textWhiteColor,
                              },
                              ".MuiDataGrid-filler": {
                                backgroundColor: color.textWhiteColor,
                              },
                              ".MuiDataGrid-columnHeaderTitle": {
                                fontFamily: "Open-Sans-Light",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                fontSize: "0.85rem",
                                textTransform: "capitalize",
                                color: color.taxtHeading,
                              },
                              ".MuiDataGrid-columnHeader--alignCenter": {
                                headerAlign: "left",
                              },
                              ".MuiDataGrid-row": {
                                border: "none", // Remove border from rows
                                backgroundColor: color.textWhiteColor, // Set rows background color to white
                                "&:hover": {
                                  backgroundColor: color.primary, // Ensure background color remains white on hover
                                  // cursor: "pointer",
                                },
                              },
                              ".MuiDataGrid-footerContainer": {
                                display:
                                  (visibleRows <= 50 && "none") ||
                                  "inline-block", // Hide the pagination footer
                                justifyContent: "space-between", // Space out selected row count and pagination controls
                                alignItems: "center", // Vertically align items in the footer
                                marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                                height: "30px",
                              },
                              ".MuiTablePagination-root": {
                                // ".MuiInputBase-root": {
                                //   display: "none",
                                // },

                                // ".MuiTablePagination-selectLabel": {
                                //   display: "none",
                                // },
                                ".MuiToolbar-root": {
                                  marginTop: "0px",
                                },
                              },
                              ".MuiDataGrid-cell": {
                                // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                                outline: "none",
                                // display: "-webkit-box",
                                // WebkitBoxOrient: "vertical",
                                // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                "&:focus": {
                                  outline: "none", // Remove the blue outline when a cell is focused
                                },
                              },
                              "&.selected-row": {
                                backgroundColor: "red", // Highlight color for the selected row
                                "&:hover": {
                                  backgroundColor: "red ", // Ensure highlight color remains on hover
                                },
                              },
                              ".MuiDataGrid-selectedRowCount": {
                                display: "none", // Hide the selected row count div
                              },
                              ".MuiTablePagination-toolbar": {
                                // width: "80%",  // Adjust the width here
                                // margin: "0 auto", // Center align the pagination
                                // marginLeft:"auto",
                                padding: "2px 16px", // You can adjust padding as well if needed
                                marginTop: "-15px",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </div>
                  )}
                  {/* react pagination */}
                  {Data?.tickets_limit !== "All" && (
                    <div className="p-1 py-2 m-0">
                      <div className=" pagination-head-container p-0 m-0">
                        <ReactPaginate
                          // pageCount={Math.ceil(totalCardsCount / cardsPerPage)}
                          pageCount={Math.ceil(
                            totalCardsCount[selectView] /
                              cardsPerPage[selectView]
                          )}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={1}
                          onPageChange={(e) => {
                            handelCurrentPage(e.selected);
                          }}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                          renderOnZeroPageCount={null}
                          forcePage={currentPage} // Ensure active page is highlighted
                        />
                      </div>
                    </div>
                  )}
                </div>
                // ) ||
              )) ||
              (Data && Data.msg && (
                <div className="container no-ticket-found-div text-center count_heading_div">
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <div className="upper-heading-container my-2">
                      {/* Document info */}
                      <div className="info-part " style={{ textAlign: "left" }}>
                        <span className="px-2 mb-1 info-heading">
                          Data Pull Details
                        </span>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <i
                            className="bi bi-info-circle icon-color px-0"
                            id="info-icon"
                            style={{ fontSize: "15px", lineHeight: "24px" }}
                          ></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                    <p>{(Data && Data.msg) || "No Data Found"}</p>
                  </div>
                </div>
              ))}
          </div>
        </>
      )) ||
        (showError.status && (
          <Error code={showError.status} msg={showError.msg} />
        )) || <Error code={500} />}
    </>
  );
};

export default ScanidDetailedAnalytics;
