import React, { useContext, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import ToolTip from "react-bootstrap/Tooltip";

import {
  Box,
  ButtonGroup,
  Button,
  Tooltip as MuiTooltip,
  linearProgressClasses,
} from "@mui/material";
import ContextState from "../../contextApi/ContextState";

const SpecificLot = () => {
  const { lotId } = useParams();

  const { productType, product_id } = useParams();
  const {
    color,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
  } = useOutletContext();

  const isOpen = true;
  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    lodingModalVisiblity,
    showModalErrorPage,
    modalControllerRef,
    formatDate,
  } = useContext(ContextState);

  const [specLotDetails, setSpecLotDetails] = useState(null);
  const [specLotAttachments, setSpecLotAttachments] = useState([]);

  const handleSpecLotDetails = (data) => {
    setSpecLotDetails(data);
  };
  const handleLotAttachments = (data) => {
    setSpecLotAttachments(data);
  };

  const navItems = [
    { label: "General Info", path: "" },
    { label: "Attachments", path: "attachments" },
  ];

  // const data = {
  //   // ticketId: "d462b5628",
  //   // ticketId: "1e063283b",
  //   ticketId: "28092349f",
  //   // status: "resolved",
  // };

  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (e, path, targetPath) => {
    if (e.ctrlKey || e.metaKey) {
      // Open in a new tab if Ctrl or Cmd key is pressed
      window.open(targetPath, "_blank");
    } else {
      // Prevent full page reload and navigate within SPA
      e.preventDefault();
      navigate(path);
    }
  };

  useEffect(() => {
    console.log("specLotAttachments : ", specLotAttachments)
  }, [specLotAttachments])
  

  return (
    <>
      {/* Page top details */}
      <div className="container">
        {" "}
        <div className="count_heading_div text-end  pe-0 text-nowrap d-flex ">
          <div className="upper-heading-container p-0 pt-2"></div>
        </div>
        <div className={`row gap-0`}>
          {/* left product  */}

          {/* product info */}

          <div
            className={`${
              (islagelargeScreen && "col-xl-12") || "col-xl-12"
            } mb-3 mt-2`}
          >
            {/* lot details  */}

            <div className="px-1 mb-2">
              <p
                style={{
                  color: color.tableRowHeading,
                  fontSize: (isSmallSmallScreen && "0.92rem") || "1.15rem",
                  marginBottom: "0px",
                  fontWeight: "600",
                }}
              >
                Lot Id #{lotId}
              </p>
              {specLotDetails?.lotDetails && false && (
                <p
                  style={{
                    color: color.taxtHeading,
                    fontSize: (isSmallSmallScreen && "0.82rem") || "0.92rem",
                    marginBottom: "0px",
                  }}
                >
                  {formatDate(specLotDetails?.lotDetails?.arrival_date).slice(
                    0,
                    16
                  )}
                </p>
              )}
            </div>

            {/* seperator line */}
            <div className="barChartDivDescHorizontalSeperator w-100 mb-2"></div>
            {/* top divs general info / Attachments / TimeLine / Comments main components */}
            <div
              className="bar-chart-heading pt-2 px-1 mb-3"
              style={{ color: color.taxtHeading }}
            >
              <Box
                className="style-scrollbar"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  overflow: "auto",
                }}
              >
                <ButtonGroup
                  variant="outlined"
                  aria-label="Basic button group"
                  sx={{
                    display: "flex",
                    gap: "8px",
                    "& .MuiButton-outlined": {
                      border: "none",
                      fontFamily: "Open-Sans-Medium",
                      borderRadius: "5px",
                      overflow: "visible",
                      "&:hover": {
                        color: color.secondary,
                        borderColor: color.secondary,
                        backgroundColor: color.iconBackgroundBlue,
                      },
                    },
                  }}
                >
                  {navItems.map((item) => {
                    const isActive =
                      item.path === ""
                        ? !["attachments"].includes(
                            location.pathname.split("/").slice(-1)[0]
                          )
                        : location.pathname.split("/").slice(-1)[0] ===
                          item.path;
                    const targetPath = `${location.pathname
                      .split("/")
                      .slice(0, 4)
                      .join("/")}/${item.path}`; // Define full path for link preview

                    return (
                      <Button
                        key={item.path}
                        href={targetPath} // Set href for link preview on hover
                        onClick={(e) => {
                          handleClick(e, item.path, targetPath);
                        }}
                        sx={{
                          backgroundColor: isActive
                            ? color.secondary
                            : color.primary,
                          color: isActive ? color.primary : "inherit",
                          fontSize: "0.85rem",
                          padding: "5px 10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            border: "none",
                            backgroundColor: isActive
                              ? "darkred"
                              : color.iconBackgroundBlue,
                          },
                        }}
                      >
                        {item.label}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Box>
            </div>
            {/* general info / Attachments / TimeLine / Comments main components */}

            <div
              className=""
              style={{ minHeight: "70vh", position: "relative", width: "100%" }}
            >
              <Outlet
                context={{
                  color: color,
                  specLotDetails: specLotDetails,
                  handleSpecLotDetails: handleSpecLotDetails,
                  specLotAttachments: specLotAttachments,
                  handleLotAttachments: handleLotAttachments,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificLot;
