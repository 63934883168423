import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import {
  Button,
  CircularProgress,
  FormLabel,
  Modal,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import close from "../../../assets/images/close.svg";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { formattingString } from "../../analytics/DataPull";
import ContextState from "../../contextApi/ContextState";
import { Description } from "@mui/icons-material";
import "../../../assets/styles/AddLotDetailsForm.css";
import { HiUserAdd } from "react-icons/hi";
import dayjs from "dayjs";
import { color } from "../../modals/Calllog";

// displaying the seller details
export const DisplaySellerDetails = (props) => {
  const { isSmallScreen, isAcknowledgment, genFields, options } = props;
  const { formatInIndianRupees } = useContext(ContextState);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");

  const sellerID = parseInt(genFields?.sellerDetails?.id, 10) || 0;

  let sellerDetails =
    options && options.length !== 0
      ? options.find((item) => sellerID === item.id)
      : genFields["sellerDetails"];


  return (
    <>
      {!isAcknowledgment && (
        <div className="d-flex flex-column gap-1 ">
          {/* <div>Seller Details</div> */}
          <div
            className="pt-1"
            style={{
              display: "grid",
              gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr", // Three columns
              gridRowGap: "15px",
            }}
          >
            {[
              "name",
              "email",
              "contact_number",
              "contact_person",
              "address",
            ].map((showValues, index) => (
              <p
                key={`${showValues}-${index}`}
                className="m-0"
                style={{
                  gridColumn:
                    showValues === "address" && !isSmallScreen ? "3" : "",
                  gridRowStart:
                    showValues === "address" && !isSmallScreen ? "1" : "",
                  gridRowEnd:
                    showValues === "address" && !isSmallScreen ? "span 2" : "",
                }}
              >
                <label
                  htmlFor={`outlined-read-only-input-${showValues}`}
                  className="basic_details_elements_label_data_table"
                  style={{
                    textTransform: "capitalize",
                    fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                  }}
                >
                  {formattingString(showValues)}
                </label>
                <div
                  id={`outlined-read-only-input-${showValues}`}
                  className="basic_details_elements_data_content"
                  style={{
                    fontSize: isSmallScreen ? "0.95rem" : "1rem",
                  }}
                >
                  {sellerDetails && sellerDetails[showValues]
                    ? sellerDetails[showValues]
                    : genFields[showValues]}
                </div>
              </p>
            ))}
          </div>
        </div>
      )}

      {isAcknowledgment && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMediumScreen
              ? isSmallScreen
                ? "1fr"
                : "1fr 1fr 1fr"
              : "repeat(4,1fr)",
            gridRowGap: "20px",
          }}
        >
          {/* {["invoiceNumber", "arrivalDate", "productCount", "totalAmount"].map( */}
          {["invoiceNumber", "arrivalDate", "totalAmount", "productCount"].map(
            (showValues, index) => (
              <p key={`${showValues}-${index}`} className="m-0 pe-2">
                <label
                  htmlFor={`outlined-read-only-input-${showValues}`}
                  className="basic_details_elements_label_data_table"
                  style={{
                    textTransform: "capitalize",
                    fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                  }}
                >
                  {formattingString(showValues)}
                </label>
                <div
                  id={`outlined-read-only-input-${showValues}`}
                  className="basic_details_elements_data_content text-break"
                  style={{
                    fontSize: isSmallScreen ? "0.95rem" : "1rem",
                  }}
                >
                  {showValues === "arrivalDate"
                    ? dayjs(genFields.sellerDetails[showValues]).format(
                        "DD-MM-YYYY"
                      )
                    : ["productCount", "totalAmount"].includes(showValues)
                    ? formatInIndianRupees(genFields.sellerDetails[showValues])
                    : genFields.sellerDetails[showValues]}
                </div>
              </p>
            )
          )}
        </div>
      )}
    </>
  );
};

function GeneralDetails({
  genFields,
  setGenFields,
  fieldError,
  isSmallScreen,
  isMediumScreen,
  islageScreen,
  FiltersData
}) {
  const { formatInIndianRupees, fetchApi, openSnackbar } =
    useContext(ContextState);
  const theme = useTheme();
  const [addNewSellerDetails, setAddNewSellerDetails] = useState({
    isOpen: false,
    sellerName: "",
    contactPerson: "",
    contactNumber: "",
    emailId: "",
    address: "",
  });

  // The filter function for options (based on MUI's default filter function)
  const filter = (options, { inputValue }) => {
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  function handleCloseAddNewSellerModal(e, reason) {
    if (reason && reason === "backdropClick") {
      return;
    }

    setAddNewSellerDetails((prev) => ({ ...prev, isOpen: false }));
  }

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleOpen = async () => {
    setOpen(true);
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   setOptions(getSellerDetails);
    // }, 3000);

    try {
      const payload = {
        sellers: true,
      };

      // Send the payload to the backend route
      const response = await fetchApi("get_lot_helper", "POST", payload, true);
      if (response.statusCode === 200) {
        // console.log("seller_details fetched succesfully", response);
        setOptions(response.data.seller_details || []);
        setLoading(false);
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error("Error editing ticket information:", error);
      // openSnackbar("some error occured fetching Sellers", "error");
    } finally {
      // setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    // setOptions([]);
  };

  useEffect(() => {
    setOptions(FiltersData.seller_details);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: genFields.sellerDetails.name !== "" ? "0px" : "20px",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* invoice number && arrival date && Product Count && Total Cost */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isSmallScreen
              ? "repeat(1, 1fr)"
              : "repeat(2, 1fr)",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "25px",
            gap: 25,
          }}
        >
          {/* invoice number */}
          <FormControl fullWidth error={fieldError.invoiceNumberError}>
            <TextField
              value={genFields.sellerDetails.invoiceNumber}
              fullWidth
              id="outlined-basic"
              label="Invoice Number"
              variant="outlined"
              onChange={(e) => {
                setGenFields((prev) => {
                  return {
                    ...prev,
                    sellerDetails: {
                      ...prev.sellerDetails,
                      invoiceNumber: e.target.value,
                    },
                  };
                });
              }}
            />
            {fieldError.invoiceNumberError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please enter invoice number.
              </div>
            ) : (
              <></>
            )}
          </FormControl>
          {/* arrival Date */}
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Arrival Date"
                format="DD/MM/YYYY"
                maxDate={dayjs()} // Restrict to today's date
                value={
                  genFields.sellerDetails.arrivalDate === ""
                    ? null
                    : genFields.sellerDetails.arrivalDate
                }
                onChange={(date) => {
                  setGenFields((prev) => ({
                    ...prev,
                    sellerDetails: {
                      ...prev.sellerDetails,
                      arrivalDate: date,
                    },
                  }));
                }}
              />
            </LocalizationProvider>

            {fieldError.arrivalDateError && (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select arrival date.
              </div>
            )}
          </FormControl>

          {/* Product Count */}
          {false && (
            <FormControl fullWidth error={fieldError.productCountError}>
              <TextField
                fullWidth
                value={genFields?.sellerDetails?.productCount}
                type="number"
                id="outlined-basic"
                label="Product Count"
                variant="outlined"
                onChange={(e) => {
                  setGenFields((prev) => {
                    return {
                      ...prev,
                      sellerDetails: {
                        ...prev.sellerDetails,
                        productCount: e.target.value,
                      },
                    };
                  });
                }}
              />
              {fieldError.productCountError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter product count.
                </div>
              ) : (
                <></>
              )}
            </FormControl>
          )}

          {/* seller name*/}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              width: "100%",
              alignItems: "start",
            }}
          >
            <FormControl fullWidth error={fieldError.sellerNameError}>
              <Autocomplete
                value={genFields?.sellerDetails?.name || null}
                onChange={(event, newValue) => {
                  // console.log("--> ", event, newValue);
                  if (newValue) {
                    setGenFields((prev) => {
                      return {
                        ...prev,
                        sellerDetails: {
                          ...prev.sellerDetails,
                          name: newValue.name,
                          email: newValue.email,
                          contact_number: newValue.contact_number,
                          contact_person: newValue.contact_person,
                          address: newValue.address,
                          id: newValue.id,
                        },
                      };
                    });
                  } else {
                    setGenFields((prev) => {
                      return {
                        ...prev,
                        sellerDetails: {
                          ...prev.sellerDetails,
                          id: "",
                          name: "",
                          email: "",
                          contact_number: "",
                          contact_person: "",
                          address: "",
                        },
                      };
                    });
                  }
                }}
                // filterOptions={(options, params) => {
                //   const filtered = filter(options, params);
                //   const { inputValue } = params;

                //   // Suggest the creation of a new value
                //   const isExisting = options.some(
                //     (option) => inputValue === option
                //   );
                //   if (inputValue !== "" && !isExisting) {
                //     filtered.push(`Add "${inputValue}"`);
                //   }

                //   return filtered;
                // }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="Choose Seller"
                // options={
                //   getSellerDetails.map((items) => items.sellerName) || []
                // }
                options={options}
                loading={loading}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                isOptionEqualToValue={(option, value) => option.name === value}
                // getOptionLabel={(option) => {
                //   // Regular option
                //   if (typeof option === "string") {
                //     return option;
                //   }
                //   return option.toString();
                // }}
                getOptionLabel={(option) =>
                  option.name ? option.name : option
                }
                // renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ width: "100%" }}
                // freeSolo
                // renderInput={(params) => (
                //   <TextField {...params} label="Seller" />
                // )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seller"
                    // slotProps={{
                    //   textField: {
                    //     ...params,
                    //     InputProps: {
                    //       ...params.InputProps,
                    //       endAdornment: (
                    //         <React.Fragment>
                    //           {/* {loading ? ( */}
                    //             <CircularProgress
                    //               style={{
                    //                 height: "16px",
                    //                 width: "16px",
                    //                 color: color.secondary,
                    //                 zIndex: 1000,
                    //               }}
                    //               thickness={4}
                    //             />
                    //           {/* ) : null} */}
                    //           {params.InputProps.endAdornment}
                    //         </React.Fragment>
                    //       ),
                    //     },
                    //   },
                    // }}
                  />
                )}
              />

              {fieldError.sellerNameError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please select seller
                </div>
              ) : (
                <></>
              )}
            </FormControl>

            <Tooltip title="Add new seller details.">
              {false && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: color.lotBtncolor,
                    borderRadius: "8px",
                    height: "45px",
                    // width: "40px",
                    margin: "8px 0px",
                  }}
                  size="small"
                  onClick={() => {
                    setAddNewSellerDetails((prev) => ({
                      ...prev,
                      isOpen: true,
                    }));
                  }}
                >
                  <HiUserAdd
                    style={{
                      height: "30px",
                      width: "30px",
                      // color: "#784af4"
                      // color: "#1976d2",
                    }}
                  />
                </Button>
              )}

              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setAddNewSellerDetails((prev) => ({ ...prev, isOpen: true }));
                }}
                className="d-flex  align-items-center justify-content-center"
                style={{
                  // backgroundColor: color.lotBtncolor,
                  // border: `1px solid ${color.lotBtncolor}`,
                  borderColor: color.lotBtncolor,
                  borderRadius: "8px",
                  height: isSmallScreen ? "38px" : "40px",
                  // width: "40px",
                  margin: "5px 0px",
                }}
              >
                <HiUserAdd
                  fontSize="small"
                  style={{
                    // color: color.textWhiteColor,
                    color: color.lotBtncolor,
                    height: "auto",
                    width: "30px",
                    // color: "#784af4"
                    // color: "#1976d2",
                  }}
                />
              </Button>
            </Tooltip>
          </div>
          {false && (
            <div>
              <CircularProgress
                style={{
                  height: "16px",
                  width: "16px",
                  color: color.secondary,
                  zIndex: 1000,
                }}
                thickness={4}
              />
            </div>
          )}
          {false && (
            <Autocomplete
              sx={{ width: 300 }}
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              isOptionEqualToValue={(option, value) =>
                option.title === value.title
              }
              getOptionLabel={(option) => option.title}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seller"
                  // slotProps={{
                  //   textField: {
                  //     ...params,
                  //     InputProps: {
                  //       ...params.InputProps,
                  //       endAdornment: (
                  //         <React.Fragment>
                  //           {/* {loading ? ( */}
                  //             <CircularProgress
                  //               style={{
                  //                 height: "16px",
                  //                 width: "16px",
                  //                 color: color.secondary,
                  //                 zIndex: 1000,
                  //               }}
                  //               thickness={4}
                  //             />
                  //           {/* ) : null} */}
                  //           {params.InputProps.endAdornment}
                  //         </React.Fragment>
                  //       ),
                  //     },
                  //   },
                  // }}
                />
              )}
            />
          )}

          {/* Total Cost */}
          <FormControl fullWidth error={fieldError.totalAmountError}>
            <TextField
              value={genFields?.sellerDetails.totalAmount}
              fullWidth
              type="number"
              id="outlined-basic"
              label="Total Cost"
              variant="outlined"
              onChange={(e) => {
                setGenFields((prev) => {
                  return {
                    ...prev,
                    sellerDetails: {
                      ...prev.sellerDetails,
                      totalAmount: e.target.value,
                    },
                  };
                });
              }}
            />
            {fieldError.totalAmountError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please enter cost.
              </div>
            ) : (
              <></>
            )}
          </FormControl>
        </div>

        {genFields.sellerDetails.name !== "" ? (
          <div className="my-4 mb-0 w-100">
            <div className="card-Div p-3 ">
              <div
                className="bar-chart-heading  "
                style={{
                  color: color.taxtHeading,
                  lineHeight: "1.2",
                  fontSize: "1rem",
                }}
              >
                Seller Details
              </div>
              <div className="px-1 my-1">
                <DisplaySellerDetails
                  isSmallScreen={isSmallScreen}
                  genFields={genFields}
                  options={options}
                  isMediumScreen={isMediumScreen}
                  islageScreen={islageScreen}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {addNewSellerDetails.isOpen && (
        <AddNewSellerDetails
          addNewSellerDetails={addNewSellerDetails}
          setAddNewSellerDetails={setAddNewSellerDetails}
          handleClose={handleCloseAddNewSellerModal}
          genFields={genFields}
          setGenFields={setGenFields}
          isSmallScreen={isSmallScreen}
          fetchApi={fetchApi}
          openSnackbar={openSnackbar}
        />
      )}
    </div>
  );
}

export default GeneralDetails;

const AddNewSellerDetails = (props) => {
  const {
    addNewSellerDetails,
    handleClose,
    genFields,
    setGenFields,
    isSmallScreen,
    setAddNewSellerDetails,
    fetchApi,
    openSnackbar,
  } = props;

  const [addNewSellerDetailsErrors, setAddNewSellerDetailsErrors] = useState({
    sellerNameError: false,
    contactPersonError: false,
    contactNumberError: false,
    emailIdError: false,
    addressError: false,
  });

  useEffect(() => {
    // Check for each field and update the corresponding error state
    if (addNewSellerDetails.sellerName !== "") {
      setAddNewSellerDetailsErrors((prev) => ({
        ...prev,
        sellerNameError: false,
      }));
    }

    if (addNewSellerDetails.contactPerson !== "") {
      setAddNewSellerDetailsErrors((prev) => ({
        ...prev,
        contactPersonError: false,
      }));
    }

    if (addNewSellerDetails.contactNumber !== "") {
      setAddNewSellerDetailsErrors((prev) => ({
        ...prev,
        contactNumberError: false,
      }));
    }

    if (addNewSellerDetails.emailId !== "") {
      setAddNewSellerDetailsErrors((prev) => ({
        ...prev,
        emailIdError: false,
      }));
    }

    if (addNewSellerDetails.address !== "") {
      setAddNewSellerDetailsErrors((prev) => ({
        ...prev,
        addressError: false,
      }));
    }
  }, [addNewSellerDetails]);

  const [loading, setLoading] = useState(false);
  const addNewSeller = async () => {
    // console.log("test clicked");
    let sError = false;
    let cpError = false;
    let cnError = false;
    let eError = false;
    let adError = false;

    if (addNewSellerDetails.sellerName === "") sError = true;
    if (addNewSellerDetails.contactPerson === "") cpError = true;
    if (addNewSellerDetails.contactNumber === "") cnError = true;
    if (addNewSellerDetails.emailId === "") eError = true;
    if (addNewSellerDetails.address === "") adError = true;

    setAddNewSellerDetailsErrors((prev) => ({
      sellerNameError: sError,
      contactPersonError: cpError,
      contactNumberError: cnError,
      emailIdError: eError,
      addressError: adError,
    }));
    if (sError || cpError || cnError || eError || adError) {
      return;
    } else {
      // console.log("addNewSellerDetails : ", addNewSellerDetails);
      setLoading(true);
      try {
        const payload = {
          name: addNewSellerDetails.sellerName,
          email: addNewSellerDetails.emailId,
          contact_number: addNewSellerDetails.contactNumber,
          contact_person: addNewSellerDetails.contactPerson,
          address: addNewSellerDetails.address,
        };

        // Send the payload to the backend route
        const response = await fetchApi(
          "add_seller_details",
          "POST",
          payload,
          true
        );
        if (response.statusCode === 200) {
          // console.log("comments added succesfully", response);
          // setLoading(false);
          setAddNewSellerDetails((prevData) => ({
            ...prevData,
            sellerName: "",
            contactPerson: "",
            contactNumber: "",
            emailId: "",
            address: "",
          }));
          openSnackbar(
            response.data.message || "Sellers Details Added Sussessfully",
            "success"
          );
        } else {
          // console.error("Error editing ticket information:", response.statusCode);
          openSnackbar(response.msg, "error");
        }
      } catch (error) {
        console.error("Error editing ticket information:", error);
        openSnackbar(
          "Some error occured while saving Sellers Details",
          "error"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={addNewSellerDetails.isOpen}
      style={{
        position: "fixed",
        overflowY: "scroll",
        height: "100%",
        margin: "0px 0px 0px 10px",
      }}
    >
      <Box
        style={{
          position: "relative",
          zIndex: "1111",
          right: "0",
          top: "16%",
          left: "0",
          margin: "auto",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          maxWidth: "1300px",
          height: "auto",
        }}
      >
        {/* Modal Header starts*/}
        <div
          style={{
            padding: "20px",
            borderBottom: "1px solid #f5f5f5",
          }}
        >
          <div className="d-flex justify-content-between">
            <h5 className="m-0">Add New Seller</h5>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {/* Modal Header ends*/}

        {/* Modal body starts */}
        <Box className="p-4 pt-0">
          <div
            style={{
              display: "grid",
              width: "100%",
              marginTop: "25px",
              gap: 25,
            }}
          >
            {/* seller name */}
            <FormControl
              error={addNewSellerDetailsErrors.sellerNameError}
              style={{
                gridColumn: isSmallScreen ? "span 2" : "span 1",
              }}
            >
              <TextField
                value={addNewSellerDetails.sellerName}
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                onChange={(e) => {
                  setAddNewSellerDetails((prev) => ({
                    ...prev,
                    sellerName: e.target.value,
                  }));
                }}
              />
              {addNewSellerDetailsErrors.sellerNameError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter seller name.
                </div>
              ) : (
                <></>
              )}
            </FormControl>
            {/* sellers email */}
            <FormControl
              error={addNewSellerDetailsErrors.emailIdError}
              style={{
                gridColumn: isSmallScreen ? "span 2" : "span 1", // Full width on larger screens
              }}
            >
              <TextField
                value={addNewSellerDetails.emailId}
                fullWidth
                type="email"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={(e) => {
                  setAddNewSellerDetails((prev) => ({
                    ...prev,
                    emailId: e.target.value,
                  }));
                }}
              />
              {addNewSellerDetailsErrors.emailIdError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter sellers email id.
                </div>
              ) : (
                <></>
              )}
            </FormControl>
            {/* contact Person */}
            <FormControl
              error={addNewSellerDetailsErrors.contactPersonError}
              style={{
                gridColumn: isSmallScreen ? "span 2" : "span 1",
              }}
            >
              <TextField
                value={addNewSellerDetails.contactPerson}
                fullWidth
                id="outlined-basic"
                label="Contact Person"
                variant="outlined"
                onChange={(e) => {
                  setAddNewSellerDetails((prev) => ({
                    ...prev,
                    contactPerson: e.target.value,
                  }));
                }}
              />
              {addNewSellerDetailsErrors.contactPersonError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter contact person.
                </div>
              ) : (
                <></>
              )}
            </FormControl>
            {/* contact number */}
            <FormControl
              error={addNewSellerDetailsErrors.contactNumberError}
              style={{
                gridColumn: isSmallScreen ? "span 2" : "span 1",
              }}
            >
              <TextField
                value={addNewSellerDetails.contactNumber}
                fullWidth
                type="number"
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                onChange={(e) => {
                  setAddNewSellerDetails((prev) => ({
                    ...prev,
                    contactNumber: e.target.value,
                  }));
                }}
              />
              {addNewSellerDetailsErrors.contactNumberError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter contact number.
                </div>
              ) : (
                <></>
              )}
            </FormControl>
            {/* address */}
            <FormControl
              error={addNewSellerDetailsErrors.addressError}
              style={{
                width: "100%",
                gridColumn: "span 2",
              }}
            >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Address"
                multiline
                rows={4}
                value={addNewSellerDetails.address}
                onChange={(e) => {
                  setAddNewSellerDetails((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }));
                }}
              />
              {addNewSellerDetailsErrors.addressError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter Sellers Address.
                </div>
              ) : (
                <></>
              )}
            </FormControl>
          </div>
          {/* modal footer starts */}
          <div className="d-flex justify-content-end mt-4">
            {" "}
            <LoadingButton
              style={{
                backgroundColor: (loading && "inherit") || color.lotBtncolor,
              }}
              size="medium"
              onClick={() => {
                addNewSeller();
              }}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              add
            </LoadingButton>
          </div>
          {/* modal footer ends */}{" "}
        </Box>
      </Box>
    </Modal>
  );
};
