import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/EachVisit.css";
import { useOutletContext } from "react-router-dom";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import SendIcon from "@mui/icons-material/Send";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  getBadgeClass,
  formatVisitCompletionMessage,
  isValidEmail,
  get_support_persons_list,
  get_parts_replaced_list,
  TableSkeleton,
  OverviewPageSkeleton,
} from "../Exporters/ExportFunctions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import close from "../../assets/images/close.svg";
import ContextState from "../contextApi/ContextState";
import Error from "../error/Error";
import { BeatLoader } from "react-spinners";
import OTPInput from "react-otp-input";
import { DataGrid } from "@mui/x-data-grid";
import { formatDateString } from "../modals/VisitDetails";

const style1 = {
  position: "relative",
  zIndex: "90",
  right: "0",
  top: "15%",
  left: "0",
  margin: "  auto",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  overflowY: "auto",
  width: "85vw",
  maxWidth: "1250px",
};
const style2 = {
  position: "relative",
  zIndex: "90",
  right: "0",
  top: "20%",
  left: "0",
  margin: "  auto",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  overflowY: "auto",
  width: "85vw",
  maxWidth: "1250px",
};

const EachVisitOverview = () => {
  const {
    color,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
    visitId,
    pagesData,
    handlePagesData,
    backendFilterData,
    addVisitFiltersData,
  } = useOutletContext();
  const {
    fetchApi,
    openSnackbar,
    showError,
    showErrorPage,
    formatDate,
    controllerRef,
  } = useContext(ContextState);

  const style3 = {
    position: "relative",
    zIndex: "90",
    right: "0",
    top: "15%",
    left: "0",
    margin: "  auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    overflowY: "auto",
    width: isSmallScreen ? "90vw" : "85vw",
    maxWidth: "1250px",
  };

  const style4 = {
    position: "relative",
    zIndex: "90",
    right: "0",
    top: "15%",
    left: "0",
    margin: "  auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    overflowY: "auto",
    width: isSmallScreen ? "90vw" : "85vw",
    maxWidth: "1250px",
  };

  const style5 = {
    position: "relative",
    zIndex: "90",
    right: "0",
    top: "25%",
    left: "0",
    margin: "  auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    overflowY: "auto",
    width: isSmallScreen ? "90vw" : "380px",
    maxWidth: "1250px",
  };

  // Modal part
  const [modalOpen, setModalOpen] = useState({
    reschedule: false,
    addVisitDetail: false,
    validate: false,
    status: false,
    addSerial: false,
    editLocation: false,
  });

  // loading variable to maintain loading button
  const [loading, setLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);

  // Temp state to hold the current entry
  const [tempSerialData, setTempSerialData] = useState({
    hardware: "",
    old_serial: "",
    new_serial: "",
  });

  // options of designation
  const options = ["TA", "MQC", "AGMQC"];

  // handle checkbox
  const handleCheckBox = (checked) => {
    setStatus((prev) => ({
      ...prev,
      isCheckBox: checked,
    }));
  };

  // this is for loading the page
  const [loadingVisiblity, setLoadingVisiblity] = useState(false);

  const filter = createFilterOptions();

  // variable to check validate modal
  const [validate, setValidate] = useState({
    email: "",
    name: "",
    designation: "",
    otp: "",
    showOtp: false,
  });

  // state variables to maintain data
  const [visitDetailsFormData, setVisitDetailsFormData] = useState({
    parts_replaced: [],
    start_date: null,
    end_date: null,
    total_expenses: "",
    distance: "",
    remarks: "",
  });

  const [serialData, setSerialData] = useState({
    product_serial_data: [],
  });

  const [status, setStatus] = useState({
    status: "",
    parts_carried_checklist: [],
    isCheckBox: false,
  });

  // state varible to get list of check points from remote validation side
  const [selectedCheckpoints, setSelectedCheckpoints] = useState({
    support: [],
    visit: [],
  });

  // this is a state variable to take input from form
  const [scheduleDetail, setScheduleDetail] = useState({
    machine: "",
    date: "",
    assignee: [],
    description: "",
    person: "",
    contact: "",
    address: "",
    // emailSwitch: false,
    // email: "",
  });

  const handleOpen = (name, editData = {}) => {
    setModalOpen((prev) => ({
      ...prev,
      [name]: true,
    }));
    if (Object.keys(editData).length > 0) {
      if (name === "status") {
        setStatus((prev) => ({
          ...prev,
          [name]: editData["status"],
        }));
      } else if (name === "addVisitDetail") {
        setVisitDetailsFormData((prev) => ({
          ...prev,
          parts_replaced: editData["Parts Replaced"]
            ? [...prev.parts_replaced, ...editData["Parts Replaced"]]
            : prev.parts_replaced,
          // start_date: dayjs(formatDateString(editData["Start Date"])),
          // end_date: dayjs(formatDateString(editData["End Date"])),
          distance: editData["Distance"],
          total_expenses: editData["Total Expense"],
          remarks: editData["Remarks"],
          remarks_tags: Object.keys(editData["Remarks Tags"]),
        }));
      } else if (name === "reschedule") {
        setScheduleDetail((prev) => {
          return {
            ...prev,
            machine: editData["Machine"],
            date: dayjs(formatDateString(editData["Scheduled Date"])),
            assignee: editData["Visit Person"],
            description: editData["Description"],
          };
        });
      } else if (name === "editLocation") {
        setScheduleDetail((prev) => {
          return {
            ...prev,
            person: editData["Contact Person"],
            contact: editData["Contact Number"],
            address: editData["Location Address"],
          };
        });
      }
    }
  };

  const handleClose = (name) => {
    setModalOpen((prev) => ({
      ...prev,
      [name]: false,
    }));

    setVisitDetailsFormData((prev) => ({
      ...prev,
      parts_replaced: [],
      start_date: null,
      end_date: null,
      remarks_tags: [],
      total_expenses: "",
      distance: "",
      remarks: "",
    }));

    setSerialData((prev) => ({
      ...prev,
      product_serial_data: [],
    }));

    setTempSerialData((prev) => ({
      ...prev,
      hardware: "",
      old_serial: "",
      new_serial: "",
    }));

    setStatus((prev) => ({
      ...prev,
      status: "",
      parts_carried_checklist: [],
      isCheckBox: false,
    }));

    setScheduleDetail((prev) => ({
      ...prev,
      machine: "",
      date: "",
      assignee: [],
      description: "",
      person: "",
      contact: "",
      address: "",
      // emailSwitch: false,
      // email: "",
    }));

    setValidate((prev) => ({
      ...prev,
      email: "",
      name: "",
      designation: "",
      otp: "",
      showOtp: false,
    }));

    setFieldError((prev) => ({
      ...prev,
      parts_replacedError: false,
      start_dateError: false,
      end_dateError: false,
      total_expensesError: false,
      distanceError: false,
      remarks_tagsError: false,
      remarksError: false,
      machineError: false,
      dateError: false,
      assigneeError: false,
      descriptionError: false,
      personError: false,
      contactError: false,
      addressError: false,
      emailError: false,
      validatePersonError: false,
      otpError: false,
    }));
  };

  const columns1 = [
    {
      field: "sno",
      headerName: "S. No",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    { field: "checkpoint", headerName: "Checkpoint", flex: 1 },
    // { field: "description", headerName: "Description", flex: 1 },
  ];
  const columns2 = [
    {
      field: "sno",
      headerName: "S. No",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    { field: "checkpoint", headerName: "Checkpoint", flex: 1 },
    // { field: "description", headerName: "Description", flex: 1 },
  ];

  const handleCheckboxChange = (ids, checklistType) => {
    setSelectedCheckpoints((prev) => ({
      ...prev,
      [checklistType]: ids,
    }));
  };
  // const handleCheckboxChange = (ids, checklistType) => {
  //   setSelectedCheckpoints((prev) => ({
  //     ...prev,
  //     [checklistType]: ids,
  //   }));
  // };

  // console.log(
  //   "support:",
  //   selectedCheckpoints?.support,
  //   "visit:",
  //   selectedCheckpoints?.visit
  // );

  //// to show the error if not filled /////
  const [fieldError, setFieldError] = useState({
    parts_replacedError: false,
    start_dateError: false,
    end_dateError: false,
    total_expensesError: false,
    distanceError: false,
    remarks_tagsError: false,
    remarksError: false,
    machineError: false,
    dateError: false,
    assigneeError: false,
    descriptionError: false,
    personError: false,
    contactError: false,
    addressError: false,
    emailError: false,
    validatePersonError: false,
    otpError: false,
  });

  // Handle input change for temp state
  const handleTempInputChange = (e) => {
    const { name, value } = e.target;
    setTempSerialData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // add serial data to db
  const handleAddSerialData = async () => {
    try {
      setLoading(true);

      const transformProductSerialData = () => {
        const hardwareMapping = backendFilterData?.hardware_list || {};

        const transformedData = serialData?.product_serial_data?.map((item) => {
          const hardwareId = Object.keys(hardwareMapping).find(
            (key) => hardwareMapping[key] === item.hardware
          );

          return {
            id: hardwareId || null,
            old: item.old_serial,
            new: item.new_serial,
          };
        });

        return transformedData;
      };

      let payload = {
        visit_id: visitId,
        serialData: transformProductSerialData(),
      };

      // console.log("payload:", payload);

      const response = await fetchApi("add_serial_data", "POST", payload, true);

      if (response.statusCode === 200 && response && response.data) {
        setExpanded(false);
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        handleClose("addSerial");
        openSnackbar(response?.data?.message, "success");
        handleReloadData();
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch (error) {
      if (controllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  // delete each serial data
  const deleteSerialData = async (serial_key) => {
    try {
      let payload = {
        visit_id: visitId,
        serial_key: serial_key,
      };

      // console.log("payload:", payload);

      const response = await fetchApi(
        "delete_serial_key",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200 && response && response.data) {
        setExpanded(false);
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        openSnackbar(response?.data?.message, "success");
        handleReloadData();
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch (error) {
      if (controllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    }
  };

  const changeStatus = async () => {
    try {
      setLoading(true);
      let payload = {
        visit_id: visitId,
        status: status?.status,
        parts_carried_checklist: status?.parts_carried_checklist,
        event: `Status has been changed from ${pagesData?.overview?.statusInfo?.Status} to ${status?.status}`,
      };

      const response = await fetchApi("start_visit", "POST", payload, true);

      if (response.statusCode === 200 && response && response.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "travel");
        handlePagesData(null, "attachments");
        handlePagesData(null, "timeline");
        handleClose("status");
        handleReloadData();
      } else if (response.statusCode === 500 || response.statusCode === 401) {
        openSnackbar(response?.msg, "error");
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch (error) {
      if (controllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const intiateValidationProcess = async () => {
    try {
      setLoading(true);
      let payload = {
        visit_id: visitId,
        remoteValidationStatus: "Started",
        event: `Validation Process has been initiated`,
      };

      const response = await fetchApi(
        "initiate_validation",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200 && response && response.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        handleClose("status");
        handleReloadData();
      } else if (response.statusCode === 500 || response.statusCode === 401) {
        openSnackbar(response?.msg, "error");
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch (error) {
      if (controllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const validateSupport = async () => {
    try {
      setLoading(true);
      let payload = {
        visit_id: visitId,
        supportCheckpoints: selectedCheckpoints?.support,
        event: `Validation of support side checklist is being done`,
      };

      const response = await fetchApi(
        "validate_support_checklist",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200 && response && response.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        handleReloadData();
      } else if (response.statusCode === 500 || response.statusCode === 401) {
        openSnackbar(response?.msg, "error");
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch (error) {
      if (controllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const validateVisitChecklist = async () => {
    try {
      setLoading(true);
      let payload = {
        visit_id: visitId,
        visitCheckpoints: selectedCheckpoints?.visit,
        event: `Validation of visit side checklist is being done`,
      };

      const response = await fetchApi(
        "validate_visit_checklist",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200 && response && response.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        handleReloadData();
      } else if (response.statusCode === 500 || response.statusCode === 401) {
        openSnackbar(response?.msg, "error");
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch (error) {
      if (controllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to add the temp data to main product_serial_data array
  const addSerialData = () => {
    setSerialData((prev) => ({
      ...prev,
      product_serial_data: [
        ...(prev?.product_serial_data || []),
        tempSerialData,
      ],
    }));

    // Clear tempSerialData after adding
    setTempSerialData((prev) => ({
      ...prev,
      hardware: "",
      old_serial: "",
      new_serial: "",
    }));
    // setVisitDetailsFormData((prev) => ({
    //   ...prev,
    //   isAddSerial: false,
    // }));
  };

  // To delete the entry
  const handleDeleteElement = (index) => {
    setSerialData((prev) => ({
      ...prev, // Keep other state properties
      product_serial_data: prev.product_serial_data.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleNewDateChange = (name, value, statusDate = false) => {
    // Convert dayjs object to format "YYYY-MM-DD"
    const formattedDate = dayjs(value).format("YYYY-MM-DD").toString();

    if (!statusDate) {
      setVisitDetailsFormData((prevState) => ({
        ...prevState,
        [name]: formattedDate,
      }));
    }

    if (statusDate) {
      console.log("inside schedule one");
      setScheduleDetail((prev) => ({
        ...prev,
        [name]: formattedDate,
      }));
    }

    // Dynamically create the error key name (e.g., start_dateError or end_dateError)
    const errorKey = `${name}Error`;

    if (value !== "" && value !== null) {
      setFieldError((prev) => ({
        ...prev,
        [errorKey]: false,
      }));
    }
  };

  // function to fetch overview page data
  const fetchOverviewData = async () => {
    try {
      if (pagesData?.overview) {
        return;
      }

      const payload = {
        visit_id: visitId,
      };
      const response = await fetchApi(
        "visit_overview_data",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        showErrorPage(null, null);
        handlePagesData(response?.data?.data, "overview");
      } else {
        handlePagesData(null, "overview");
        showErrorPage(response?.statusCode, response.msg);
      }
    } catch {
      handlePagesData(null, "overview");
      showErrorPage(500, "Some Error Occured!");
    } finally {
      setLoadingVisiblity(false);
    }
  };

  // to reload the page
  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
  };

  const validateVisit = async () => {
    try {
      if (
        validate?.name === "" ||
        !isValidEmail(validate?.email) ||
        validate?.otp?.length !== 4
      ) {
        setFieldError((prev) => ({
          ...prev,
          validatePersonError: validate?.name === "",
          emailError: !isValidEmail(validate?.email),
          otpError: validate?.otp?.length !== 4,
        }));
        return;
      }

      setValidateLoading(true);
      const payload = {
        visit_id: visitId,
        name: validate?.name,
        email: validate?.email,
        designation: validate?.designation,
        code: validate?.otp,
      };
      const response = await fetchApi("validate_visit", "POST", payload, true);
      if (response?.statusCode === 200 && response?.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "travel");
        handlePagesData(null, "attachments");
        handlePagesData(null, "timeline");
        handleClose("validate");
        handleReloadData();
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while validating visit!", "error");
    } finally {
      setValidateLoading(false);
    }
  };

  const sendOtp = async () => {
    try {
      if (validate?.name === "" || !isValidEmail(validate?.email)) {
        setFieldError((prev) => ({
          ...prev,
          validatePersonError: validate?.name === "",
          emailError: !isValidEmail(validate?.email),
        }));
        return;
      }

      setLoading(true);
      const payload = {
        visit_id: visitId,
        name: validate?.name,
        email: validate?.email,
        designation:
          validate?.designation === "" ? null : validate?.designation,
        machine_code: pagesData?.overview?.locationInfo["Machine Code"],
      };
      const response = await fetchApi(
        "get_validate_visit_otp",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200 && response?.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        setValidate((prev) => ({
          ...prev,
          showOtp: true,
        }));
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while sending OTP!", "error");
    } finally {
      setLoading(false);
    }
  };

  const rescheduleVisit = async () => {
    try {
      setLoading(true);
      if (
        scheduleDetail.machine === "" ||
        scheduleDetail.assignee?.length === 0 ||
        scheduleDetail?.date === "" ||
        scheduleDetail.description.trim().split(/\s+/).length < 5
      ) {
        setFieldError((prev) => ({
          ...prev,
          machineError: scheduleDetail?.machine === "",
          assigneeError: scheduleDetail?.assignee?.length === 0,
          dateError: scheduleDetail?.date === "",
          descriptionError:
            scheduleDetail.description.trim().split(/\s+/).length < 5,
        }));
        return;
      }
      let payload = {
        visit_id: visitId,
        machine_code: scheduleDetail?.machine,
        support_person: get_support_persons_list(
          scheduleDetail?.assignee,
          backendFilterData?.support_list
        ),
        scheduled_date: scheduleDetail?.date,
        description: scheduleDetail?.description,
        status: "Scheduled",
      };

      const response = await fetchApi(
        "reschedule_visit",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200 && response?.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        handleClose("reschedule");
        handleReloadData();
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while saving details!", "error");
    } finally {
      setLoading(false);
    }
  };

  const editVisitLocation = async () => {
    try {
      setLoading(true);
      if (
        scheduleDetail?.person === "" ||
        scheduleDetail?.contact === "" ||
        scheduleDetail?.address === ""
      ) {
        setFieldError((prev) => ({
          ...prev,
          personError: scheduleDetail?.person === "",
          contactError: scheduleDetail?.contact === "",
          addressError: scheduleDetail?.address === "",
        }));
        return;
      }
      let payload = {
        visit_id: visitId,
        contact_person: scheduleDetail?.person,
        contact_number: scheduleDetail?.contact,
        location_address: scheduleDetail?.address,
      };

      const response = await fetchApi(
        "edit_visit_location",
        "POST",
        payload,
        true
      );
      if (response?.statusCode === 200 && response?.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        handleClose("editLocation");
        handleReloadData();
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch {
      openSnackbar("Some error occured while saving details!", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    showErrorPage(null, null);
    document.title = "Visit Overview - NEO";
    setLoadingVisiblity(true);
    fetchOverviewData();

    return () => {
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [reloadData]);

  // state varibales
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddMoreDetails = async () => {
    try {
      // Validate form data
      const {
        parts_replaced,
        total_expenses,
        distance,
        remarks,
        remarks_tags,
      } = visitDetailsFormData || {};

      if (
        !parts_replaced?.length ||
        !total_expenses ||
        distance === "" ||
        remarks === "" ||
        !remarks_tags?.length
      ) {
        setFieldError((prev) => ({
          ...prev,
          parts_replacedError: !parts_replaced?.length,
          total_expensesError: !total_expenses,
          distanceError: distance === "",
          remarksError: remarks === "",
          remarks_tagsError: !remarks_tags?.length,
        }));
        return;
      }

      setLoading(true);

      // Prepare remarks_tag_ids
      const remarks_tag_ids =
        remarks_tags?.map(
          (item) => backendFilterData?.filters_data?.remarksTags?.[item]
        ) || [];

      // Construct payload
      const payload = {
        visit_id: visitId,
        parts_replaced: get_parts_replaced_list(
          parts_replaced,
          backendFilterData?.hardware_list
        ),
        total_expense: total_expenses,
        distance,
        remarks_tags: remarks_tag_ids,
        remarks,
      };

      // Send API request
      const response = await fetchApi(
        "add_visit_summary_details",
        "POST",
        payload,
        true
      );

      // Handle response
      if (response?.statusCode === 200 && response?.data) {
        setExpanded(false);
        openSnackbar(response?.data?.message, "success");
        handlePagesData(null, "overview");
        handlePagesData(null, "timeline");
        handleClose("addVisitDetail");
        handleReloadData();
      } else {
        openSnackbar(response?.msg || "Something went wrong", "error");
      }
    } catch (error) {
      openSnackbar("Internal Server Error!", "error");
    } finally {
      setLoading(false);
    }
  };

  // status action
  const statusAction =
    pagesData?.overview?.statusInfo?.Status?.toLowerCase() === "scheduled"
      ? { label: "Start Visit", status: "Ongoing" }
      : pagesData?.overview?.statusInfo?.Status?.toLowerCase() === "ongoing"
      ? { label: "Validate Visit", status: "Validated" }
      : "";

  return (
    <>
      {(loadingVisiblity && (
        // <div
        //   className="showSpinner"
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <OverviewPageSkeleton
          isSmallSmallScreen={isSmallSmallScreen}
          color={color}
        />
      )) ||
        (!showError?.status && (
          <div className="row row-gap-3 mb-5">
            <div
              className={
                !islagelargeScreen
                  ? "col-9"
                  : !islageScreen
                  ? "col-8"
                  : !isMediumScreen
                  ? "col-7"
                  : "col-12"
              }
            >
              <div className="d-flex flex-column gap-3">
                <div className="card-Div-1 ">
                  <div className="d-flex flex-column gap-2 px-2 py-1 mb-2">
                    <div className="d-flex flex-row justify-content-between align-items-center pb-1">
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "rgba(76, 78, 100, 1)",
                        }}
                      >
                        Ticket Info
                      </p>
                    </div>
                    <div className="basic-info">
                      {pagesData?.overview?.basicInfo &&
                        Object.entries(pagesData?.overview?.basicInfo).map(
                          ([label, content], index) => (
                            <div
                              key={index}
                              className={
                                ["Error Detail"].includes(label)
                                  ? "full-width"
                                  : "p-0"
                              }
                            >
                              <p
                                className="label-style"
                                style={
                                  ["Ticket ID", "Visit ID"].includes(label)
                                    ? { fontWeight: "bold" }
                                    : undefined
                                }
                              >
                                {label}
                              </p>
                              <p
                                className="below-content-style"
                                style={{
                                  fontWeight: [
                                    "Ticket ID",
                                    "Visit ID",
                                  ].includes(label)
                                    ? "bold"
                                    : undefined,
                                  textTransform: "none",
                                }}
                              >
                                {label === "Creation Time"
                                  ? formatDate(content)
                                  : content}
                              </p>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
                <div className="card-Div-1 ">
                  <div className="d-flex flex-column gap-2 px-2 py-1 mb-2">
                    <div className="d-flex flex-row justify-content-between align-items-center pb-1">
                      <p
                        className="m-0"
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "rgba(76, 78, 100, 1)",
                        }}
                      >
                        Location Info
                      </p>
                      <p
                        className="m-0 hoverText"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleOpen(
                            "editLocation",
                            pagesData?.overview?.locationInfo
                          );
                          addVisitFiltersData(
                            pagesData?.overview?.basicInfo["Ticket ID"]
                          );
                        }}
                      >
                        Edit
                      </p>
                    </div>
                    <div
                      className="basic-info"
                      style={{
                        textTransform: "none",
                      }}
                    >
                      {pagesData?.overview?.locationInfo &&
                        Object.entries(pagesData?.overview?.locationInfo).map(
                          ([label, content], index) =>
                            content && (
                              <div
                                key={index}
                                className={
                                  ["Remarks"].includes(label)
                                    ? "full-width"
                                    : "p-0"
                                }
                              >
                                <p className="label-style">{label}</p>
                                <p className="below-content-style">
                                  {Array.isArray(content)
                                    ? content.join(", ")
                                    : content}
                                </p>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </div>

                {pagesData?.overview?.partsCarried && (
                  <div className="card-Div-1  pb-3">
                    <div className=" d-flex flex-column gap-3 m-2">
                      <div
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "rgba(76, 78, 100, 1)",
                        }}
                      >
                        Tools Carried Checklist
                      </div>
                      <Accordion
                        key={3}
                        expanded={expanded === 3}
                        onChange={handleChange(3)}
                        className=""
                        sx={{
                          "&.MuiAccordion-root": {
                            borderRadius: "6px !important",
                            boxShadow:
                              "rgb(76 78 100 / 15%) 0px 0px 15px 0px !important",
                            padding: "8px 5px !important",
                            position: "static",
                          },
                          "&.Mui-expanded": {
                            margin: "1px 0",
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel3-content`}
                          id={`panel3-header`}
                          sx={{
                            borderBottom:
                              expanded === 3 ? "1px solid #ccc" : "none",
                          }}
                          className="mx-3 px-0"
                        >
                          <div className="d-flex justify-content-between align-items-center gap-3 w-100">
                            <Typography
                              sx={{
                                // width: "33%",
                                // flexShrink: 0,
                                color: expanded === 3 ? "#666bff" : "inherit",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <i
                                className="fas fa-tools"
                                style={{ color: "#007bff", marginRight: "8px" }}
                              ></i>
                              Checklist Points
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ padding: 0, margin: "10px 0px" }}
                        >
                          <div
                            className="d-flex flex-column gap-3 py-2 px-3"
                            style={{ width: "100%" }}
                          >
                            <DataGrid
                              disableRowSelectionOnClick
                              disableSelectionOnClick
                              rows={pagesData?.overview?.partsCarried}
                              columns={columns1}
                              pageSize={20}
                              // condition when row can be selected
                              // isRowSelectable={(params) => false}
                              sx={{
                                "&.MuiDataGrid-root": {
                                  border: "none",
                                  borderRadius: "5px", // Adjust border radius as needed
                                  overflow: "hidden", // Ensure content respects the border radius,
                                  padding: "0px",
                                  margin: "0px",
                                  marginTop: "-10px",
                                },
                                ".MuiDataGrid-columnHeader": {
                                  backgroundColor: "#f0f4f8",
                                  // justifyContent: "center",
                                },
                                // ".disabled-row .MuiDataGrid-checkboxInput": {
                                //   pointerEvents: "none",
                                //   color: "rgba(0, 0, 0, 0.38)", // Light gray for disabled
                                // },

                                ".MuiDataGrid-columnHeaderTitleContainer": {
                                  // justifyContent: "center",
                                },
                                ".MuiDataGrid-filler": {
                                  backgroundColor: color.textWhiteColor,
                                },
                                ".MuiDataGrid-columnHeaderTitle": {
                                  fontFamily: "Open-Sans-Light",
                                  fontWeight: "bold",
                                  padding: "5px 0px",
                                  fontSize: "0.85rem",
                                  textTransform: "capitalize",
                                  color: color.taxtHeading,
                                },
                                ".MuiDataGrid-columnHeader--alignCenter": {
                                  headerAlign: "left",
                                },
                                ".MuiDataGrid-row": {
                                  border: "none", // Remove border from rows
                                  backgroundColor: color.textWhiteColor, // Set rows background color to white
                                  "&:hover": {
                                    backgroundColor: color.primary, // Ensure background color remains white on hover
                                    // cursor: "pointer",
                                  },
                                },

                                ".MuiDataGrid-cell": {
                                  outline: "none",
                                  "&:focus": {
                                    outline: "none",
                                  },
                                },
                                // Custom CSS to hide the "Rows per page" component

                                ".MuiDataGrid-footerContainer": {
                                  display: "none", // Hide the pagination footer
                                },
                                ".MuiTablePagination-root": {
                                  ".MuiInputBase-root": {
                                    display: "none",
                                  },

                                  ".MuiTablePagination-selectLabel": {
                                    display: "none",
                                  },
                                  ".MuiToolbar-root": {
                                    marginTop: "12px",
                                  },
                                },
                                "&.selected-row": {
                                  backgroundColor: "red", // Highlight color for the selected row
                                  "&:hover": {
                                    backgroundColor: "red ", // Ensure highlight color remains on hover
                                  },
                                },
                                ".MuiDataGrid-selectedRowCount": {
                                  display: "none", // Hide the selected row count div
                                },
                              }}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {/* support person checklist  */}
                    </div>
                  </div>
                )}

                {["Started", "Completed"].includes(
                  pagesData?.overview?.validationDetails?.Status
                ) && (
                  <div className="card-Div-1 ">
                    <div className=" d-flex flex-column gap-3 m-2">
                      <div
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: "16px",
                          color: "rgba(76, 78, 100, 1)",
                        }}
                      >
                        Validation Checklist Form
                      </div>
                      {/* support person checklist  */}
                      <Accordion
                        key={1}
                        expanded={expanded === 1}
                        onChange={handleChange(1)}
                        className=""
                        sx={{
                          "&.MuiAccordion-root": {
                            borderRadius: "6px !important",
                            boxShadow:
                              "rgb(76 78 100 / 15%) 0px 0px 15px 0px !important",
                            padding: "8px 5px !important",
                            position: "static",
                          },
                          "&.Mui-expanded": {
                            margin: "1px 0",
                          },
                        }}
                      >
                        <AccordionSummary
                          onClick={(e) => {
                            if (
                              !pagesData?.overview?.validationDetails[
                                "Support Person"
                              ]
                            ) {
                              e.preventDefault();
                              e.stopPropagation();
                              addVisitFiltersData(
                                pagesData?.overview?.basicInfo["Ticket ID"]
                              );
                            }
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel1-content`}
                          id={`panel1-header`}
                          sx={{
                            borderBottom:
                              expanded === 1 ? "1px solid #ccc" : "none",
                          }}
                          className="mx-3 px-0"
                        >
                          <div className="d-flex justify-content-between align-items-center gap-3 w-100">
                            <Typography
                              sx={{
                                // width: "33%",
                                // flexShrink: 0,
                                color: expanded === 1 ? "#666bff" : "inherit",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <i
                                className="fas fa-user-tie"
                                style={{ color: "#28a745", marginRight: "8px" }}
                              ></i>
                              Support Checklist
                            </Typography>
                            {!isSmallScreen && (
                              <Typography
                                sx={{
                                  color: expanded === 1 ? "#666bff" : "inherit",
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {pagesData?.overview?.validationDetails[
                                  "Support Person"
                                ] ? (
                                  <>
                                    <i
                                      className="fas fa-check-circle"
                                      style={{
                                        color: "#28a745",
                                        marginRight: "8px",
                                      }}
                                    ></i>
                                    Validated by{" "}
                                    {
                                      pagesData?.overview?.validationDetails[
                                        "Support Person"
                                      ]
                                    }
                                  </>
                                ) : (
                                  <>
                                    <i
                                      className="fas fa-exclamation-circle"
                                      style={{
                                        color: "#dc3545",
                                        marginRight: "8px",
                                      }}
                                    ></i>
                                    Not Validated
                                  </>
                                )}
                              </Typography>
                            )}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ padding: 0, margin: "10px 0px" }}
                        >
                          <div
                            className="d-flex flex-column gap-3 p-3"
                            style={{ width: "100%" }}
                          >
                            {!pagesData?.overview?.validationDetails[
                              "Support Checklist"
                            ] &&
                            !backendFilterData?.filters_data
                              ?.supportCheckpoints ? (
                              <TableSkeleton
                                rowsNum={5}
                                colsNum={3}
                                color={color}
                              />
                            ) : (
                              <DataGrid
                                checkboxSelection={
                                  !pagesData?.overview?.validationDetails[
                                    "Support Checklist"
                                  ]
                                    ? true
                                    : false
                                }
                                disableRowSelectionOnClick
                                disableSelectionOnClick
                                rows={
                                  pagesData?.overview?.validationDetails[
                                    "Support Person"
                                  ]
                                    ? pagesData?.overview?.validationDetails[
                                        "Support Checklist"
                                      ]
                                    : backendFilterData?.filters_data
                                        ?.supportCheckpoints
                                }
                                columns={columns1}
                                pageSize={20}
                                onRowSelectionModelChange={(ids) =>
                                  handleCheckboxChange(ids, "support")
                                }
                                // condition when row can be selected
                                isRowSelectable={(params) =>
                                  !pagesData?.overview?.validationDetails[
                                    "Support Checklist"
                                  ]
                                }
                                sx={{
                                  "&.MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5px", // Adjust border radius as needed
                                    overflow: "hidden", // Ensure content respects the border radius,
                                    padding: "0px",
                                    margin: "0px",
                                    marginTop: "-10px",
                                  },
                                  ".MuiDataGrid-columnHeader": {
                                    backgroundColor: "#f0f4f8",
                                    // justifyContent: "center",
                                  },
                                  // ".disabled-row .MuiDataGrid-checkboxInput": {
                                  //   pointerEvents: "none",
                                  //   color: "rgba(0, 0, 0, 0.38)", // Light gray for disabled
                                  // },

                                  ".MuiDataGrid-columnHeaderTitleContainer": {
                                    // justifyContent: "center",
                                  },
                                  ".MuiDataGrid-filler": {
                                    backgroundColor: color.textWhiteColor,
                                  },
                                  ".MuiDataGrid-columnHeaderTitle": {
                                    fontFamily: "Open-Sans-Light",
                                    fontWeight: "bold",
                                    padding: "5px 0px",
                                    fontSize: "0.85rem",
                                    textTransform: "capitalize",
                                    color: color.taxtHeading,
                                  },
                                  ".MuiDataGrid-columnHeader--alignCenter": {
                                    headerAlign: "left",
                                  },
                                  ".MuiDataGrid-row": {
                                    border: "none", // Remove border from rows
                                    backgroundColor: color.textWhiteColor, // Set rows background color to white
                                    "&:hover": {
                                      backgroundColor: color.primary, // Ensure background color remains white on hover
                                      // cursor: "pointer",
                                    },
                                  },

                                  ".MuiDataGrid-cell": {
                                    outline: "none",
                                    "&:focus": {
                                      outline: "none",
                                    },
                                  },
                                  // Custom CSS to hide the "Rows per page" component

                                  ".MuiDataGrid-footerContainer": {
                                    display: "none", // Hide the pagination footer
                                  },
                                  ".MuiTablePagination-root": {
                                    ".MuiInputBase-root": {
                                      display: "none",
                                    },

                                    ".MuiTablePagination-selectLabel": {
                                      display: "none",
                                    },
                                    ".MuiToolbar-root": {
                                      marginTop: "12px",
                                    },
                                  },
                                  "&.selected-row": {
                                    backgroundColor: "red", // Highlight color for the selected row
                                    "&:hover": {
                                      backgroundColor: "red ", // Ensure highlight color remains on hover
                                    },
                                  },
                                  ".MuiDataGrid-selectedRowCount": {
                                    display: "none", // Hide the selected row count div
                                  },
                                }}
                              />
                            )}
                          </div>
                          {!pagesData?.overview?.validationDetails[
                            "Support Checklist"
                          ] && (
                            <Typography className="float-end px-3 pb-3">
                              <LoadingButton
                                style={{
                                  color: "white",
                                  backgroundColor: "#666bff",
                                  borderRadius: "8px",
                                }}
                                size="large"
                                onClick={() => validateSupport()}
                                endIcon={<SendIcon />}
                                disabled={
                                  selectedCheckpoints?.support?.length !==
                                  backendFilterData?.filters_data
                                    ?.supportCheckpoints?.length
                                }
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                              >
                                <span>Validate</span>
                              </LoadingButton>
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>

                      {/* visit person accordion */}
                      {pagesData?.overview?.validationDetails[
                        "Support Person"
                      ] && (
                        <Accordion
                          key={2}
                          expanded={expanded === 2}
                          onChange={handleChange(2)}
                          className=""
                          sx={{
                            "&.MuiAccordion-root": {
                              borderRadius: "6px !important",
                              boxShadow:
                                "rgb(76 78 100 / 15%) 0px 0px 15px 0px !important",
                              padding: "8px 5px !important",
                              position: "static",
                            },
                            "&.Mui-expanded": {
                              margin: "1px 0",
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel2-content`}
                            id={`panel2-header`}
                            sx={{
                              borderBottom:
                                expanded === 2 ? "1px solid #ccc" : "none",
                            }}
                            className="mx-3 px-0"
                            onClick={(e) => {
                              if (
                                !pagesData?.overview?.validationDetails[
                                  "Visit Person"
                                ]
                              ) {
                                e.preventDefault();
                                e.stopPropagation();
                                addVisitFiltersData(
                                  pagesData?.overview?.basicInfo["Ticket ID"]
                                );
                              }
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center gap-3 w-100">
                              <Typography
                                sx={{
                                  // width: "33%",
                                  // flexShrink: 0,
                                  color: expanded === 2 ? "#666bff" : "inherit",
                                  fontSize: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  className="fas fa-map-marker-alt"
                                  style={{
                                    color: "#007bff",
                                    marginRight: "8px",
                                  }}
                                ></i>
                                Visit Checklist
                              </Typography>
                              {!isSmallScreen && (
                                <Typography
                                  sx={{
                                    color:
                                      expanded === 2 ? "#666bff" : "inherit",
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {pagesData?.overview?.validationDetails[
                                    "Visit Person"
                                  ] ? (
                                    <>
                                      <i
                                        className="fas fa-check-circle"
                                        style={{
                                          color: "#28a745",
                                          marginRight: "8px",
                                        }}
                                      ></i>
                                      Validated by{" "}
                                      {
                                        pagesData?.overview?.validationDetails[
                                          "Visit Person"
                                        ]
                                      }
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        className="fas fa-exclamation-circle"
                                        style={{
                                          color: "#dc3545",
                                          marginRight: "8px",
                                        }}
                                      ></i>
                                      Not Validated
                                    </>
                                  )}
                                </Typography>
                              )}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ padding: 0, margin: "10px 0px" }}
                          >
                            <div
                              className="d-flex flex-column gap-3 p-3"
                              style={{ width: "100%" }}
                            >
                              {!pagesData?.overview?.validationDetails[
                                "Visit Checklist"
                              ] &&
                              !backendFilterData?.filters_data
                                ?.visitCheckpoints ? (
                                <TableSkeleton
                                  rowsNum={5}
                                  colsNum={3}
                                  color={color}
                                />
                              ) : (
                                <DataGrid
                                  checkboxSelection={
                                    !pagesData?.overview?.validationDetails[
                                      "Visit Checklist"
                                    ]
                                      ? true
                                      : false
                                  }
                                  disableRowSelectionOnClick
                                  rows={
                                    pagesData?.overview?.validationDetails[
                                      "Visit Person"
                                    ]
                                      ? pagesData?.overview?.validationDetails[
                                          "Visit Checklist"
                                        ]
                                      : backendFilterData?.filters_data
                                          ?.visitCheckpoints
                                  }
                                  // condition when row can be selected
                                  isRowSelectable={(params) =>
                                    !pagesData?.overview?.validationDetails[
                                      "Visit Checklist"
                                    ]
                                  }
                                  columns={columns2}
                                  pageSize={20}
                                  onRowSelectionModelChange={(ids) =>
                                    handleCheckboxChange(ids, "visit")
                                  }
                                  sx={{
                                    "&.MuiDataGrid-root": {
                                      border: "none",
                                      borderRadius: "5px", // Adjust border radius as needed
                                      overflow: "hidden", // Ensure content respects the border radius,
                                      padding: "0px",
                                      margin: "0px",
                                      marginTop: "-10px",
                                    },
                                    ".MuiDataGrid-columnHeader": {
                                      backgroundColor: "#f0f4f8",
                                      // justifyContent: "center",
                                    },

                                    ".MuiDataGrid-columnHeaderTitleContainer": {
                                      // justifyContent: "center",
                                    },
                                    ".MuiDataGrid-filler": {
                                      backgroundColor: color.textWhiteColor,
                                    },
                                    ".MuiDataGrid-columnHeaderTitle": {
                                      fontFamily: "Open-Sans-Light",
                                      fontWeight: "bold",
                                      padding: "5px 0px",
                                      fontSize: "0.85rem",
                                      textTransform: "capitalize",
                                      color: color.taxtHeading,
                                    },
                                    ".MuiDataGrid-columnHeader--alignCenter": {
                                      headerAlign: "left",
                                    },
                                    ".MuiDataGrid-row": {
                                      border: "none", // Remove border from rows
                                      backgroundColor: color.textWhiteColor, // Set rows background color to white
                                      "&:hover": {
                                        backgroundColor: color.primary, // Ensure background color remains white on hover
                                        // cursor: "pointer",
                                      },
                                    },

                                    ".MuiDataGrid-cell": {
                                      outline: "none",
                                      "&:focus": {
                                        outline: "none", // Remove the blue outline when a cell is focused
                                      },
                                    },
                                    // Custom CSS to hide the "Rows per page" component

                                    ".MuiDataGrid-footerContainer": {
                                      display: "none", // Hide the pagination footer
                                    },
                                    ".MuiTablePagination-root": {
                                      ".MuiInputBase-root": {
                                        display: "none",
                                      },

                                      ".MuiTablePagination-selectLabel": {
                                        display: "none",
                                      },
                                      ".MuiToolbar-root": {
                                        marginTop: "12px",
                                      },
                                    },
                                    "&.selected-row": {
                                      backgroundColor: "red",
                                      "&:hover": {
                                        backgroundColor: "red ",
                                      },
                                    },
                                    ".MuiDataGrid-selectedRowCount": {
                                      display: "none",
                                    },
                                  }}
                                />
                              )}
                            </div>
                            {!pagesData?.overview?.validationDetails[
                              "Visit Checklist"
                            ] && (
                              <Typography className="float-end px-3 pb-3">
                                <LoadingButton
                                  style={{
                                    color: "white",
                                    backgroundColor: "#666bff",
                                    borderRadius: "8px",
                                  }}
                                  size="large"
                                  endIcon={<SendIcon />}
                                  onClick={() => validateVisitChecklist()}
                                  loading={loading}
                                  disabled={
                                    selectedCheckpoints?.visit?.length !==
                                    backendFilterData?.filters_data
                                      ?.visitCheckpoints?.length
                                  }
                                  loadingPosition="end"
                                  variant="contained"
                                >
                                  <span>Validate</span>
                                </LoadingButton>
                              </Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}

                      {/* final validation through OTP  */}
                      {(["Started"].includes(
                        pagesData?.overview?.validationDetails["Status"]
                      ) &&
                        pagesData?.overview?.validationDetails[
                          "Visit Checklist"
                        ] && (
                          <Typography className="pb-3">
                            <LoadingButton
                              sx={{
                                color: "#666bff",
                                border: "1px solid #666bff",
                                borderRadius: "8px",
                                textTransform: "capitalize",
                                "&:hover": {
                                  color: "white",
                                  backgroundColor: "#666bff",
                                  border: "1px solid #666bff",
                                },
                              }}
                              size="large"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleOpen("validate");
                              }}
                              endIcon={<SendIcon />}
                              // loading={loading}
                              loadingPosition="end"
                              variant="outlined"
                            >
                              <span>Submit OTP</span>
                            </LoadingButton>
                          </Typography>
                        )) ||
                        (pagesData?.overview?.verifierDetails &&
                          Object.keys(pagesData?.overview?.verifierDetails)
                            .length > 0 && (
                            <div className="mx-2 my-2 d-flex flex-column gap-2 py-1">
                              <div className="d-flex flex-row justify-content-between align-items-center">
                                <p
                                  className="m-0"
                                  style={{
                                    fontFamily: "Open-Sans-Light",
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    color: "rgba(76, 78, 100, 1)",
                                  }}
                                >
                                  Verifier Details
                                </p>
                              </div>
                              <div
                                className="basic-info"
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {pagesData?.overview?.verifierDetails &&
                                  Object.entries(
                                    pagesData?.overview?.verifierDetails
                                  ).map(
                                    ([label, content], index) =>
                                      content && (
                                        <div
                                          key={index}
                                          className={
                                            [
                                              "Location Address",
                                              "Remarks",
                                            ].includes(label)
                                              ? "full-width"
                                              : "p-0"
                                          }
                                        >
                                          <p className="label-style">{label}</p>
                                          <p className="below-content-style">
                                            {Array.isArray(content)
                                              ? content.join(", ")
                                              : content}
                                          </p>
                                        </div>
                                      )
                                  )}
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                )}

                {/* add vist details  */}
                {/* {["Completed"].includes(
                  pagesData?.overview?.statusInfo?.Status
                ) &&
                  pagesData?.overview?.More_details &&
                  Object.keys(pagesData?.overview?.More_details).length ===
                    0 && (
                    <div className="add-more px-1">
                      <LoadingButton
                        sx={{
                          color: "#666bff",
                          border: "1px solid #666bff",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "#666bff",
                            border: "1px solid #666bff",
                          },
                        }}
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleOpen("addVisitDetail");
                          addVisitFiltersData(
                            pagesData?.overview?.basicInfo["Ticket ID"]
                          );
                        }}
                        startIcon={<AddIcon />}
                        variant="outlined"
                      >
                        <span>Add Visit Details</span>
                      </LoadingButton>
                    </div>
                  )} */}

                {/* to show the extra visit details  */}
                {/* {["Completed"].includes(
                  pagesData?.overview?.statusInfo?.Status
                ) &&
                  pagesData?.overview?.More_details &&
                  Object.keys(pagesData?.overview?.More_details).length > 0 && (
                    <div className="card-Div-1 ">
                      <div className="d-flex flex-column gap-3 p-2 mb-2">
                        <div className="d-flex flex-row justify-content-between">
                          <p
                            className="m-0"
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "rgba(76, 78, 100, 1)",
                            }}
                          >
                            Visit Summary
                          </p>
                          <div className="d-flex justify-content-between gap-3">
                            {pagesData?.overview?.More_details[
                              "Remarks Tags"
                            ] && (
                              <p
                                className="m-0 hoverText"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleOpen(
                                    "addVisitDetail",
                                    pagesData?.overview?.More_details
                                  );
                                  addVisitFiltersData(
                                    pagesData?.overview?.basicInfo["Ticket ID"]
                                  );
                                }}
                              >
                                Edit
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className="basic-info"
                          style={{
                            textTransform: "none",
                          }}
                        >
                          {Object.entries(
                            pagesData?.overview?.More_details || {}
                          ).map(
                            ([label, content], index) =>
                              content && (
                                <div
                                  key={index}
                                  className={
                                    label === "Remarks" ? "full-width" : "p-0"
                                  }
                                >
                                  <p className="label-style">{label}</p>
                                  <p className="below-content-style">
                                    {Array.isArray(content)
                                      ? content.join(", ")
                                      : label === "Remarks Tags"
                                      ? Object.keys(content).join(", ")
                                      : content || null}
                                  </p>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
            <div
              className={
                !islagelargeScreen
                  ? "col-3"
                  : !islageScreen
                  ? "col-4"
                  : !isMediumScreen
                  ? "col-5"
                  : "col-12"
              }
            >
              <div className="d-flex flex-column gap-3">
                <div className="card-Div-1 " style={{ height: "max-content" }}>
                  <div
                    className={`d-flex ${
                      isSmallScreen
                        ? "align-items-center"
                        : "align-items-center"
                    } justify-content-evenly gap-3 p-2 mb-2 ${
                      ["Started", "Completed"].includes(
                        pagesData?.overview?.validationDetails?.Status
                      ) ||
                      pagesData?.overview?.statusInfo["Status"] === "Completed"
                        ? "mt-0"
                        : "mt-4"
                    } position-relative`}
                  >
                    {!["Started", "Completed"].includes(
                      pagesData?.overview?.validationDetails?.Status
                    ) && (
                      <div
                        className="position-absolute hoverText"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleOpen("status", {
                            status: statusAction["status"],
                          });
                          if (statusAction["status"] === "Ongoing") {
                            addVisitFiltersData(
                              pagesData?.overview?.basicInfo["Ticket ID"]
                            );
                          }
                        }}
                        style={{
                          right: "10px",
                          top: "-25px",
                        }}
                      >
                        {statusAction["label"]}
                      </div>
                    )}
                    {/* Current Status Badge */}
                    <div className="d-flex flex-column gap-2">
                      <div
                        className={`${getBadgeClass(
                          pagesData?.overview?.statusInfo?.Status
                        )} rounded-pill d-flex justify-content-start`}
                        style={{
                          fontSize: isSmallSmallScreen ? "13px" : "14px",
                          padding: "10px 12px",
                          width: "fit-content",
                          // backgroundColor: "#e6e0f8",
                          // color: "#9400D3",
                        }}
                      >
                        {pagesData?.overview?.statusInfo["Status"]}
                      </div>
                      <div
                        className="mx-auto "
                        style={{
                          fontSize: "12px",
                          // color: "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        Current Status
                      </div>
                    </div>

                    {/* Separating Line */}
                    <div
                      className={`separator `}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.26)",
                        height: "35px",
                        width: "1px",
                        transition: "all 0.3s ease",
                      }}
                    ></div>

                    {/* Updated Time Badge */}
                    <div className="d-flex flex-column gap-2">
                      <div
                        className={`${getBadgeClass(
                          pagesData?.overview?.statusInfo?.Status
                        )} rounded-pill d-flex justify-content-start`}
                        style={{
                          fontSize: isSmallSmallScreen ? "11px" : "12px",
                          padding: "10px",
                          width: "fit-content",
                        }}
                      >
                        {formatDate(
                          pagesData?.overview?.statusInfo["Stage Changed"]
                        )}
                      </div>
                      <div
                        className="mx-auto"
                        style={{
                          fontSize: isSmallSmallScreen ? "11px" : "12px",
                          // color: "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        Last Updated
                      </div>
                    </div>
                  </div>
                  {/* Final button to validate  */}
                  {/* {!["Started", "Completed"].includes(
                    pagesData?.overview?.validationDetails?.Status
                  ) && (
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        // className=""
                        className="btn btn-outline-primary markResolved_button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleOpen("status", {
                            status: statusAction["status"],
                          });
                          if (statusAction["status"] === "Started") {
                            addVisitFiltersData(
                              pagesData?.overview?.basicInfo["Ticket ID"]
                            );
                          }
                        }}
                        style={{
                          margin: "10px",
                          color: "#2546ec",
                          // backgroundColor: "",
                        }}
                      >
                        {statusAction["label"]}
                      </button>
                    </div>
                  )} */}
                </div>

                {/* Scheduled by Info  */}
                {pagesData?.overview?.scheduleInfo &&
                  Object.keys(pagesData?.overview?.scheduleInfo).length > 0 && (
                    <div className="card-Div-1 ">
                      <div className="d-flex flex-column gap-2 px-2 py-1 mb-2">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <p
                            className="m-0"
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "rgba(76, 78, 100, 1)",
                            }}
                          >
                            Scheduled Info
                          </p>
                          {["Scheduled"].includes(
                            pagesData?.overview?.statusInfo?.Status
                          ) && (
                            <p
                              className="m-0 hoverText"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleOpen(
                                  "reschedule",
                                  pagesData?.overview?.scheduleInfo
                                );
                                addVisitFiltersData(
                                  pagesData?.overview?.basicInfo["Ticket ID"]
                                );
                              }}
                            >
                              Reschedule
                            </p>
                          )}
                        </div>
                        <div className="right-basic-info">
                          {pagesData?.overview?.scheduleInfo &&
                            Object.entries(
                              pagesData?.overview?.scheduleInfo
                            ).map(([label, content], index) => (
                              <div
                                key={index}
                                className={
                                  label === "Description" ? "full-width" : "p-0"
                                }
                              >
                                <p
                                  className="label-style"
                                  style={
                                    ["Visit Person", "Scheduled Date"].includes(
                                      label
                                    )
                                      ? { fontWeight: "bold" }
                                      : undefined
                                  }
                                >
                                  {label}
                                </p>
                                <p
                                  className="below-content-style"
                                  style={{
                                    fontWeight: [
                                      "Visit Person",
                                      "Scheduled Date",
                                    ].includes(label)
                                      ? "bold"
                                      : undefined,
                                    textTransform: "none",
                                  }}
                                >
                                  {label === "Scheduled Date"
                                    ? content?.slice(0, -12)
                                    : label === "Visit Person"
                                    ? content.join(", ")
                                    : content}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Visit Summary Part  */}
                {["Completed"].includes(
                  pagesData?.overview?.statusInfo?.Status
                ) &&
                  pagesData?.overview?.More_details &&
                  Object.keys(pagesData?.overview?.More_details).length > 0 && (
                    <div className="card-Div-1 ">
                      <div className="d-flex flex-column gap-2 px-2 py-1 mb-2">
                        <div className="d-flex flex-row justify-content-between align-items-center pb-1">
                          <p
                            className="m-0"
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "rgba(76, 78, 100, 1)",
                            }}
                          >
                            Visit Summary
                          </p>
                          <div className="d-flex justify-content-between gap-3">
                            {pagesData?.overview?.More_details[
                              "Remarks Tags"
                            ] && (
                              <p
                                className="m-0 hoverText"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleOpen(
                                    "addVisitDetail",
                                    pagesData?.overview?.More_details
                                  );
                                  addVisitFiltersData(
                                    pagesData?.overview?.basicInfo["Ticket ID"]
                                  );
                                }}
                              >
                                Edit
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className="right-basic-info"
                          style={{
                            textTransform: "none",
                          }}
                        >
                          {Object.entries(
                            pagesData?.overview?.More_details || {}
                          ).map(
                            ([label, content], index) =>
                              content && (
                                <div
                                  key={index}
                                  className={
                                    label === "Remarks" ? "full-width" : "p-0"
                                  }
                                >
                                  <p
                                    className="label-style"
                                    style={{
                                      fontWeight: [
                                        "Parts Replaced",
                                        "Total Expense(Rs)",
                                      ].includes(label)
                                        ? "bold"
                                        : undefined,
                                      textTransform: "none",
                                    }}
                                  >
                                    {label}
                                  </p>
                                  <p
                                    className="below-content-style"
                                    style={{
                                      fontWeight: [
                                        "Parts Replaced",
                                        "Total Expense(Rs)",
                                      ].includes(label)
                                        ? "bold"
                                        : undefined,
                                      textTransform: "none",
                                    }}
                                  >
                                    {Array.isArray(content)
                                      ? content.join(", ")
                                      : label === "Remarks Tags"
                                      ? Object.keys(content).join(", ")
                                      : content || null}
                                  </p>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Show Product Serial No part */}
                {["Completed"].includes(
                  pagesData?.overview?.statusInfo?.Status
                ) &&
                  pagesData?.overview?.productDetails?.length > 0 && (
                    <div className="card-Div-1 ">
                      <div className="d-flex flex-column gap-2 px-2 mb-2">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <p
                            className="m-0"
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "rgba(76, 78, 100, 1)",
                            }}
                          >
                            Product Serial No
                          </p>
                          <p
                            className="m-0 hoverText"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleOpen("addSerial");
                              addVisitFiltersData(
                                pagesData?.overview?.basicInfo["Ticket ID"]
                              );
                            }}
                          >
                            Add
                          </p>
                        </div>
                        {/* Render the list of elements */}
                        {(pagesData?.overview?.productDetails?.length > 0 && (
                          <div
                            className={`serial-keys-container p-0 m-0 ms-0 ${
                              isSmallScreen ? "" : "m-0"
                            }`}
                            style={{ boxShadow: "none" }}
                          >
                            <div className="mt-2 data-variation-elements-container">
                              {pagesData?.overview?.productDetails?.map(
                                (element, index) => (
                                  <div
                                    key={element.id}
                                    className="data-variation-elements d-flex justify-content-center"
                                  >
                                    <div
                                      className="
                        "
                                      style={{
                                        color: "rgba(76, 78, 100, 0.8)",
                                      }}
                                    >
                                      <strong>Hardware:</strong>{" "}
                                      {element.hardware},{" "}
                                      <strong>Old Serial:</strong>{" "}
                                      {element.old_serial},{" "}
                                      <strong>New Serial:</strong>{" "}
                                      {element.new_serial}
                                    </div>
                                    <div className="ms-auto">
                                      <div
                                        className="dropdown ms-2  "
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Tooltip
                                          data-html="true"
                                          title={
                                            "Click to Delete this serial no"
                                          }
                                          arrow={true}
                                        >
                                          <i
                                            className="fa-solid fa-ellipsis-vertical  "
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{ padding: "0px 3px" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          ></i>
                                        </Tooltip>
                                        {/* Centered dropdown */}
                                        {/* </button> */}
                                        <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                                          <li>
                                            <a
                                              className="dropdown-item text-center delete-ticket-btn"
                                              href="#"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                deleteSerialData(element.id);
                                              }}
                                            >
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )) || <></>}
                      </div>
                    </div>
                  )}

                <div className="d-flex gap-2">
                  {/* add vist summary button  */}
                  {["Completed"].includes(
                    pagesData?.overview?.statusInfo?.Status
                  ) &&
                    pagesData?.overview?.More_details &&
                    Object.keys(pagesData?.overview?.More_details).length ===
                      0 && (
                      <div className="add-more px-1">
                        <LoadingButton
                          sx={{
                            color: "#666bff",
                            border: "1px solid #666bff",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                            "&:hover": {
                              color: "white",
                              backgroundColor: "#666bff",
                              border: "1px solid #666bff",
                            },
                          }}
                          size={isSmallSmallScreen ? "small" : "medium"}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleOpen("addVisitDetail");
                            addVisitFiltersData(
                              pagesData?.overview?.basicInfo["Ticket ID"]
                            );
                          }}
                          startIcon={<AddIcon />}
                          variant="outlined"
                        >
                          <span>Add Summary</span>
                        </LoadingButton>
                      </div>
                    )}

                  {/* Add serial no button  */}
                  {["Completed"].includes(
                    pagesData?.overview?.statusInfo?.Status
                  ) &&
                    pagesData?.overview?.productDetails?.length === 0 && (
                      <div className="">
                        <LoadingButton
                          sx={{
                            color: "#666bff",
                            border: "1px solid #666bff",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                            "&:hover": {
                              color: "white",
                              backgroundColor: "#666bff",
                              border: "1px solid #666bff",
                            },
                          }}
                          size={isSmallSmallScreen ? "small" : "medium"}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleOpen("addSerial");
                            addVisitFiltersData(
                              pagesData?.overview?.basicInfo["Ticket ID"]
                            );
                          }}
                          startIcon={<AddIcon />}
                          variant="outlined"
                        >
                          <span>Add Serial No</span>
                        </LoadingButton>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )) ||
        (showError.status && <Error height={"80vh"} />)}

      {/* add more visit details modal part  */}
      <Modal
        style={{
          position: "fixed",
          height: "100%",
          overflowY: "scroll",
          margin: "0px 0px 0px 10px",
        }}
        open={modalOpen?.addVisitDetail}
        // onClose={() => handleClose("addVisitDetail")}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
      >
        <Box sx={style1}>
          <Typography
            style={{
              padding: "16px",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h5
              className=""
              style={{
                fontFamily: "Open-Sans-Light",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgba(76, 78, 100, 1)",
                margin: "12px",
              }}
            >
              Add Visit Summary
            </h5>
            <div onClick={() => handleClose("addVisitDetail")}>
              <Tooltip title={"Close"} arrow>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </Typography>
          <Typography className="px-3 py-4 pb-0">
            <div className="row row-gap-3 mb-3">
              <div className="col-lg-12">
                <Autocomplete
                  multiple
                  name="parts_replaced"
                  id="tags-outlined"
                  value={visitDetailsFormData.parts_replaced}
                  options={backendFilterData?.hardware_relaced_list}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  onChange={(e, selectedOptions) => {
                    setVisitDetailsFormData((prev) => ({
                      ...prev,
                      parts_replaced: selectedOptions.map((option) => option),
                    }));
                    if (selectedOptions?.length > 0) {
                      setFieldError((prev) => ({
                        ...prev,
                        parts_replacedError: false,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parts Replaced"
                      placeholder="Enter Parts Replaced"
                      required
                      error={fieldError?.parts_replacedError}
                      helperText={
                        fieldError.parts_replacedError &&
                        "Please select at least 1 option"
                      }
                    />
                  )}
                />
              </div>

              {/* dates part  */}
              {/* <div className="col-lg-6">
                <div className="row row-gap-3">
                  <div className="col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{
                          overflow: "unset",
                          padding: "0px",
                          width: "100%",
                        }}
                      >
                        <DatePicker
                          label="Start Date *"
                          value={visitDetailsFormData?.start_date}
                          onChange={(date) =>
                            handleNewDateChange("start_date", date)
                          }
                          slotProps={{
                            textField: {
                              error: fieldError.start_dateError,
                              helperText: fieldError.start_dateError
                                ? "Please select a Start Date"
                                : "",
                            },
                          }}
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            padding: "0px",
                            margin: "0px",
                            "& .MuiInputBase-input": {
                              color: "#4c4e64de",
                            },
                            "& .MuiInputLabel-root": {
                              color: fieldError.start_dateError
                                ? "#df0538"
                                : "#272323b3",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="col-lg-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{
                          overflow: "unset",
                          padding: "0px",
                          width: "100%",
                        }}
                      >
                        <DatePicker
                          label="End Date *"
                          value={visitDetailsFormData?.end_date}
                          onChange={(date) =>
                            handleNewDateChange("end_date", date)
                          }
                          slotProps={{
                            textField: {
                              error: fieldError.end_dateError,
                              helperText: fieldError.end_dateError
                                ? "Please select an End Date"
                                : "",
                            },
                          }}
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            padding: "0px",
                            margin: "0px",
                            "& .MuiInputBase-input": {
                              color: "#4c4e64de",
                            },
                            "& .MuiInputLabel-root": {
                              color: fieldError.end_dateError
                                ? "#df0538"
                                : "#272323b3",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row row-gap-3 mb-3">
              <div className="col-lg-6">
                <TextField
                  value={visitDetailsFormData?.distance}
                  name="distance"
                  placeholder="Enter Distance"
                  fullWidth
                  id="outlined-basic"
                  label="Distance (km)"
                  variant="outlined"
                  type="number"
                  required
                  onChange={(e) => {
                    setVisitDetailsFormData((prev) => ({
                      ...prev,
                      distance: e.target.value,
                    }));
                    if (e.target.value !== "") {
                      setFieldError((prev) => ({
                        ...prev,
                        distanceError: false,
                      }));
                    }
                  }}
                  error={fieldError?.distanceError}
                  helperText={
                    fieldError.distanceError &&
                    "Please add the distance covered"
                  }
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                    },
                  }}
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  value={visitDetailsFormData?.total_expenses}
                  name="total_expenses"
                  placeholder="Total Expense"
                  fullWidth
                  id="outlined-basic"
                  label="Total Expense (Rs)"
                  variant="outlined"
                  required
                  type="number"
                  onChange={(e) => {
                    setVisitDetailsFormData((prev) => ({
                      ...prev,
                      total_expenses: e.target.value,
                    }));
                    if (e.target.value !== "") {
                      setFieldError((prev) => ({
                        ...prev,
                        total_expensesError: false,
                      }));
                    }
                  }}
                  error={fieldError?.total_expensesError}
                  helperText={
                    fieldError?.total_expensesError && "Please add the expense"
                  }
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12 mb-3 mt-4">
              <Autocomplete
                multiple
                name="remarks_tags"
                id="tags-outlined"
                value={visitDetailsFormData?.remarks_tags || []}
                options={
                  (backendFilterData?.filters_data?.remarksTags &&
                    Object.keys(
                      backendFilterData?.filters_data?.remarksTags
                    )) ||
                  []
                }
                getOptionLabel={(option) => option}
                filterSelectedOptions
                onChange={(e, selectedOptions) => {
                  setVisitDetailsFormData((prev) => ({
                    ...prev,
                    remarks_tags: selectedOptions.map((option) => option),
                  }));
                  if (selectedOptions?.length > 0) {
                    setFieldError((prev) => ({
                      ...prev,
                      remarks_tagsError: false,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Remark Tags"
                    placeholder="Choose Remark Tags"
                    error={fieldError.remarks_tagsError}
                    helperText={
                      fieldError.remarks_tagsError &&
                      "Please select at least one Tag"
                    }
                    required
                  />
                )}
              />
            </div>
            {visitDetailsFormData?.remarks_tags?.length > 0 && (
              <div className="col-12">
                <TextField
                  className="w-100"
                  name="remarks"
                  label="Remarks*"
                  id="outlined-textarea"
                  onChange={(e) => {
                    setVisitDetailsFormData((prev) => ({
                      ...prev,
                      remarks: e.target.value,
                    }));
                    if (e.target.value !== "") {
                      setFieldError((prev) => ({
                        ...prev,
                        remarksError: false,
                      }));
                    }
                  }}
                  rows={4}
                  multiline
                  value={visitDetailsFormData.remarks}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                    },
                  }}
                  error={fieldError?.remarksError}
                  helperText={fieldError?.remarksError && "Please add remarks"}
                />
              </div>
            )}
          </Typography>
          <Typography className="float-end p-3">
            <LoadingButton
              sx={{
                color: "white",
                backgroundColor: "#666bff",
                borderRadius: "8px",
                textTransform: "capitalize",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#666bff",
                },
              }}
              size="large"
              onClick={() => handleAddMoreDetails()}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          </Typography>
        </Box>
      </Modal>

      {/* add serial number modal part  */}
      <Modal
        style={{
          position: "fixed",
          height: "100%",
          overflowY: "scroll",
          margin: "0px 0px 0px 10px",
        }}
        open={modalOpen?.addSerial}
        // onClose={() => handleClose("addSerial")}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
      >
        <Box sx={style2}>
          <Typography
            style={{
              padding: "16px",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h5
              className=""
              style={{
                fontFamily: "Open-Sans-Light",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgba(76, 78, 100, 1)",
                margin: "12px",
              }}
            >
              Add Serial No
            </h5>
            <div onClick={() => handleClose("addSerial")}>
              <Tooltip title={"Close"} arrow>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </Typography>
          <Typography className="px-3 py-4 pb-0">
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `${
                    islageScreen ? "1fr" : "1fr 1fr 1fr"
                  }`,
                  // marginTop: "10px",
                  gap: "2px", // Add some space between columns
                }}
              >
                {/* Hardware Autocomplete */}
                <div className="p-2 w-100">
                  <Autocomplete
                    name="hardware"
                    id="tags-outlined"
                    value={tempSerialData.hardware}
                    options={backendFilterData?.hardware_relaced_list || []}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    onChange={(e, selectedOption) => {
                      setTempSerialData((prev) => ({
                        ...prev,
                        hardware: selectedOption,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Hardware*"
                        placeholder="Select Hardware"
                        fullWidth
                        sx={{
                          "& .MuiButtonBase-input.Mui-disabled": {
                            opacity: "0.6",
                          },
                        }}
                      />
                    )}
                  />
                </div>

                {/* Old V39 Serial No */}
                <div className="p-2 w-100">
                  <TextField
                    value={tempSerialData.old_serial}
                    name="old_serial"
                    placeholder="Enter Old Serial No"
                    fullWidth
                    id="outlined-basic"
                    label="Old Serial No*"
                    variant="outlined"
                    onChange={handleTempInputChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                      },
                    }}
                  />
                </div>

                {/* New V39 Serial No */}
                <div className="p-2 w-100">
                  <TextField
                    value={tempSerialData.new_serial}
                    name="new_serial"
                    placeholder="Enter New Serial No"
                    fullWidth
                    id="outlined-basic"
                    label="New Serial No*"
                    variant="outlined"
                    onChange={handleTempInputChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                      },
                    }}
                  />
                </div>
              </div>

              {/* Button to add tempSerialData to product_serial_data */}
              <div className="px-2 py-2">
                <LoadingButton
                  // style={{
                  //   color: "#666bff",
                  //   border: "1px solid #666bff",
                  //   borderRadius: "8px",
                  // }}
                  sx={{
                    color: "#666bff",
                    border: "1px solid #666bff",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#666bff",
                      border: "1px solid #666bff",
                    },
                  }}
                  size="medium"
                  onClick={() => addSerialData()}
                  disabled={
                    !tempSerialData.hardware ||
                    !tempSerialData.old_serial ||
                    !tempSerialData.new_serial
                  }
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  <span>Add</span>
                </LoadingButton>
              </div>
            </div>

            {/* Render the list of elements */}
            {(serialData?.product_serial_data?.length > 0 && (
              <div className="serial-keys-container mx-0 px-2 shadow-none mt-1">
                <header className="previous-comments-header">
                  Product Serial No
                </header>
                <div className="mt-2 data-variation-elements-container">
                  {serialData?.product_serial_data?.map((element, index) => (
                    <div
                      key={index}
                      className="data-variation-elements"
                      style={{ color: "rgba(76, 78, 100, 0.7)" }}
                    >
                      <strong>Hardware:</strong> {element.hardware},{" "}
                      <strong>Old Serial:</strong> {element.old_serial},{" "}
                      <strong>New Serial:</strong> {element.new_serial}
                      <span
                        className="px-2 cross-btn-data-variation"
                        variant="outlined"
                        onClick={() => handleDeleteElement(index)}
                      >
                        {"x" || <></>}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )) || <></>}
          </Typography>
          <Typography className="float-end p-3">
            <LoadingButton
              sx={{
                color: "white",
                backgroundColor: "#666bff",
                borderRadius: "8px",
                textTransform: "capitalize",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#666bff",
                },
              }}
              size="large"
              onClick={() => handleAddSerialData()}
              endIcon={<SendIcon />}
              loading={loading}
              disabled={serialData?.product_serial_data?.length === 0}
              loadingPosition="end"
              variant="contained"
            >
              <span>Submit</span>
            </LoadingButton>
          </Typography>
        </Box>
      </Modal>

      {/* adding status modal part  */}
      <Modal
        style={{
          position: "fixed",
          height: "100%",
          overflowY: "scroll",
          margin: "0px 0px 0px 10px",
        }}
        open={modalOpen?.status}
        // onClose={() => handleClose("addSerial")}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
      >
        <Box sx={style3}>
          <Typography
            style={{
              padding: " 10px 14px",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h5
              className=""
              style={{
                fontFamily: "Open-Sans-Light",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgba(76, 78, 100, 1)",
                margin: "12px 6px",
              }}
            >
              {status?.status === "Ongoing"
                ? "Edit Status"
                : "Initiate Validation Process"}
            </h5>
            <div onClick={() => handleClose("status")}>
              <Tooltip title={"Close"} arrow>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </Typography>
          <Typography className="px-3 py-3 pb-0">
            {status?.status === "Ongoing" && (
              <div className="my-0">
                {/* <Autocomplete
                  multiple
                  name="parts_carried_checklist"
                  id="tags-outlined"
                  value={status.parts_carried_checklist}
                  options={backendFilterData?.hardware_relaced_list}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  onChange={(e, selectedOptions) => {
                    setStatus((prev) => ({
                      ...prev,
                      parts_carried_checklist: selectedOptions.map((option) => option),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parts Carried"
                      placeholder="Enter the parts you are carrying on this visit"
                      required
                    />
                  )}
                /> */}
                <div
                  className="d-flex flex-column gap-4"
                  style={{ width: "100%" }}
                >
                  <p
                    className="m-0"
                    style={{
                      fontFamily: "Open-Sans-Light",
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "rgba(76, 78, 100, 1)",
                    }}
                  >
                    Tools Carrying Checklist
                  </p>
                  {!backendFilterData?.filters_data?.toolsCarriedCheckpoints ? (
                    <TableSkeleton rowsNum={5} colsNum={3} color={color} />
                  ) : (
                    <DataGrid
                      checkboxSelection
                      disableRowSelectionOnClick
                      rows={
                        backendFilterData?.filters_data?.toolsCarriedCheckpoints
                      }
                      columns={columns1}
                      pageSize={20}
                      onRowSelectionModelChange={(ids) =>
                        setStatus((prev) => ({
                          ...prev,
                          parts_carried_checklist: ids,
                        }))
                      }
                      // condition when row can be selected
                      // isRowSelectable={(params) =>
                      //   !pagesData?.overview?.validationDetails[
                      //     "Support Checklist"
                      //   ]
                      // }
                      sx={{
                        "&.MuiDataGrid-root": {
                          border: "none",
                          borderRadius: "5px", // Adjust border radius as needed
                          overflow: "hidden", // Ensure content respects the border radius,
                          padding: "0px",
                          margin: "0px",
                          marginTop: "-10px",
                        },
                        ".MuiDataGrid-columnHeader": {
                          backgroundColor: "#f0f4f8",
                          // justifyContent: "center",
                        },
                        // ".disabled-row .MuiDataGrid-checkboxInput": {
                        //   pointerEvents: "none",
                        //   color: "rgba(0, 0, 0, 0.38)", // Light gray for disabled
                        // },

                        ".MuiDataGrid-columnHeaderTitleContainer": {
                          // justifyContent: "center",
                        },
                        ".MuiDataGrid-filler": {
                          backgroundColor: color.textWhiteColor,
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                          fontFamily: "Open-Sans-Light",
                          fontWeight: "bold",
                          padding: "5px 0px",
                          fontSize: "0.85rem",
                          textTransform: "capitalize",
                          color: color.taxtHeading,
                        },
                        ".MuiDataGrid-columnHeader--alignCenter": {
                          headerAlign: "left",
                        },
                        ".MuiDataGrid-row": {
                          border: "none", // Remove border from rows
                          backgroundColor: color.textWhiteColor, // Set rows background color to white
                          "&:hover": {
                            backgroundColor: color.primary, // Ensure background color remains white on hover
                            // cursor: "pointer",
                          },
                        },

                        ".MuiDataGrid-cell": {
                          outline: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        },
                        // Custom CSS to hide the "Rows per page" component

                        ".MuiDataGrid-footerContainer": {
                          display: "none", // Hide the pagination footer
                        },
                        ".MuiTablePagination-root": {
                          ".MuiInputBase-root": {
                            display: "none",
                          },

                          ".MuiTablePagination-selectLabel": {
                            display: "none",
                          },
                          ".MuiToolbar-root": {
                            marginTop: "12px",
                          },
                        },
                        "&.selected-row": {
                          backgroundColor: "red", // Highlight color for the selected row
                          "&:hover": {
                            backgroundColor: "red ", // Ensure highlight color remains on hover
                          },
                        },
                        ".MuiDataGrid-selectedRowCount": {
                          display: "none", // Hide the selected row count div
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            )}

            <div
              className={`form-check ${
                status?.status === "Ongoing" ? "mt-4" : ""
              } ms-1`}
            >
              <input
                className="form-check-input"
                type="checkbox"
                value={status?.isCheckBox}
                onChange={(e) => {
                  handleCheckBox(e.target.checked);
                }}
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {status?.status === "Ongoing" ? (
                  "Are you sure you want to start the visit?"
                ) : (
                  <>
                    I confirm that the issue with Machine No{" "}
                    <span
                      style={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {pagesData?.overview?.locationInfo["Machine Code"]}
                    </span>{" "}
                    has been resolved, and I am ready to initiate the validation
                    process.
                  </>
                )}
              </label>
            </div>
          </Typography>
          <Typography className="float-end p-3">
            <LoadingButton
              sx={{
                color: "white",
                backgroundColor: "#666bff",
                borderRadius: "8px",
                textTransform: "capitalize",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#666bff",
                },
              }}
              size="large"
              onClick={() =>
                status?.status === "Ongoing"
                  ? changeStatus()
                  : intiateValidationProcess()
              }
              endIcon={<SendIcon />}
              disabled={
                status?.status === "Ongoing"
                  ? status?.parts_carried_checklist?.length !==
                      backendFilterData?.filters_data?.toolsCarriedCheckpoints
                        ?.length || !status?.isCheckBox
                  : !status?.isCheckBox
              }
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Typography>
        </Box>
      </Modal>

      {/* reschedule modal */}
      <Modal
        style={{
          position: "fixed",
          height: "100%",
          overflowY: "scroll",
          margin: "0px 0px 0px 10px",
        }}
        open={modalOpen?.reschedule}
        // onClose={() => handleClose("addSerial")}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
      >
        <Box sx={style4}>
          <Typography
            style={{
              padding: " 10px 14px",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h5
              className=""
              style={{
                fontFamily: "Open-Sans-Light",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgba(76, 78, 100, 1)",
                margin: "12px 5px",
              }}
            >
              Reschedule Visit
            </h5>
            <div onClick={() => handleClose("reschedule")}>
              <Tooltip title={"Close"} arrow>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </Typography>
          <Typography className="px-3 py-4 pb-0">
            <div className="row mb-4" style={{ rowGap: "25px" }}>
              {/* Machine selection */}
              <div className="col-lg-4">
                <FormControl
                  helperText="Please select machine"
                  error={fieldError.machineError}
                  sx={{ width: "100%" }}
                  color="primary"
                >
                  <Autocomplete
                    id="machine-select"
                    options={backendFilterData?.machines_list || []}
                    getOptionLabel={(option) =>
                      `${option.code} (${option.location})`
                    }
                    filterSelectedOptions
                    value={
                      backendFilterData?.machines_list.find(
                        (machine) => machine.code === scheduleDetail.machine
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      if (newValue !== "") {
                        setFieldError((prev) => ({
                          ...prev,
                          machineError: false,
                        }));
                      }
                      if (newValue) {
                        setScheduleDetail((prev) => ({
                          ...prev,
                          machine: newValue.code,
                        }));
                      } else {
                        setScheduleDetail((prev) => ({
                          ...prev,
                          machine: "",
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Machine"
                        placeholder="Select Machine"
                        variant="outlined"
                        error={fieldError?.machineError}
                        helperText={
                          fieldError.machineError && "Please select a machine"
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>

              {/* Support person selection */}
              <div className="col-lg-5">
                <Autocomplete
                  multiple
                  id="assignee-select"
                  options={backendFilterData?.support_name_list || []}
                  filterSelectedOptions
                  value={scheduleDetail?.assignee}
                  onChange={(event, values) => {
                    const finalValues = values.map((item) => item);
                    setScheduleDetail((prev) => ({
                      ...prev,
                      assignee: finalValues,
                    }));
                    if (finalValues.length > 0) {
                      setFieldError((prev) => ({
                        ...prev,
                        assigneeError: false,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Support Persons"
                      // placeholder="Select Support Persons"
                      error={fieldError.assigneeError}
                      helperText={
                        fieldError.assigneeError &&
                        "Please select at least one person"
                      }
                      variant="outlined"
                    />
                  )}
                />
              </div>

              {/* Date selection */}
              <div className="col-lg-3 mb-2 mb-lg-0 position-relative">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker"]}
                    sx={{
                      overflow: "unset",
                      padding: "0px",
                      width: "100%",
                    }}
                  >
                    <DatePicker
                      label="Select Date"
                      value={scheduleDetail?.date || null}
                      onChange={(date) =>
                        handleNewDateChange("date", date, true)
                      }
                      slotProps={{
                        textField: {
                          error: fieldError.dateError,
                          helperText: fieldError.dateError
                            ? "Please select a date"
                            : "",
                        },
                      }}
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        padding: "0px",
                        margin: "0px",
                        "& .MuiInputBase-input": {
                          color: "#4c4e64de",
                        },
                        "& .MuiInputLabel-root": {
                          color: fieldError.dateError ? "#df0538" : "#272323b3",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>

            {/* description  */}
            <div className="row">
              <div className="">
                <TextField
                  name="Description"
                  fullWidth
                  label="Description"
                  id="outlined-textarea"
                  onChange={(event) => {
                    const value = event.target.value;
                    setScheduleDetail((prev) => ({
                      ...prev,
                      description: value,
                    }));

                    // Update error state based on word count
                    setFieldError((prev) => ({
                      ...prev,
                      descriptionError: value.trim().split(/\s+/).length < 5,
                    }));
                  }}
                  rows={4}
                  multiline
                  value={scheduleDetail?.description}
                  error={fieldError?.descriptionError}
                  helperText={
                    fieldError?.descriptionError &&
                    "Please enter at leat 5 words"
                  }
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                    },
                  }}
                />
              </div>
            </div>
          </Typography>
          <Typography className="float-end p-3">
            <LoadingButton
              sx={{
                color: "white",
                backgroundColor: "#666bff",
                borderRadius: "8px",
                textTransform: "capitalize",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#666bff",
                },
              }}
              size="large"
              onClick={() => rescheduleVisit()}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              <span>Submit</span>
            </LoadingButton>
          </Typography>
        </Box>
      </Modal>

      {/* Edit Visit Location modal */}
      <Modal
        style={{
          position: "fixed",
          height: "100%",
          overflowY: "scroll",
          margin: "0px 0px 0px 10px",
        }}
        open={modalOpen?.editLocation}
        // onClose={() => handleClose("addSerial")}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
      >
        <Box sx={style4}>
          <Typography
            style={{
              padding: " 10px 14px",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h5
              className=""
              style={{
                fontFamily: "Open-Sans-Light",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgba(76, 78, 100, 1)",
                margin: "12px 5px",
              }}
            >
              Edit Details for Machine{" "}
              {pagesData?.overview?.locationInfo["Machine Code"]}
            </h5>
            <div onClick={() => handleClose("editLocation")}>
              <Tooltip title={"Close"} arrow>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </Typography>
          <Typography className="px-3 pb-2">
            {/* remote details  */}
            <div className="row my-4" style={{ rowGap: "25px" }}>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  value={scheduleDetail?.person}
                  id="outlined-basic"
                  label="Contact Person"
                  placeholder="For Ex: John Doe"
                  variant="outlined"
                  onChange={(event) => {
                    setScheduleDetail((prev) => {
                      return {
                        ...prev,
                        person: event.target.value,
                      };
                    });
                    setFieldError((prev) => ({
                      ...prev,
                      personError: event.target.value === "",
                    }));
                  }}
                  error={fieldError?.personError}
                  helperText={
                    fieldError?.personError && "Please enter a person name"
                  }
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  fullWidth
                  type="number"
                  value={scheduleDetail?.contact}
                  id="outlined-basic"
                  label="Contact Number"
                  placeholder="For Ex: 9234 567 890"
                  variant="outlined"
                  onChange={(event) => {
                    setScheduleDetail((prev) => {
                      return {
                        ...prev,
                        contact: event.target.value,
                      };
                    });
                    setFieldError((prev) => ({
                      ...prev,
                      contactError: event.target.value === "",
                    }));
                  }}
                  error={fieldError?.contactError}
                  helperText={
                    fieldError?.contactError && "Please enter a contact no"
                  }
                />
              </div>
              <div className="col-lg-6">
                <TextField
                  fullWidth
                  value={scheduleDetail?.address}
                  id="outlined-basic"
                  label="Location Address"
                  placeholder="For Ex: Fci Regional office Punjab, Bay no. 34-38, Sector 31A, Chandigarh Pin 160030"
                  variant="outlined"
                  onChange={(event) => {
                    setScheduleDetail((prev) => {
                      return {
                        ...prev,
                        address: event.target.value,
                      };
                    });
                    setFieldError((prev) => ({
                      ...prev,
                      addressError: event.target.value === "",
                    }));
                  }}
                  error={fieldError?.addressError}
                  helperText={
                    fieldError?.addressError &&
                    "Please enter the location address"
                  }
                />
              </div>
            </div>
          </Typography>
          <Typography className="float-end p-3">
            <LoadingButton
              sx={{
                color: "white",
                backgroundColor: "#666bff",
                borderRadius: "8px",
                textTransform: "capitalize",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#666bff",
                },
              }}
              size="large"
              onClick={() => editVisitLocation()}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              <span>Submit</span>
            </LoadingButton>
          </Typography>
        </Box>
      </Modal>

      {/* validate modal */}
      <Modal
        style={{
          position: "fixed",
          height: "100%",
          overflowY: "scroll",
          margin: "0px 0px 0px 10px",
        }}
        open={modalOpen?.validate}
        // onClose={() => handleClose("addSerial")}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
      >
        <Box sx={style5}>
          <Typography
            style={{
              padding: " 5px 14px",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h5
              className=""
              style={{
                fontFamily: "Open-Sans-Light",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgba(76, 78, 100, 1)",
                margin: "12px 5px",
              }}
            >
              Validate Visit
            </h5>
            <div onClick={() => handleClose("validate")}>
              <Tooltip title={"Close"} arrow>
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          </Typography>
          <div className="d-flex flex-column mt-2">
            <Typography className="p-3 pb-0">
              <TextField
                fullWidth
                value={validate?.name}
                id="outlined-basic"
                label="Name *"
                disabled={validate?.showOtp}
                placeholder="Enter Person Name"
                variant="outlined"
                onChange={(event) => {
                  setValidate((prev) => ({
                    ...prev,
                    name: event.target.value,
                  }));
                  setFieldError((prev) => ({
                    ...prev,
                    validatePersonError: event.target.value === "",
                  }));
                }}
                error={fieldError?.validatePersonError}
                helperText={
                  fieldError?.validatePersonError &&
                  "Please enter the person Name"
                }
              />
            </Typography>
            <Typography className="p-3 pb-0">
              <TextField
                fullWidth
                value={validate?.email}
                id="outlined-basic"
                label="Email *"
                disabled={validate?.showOtp}
                placeholder="Enter Person Email"
                variant="outlined"
                onChange={(event) => {
                  setValidate((prev) => {
                    return {
                      ...prev,
                      email: event.target.value,
                    };
                  });
                  setFieldError((prev) => ({
                    ...prev,
                    emailError: !isValidEmail(event.target.value),
                  }));
                }}
                error={fieldError?.emailError}
                helperText={
                  fieldError?.emailError && "Please enter a valid email"
                }
              />
            </Typography>

            {/* Add Designation */}
            <Typography className="p-3 pb-0">
              <Autocomplete
                value={validate?.designation}
                disabled={validate?.showOtp}
                onChange={(event, newValue) => {
                  if (newValue) {
                    if (
                      typeof newValue === "string" &&
                      newValue.startsWith('Add "')
                    ) {
                      setValidate((prev) => ({
                        ...prev,
                        designation: newValue.slice(5, -1),
                      }));
                    } else {
                      setValidate((prev) => ({
                        ...prev,
                        designation: newValue,
                      }));
                    }
                  } else {
                    setValidate((prev) => ({
                      ...prev,
                      designation: "",
                    }));
                  }
                }}
                options={options || []}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;

                  // Normalize the inputValue to lowercase
                  const normalizedInputValue = inputValue.toLowerCase();

                  // Check if the inputValue matches any existing option (case-insensitive)
                  const isExisting = options.some(
                    (option) => option.toLowerCase() === normalizedInputValue
                  );

                  // Suggest the creation of a new value only if it does not already exist
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(`Add "${inputValue}"`);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="Select Designation"
                getOptionLabel={(option) => {
                  // Regular option
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.toString();
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ width: "100%" }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Designation"
                    placeholder="Choose person designation"
                  />
                )}
              />
            </Typography>

            {validate?.showOtp && (
              <div
                className={`d-flex flex-row gap-1 align-items-center otp  justify-content-center my-4 ${
                  isSmallScreen ? "mx-1" : "mx-auto"
                }`}
              >
                <div
                  style={{
                    fontFamily: "Open-Sans-Light",
                    fontSize: "16px",
                    color: "rgba(56, 48, 100, 0.7)",
                    fontWeight: "bold",
                  }}
                >
                  Enter OTP :{" "}
                </div>
                <div className="position-relative">
                  <OTPInput
                    value={validate?.otp || ""}
                    error={fieldError?.otpError}
                    helperText={
                      fieldError?.otpError && "Please enter a valid 4-digit OTP"
                    }
                    onChange={(value) => {
                      setValidate((prev) => ({
                        ...prev,
                        otp: value,
                      }));
                      setFieldError((prev) => ({
                        ...prev,
                        otpError: value.length !== 4, // Set error if length is not 4
                      }));
                    }}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {fieldError?.otpError && (
                    <div
                      style={{
                        position: "absolute",
                        marginTop: "5px",
                        color: "red",
                        fontWeight: 300,
                        fontSize: isSmallScreen ? "11px" : "12px",
                        marginLeft: "5px",
                      }}
                    >
                      Please enter a 4-digit OTP
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <Typography className="d-flex gap-3 justify-content-center p-3 float-end">
            {validate?.showOtp && (
              <LoadingButton
                sx={{
                  color: "#666bff",
                  border: "1px solid #666bff",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#666bff",
                    border: "1px solid #666bff",
                  },
                }}
                size="large"
                onClick={() => sendOtp()}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="outlined"
              >
                <span>Resend Otp</span>
              </LoadingButton>
            )}
            <LoadingButton
              sx={{
                color: "white",
                backgroundColor: "#666bff",
                borderRadius: "8px",
                textTransform: "capitalize",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#666bff",
                },
              }}
              size="large"
              onClick={() => (validate?.showOtp ? validateVisit() : sendOtp())}
              endIcon={<SendIcon />}
              loading={validate?.showOtp ? validateLoading : loading}
              loadingPosition="end"
              variant="contained"
            >
              <span>{validate?.showOtp ? "Validate" : "Send OTP"}</span>
            </LoadingButton>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default EachVisitOverview;
