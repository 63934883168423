import React, { useContext, useEffect, useState } from "react";
import { SnackbarContent } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Error from "../../error/Error";
import Attachments from "../../RaiseTicketForm/Attachments";
import "../../../assets/styles/AddLotDetailsForm.css";
import { color } from "../../modals/Calllog";
import ShipmentGeneralDetails from "./ShipmentGeneralDetails";
import ShipmentProductDetails from "./ShipmentProductDetails";
import ShipmentPreview from "./ShipmentPreview";
import { useDispatch, useSelector } from "react-redux";
import ContextState from "../../contextApi/ContextState";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color.lotBtncolor,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: color.lotBtncolor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : color.lotBtncolor,
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: color.lotBtncolor,
  }),
  "& .QontoStepIcon-completedIcon": {
    color: color.lotBtncolor,
    zIndex: 1,
    fontSize: 24,
  },
  "& .QontoStepIcon-circle": {
    width: 12,
    height: 12,
    border: "2px solid currentColor",
    borderRadius: "50%",
    // backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};
const AddShipmentDetailsForm = () => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const TicketDetils = useSelector((state) => state.createTicketDetail);
  const [FiltersData, setFiltersData] = useState({});
  const { fetchApi, showLoading, showErrorPage, controllerRef } =
    useContext(ContextState);
  const [isCheckBox, setIsCheckBox] = useState(false);

  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    lodingModalVisiblity,
    showModalErrorPage,
    modalControllerRef,
    formatDate,
    openSnackbar,
  } = useContext(ContextState);
  ///// useEffect

  useEffect(() => {
    document.title = "Add Shipment Details - NEO";
    let is_Mounted = true;
    showLoading(true);
    setinitialLoading(false);

    return () => {
      is_Mounted = false;
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  const [initialLoading, setinitialLoading] = useState({
    loading: true,
    error: false,
  });

  useEffect(() => {
    if (FiltersData === null) {
      setinitialLoading({
        loading: false,
        error: true,
      });
    } else if (Object.keys(FiltersData).length) {
      setinitialLoading({
        loading: false,
        error: false,
      });
    }
  }, [FiltersData]);

  // first page fields
  const [genFields, setGenFields] = useState({
    generalDetails: {
      sender: {
        id: "",
        email: "",
        name: "",
        contact_number: "",
        contact_person: "",
        address: "",
      },
      receiver: {
        id: "",
        email: "",
        name: "",
        contact_number: "",
        contact_person: "",
        address: "",
      },
      date: "",
      productCount: "",
      trackId: "",
      shipmentType: "",
      machine: "",
    },
    productDetails: [],
    attachments: [],
  });

  useEffect(() => {
    console.log(genFields);
  }, [genFields]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  // // console.log("islagelargeScreen: ", islagelargeScreen);

  const [steps, setStep] = useState({
    activeStep: 0,
    labels: [
      ["General Details", true, true],
      ["Product Details", true, false],
      ["Attachments", true, false],
      ["Preview", true, false],
    ],
  });

  const [MainForm, setMainForm] = useState([]);
  const togglestep = (flag) => {
    setStep((prevSteps) => {
      const newSteps = prevSteps.labels.map((step, index) => {
        if (index === 1) {
          // Check if this is the second step
          return [step[0], flag, step[2]]; // Toggle the boolean value
        }
        return step; // Return other steps as they are
      });
      return {
        ...prevSteps,
        labels: newSteps,
      }; // Return the updated state
    });
  };
  const [fieldError, setFieldError] = useState({
    trackIdError: false,
    dateError: false,
    generalDetailsError: false,
    addressSenderError: false,
    addressReceiverError: false,
    shipmentError: false,
    machineError: false,
    attachmentError: false,
    productDetailsError: false,
    productSerialNumberError: false,
  });
  const gonext = () => {
    let new_selected_index = -1;
    let curentIndex = -1;
    let trackIdError = false;
    let dateError = false;
    let shipmentTypeError = false;
    let senderError = false;
    let receiverError = false;
    let attachmentError = false;
    let productDetailsError = false;
    let prodSerialError = false;

    setStep((prevSteps) => {
      // console.log("prev Steps -> ", prevSteps);
      let step2Flag = prevSteps.labels[1][1]; // Check the flag for "Attachments"

      prevSteps.labels.forEach((step, index) => {
        if (step[2] === true) {
          // Find the currently active step
          if (index === prevSteps.labels.length - 1) {
            new_selected_index = 0; // Wrap around to the first step
          } else {
            new_selected_index = index + 1; // Move to the next step
          }
          curentIndex = index;
        }
      });

      // If the next step is "Attachments" and its flag is false, skip to "Product Details"
      if (new_selected_index === 1 && !step2Flag) {
        new_selected_index = 2;
      }

      // Validate fields on the first step before moving forward
      if (curentIndex === 0) {
        if (genFields.generalDetails.trackId === "") trackIdError = true;
        if (genFields.generalDetails.date === "") dateError = true;
        if (genFields.generalDetails.shipmentType === "")
          shipmentTypeError = true;
        if (genFields.generalDetails.sender.name === "") senderError = true;
        if (genFields.generalDetails.receiver.name === "") receiverError = true;

        setFieldError((prev) => ({
          ...prev,
          trackIdError: trackIdError,
          dateError: dateError,
          shipmentError: shipmentTypeError,
          addressSenderError: senderError,
          addressReceiverError: receiverError,
        }));

        //   // If there are errors, do not proceed to the next step
        if (
          trackIdError ||
          dateError ||
          shipmentTypeError ||
          senderError ||
          receiverError
        ) {
          return { ...prevSteps };
        }
      }

      //   // Validate fields on the third step before moving forward
      if (curentIndex === 1) {
        let productDetailsError = false;
        let prodSerialError = false;

        // Check if product details are empty
        if (genFields.productDetails.length === 0) {
          productDetailsError = true;
          openSnackbar("Add Product Details to proceed.", "warning");
        }
        // else {
        //   //  check is all product Details entries productPrice matches the total Amount
        //   if (
        //     genFields.productDetails.reduce(
        //       (acc, item) => acc + parseInt(item.productPrice, 10),
        //       0
        //     ) < parseInt(genFields.sellerDetails.totalAmount, 10)
        //   ) {
        //     productDetailsError = true;
        //     openSnackbar(
        //       `Total price ${genFields.sellerDetails.totalAmount} doesn't match product details.`,
        //       "warning"
        //     );
        //   }
        //   //  check is any product of the serial number list which is serilized enterd or not before proceed
        //   if (
        //     genFields?.productDetails?.some(
        //       (item) =>
        //         item.productType.tracking_type.toLowerCase() !== "batch wise" &&
        //         item.quantity > item.serialNumber.length
        //     )
        //   ) {
        //     productDetailsError = true;
        //     // serialNumberlengthError = true;
        //     openSnackbar(
        //       "Some products are missing serial numbers. Please add them to proceed.",
        //       "warning"
        //     );
        //   }
        // }

        // Set field errors in state
        setFieldError((prev) => ({
          ...prev,
          productDetailsError: productDetailsError,
          // productSerialNumberError: prodSerialError,
        }));

        // If there are errors, return early
        if (productDetailsError) {
          return { ...prevSteps }; // Prevent progression
        }
      }

      //   // Validate fields on the second step before moving forward
      if (curentIndex === 2) {
        if (genFields.attachments.length === 0) attachmentError = true;

        setFieldError((prev) => ({
          ...prev,
          attachmentError: attachmentError,
        }));

        //   //   // If there are errors, do not proceed to the next step
        if (attachmentError) {
          return { ...prevSteps };
        }
      }

      // Update the active step in the labels array
      const newSteps1 = prevSteps.labels.map((step, index) => {
        return [step[0], step[1], index === new_selected_index]; // Set new active step
      });

      return {
        ...prevSteps,
        labels: newSteps1,
        activeStep: new_selected_index,
      };
    });
  };

  const goprev = () => {
    let new_selected_index = -1;

    setStep((prevSteps) => {
      let step2Flag = prevSteps.labels[1][1]; // Check if "Attachments" is enabled

      prevSteps.labels.forEach((step, index) => {
        if (step[2] === true) {
          // Find the current active step
          if (index === 0) {
            new_selected_index = prevSteps.labels.length - 1; // Wrap around to the last step
          } else {
            new_selected_index = index - 1; // Move to the previous step
          }
        }
      });

      // Skip "Attachments" if its flag is false
      if (new_selected_index === 1 && !step2Flag) {
        new_selected_index = 0;
      }

      // Update steps to reflect the new active step
      const newSteps1 = prevSteps.labels.map((step, index) => {
        return [step[0], step[1], index === new_selected_index]; // Set new active step
      });

      return {
        ...prevSteps,
        labels: newSteps1,
        activeStep: new_selected_index,
      };
    });
  };

  const handleCheckBox = (checked) => {
    setIsCheckBox(checked);
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    if (!initialLoading.error && !initialLoading.loading) {
      let tempMainForm = steps?.labels?.map((step, index) => {
        if (step[2]) {
          // Render components based on the step's name
          return step[0] === "General Details" ? (
            <ShipmentGeneralDetails
              key={index}
              genFields={genFields}
              setGenFields={setGenFields}
              togglestep={togglestep}
              fieldError={fieldError}
              isSmallScreen={isSmallScreen}
              isMediumScreen={isMediumScreen}
              islageScreen={islageScreen}
            />
          ) : step[0] === "Attachments" ? (
            <div style={{ width: "100%", padding: "20px 0px" }}>
              <p className="m-2 mt-0">*Product images are required.</p>
              <Attachments
                key={index}
                genFields={genFields}
                setGenFields={setGenFields}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                isSmallScreen={isSmallScreen}
                isLotDetailsForm={true}
              />
              {fieldError.attachmentError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please upload attachments.
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : step[0] === "Product Details" ? (
            <ShipmentProductDetails
              key={index}
              genFields={genFields}
              setGenFields={setGenFields}
              isSmallScreen={isSmallScreen}
              fieldError={fieldError}
            />
          ) : (
            <ShipmentPreview
              key={index}
              genFields={genFields}
              setGenFields={setGenFields}
              isSmallScreen={isSmallScreen}
              handleCheckBox={handleCheckBox}
              isCheckBox={isCheckBox}
              isMediumScreen={isMediumScreen}
              islageScreen={islageScreen}
            />
          );
        }
        return null;
      });
      setMainForm(tempMainForm);
    }
  }, [steps, genFields, fieldError, isSmallScreen, initialLoading]);

  useEffect(() => {
    // console.log(genFields);
    if (genFields.generalDetails.trackId !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          trackIdError: false,
        };
      });
    }
    if (genFields.generalDetails.date !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          dateError: false,
        };
      });
    }
    if (genFields.generalDetails.shipmentType !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          shipmentError: false,
        };
      });
    }
    if (genFields.generalDetails.sender.name !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          addressSenderError: false,
        };
      });
    }
    if (genFields.generalDetails.receiver.name !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          addressReceiverError: false,
        };
      });
    }
    if (genFields.attachments.length !== 0) {
      setFieldError((prev) => {
        return {
          ...prev,
          attachmentError: false,
        };
      });
    }
    if (genFields.productDetails.length !== 0) {
      setFieldError((prev) => {
        return {
          ...prev,
          productDetailsError: false,
        };
      });
    }
  }, [genFields]);

  const urlToFileArrayBuffer = async (fileUrl) => {
    try {
      // Fetch the file content as a blob
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Read the blob as an array buffer
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(new Error("Failed to read file"));
        };
        reader.readAsArrayBuffer(blob);
      });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };

  const submitForm = async () => {
    // setSubmitLoading(true);
    //  make it true to show loading while submiting
    setOpen((prev) => {
      return {
        ...prev,
        submitLoading: true,
      };
    });
    let formData = {};
    const attachmentsData = await Promise.all(
      genFields.attachments.map(async (data) => {
        // console.log(data);
        // Fetch array buffer from URL
        const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
        // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
        const uint8Array = new Uint8Array(arrayBuffer);

        // console.log(arrayBuffer);
        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer]);
        // console.log("blob-", blob);
        // Return object with file name and FormData
        return {
          file_name: data.name,
          file_data: Array.from(uint8Array),
        };
      })
    );

    // console.log("attachmentsData--->", attachmentsData);
    formData = {
      lotDetails: { ...genFields.sellerDetails },
      productDetails: { ...genFields.productDetails },
      files: attachmentsData,
    };
    console.log("finalData-", formData);

    let isMounted = true;
    const sendRaiseTicketDataResponce = await fetchApi(
      //   "add_lot_details",
      "POST",
      formData,
      isMounted
    );
    // console.log(sendRaiseTicketDataResponce);
    if (sendRaiseTicketDataResponce.statusCode === 200) {
      setOpen({
        submitLoading: false,
        flag: true,
        ticket_id: sendRaiseTicketDataResponce.data.lot_id,
      });
    } else {
      console.log("error in submit form");
      openSnackbar(
        "Some internal error occurred. Please try again later.",
        "warning"
      );
      setOpen((prev) => {
        return { ...prev, submitLoading: false };
      });
    }
    setReload(!reload);

    return () => {
      isMounted = false;
    };
  };

  const [open, setOpen] = React.useState({
    submitLoading: false,
    flag: false,
    ticket_id: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen({
        flag: false,
        ticket_id: "",
      });
    }
  };
  // media query
  const responsiveRaiseTicket = useMediaQuery("(max-width:1400px)");
  return (
    <div className="container">
      {initialLoading.loading ? (
        <div
          className="showSpinner all-ticket-spinner"
          style={{ height: "100vh", zIndex: 5 }}
        >
          <BeatLoader color="#2D97D4" />
        </div>
      ) : initialLoading.error ? (
        // <div>Internal server error</div>
        <Error />
      ) : (
        <div
          style={{
            marginTop: "40px",
            marginBottom: "25px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* top Heading Div */}
          <div
            style={{
              marginBottom: "15px",
              color: "rgba(76,78,100,0.7)",
              fontSize: "24px",
            }}
          >
            Shipment Form
          </div>
          {/* main form */}
          <div
            style={{
              width: responsiveRaiseTicket ? "97%" : "96vw",
              maxWidth: "1200px",
              minHeight: "400px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "30px",
              paddingBottom: "30px",
              borderRadius: 12,
              paddingInline: isSmallScreen ? "10px" : "30px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 16px 0px",
              justifyContent: "space-between",
            }}
          >
            {/* top checkpoints */}
            {isSmallScreen ? (
              <div
                style={{ fontSize: "20px", color: "rgba(76, 78, 100, 0.7)" }}
              >
                {steps.labels[steps.activeStep][0]}
              </div>
            ) : (
              <Stepper
                orientation="horizontal"
                alternativeLabel
                activeStep={steps?.activeStep}
                connector={<QontoConnector />}
                style={{
                  width: "100%",
                  margin: "25px 0px 15px",
                }}
              >
                {steps?.labels?.map((label) =>
                  label[1] ? (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        style={{ fontSize: "16px", color: "#ff0000" }}
                        className="custom-label"
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ) : (
                    <></>
                  )
                )}
              </Stepper>
            )}
            {/* {MainForm} rendering every component */}

            <div
              style={{
                width: "100%",
                // maxHeight: "680px",
                maxHeight: "60vh",
                overflowY: "scroll",
                margin: "5px",
                padding: "10px",
              }}
            >
              {MainForm}
            </div>
            {/* bottom buttons back , next , submit */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "20px",
                padding: "0px 20px 0px 15px",
              }}
            >
              <Button
                variant={steps?.activeStep === 0 ? "disabled" : "outlined"}
                style={{
                  color:
                    steps?.activeStep === 0 ? undefined : color.lotBtncolor,
                  // backgroundColor: color.lotBtncolor,
                  height: "38px",
                  borderRadius: "8px",
                }}
                onClick={goprev}
                disabled={steps?.activeStep === 0}
              >
                <GrFormPreviousLink
                  fontSize="small"
                  style={{
                    // color: color.textWhiteColor,
                    height: "auto",
                    width: "25px",
                  }}
                />
                <span
                  className="text-nowrap"
                  style={{
                    fontFamily: "Open-Sans-regular",
                    fontWeight: 600,
                    // color: color.textWhiteColor,
                  }}
                >
                  Back
                </span>
              </Button>
              {steps?.activeStep === steps?.labels.length - 1 ? (
                <LoadingButton
                  size="small"
                  onClick={submitForm}
                  disabled={!isCheckBox}
                  endIcon={<SendIcon />}
                  loading={open.submitLoading}
                  loadingPosition="end"
                  variant="contained"
                  style={{
                    backgroundColor: color.lotBtncolor,
                    height: "38px",
                    borderRadius: "8px",
                    color: color.textWhiteColor,
                    opacity: !isCheckBox ? "0.6" : "",
                  }}
                >
                  <span>Submit</span>
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  onClick={gonext}
                  style={{
                    backgroundColor: color.lotBtncolor,
                    height: "38px",
                    borderRadius: "8px",
                  }}
                >
                  <span
                    className="text-nowrap"
                    style={{
                      fontFamily: "Open-Sans-regular",
                      fontWeight: 600,
                      color: color.textWhiteColor,
                    }}
                  >
                    Next
                  </span>
                  <GrFormNextLink
                    fontSize="small"
                    style={{
                      color: color.textWhiteColor,
                      height: "auto",
                      width: "25px",
                    }}
                  />
                </Button>
              )}
            </div>
          </div>
          {/* final modal after adding lot */}
          <Dialog
            ClickAwayListenerProps={{ onClickAway: () => null }}
            fullScreen={fullScreen}
            open={open.flag}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"
              style={{
                color: "#59A148",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleOutlineIcon style={{ marginRight: "5px" }} />
              {"Added Lot Details Successfully !"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Added lot details successfully with ID #{open.ticket_id}.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  // navigate("/raiseticket");
                  // navigate(window.location.pathname, { replace: true });
                  window.location.reload();
                }}
              >
                Add Lot Details
              </Button>
              <Button
                onClick={() => {
                  navigate("/FCI/lots");
                }}
                autoFocus
                style={{ marginLeft: "15px" }}
              >
                View All Lots
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default AddShipmentDetailsForm;
