import React, { useEffect, useState, useContext, useCallback } from "react";
import Tickets from "../Tickets";
import "../../assets/styles/AllTickets.css";
import AllFilters from "../filters/AllFilters";
import ContextState from "../contextApi/ContextState";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../features/filtersSlice/filterSlice";
import { BeatLoader } from "react-spinners";
import Dropdown from "../filters/Dropdown";
import Error from "../error/Error";
import * as XLSX from "xlsx";
import { GiConsoleController } from "react-icons/gi";
import ProductType from "./Product/ProductType";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const AllProducts = () => {
  const {
    fetchApi,
    showLoading,
    showError,
    showErrorPage,
    openSnackbar,
    generalApiCall,
    gencontrollerRef,
    controllerRef,
    formatInIndianRupees,
  } = useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters);

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");
  const [liveMachinesData, setliveMachinesData] = useState(null);

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);

  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters

  // console.log("testing export data--------->", detailedExportData);
  const color = {
    primary: "#f7f7f9", // website background
    // secondary: "#8884d8",
    // secondary: "#826af9", // blue color in dashboard
    // secondary: "#0098e9", // blue color in dashboard
    secondary: "#666bff", // blue color in dashboard
    green: "#82ca9d", // green color in dashboard
    red: "#ff4d49d9", // red color in dashboard
    navBorderBottom: "#4c4e6422",
    taxtHeadingLight: "#4c4e6499", // normal text heading light color
    navHeadingAboveText: "#4c4e64d3", // (76,78,100,0.7)
    tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
    taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
    iconBackgroundBlue: "#666bff39",
    iconBackgroundWhite: "#ffffffe6",
    iconBackgroundgreen: "#82ca9d39",
    iconBackgroundred: "#ff4d4939",
    textWhiteColor: "#ffffff",
    textDescBackgroundRegionDiv: "#5b61e5",
    locationTextHeading: "#4c4e64fc", //location heeading color
    imgBackgroundColor : "#4f4f4f33"
  };

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  return (
    // <div className={`container ${isSmallScreen && "ms-2" || "ms-0"} me-3`}>
    <div className={``}>
      <Outlet
        context={{
          color: color,
          ProductTypeData: null,
          formatInIndianRupees: formatInIndianRupees,
          isMediumScreen: isMediumScreen,
          isSmallScreen: isSmallScreen,
          isSmallSmallScreen: isSmallSmallScreen,
          islageScreen: islageScreen,
          islagelargeScreen: islagelargeScreen,
        }}
      />
    </div>
  );
};

export default AllProducts;
