import { useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ContextState from "../contextApi/ContextState";
import { BeatLoader } from "react-spinners";
import Error from "../error/Error";
import {
  FaClock,
  FaEye,
  FaFileInvoiceDollar,
  FaPaperclip,
  FaPlane,
} from "react-icons/fa";

const EachVisitOutlet = () => {
  const { fetchApi, openSnackbar } = useContext(ContextState);

  const color = {
    primary: "#f7f7f9", // website background
    secondary: "#666bff", // blue color in dashboard
    green: "#82ca9d", // green color in dashboard
    red: "#ff4d49d9", // red color in dashboard
    navBorderBottom: "#4c4e6422",
    taxtHeadingLight: "#4c4e6499", // normal text heading light color
    taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
    tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
    iconBackgroundBlue: "#666bff39",
    iconBackgroundWhite: "#ffffffe6",
    iconBackgroundgreen: "#82ca9d39",
    iconBackgroundred: "#ff4d4939",
    textWhiteColor: "#ffffff",
    textDescBackgroundRegionDiv: "#5b61e5",
    locationTextHeading: "#4c4e64fc", //location heeading color
    imgBackgroundColor: "#4f4f4f33",
    skeletonBgColor: "#e9e9e9",
  };

  const isSmallSmallScreen = useMediaQuery("(max-width:415px)");
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  const islageScreen = useMediaQuery("(max-width:1400px)");
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const { visitId } = useParams();
  // console.log(visitId);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [activeButton, setActiveButton] = useState(null);

  const options = [
    { label: "Overview", value: "overview", icon: <FaEye /> },
    { label: "Travels", value: "traveldetail", icon: <FaPlane /> },
    { label: "Attachment", value: "attachment", icon: <FaPaperclip /> },
    { label: "Timeline", value: "timeline", icon: <FaClock /> },
    // { label: "Bill", value: "bill", icon: <FaFileInvoiceDollar /> },
  ];

  // Set the active button based on the initial URL path
  useEffect(() => {
    const path = location.pathname;
    if (path.endsWith(`/FCI/allvisits/${visitId}/traveldetail`)) {
      setActiveButton("traveldetail");
    } else if (path.endsWith(`/FCI/allvisits/${visitId}/attachment`)) {
      setActiveButton("attachment");
    } else if (path.endsWith(`/FCI/allvisits/${visitId}/timeline`)) {
      setActiveButton("timeline");
    } else if (path.endsWith(`/FCI/allvisits/${visitId}/bill`)) {
      setActiveButton("bill");
    } else {
      setActiveButton("overview"); // Default to overview
    }
  }, [location.pathname, visitId]);

  const handleButtonClick = (value) => {
    setActiveButton(value);
    if (value === "overview") {
      navigate(`/FCI/allvisits/${visitId}`);
    } else {
      navigate(`/FCI/allvisits/${visitId}/${value}`);
    }
  };
  // data for all 3 pages
  const [pagesData, setPagesData] = useState({
    overview: null,
    travel: null,
    attachments: null,
    timeline: null,
    bill: null,
  });

  // Function to set data in states
  const handlePagesData = (data, type) => {
    setPagesData((prev) => ({
      ...prev,
      [type]: data,
    }));
  };

  // Variable to store filters data
  const [backendFilterData, setBackendFilterData] = useState({
    support_list: [],
    hardware_list: {},
    machines_list: [],
    support_name_list: [],
    machine_location_list: [],
    hardware_relaced_list: [],
    filters_data: [],
  });

  // fetching data for visit filters
  const addVisitFiltersData = async (ticketId) => {
    if (backendFilterData?.machines_list?.length === 0) {
      try {
        const payload = {
          ticket_id: ticketId,
        };

        const jsonData = await fetchApi(
          "get_visit_filters",
          "POST",
          payload,
          true
        );

        if (jsonData?.statusCode === 200 && jsonData && jsonData?.data) {
          setBackendFilterData((prevState) => ({
            ...prevState,
            support_list: jsonData?.data?.support_list,
            hardware_list: jsonData?.data?.hardware_list,
            machines_list: jsonData?.data?.machines_list,
            support_name_list: jsonData?.data?.support_list.map(
              (element, index) => {
                return element.name;
              }
            ),
            machine_location_list: jsonData?.data?.machines_list.map(
              (element, index) => {
                return element.location;
              }
            ),
            hardware_relaced_list: Object.values(jsonData?.data?.hardware_list),
            filters_data: jsonData?.data?.filters_data,
          }));
        } else {
          openSnackbar("Some error occured while adding filters!", "error");
        }
      } catch {
        openSnackbar("Some error occured while fetching filters!", "error");
      }
    }
  };

  return (
    <>
      <div className="container">
        <div
          className="tag-options style-scrollbar"
          style={{ overflowX: "auto", marginTop: "20px" }}
        >
          {options.map((option, index) => (
            <div className="tag-option-button" key={index}>
              <button
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleButtonClick(option.value);
                }}
                className={activeButton === option.value ? "active" : ""}
              >
                <span className="icon">{option.icon}</span>
                <span className="label">{option.label}</span>
              </button>
            </div>
          ))}
        </div>

        <Outlet
          context={{
            color,
            isMediumScreen,
            isSmallScreen,
            isSmallSmallScreen,
            islageScreen,
            islagelargeScreen,
            visitId,
            pagesData,
            handlePagesData,
            backendFilterData,
            addVisitFiltersData,
          }}
        />
      </div>
    </>
  );
};

export default EachVisitOutlet;
