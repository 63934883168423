import React, { useState } from "react";
import { IoMdDownload } from "react-icons/io";

const ShowPreview = ({
  showModal,
  handleClosePreview,
  sheetData,
  previewData,
  isSmallScreen,
  color,
}) => {
  const styles = {
    thumbnail: {
      maxHeight: "150px",
      cursor: "pointer",
      // border: "1px solid #ccc",
      borderRadius: "4px",
      transition: "transform 0.2s ease",
    },
    overlay: {
      position: "fixed",
      top: isSmallScreen ? "65px" : "0",
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: previewData.type === "excel" ? "left" : "center",
      alignItems: previewData.type === "excel" ? "start" : "center",
      rowGap: "10px",
      flexDirection: "column",
      zIndex: 1000,
      overflow: "auto",
    },
    closeBtn: {
      position: "absolute",
      top: "10px",
      right: "0px",
    },
    previewContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fullViewImage: {
      width: "100%",
      height: "auto",
      border: "5px solid white",
      borderRadius: "8px",
      transformOrigin: "center", // Ensures the image scales from the center
    },
    controls: {
      position: "fixed",
      zIndex: 10,
      bottom: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex",
      gap: "10px",
      overflow: "auto",
    },
    controlsButton: {
      background: color.taxtHeading,
      color: color.textWhiteColor,
      borderRadius: "4px",
      padding: "0px 14px ",
      textAlign: "center",
      fontSize: "30px",
    },
    closeBtn: {
      color: "white",
      fontSize: "20px",
      fontWeight: "normal",
      cursor: "pointer",
    },
    previewHeader: {
      // border: "1px solid white",
      color: color.textWhiteColor,
      fontSize: "0.95rem",
      fontFamily: "Open-Sans-Light",
      fontWeight: 400,
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      whiteSpace: "normal", // Keeps the text on a single line
      overflow: "hidden", // Hides the overflowed text
      textOverflow: "ellipsis", // Adds the ellipsis (...) when the text overflows
    },

    excelTable: {
      borderCollapse: "collapse",
      width: "100%",
      backgroundColor: color.textWhiteColor,
      color: color.tableRowHeading,
      overflow: "auto",
      // margin:"-20px auto",
    },
    excelCell: {
      border: "1px solid #ccc",
      padding: "8px",
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      textAlign: "center",
      textWrap: "nowrap",
    },
  };
  const [scale, setScale] = useState(1);
  const handleZoomIn = () => setScale((prevScale) => prevScale * 1.2);
  const handleZoomOut = () => setScale((prevScale) => prevScale / 1.2);

  return (
    <div>
      {showModal &&
        (previewData.type === "img" || previewData.type === "pdf" || previewData.type === "audio") && (
          <div style={styles.overlay}>
            <div
              style={{
                width: "97%",
                zIndex: 10,
                backgroundColor: color.taxtHeading,
                padding: "6px 15px",
                margin: "10px",
                borderRadius: "5px",
                position: "fixed",
                top: isSmallScreen ? "65px" : "0px",
              }}
            >
              <div className="d-flex flex-row gap-2 align-items-center justify-content-center">
                <div className="flex-grow-1" style={styles.previewHeader}>
                  {previewData.fileName} Preview
                </div>
                {/* download */}
                <a
                  style={{ ...styles.closeBtn, fontSize: "18px" }}
                  href={previewData.fileUrl}
                  download={`${previewData.fileName}`}
                >
                  {/* {attachment?.filename} ({attachment.stage}) */}
                  <IoMdDownload />
                </a>
                <span
                  style={{ ...styles.closeBtn, marginTop: "2px" }}
                  onClick={() => handleClosePreview()}
                >
                  &#10005;
                </span>
              </div>
            </div>
            <div
              className="flex-grow-1 w-100 d-flex align-items-center justify-content-center "
              style={{ marginTop: "40px" }}
            >
              {previewData.type === "img" && (
                <div className="d-flex flex-column row-gap-2 align-items-center">
                  <div style={styles.controls}>
                    <button
                      onClick={handleZoomIn}
                      style={styles.controlsButton}
                    >
                      +
                    </button>
                    <button
                      onClick={handleZoomOut}
                      style={styles.controlsButton}
                    >
                      -
                    </button>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "visible",
                    }}
                  >
                    <img
                      src={previewData?.fileUrl}
                      alt={previewData.fileName}
                      // style={styles.fullViewImage}
                      style={{
                        ...styles.fullViewImage,
                        transform: `scale(${scale})`,
                      }}
                    />
                  </div>
                </div>
              )}
              {previewData.type === "pdf" && (
                <embed
                  src={previewData.fileUrl}
                  type="application/pdf"
                  width="95%"
                  height="95%"
                />
              )}
              {previewData.type === "audio" && (
                <audio controls style={{ width: isSmallScreen ? "90%" : "50%" }}>
                  <source src={previewData.fileUrl} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              )}
            </div>
          </div>
        )}

      {sheetData && (
        <div style={styles.overlay}>
          <div
            style={{
              width: "97%",
              zIndex: 10,
              backgroundColor: color.taxtHeading,
              padding: "6px 15px",
              margin: "10px",
              borderRadius: "5px",
              position: "fixed",
              top: isSmallScreen ? "65px" : "0px",
            }}
          >
            <div className="d-flex flex-row gap-2 align-items-center justify-content-center">
              <div className="flex-grow-1" style={styles.previewHeader}>
                {previewData.fileName} Preview
              </div>
              {/* download */}
              <a
                style={{ ...styles.closeBtn, fontSize: "18px" }}
                href={previewData.fileUrl}
                download={`${previewData.fileName}`}
              >
                {/* {attachment?.filename} ({attachment.stage}) */}
                <IoMdDownload />
              </a>
              <span
                style={{ ...styles.closeBtn, marginTop: "2px" }}
                onClick={() => handleClosePreview()}
              >
                &#10005;
              </span>
            </div>
          </div>
          <div style={{ marginTop: "70px", marginLeft: "10px" }}>
            <table style={styles.excelTable}>
              <tbody>
                {sheetData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} style={styles.excelCell}>
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowPreview;
