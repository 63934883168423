import React, { useContext, useEffect, useState } from "react";
import Calender from "../../../assets/images/calender.jpg";
import running from "../../../assets/images/running.svg";
import Person from "../../../assets/images/person.jpg";
import attcahments_icons from "../../.././assets/images/paperclip.svg";
import rupees_icon from "../../../assets/images/currency-rupee.svg";
import parts_replaced_icons from "../../../assets/images/gear-fill.svg";
import remarks_icon from "../../../assets/images/chat-dots.svg";
import "../../../App.css";
import "../../../assets/styles/CardModal.css";
import ContextState from "../../contextApi/ContextState";
import { Tooltip } from "@mui/material";
import { TbFileInvoice } from "react-icons/tb";
import { RiUserReceived2Line } from "react-icons/ri";
import { IoLocationOutline } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";
import productName from "../../../assets/images/productName.png";
import seller from "../../../assets/images/seller_3.svg";
const ShowLot = (props) => {
  const {
    invoiceNumber,
    lotId,
    productCount,
    receivedBy,
    sellerAddress,
    sellerName,
    totalAmount,
    arrivalDate,
    creationDate,
    productList,
    handleDeleteModal,
  } = props;
  const { formatInIndianRupees } = useContext(ContextState);

  const navigate = useNavigate();
  // console.log("from sghow visits-->", visit_id);
  // state for visit Modals
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setModalIsOpen(false);
  };

  return (
    <div className="card">
      <div
        className="card-body py-3 px-3"
        onClick={() => {
          navigate(`${lotId}`);
        }}
      >
        <div className="position-relative d-flex flex-row align-items-center">
          <h3 className="ticketid  fs-4 mb-0 ">
            <svg className="ticket-icon" viewBox="0 0 576 512">
              <path
                fill="#362b2b"
                d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"
              />
            </svg>
            {/* {lot ID} */}
            {lotId}
          </h3>

          <div className="dropdown ">
            {/* <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              > */}
            <Tooltip
              data-html="true"
              title={"Click here to delete this lot detail"}
              arrow={true}
              placement="right"
            >
              <i
                className="fa-solid fa-ellipsis-vertical "
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ padding: "0px 3px" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></i>
            </Tooltip>
            {/* Centered dropdown */}
            {/* </button> */}
            <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
              <li>
                <a
                  className="dropdown-item text-center delete-ticket-btn"
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteModal(true, lotId);
                  }}
                >
                  Delete Lot
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Invoice Number*/}
        <div className="d-flex align-items-center error_type  pb-0 mb-1 ">
          <p className=" ps-0 mb-0">Invocie </p>
          {invoiceNumber && (
            <p className="mb-0">
              {/* <span>
                <i className="bi bi-dot"></i>
              </span> */}
              <span className="ms-1"> #{invoiceNumber}</span>
            </p>
          )}
        </div>

        {/* Receoived by */}
        <div className="lower-part">
          {receivedBy && (
            <div className="d-flex align-items-center mb-2">
              <img className="imported_img " src={Person} alt="e-mail" />
              <p className=" generated_by  ps-2">{receivedBy}</p>
            </div>
          )}

          {/* item Count and total amount */}
          <div className="d-flex mb-2">
            {/* seller name */}
            <div className="d-flex align-items-center me-3">
              <img className="imported_img " src={seller} alt="" />
              <p className=" generated_by ps-2">{sellerName}</p>
            </div>

            {/* Products Count */}
            <div className="d-flex align-items-center me-3">
              <img
                className="imported_img "
                src={parts_replaced_icons}
                alt=""
              />
              <p className=" generated_by  ps-2">
                {productCount
                  ? `${formatInIndianRupees(productCount)}`
                  : "Not Mentioned"}
              </p>
            </div>

            {/* Total Amount */}
            <div className="d-flex align-items-center me-3">
              <img className="imported_img " src={rupees_icon} alt="" />
              <p className=" generated_by">
                {totalAmount
                  ? formatInIndianRupees(totalAmount)
                  : "Not Mentioned"}
              </p>
            </div>
          </div>

          {/* Products names */}
          {productList && (
            <div className="d-flex align-items-start  mb-2">
              <img
                className="imported_img"
                // key={"parts_replaced_icons"}
                src={productName}
                alt="parts_icon"
              />
              {/* <i className="bi bi-card-text imported_img"></i> */}
              <div
                className="d-flex flex-row  ps-2 error_description generated_by"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {productList.length > 0 ? (
                  productList.map((parts, index) => (
                    <p key={index} className={`m-0  text-nowrap`}>
                      <React.Fragment key={index}>
                        {index > 0 && <i className="bi bi-dot"></i>}
                        {parts}
                      </React.Fragment>
                    </p>
                  ))
                ) : (
                  <p className="mb-0 ms-2">Not Mentioned</p>
                )}
              </div>
            </div>
          )}

          {/* seller name
          {sellerName && (
            <div className="d-flex align-items-center me-3 mb-2">
              <img className="imported_img " src={seller} alt="e-mail" />
              <p className=" generated_by  ps-2">{sellerName}</p>
            </div>
          )} */}

          {/* arrival date && creation date */}
          <div className="d-flex flex-wrap align-items-center  ">
            <div className="d-flex me-auto flex-row ">
              <img className="imported_img " src={Calender} alt="" />
              {/* <i className="bi bi-calendar3 imported_img"></i> */}
              <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                <span className="">{arrivalDate.slice(0, -12)}</span>
                <label className="text-start" style={{ marginTop: "2px" }}>
                  Arrival Date
                </label>
              </p>
            </div>

            {/* creation date */}

            {creationDate && (
              <div className="d-flex  flex-row justify-content-end">
                <img className="imported_img " src={Calender} alt="" />
                {/* <i className="bi bi-calendar3 imported_img"></i> */}
                <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                  <span className="">{creationDate.slice(0, -12)}</span>
                  <label className="text-start" style={{ marginTop: "2px" }}>
                    creationDate
                  </label>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowLot;
