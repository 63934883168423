import React, { useContext, useEffect } from "react";
import "../../assets/styles/ModalError.css";
import { useNavigate } from "react-router-dom";
import ContextState from "../contextApi/ContextState";
const ModalError = ({ code, msg, modalHeight }) => {
  const navigate = useNavigate();
  const { showModalError, showModalErrorPage } = useContext(ContextState);
  const showHome = () => {
    showModalErrorPage(null, null);
    navigate("/");
  };
  const goToLogin = () => {
    showModalErrorPage(null, null);
    localStorage.clear();
    // navigate("/");
    window.location.reload("/");
  };

  useEffect(() => {
    document.title = "Error - NEO";

    return () => {
      document.title = "NEO";
    };
  }, []);

  // console.log(
  //   "showModalError.status----------->",
  //   code,
  //   showModalError,
  //   showModalError.status
  // );
  return (
    <>
      <div className="error-div">
        <div
          className="d-flex align-items-center justify-content-center "
          style={{ height: modalHeight || "100vh" }}
        >
          <div className="text-center">
            <h1 className="display-1 fw-bold">
              {code || showModalError.status}
            </h1>
            {(code === 404 || showModalError.status === 404) && (
              <>
                <p className="fs-3">
                  <span className="text-danger">Opps!</span> Page not found.
                </p>
                <p className="lead">
                  {showModalError.msg ||
                    "The page you’re looking for doesn’t exist."}
                </p>
                {/* <a onClick={showHome} className="btn btn-primary mt-5">
                  Go To Home
                </a> */}
              </>
            )}
            {(code === 500 || showModalError.status === 500) && (
              <>
                <p className="fs-3">Internal server error 👨🏻‍💻</p>
                <p className="lead">
                  {msg || showModalError.msg || "Oops, something went wrong!"}
                </p>
                {/* <a onClick={goToLogin} className="btn btn-primary mt-5">
                  Login
                </a> */}
              </>
            )}
            {(code === 401 || showModalError.status === 401) && (
              <>
                <p className="fs-3">You are not authorized! 🔐</p>
                <p className="lead">
                  {showModalError.msg ||
                    "You don′t have permission to access this page. Go Home!"}
                </p>
                {/* <a onClick={showHome} className="btn btn-primary mt-5">
                  Go To Home
                </a> */}
              </>
            )}
            {((!showModalError.status && !code) ||
              (code && ![500, 401, 404].includes(code))) && (
              <>
                <p className="lead">
                  {showModalError.msg ||
                    "You don′t have permission to access this page. Go Home!"}
                </p>
                {/* <a onClick={showHome} className="btn btn-primary mt-5">
                  Go To Home
                </a> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalError;
