import React, { useContext, useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import "../../assets/styles/CardModal.css";
import { BeatLoader } from "react-spinners";
import { useOutletContext, useParams } from "react-router-dom";
import { useMediaQuery, Typography, Skeleton } from "@mui/material";
import { RiAttachmentLine } from "react-icons/ri";
import { IoMdArrowDropleft } from "react-icons/io";
import HistoryIcon from "@mui/icons-material/History";
import ContextState from "../contextApi/ContextState";
import Error from "../error/Error";
import Placeholder from "../error/Placeholder";
import { TimelineSkeleton } from "../Exporters/ExportFunctions";
import ModalError from "../error/ModalError";

const EachVisitTimeline = () => {
  const {
    color,
    isMediumScreen,
    isSmallScreen,
    isSmallSmallScreen,
    islageScreen,
    islagelargeScreen,
    visitId,
    pagesData,
    handlePagesData,
  } = useOutletContext();

  const {
    url,
    formatDate,
    fetchApi,
    openSnackbar,
    showError,
    showErrorPage,
    controllerRef,
  } = useContext(ContextState);

  // this is for loading the page
  const [loadingVisiblity, setLoadingVisiblity] = useState(true);

  // resize observer
  const [tableWidthDiv, setTableWidthDiv] = useState(0);

  useEffect(() => {
    let resizeObserver;

    const waitForElement = () => {
      const tableWidthDivElement = document.querySelector(".tableWidthDiv");
      if (tableWidthDivElement) {
        // Create a ResizeObserver and observe the element
        resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setTableWidthDiv(entry.contentRect.width);
            // console.log("Width:", entry.contentRect.width);
          }
        });
        resizeObserver.observe(tableWidthDivElement);
      } else {
        // Retry finding the element after 100ms
        setTimeout(waitForElement, 100);
      }
    };

    // Start the element check
    waitForElement();

    // Cleanup function
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect(); // Stop observing
      }
    };
  }, []);

  // console.log("timeline data", pagesData?.timeline);
  const fetchCardData = async () => {
    try {
      if (pagesData?.timeline) {
        return;
      }

      let payload = {
        visit_id: visitId,
      };

      const jsonData = await fetchApi(
        "get_visit_timeline",
        "POST",
        payload,
        true,
        1
      );
      if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
        showErrorPage(null, null);
        handlePagesData(jsonData?.data?.timeline, "timeline");
      } else if (jsonData.statusCode === 500 || jsonData.statusCode === 401) {
        showErrorPage(jsonData.statusCode, jsonData.msg);
      } else {
        // showErrorPage(jsonData.statusCode, jsonData.msg);
        showErrorPage(jsonData.statusCode, jsonData.msg);
      }
    } catch (error) {
      if (controllerRef.current) {
        // openSnackbar("Request Timed Out!!!", "warning");
        console.log("fetch abort in timeline ... ");
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setLoadingVisiblity(false);
    }
  };
  useEffect(() => {
    showErrorPage(null, null);
    document.title = "Visit TImeline - NEO";
    setLoadingVisiblity(true);
    fetchCardData();
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  return (
    <>
      {(loadingVisiblity && (
        // {lodingVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "88vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <TimelineSkeleton
          color={color}
          isSmallSmallScreen={isSmallSmallScreen}
        />
      )) ||
        (!showError.status &&
          !loadingVisiblity &&
          pagesData?.timeline?.length > 0 && (
            <div
              className="card-Div-1 tableWidthDiv mx-1 px-1"
              style={{ minHeight: "15vh", marginBottom: "30px" }}
            >
              <Timeline
                sx={{
                  // [`& .${timelineOppositeContentClasses.root}`]: {
                  //   flex: 1, // Adjust spacing for better layout
                  // },
                  "& .MuiTimelineItem-root": {
                    margin: "1px 0px",
                  },
                  "& .MuiTimeline-root": {
                    padding: isSmallSmallScreen ? "6px 3px" : "6px 16px",
                  },
                  "& .MuiTimelineContent-root": {
                    padding: isSmallSmallScreen ? "6px 10px" : "6px 16px",
                  },
                  "& .MuiTimelineDot-root": {
                    width: "12px", // Bigger dots
                    height: "12px",
                    borderWidth: "2px",
                    // borderColor: "#1976d2", // Border color for dots
                    // borderColor: color.taxtHeadingLight, // Border color for dots
                    boxShadow: "0px 0px  4px 0px " + color.taxtHeadingLight,
                  },
                  "& .MuiTimelineConnector-root": {
                    // backgroundColor: "#1976d2", // Line color
                    backgroundColor: color.secondary, // Line color
                    width: "4px", // Thicker lines
                  },
                }}
                position="right"
                className="mt-2"
              >
                {pagesData?.timeline?.map((item, index) => (
                  <TimelineItem key={index} className="timeline-page">
                    <TimelineOppositeContent
                      color="textSecondary"
                      style={{
                        fontFamily: "Open-Sans-regular",
                        color: "rgba(146, 146, 146, 0.88)",
                        // padding: "7px 16px",
                        fontSize: "14px",
                        // padding: "0px 10px",
                        // width:"200px",
                        flex: "0 0 250px",
                        display: (tableWidthDiv < 800 && "none") || "block",
                      }}
                    >
                      {item.creation_time && (
                        <Typography
                          component="span"
                          style={{ fontSize: "14px" }}
                        >
                          {formatDate(item.creation_time)}
                        </Typography>
                      )}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{
                          backgroundColor:
                            item.event_type === "Scheduled"
                              ? "red"
                              : item.event_type === "Validation"
                              ? "#9400D3"
                              : item.event_type === "Completed"
                              ? "green"
                              : color.secondary,
                          // padding: "3px",
                          marginTop: "12px",
                        }}
                      />
                      {/* {index < timelineData.length - 1 && <TimelineConnector />} */}
                      <TimelineConnector
                        style={{
                          backgroundColor:
                            (index === pagesData?.timeline?.length - 1 &&
                              color.textWhiteColor) ||
                            color.secondary,
                        }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      style={{
                        fontFamily: "Open-Sans-regular",
                        color: "rgba(76, 78, 100, 0.88)",
                        fontSize: isSmallSmallScreen ? "14px" : "16px",
                        wordBreak: "break-word",
                        paddingTop: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Open-Sans-Light",
                          fontWeight: 600,
                          fontSize: isSmallSmallScreen ? "14px" : "16px",
                          color: "rgba(76, 78, 100, 1)",
                        }}
                        component="span"
                      >
                        {item.event}
                      </Typography>
                      {item.creation_time && (
                        <Typography
                          color="textSecondary"
                          style={{
                            marginBottom: "10px",
                            fontFamily: "Open-Sans-regular",
                            color: color.taxtHeadingLight,
                            // padding: "6px 8px",
                            fontSize: isSmallSmallScreen ? "13px" : "14px",
                            textAlign: "right",
                            display:
                              (tableWidthDiv >= 800 && "none") || "block",
                          }}
                          component="div"
                        >
                          {formatDate(item.creation_time)}
                        </Typography>
                      )}
                      {/* new Time line  */}
                      {item.subEvent?.length > 0 && (
                        <Typography className="card-Div-1 mt-2">
                          {/* <Typography
                            style={{
                              fontFamily: "Open-Sans-Light",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "rgba(76, 78, 100, 1)",
                            }}
                            component="span"
                          >
                            Events
                          </Typography> */}

                          <Timeline
                            sx={{
                              // [`& .${timelineOppositeContentClasses.root}`]: {
                              //   flex: 0.3, // Adjust spacing for better layout
                              // },
                              display: "flex", // Ensure it's using flexbox
                              flexDirection: "column", // Stack the items vertically
                              alignItems: "flex-start", // Left-align the items
                              "& .MuiTimelineItem-root": {
                                margin: "0px 0px", // Spacing between timeline items
                              },
                              "& .MuiTimelineDot-root": {
                                width: "16px", // Bigger dots
                                height: "16px",
                                borderWidth: "2px",
                                // borderColor: "#1976d2", // Border color for dots
                                borderColor: color.secondary,
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              },
                              "& .MuiTimelineConnector-root": {
                                backgroundColor: color.secondary, // Line color
                                width: "4px", // Thicker lines
                              },
                            }}
                          >
                            {item.subEvent.map((checkpoint, idx) => (
                              <TimelineItem style={{ width: "100%" }}>
                                <TimelineOppositeContent
                                  style={{ display: "none" }}
                                />
                                <TimelineSeparator
                                  style={{ marginRight: "20px" }}
                                >
                                  {/* <TimelineConnector /> */}
                                  <TimelineDot
                                    style={{
                                      backgroundColor: color.primary,
                                      borderColor:
                                        idx === 0
                                          ? color.secondary
                                          : color.secondary,
                                      // padding: "3px",
                                      // marginTop: "12px",
                                    }}
                                  />
                                  <TimelineConnector
                                    style={{
                                      backgroundColor:
                                        item?.checklistData?.length - 1 === idx
                                          ? "white"
                                          : color.secondary,
                                    }}
                                  />
                                </TimelineSeparator>
                                <TimelineContent
                                  className=" position-relative "
                                  style={{
                                    // padding: "8px",
                                    margin: "8px 0px",
                                    marginTop: "10px",
                                    // boxShadow:"rgb(76 78 100 / 20%) 2px 1px 10px 0px",
                                    boxShadow:
                                      "-1px 1px 2px 0px " +
                                      color.taxtHeadingLight.slice(0, -2) +
                                      "22",
                                    borderRadius: "4px",
                                    // borderLeft:"1px solid gray",
                                    // borderBottom:"1px solid gray"
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: isSmallSmallScreen
                                        ? "14px"
                                        : "16px",
                                    }}
                                    component="span"
                                  >
                                    {checkpoint.event}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    className="position-absolute"
                                    style={{
                                      top: "-15px",
                                      left: "-30px",
                                    }}
                                  >
                                    <IoMdArrowDropleft
                                      style={{
                                        fontSize: "50px",
                                        color: color.textWhiteColor,
                                        filter: `drop-shadow(-1px 1px 1px ${
                                          color.taxtHeadingLight.slice(0, -2) +
                                          "44"
                                        })`,
                                      }}
                                    />
                                  </Typography>
                                  <Typography
                                    color="textSecondary"
                                    style={{
                                      fontFamily: "Open-Sans-regular",
                                      color: color.taxtHeadingLight,
                                      // padding: "6px 8px",
                                      fontSize: isSmallSmallScreen
                                        ? "12px"
                                        : "14px",
                                      textAlign: "right",
                                      marginTop: "5px",
                                    }}
                                    component="div"
                                  >
                                    {formatDate(checkpoint.creation_time)}
                                  </Typography>
                                </TimelineContent>
                              </TimelineItem>
                            ))}
                          </Timeline>
                        </Typography>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </div>
          )) ||
        (!showError.status && (
          <Placeholder
            message="Oops! There is no Timeline for this visit."
            icon={<HistoryIcon fontSize="inherit" />}
          />
        )) ||
        (showError.status && <Error pageHeight={"80vh"} />)}
    </>
  );
};
export default EachVisitTimeline;
