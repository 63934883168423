import React, { useContext, useState, useMemo, useEffect } from "react";
import "./SellerModal.css";
import { color } from "../../../modals/Calllog";
import {
  FormControl,
  TextField,
  useMediaQuery,
  Box,
  Tooltip as MuiTooltip,
} from "@mui/material";
import ContextState from "../../../contextApi/ContextState";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import "../../../../assets/styles/CardModal.css";
import { DataGrid } from "@mui/x-data-grid";

import Orders from "../../../../assets/images/orders.png";
import TotalItems from "../../../../assets/images/shop.png";
import TotalAmount from "../../../../assets/images/currency-rupee.svg";
import TotalProductTypes from "../../../../assets/images/TotalProductType.png";
import { formattingString } from "../../../analytics/DataPull";
import { BeatLoader } from "react-spinners";
import ModalError from "../../../error/ModalError";
import {
  CardPagesSkeleton,
  LabelContentSkeleton,
  TableSkeleton,
  TravelPageSkeleton,
} from "../../../Exporters/ExportFunctions";

const SellerGeneralInfo = ({
  sellerId,
  reloadAllSellersData,
  setRefreshLoading,
  refreshBtn,
  setRefreshBtn,
  reloadGeneralPage,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const isMediumScreen = useMediaQuery("(max-width:992px)");

  const {
    fetchApi,
    openSnackbar,
    formatInIndianRupees,
    showModalErrorPage,
    showModalError,
    formatDate,
  } = useContext(ContextState);

  const [Data, setData] = useState(null);
  const [loadingModalVisiblity, setLoadingModalVisiblity] = useState(true);

  const [editedDetails, setEditedDetails] = useState({
    sellerName: "",
    address: "",
    person: "",
    phone: "",
    email: "",
  });

  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  let isMounted;
  useEffect(() => {
    isMounted = true;
    showModalErrorPage(null, null);
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [reloadGeneralPage]);

  const fetchData = async () => {
    try {
      if (refreshBtn) {
        setRefreshLoading(true);
      }
      const payload = { info_type: "", seller_id: sellerId };

      const response = await fetchApi(
        "get_seller_modal_details",
        "POST",
        payload,
        isMounted
      );

      if (response.statusCode === 200) {
        setData(response.data);
      } else {
        setData(null);
        showModalErrorPage(response.statusCode, response.err);
      }
    } catch (error) {
    } finally {
      setRefreshLoading(false);
      setLoadingModalVisiblity(false);
      setRefreshBtn(false);
    }
  };

  const headingIcons = [Orders, TotalAmount, TotalItems, TotalProductTypes];

  const handleChange = (name, e) => {
    setEditedDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const iconPosition = isEdit ? "endIcon" : "startIcon";

  const handleEdit = () => {
    setIsEdit((prev) => !prev);

    setEditedDetails((prev) => ({
      ...prev,
      sellerName: Data.sellerDetails.seller,
      address: Data.sellerDetails.address,
      person: Data.sellerDetails.contacted_person,
      phone: Data.sellerDetails.phone,
      email: Data.sellerDetails["e-mail"],
    }));
  };

  const handleCancel = () => {
    setIsEdit(!isEdit);
    // reset state
    setEditedDetails({
      sellerName: Data.sellerDetails.seller || "",
      address: Data.sellerDetails.address || "",
      person: Data.sellerDetails.person || "",
      phone: Data.sellerDetails.phone || "",
      email: Data.sellerDetails["e-mail"] || "",
    });
    // resetting errors
    setErrors({});
  };

  const validateFields = () => {
    const newErrors = {};
    if (!editedDetails.sellerName.trim())
      newErrors.sellerName = "Seller name is required.";
    if (!editedDetails.person.trim())
      newErrors.person = "Contacted person is required.";
    if (!/^\d{10}$/.test(editedDetails.phone))
      newErrors.phone = "Phone number must be 10 digits.";
    if (editedDetails.email && !/^\S+@\S+\.\S+$/.test(editedDetails.email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!editedDetails.address.trim())
      newErrors.address = "Address is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handelSaveChanges = async () => {
    const isValid = validateFields(); // Use the result of validateFields()

    if (isValid) {
      // Proceed only if fields are valid
      setLoading(true);
      const payload = {
        seller_id: sellerId,
        sellerName: editedDetails.sellerName,
        address: editedDetails.address,
        contactedPerson: editedDetails.person,
        phone: editedDetails.phone,
        email: editedDetails.email,
      };

      try {
        const response = await fetchApi("edit_seller", "POST", payload, true);
        if (response.statusCode === 200) {
          await reloadAllSellersData();

          openSnackbar(response.data.message, "success");
          fetchData();
          handleCancel();
        } else {
          openSnackbar(response.msg, "error");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error saving changes:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const imagesUrls = {
    Laptop:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/dell-inspiron-3000.png",
    Keybord:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
    Mobile:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/samsung-s22.png",
    Monitor:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/apple-iMac-4k.png",
    default:
      "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
  };

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      data.columns.forEach((item, index) => {
        headers.push({
          field: item.field,
          headerName: item.headerName,
          width: item.width,
          // editable: item.editable,
          editable: false,
          sortable: false,
          filterable: false,
          flex: !isSmallScreen && ["product_name"].includes(item.field) ? 1 : 0,

          headerAlign: ![0].includes(index) ? "center" : "left",
          resizable: item.resizable,
          renderHeader: (params) => (
            <MuiTooltip
              title={`${item?.field
                ?.replace(/_/g, " ")
                ?.split(" ")
                .map(
                  (word) =>
                    word?.charAt(0)?.toUpperCase() +
                    word?.slice(1)?.toLowerCase()
                )
                .join(" ")}`}
              arrow
            >
              <span
                style={{
                  paddingLeft: ([0].includes(index) && "10px") || "0px",
                  extAlign: ![0].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  // fontWeight: "600",
                  color: color.tableRowHeading,
                }}
              >
                {params.colDef.headerName}
              </span>
            </MuiTooltip>
          ),
          renderCell: (params) => {
            // console.log("params-->: ", params);
            return (
              <Box
                sx={{
                  // width: !index ? 400 : 150, // Adjust width as needed
                  //   marginLeft: "5px", // Add margin to separate text from progress bar
                  //   fontWeight: "bold", // Customize font weight if needed
                  textAlign: ![0].includes(index) ? "center" : "left",
                  fontFamily: "Open-Sans-Medium",
                  fontWeight:
                    (item.field === "status" && "600") ||
                    (index !== 0 && "500") ||
                    "700",
                  fontSize: item.field === "status" ? "0.785rem" : "1.05rem",
                  color: color.tableRowHeading,
                  textWrap: "nowrap",
                }}
              >
                {
                  <MuiTooltip
                    title={
                      <>
                        <div
                          style={{
                            fontSize: "0.85rem",
                            fontFamily: "Open-Sans-Medium",
                          }}
                        >
                          <div>
                            <strong style={{ textTransform: "capitalize" }}>
                              {params.field?.split("_").join(" ")}
                            </strong>
                            :{" "}
                            <strong
                              style={{
                                textTransform:
                                  (item.field === "status" && "capitalize") ||
                                  "none",
                              }}
                            >
                              {(item.field === "creation_time" &&
                                formatDate(params.value)) ||
                                (item.field === "last_updated" &&
                                  formatDate(params.value)) ||
                                params.value}
                            </strong>
                          </div>
                        </div>
                      </>
                    }
                    arrow
                  >
                    {(item.field === "product_name" && (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding: "0px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform: "none",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          height: "100%",
                        }}
                      >
                        <div className="product_img m-0 p-0 me-2 ">
                          <img
                            src={
                              imagesUrls[params.value] || imagesUrls["default"]
                            }
                            alt="product_img"
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        <div
                          className="product_full_desc d-flex flex-column  p-0 m-0 lh-sm"
                          style={{
                            fontFamily: "Open-Sans-Light",
                            color: color.taxtHeading,
                            fontWeight: 600,
                            width: "100%",
                          }}
                        >
                          <div
                            className="product_name p-0 m-0"
                            // style={{ height: "20px" }}
                          >
                            {params.value[0]}
                          </div>
                          <div
                            className="product_desc p-0 m-0"
                            style={{
                              width: "90%",
                              fontWeight: 400,
                              fontSize: "0.89rem",
                              overflow: "hidden", // Hide overflow
                              textOverflow: "ellipsis", // Show ellipsis
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                          >
                            {params.value[1]}
                          </div>
                        </div>
                      </span>
                    )) || (
                      <span
                        style={{
                          //   border: "2px solid blue",
                          padding:
                            (item.field === "status" && "5px 9px") || "4px 9px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          textTransform:
                            (["status", "reason"].includes(item.field) &&
                              "capitalize") ||
                            "none",
                          width: "100px",
                        }}
                      >
                        {(item.field === "created_date" &&
                          formatDate(params.value, true)) ||
                          (item.field === "updated_date" &&
                            formatDate(params.value, true)) ||
                          (item.field === "price" &&
                            formatInIndianRupees(params.value)) ||
                          params.value}
                      </span>
                    )}
                  </MuiTooltip>
                }
              </Box>
            );
          },
        });
      });
    }

    data.rows.forEach((item) => {
      rows.push({ ...item });
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = useMemo(() => {
    if (Data && Data?.tableData?.rows?.length !== 0) {
      return getFirstPageDataTableDataHeader({
        rows: Data?.tableData?.rows || [],
        columns: Data?.tableData?.columns || [],
      });
    }
    return { TableDataHeaders: [], TabelDataRows: [] };
  }, [
    JSON.stringify(Data?.tableData?.rows),
    JSON.stringify(Data?.tableData?.columns),
    isSmallScreen,
    isMediumScreen,
  ]);

  return (
    <>
      {(loadingModalVisiblity && (
        // <div
        //   className="showSpinner "
        //   style={{ position: "relative", height: "64vh", width: "100%" }}
        // >
        //   <BeatLoader color="#2D97D4" />
        // </div>
        <>
          <div className="card-Div my-4 mx-3">
            <LabelContentSkeleton
              len={isSmallScreen ? 5 : 8}
              labelWidth="30%"
              headerWidth="90%"
              cardHeight={30}
              componentClass="issue-type-row-div"
            />
          </div>
          <div className="mx-3">
            <TravelPageSkeleton
              len={1}
              accordionHeight={isSmallScreen ? 600 : 100}
            />
          </div>
          <TableSkeleton rowsNum={8} colsNum={5} />
        </>
      )) ||
        (!showModalError?.status && Data && (
          <div className="container">
            <div style={{ padding: "10px" }}>
              {/* Displaying seller details */}
              {Data && Data.sellerDetails && (
                <>
                  {" "}
                  <div
                    style={{
                      border: "1px solid #e5e6e8",
                      padding: "15px",
                      borderRadius: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <p style={{ marginBottom: "4px", fontSize: "0.95rem" }}>
                      Seller Details
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: isSmallScreen
                          ? "1fr"
                          : isMediumScreen
                          ? "1fr 1fr"
                          : "repeat(4,1fr)",
                        gap: "10px",
                      }}
                    >
                      {Object.entries(Data.sellerDetails).map(
                        ([key, value], index) => (
                          <div
                            key={key}
                            style={{
                              gridColumnStart: index === 4 ? "1" : undefined,
                              gridColumnEnd: index === 4 ? "-1" : undefined,
                              overflowWrap:
                                index === 4 ? "anywhere" : undefined,
                            }}
                          >
                            <label
                              htmlFor={`outlined-read-only-input-${key}`}
                              className="basic_details_elements_label"
                              style={{ fontSize: "13.5px" }}
                            >
                              {formattingString(key)}
                            </label>
                            <div
                              id={`outlined-read-only-input-${key}`}
                              className=""
                            >
                              {value}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {/* edit seller details */}
                  <div style={{ display: "flex", margin: "20px 0px" }}>
                    <LoadingButton
                      style={{ color: color.secondary }}
                      size="small"
                      onClick={() => {
                        handleEdit();
                      }}
                      variant="outlined"
                    >
                      <span>Edit Seller Details</span>
                    </LoadingButton>
                  </div>
                  {isEdit && (
                    <div
                      style={{
                        padding: "30px 25px 20px",
                        borderRadius: "10px",
                        border: "1px solid #e5e6e8",
                        marginBottom: "20px",
                      }}
                    >
                      {/* <p
              style={{
                marginBottom: "12px",
                padding: "4px",
                fontSize: "0.95rem",
              }}
            >
              Edit Seller Details
            </p> */}
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: isSmallScreen
                            ? "1fr"
                            : "1fr 1fr",
                          gap: "12px",
                        }}
                      >
                        <FormControl fullWidth className="p-1">
                          <TextField
                            size="medium"
                            fullWidth
                            value={editedDetails.sellerName}
                            type="text"
                            name="sellerName"
                            id="outlined-basic"
                            label="Seller"
                            variant="outlined"
                            onChange={(e) => {
                              handleChange(e.target.name, e);
                            }}
                            disabled={!isEdit}
                          />
                          {!!errors.sellerName ? (
                            <div
                              style={{
                                marginTop: "8px",
                                color: "red",
                                fontWeight: 300,
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {errors.sellerName}
                            </div>
                          ) : (
                            <></>
                          )}
                        </FormControl>

                        <FormControl fullWidth className="p-1">
                          <TextField
                            size="medium"
                            fullWidth
                            value={editedDetails.person}
                            type="text"
                            name="person"
                            id="outlined-basic"
                            label="Contacted Person"
                            variant="outlined"
                            disabled={!isEdit}
                            onChange={(e) => {
                              handleChange(e.target.name, e);
                            }}
                          />
                          {!!errors.person ? (
                            <div
                              style={{
                                marginTop: "8px",
                                color: "red",
                                fontWeight: 300,
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {errors.person}
                            </div>
                          ) : (
                            <></>
                          )}
                        </FormControl>

                        <FormControl fullWidth className="p-1">
                          <TextField
                            size="medium"
                            fullWidth
                            value={editedDetails.phone}
                            type="number"
                            name="phone"
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            disabled={!isEdit}
                            onChange={(e) => {
                              handleChange(e.target.name, e);
                            }}
                          />
                          {!!errors.phone ? (
                            <div
                              style={{
                                marginTop: "8px",
                                color: "red",
                                fontWeight: 300,
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {errors.phone}
                            </div>
                          ) : (
                            <></>
                          )}
                        </FormControl>

                        <FormControl fullWidth className="p-1">
                          <TextField
                            size="medium"
                            fullWidth
                            value={editedDetails.email}
                            type="text"
                            name="email"
                            id="outlined-basic"
                            label="E-mail"
                            variant="outlined"
                            disabled={!isEdit}
                            onChange={(e) => {
                              handleChange(e.target.name, e);
                            }}
                          />
                          {!!errors.email ? (
                            <div
                              style={{
                                marginTop: "8px",
                                color: "red",
                                fontWeight: 300,
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {errors.email}
                            </div>
                          ) : (
                            <></>
                          )}
                        </FormControl>

                        <FormControl
                          fullWidth
                          className="p-1"
                          style={{ gridColumnStart: "1", gridColumnEnd: "-1" }}
                        >
                          <TextField
                            size="medium"
                            multiline
                            fullWidth
                            rows={4}
                            value={editedDetails.address}
                            type="text"
                            name="address"
                            id="outlined-multiline-static"
                            label="Address"
                            disabled={!isEdit}
                            onChange={(e) => {
                              handleChange(e.target.name, e);
                            }}
                          />
                          {!!errors.address ? (
                            <div
                              style={{
                                marginTop: "8px",
                                color: "red",
                                fontWeight: 300,
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                              {errors.address}
                            </div>
                          ) : (
                            <></>
                          )}
                        </FormControl>
                      </div>
                      {/* Modal Header */}
                      <div className="d-flex justify-content-end gap-2 mt-3 px-1">
                        {isEdit && (
                          <LoadingButton
                            style={{ color: color.secondary }}
                            size="small"
                            onClick={handleCancel}
                            variant="outlined"
                          >
                            <span>Cancel</span>
                          </LoadingButton>
                        )}

                        <LoadingButton
                          sx={{
                            backgroundColor: color.secondary,
                            "&:hover": {
                              backgroundColor: color.secondary,
                            },
                          }}
                          size="small"
                          onClick={isEdit ? handelSaveChanges : handleEdit}
                          {...{
                            [iconPosition]: isEdit ? (
                              <SendIcon style={{ fontSize: "15px" }} />
                            ) : (
                              <EditIcon style={{ fontSize: "15px" }} />
                            ),
                          }}
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                        >
                          <span
                            className="text-nowrap"
                            style={{
                              fontFamily: "Open-Sans-regular",
                              fontWeight: 600,
                              color: color.textWhiteColor,
                            }}
                          >
                            {isEdit ? "Save Changes" : "Edit"}
                          </span>
                        </LoadingButton>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* top heading */}
              {Data && Data.overview && (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: isSmallScreen
                      ? "1fr"
                      : isMediumScreen
                      ? "1fr 1fr"
                      : "repeat(4,1fr)",
                    border: "1px solid #e5e6e8",
                    // margin: "20px 25px",
                    padding: "18px 15px",
                    borderRadius: "12px",
                    margin: "12px 0px 20px",
                    gap:"10px",
                    rowGap:"5px"
                    // gap: isSmallScreen ? "20px" : isMediumScreen ? "15px" : "",
                  }}
                >
                  {Object?.entries(Data?.overview)?.map(
                    ([key, value], index) => (
                      <div key={index}>
                        <div
                          class="d-flex justify-content-between align-items-start "
                          style={{
                            paddingRight: "10px",
                            borderRight: isSmallScreen
                              ? ""
                              : isMediumScreen && index % 2 === 0
                              ? "1px solid #e5e6e8"
                              : !isMediumScreen &&
                                index !==
                                  Object.entries(Data?.overview).length - 1
                              ? "1px solid #e5e6e8"
                              : "",
                          }}
                        >
                          <div>
                            <p
                              class="mb-1"
                              style={{ color: color.taxtHeadingLight }}
                            >
                              {formattingString(key)}
                            </p>
                            <h4
                              class="mb-1"
                              style={{
                                color: color.taxtHeading,
                                fontWeight: "600",
                              }}
                            >
                              {formatInIndianRupees(value)}
                            </h4>
                          </div>
                          <div class="avatar">
                            <span class="avatar-initial rounded text-heading">
                              <img
                                src={headingIcons[index]}
                                className="seller_card_img"
                              />
                            </span>
                          </div>
                        </div>
                        {isSmallScreen ? (
                          index !== 3 ? (
                            <hr className="d-lg-none opacity-25" />
                          ) : null
                        ) : isMediumScreen ? (
                          index <= 1 ? (
                            <hr
                              className="d-lg-none  opacity-25"
                              style={{
                                marginRight: index === 0 ? "20px" : "",
                                marginLeft: index === 1 ? "20px" : "",
                              }}
                            />
                          ) : null
                        ) : null}
                      </div>
                    )
                  )}

                  {/* table for product count  */}
                </div>
              )}

              {Data && Data.tableData.rows.length > 0 && (
                <div
                  style={{
                    border: "1px solid #e5e6e8",
                    borderRadius: "12px",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      padding: "20px 20px 25px",
                    }}
                  >
                    Products Overview
                  </div>
                  <Box
                    sx={{
                      maxHeight:
                        // (specLotDetails?.tickets_limit === "All" && "55vh") || gridHeight,
                        "60vh",
                    }}
                  >
                    <DataGrid
                      rows={TabelDataRows}
                      columns={TableDataHeaders}
                      rowHeight={62}
                      columnHeaderHeight={62}
                      density="standard"
                      paginationMode="client"
                      disableSelectionOnClick
                      disableRowSelectionOnClick
                      disableColumnFilter
                      sx={{
                        "&.MuiDataGrid-root": {
                          border: "none",
                          // borderRadius: "5px", // Adjust border radius as needed
                          overflow: "hidden", // Ensure content respects the border radius,
                          padding: "0px",
                          margin: "0px",
                          marginTop: "-10px",
                          borderColor: "white",
                        },

                        ".MuiDataGrid-columnHeader": {
                          backgroundColor: "#f5f5f7",
                          border: "none",
                        },
                        ".MuiDataGrid-filler": {
                          backgroundColor: color.textWhiteColor,
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                          fontFamily: "Open-Sans-Light",
                          fontWeight: "bold",
                          padding: "5px 0px",
                          fontSize: "0.85rem",
                          textTransform: "capitalize",
                          color: color.taxtHeading,
                        },
                        ".MuiDataGrid-columnHeader--alignCenter": {
                          headerAlign: "left",
                        },
                        ".MuiDataGrid-row": {
                          border: "none",
                          backgroundColor: color.textWhiteColor,
                          "&:hover": {
                            backgroundColor: color.primary,
                          },
                        },
                        ".MuiDataGrid-footerContainer": {
                          // display: (visibleRows <= 50 && "none") || "inline-block", // Hide the pagination footer
                          justifyContent: "space-between", // Space out selected row count and pagination controls
                          alignItems: "center", // Vertically align items in the footer
                          marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                          height: "30px",
                        },
                        ".MuiTablePagination-root": {
                          // ".MuiInputBase-root": {
                          //   display: "none",
                          // },

                          // ".MuiTablePagination-selectLabel": {
                          //   display: "none",
                          // },
                          ".MuiToolbar-root": {
                            marginTop: "0px",
                          },
                        },
                        ".MuiDataGrid-cell": {
                          // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                          outline: "none",
                          // display: "-webkit-box",
                          // WebkitBoxOrient: "vertical",
                          // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          "&:focus": {
                            outline: "none", // Remove the blue outline when a cell is focused
                          },
                        },
                        "&.selected-row": {
                          backgroundColor: "red", // Highlight color for the selected row
                          "&:hover": {
                            backgroundColor: "red ", // Ensure highlight color remains on hover
                          },
                        },
                        ".MuiDataGrid-selectedRowCount": {
                          display: "none", // Hide the selected row count div
                        },
                        ".MuiTablePagination-toolbar": {
                          // width: "80%",  // Adjust the width here
                          // margin: "0 auto", // Center align the pagination
                          // marginLeft:"auto",
                          padding: "2px 16px", // You can adjust padding as well if needed
                          marginTop: "-15px",
                        },
                        "& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader":
                          {
                            borderBottom: "none",
                          },
                        "& .MuiDataGrid-row--borderBottom .MuiDataGrid-filler":
                          {
                            borderBottom: "none",
                          },
                        "& .MuiDataGrid-row--borderBottom .MuiDataGrid-scrollbarFiller":
                          {
                            borderBottom: "none",
                          },
                        ".MuiDataGrid-columnSeparator--resizable": {
                          display: "none",
                        },
                        ".MuiDataGrid-menuOpen": {
                          visibility: "hidden",
                        },
                        ".MuiDataGrid-menuIcon": {
                          display: "none",
                        },

                        ".MuiTablePagination-selectLabel": {
                          display: "none",
                        },

                        ".MuiTablePagination-input": {
                          display: "none",
                        },
                      }}
                    />
                  </Box>
                </div>
              )}
            </div>
          </div>
        )) ||
        (showModalError?.status && (
          <div style={{ height: "60vh", overflow: "hidden" }}>
            <ModalError />
          </div>
        )) || <ModalError code={500} style={{ height: "55vh" }} />}
    </>
  );
};

export default SellerGeneralInfo;
