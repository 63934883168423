import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
  Tooltip as MuiTooltip,
} from "@mui/material";
import ContextState from "../../contextApi/ContextState";
import { useContext, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { color } from "../../modals/Calllog";
import { AiOutlineDelete } from "react-icons/ai";
import close from "../../../assets/images/close.svg";
import { GrEdit } from "react-icons/gr";
import "../../../assets/styles/AddLotDetailsForm.css";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { Laptop, Monitor } from "@mui/icons-material";
import product_default_img from "../../../../src/assets/images/default_product_hdd_without_background.png";
import test_profile_img from "../../../assets/images/default_product_hdd_without_background.png";
import v39_scanner_img from "../../../assets/images/product/V39.png";
import v600_scanner_img from "../../../assets/images/product/V600.png";
import ardiuno_nano_controller_img from "../../../assets/images/product/ardiuno_nano.png";
import ardiuno_uno_controller_img from "../../../assets/images/product/ardiuno_uno.png";
import nuc_img from "../../../assets/images/product/nuc.png";

export const productTypeImg = {
  controller:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/dell-inspiron-3000.png",
  scanner:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
  mobile:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/samsung-s22.png",
  monitor:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/apple-iMac-4k.png",
  default:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
};

export const DisplayingProductDetailsTable = (props) => {
  const {
    genFields,
    setGenFields,
    isProductsPage,
    isSmallScreen,
    isSerialNumber,
    isReview,
    tableHeader,
  } = props;
  const { formatInIndianRupees, openSnackbar } = useContext(ContextState);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");

  //   state edit product details

  const [editProductDetailsModal, setEditProductDetailsModal] = useState({
    isOpen: false,
    rowId: "",
  });

  // data for product details data grid table
  const columns = [
    { field: "product_type", headerName: "Category", width: 180 },
    { field: "product_name", headerName: "Product Name", width: 200 },

    isSerialNumber
      ? { field: "serial_number", headerName: "Serial Number", width: 180 }
      : { field: "quantity", headerName: "Quantity", width: 140 },
    { field: "price", headerName: "Price", width: 140 },
  ];

  const rows = genFields.productDetails.map((each_row, index) => ({
    id: index + 1,
    product_name: each_row.productType.name,
    serial_number: each_row.serialNumber,
    product_type: each_row.productType.category,
    quantity: each_row.quantity,
    price: each_row.productPrice,
  }));

  const handleDelete = (row, isSerialNumber = false) => {
    // console.log("Deleting row: ", row, isSerialNumber);
    if (isSerialNumber) {
      setGenFields((prev) => {
        return {
          ...prev,
          productDetails: prev.productDetails.map((prod) => {
            if (
              prod.productType.name.toLowerCase() ===
                row.product_name.toLowerCase() &&
              prod.quantity === row.quantity
            ) {
              return {
                ...prod,
                serialNumber: prod.serialNumber.filter(
                  (serial) => serial !== row.serial_number
                ),
              };
            }
            return prod;
          }),
        };
      });
    } else {
      setGenFields((prev) => {
        return {
          ...prev,
          productDetails: prev.productDetails.filter(
            (item, index) => index !== row.id - 1
          ),
        };
      });
    }

    openSnackbar("Successfully deleted from the product list", "success");
  };

  //   closing of edit details modal
  function handleCloseEditDetailsModal(e, reason) {
    if (reason && reason === "backdropClick") {
      return;
    }
    setEditProductDetailsModal((prev) => ({ ...prev, isOpen: false }));
  }

  const getProductImage = (productName) => {
    const lowerCaseName = productName.toLowerCase();

    if (lowerCaseName.includes("v39")) {
      return v39_scanner_img;
    }
    if (lowerCaseName.includes("v600")) {
      return v39_scanner_img; // Consider if this is intentional or a typo
    }
    if (lowerCaseName.includes("nuc")) {
      return nuc_img;
    }
    if (lowerCaseName.includes("ardiuno nano")) {
      return ardiuno_nano_controller_img;
    }
    if (lowerCaseName.includes("ardiuno")) {
      return ardiuno_uno_controller_img;
    }
    return test_profile_img;
  };

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      // First column (Image)
      headers.push({
        field: "expand",
        headerName: "",
        width: 70,
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const productType = params.row.product_type;
          const productName = params.row.product_name;
          return (
            <Box sx={{ textAlign: "center", padding: "8px" }}>
              <Tooltip title={productType} arrow>
                <div
                  style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    className="img-fluid"
                    src={getProductImage(productName)}
                    alt={product_default_img}
                  />
                </div>
              </Tooltip>
            </Box>
          );
        },
      });

      // Add all your other columns (Product Name, Serial Number, etc.)
      data.column.forEach((item, index) => {
        if (!["uuid", "notes"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex: isMediumScreen ? 0 : 1,
            headerAlign: index === 0 ? "left" : "center",
            renderHeader: (params) => (
              <Tooltip title={item.headerName} arrow>
                <span style={{ paddingLeft: index === 0 ? "10px" : "0px" }}>
                  {params.colDef.headerName}
                </span>
              </Tooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              const finalParamValue = ["quantity", "price"].some(
                (items) => item.field === items && params.value !== ""
              )
                ? formatInIndianRupees(params.value)
                : ["quantity"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "1"
                : ["serial_number"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "-"
                : params.value;
              return (
                <Box
                  sx={{
                    textAlign: index !== 0 ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight: (index !== 0 && "500") || "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color: color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <Tooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params.field.split("_").join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {finalParamValue}
                              </strong>
                            </div>
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding: "4px 9px",
                          borderRadius: "4px",
                          textTransform: "none",
                        }}
                      >
                        {finalParamValue}
                      </span>
                    </Tooltip>
                  }
                </Box>
              );
            },
          });
        }
      });

      // Add the "Delete" column at the end
      if (data.isProductsPage) {
        headers.push({
          field: "delete",
          headerName: "Action",
          width: 100,
          editable: false,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          headerAlign: "center",
          renderCell: (params) => {
            return (
              <Box sx={{ textAlign: "center", padding: "8px" }}>
                {/* <Tooltip title="Remove product from list." arrow> */}
                <div
                  style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    margin: "0px auto",
                  }}
                  //   onClick={() => {
                  //     // handleDelete(params.row);
                  //   }}
                >
                  {/* <AiFillDelete style={{ width: "20px", height: "20px" }} /> */}
                  {/* <AiOutlineDelete
                      style={{ width: "20px", height: "20px" }}
                    /> */}

                  {/* action for delete and edit */}

                  {/* <div
                    className="dropdow dropdown_export mx-2"
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip
                      data-html="true"
                      title="Click for Edit && Delete Options"
                      arrow={true}
                    >
                      <i
                        className="fa-solid fa-ellipsis-vertical  "
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ padding: "0px 3px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      ></i>
                    </Tooltip>
                    <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                      <li>
                        <div
                          className="dropDown_btns lot_btn_edit"
                          style={{ borderBottom: "1px solid #eaeaf0" }}
                          onClick={() => {
                            setEditProductDetailsModal((prev) => ({
                              ...prev,
                              isOpen: true,
                              rowId: params.row.id - 1,
                            }));

                            // console.log(params.row);
                          }}
                        >
                          <GrEdit
                            style={{
                              width: "20x",
                              height: "20px",
                              marginRight: "12px",
                              marginLeft: "5px",
                            }}
                          />
                          Edit
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropDown_btns lot_btn_delete"
                          onClick={() => handleDelete(params.row)}
                        >
                          <AiOutlineDelete
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "12px",
                            }}
                          />
                          Delete
                        </div>
                      </li>
                    </ul>
                  </div> */}

                  <Tooltip title="Delete" x>
                    <div
                      className="dropDown_btns lot_btn_delete"
                      onClick={() =>
                        isSerialNumber
                          ? handleDelete(params.row, isSerialNumber)
                          : handleDelete(params.row)
                      }
                    >
                      <AiOutlineDelete
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "0px auto",
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              </Box>
            );
          },
        });
      }
    }

    data.rows.forEach((item, index) => {
      if (isSerialNumber) {
        if (item?.serial_number?.length > 0) {
          item.serial_number.forEach((serial, serialIndex) => {
            rows.push({
              ...item,
              serial_number: serial,
              price: parseInt(item.price) / parseInt(item.quantity),
              // id: `${index + 1}-${serialIndex + 1}`, // Unique ID for each serial number
              id: index + serialIndex, // Unique ID for each serial number
            });
          });
        }
      } else {
        rows.push({ ...item, id: index + 1 });
      }
    });

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = (columns &&
    rows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: rows || [],
      column: columns || [],
      isProductsPage: isProductsPage ? isProductsPage : false,
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  const getProductDetailsHeaders = (TableDataHeaders) => {
    const tableHeaders = TableDataHeaders.filter(
      (item) => item.field !== "expand"
    ).map((item, index) => ({
      id: item.field,
      label: item.headerName,
      align: index ? "center" : "left",
      minWidth: index ? "250" : "90",
      name: item.product_name,
    }));

    // console.log("table Data header ", tableHeader);
    return tableHeaders;
  };

  const getProductDetailsRows = (TableDataRows) => {
    return TableDataRows.map((item) => {
      const imageUrl =
        productTypeImg[item.product_type.toLowerCase()] ||
        productTypeImg["default"];

      if (isSerialNumber) {
        return createData(
          // imageUrl,
          item.product_type,
          item.product_name,
          item.quantity,
          item.price,
          item.serial_number,
          isSerialNumber
        );
      } else {
        return createData(
          // imageUrl,
          item.product_type,
          item.product_name,
          item.quantity,
          item.price,
          null,
          isSerialNumber
        );
      }
    });
  };

  function createData(
    product_type,
    product_name,
    quantity,
    price,
    serial_number,
    isSerialNumber
  ) {
    if (isSerialNumber) {
      return {
        product_type,
        product_name,
        serial_number,
        quantity,
        price,
      };
    } else {
      return { product_type, product_name, quantity, price };
    }
  }

  return (
    <>
      {TabelDataRows.length > 0 && !isReview ? (
        <div className="card-Div">
          <DataGrid
            rows={TabelDataRows}
            columns={TableDataHeaders}
            sx={{
              "&.MuiDataGrid-root": {
                border: "none",
                borderRadius: "5px", // Adjust border radius as needed
                overflow: "hidden", // Ensure content respects the border radius,
                padding: "0px",
                margin: "0px",
                marginTop: "-10px",
              },
              ".MuiDataGrid-columnHeader": {
                backgroundColor: color.textWhiteColor,
                // justifyContent: "center",
              },

              ".MuiDataGrid-columnHeaderTitleContainer": {
                // justifyContent: "center",
              },
              ".MuiDataGrid-filler": {
                backgroundColor: color.textWhiteColor,
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontFamily: "Open-Sans-Light",
                fontWeight: "bold",
                padding: "5px 0px",
                fontSize: "0.85rem",
                textTransform: "capitalize",
                color: color.taxtHeading,
              },
              ".MuiDataGrid-columnHeader--alignCenter": {
                headerAlign: "left",
              },
              ".MuiDataGrid-row": {
                border: "none", // Remove border from rows
                backgroundColor: color.textWhiteColor, // Set rows background color to white
                "&:hover": {
                  backgroundColor: color.primary, // Ensure background color remains white on hover
                  // cursor: "pointer",
                },
              },

              ".MuiDataGrid-cell": {
                outline: "none",
                "&:focus": {
                  outline: "none", // Remove the blue outline when a cell is focused
                },
              },
              // Custom CSS to hide the "Rows per page" component

              ".MuiDataGrid-footerContainer": {
                // display: "none", // Hide the pagination footer
              },
              ".MuiTablePagination-root": {
                ".MuiInputBase-root": {
                  display: "none",
                },
                ".MuiTablePagination-selectLabel": {
                  display: "none",
                },
                // ".MuiToolbar-root": {
                //   marginTop: "12px",
                // },
              },
              "&.selected-row": {
                backgroundColor: "red", // Highlight color for the selected row
                "&:hover": {
                  backgroundColor: "red ", // Ensure highlight color remains on hover
                },
              },
              ".MuiDataGrid-selectedRowCount": {
                display: "none",
              },
            }}
          />

          {/* {editProductDetailsModal && (
          <EditProdutDetails
            editProductDetailsModal={editProductDetailsModal}
            handleClose={handleCloseEditDetailsModal}
            genFields={genFields}
            setGenFields={setGenFields}
            isSmallScreen={isSmallScreen}
          />
        )} */}
        </div>
      ) : null}

      {TabelDataRows.length > 0 && !isProductsPage && isReview && (
        <>
          <div
            className={`static-header-chart-div flex-grow-1 ${
              isSmallScreen ? "w-100" : "w-auto"
            }`}
            style={{ marginTop: "18px" }}
          >
            <div className="card-Div h-100 p-0 ">
              <div className="top-table-div ">
                <div
                  className="bar-chart-heading pt-3 ps-3"
                  style={{
                    color: color.taxtHeading,
                    lineHeight: "1.2",
                    fontSize: "1rem",
                  }}
                >
                  {tableHeader}
                </div>
                {/* <div
                  className="ps-3"
                  style={{
                    color: "#838485",
                    fontSize: "14px",
                    textTransform: "none",
                    marginBottom: "4px",
                  }}
                >
                  Top districts with highest procurement
                </div> */}
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "auto",
                    border: "none",
                    boxShadow: "none",
                    borderRadius: "10px",
                    padding: "8px 0px",
                  }}
                >
                  <TableContainer
                    sx={{
                      overflow: "auto",
                      // border: "2px solid" + color.primary,
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      style={{ overflow: "auto" }}
                    >
                      <TableHead>
                        <TableRow>
                          {getProductDetailsHeaders(TableDataHeaders)?.map(
                            (column, headerIndex) =>
                              column.id !== "percent" && (
                                <TableCell
                                  key={`table-header-${headerIndex}-${headerIndex}`}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    color: color.tableRowHeading,
                                    padding: "7px",
                                    paddingLeft:
                                      headerIndex === 0 ? "72px" : "10px",
                                    paddingRight:
                                      headerIndex ===
                                      TableDataHeaders.length - 1
                                        ? "20px"
                                        : "10px",
                                    fontWeight: 400,
                                    fontSize: "0.9rem",
                                    lineHeight: "1.959rem",
                                    letterSpacing: "0.17px",
                                  }}
                                  className="bar-chart-heading"
                                >
                                  {column.label}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getProductDetailsRows(TabelDataRows)?.map(
                          (row, rowIndex) => (
                            <MuiTooltip
                              key={`table-${rowIndex}`}
                              placement="top"
                              arrow
                            >
                              <TableRow hover role="checkbox" tabIndex={-1}>
                                {getProductDetailsHeaders(
                                  TableDataHeaders
                                )?.map((column, rowDivIndex) => {
                                  // Access the specific value for each column by its ID from the row
                                  const value =
                                    column.id === "product_type" ? (
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={getProductImage(
                                            row["product_name"]
                                          )}
                                          alt={row.product_type}
                                          style={{
                                            width: "35px",
                                            height: "auto",
                                            borderRadius: "4px",
                                          }}
                                        />
                                        <div>{row[column.id]}</div>
                                      </div>
                                    ) : (
                                      row[column.id]
                                    );

                                  return (
                                    <TableCell
                                      key={`table-row-${rowIndex}-${rowDivIndex}`}
                                      align={column.align}
                                      sx={{
                                        minWidth: column.minWidth,
                                        borderBottom: "none",
                                        padding: "10px",
                                        color:
                                          column.label !== "Product Name"
                                            ? color.tableRowHeading
                                            : color.taxtHeading,
                                        paddingLeft:
                                          rowDivIndex === 0 ? "20px" : "10px",
                                        paddingRight:
                                          rowDivIndex ===
                                          TableDataHeaders.length - 1
                                            ? "20px"
                                            : "10px",
                                        fontWeight:
                                          rowDivIndex === 0 ? 600 : 500,
                                        fontSize: "1rem",
                                        lineHeight: 1.429,
                                        letterSpacing: "0.15px",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </MuiTooltip>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const EditProdutDetails = (props) => {
  const {
    editProductDetailsModal,
    handleClose,
    genFields,
    setGenFields,
    isSmallScreen,
  } = props;

  const [editProductDetails, setEditProductDetails] = useState({
    productCategory: "",
    serialNumber: "",
    quantity: "",
    productType: "",
    productPrice: "",
    isSerialNumber: "",
  });

  const [editProductDetailsErrors, setEditProductDetailsErrors] = useState({
    productNameError: false,
    serialNumberError: false,
    quantityError: false,
    productTypeError: false,
    productPriceError: false,
    productSerialNumberError: false,
    isSerialNumberMatched: false,
  });

  useEffect(() => {
    if (editProductDetailsModal.isOpen) {
      const productDetails = genFields.productDetails.find(
        (_, index) => index === editProductDetailsModal?.rowId
      );

      if (productDetails) {
        setEditProductDetails({
          productCategory: productDetails.productCategory,
          serialNumber: productDetails.serialNumber,
          quantity: productDetails.quantity,
          productType: productDetails.productType,
          productPrice: productDetails.productPrice,
          isSerialNumber: productDetails.serialNumber !== "" ? true : false,
        });
      }
    }
  }, [
    editProductDetailsModal.isOpen,
    editProductDetailsModal.rowId,
    genFields.productDetails,
  ]);

  useEffect(() => {
    if (editProductDetails.productCategory !== "") {
      setEditProductDetailsErrors((prev) => ({
        ...prev,
        productNameError: false,
      }));
    }

    if (editProductDetails.serialNumber !== "") {
      setEditProductDetailsErrors((prev) => ({
        ...prev,
        serialNumberError: false,
      }));
    }

    if (editProductDetails.quantity !== "") {
      setEditProductDetailsErrors((prev) => ({
        ...prev,
        quantityError: false,
      }));
    }
    if (editProductDetails.productType !== "") {
      setEditProductDetailsErrors((prev) => ({
        ...prev,
        productTypeError: false,
      }));
    }
    if (editProductDetails.productPrice !== "") {
      setEditProductDetailsErrors((prev) => ({
        ...prev,
        productPriceError: false,
      }));
    }

    // if (editProductDetails.isSerialNumber !== "") {
    //   const productDetails = genFields.productDetails.find(
    //     (_, index) => index === editProductDetailsModal?.rowId
    //   );
    //   if (productDetails) {
    //     setEditProductDetailsErrors((prev) => ({
    //       ...prev,
    //       isSerialNumberMatched: false,
    //     }));
    //   }
    // }
  }, [editProductDetails]);

  const handleSaveChanges = () => {
    // Reset serial number match error initially
    setEditProductDetailsErrors((prev) => ({
      ...prev,
      isSerialNumberMatched: false,
    }));

    // Validation (based on isSerialNumber)
    let hasError = false;
    let newErrors = {};

    // Check if serial number should be validated
    if (editProductDetails.isSerialNumber) {
      // Check for duplicate serial number
      const serialNumberExists = genFields.productDetails.some(
        (product, index) =>
          product.serialNumber === editProductDetails.serialNumber
      );

      // If serial number exists, set error
      if (serialNumberExists) {
        setEditProductDetailsErrors((prev) => ({
          ...prev,
          isSerialNumberMatched: true,
        }));
        return; // Exit early as serial number is duplicated
      }

      newErrors = {
        productNameError: !editProductDetails.productCategory,
        serialNumberError: !editProductDetails.serialNumber,
        productTypeError: !editProductDetails.productType,
        productPriceError: !editProductDetails.productPrice,
      };
    } else {
      newErrors = {
        productNameError: !editProductDetails.productCategory,
        quantityError: !editProductDetails.quantity,
        productTypeError: !editProductDetails.productType,
        productPriceError: !editProductDetails.productPrice,
      };
    }

    setEditProductDetailsErrors(newErrors);
    hasError = Object.values(newErrors).some((error) => error);

    if (hasError) {
      return; // Exit if there are validation errors
    }

    // Clone genFields.productDetails to update the entry at the specific rowId
    const updatedProductDetails = [...genFields.productDetails];
    updatedProductDetails[editProductDetailsModal.rowId] = {
      productCategory: editProductDetails.productCategory,
      serialNumber: editProductDetails.serialNumber,
      quantity: editProductDetails.quantity,
      productType: editProductDetails.productType,
      productPrice: editProductDetails.productPrice,
    };

    // Save the updated details back to genFields
    setGenFields((prevGenFields) => ({
      ...prevGenFields,
      productDetails: updatedProductDetails,
    }));

    // Close the modal and reset the form
    handleClose();
    resetForm();
  };

  const resetForm = () => {
    setEditProductDetails({
      productCategory: "",
      serialNumber: "",
      quantity: "",
      productType: "",
      productPrice: "",
      isSerialNumber: "",
    });
    setEditProductDetailsErrors({
      productNameError: false,
      serialNumberError: false,
      quantityError: false,
      productTypeError: false,
      productPriceError: false,
    });
  };

  return (
    <>
      <Modal
        open={editProductDetailsModal.isOpen}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
          margin: "0px 0px 0px 10px",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "16%",
            left: "0",
            margin: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "1200px",
            height: "auto",
          }}
        >
          {/* Modal Header starts*/}
          <div
            style={{
              padding: "16px",
              borderBottom: "1px solid #f5f5f5",
            }}
          >
            <div className="d-flex justify-content-between">
              <h5 className="m-0">Edit Product Details</h5>
              <div
                onClick={() => {
                  handleClose();
                }}
              >
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {/* Modal Header ends*/}

          {/* Modal body starts */}
          <Box className=" m-1 mt-0 p-3 pt-0">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* Form Fields for Editing */}
              {/* product name &&  quantity*/}
              <div
                style={{
                  display: "flex",
                  flexDirection: isSmallScreen ? "column" : "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "10px",
                  gap: 25,
                }}
              >
                <FormControl
                  fullWidth
                  error={editProductDetailsErrors.productNameError}
                >
                  <TextField
                    value={editProductDetails.productCategory}
                    fullWidth
                    id="outlined-basic"
                    label="Product"
                    variant="outlined"
                    onChange={(e) => {
                      setEditProductDetails((prev) => ({
                        ...prev,
                        productCategory: e.target.value,
                      }));
                    }}
                  />
                  {editProductDetailsErrors.productNameError && (
                    <div
                      style={{
                        marginTop: "8px",
                        color: "red",
                        fontWeight: 300,
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Please enter product name.
                    </div>
                  )}
                </FormControl>

                {!editProductDetails.isSerialNumber ? (
                  <FormControl
                    fullWidth
                    error={editProductDetailsErrors.quantityError}
                  >
                    <TextField
                      value={editProductDetails.quantity}
                      fullWidth
                      type="number"
                      id="outlined-basic"
                      label="Quantity"
                      variant="outlined"
                      onChange={(e) => {
                        setEditProductDetails((prev) => ({
                          ...prev,
                          quantity: e.target.value,
                        }));
                      }}
                    />
                    {editProductDetailsErrors.quantityError && (
                      <div
                        style={{
                          marginTop: "8px",
                          color: "red",
                          fontWeight: 300,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        Please enter quantity.
                      </div>
                    )}
                  </FormControl>
                ) : (
                  <FormControl
                    fullWidth
                    error={editProductDetailsErrors.productSerialNumberError}
                  >
                    <TextField
                      value={editProductDetails.serialNumber}
                      fullWidth
                      id="outlined-basic"
                      label="Serial Number"
                      variant="outlined"
                      onChange={(e) => {
                        setEditProductDetails((prev) => ({
                          ...prev,
                          serialNumber: e.target.value,
                        }));
                      }}
                    />
                    {editProductDetailsErrors.productSerialNumberError ? (
                      <div
                        style={{
                          marginTop: "8px",
                          color: "red",
                          fontWeight: 300,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        Please Enter Serial Number.
                      </div>
                    ) : editProductDetailsErrors.isSerialNumberMatched ? (
                      <div
                        style={{
                          marginTop: "8px",
                          color: "red",
                          fontWeight: 300,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        Serial number has already been entered into the list.
                      </div>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                )}
              </div>
              {/* Product price&& product type */}
              <div
                style={{
                  display: "flex",
                  flexDirection: isSmallScreen ? "column" : "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "25px",
                  gap: 25,
                }}
              >
                <FormControl
                  fullWidth
                  error={editProductDetailsErrors.productPriceError}
                >
                  <TextField
                    value={editProductDetails.productPrice}
                    fullWidth
                    type="number"
                    id="outlined-basic"
                    label="Product Price"
                    variant="outlined"
                    onChange={(e) => {
                      setEditProductDetails((prev) => ({
                        ...prev,
                        productPrice: e.target.value,
                      }));
                    }}
                  />
                  {editProductDetailsErrors.productPriceError && (
                    <div
                      style={{
                        marginTop: "8px",
                        color: "red",
                        fontWeight: 300,
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Please enter product price.
                    </div>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Product Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editProductDetails.productType}
                    label="Product Type"
                    onChange={(e) => {
                      setEditProductDetails((prev) => ({
                        ...prev,
                        productType: e.target.value,
                      }));
                    }}
                  >
                    {[].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>

                  {editProductDetailsErrors.productTypeError && (
                    <div
                      style={{
                        marginTop: "8px",
                        color: "red",
                        fontWeight: 300,
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Please select product type.
                    </div>
                  )}
                </FormControl>
              </div>
            </div>
            {/* modal footer starts */}
            <div className="d-flex justify-content-end mt-3">
              <LoadingButton
                size="medium"
                onClick={handleSaveChanges} // Save changes handler
                endIcon={<SendIcon />}
                // loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </div>
            {/* modal footer ends */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
