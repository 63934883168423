import adrino_nano from "../../assets/images/product/ardiuno_nano.png";

import nuc from "../../assets/images/product/nuc.png";
import V39 from "../../assets/images/product/V39.png";
import V600 from "../../assets/images/product/V600.png";

import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const handleClick = (e, path, targetPath, navigate) => {
  // console.log("1", e, "2", path, "3", targetPath, "4", navigate);
  if (e.ctrlKey || e.metaKey) {
    // Open in a new tab if Ctrl or Cmd key is pressed
    window.open(targetPath, "_blank");
  } else {
    // Prevent full page reload and navigate within SPA
    e.stopPropagation();
    e.preventDefault();
    navigate(`${path}`);
  }
};
// Function to get badge class based on status and start date
export function getBadgeClass(curr_status) {
  // Return appropriate badge class based on status and days difference
  switch (curr_status) {
    case "Scheduled":
      return "badge bg-warning-subtle text-warning-emphasis";
    case "Ongoing":
      return "badge bg-primary-subtle text-primary-emphasis";
    case "Validation":
      return "badge bg-info-subtle text-info-emphasis"; 
    case "Completed":
      return "badge bg-success-subtle text-success-emphasis";
    default:
      // Grey as fallback for unknown statuses
      return "badge bg-secondary-subtle text-secondary-emphasis";
  }
}

// function to get time diff in days with text
export function formatVisitCompletionMessage(
  curr_status,
  startDate,
  endDate = false
) {
  // first convert the dates to GMT +5:30 to match with js date which is in +5:30 GMT
  startDate = startDate?.replace("GMT", "GMT+0530");
  if (endDate) {
    endDate = endDate?.replace("GMT", "GMT+0530");
  }
  // Parse the dates as UTC
  const parseDateUTC = (dateStr) => new Date(dateStr);

  const currentDateUTC = new Date(); // Current UTC time
  const startDateUTC = parseDateUTC(startDate); // Start date in UTC

  let diffTime;
  if (endDate) {
    const endDateUTC = parseDateUTC(endDate); // End date in UTC
    diffTime = endDateUTC - startDateUTC; // Time difference in milliseconds
  } else {
    diffTime = currentDateUTC - startDateUTC; // Time difference in milliseconds
  }

  // Calculate time units
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
  const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000);

  // Determine the message based on the status and date difference
  switch (curr_status) {
    case "Scheduled":
      if (diffDays === 0) {
        return "Scheduled for today";
      } else if (diffDays === 1) {
        return "Scheduled for yesterday";
      } else if (diffDays > 1) {
        return `Scheduled ${diffDays} days ago`;
      } else if (diffDays === -1) {
        return "Scheduled for tomorrow";
      } else {
        return `Scheduled in ${Math.abs(diffDays)} days`;
      }

    case "Ongoing":
      if (diffDays > 0) {
        return `Started ${diffDays} days ago`;
      } else if (diffHours > 0) {
        return `Started ${diffHours} hours ago`;
      } else if (diffMinutes > 0) {
        return `Started ${diffMinutes} minutes ago`;
      } else {
        return `Started ${diffSeconds} seconds ago`;
      }

    case "Validation":
      if (diffDays > 0) {
        return `Under validation for ${diffDays} days`;
      } else if (diffHours > 0) {
        return `Under validation for ${diffHours} hours`;
      } else if (diffMinutes > 0) {
        return `Under validation for ${diffMinutes} minutes`;
      } else {
        return `Under validation for ${diffSeconds} seconds`;
      }

    case "Completed":
      if (diffDays === 0) {
        return "Closed on the same day";
      } else {
        return `Closed in ${diffDays} days`;
      }

    default:
      return "Unknown status";
  }
}

export const urlToFileArrayBuffer = async (fileUrl) => {
  try {
    // Fetch the file content as a blob
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // Read the blob as an array buffer
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
      reader.readAsArrayBuffer(blob);
    });
  } catch (error) {
    console.error("Error fetching file:", error);
    return null;
  }
};

// verify a correct email
export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// get support person list
export const get_support_persons_list = (persons_list, assignee_list) => {
  let updated_persons_list = [];
  for (let index = 0; index < persons_list?.length; index++) {
    let coming_name = persons_list[index];
    for (let index1 = 0; index1 < assignee_list?.length; index1++) {
      const element = assignee_list[index1];
      const name = element["name"];
      const id = element["id"];
      if (name === coming_name) {
        updated_persons_list.push(id);
      }
    }
  }
  return updated_persons_list;
};

export const get_parts_replaced_list = (check_parts, hardware_list) => {
  const ids = [];
  for (const key in hardware_list) {
    if (Object.prototype.hasOwnProperty.call(hardware_list, key)) {
      const value = hardware_list[key];
      if (check_parts.includes(value)) {
        ids.push(parseInt(key));
      }
    }
  }
  return ids;
};

// skeleton components
export const OverviewPageSkeleton = ({
  isSmallSmallScreen,
  color = { skeletonBgColor: "#e9e9e9" },
}) => {
  return (
    <Grid container spacing={2} sx={{ padding: "0px" }}>
      {/* Left Column - col-9 */}
      <Grid item xs={12} md={9}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              animation="wave"
              sx={{
                width: "100%",
                height: 250, // Adjust height for big cards
                borderRadius: "8px",
                backgroundColor: color?.skeletonBgColor,
              }}
            />
          ))}
        </Box>
      </Grid>

      {/* Right Column - col-3 */}
      {!isSmallSmallScreen && (
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginBottom: "12px",
            }}
          >
            <Skeleton
              key={1}
              variant="rectangular"
              animation="wave"
              sx={{
                width: "100%",
                height: 120, // Adjust height for big cards
                borderRadius: "8px",
                backgroundColor: color?.skeletonBgColor,
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Skeleton
              key={2}
              variant="rectangular"
              animation="wave"
              sx={{
                width: "100%",
                height: 320, // Adjust height for big cards
                borderRadius: "8px",
                backgroundColor: color?.skeletonBgColor,
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export const TravelPageSkeleton = ({
  len,
  color = { skeletonBgColor: "#e9e9e9" },
  accordionHeight = 60,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px", // Space between each accordion
        width: "100%", // Full width for the skeletons
        padding: "0px",
      }}
    >
      {Array.from({ length: len }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          animation="wave"
          sx={{
            width: "100%",
            height: accordionHeight, // Adjust height as needed for your accordions
            borderRadius: "8px", // Optional: rounded corners
            backgroundColor: color?.skeletonBgColor,
          }}
        />
      ))}
    </Box>
  );
};

export const AttachmentSkeleton = ({
  color = { skeletonBgColor: "#e9e9e9" },
  len = 5,
  isSmallSmallScreen,
  isSmallScreen,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* Attachment Heading */}
      <Typography variant="h3" sx={{ marginBottom: "0px" }}>
        <Skeleton
          animation="wave"
          width={isSmallSmallScreen ? "60%" : "15%"}
          sx={{
            backgroundColor: color?.skeletonBgColor,
          }}
        />
      </Typography>

      {/* Subheading */}
      {/* <Typography
        variant="h5"
        sx={{
          marginBottom: "0px",
        }}
      >
        <Skeleton
          width={isSmallSmallScreen ? "80%" : "30%"}
          animation="wave"
          sx={{
            backgroundColor: color?.skeletonBgColor,
          }}
        />
      </Typography> */}

      {/* Attachment Boxes */}
      <Box
        className=""
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "flex-start",
          marginTop: "16px",
        }}
      >
        {/* Skeleton for small rectangular cards */}
        {Array.from({ length: len }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={isSmallScreen ? "95%" : "30%"}
            height={80} // Adjust height as needed for small rectangular cards
            animation="wave" // Ensures the animation effect
            sx={{
              borderRadius: "8px",
              backgroundColor: color?.skeletonBgColor,
            }} // Optional: add rounded corners for aesthetic
          />
        ))}
      </Box>
    </Box>
  );
};

export const TimelineSkeleton = ({
  isSmallSmallScreen,
  color = { skeletonBgColor: "#e9e9e9" },
}) => {
  return (
    <div style={{ padding: "0px" }}>
      {[...Array(5)].map((_, index) => (
        <div key={index} style={{ marginBottom: "16px" }}>
          <Typography variant="h5">
            <Skeleton
              animation="wave"
              width={isSmallSmallScreen ? "60%" : "30%"}
              sx={{
                backgroundColor: color?.skeletonBgColor,
              }}
            />
          </Typography>
          <Typography variant="h3">
            <Skeleton
              animation="wave"
              width={"99%"}
              sx={{
                backgroundColor: color?.skeletonBgColor,
              }}
            />
          </Typography>
          <Typography variant="h6">
            <Skeleton
              animation="wave"
              width="40%"
              sx={{
                backgroundColor: color?.skeletonBgColor,
              }}
            />
          </Typography>
        </div>
      ))}
    </div>
  );
};

export const CardPagesSkeleton = ({
  len,
  cardHeight = 150,
  color = { skeletonBgColor: "#e9e9e9" },
  componentClass = "cards-parent",
}) => {
  return (
    <div className={`${componentClass} px-2 py-2`}>
      {/* Skeleton for rectangular cards */}
      {Array.from({ length: len }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={cardHeight}
          animation="wave" // Ensures the animation effect
          sx={{
            borderRadius: "8px",
            backgroundColor: color?.skeletonBgColor,
          }}
        />
      ))}
    </div>
  );
};

export const ButtonSkeletons = ({
  height = 50,
  width = 200,
  color = { skeletonBgColor: "#e9e9e9" },
  len = 1,
}) => {
  return (
    <div
      className="p-2"
      style={{
        display: "flex",
        gap: "15px",
      }}
    >
      {Array.from({ length: len }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={height}
          width={width}
          animation="wave"
          sx={{
            borderRadius: "8px",
            backgroundColor: color?.skeletonBgColor,
          }}
        />
      ))}
    </div>
  );
};

export const LabelContentSkeleton = ({
  len,
  labelWidth = "40%", // Width of the label skeleton
  headerWidth = "80%", // Width of the header skeleton
  cardHeight = 150, // Height of the card skeleton
  color = { skeletonBgColor: "#e9e9e9" },
  componentClass = "skeleton-parent", // Class for the parent container
}) => {
  return (
    <div className={`${componentClass} px-2 py-2`}>
      {/* Skeleton for labels and headers */}
      {Array.from({ length: len }).map((_, index) => (
        <div
          key={index}
          className="skeleton-item"
          style={{ marginBottom: "16px" }} // Spacing between skeleton items
        >
          {/* Label Skeleton */}
          <Skeleton
            variant="text"
            animation="wave"
            width={labelWidth}
            sx={{
              backgroundColor: color?.skeletonBgColor,
              marginBottom: "8px", // Spacing between label and header
            }}
          />
          {/* Header Skeleton */}
          <Skeleton
            variant="rectangular"
            height={cardHeight}
            animation="wave"
            width={headerWidth}
            sx={{
              borderRadius: "8px",
              backgroundColor: color?.skeletonBgColor,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export const TableSkeleton = ({
  rowsNum = 5,
  colsNum = 15,
  color = { skeletonBgColor: "#e9e9e9" },
}) => {
  return (
    <TableContainer>
      <Table>
        {/* Table Header */}
        <TableHead>
          <TableRow>
            {[...Array(colsNum)].map((_, index) => (
              <TableCell key={`header-${index}`}>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* Table Body */}
        <TableBody>
          {[...Array(rowsNum)].map((_, rowIndex) => (
            <TableRow key={`row-${rowIndex}`}>
              {[...Array(colsNum)].map((_, colIndex) => (
                <TableCell key={`row-${rowIndex}-col-${colIndex}`}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{
                      backgroundColor: color?.skeletonBgColor,
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// lots page skletion starts here
export const HeadingSkeletion = ({
  height,
  color = { skeletonBgColor: "#e9e9e9" },
  width,
}) => {
  return (
    <>
      <div className="upper-heading-container p-0 px-2 pt-2">
        <div className="info-part">
          <Skeleton
            animation="wave"
            width={width || "50px"}
            height={`${height}px`}
            sx={{
              backgroundColor: color?.skeletonBgColor,
            }}
          />
        </div>

        <div className="d-flex flex-row ms-auto align-items-center display-inline w-100">
          <div className="show-range ms-auto" style={{ alignSelf: "end" }}>
            {" "}
            <Skeleton
              animation="wave"
              width={"250px"}
              height={`${height}px`}
              sx={{
                backgroundColor: color?.skeletonBgColor,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const LotGeneralPageInfoSkeletion = ({
  height,
  color = { skeletonBgColor: "#e9e9e9" },
  isMediumScreen,
  len,
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: isMediumScreen ? "1fr" : "2fr 1fr",
        gap: "15px",
      }}
    >
      {Array.from({ length: len }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={
            (index === 0 && height - 100) ||
            (index == 1 && height - 150) ||
            height
          }
          animation="wave" // Ensures the animation effect
          sx={{
            borderRadius: "8px",
            backgroundColor: color?.skeletonBgColor,
          }}
        />
      ))}
    </div>
  );
};

export const productTypeImg = {
  controller:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/dell-inspiron-3000.png",
  scanner:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
  mobile:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/samsung-s22.png",
  monitor:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/apple-iMac-4k.png",
  default:
    "https://demos.pixinvent.com/materialize-html-admin-template/assets/img/products/sony-play-station-5.png",
};
