// export default ShowTicket;

import React, { useEffect, useState, useMemo } from "react";
import CardDetailModal from "./modals/CardDetailModal";
import Calender from "../assets/images/calender.jpg";
import Description from "../assets/images/description.png";
import Info from "../assets/images/info.png";
import Tooltip from "@mui/material/Tooltip";
import Person from "../assets/images/person.jpg";
import "../App.css";
import VisitDetailModal from "./visits/VisitDetailModal";

function ShowTicket({
  ticketId,
  status,
  machines,
  description,
  generatedBy,
  createdDate,
  issueType,
  resolvedDate,
  subIssueType,
  internetIssue,
  getTimeSring,
  hoursDifference,
  resolvedTimeString,
  resolvedHoursDifference,
  reloadData,
  handelDeleteModal,
  liveMachinesData,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = (event, reason) => {
    // when click on outside of modal the modal is not closing....
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setModalIsOpen(false);
  };

  const getTimeinLocalMinutes = (last_online) => {
    const currentTime = new Date();
    const inputDate = last_online;
    const TicketCeratedTime = new Date(inputDate);
    // Calculate the difference in milliseconds
    const timeDifferenceInMilliseconds = currentTime - TicketCeratedTime;
    let CreatedTime = "Last Online: ";

    // if (support_state !== 'done') {
    const minutesDifference = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );

    if (timeDifferenceInMilliseconds < 0) {
      CreatedTime += 0 + " minutes ago";
    } else {
      if (minutesDifference < 60) {
        CreatedTime += minutesDifference + " minutes ago";
      } else {
        const hoursDifference = Math.floor(minutesDifference / 60);

        if (hoursDifference < 24) {
          CreatedTime += hoursDifference + " hours ago";
        } else {
          const daysDifference = Math.floor(hoursDifference / 24);

          if (daysDifference < 7) {
            CreatedTime += daysDifference + " days ago";
          } else {
            const weeksDifference = Math.floor(daysDifference / 7);

            if (weeksDifference < 4) {
              CreatedTime += weeksDifference + " weeks ago";
            } else {
              const monthsDifference = Math.floor(daysDifference / 30);

              if (monthsDifference < 12) {
                CreatedTime += monthsDifference + " months ago";
              } else {
                const yearsDifference = Math.floor(daysDifference / 365);
                CreatedTime += yearsDifference + " years ago";
              }
            }
          }
        }
      }
    }
    return CreatedTime;
  };

  // Memoized processing of machine data
  const processedMachines = useMemo(() => {
    return machines.map((machine) => {
      const machineData = liveMachinesData ? liveMachinesData[machine] : null;

      // Memoized machine data values
      const status =
        machineData && machineData.status ? machineData.status : "";
      const last_online =
        machineData && machineData.last_online
          ? getTimeinLocalMinutes(new Date(machineData.last_online))
          : "";
      const style =
        status === "online"
          ? { backgroundColor: "#41835B", color: "white" }
          : {};

      // Full last_online date string for tooltip
      const lastOnlineTooltip =
        machineData && machineData.last_online
          ? `(${new Date(machineData.last_online).toDateString()} ${new Date(
              machineData.last_online
            ).toLocaleTimeString()})`
          : "";

      return {
        machine,
        status,
        last_online,
        style,
        lastOnlineTooltip,
      };
    });
  }, [liveMachinesData]); // Recompute only when liveMachinesData change

  // console.log("component redered again");

  // const { hoursDifference, getTimeSring } = getTimeSring;
  // console.log(hoursDifference,getTimeSring)

  return (
    <div className="card">
      <div className="card-body py-2 px-3" onClick={openModal}>
        <div className="position-relative d-flex flex-row align-items-center">
          <h3 className="ticketid  fs-4 mb-0 ">
            <svg
              className="ticket-icon"
              xmlns="http://www.w3.org/2000/svg"
              height="10"
              width="11.25"
              viewBox="0 0 576 512"
            >
              <path
                fill="#362b2b"
                d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"
              />
            </svg>
            {ticketId}
          </h3>
          <span
            className={`position-absolute d-flex align-items-center top-0 end-0 fs-6 ${
              status.toLowerCase() !== "resolved"
                ? "card-tid-status-heading"
                : ""
            }`}
          >
            <div
              className={`${status.toLowerCase()}-dot ${status.toLowerCase()}-priority m-0 p-0 `}
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                display: "inline-block",
                marginTop: "5px",
                marginRight: "10px",
                backgroundColor:
                  status.toLowerCase() === "pending"
                    ? "red"
                    : status.toLowerCase() === "resolved"
                    ? "green"
                    : status.toLowerCase() === "validation"
                    ? "darkviolet"
                    : "blue",
              }}
            ></div>
            <p
              className={`m-0 p-0 ps-1`}
              style={{
                fontFamily: "Open-Sans-Medium",
                color:
                  status.toLowerCase() === "pending"
                    ? "red"
                    : status.toLowerCase() === "resolved"
                    ? "green"
                    : status.toLowerCase() === "validation"
                    ? "darkviolet"
                    : "blue",
              }}
            >
              {status.toLowerCase()}
            </p>
          </span>
          {status.toLowerCase() !== "resolved" ? (
            <div className="delete-dropdown-visiblity">
              <div className="dropdown ">
                {/* <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              > */}
                <Tooltip
                  data-html="true"
                  title={<span>Click here to delete this card</span>}
                  arrow={true}
                  placement="right"
                >
                  <i
                    className="fa-solid fa-ellipsis-vertical "
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ padding: "0px 3px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  ></i>
                </Tooltip>
                {/* Centered dropdown */}
                {/* </button> */}
                <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                  <li>
                    <a
                      className="dropdown-item text-center delete-ticket-btn"
                      href="#"
                      onClick={(e) => {
                        e.stopPropagation();
                        handelDeleteModal(true, ticketId);
                      }}
                    >
                      Delete Card
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
        </div>

        {/* <div className="d-flex gap-2 machine_no my-2 mt-1">
          {machines.map((machine, index) => {
            const status =
              liveMachinesData &&
              liveMachinesData[machine] &&
              liveMachinesData[machine]["status"]
                ? liveMachinesData[machine]["status"]
                : "";
            const last_online =
              liveMachinesData &&
              liveMachinesData[machine] &&
              liveMachinesData[machine]["last_online"]
                ? getTimeinLocalMinutes(
                    new Date(liveMachinesData[machine]["last_online"])
                  )
                : "";
            const style =
              status === "online"
                ? { backgroundColor: "#41835B", color: "white" }
                : {};

            return (
              <p
                key={index}
                className={`machine_btns ${index > 3 ? "d-none" : ""}`}
                style={style}
              >
                <Tooltip
                  className="tooltip-inner"
                  data-html="true"
                  arrow={true}
                  title={
                    <span style={{ fontSize: "11px" }}>
                      {last_online}
                      <br />
                      {liveMachinesData &&
                      liveMachinesData[machine] &&
                      liveMachinesData[machine]["last_online"]
                        ? `(${new Date(
                            liveMachinesData[machine]["last_online"]
                          ).toDateString()} 
                    ${new Date(
                      liveMachinesData[machine]["last_online"]
                    ).toLocaleTimeString()})`
                        : ""}
                    </span>
                  }
                >
                  {machine}
                </Tooltip>
              </p>
            );
          })}
          {machines.length > 4 && (
            <p className="machine_btns">+{machines.length - 4}</p>
          )}
        </div> */}

        <div className="d-flex gap-2 machine_no my-2 mt-1">
          {/* Render the first 4 machines */}
          {processedMachines.slice(0, 4).map((processedMachine, index) => (
            <p
              key={index}
              className="machine_btns"
              style={processedMachine.style}
            >
              <Tooltip
                className="tooltip-inner"
                data-html="true"
                arrow={true}
                title={
                  <span style={{ fontSize: "11px" }}>
                    {processedMachine.last_online}
                    <br />
                    {processedMachine.lastOnlineTooltip}
                  </span>
                }
              >
                {processedMachine.machine}
              </Tooltip>
            </p>
          ))}

          {/* Render the '+X' if more than 4 machines */}
          {machines.length > 4 && (
            <p className="machine_btns">+{machines.length - 4}</p>
          )}
        </div>

        <div className="d-flex align-items-center error_type  mt-3 pb-0 mb-0">
          {/* <img className="imported_img " src={Info} alt="" /> */}
          <p className=" ps-0">{issueType}</p>
          {subIssueType && (
            <p>
              <span>
                <i className="bi bi-dot"></i>
              </span>
              {subIssueType}
            </p>
          )}
        </div>

        <div className="lower-part">
          <div className="d-flex align-items-center mb-2">
            <img className="imported_img " src={Person} alt="" />
            {/* <i className="bi bi-person-fill imported_img"></i> */}
            <p className=" generated_by  ps-2">{generatedBy}</p>
          </div>

          <div className="d-flex align-items-center  mb-2">
            <img className="imported_img " src={Description} alt="" />
            {/* <i className="bi bi-card-text imported_img"></i> */}
            <p className="error_description ps-2">{description}</p>
          </div>
          <div className="d-flex flex-wrap align-items-center  ">
            <div className="d-flex me-auto flex-row ">
              <img className="imported_img " src={Calender} alt="" />
              {/* <i className="bi bi-calendar3 imported_img"></i> */}
              <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                <span className="">{createdDate}</span>
                <label className="text-start">Created Date</label>
              </p>
            </div>
            {resolvedDate && (
              <div className="d-flex  flex-row justify-content-end">
                <img className="imported_img " src={Calender} alt="" />
                {/* <i className="bi bi-calendar3 imported_img"></i> */}
                <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                  <span className="">{resolvedDate}</span>
                  <label className="text-start">Resolved Date</label>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="underline1 pt-2"></div>

        <div className="d-flex bottom-content pt-2 w-100 ">
          {/* <p className="m-0 p-0  more-details-class me-auto"> */}
          {/* More Details &gt;&gt; */}
          {/* <span>{getTimeSring}</span> */}
          {/* </p> */}
          {/* <span className="badge bg-danger-subtle text-danger-emphasis rounded-pill me-auto">
            {getTimeSring} 
          </span> */}
          {(hoursDifference > 48 && status?.toLowerCase() !== "resolved" && (
            <span className="badge bg-danger-subtle text-danger-emphasis-red rounded-pill me-auto">
              {getTimeSring}
            </span>
          )) ||
            (hoursDifference > 24 && status?.toLowerCase() !== "resolved" && (
              <span className="badge bg-danger-subtle-orange text-danger-emphasis-orange rounded-pill me-auto">
                {getTimeSring}
              </span>
            )) ||
            (status?.toLowerCase() !== "resolved" && (
              <span className="badge bg-warning-subtle text-warning-emphasis rounded-pill me-auto">
                {getTimeSring}
              </span>
            )) ||
            (status?.toLowerCase() === "resolved" && (
              // ((resolvedHoursDifference > 48 && (
              //   <span className="badge bg-danger-subtle text-danger-emphasis-red rounded-pill me-auto">
              //     {resolvedTimeString}
              //   </span>
              // )) ||
              //   (resolvedHoursDifference > 24 && (
              //     <span className="badge bg-danger-subtle-orange text-danger-emphasis-orange  rounded-pill me-auto">
              //       {resolvedTimeString}
              //     </span>
              //   )) || (
              <span className="badge bg-success-subtle text-success-emphasis rounded-pill me-auto">
                {resolvedTimeString}
              </span>
            )) || (
              // ))
              <p className="m-0 p-0  more-details-class me-auto">
                <span>More Details &gt;&gt;</span>
              </p>
            )}
          {internetIssue && (
            <span className=" ms-auto badge bg-primary-subtle text-primary-emphasis rounded-pill">
              Internet Issue
            </span>
          )}
        </div>
      </div>

      {/* Render the modal */}
      {/* <div className="individualdatamodal"> */}
      {modalIsOpen && <CardDetailModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        data={{
          ticketId,
          status,
        }}
        reloadData={reloadData}
      />}

      {/* <VisitDetailModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        data={{
          ticketId,
          status,
        }}
        reloadData={reloadData}
      /> */}
    </div>
  );
}

export default ShowTicket;
