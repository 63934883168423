import React, {
  useContext,
  useState,
  lazy,
  Suspense,
  useEffect,
  useRef,
} from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { BsDot } from "react-icons/bs";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TbDeviceAnalytics } from "react-icons/tb";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Box,
  duration,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DirectionsRunOutlinedIcon from "@mui/icons-material/DirectionsRunOutlined";
import HomeIcon from "@mui/icons-material/Home";
import { IoMdHome } from "react-icons/io";
import { HomeMax } from "@mui/icons-material";
import "../App.css";
import nebulaa_logo from "../assets/images/logo.png";
import { AiFillHome } from "react-icons/ai";
import { FaTicketAlt } from "react-icons/fa";
import { FaPersonWalking } from "react-icons/fa6";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { AiOutlineHome } from "react-icons/ai";
import { GrTicket } from "react-icons/gr";

import useMediaQuery from "@mui/material/useMediaQuery";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// routes import
import { BeatLoader } from "react-spinners";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import Error from "./error/Error";
import AllTickets from "./AllTickets";
import AddNewModel from "./Miscellaneous/AddNewModel";
import AllVisits from "./AllVisits";
import Login from "./auth/Login";
import UserDashboard from "./User/UserDashboard";
import Signup from "./auth/Signup";
import Navigation from "./navigation/Navigation";
import ContextState from "./contextApi/ContextState";
import ForgotPassword from "./auth/ForgotPassword";
// import RaiseTicketWrapper from "./MultipageForm/RaiseTicketWrapper";
import RaiseTicketForm from "./RaiseTicketForm/RaiseTicketForm";
import UserProfile from "./User/UserProfile";
import Navbar from "./Navbar";
import ShowTicket from "./ShowTicket";

// const LineChartAnalysis = lazy(() => import("./User/LineChartAnalysis"));
// const TableFormTicketData = lazy(() => import("./User/TableFormTicketData"));
// const IssueWiseTableView = lazy(() => import("./User/IssueWiseTableView"));

import VisitLineChartAnalysis from "./User/Vists_Analytics/VisitLineChartAnalysis";
import LocationWiseVisitData from "./User/Vists_Analytics/LocationWiseVisitData";
import VisitsUserDashboard from "./User/Vists_Analytics/VisitsUserDashboard";
import DetailedVisitData from "./User/Vists_Analytics/DetailedVisitData";
import LineChartAnalysis from "./User/LineChartAnalysis";
import TableFormTicketData from "./User/TableFormTicketData";
import IssueWiseTableView from "./User/IssueWiseTableView";

import { BiBorderRadius, BiTrip } from "react-icons/bi";
import {
  MdMiscellaneousServices,
  MdOutlineInventory2,
  MdOutlinePersonOutline,
} from "react-icons/md";
// dash board
import FirstPage from "./User/Dashboard/FirstPage";
import Location from "./User/Dashboard/Location";
import Overview from "./User/Dashboard/Overview";
import Auth from "./utils/Auth";

// Role based rendering
import authData from "./utils/auth.json";
import { useDispatch, useSelector } from "react-redux";
import {
  addOnlineStatus,
  addUserDetail,
} from "./features/filtersSlice/filterSlice";
import CropOverview from "./User/Dashboard/CropOverview";
import Analytics from "./analytics/Analytics";
import ScanidDetailedAnalytics from "./analytics/ScanidDetailedAnalytics";
import DataPull from "./analytics/DataPull";
import Attendance from "./Attendence/Attendance";
import {
  IoPersonCircleSharp,
  IoPersonSharp,
  IoSparkles,
} from "react-icons/io5";
import { RiCalendarCheckFill } from "react-icons/ri";
import PunchRecords from "./Attendence/PunchRecords";
import ManualAttendance from "./Attendence/ManualAttendance";
import UpdateMachine from "./Miscellaneous/UpdateMachine";
import ModelDetails from "./Miscellaneous/ModelDetails";
import Clients from "./User/Dashboard/Clients";
import AddLotDetailsForm from "./Inventory/AddLotDetailsForm";
import AllLots from "../Components/Inventory/AllLots";
import AllProducts from "./Inventory/AllProducts";
import SpecificProductType from "./Inventory/Product/SpecificProductType";
import ProductType from "./Inventory/Product/ProductType";
import ProductDetails from "./Inventory/Product/ProductDetails";
import ProductTypeDetails from "./Inventory/Product/ProductTypeDetails";
import AllLotsDetails from "./Inventory/LotDetails/AllLotsDetails";
import SpecificLot from "./Inventory/LotDetails/SpecificLot";
import VisitOutlet from "./visits/VisitOutlet";
import EachVisitOutlet from "./visits/EachVisitOutlet";
import EachVisitOverview from "./visits/EachVisitOverview";
import EachVisitTravel from "./visits/EachVisitTravel";
import EachVisitAttachment from "./visits/EachVisitAttachment";
import EachVisitTimeline from "./visits/EachVisitTimeline";
import ProductGeneralContent from "./Inventory/Product/ProductDeatilsComponents/ProductGeneralContent";
import ProductAttachment from "./Inventory/Product/ProductDeatilsComponents/ProductAttchment";
import ProductTimeline from "./Inventory/Product/ProductDeatilsComponents/ProductTimeline";
import ProductComments from "./Inventory/Product/ProductDeatilsComponents/ProductComments";
import LotGeneralContent from "./Inventory/LotDetails/LotDetailsComponents/LotGeneralContent";
import AllSellers from "./Inventory/AllSellers";
import LotAttachments from "../Components/Inventory/LotDetails/LotDetailsComponents/LotAttachments";
import EachVisitBill from "./visits/EachVisitBill";
import AddShipmentDetailsForm from "./Inventory/AddShipmentsForm/AddShipmentDetailsForm";
import { color } from "./modals/Calllog";
import { handleClick } from "./Exporters/ExportFunctions";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    // duration: theme.transitions.duration.enteringScreen,
    duration: 100,
  }),
  overflowX: "hidden",
});
// const closedMixin = (theme) => console.log("theme", theme);
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    // duration: theme.transitions.duration.leavingScreen,
    duration: 100,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    // duration: theme.transitions.duration.leavingScreen,
    duration: 100,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.enteringScreen,
      duration: 100,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const onlineStatus = useSelector((state) => state.onlineStatus.online);
  const theme = useTheme();
  const sidebarRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    if (sidebarRef.current.scrollTop > 30) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    sidebarRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const sidebar = sidebarRef.current;
    sidebar.addEventListener("scroll", handleScroll);

    return () => {
      sidebar.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarRef]);

  // set initial scrollinng to top ...
  const scrollToIntitialTop = () => {
    sidebarRef.current.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };

  // so this is to handle scroll top in different pages
  useEffect(() => {
    scrollToIntitialTop();
  }, [window.location.pathname, window.location.search]);

  const {
    lodingVisiblity,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    controllerRef,
  } = useContext(ContextState);
  const [ticketDropDown, setTicketDropDown] = useState(false);
  const handelTicketDropdown = (value) => {
    setTicketDropDown(value);
  };

  const [visitDropDown, setVisitDropDown] = useState(false);
  const handelVisitDropdown = (value) => {
    setVisitDropDown(value);
  };
  const [AnalyticsDropDown, setAnalyticsDropDown] = useState(false);
  const handelAnalyticsDropdown = (value) => {
    setAnalyticsDropDown(value);
  };

  const [miscellaneousDropDown, setMiscellaneousDropDown] = useState(false);
  const handelMiscellaneousDropDown = (value) => {
    setMiscellaneousDropDown(value);
  };

  const [attendanceDropDown, setAttendanceDropDown] = useState(false);
  const handelAttendanceDropDown = (value) => {
    setAttendanceDropDown(value);
  };
  const [inventoryDropDown, setInventoryDropDown] = useState(false);
  const handleInventoryDropDown = (value) => {
    setInventoryDropDown(value);
  };

  // media query
  const showDrawer = useMediaQuery("(max-width:1200px)");
  const floatDrawer = useMediaQuery("(max-width:768px)");

  //logout
  const {
    url,
    fetchlogoutApiCall,
    openSnackbar,
    key,
    fetchApi,
    showError,
    showErrorPage,
  } = useContext(ContextState);

  const [showSideBarError, setshowSideBarError] = useState({
    status: null,
    msg: null,
  });
  const handelshowFirstPageError = (status, msg) => {
    setshowSideBarError((prev) => ({ ...prev, status: status, msg: msg }));
  };
  const handleLogout = async () => {
    const response = await fetchlogoutApiCall("user_logout", "POST");
    if (response.statusCode === 200) {
      navigate("/login");
      openSnackbar(response?.data?.message, "success");
    } else {
      openSnackbar(response?.msg, "error");
    }
  };
  // Ropdown liat stylyling
  const listItemTextStyles = {
    fontFamily: "Open-Sans-light",
    fontWeight: "600",
    fontSize: "15px",
    color: "#5b4a4ae6",
  };
  const listItemIcon = {
    minWidth: 0,
    mr: open ? 1.8 : "auto",
    justifyContent: "center",
    fontSize: "1.45rem",
    // color: "#0098e5",
    color: "#4c4e64b3",
  };
  const listItemText = {
    opacity: open ? 1 : 0,
  };
  const listButtonTextStyle = {
    minHeight: 40,
    justifyContent: open ? "initial" : "center",
    padding: "5px 5px 5px 15px",
    margin: "4px 5px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: open ? "#e5e6e8" : "",
    },
  };
  const listButtonCollapseStyle = {
    color: "#535050e6",
    fontWeight: "600",
    padding: "5px 0px 5px 52px",
    margin: "4px 5px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: open ? "#e5e6e8 !important" : "",
    },
  };

  // navigate
  const navigate = useNavigate();

  useEffect(() => {
    if (!showDrawer) {
      handleDrawerOpen(false);
    } else {
      handleDrawerOpen(false);
    }
  }, [showDrawer]);

  useEffect(() => {
    if (open === false) {
      handelTicketDropdown(false);
      handelVisitDropdown(false);
      handelAnalyticsDropdown(false);
      handelMiscellaneousDropDown(false);
      handelAnalyticsDropdown(false);
      handelAttendanceDropDown(false);
      handleInventoryDropDown(false);
    }
  }, [open]);

  // for user deails in redux

  // user Detils from redux
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetail);

  // checking the user is logged in or not .......
  const check_user_loggedin_or_loggedout = localStorage.getItem(key);
  // console.log("check_user_loggedin_or_loggedout: ",check_user_loggedin_or_loggedout);
  // location pathname

  useEffect(() => {
    let is_user_detils_Mounted = true;
    const fetchUserDataFromBackend = async () => {
      if (!userDetails.data) {
        // fetchFiltersDataApiCall
        const user_details_data = await fetchApi(
          "get_user_info",
          "POST",
          {},
          is_user_detils_Mounted
        );
        // console.log("user_details_data in All Ticket : ", user_details_data);

        if (user_details_data.statusCode === 200) {
          // console.log(
          //   "SideBar - user_details_data and updated to redux : ",
          //   user_details_data,
          //   user_details_data?.data?.user_details
          // );
          dispatch(addUserDetail(user_details_data?.data?.user_info || null));
          handelshowFirstPageError(null, null);
        } else if (
          user_details_data.statusCode === 500 ||
          user_details_data.statusCode === 401
        ) {
          // console.log("SideBar - user_details_data : ", user_details_data);
          handelshowFirstPageError(
            user_details_data.statusCode,
            user_details_data.msg
          );
        } else {
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        console.log("");
      }
    };
    if (check_user_loggedin_or_loggedout) {
      fetchUserDataFromBackend();
    }

    return () => {
      is_user_detils_Mounted = false;
    };
  }, [check_user_loggedin_or_loggedout]);

  // list

  const VisitList = [
    {
      name: "All Visits",
      to: "/FCI/allvisits",
      code: 3001,
    },
    {
      name: "Visit Trends",
      to: "/FCI/visitstrends",
      code: 3002,
    },
    {
      name: "Visit Overview",
      to: "/FCI/visitsoverview",
      code: 3003,
    },
    {
      name: "Detailed Insights",
      to: "/FCI/detailedinsights",
      code: 3004,
    },
    {
      name: "Location Insights",
      to: "/FCI/visitsinsights",
      code: 3005,
    },
  ];

  const EachVisitSubSections = [
    { name: "Overview", to: "overview" },
    { name: "Travels", to: "traveldetail" },
    { name: "Attachment", to: "attachment" },
    { name: "Timeline", to: "timeline" },
    // { name: "Bill", to: "bill" },
  ];

  // Check if the user is inside a specific visit
  const visitMatch = location.pathname.match(/\/FCI\/allvisits\/(\d+)/);
  const currentVisitId = visitMatch ? visitMatch[1] : null;

  // Handle navigation for overview/index route
  const handleButtonClick = (value) => {
    if (value === "overview") {
      navigate(`/FCI/allvisits/${currentVisitId}`);
    } else {
      navigate(`/FCI/allvisits/${currentVisitId}/${value}`);
    }
  };

  const TicketList = [
    {
      name: "All Tickets",
      to: "/FCI/allticket",
      code: 2001,
    },
    {
      name: "Raise Ticket",
      to: "/FCI/raiseticket",
      code: 2002,
    },
    {
      name: "Ticket Trends",
      to: "/FCI/tickettrends",
      code: 2003,
    },
    {
      name: "Ticket Overview",
      to: "/FCI/ticketsoverview",
      code: 2004,
    },
    {
      name: "Location Insights",
      to: "/FCI/locationinsights",
      code: 2005,
    },
    {
      name: "Department Insights",
      to: "/FCI/issuewisedata",
      code: 2006,
    },
  ];

  const AnalyticsList = [
    {
      name: "Parameter Trends",
      to: "/FCI/analytics",
      code: 5001,
    },
    {
      name: "Scan Details",
      to: "/FCI/scaniddetailedanalysis",
      code: 5002,
    },
    {
      name: "Data Pull",
      to: "/FCI/datapulldetails",
      code: 5003,
    },
  ];
  const AttendanceList = [
    {
      name: "Attendance Overview",
      to: "/FCI/attendanceoverview",
      code: 6001,
    },
    {
      name: "Punch Records",
      to: "/FCI/punchrecords",
      code: 6002,
    },
    // {
    //   name: "Manual Attendance",
    //   to: "/manualattendance",
    //   code: 6003,
    // },
  ];

  const InventoryList = [
    {
      name: "Add Lot",
      to: "/addlotdetails",
      code: 7001,
    },
    {
      name: "Lots",
      to: "/alllots",
      code: 7002,
    },
    {
      name: "Products",
      to: "/allproducts",
      code: 7003,
    },
    {
      name: "Sellers",
      to: "/allsellers",
      code: 7004,
    },
    {
      name: "Add Shipment Form",
      to: "/addshipmentdetails",
      code: 7005,
    },
  ];
  const MiscellaneousList = [
    {
      name: "Add Model",
      to: "/FCI/addmodel",
      code: 4001,
    },
    {
      name: "Add Machine",
      to: "/FCI/update_machine_info",
      code: 4002,
    },
    {
      name: "Model Details Log",
      to: "/FCI/model_details_log",
      code: 4003,
    },
  ];
  // user Details from redux
  const userData = useSelector((state) => state.userDetail);
  // console.log("userFilters:", userFilters);
  // console.log("userData : ", userData?.data?.user_type);
  const isUser = userData?.data?.user_type === "user" ? false : true;
  // role based rendering
  const activeRole = userData?.data?.user_type?.toLowerCase() || "user";
  const { allowed_roles } = authData;
  const allowedPageList = allowed_roles[activeRole];
  // console.log("Alloed Page : ", allowedPageList);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* item to scroll page from bottom to top */}
      {/* "/allticket", "/allvisits", "/datapulldetails" */}
      {isVisible && ["/FCI"].some((path) => location.pathname === path) && (
        <button
          type="button"
          className="btn btn-primary btn-floating btn-lg"
          id="btn-back-to-top"
          style={{ backgroundColor: color.secondary }}
          onClick={scrollToTop}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
      {check_user_loggedin_or_loggedout && (
        <AppBar
          position="fixed"
          className="navbar-main-div"
          style={{
            boxShadow: "none",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <Toolbar
            style={{
              padding: "0px 18px",
              paddingLeft: !isUser ? "4px" : "18px",
            }}
          >
            {isUser && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  handleDrawerOpen(!open);
                }}
                edge="start"
                sx={{
                  marginRight: 1.8,
                  color: "gray",
                }}
                style={{ paddingLeft: "14px", backgroundColor: "transparent" }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => {
                document.location.href = "/";
              }}
              // to={"/"}
              style={{
                backgroundColor: "transparent",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <img
                className="nebulaa_icon"
                src={nebulaa_logo}
                alt="nebulaa img"
              />
            </IconButton>
            {(!floatDrawer || !isUser) && (
              <div className="ms-auto pe-0 me-0">
                <button
                  type="button"
                  className="logout-btn"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
      {/* drawer */}
      {check_user_loggedin_or_loggedout && isUser && (
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            zIndex: 75,
            width: open
              ? floatDrawer
                ? "240px"
                : "240px"
              : floatDrawer
              ? "0px"
              : "60px",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              // duration: theme.transitions.duration.enteringScreen,
              duration: 100,
            }),
            "& .MuiDrawer-paper": {
              width: open
                ? floatDrawer
                  ? "240px"
                  : "240px"
                : floatDrawer
                ? "0px"
                : "60px",
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                // duration: theme.transitions.duration.enteringScreen,
                duration: 100,
              }),
              overflowX: "hidden",
              // backgroundColor: "#F7F7F9",
              // backgroundColor: open ? color.textWhiteColor : color.primary,
              backgroundColor: color.textWhiteColor,
              backgroundColor: color.textWhiteColor,
              border: "none",
            },
          }}
          // sx={{ zIndex: 75 }}
          PaperProps={{
            sx: {
              // backgroundColor: "#f3f7fd",
              backgroundColor: "#F7F7F9",
              border: "none",
              // color: "red",
            },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {/* Home */}
            {allowedPageList.includes(1001) && (
              <ListItem disablePadding sx={{ display: "block" }}>
                <Tooltip title="Home" placement="right" arrow={true}>
                  <a
                    href={`${window.location.origin}`}
                    onClick={(e) => {
                      const targetPath = `${window.location.origin}`;
                      handleClick(e, "/", targetPath, navigate);
                      if (showDrawer) {
                        handleDrawerClose();
                      }
                    }}
                  >
                    <ListItemButton
                      sx={listButtonTextStyle}
                      // onClick={() => {
                      //   navigate("/");
                      // }}
                    >
                      <ListItemIcon sx={listItemIcon}>
                        {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                        {/* <AiFillHome /> */}
                        <AiOutlineHome
                          style={{
                            color:
                              location.pathname === "/" ||
                              location.pathname === "/FCI" ||
                              location.pathname === "/FCI/location" ||
                              location.pathname === "/FCI/crop"
                                ? "#0098e5"
                                : "#535050e6",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box
                            sx={listItemTextStyles}
                            style={{
                              color:
                                location.pathname === "/" ||
                                location.pathname === "/FCI" ||
                                location.pathname === "/FCI/location" ||
                                location.pathname === "/FCI/crop"
                                  ? "#0098e5"
                                  : "#535050e6",
                            }}
                          >
                            Home
                          </Box>
                        }
                        sx={listItemText}
                      />
                    </ListItemButton>
                  </a>
                </Tooltip>
              </ListItem>
            )}

            {/* Ticket */}
            {allowedPageList.some((page) =>
              [2001, 2002, 2003, 2004, 2005, 2006].includes(page)
            ) && (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  handelTicketDropdown(!ticketDropDown);
                  handleDrawerOpen(true);
                }}
              >
                <Tooltip title="Support Tickets" placement="right" arrow={true}>
                  <ListItemButton sx={listButtonTextStyle}>
                    <ListItemIcon sx={listItemIcon}>
                      {/* <FaTicketAlt
                        style={{
                          filter: `invert(100%)`,
                        }}
                      /> */}
                      <GrTicket
                        style={{
                          color: TicketList.some((item) =>
                            location.pathname
                              .split("/")
                              .includes(item.to.split("/").splice(-1)[0])
                          )
                            ? "#0098e5"
                            : "#535050e6",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          // fontWeight={
                          //   ticketDropDown
                          //     ? "fontWeightBold"
                          //     : "fontWeightMedium"
                          // }
                          sx={listItemTextStyles}
                          style={{
                            color: TicketList.some((item) =>
                              location.pathname
                                .split("/")
                                .includes(item.to.split("/").splice(-1)[0])
                            )
                              ? "#0098e5"
                              : "#535050e6",
                          }}
                        >
                          Support Tickets
                        </Box>
                      }
                      sx={listItemText}
                    />
                    {open && (ticketDropDown ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
            <Collapse
              in={open ? ticketDropDown : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {TicketList.map(
                  (subItem, index) =>
                    allowedPageList.includes(subItem.code) && (
                      <a
                        href={`${window.location.origin}${subItem.to}`}
                        onClick={(e) => {
                          const targetPath = `${window.location.origin}${subItem.to}`;
                          handleClick(e, subItem.to, targetPath, navigate);
                          if (showDrawer) {
                            handleDrawerClose();
                          }
                        }}
                      >
                        <ListItemButton
                          // to={subItem.to}
                          // onClick={() => {
                          //   navigate(subItem.to);
                          //   if (showDrawer) {
                          //     handleDrawerClose();
                          //   }
                          // }}
                          key={`ticket-key-${index}`}
                          sx={listButtonCollapseStyle}
                          style={{
                            backgroundColor: location.pathname
                              .split("/")
                              .includes(subItem.to.split("/").splice(-1)[0])
                              ? "#c5e4ff99"
                              : "inherit",
                          }}
                        >
                          {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Box
                                fontFamily={"Open-Sans-light"}
                                fontSize={"15px"}
                                fontWeight={600}
                              >
                                {/* {location.pathname === subItem.to && (
                            <FiberManualRecordIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                color: "#0098e5",
                              }}
                            />
                          )} */}
                                {subItem.name}
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </a>
                    )
                )}
              </List>
            </Collapse>
            {/* Visit */}
            {allowedPageList.some((page) =>
              [3001, 3002, 3003, 3004, 3005, 3006].includes(page)
            ) && (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  handelVisitDropdown(!visitDropDown);
                  handleDrawerOpen(true);
                }}
              >
                <Tooltip title="Support Visits" placement="right" arrow={true}>
                  <ListItemButton
                    sx={listButtonTextStyle}
                    style={{ position: "relative" }}
                  >
                    <ListItemIcon sx={listItemIcon}>
                      {/* <FaPersonWalking /> */}
                      {/* <div style={{ padding: "0px", margin: "0px -3px" }}>
                        <img
                          src={visitImg}
                          alt="All Assesment img"
                          style={{
                            height: "auto",
                            width: "30px",
                          }}
                        />
                      </div> */}
                      <BiTrip
                        style={{
                          color: VisitList.some((item) =>
                            location.pathname
                              .split("/")
                              .includes(item.to.split("/").splice(-1)[0])
                          )
                            ? "#0098e5"
                            : "#4c4e64e6",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          // fontWeight={
                          //   visitDropDown
                          //     ? "fontWeightBold"
                          //     : "fontWeightMedium"
                          // }
                          sx={listItemTextStyles}
                          style={{
                            color: VisitList.some((item) =>
                              location.pathname
                                .split("/")
                                .includes(item.to.split("/").splice(-1)[0])
                            )
                              ? "#0098e5"
                              : "#4c4e64e6",
                          }}
                        >
                          Support Visits
                        </Box>
                      }
                      sx={listItemText}
                    />
                    {open && (visitDropDown ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
            <Collapse
              in={open ? visitDropDown : false}
              timeout="auto"
              unmountOnExit
            >
              {/* new list with sub-section  */}
              <List component="div" disablePadding>
                {/* Main Menu Items */}
                {VisitList.map((item) => (
                  <React.Fragment key={item.code}>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <Tooltip title={item.name} placement="right" arrow={true}>
                        <a
                          href={`${window.location.origin}${item.to}`}
                          onClick={(e) => {
                            const targetPath = `${window.location.origin}${item.to}`;
                            handleClick(e, item.to, targetPath, navigate);
                            if (showDrawer) {
                              handleDrawerClose();
                            }
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemButton
                            sx={listButtonCollapseStyle}
                            style={{
                              backgroundColor: location.pathname
                                .split("/")
                                .includes(item.to.split("/").splice(-1)[0])
                                ? "#c5e4ff99"
                                : "inherit",
                            }}
                          >
                            {/* Only show icon for "Support Visits" */}
                            <ListItemText
                              primary={
                                <span
                                  style={{
                                    fontFamily: "Open-Sans-light",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.name}
                                </span>
                              }
                            />
                          </ListItemButton>
                        </a>
                      </Tooltip>
                    </ListItem>

                    {/* Render subsections dynamically for "All Visits" */}
                    {item.to === "/FCI/allvisits" && currentVisitId && (
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {EachVisitSubSections.map((subItem, index) => {
                            // Construct the correct navigation path
                            const subItemPath =
                              subItem.to === "overview"
                                ? `/FCI/allvisits/${currentVisitId}`
                                : `/FCI/allvisits/${currentVisitId}/${subItem.to}`;

                            return (
                              <ListItemButton
                                key={`sub-${index}`}
                                sx={listButtonCollapseStyle}
                                component="a"
                                href={`${window.location.origin}${subItemPath}`}
                                onClick={(e) => {
                                  const targetPath = `${window.location.origin}${subItemPath}`;
                                  handleClick(
                                    e,
                                    subItemPath,
                                    targetPath,
                                    navigate
                                  );
                                  if (showDrawer) {
                                    handleDrawerClose();
                                  }
                                }}
                              >
                                <BsDot
                                  style={{
                                    color:
                                      location.pathname === subItemPath
                                        ? "#0098e5"
                                        : "#4c4e64e6",
                                    fontSize: "35px",
                                  }}
                                />
                                <ListItemText
                                  primary={
                                    <span
                                      style={{
                                        color:
                                          location.pathname === subItemPath
                                            ? "#0098e5"
                                            : "#4c4e64e6",
                                        fontFamily: "Open-Sans-light",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {subItem.name}
                                    </span>
                                  }
                                />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Collapse>

            {/* Analytics */}
            {allowedPageList.some((page) =>
              [5001, 5002, 5003, 5004, 5005, 5006, 5007, 5008].includes(page)
            ) && (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  handelAnalyticsDropdown(!AnalyticsDropDown);
                  handleDrawerOpen(true);
                }}
              >
                <Tooltip title="Scan Analytics" placement="right" arrow={true}>
                  <ListItemButton sx={listButtonTextStyle}>
                    <ListItemIcon sx={listItemIcon}>
                      {/* <FaTicketAlt
                        style={{
                          filter: `invert(100%)`,
                        }}
                      /> */}
                      <TbDeviceAnalytics
                        style={{
                          color: AnalyticsList.some((item) =>
                            location.pathname
                              .split("/")
                              .includes(item.to.split("/").splice(-1)[0])
                          )
                            ? "#0098e5"
                            : "#535050e6",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          // fontWeight={
                          //   ticketDropDown
                          //     ? "fontWeightBold"
                          //     : "fontWeightMedium"
                          // }
                          sx={listItemTextStyles}
                          style={{
                            color: AnalyticsList.some((item) =>
                              location.pathname
                                .split("/")
                                .includes(item.to.split("/").splice(-1)[0])
                            )
                              ? "#0098e5"
                              : "#535050e6",
                          }}
                        >
                          Scan Analytics
                        </Box>
                      }
                      sx={listItemText}
                    />
                    {open &&
                      (AnalyticsDropDown ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
            <Collapse
              in={open ? AnalyticsDropDown : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {AnalyticsList.map(
                  (subItem, index) =>
                    allowedPageList.includes(subItem.code) && (
                      <a
                        href={`${window.location.origin}${subItem.to}`}
                        onClick={(e) => {
                          const targetPath = `${window.location.origin}${subItem.to}`;
                          handleClick(e, subItem.to, targetPath, navigate);
                          if (showDrawer) {
                            handleDrawerClose();
                          }
                        }}
                      >
                        <ListItemButton
                          // to={subItem.to}
                          // onClick={() => {
                          //   navigate(subItem.to);
                          //   if (showDrawer) {
                          //     handleDrawerClose();
                          //   }
                          // }}
                          key={`ticket-key-${index}`}
                          sx={listButtonCollapseStyle}
                          style={{
                            backgroundColor: location.pathname
                              .split("/")
                              .includes(subItem.to.split("/").splice(-1)[0])
                              ? "#c5e4ff99"
                              : "inherit",
                          }}
                        >
                          {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Box
                                fontFamily={"Open-Sans-light"}
                                fontSize={"15px"}
                                fontWeight={600}
                              >
                                {/* {location.pathname === subItem.to && (
                            <FiberManualRecordIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                color: "#0098e5",
                              }}
                            />
                          )} */}
                                {subItem.name}
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </a>
                    )
                )}
              </List>
            </Collapse>

            {/* attendance */}
            {allowedPageList.some((page) =>
              [6001, 6002, 6003, 6004, 6005, 6006, 6007].includes(page)
            ) && (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  handelAttendanceDropDown(!attendanceDropDown);
                  handelAttendanceDropDown(!attendanceDropDown);
                  handleDrawerOpen(true);
                }}
              >
                <Tooltip title="Attendance" placement="right" arrow={true}>
                  <ListItemButton
                    sx={listButtonTextStyle}
                    style={{ position: "relative" }}
                  >
                    <ListItemIcon sx={listItemIcon}>
                      {/* <FaPersonWalking /> */}
                      {/* <div style={{ padding: "0px", margin: "0px -3px" }}>
                        <img
                          src={visitImg}
                          alt="All Assesment img"
                          style={{
                            height: "auto",
                            width: "30px",
                          }}
                        />
                      </div> */}
                      <RiCalendarCheckFill
                        style={{
                          color: AttendanceList.some((item) =>
                            location.pathname
                              .split("/")
                              .includes(item.to.split("/").splice(-1)[0])
                          )
                            ? "#0098e5"
                            : "#535050e6",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          // fontWeight={
                          // miscellaneousDropDown
                          //     ? "fontWeightBold"
                          //     : "fontWeightMedium"
                          // }
                          sx={listItemTextStyles}
                          style={{
                            color: AttendanceList.some((item) =>
                              location.pathname
                                .split("/")
                                .includes(item.to.split("/").splice(-1)[0])
                            )
                              ? "#0098e5"
                              : "#535050e6",
                          }}
                        >
                          Attendance
                        </Box>
                      }
                      sx={listItemText}
                    />
                    {open &&
                      (attendanceDropDown ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
            <Collapse
              in={open ? attendanceDropDown : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {AttendanceList.map(
                  (subItem, index) =>
                    allowedPageList.includes(subItem.code) && (
                      <a
                        href={`${window.location.origin}${subItem.to}`}
                        onClick={(e) => {
                          const targetPath = `${window.location.origin}${subItem.to}`;
                          handleClick(e, subItem.to, targetPath, navigate);
                          if (showDrawer) {
                            handleDrawerClose();
                          }
                        }}
                      >
                        <ListItemButton
                          // to={subItem.to}
                          // onClick={() => {
                          //   navigate(subItem.to);
                          //   if (showDrawer) {
                          //     handleDrawerClose();
                          //   }
                          // }}
                          key={`ticket-key-${index}`}
                          sx={listButtonCollapseStyle}
                          style={{
                            backgroundColor: location.pathname
                              .split("/")
                              .includes(subItem.to.split("/").splice(-1)[0])
                              ? "#c5e4ff99"
                              : "inherit",
                          }}
                        >
                          {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Box
                                fontFamily={"Open-Sans-light"}
                                fontSize={"15px"}
                                fontWeight={600}
                              >
                                {subItem.name}
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </a>
                    )
                )}
              </List>
            </Collapse>

            {/* inventory */}
            {allowedPageList.some((page) =>
              [7001, 7002, 7003, 7004, 7005, 7006].includes(page)
            ) && (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  setInventoryDropDown(!inventoryDropDown);
                  handleDrawerOpen(true);
                }}
              >
                <Tooltip title="Inventory" placement="right" arrow={true}>
                  <ListItemButton
                    sx={listButtonTextStyle}
                    style={{ position: "relative" }}
                  >
                    <ListItemIcon sx={listItemIcon}>
                      {/* <FaPersonWalking /> */}
                      {/* <div style={{ padding: "0px", margin: "0px -3px" }}>
                        <img
                          src={visitImg}
                          alt="All Assesment img"
                          style={{
                            height: "auto",
                            width: "30px",
                          }}
                        />
                      </div> */}
                      <MdOutlineInventory2
                        style={{
                          color: InventoryList.some((item) =>
                            location.pathname
                              .split("/")
                              .includes(item.to.split("/").splice(-1)[0])
                          )
                            ? "#0098e5"
                            : "#535050e6",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          // fontWeight={
                          // miscellaneousDropDown
                          //     ? "fontWeightBold"
                          //     : "fontWeightMedium"
                          // }
                          sx={listItemTextStyles}
                          style={{
                            color: InventoryList.some((item) =>
                              location.pathname
                                .split("/")
                                .includes(item.to.split("/").splice(-1)[0])
                            )
                              ? "#0098e5"
                              : "#535050e6",
                          }}
                        >
                          Inventory
                        </Box>
                      }
                      sx={listItemText}
                    />
                    {open &&
                      (inventoryDropDown ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
            <Collapse
              in={open ? inventoryDropDown : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {InventoryList.map(
                  (subItem, index) =>
                    allowedPageList.includes(subItem.code) && (
                      <a
                        href={`${window.location.origin}${subItem.to}`}
                        onClick={(e) => {
                          const targetPath = `${window.location.origin}${subItem.to}`;
                          handleClick(e, subItem.to, targetPath, navigate);
                          if (showDrawer) {
                            handleDrawerClose();
                          }
                        }}
                      >
                        <ListItemButton
                          // to={subItem.to}
                          // onClick={() => {
                          //   navigate(subItem.to);
                          //   if (showDrawer) {
                          //     handleDrawerClose();
                          //   }
                          // }}
                          key={`ticket-key-${index}`}
                          sx={listButtonCollapseStyle}
                          style={{
                            backgroundColor: location.pathname
                              .split("/")
                              .includes(subItem.to.replace("/", ""))
                              ? "#c5e4ff99"
                              : "inherit",
                          }}
                        >
                          {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Box
                                fontFamily={"Open-Sans-light"}
                                fontSize={"15px"}
                                fontWeight={600}
                              >
                                {subItem.name}
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </a>
                    )
                )}
              </List>
            </Collapse>
            {/* Miscellaneous */}
            {allowedPageList.some((page) =>
              [4001, 4002, 4003, 4004, 4005].includes(page)
            ) && (
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  handelMiscellaneousDropDown(!miscellaneousDropDown);
                  handelMiscellaneousDropDown(!miscellaneousDropDown);
                  handleDrawerOpen(true);
                }}
              >
                <Tooltip title="Miscellaneous" placement="right" arrow={true}>
                  <ListItemButton
                    sx={listButtonTextStyle}
                    style={{ position: "relative" }}
                  >
                    <ListItemIcon sx={listItemIcon}>
                      {/* <FaPersonWalking /> */}
                      {/* <div style={{ padding: "0px", margin: "0px -3px" }}>
                        <img
                          src={visitImg}
                          alt="All Assesment img"
                          style={{
                            height: "auto",
                            width: "30px",
                          }}
                        />
                      </div> */}
                      <MdMiscellaneousServices
                        style={{
                          color: MiscellaneousList.some((item) =>
                            location.pathname
                              .split("/")
                              .includes(item.to.split("/").splice(-1)[0])
                          )
                            ? "#0098e5"
                            : "#535050e6",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          // fontWeight={
                          // miscellaneousDropDown
                          //     ? "fontWeightBold"
                          //     : "fontWeightMedium"
                          // }
                          sx={listItemTextStyles}
                          style={{
                            color: MiscellaneousList.some((item) =>
                              location.pathname
                                .split("/")
                                .includes(item.to.split("/").splice(-1)[0])
                            )
                              ? "#0098e5"
                              : "#535050e6",
                          }}
                        >
                          Miscellaneous
                        </Box>
                      }
                      sx={listItemText}
                    />
                    {open &&
                      (miscellaneousDropDown ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
            <Collapse
              in={open ? miscellaneousDropDown : false}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {MiscellaneousList.map(
                  (subItem, index) =>
                    allowedPageList.includes(subItem.code) && (
                      <a
                        href={`${window.location.origin}${subItem.to}`}
                        onClick={(e) => {
                          const targetPath = `${window.location.origin}${subItem.to}`;
                          handleClick(e, subItem.to, targetPath, navigate);
                          if (showDrawer) {
                            handleDrawerClose();
                          }
                        }}
                      >
                        <ListItemButton
                          // to={subItem.to}
                          // onClick={() => {
                          //   navigate(subItem.to);
                          //   if (showDrawer) {
                          //     handleDrawerClose();
                          //   }
                          // }}
                          key={`ticket-key-${index}`}
                          sx={listButtonCollapseStyle}
                          style={{
                            backgroundColor: location.pathname
                              .split("/")
                              .includes(subItem.to.split("/").splice(-1)[0])
                              ? "#c5e4ff99"
                              : "inherit",
                          }}
                        >
                          {/* <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Box
                                fontFamily={"Open-Sans-light"}
                                fontSize={"15px"}
                                fontWeight={600}
                              >
                                {subItem.name}
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </a>
                    )
                )}
              </List>
            </Collapse>
          </List>
          <Divider />
          {/* Footer content */}
          <List style={{ marginTop: `auto` }}>
            {floatDrawer && (
              <ListItem sx={{ padding: "0px", textAlign: "center" }}>
                <ListItemText>
                  <button
                    type="button"
                    className="logout-btn"
                    style={{
                      padding: open ? "2px 15px" : "1px 5px",
                      transition: "padding 1s ease",
                    }}
                    onClick={handleLogout}
                  >
                    {(open && "Logout") || (
                      <Tooltip title="Logout" placement="right" arrow={true}>
                        <PowerSettingsNewIcon />
                      </Tooltip>
                    )}
                  </button>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 0, overflow: "auto", height: "100vh" }}
        style={{
          position: floatDrawer ? "fixed" : "relative",
          width: "auto",
          right: "0px",
          left: "0px",
          marginLeft:
            check_user_loggedin_or_loggedout && floatDrawer ? "0px" : "auto",
        }}
        ref={sidebarRef}
      >
        <DrawerHeader />

        {floatDrawer && open && (
          <div
            className="balu"
            style={{
              height: "100vh",
              width: "100vw",
              position: "fixed",
              top: "0%",
              right: "0%",
              backgroundColor: "black",
              zIndex: "76",
              opacity: "0.5",
            }}
          ></div>
        )}
        <div className="main-container pb-5 pb-lg-0">
          {/* Beatloader visiblity */}
          {/* {lodingVisiblity && (
            <div className="showSpinner">
              <BeatLoader color="#2D97D4" />
            </div>
          )} */}

          {/* <Navigation /> */}
          {/* <Box sx={{ width: 500 }}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={closeSnackbar}
              message={snackbarMessage}
              severity={snackbarSeverity}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={closeSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              <Alert
                onClose={closeSnackbar}
                severity={snackbarSeverity}
                variant="filled"
                sx={{
                  width: "100%",
                  ".MuiAlert-action": {
                    display: onlineStatus ? "" : "none",
                  },
                }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box> */}

          {
            <div className="main-inner-container ">
              {/* {false && lodingVisiblity && (
              <div
                className="showSpinner all-ticket-spinner"
                style={{ height: "100vh", zIndex: 5 }}
              >
                <BeatLoader color="#2D97D4" />
              </div>
            )} */}

              <>
                <Routes>
                  <Route
                    element={
                      <PrivateRoutes
                        showSideBarError={showSideBarError}
                        handelshowFirstPageError={handelshowFirstPageError}
                      />
                    }
                  >
                    <Route path="/" element={<Clients />} />
                    <Route element={<Auth routeCode={[2001]} />}>
                      <Route
                        element={<AllTickets />}
                        path="/FCI/allticket"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[2002]} />}>
                      <Route
                        element={<RaiseTicketForm />}
                        path="/FCI/raiseticket"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[2003]} />}>
                      <Route
                        element={<LineChartAnalysis />}
                        path="/FCI/tickettrends"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[2004]} />}>
                      <Route
                        element={<UserDashboard />}
                        path="/FCI/ticketsoverview"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[2005]} />}>
                      <Route
                        element={<TableFormTicketData />}
                        path="/FCI/locationinsights"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[2006]} />}>
                      <Route
                        element={<IssueWiseTableView />}
                        path="/FCI/issuewisedata"
                        exact
                      />
                    </Route>
                    {/* <Route element={<Auth routeCode={[3001]} />}>
                        <Route
                          element={<AllVisits />}
                          path="/FCI/allvisits"
                          exact
                        />
                      </Route> */}
                    <Route element={<Auth routeCode={[3001]} />}>
                      <Route element={<VisitOutlet />} path="/FCI/allvisits">
                        <Route index element={<AllVisits />} />
                        <Route path=":visitId" element={<EachVisitOutlet />}>
                          {/* <Route index element={<Navigate to="overview" />} /> */}
                          <Route index element={<EachVisitOverview />} />
                          <Route
                            path="traveldetail"
                            element={<EachVisitTravel />}
                          />
                          <Route
                            path="attachment"
                            element={<EachVisitAttachment />}
                          />
                          <Route
                            path="timeline"
                            element={<EachVisitTimeline />}
                          />
                          <Route path="bill" element={<EachVisitBill />} />
                        </Route>
                      </Route>
                    </Route>

                    <Route element={<Auth routeCode={[3002]} />}>
                      <Route
                        element={<VisitLineChartAnalysis />}
                        path="/FCI/visitstrends"
                        exact
                      />
                    </Route>

                    <Route element={<Auth routeCode={[3003]} />}>
                      <Route
                        element={<VisitsUserDashboard />}
                        path="/FCI/visitsoverview"
                        exact
                      />
                    </Route>

                    <Route element={<Auth routeCode={[3004]} />}>
                      <Route
                        element={<DetailedVisitData />}
                        path="/FCI/detailedinsights"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[3005]} />}>
                      <Route
                        element={<LocationWiseVisitData />}
                        path="/FCI/visitsinsights"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[4001]} />}>
                      <Route
                        element={<AddNewModel />}
                        path="/FCI/addmodel"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[4002]} />}>
                      <Route
                        element={<UpdateMachine />}
                        path="/FCI/update_machine_info"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[4003]} />}>
                      <Route
                        element={<ModelDetails />}
                        path="/FCI/model_details_log"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[5001]} />}>
                      <Route
                        element={<Analytics isSideBarOpen={open} />}
                        path="/FCI/analytics"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[5002]} />}>
                      <Route
                        element={<ScanidDetailedAnalytics />}
                        path="/FCI/scaniddetailedanalysis"
                        exact
                      ></Route>
                    </Route>
                    <Route element={<Auth routeCode={[5003]} />}>
                      <Route
                        element={<DataPull />}
                        path="/FCI/datapulldetails"
                        exact
                      ></Route>
                    </Route>
                    <Route element={<Auth routeCode={[6001]} />}>
                      <Route
                        element={<Attendance />}
                        path="/FCI/attendanceoverview"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[6002]} />}>
                      <Route
                        element={<PunchRecords />}
                        path="/FCI/punchrecords"
                        exact
                      />
                    </Route>
                    <Route element={<Auth routeCode={[7001]} />}>
                      <Route
                        element={<AddLotDetailsForm />}
                        path="/addlotdetails"
                        exact
                      ></Route>
                    </Route>
                    <Route element={<Auth routeCode={[7002]} />}>
                      <Route element={<AllLotsDetails />} path="/alllots">
                        <Route index element={<AllLots />} />
                        <Route path=":lotId" element={<SpecificLot />}>
                          <Route index element={<LotGeneralContent />} />
                          <Route
                            path="attachments"
                            element={<LotAttachments />}
                          />
                        </Route>
                        <Route />
                      </Route>
                    </Route>

                    <Route element={<Auth routeCode={[7003]} />}>
                      <Route path="/allproducts" element={<AllProducts />}>
                        <Route index element={<ProductType />} />
                        <Route
                          element={<ProductTypeDetails />}
                          path=":productCategory"
                        >
                          <Route index element={<SpecificProductType />} />
                          <Route path=":productID" element={<ProductDetails />}>
                            <Route index element={<ProductGeneralContent />} />
                            <Route
                              path="attachments"
                              element={<ProductAttachment />}
                            />
                            <Route
                              path="timeline"
                              element={<ProductTimeline />}
                            />
                            <Route
                              path="comments"
                              element={<ProductComments />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>

                    <Route element={<Auth routeCode={[7004]} />}>
                      <Route element={<AllSellers />} path="/allsellers" />
                    </Route>

                    <Route element={<Auth routeCode={[7005]} />}>
                      <Route
                        element={<AddShipmentDetailsForm />}
                        path="/addshipmentdetails"
                        exact
                      ></Route>
                    </Route>

                    <Route element={<Error />} path="/error" exact />
                    {/* <Route
                        element={<Attendance />}
                        path="/attendance"
                        exact
                      /> */}
                    {/* Dashboard route */}
                    {/* {activeRole.toLowerCase() === "support" ? (
                  <Route element={<UserProfile />} path="/" exact />
                ) : (
                )} */}
                    <Route element={<Auth routeCode={[1001]} />}>
                      <Route path="/FCI" element={<FirstPage />}>
                        <Route index element={<Overview />} />
                        <Route element={<Location />} path="location" />
                        <Route element={<CropOverview />} path="crop" />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/forgotpassword" element={<ForgotPassword />} />
                  <Route path="*" element={<Error code={404} />} />
                </Routes>
              </>
            </div>
          }
        </div>
      </Box>
    </Box>
  );
};

export default SideBar;

{
  /* <List component="div" disablePadding>
  {VisitList.map(
    (subItem, index) =>
      allowedPageList.includes(subItem.code) && (
        <a
          href={`${window.location.origin}${subItem.to}`}
          onClick={(e) => {
            const targetPath = `${window.location.origin}${subItem.to}`;
            handleClick(e, subItem.to, targetPath, navigate);
            if (showDrawer) {
              handleDrawerClose();
            }
          }}
        >
          <ListItemButton
            key={`ticket-key-${index}`}
            sx={listButtonCollapseStyle}
            style={{
              backgroundColor: location.pathname
                .split("/")
                .includes(subItem.to.split("/").splice(-1)[0])
                ? "#c5e4ff99"
                : "inherit",
            }}
          >
            <ListItemText
              primary={
                <Box
                  fontFamily={"Open-Sans-light"}
                  fontSize={"15px"}
                  fontWeight={600}
                >
                  {subItem.name}
                </Box>
              }
            />
          </ListItemButton>
        </a>
      )
  )}
</List> */
}
