import React from "react";
import FilesUpload from "./FilesUpload";

function Attachments({
  genFields,
  setGenFields,
  selectedFiles,
  setSelectedFiles,
  isSmallScreen,
  isLotDetailsForm,
}) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: isLotDetailsForm ? "0px" : "20px 0px",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        justifyContent: "space-between",
      }}
    >
      <FilesUpload
        genFields={genFields}
        setGenFields={setGenFields}
        isSmallScreen={isSmallScreen}
      />
    </div>
  );
}

export default Attachments;
