import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  CircularProgress,
  Modal,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import close from "../../../assets/images/close.svg";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { formattingString } from "../../analytics/DataPull";
import ContextState from "../../contextApi/ContextState";
import "../../../assets/styles/AddLotDetailsForm.css";
import { HiUserAdd } from "react-icons/hi";
import dayjs from "dayjs";
import { color } from "../../modals/Calllog";

export const DisplayAddress = (props) => {
  const { isSmallScreen, preview, genFields, options, addressType } = props;

  const { formatInIndianRupees } = useContext(ContextState);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");

  const addressId =
    parseInt(genFields?.generalDetails?.[addressType]?.id, 10) || 0;

  let addressDetails =
    options && options[addressType]?.options?.length !== 0
      ? options[addressType].options.find((item) => addressId === item.id)
      : genFields.generalDetails[addressType];

  // if (!sellerDetails) {
  //   sellerDetails = genFields["sellerDetails"];
  // }
  // console.log("address Details", addressDetails, options, genFields);

  useEffect(() => {
    // console.log("isMediumScreen : ", isMediumScreen);
  }, [isMediumScreen]);

  return (
    <>
      {!preview && (
        <div className="d-flex flex-column gap-1 ">
          {/* <div>Seller Details</div> */}
          <div
            className="pt-1"
            style={{
              display: "grid",
              gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr", // Three columns
              gridRowGap: "15px",
            }}
          >
            {["name", "email", "contact_number", "address"].map(
              (showValues, index) => (
                <p
                  key={`${showValues}-${index}`}
                  className="m-0"
                  style={{
                    gridColumn:
                      showValues === "address"
                        ? isSmallScreen
                          ? "1"
                          : "1 / span 3"
                        : "",
                    gridRow:
                      showValues === "address"
                        ? isSmallScreen
                          ? "auto"
                          : "2"
                        : "1",
                  }}
                >
                  <label
                    htmlFor={`outlined-read-only-input-${showValues}`}
                    className="basic_details_elements_label_data_table"
                    style={{
                      textTransform: "capitalize",
                      fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                    }}
                  >
                    {formattingString(showValues)}
                  </label>
                  <div
                    id={`outlined-read-only-input-${showValues}`}
                    className="basic_details_elements_data_content"
                    style={{
                      fontSize: isSmallScreen ? "0.95rem" : "1rem",
                    }}
                  >
                    {/* {addressDetails && addressDetails[showValues]
                      ? addressDetails[showValues]
                      : genFields.generalDetails[addressType]} */}

                    {addressType &&
                      genFields?.generalDetails?.[addressType]?.[showValues]}
                  </div>
                </p>
              )
            )}
          </div>
        </div>
      )}

      {/* general details */}
      {preview && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMediumScreen
              ? isSmallScreen
                ? "1fr"
                : "1fr 1fr 1fr"
              : "repeat(3,1fr)",
            gridRowGap: "20px",
          }}
        >
          {/* {["invoiceNumber", "arrivalDate", "productCount", "totalAmount"].map( */}
          {["trackId", "date", "shipmentType"].map((showValues, index) => (
            <p
              key={`${showValues}-${index}`}
              className={`m-0 pe-2  ${
                !genFields.generalDetails.machine && showValues === "machine"
                  ? "d-none"
                  : ""
              }`}
            >
              <label
                htmlFor={`outlined-read-only-input-${showValues}`}
                className="basic_details_elements_label_data_table"
                style={{
                  textTransform: "capitalize",
                  fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                }}
              >
                {formattingString(showValues)}
              </label>
              <div
                id={`outlined-read-only-input-${showValues}`}
                className="basic_details_elements_data_content text-break"
                style={{
                  fontSize: isSmallScreen ? "0.95rem" : "1rem",
                }}
              >
                {showValues === "date"
                  ? dayjs(genFields.generalDetails[showValues]).format(
                      "DD-MM-YYYY"
                    )
                  : genFields.generalDetails[showValues]}
              </div>
            </p>
          ))}
        </div>
      )}
    </>
  );
};

const ShipmentGeneralDetails = ({
  genFields,
  setGenFields,
  fieldError,
  isSmallScreen,
  isMediumScreen,
  islageScreen,
}) => {
  const { fetchApi, openSnackbar } = useContext(ContextState);

  const [addNewAddress, setAddNewAddress] = useState({
    isOpen: false,
    addAddressType: "",
    name: "",
    phone: "",
    email: "",
    address: "",
  });

  // The filter function for options (based on MUI's default filter function)
  const filter = (options, { inputValue }) => {
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  function handleCloseAddNewSellerModal(e, reason) {
    if (reason && reason === "backdropClick") {
      return;
    }

    setAddNewAddress((prev) => ({
      ...prev,
      isOpen: false,
      addAddressType: "",
    }));
  }

  const [addressState, setAddressState] = React.useState({
    sender: {
      open: false,
      options: [],
      loading: false,
      selected: null,
    },
    receiver: {
      open: false,
      options: [],
      loading: false,
      selected: null,
    },
  });

  const fetchSellerDetails = async (key) => {
    setAddressState((prev) => ({
      ...prev,
      [key]: { ...prev[key], loading: true, open: true },
    }));

    try {
      const payload = {
        sellers:true
      };
      const response = await fetchApi(
        "get_lot_helper",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        setAddressState((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            options: response.data.seller_details || [],
            loading: false,
          },
        }));
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error(`Error fetching seller details for ${key}:`, error);
    }
  };

  const handleAddressChange = (key, newValue) => {
    setGenFields((prev) => ({
      ...prev,
      generalDetails: {
        ...prev.generalDetails,
        [key]: newValue
          ? {
              name: newValue.name,
              email: newValue.email,
              contact_number: newValue.contact_number,
              contact_person: newValue.contact_person,
              address: newValue.address,
              id: newValue.id,
            }
          : {
              id: "",
              name: "",
              email: "",
              contact_number: "",
              contact_person: "",
              address: "",
            },
      },
    }));

    // setAddressState((prev) => ({
    //   ...prev,
    //   [key]: { ...prev[key], selected: newValue || null },
    // }));
  };

  const handleCloseAddress = (key) => {
    setAddressState((prev) => ({
      ...prev,
      [key]: { ...prev[key], open: false },
    }));
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom:
          genFields.generalDetails.receiver.name !== "" ||
          genFields.generalDetails.sender?.name !== ""
            ? "0px"
            : "20px",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isSmallScreen
              ? "repeat(1, 1fr)"
              : "repeat(2, 1fr)",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "25px",
            gap: 25,
          }}
        >
          {/* Track Id */}
          <FormControl fullWidth error={fieldError.trackIdError}>
            <TextField
              value={genFields.generalDetails.trackId}
              fullWidth
              id="outlined-basic"
              label="Track Id"
              variant="outlined"
              onChange={(e) => {
                setGenFields((prev) => {
                  return {
                    ...prev,
                    generalDetails: {
                      ...prev.generalDetails,
                      trackId: e.target.value,
                    },
                  };
                });
              }}
            />
            {fieldError.trackIdError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please enter Track Id.
              </div>
            ) : (
              <></>
            )}
          </FormControl>
          {/* Date */}
          <FormControl fullWidth error={fieldError.dateError}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                format="DD/MM/YYYY"
                maxDate={dayjs()} // Restrict to today's date
                value={
                  genFields.generalDetails.date === ""
                    ? null
                    : genFields.generalDetails.date
                }
                onChange={(date) => {
                  setGenFields((prev) => ({
                    ...prev,
                    generalDetails: {
                      ...prev.generalDetails,
                      date: date,
                    },
                  }));
                }}
              />
            </LocalizationProvider>

            {fieldError.dateError && (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select date.
              </div>
            )}
          </FormControl>

          {/* Shipment Type */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Shipment Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Shipment Type"
              value={genFields?.generalDetails?.shipmentType}
              onChange={(e) => {
                setGenFields((prev) => ({
                  ...prev,
                  generalDetails: {
                    ...prev.generalDetails,
                    shipmentType: e.target.value,
                  },
                }));
              }}
            >
              {["Dispatch", "Return"].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>

            {fieldError.shipmentError && (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select Shipment type.
              </div>
            )}
          </FormControl>

          {/* Machine Code */}
          <FormControl fullWidth error={fieldError.machineError}>
            <TextField
              value={genFields?.generalDetails.machine}
              fullWidth
              type="number"
              id="outlined-basic"
              label="Machine Code"
              variant="outlined"
              onChange={(e) => {
                setGenFields((prev) => {
                  return {
                    ...prev,
                    generalDetails: {
                      ...prev.generalDetails,
                      machine: e.target.value,
                    },
                  };
                });
              }}
            />
          </FormControl>

          {/* Sender */}
          <div>
            <FormControl
              fullWidth
              error={fieldError.addressSenderError}
              className="d-flex flex-row align-items-center gap-3"
            >
              <Autocomplete
                className="w-100"
                value={genFields?.generalDetails?.sender?.name || ""}
                onChange={(event, newValue) =>
                  handleAddressChange("sender", newValue)
                }
                options={addressState.sender.options}
                loading={addressState.sender.loading}
                open={addressState.sender.open}
                onOpen={() => fetchSellerDetails("sender")}
                onClose={() => handleCloseAddress("sender")}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sender Address"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addressState.sender.loading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <Tooltip title="Add new Sender details.">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setAddNewAddress((prev) => ({
                      ...prev,
                      isOpen: true,
                      addAddressType: "sender",
                    }));
                  }}
                  className="d-flex  align-items-center justify-content-center"
                  style={{
                    // backgroundColor: color.lotBtncolor,
                    // border: `1px solid ${color.lotBtncolor}`,
                    borderColor: color.lotBtncolor,
                    borderRadius: "8px",
                    height: isSmallScreen ? "38px" : "40px",
                    // width: "40px",
                    margin: "5px 0px",
                  }}
                >
                  <HiUserAdd
                    fontSize="small"
                    style={{
                      // color: color.textWhiteColor,
                      color: color.lotBtncolor,
                      height: "auto",
                      width: "30px",
                      // color: "#784af4"
                      // color: "#1976d2",
                    }}
                  />
                </Button>
              </Tooltip>
            </FormControl>
            {fieldError.addressSenderError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select sender address.
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* Receiver Address */}
          <div>
            <FormControl
              fullWidth
              error={fieldError.addressReceiverError}
              className="d-flex flex-row align-items-center gap-3"
            >
              <Autocomplete
                className="w-100"
                value={genFields?.generalDetails?.sender?.name || ""}
                onChange={(event, newValue) =>
                  handleAddressChange("receiver", newValue)
                }
                options={addressState.receiver.options}
                loading={addressState.receiver.loading}
                open={addressState.receiver.open}
                onOpen={() => fetchSellerDetails("receiver")}
                onClose={() => handleCloseAddress("receiver")}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Receiver Address"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addressState.receiver.loading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <Tooltip title="Add new Receiver details.">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setAddNewAddress((prev) => ({
                      ...prev,
                      isOpen: true,
                      addAddressType: "receiver",
                    }));
                  }}
                  className="d-flex  align-items-center justify-content-center"
                  style={{
                    // backgroundColor: color.lotBtncolor,
                    // border: `1px solid ${color.lotBtncolor}`,
                    borderColor: color.lotBtncolor,
                    borderRadius: "8px",
                    height: isSmallScreen ? "38px" : "40px",
                    // width: "40px",
                    margin: "5px 0px",
                  }}
                >
                  <HiUserAdd
                    fontSize="small"
                    style={{
                      // color: color.textWhiteColor,
                      color: color.lotBtncolor,
                      height: "auto",
                      width: "30px",
                      // color: "#784af4"
                      // color: "#1976d2",
                    }}
                  />
                </Button>
              </Tooltip>
            </FormControl>
            {fieldError.addressReceiverError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select receiver address.
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* displaying of sender Address */}
        {genFields.generalDetails.sender.name !== "" ? (
          <div className="my-4 mb-0 w-100">
            <div className="card-Div p-3 ">
              <div
                className="bar-chart-heading  "
                style={{
                  color: color.taxtHeading,
                  lineHeight: "1.2",
                  fontSize: "1rem",
                }}
              >
                Sender Details
              </div>
              <div className="px-1 my-1">
                <DisplayAddress
                  isSmallScreen={isSmallScreen}
                  genFields={genFields}
                  options={addressState}
                  isMediumScreen={isMediumScreen}
                  islageScreen={islageScreen}
                  addressType={"sender"}
                />
              </div>
            </div>
          </div>
        ) : null}

        {/* displaying of receviverAddress */}
        {genFields.generalDetails.receiver.name !== "" ? (
          <div className="my-4 mb-0 w-100">
            <div className="card-Div p-3 ">
              <div
                className="bar-chart-heading  "
                style={{
                  color: color.taxtHeading,
                  lineHeight: "1.2",
                  fontSize: "1rem",
                }}
              >
                Receiver Details
              </div>
              <div className="px-1 my-1">
                <DisplayAddress
                  isSmallScreen={isSmallScreen}
                  genFields={genFields}
                  options={addressState}
                  isMediumScreen={isMediumScreen}
                  islageScreen={islageScreen}
                  addressType={"receiver"}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {addNewAddress.isOpen && (
        <AddNewAddressDetails
          addNewAddress={addNewAddress}
          setAddNewAddress={setAddNewAddress}
          handleClose={handleCloseAddNewSellerModal}
          genFields={genFields}
          setGenFields={setGenFields}
          isSmallScreen={isSmallScreen}
          fetchApi={fetchApi}
          openSnackbar={openSnackbar}
        />
      )}
    </div>
  );
};

export default ShipmentGeneralDetails;

const AddNewAddressDetails = (props) => {
  const {
    addNewAddress,
    handleClose,
    isSmallScreen,
    setAddNewAddress,
    fetchApi,
    openSnackbar,
  } = props;

  const [addNewAddressDetailsErrors, setAddNewAddressDetailsErrors] = useState({
    nameError: false,
    phoneError: false,
    emailError: false,
    addressError: false,
  });

  useEffect(() => {
    // Check for each field and update the corresponding error state
    if (addNewAddress.name !== "") {
      setAddNewAddressDetailsErrors((prev) => ({
        ...prev,
        nameError: false,
      }));
    }

    if (addNewAddress.phone !== "") {
      setAddNewAddressDetailsErrors((prev) => ({
        ...prev,
        phoneError: false,
      }));
    }

    if (addNewAddress.email !== "") {
      setAddNewAddressDetailsErrors((prev) => ({
        ...prev,
        email: false,
      }));
    }

    if (addNewAddress.address !== "") {
      setAddNewAddressDetailsErrors((prev) => ({
        ...prev,
        addressError: false,
      }));
    }
  }, [addNewAddress]);

  const [loading, setLoading] = useState(false);
  const addNewSeller = async () => {
    // console.log("test clicked");
    let nError = false;
    let pError = false;
    let eError = false;
    let adError = false;

    if (addNewAddress.name === "") nError = true;
    if (addNewAddress.phone === "") pError = true;
    if (addNewAddress.email === "") eError = true;
    if (addNewAddress.address === "") adError = true;

    setAddNewAddressDetailsErrors((prev) => ({
      nameError: nError,
      phoneError: pError,
      emailError: eError,
      addressError: adError,
    }));
    if (nError || pError || eError || adError) {
      return;
    } else {
      setLoading(true);
      try {
        const payload = {
          name: addNewAddress.name,
          email: addNewAddress.email,
          phone: addNewAddress.phone,
          address: addNewAddress.address,
        };

        // Send the payload to the backend route
        // const response = await fetchApi(
        //   "add_seller_details",
        //   "POST",
        //   payload,
        //   true
        // );
        // if (response.statusCode === 200) {
        //   console.log("comments added succesfully", response);
        //   // setLoading(false);
        //   setAddNewAddress((prevData) => ({
        //     ...prevData,
        //     sellerName: "",
        //     contactPerson: "",
        //     contactNumber: "",
        //     emailId: "",
        //     address: "",
        //   }));
        //   openSnackbar(
        //     response.data.message || "Sellers Details Added Sussessfully",
        //     "success"
        //   );
        // } else {
        //   // console.error("Error editing ticket information:", response.statusCode);
        //   openSnackbar(response.msg, "error");
        // }

        console.log("hitting backend");
      } catch (error) {
        console.error("Error editing ticket information:", error);
        openSnackbar(
          "Some error occured while saving Sellers Details",
          "error"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={addNewAddress.isOpen}
      style={{
        position: "fixed",
        overflowY: "scroll",
        height: "100%",
        margin: "0px 0px 0px 10px",
      }}
    >
      <Box
        style={{
          position: "relative",
          zIndex: "1111",
          right: "0",
          top: "16%",
          left: "0",
          margin: "auto",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          maxWidth: "1300px",
          height: "auto",
        }}
      >
        {/* Modal Header starts*/}
        <div
          style={{
            padding: "20px",
            borderBottom: "1px solid #f5f5f5",
          }}
        >
          <div className="d-flex justify-content-between">
            <h5 className="m-0">{`Add new ${addNewAddress.addAddressType} address`}</h5>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {/* Modal Header ends*/}

        {/* Modal body starts */}
        <Box className="p-4 pt-0">
          <div
            style={{
              display: "grid",
              width: "100%",
              marginTop: "25px",
              gap: 25,
            }}
          >
            {/* seller name */}
            <FormControl
              error={addNewAddressDetailsErrors.nameError}
              style={{
                gridColumn: isSmallScreen ? "span 2" : "span 1",
              }}
            >
              <TextField
                value={addNewAddress.name}
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                onChange={(e) => {
                  setAddNewAddress((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
              {addNewAddressDetailsErrors.nameError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter name.
                </div>
              ) : (
                <></>
              )}
            </FormControl>

            {/* phone */}
            <FormControl error={addNewAddressDetailsErrors.phoneError}>
              <TextField
                value={addNewAddress.phone}
                fullWidth
                type="number"
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                onChange={(e) => {
                  setAddNewAddress((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }));
                }}
              />
              {addNewAddressDetailsErrors.phoneError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter phone.
                </div>
              ) : (
                <></>
              )}
            </FormControl>

            {/*  email */}
            <FormControl
              error={addNewAddressDetailsErrors.emailError}
              style={{
                gridColumn: isSmallScreen ? "span 2" : "1 / 3",
              }}
            >
              <TextField
                value={addNewAddress.email}
                fullWidth
                type="email"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={(e) => {
                  setAddNewAddress((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
              {addNewAddressDetailsErrors.emailError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter email id.
                </div>
              ) : (
                <></>
              )}
            </FormControl>

            {/* address */}
            <FormControl
              error={addNewAddressDetailsErrors.addressError}
              style={{
                width: "100%",
                gridColumn: "span 2",
              }}
            >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Address"
                multiline
                rows={4}
                value={addNewAddress.address}
                onChange={(e) => {
                  setAddNewAddress((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }));
                }}
              />
              {addNewAddressDetailsErrors.addressError ? (
                <div
                  style={{
                    marginTop: "8px",
                    color: "red",
                    fontWeight: 300,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Please enter Address.
                </div>
              ) : (
                <></>
              )}
            </FormControl>
          </div>
          {/* modal footer starts */}
          <div className="d-flex justify-content-end mt-4">
            <LoadingButton
              style={{
                backgroundColor: (loading && "inherit") || color.lotBtncolor,
              }}
              size="medium"
              onClick={() => {
                addNewSeller();
              }}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              add
            </LoadingButton>
          </div>
          {/* modal footer ends */}{" "}
        </Box>
      </Box>
    </Modal>
  );
};
